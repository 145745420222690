import React from "react";
import {
  Pagination as ReactStrapPagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

interface IProps {
  numOfPages: number;
  setPage: (page: number) => void;
  page: number;
}

const Pagination: React.FC<IProps> = ({ numOfPages, setPage, page }) => {
  const array:Array<number> = [];
  const start = Math.max(1, page - 2);
  const end = Math.min(page + 2, numOfPages);
  for(let i = Math.min(Math.max(1, numOfPages - 4), start); i <= Math.max(end, Math.min(5, numOfPages)); i++) {
      array.push(i);
  }

  const onPageNumber = (
    event: React.MouseEvent<HTMLAnchorElement>,
    pageNumber: number
  ) => {
    event.preventDefault();
    setPage(pageNumber);
  };
  return (
    <ReactStrapPagination style={{display: 'flex', justifyContent: 'center'}}>
      <PaginationItem disabled={page === 1}>
        <PaginationLink
          previous
          href="#"
          onClick={event => onPageNumber(event, (page - 1))}
        ></PaginationLink>
      </PaginationItem>
      {array.map(pageNumber => {
        let isActive = false;
        if (page === pageNumber) isActive = true;
        return (
          <PaginationItem key={pageNumber} active={isActive}>
            <PaginationLink
              href="#"
              onClick={event => onPageNumber(event, pageNumber)}
            >
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        );
      })}
      <PaginationItem disabled={page === numOfPages}>
        <PaginationLink
          next
          href="#"
          onClick={event => onPageNumber(event, (page + 1))}
        ></PaginationLink>
      </PaginationItem>
    </ReactStrapPagination>
  );
};

export default Pagination;
