import React, { useContext, useEffect } from "react";
import { IBlockContentPricing, IBlockContent } from "../../../../../../../interfaces";
import { EPricingType, EBillingPeriod } from "./BlockContentPricingTableEdit";
import { useTranslation } from "react-i18next";
import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";
import { toFixed } from "../../../../../../../shared/utility";

import classes from "./BlockContentPricingTableShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent: boolean;
}

const dummyPricing = {
  pricingType: EPricingType.SERVICE_PRICING,
  price: 300,
  initialPayment: 1500,
  billingPeriod: EBillingPeriod.EVERY_TWO_WEEKS,
  terminationPeriod: 12
}

const BlockContentPricingTableShow: React.FC<IProps> = (props) => {
  let blockContent = props.blockContent as IBlockContentPricing;
  const showDummyContent = props.showDummyContent;

  if (showDummyContent && !blockContent.pricingType) {
    blockContent = { ...blockContent, ...dummyPricing };
  }

  const { t, i18n } = useTranslation();
  const { customer } = useContext(OfferPreviewContext);

  useEffect(() => {
    if(customer && customer.language) i18n.changeLanguage(customer.language);
    // eslint-disable-next-line
  }, [customer]);

  const calculateTotalPrice = () => {
    if (blockContent.price) {
      if (blockContent.estimatedAmountText && blockContent.estimatedAmountText.indexOf("-") !== -1) {
        const split = blockContent.estimatedAmountText.split("-");
        const min = toFixed(blockContent.price * Number(split[0]));
        const max = toFixed(blockContent.price * Number(split[1]));
        return min + "-" + max;
      } else if(blockContent.estimatedAmountText) {
        return toFixed(blockContent.price * Number(blockContent.estimatedAmountText));
      } else {
        return toFixed(blockContent.price);
      }
    }
  };

  const getPricingTable = (pricingType: string) => {
    switch (pricingType) {
      case EPricingType.SERVICE_PRICING:
        return (
          <div>
            <div className={classes.Row}>
              {blockContent.price ? <p className={classes.Heading}>{t('offerPreview.monthlyPrice')}</p> : null}
              {blockContent.initialPayment ? <p className={classes.Heading}>{t('offerPreview.initialPayment')}</p> : null}
            </div>
            <div className={classes.Row}>
              {blockContent.price ? <p className={classes.Heading}>{toFixed(blockContent.price)} €</p> : null}
              {blockContent.initialPayment ? <p className={classes.Heading}>{toFixed(blockContent.initialPayment)} €</p> : null}
            </div>
            {blockContent.price || blockContent.initialPayment ? <div className={classes.Divider}></div> : null}
            {blockContent.billingPeriod ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.billingPeriod')}</p>
                  <p>{getBillingPeriod(blockContent.billingPeriod)}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
            {blockContent.terminationPeriod ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.terminationPeriod')}</p>
                  <p>{blockContent.terminationPeriod} {t('offerPreview.months')}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
          </div>
        );
      case EPricingType.FIXED_PROJECT_PRICING:
        return (
          <div>
            {blockContent.price ? (
              <React.Fragment>
                <div>
                  <p className={classes.Heading}>{t('offerPreview.totalPrice')}</p>
                  <p className={classes.Heading}>{toFixed(blockContent.price)} €</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
            {blockContent.billingPeriod ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.billingPeriod')}</p>
                  <p>{getBillingPeriod(blockContent.billingPeriod)}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
            {blockContent.estimatedDeliveryDate ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.deliveryDate')}</p>
                  <p>{blockContent.estimatedDeliveryDate}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
          </div>
        );
      case EPricingType.DAILY_RATE:
        return (
          <div>
            {blockContent.price ? (
              <React.Fragment>
                <div>
                  <p className={classes.Heading}>{t('offerPreview.totalPrice')}</p>
                  <p className={classes.Heading}>{calculateTotalPrice()} €</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
            {blockContent.billingPeriod ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.billingPeriod')}</p>
                  <p>{getBillingPeriod(blockContent.billingPeriod)}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
            {blockContent.price && blockContent.estimatedAmountText ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.estimatedDays')}</p>
                  <p>{toFixed(blockContent.price)} € x {blockContent.estimatedAmountText} {t('offerPreview.days')}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
          </div>
        );
      case EPricingType.HOURLY_RATE:
        return (
          <div>
            {blockContent.price ? (
              <React.Fragment>
                <div>
                  <p className={classes.Heading}>{t('offerPreview.totalPrice')}</p>
                  <p className={classes.Heading}>{calculateTotalPrice()} €</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
            {blockContent.billingPeriod ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.billingPeriod')}</p>
                  <p>{getBillingPeriod(blockContent.billingPeriod)}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
            {blockContent.price && blockContent.estimatedAmountText ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.estimatedHours')}</p>
                  <p>{toFixed(blockContent.price)} € x {blockContent.estimatedAmountText} {t('offerPreview.hours')}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
          </div>
        );
      case EPricingType.OTHER_PRICING:
        return (
          <div>
            {blockContent.priceOther ? (
              <React.Fragment>
                <div>
                  <p className={classes.Heading}>{t('offerPreview.totalPrice')}</p>
                  <p className={classes.Heading}>{blockContent.priceOther}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
            {blockContent.billingPeriod ? (
              <React.Fragment>
                <div className={classes.Row}>
                  <p>{t('offerPreview.billingPeriod')}</p>
                  <p>{getBillingPeriod(blockContent.billingPeriod)}</p>
                </div>
                <div className={classes.Divider}></div>
              </React.Fragment>
            ) : null}
          </div>
        );
      default:
        return null;
    }
  }

  const getBillingPeriod = (billingPeriod: string) => {
    switch (billingPeriod) {
      case EBillingPeriod.EVERY_TWO_WEEKS:
        return t('offerPreview.EVERY_TWO_WEEKS');
      case EBillingPeriod.END_OF_MONTH:
        return t('offerPreview.END_OF_MONTH');
      case EBillingPeriod.END_OF_PROJECT:
        return t('offerPreview.END_OF_PROJECT');
      case EBillingPeriod.UPFRONT_MONTH:
        return t('offerPreview.UPFRONT_MONTH');
      case EBillingPeriod.UPFRONT_3_MONTHS:
        return t('offerPreview.UPFRONT_3_MONTHS');
      case EBillingPeriod.OTHER_BILLING:
        return blockContent.billingPeriodOther;
      default:
        return null;
    }
  }

  return (
    <div className={[classes.Container, 'customColor-bg'].join(' ')}>
      {getPricingTable(blockContent.pricingType)}
    </div>
  );
};

export default BlockContentPricingTableShow;
