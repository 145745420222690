const f = (x:number) => (parseFloat(String(x)) || 0)

export const calcTotal = (price:number,quantity:number,discount:number) => {
	return f(price)*f(quantity)*((100-(f(discount) || 0))/100)
}

export const calcTotalVat = (price:number,quantity:number,discount:number,vat:number) => {
	return f(price)*f(quantity)*((100-(f(discount) || 0))/100)*(f(vat)/100)
}

export const round = (x:number) => {
	return f(x).toFixed(2)
}

export const getKuukausikerroin = (kertoimet:any, index:number) => {
	if(index === -1) return ["ei valittu",0];
	return kertoimet[index];
}

export const getValue = (a:any, _i:number,pos:number) => {
		const i=isNaN(_i)?0:_i;

		if(i in a) {
			if(pos in a[i]) {
				return a[i][pos];
			}
		}
		return 1;
	}

export const summa = (setup:any, data:any, tilat:any) => {
		const x = data.reduce((resp:any,o:any) => {
			const tila = tilat[o.tila];
			if(tila === undefined) return resp;
			
			return resp+o.kpl*o.kerros*tila.standardiaika*o.tuntihinta*
				getValue(setup.kuukausikertoimet,o.kuukausikerroin,1)*
				getValue(tila.kokokerroin,o.kokokerroin,1)*
				getValue(tila.likakerroin,o.likakerroin,1)*
				pakettiKerroin(setup.paketti, tila);
		},0);

		return x;
	}

export const isnot = (v:number) => {
		if(isNaN(v)) return(true);
		if(v===null) return(true);
		return false;
	}

const pakettiKerroin = (paketti:string, tila:any) => {
	const conv:{[id:string]:string} = {"A":"Täsmä","B":"Puhdas","C":"Loisto"};
	const p = conv[paketti];
	if(tila.paketti && p in tila.paketti) {
		return tila.paketti[p];
	}
	return 1;
}


export const summa2 = (data:any, tilat:any) => {
		const x = data.reduce((resp:any,o:any) => {
			const tila = tilat[o.tila];

			if(tila === undefined) return resp;

			const standardiaika = getValue(tila.lisatyot,parseInt(o.tyo),1);
			const kokolikakerroin = getValue(tila.lisatyokerroin,parseInt(o.kokolikakerroin),1);

			return resp + (!isnot(o.korjattuhinta)?(o.korjattuhinta):(
			//return resp + 
					o.kpl *
				  	o.kerros *
				  	kokolikakerroin *
				  	standardiaika *
				  	o.hinta * 
				  	pakettiKerroin(data.paketti, tila)));
			},0);
		return x;
	}

export const empty = {kuukausikerroin:-1, tila:-1, kokokerroin:0, likakerroin:0, kpl:1, kerros:1, tuntihinta:30};

export const bcparser = (blockContent:any) => {
	const json = blockContent.json && JSON.parse(blockContent.json) ||
	 	{
	 		section1: [empty],
	 		section2: [],
	 		section3: {},
	 		section4: {},
	 		section5: {},
		};

	if(!("section1" in json)) {
		const s=Object.keys(json).length === 0?[]:json;

		return { section1: s, section2: [], section3:{info:"", alennus:0, showalennus:true}, section4:{}, section5:{} };
	}

	return {section3:{info:"", alennus:0, showalennus:true},section4:{}, section5:{},...json};
}

