import { IUserTemplateAction } from '../../interfaces/state';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';
import { IUserTemplateState } from './../../interfaces/state';

const initialState:IUserTemplateState = {
    userTemplates: null,
    userTemplate: null,
    loading: false,
    error: null,
};

const findUserTemplatesStart = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, { error: null, loading: true});
}

const findUserTemplatesSuccess = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, {
        userTemplates: action.userTemplates,
        error: null,
        loading: false,
        userTemplate: null
    });
}

const findUserTemplatesFail = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getUserTemplateStart = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, { error: null, loading: true});
}

const getUserTemplateSuccess = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, {
        userTemplate: action.userTemplate,
        error: null,
        loading: false
    });
}

const getUserTemplateFail = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const saveUserTemplateStart = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, { error: null, loading: true});
}

const saveUserTemplateSuccess = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const saveUserTemplateFail = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateUserTemplateStart = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, { error: null, loading: true});
}

const updateUserTemplateSuccess = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const updateUserTemplateFail = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const deleteUserTemplateStart = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
  return updateObject(state, { error: null, loading: true });
}

const deleteUserTemplateSuccess = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
  return updateObject(state, {
      error: null,
      loading: false,
      userTemplate: null
  });
}

const deleteUserTemplateFail = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const copyUserTemplateStart = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
  return updateObject(state, { error: null, loading: true });
}

const copyUserTemplateSuccess = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
  return updateObject(state, {
      error: null,
      loading: false,
      userTemplate: action.userTemplate
  });
}

const copyUserTemplateFail = (state:IUserTemplateState, action:IUserTemplateAction):IUserTemplateState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const reducer = (state:IUserTemplateState = initialState, action:IUserTemplateAction):IUserTemplateState => {
    
    switch (action.type) {
        case ActionTypes.FIND_USER_TEMPLATES_START : return findUserTemplatesStart(state, action);
        case ActionTypes.FIND_USER_TEMPLATES_SUCCESS : return findUserTemplatesSuccess(state, action);
        case ActionTypes.FIND_USER_TEMPLATES_FAIL : return findUserTemplatesFail(state, action);
        case ActionTypes.GET_USER_TEMPLATE_START : return getUserTemplateStart(state, action);
        case ActionTypes.GET_USER_TEMPLATE_SUCCESS : return getUserTemplateSuccess(state, action);
        case ActionTypes.GET_USER_TEMPLATE_FAIL : return getUserTemplateFail(state, action);
        case ActionTypes.SAVE_USER_TEMPLATE_START : return saveUserTemplateStart(state, action);
        case ActionTypes.SAVE_USER_TEMPLATE_SUCCESS : return saveUserTemplateSuccess(state, action);
        case ActionTypes.SAVE_USER_TEMPLATE_FAIL : return saveUserTemplateFail(state, action);
        case ActionTypes.UPDATE_USER_TEMPLATE_START : return updateUserTemplateStart(state, action);
        case ActionTypes.UPDATE_USER_TEMPLATE_SUCCESS : return updateUserTemplateSuccess(state, action);
        case ActionTypes.UPDATE_USER_TEMPLATE_FAIL : return updateUserTemplateFail(state, action);
        case ActionTypes.DELETE_USER_TEMPLATE_START : return deleteUserTemplateStart(state, action);
        case ActionTypes.DELETE_USER_TEMPLATE_SUCCESS : return deleteUserTemplateSuccess(state, action);
        case ActionTypes.DELETE_USER_TEMPLATE_FAIL : return deleteUserTemplateFail(state, action);
        case ActionTypes.COPY_USER_TEMPLATE_START : return copyUserTemplateStart(state, action);
        case ActionTypes.COPY_USER_TEMPLATE_SUCCESS : return copyUserTemplateSuccess(state, action);
        case ActionTypes.COPY_USER_TEMPLATE_FAIL : return copyUserTemplateFail(state, action);
        default: return state;
    }
}

export default reducer;