import * as qs from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../../withRouter';

import ContentWithSidebarStatic from "../../../../../components/UI/ContentWithSidebarStatic/ContentWithSidebarStatic";
import CustomDialog from "../../../../../components/UI/CustomDialog/CustomDialog";
import ListSpinner from "../../../../../components/UI/ListSpinner/ListSpinner";
import SidebarStatic from "../../../../../components/UI/SidebarStatic/SidebarStatic";
import {
  IAppState,
  IDialog,
  IOffer,
  IOfferComment,
  IUser
} from "../../../../../interfaces";

import _ from "lodash";
import { IBlockContent, IUserTemplate, IUserTemplateAreaBlock } from "../../../../../interfaces";
import { EUserTemplateBlockType } from "../../../../../enums";
import OfferContext from "../Offer/OfferContext";


import { isOfferDone, defaultOffer } from "../../../../../shared/offer-utils";
import * as actions from "../../../../../store/actions";
import OfferAcceptDialog from "../../../components/Offers/Offer/OfferAcceptDialog/OfferAcceptDialog";
import OfferAcceptSidebar from "../../../components/Offers/Offer/OfferAcceptSidebar/OfferAcceptSidebar";
import OfferCommentsContext from "../../../components/Offers/Offer/OfferComment/OfferCommentsContext";
import OfferDeclineDialog from "../../../components/Offers/Offer/OfferDeclineDialog/OfferDeclineDialog";
import OfferPreview from "../../../components/Offers/Offer/OfferPreview/OfferPreview";
import { Button } from "reactstrap";
import { EOfferStatus, ESignerType } from "../../../../../enums";
import OfferExpired from "../OfferExpired/OfferExpired";

import { getOfferSigners } from '../../../../../shared/offer-utils';

import classes from "./OfferAccept.module.scss";

interface IStateProps {
  loading: boolean;
  error: string;
  currentUser: IUser;
  commentLoading: boolean;
  commentError: string;
  codeError: string;
}

interface IDispatchProps {
  onGetOffer: (id: string, token: string, printable: boolean, email?:string, hash?:string) => Promise<IOffer>;
  onSendOfferConfirmCode: (id: string, token: string, email?: string) => void;
  onConfirmOffer: (data: Object) => Promise<IOffer>;
  onDeclineOffer: (data: Object) => void;
  onForwardOffer: (data: Object) => void;
	onCommentOffer: (data: Object) => Promise<IOffer>;

  onUpdateOffer: (id: string, token:string, offer: IOffer) => Promise<boolean>;
}

interface IParams {
  id: string;
}

interface IProps
  extends RouteComponentProps, //<IParams>,
    IStateProps,
    IDispatchProps {
  hasNavigation?: boolean;
}

const defaultDialog: IDialog = {
  isOpen: false,
  title: "",
  text: ""
};

interface UrlParams {
  id: string;
  token: string;
	printable: boolean;
	strongauth?: boolean;

  email?: string;
  hash?: string;
}

const OfferAccept: React.FC<IProps> = ({
  loading,
  onGetOffer,
  error,
  location,
  currentUser,
  hasNavigation,
  commentLoading,
  commentError,
  onSendOfferConfirmCode,
  onConfirmOffer,
  onDeclineOffer,
  onCommentOffer,
	codeError, // confirmCodeError
	onUpdateOffer,
}) => {
  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const [showOfferDeclineDialog, setShowOfferDeclineDialog] = useState(false);
  const [dialog, setDialog] = useState<IDialog>(defaultDialog);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  // const [showOfferForwardDialog, setShowOfferForwardDialog] = useState(false);
  const { t, i18n } = useTranslation();
  const [offer, setOffer] = useState<IOffer>(defaultOffer);

	const [contentUpdated, setContentUpdated] = useState<boolean>(false);

  const { id, token, printable, strongauth, email, hash } = ((): UrlParams => {
    const params = qs.parse(location.search);

    return {
      id: params.id as string,
      token: params.token as string,
			printable: params.printable === "true",
			strongauth: params.strongauth === "true",
      email: params.email as string,
      hash: params.hash as string,
    };
  })();

  const getBlocksObject = (
    userTemplate: IUserTemplate,
    blockType: EUserTemplateBlockType
  ): { [id: string]: IUserTemplateAreaBlock } => {
    //const { header, main, footer } = userTemplate;
    const { main } = userTemplate;
    return main.blocks //_.concat([], header.blocks, main.blocks, footer.blocks)
      .filter(block => block && block.blockType === blockType)
      .reduce((current: any, item) => {
        current[item.id] = item;
        return current;
      }, {});
  };


	const onSimpleContentChanged = (
    block: IUserTemplateAreaBlock,
    blockContent: IBlockContent
  ) => {
    const newOffer = { ...offer };
    const blocks = getBlocksObject(
      newOffer.userTemplate,
      EUserTemplateBlockType.SIMPLE
    );

    try {
      const newBlock = blocks[block.id];
      const indexOf = newBlock.content.findIndex(
        item => item.id === blockContent.id
      );
      if (indexOf !== -1) {
        newBlock.content[indexOf] = { ...blockContent, editOnOffer: true };
      }
    } catch(err) {
      const dblocks = getBlocksObject(
        newOffer.userTemplate,
        EUserTemplateBlockType.DYNAMIC
      );
      const newBlock = dblocks[block.id];    

      if(newBlock) 
      {
        const indexOf = newBlock.offerBlockItems.findIndex((item) => 
          item.content.findIndex((x)=>x.id === blockContent.id)!==-1
        );

        const indexOf2 = newBlock.offerBlockItems[indexOf].content.findIndex((item) => 
          item.id === blockContent.id
        );

        if (indexOf2 !== -1) {
          newBlock.offerBlockItems[indexOf].content[indexOf2] = { ...blockContent, editOnOffer: true };
        }
      }
    }


		setOffer(newOffer);
		setContentUpdated(true);
  };


	useEffect(() => {
    (async () => {
      const asyncOffer = await onGetOffer(id, token, printable, email, hash);
      if (!asyncOffer) return;
      setOffer(asyncOffer);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (offer && offer.customer) {
      i18n.changeLanguage(offer.customer.language);
    }
    // eslint-disable-next-line
  }, [offer]);

  useEffect(() => {
    if (showMobileSidebar) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = null;
    }
  }, [showMobileSidebar]);

  const signers = getOfferSigners(offer) || [];

  const acceptOffer = async (confirmCode: string, customerSignature: string, email?: string, hash?: string) => {
    // Move modal closing to onEffect check if offer was completed / don't close if error happened and pass the error to accept dialog
    setShowAcceptDialog(false);

    const data = {
      id: offer.id,
      token,
      confirmCode: confirmCode,
      customerSignature: customerSignature,
      email: email,
      hash: hash,
    };

    const newOffer = await onConfirmOffer(data);
    let title = "";
    let content = "";
    if (newOffer) {
      if(newOffer.status===EOfferStatus.CONFIRMED) {
        title = t("thankYouDialog.title");
        content = t("thankYouDialog.content");
      }
      else {
        title = t("thankYouSignDialog.title");
        content = t("thankYouSignDialog.content");        
      }
      setOffer(newOffer);
    } else {
      title = t("confirmFailedDialog.title");
      content = t("confirmFailedDialog.content");
    }
    setDialog({
      isOpen: true,
      title,
      text: content
    });
  };

	const updateOffer = async () => {
		await onUpdateOffer(offer.id, token, offer);
		setContentUpdated(false);
	}

  const declineOffer = async (declineOfferData?: {}) => {
    const data = {
      id: offer.id,
      token,
      comment: declineOfferData
    };

    await onDeclineOffer(data);

    setDialog({
      isOpen: true,
      title: t("offerDeclineDialog.offerDeclined"),
      text: `${offer.lastSender.name} ${t(
        "offerDeclineDialog.declineInfoText"
      )}`
    });
  };

  // const forwardOffer = async (forwardOfferData: {}) => {
  //   await onForwardOffer(forwardOfferData);
  // }

  const commentOfferHandler = async (comment: IOfferComment) => {
    const data = {
      id: offer.id,
      token,
      comment
    };

    const newOffer = await onCommentOffer(data);
    setOffer({...offer, comments:newOffer.comments});
  };

  // const openAuditLogHandler = () => {
  //   window.open(
  //     `/offer/logs/${getURLParams().id}/${getURLParams().token}`,
  //     "_blank"
  //   );
  // };

  let comments = null;
  let defaultSenderName = null;
  if (offer) {
    comments = offer.comments;
    if (offer.customer) {
      defaultSenderName = offer.customer.contactPerson;
    }
  }

  const containerClassNames = [classes.Container];
  if (printable) {
    containerClassNames.push(classes.Printable);
  }

  if (!offer || loading) {
    return <ListSpinner />;
  } else if (offer.status === EOfferStatus.EXPIRED || offer.isExpired) {
    return <OfferExpired offer={offer} />;
	} else {
		//console.log(offer)
    return (
			<React.Fragment>
        <CustomDialog
          okButtonText={t("thankYouDialog.button")}
          onOk={() => setDialog(defaultDialog)}
          open={dialog.isOpen}
          title={dialog.title}
        >
          {dialog.text}
        </CustomDialog>
			
        <OfferAcceptDialog
          organizationLevel={offer.organization && offer.organization.level}
          open={showAcceptDialog}
          acceptOffer={acceptOffer}
          closeModal={() => setShowAcceptDialog(false)}
          offer={offer}
          onSendOfferConfirmCode={() => onSendOfferConfirmCode(offer.id, token, email)}
          error={codeError}

          email={email}
          hash={hash}
        />
        <OfferDeclineDialog
          isOpen={showOfferDeclineDialog}
          setIsOpen={setShowOfferDeclineDialog}
          onSave={data => declineOffer(data)}
          defaultSenderName={defaultSenderName}
        />
        {/* CD-713 */}
        {/* <OfferForwardDialog
          isOpen={showOfferForwardDialog}
          setIsOpen={setShowOfferForwardDialog}
          onSave={(data) => forwardOffer(data)}
          offer={offer}
        /> */}

			<ContentWithSidebarStatic className={containerClassNames.join(" ")}>
	      <OfferContext.Provider
	        value={{
	          offer,
	          setOffer,
						onSimpleContentChanged,
	
	          onDynamicBlockItemChanged:null,
	          blockItemsLoading:null,
	          userTemplates:null,
	          userTemplatesLoading:null,
	          customers:null,
	          customersLoading:null,
	          onAddBlockItem:null,
	          onEditBlockItem:null,
	          customerHasChanged:null,
	          setCustomerHasChanged:null,
	          hideEditableContent:null,
	          setHideEditableContent:null,
	          onBlockItemChanged:null,
	          blockLoading:null,
	
            onSearchCustomers: null,
            onGetCustomer: null,

            currentUser: null,
	        }}
	      >
 
          <OfferPreview
            offer={offer}
            isVisible={true}
            token={token}
						printable={printable}
						strongauth={strongauth}
					/>

 	     </OfferContext.Provider>

          <SidebarStatic shadow>
            <OfferCommentsContext.Provider
              value={{
                comments,
                loading: commentLoading,
                error: commentError,
                defaultSenderName,
                currentUser,
                onCommentOffer: commentOfferHandler,
                hideControls: isOfferDone(offer)
              }}
            >
              <OfferAcceptSidebar
								offer={offer}
								token={token}
                onAccept={() => setShowAcceptDialog(true)}
                onDecline={() => setShowOfferDeclineDialog(true)}
                onForward={() => {}}
                onCloseMobileSidebar={() => setShowMobileSidebar(false)}
                hasNavigation={hasNavigation}
                showMobileSidebar={showMobileSidebar}
								hidden={printable}
								contentUpdated={contentUpdated}
								updateOffer={updateOffer}
                email={email}
              />
						</OfferCommentsContext.Provider>
          </SidebarStatic>

          {!printable && (
            <div className={classes.MobileFooter}>
              <Button
                color="info"
                size="lg"
                onClick={() => setShowMobileSidebar(true)}
							>
                {signers.find((s:any)=>s.email === email && s.signerType === ESignerType.WATCH) 
                  ?<>{t("offerAccept.mobileButtonViewer")}</>
                  :<>
								    {offer.status !== EOfferStatus.CONFIRMED
									   ?t("offerAccept.mobileButton")
									   :t("offerAccept.mobileButtonConfirmed")
                    }
                  </>
                }
              </Button>
            </div>
          )}
        </ContentWithSidebarStatic>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.offers.loading,
    error: state.offers.error,
    currentUser: state.auth.currentUser,
    commentError: state.offers.commentError,
    commentLoading: state.offers.commentLoading,
    codeError: state.offers.codeError
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetOffer: (id: string, token: string, printable: boolean, email?:string, hash?:string) =>
      dispatch(actions.getOffer(id, token, printable, email, hash)),
    onSendOfferConfirmCode: (id, token, email) =>
      dispatch(actions.sendOfferConfirmCode(id, token, email)),
    onConfirmOffer: data => dispatch(actions.confirmOffer(data)),
    onDeclineOffer: data => dispatch(actions.declineOffer(data)),
    onForwardOffer: data => dispatch(actions.forwardOffer(data)),
		onCommentOffer: data => dispatch(actions.commentOffer(data)),

    onUpdateOffer: (id, token, offer) => dispatch(actions.customerUpdateOffer(id, token, offer)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OfferAccept));
