import React from "react";
import { Alert, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import classes from "./BackAlert.module.scss";

interface IProps {
  backText: string;
  onBack: () => void;
  centerText?: string;
}

const BackAlert: React.FC<IProps> = ({ backText, onBack, centerText }) => {
  return (
    <Alert color="info" className={classes.Container}>
      <div className={classes.Back}>
        <Button color="link" onClick={onBack} className={classes.BackButton}>
          <span className={classes.BackIcon}>
            <FontAwesomeIcon icon={faArrowLeft} className="fa-sm" />
          </span>
          {backText}
        </Button>
      </div>
      <div className={classes.Center}>{centerText || <span>&nbsp;</span>}</div>
    </Alert>
  );
};

export default BackAlert;
