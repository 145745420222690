import { EPricingType } from './../../sections/default/components/Blocks/Block/BlockContent/BlockContentPricingTable/BlockContentPricingTableEdit';
import { IBlockContentPricing } from "../../interfaces";
import { stringToDate } from '../utility';

export const getBlockItemContentPricingTableEditControls = (
  pricingType: string,
  blockContent: IBlockContentPricing
): any => {
  switch (pricingType) {
    case EPricingType.SERVICE_PRICING:
      return {
        price: {
          elementType: "input",
          elementConfig: {
            label: "Monthly price",
            placeholder: "Monthly price",
            type: "number"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.price || 0
        },
        initialPayment: {
          elementType: "input",
          elementConfig: {
            label: "Initial payment (€)",
            placeholder: "Initial payment",
            type: "number"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.initialPayment || 0
        },
        terminationPeriod: {
          elementType: "input",
          elementConfig: {
            label: "Termination period (months)",
            placeholder: "Termination period",
            type: "number"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.terminationPeriod || 0
        }
      };
    case EPricingType.FIXED_PROJECT_PRICING:
      return {
        price: {
          elementType: "input",
          elementConfig: {
            label: "Fixed price",
            placeholder: "Fixed price",
            type: "number"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.price || 0
        },
        estimatedDeliveryDate: {
          elementType: "datepicker",
          elementConfig: {
            label: "Estimated delivery date",
            placeholder: "Estimated delivery date"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.estimatedDeliveryDate ? stringToDate(blockContent.estimatedDeliveryDate) : null
        }
      };
    case EPricingType.DAILY_RATE:
        return {
          price: {
            elementType: "input",
            elementConfig: {
              label: "Day rate",
              placeholder: "Day rate",
              type: "number"
            },
            validation: {
              required: true
            },
            valid: false,
            touched: false,
            value: blockContent.price || 0
          },
          estimatedAmountText: {
            elementType: "input",
            elementConfig: {
              label: "Estimated days",
              placeholder: "Estimated days",
              type: "text"
            },
            validation: {
              required: true
            },
            valid: false,
            touched: false,
            value: blockContent.estimatedAmountText || ""
          }
        };
    case EPricingType.HOURLY_RATE:
      return {
        price: {
          elementType: "input",
          elementConfig: {
            label: "Hourly rate",
            placeholder: "Hourly rate",
            type: "number"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.price || 0
        },
        estimatedAmountText: {
          elementType: "input",
          elementConfig: {
            label: "Estimated hours",
            placeholder: "Estimated hours",
            type: "text"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.estimatedAmountText || ""
        }
      };
    case EPricingType.OTHER_PRICING:
      return {
        priceOther: {
          elementType: "input",
          elementConfig: {
            label: "Other pricing",
            placeholder: "Other pricing",
            type: "text"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.priceOther || ""
        }
      };
    default:
      return null;
  }
};
