import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IBlockContentPrivate, IProduct } from "../../../../../../../../interfaces";

import { ETaxTypes } from "../../../../../../../../enums";

import { IBlockContentEditItem } from "../../../BlockContentEdit";

import { Button, Label, Container, Row, Col, Input, InputGroup, InputGroupText } from 'reactstrap'

import CustomDialog from "../../../../../../../../components/UI/CustomDialog/CustomDialog";


import IconButton from "../../../../../../../../components/UI/IconButton/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReadCSVModal from "../../../../../../../../components/ReadCSVModal/ReadCSVModal";

//import Section2 from "./Section2Edit";
import Lisatyot from "./Lisatyot";

import { readString } from "react-papaparse";

import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  controlsToFormGroups,
} from "../../../../../../../../shared/utility";

import { summa, summa2, bcparser, empty, getKuukausikerroin } from "./functions";

import classes from "./BlockContentProducts3Edit.module.scss";

/*
const empty = {kuukausikerroin:0, tila:0, kokokerroin:0, likakerroin:0, kpl:1, kerros:1};

const bcparser = (blockContent:any) => {
	const json = blockContent.json && JSON.parse(blockContent.json) ||
	 	{
	 		section1: [empty],
	 		section2: [],
	 		section3: {},
		};

	if(!("section1" in json)) {
		const s=Object.keys(json).length === 0?[]:json;

		return { section1: s, section2: [], section3:{info:"", alennus:0} };
	}

	return {section3:{info:"", alennus:0},...json};
}
*/

const BlockContentProductsEdit: React.ForwardRefExoticComponent<IBlockContentEditItem> = forwardRef((props, ref) => {
  const blockContent = props.blockContent as IBlockContentPrivate;
  
  //const [selected, setSelected] = useState<{[id:string]:any}[]>(blockContent.json && JSON.parse(blockContent.json) || empty);
  const [selected, setSelected] = useState<{[id:string]:any}[]>(bcparser(blockContent).section1);
  const [selected2, setSelected2] = useState<{[id:string]:any}[]>(bcparser(blockContent).section2);
  const [selected3, setSelected3] = useState<{[id:string]:any}>(bcparser(blockContent).section3);
  const [selected4, setSelected4] = useState<{[id:string]:any}>(bcparser(blockContent).section4);
  const [selected5, setSelected5] = useState<{[id:string]:any}>(bcparser(blockContent).section5);

  //const [saveSettings, setSaveSettings] = useState<{[id:string]:any}>(JSON.parse(blockContent.settings));
  /*
  const updateControls = (data:IBlockContentPrivate) => ({
    controls: {
  
    },

  })
	*/
//console.log("BC",selected4);
  const round = (x:any) => {
  	if(isNaN(x)) return 1;
	  return (Math.round(parseFloat(x)*100)/100).toFixed(2);
  }

  const float = (x:any) => {
  	if(isNaN(x)) return 0;
  	return parseFloat(x);
  }

  const getData = () => {
		return Object.assign(blockContent, {json: JSON.stringify({section1:selected,section2:selected2, section3:selected3, section4:selected4, section5:selected5}), settings: JSON.stringify(customJson)});
	}

	const [controlsState, setControlsState] = useState(null);

	const [asetukset, setAsetukset] = useState<{[id:string]:any}>({tilat:[]});

	const [loadedSettings, setLoadedSettings] = useState<{[id:string]:any}>({tilat:[]});

	const [alv, setAlv] = useState<number>(24.0);

	const pakettiKerroin = (tila:any) => {
		const conv:{[id:string]:string} = {"A":"Täsmä","B":"Puhdas","C":"Loisto"};
		const p = conv[customJson.paketti];
		if(tila.paketti && p in tila.paketti) {
			return tila.paketti[p];
		}
		return 1;
	}

	const updateSelected4 = (paketti:string) => {
		const settings = JSON.parse(blockContent.settings);

		let kuvaukset = settings.palvelukuvaukset_puhdas;
		if(paketti === "C") kuvaukset = settings.palvelukuvaukset_loisto;
		else if(paketti === "A") kuvaukset = settings.palvelukuvaukset_tasma;

		const _palvelukuvaukset:{[id:string]:any} = {};
		let key:string = null;
		(kuvaukset || "").split("\n").forEach((rowstring:any)=> {
			const x = rowstring.split(",");
			if(x.length === 1) { key = null; }
			else if(rowstring.match(/,/g).length === rowstring.length) { key = null; }
			else if(x[0][0] !== "#") {
				if(key == null) {
					key = x[0];
					_palvelukuvaukset[key] = {st:x[1],lkm:0,rows:[]};
				}
			}

			if(key !== null) {
				_palvelukuvaukset[key].rows.push([x[3],x[4],x[5]]);
			}
			
		});
		setSelected4(_palvelukuvaukset);

	}

	useEffect(() => {
		if(blockContent.settings) {
			if(asetukset.tilat.length === 0)
			{
			const settings = JSON.parse(blockContent.settings);

			setAlv(settings.alv || 24.0);

			const mymap = (x:any) => {
				 const [key,value] = x.split("=");
				 return [key.trim(),round(value)];
			}

			const mymap2 = (resp:any, x:any) => {
				 const [key,value] = x.split("=");
				 return {...resp, [key.trim()]:round(value)};
			}

			const x:any = {header:false, skipEmptyLines:true};
			const d:any=readString(settings.asetukset,x);
			const _tilat = d.data.reduce((resp:any, ob:any, index:number)=>{
				//console.log("__",ob[5].split(";").map(mymap));
				const _tila = {
					nimi: ob[0],
					standardiaika : float(ob[1]),
					kokokerroin: ob[2].split(";").map(mymap),
					likakerroin: ob[3].split(";").map(mymap),
					tuntihinta: float(ob[4]),
					lisatyot: ob[5].split(";").map(mymap).sort((a:any,b:any)=>a[0]>b[0]?1:-1),
					lisatyokerroin: ob[6].split(";").map(mymap),
					lisahinta: float(ob[7]),
					index: index,
					kerrokset: parseInt(ob[8]),
					info1: ob[9],
					info2: ob[10],
					paketti:ob[6].split(";").reduce(mymap2,{}),
				}
				return [...resp,_tila];
			},[]);

//console.log(settings);
//console.log(selected4);
//console.log(Object.keys(selected4).length);
			if(Object.keys(selected4).length == 0) {
				updateSelected4("A");
				/*
				const _palvelukuvaukset:{[id:string]:any} = {};
				let key:string = null;
				(settings.palvelukuvaukset_loisto || "").split("\n").forEach((rowstring:any)=> {
					const x = rowstring.split(",");
					if(x.length === 1) { key = null; }
					else if(rowstring.match(/,/g).length === rowstring.length) { key = null; }
					else if(x[0][0] !== "#") {
						if(key == null) {
							key = x[0];
							_palvelukuvaukset[key] = {st:x[1],lkm:0,rows:[]};
						}
					}

					if(key !== null) {
						_palvelukuvaukset[key].rows.push([x[3],x[4],x[5]]);
					}
					
				});
				setSelected4(_palvelukuvaukset);
				*/
			}

			setAsetukset({tilat:_tilat.sort(sortnimi)});
			setLoadedSettings({tilat:_tilat.sort(sortnimi)});
			}
			//setSelected(JSON.parse(blockContent.json));
		}
	},[blockContent.settings]);

  useImperativeHandle(ref, () => ({
    getData
  }));

/*  
  const [customJson, setCustomJson] = useState<{[id:string]:any}>(blockContent.settings && JSON.parse(blockContent.settings) || {
		asetukset: "",
		kuukausikertoimet: [],
		tarjous: "A",
	});
*/
	const [customJson, setCustomJson] = useState<{[id:string]:any}>({
		asetukset: "",
		kuukausikertoimet: [],
		tarjous: "B",
		paketti: "A",
		siirtyma: 0,
		siirtymaeurh: 30,
		siirtymakerroin: -1,
		...(blockContent.settings && JSON.parse(blockContent.settings))
	});
  
  useEffect(()=>{
  	if(customJson.paketti=="") {
  		onChangeSettings("paketti", "A")
  	}
  },[customJson]);

  useEffect(() => {
  	if(props.onChange) {
			props.onChange(getData())
		}
    // eslint-disable-next-line
  }, [selected, selected2, selected3, selected4, selected5, customJson]);
  

  const updatePaketti = (value:any) => {
			let _sel = [...selected];
			let _tilat = asetukset.tilat;

			/*
			if(value === "A") {
				// Automaattinen likaisuus kerroin Täsmä 1,44 ja Vaativa Täsmä kerroin 1,64

				
				_sel.forEach((s:any)=>{
					s.likakerroin = 0;
					if(isDisabled(s.kuukausikerroin,"A"))
						s.kuukausikerroin = -1; //customJson.kuukausikertoimet.findIndex((x:any)=>x[0] === "1 kertaa/viikko");
				});

				const __tilat = [...asetukset.tilat];
				_tilat = __tilat.reduce((resp:any, s:any) => {
					resp.push({...s, likakerroin:[["Täsmä","1.44"],["Vaativa Täsmä","1.64"]]});
					return resp;
				},[]);

			}
			else if(value === "B") {
				// Automaattinen likaisuus normaali ja valittavana -> vaativa Puhdas
				
				_sel.forEach((s:any,index:number)=>{
					s.likakerroin = 0;
					if(isDisabled(s.kuukausikerroin,"B"))
						s.kuukausikerroin = -1; //customJson.kuukausikertoimet.findIndex((x:any)=>x[0] === "1 kertaa/viikko");
				});

				const __tilat = [...loadedSettings.tilat];
				_tilat = __tilat.reduce((resp:any, s:any) => {
					//resp.push({...s, likakerroin:s.likakerroin.filter((x:any)=>["Normaali","Vaativa Puhdas"].includes(x[0]))});
					if(s.nimi == "Tekninen tila") {
						resp.push({...s, likakerroin:[["Normaali","1.44"],["Vaativa Puhdas","1.64"]]});
					}
					else {
						resp.push({...s, likakerroin:[["Normaali","1"],["Vaativa Puhdas","1"]]});
					}
					return resp;
				},[]);

			}
			else if(value === "C") {
				// Automaattinen likaisuus  Loisto normaali ja valittavana -> vaativa Loisto 1,2
				
				_sel.forEach((s:any)=>{
					s.likakerroin = 0;
					if(isDisabled(s.kuukausikerroin,"C"))
						s.kuukausikerroin = -1; //customJson.kuukausikertoimet.findIndex((x:any)=>x[0] === "1 kertaa/viikko");
				});

				const __tilat = [...loadedSettings.tilat];
				_tilat = __tilat.reduce((resp:any, s:any) => {
					//resp.push({...s, likakerroin:s.likakerroin.filter((x:any)=>["Normaali","Vaativa Loisto"].includes(x[0]))});
					if(s.nimi == "Tekninen tila") {
						resp.push({...s, likakerroin:[["Normaali","1.44"],["Vaativa Loisto","1.64"]]});
					}
					else {
						resp.push({...s, likakerroin:[["Normaali","1.15"],["Vaativa Loisto","1.2"]]});
					}

					return resp;
				},[]);

			}
			else {
				_tilat = loadedSettings.tilat;
			}
			*/
			_tilat = loadedSettings.tilat;


			return [_sel,_tilat.sort(sortnimi)];
			/*
			setSelected(_sel);
			setAsetukset({tilat:_tilat.sort(sortnimi)});
			*/
  }


	const onChangeSettings = (t:string, value:any) => {
		const settings = JSON.parse(blockContent.settings);

		if(t==="tarjous") {
			setCustomJson({...customJson, tarjous:value}); //{...selected, tila:e});	
		}
		else if(t==="siirtyma") {
			const v = value*100-Math.round(value*100);
			setCustomJson({...customJson, siirtyma:v===0?value:Math.round(value*100)/100}); //{...selected, tila:e});	
		}
		else if(t==="siirtymaeurh") {
			setCustomJson({...customJson, siirtymaeurh:value}); //{...selected, tila:e});	
		}
		else if(t==="siirtymakerroin") {
			setCustomJson({...customJson, siirtymakerroin:value}); //{...selected, tila:e});	
		}

		else if(t==="paketti") {
			let sk = customJson.siirtymakerroin;
			if(isDisabled(sk,value)) sk = -1;
			setCustomJson({...customJson, paketti:value, siirtymakerroin:sk}); //{...selected, tila:e});	
			//Tekninen tila, puhdas paketin ajaksi sama kuin Täsmässä ja Loistossa (0,18 h)

			let ret = updatePaketti(value);
			ret[0].forEach((s:any)=>{
				s.likakerroin = 0;
				if(isDisabled(s.kuukausikerroin,value))
					s.kuukausikerroin = -1; //customJson.kuukausikertoimet.findIndex((x:any)=>x[0] === "1 kertaa/viikko");
			});


			setSelected(ret[0]);
			updateSelected4(value);
			//setSelected4({});
			setAsetukset({tilat:ret[1]});

			

/*
			if(value === "A") {
				// Automaattinen likaisuus kerroin Täsmä 1,44 ja Vaativa Täsmä kerroin 1,64

				let _sel = [...selected];
				
				_sel.forEach((s:any)=>{
					s.likakerroin = 0;
					if(isDisabled(s.kuukausikerroin,"A"))
						s.kuukausikerroin = -1; //customJson.kuukausikertoimet.findIndex((x:any)=>x[0] === "1 kertaa/viikko");
				});

				const __tilat = [...asetukset.tilat];
				const _tilat = __tilat.reduce((resp:any, s:any) => {
					resp.push({...s, likakerroin:[["Täsmä","1.44"],["Vaativa Täsmä","1.64"]]});
					return resp;
				},[]);

				setAsetukset({tilat:_tilat.sort(sortnimi)});
				setSelected(_sel);
			}
			else if(value === "B") {
				// Automaattinen likaisuus normaali ja valittavana -> vaativa Puhdas

				let _sel = [...selected];
				
				_sel.forEach((s:any,index:number)=>{
					s.likakerroin = 0;
					if(isDisabled(s.kuukausikerroin,"B"))
						s.kuukausikerroin = -1; //customJson.kuukausikertoimet.findIndex((x:any)=>x[0] === "1 kertaa/viikko");
				});

				const __tilat = [...loadedSettings.tilat];
				const _tilat = __tilat.reduce((resp:any, s:any) => {
					//resp.push({...s, likakerroin:s.likakerroin.filter((x:any)=>["Normaali","Vaativa Puhdas"].includes(x[0]))});
					if(s.nimi == "Tekninen tila") {
						resp.push({...s, likakerroin:[["Normaali","1.44"],["Vaativa Puhdas","1.64"]]});
					}
					else {
						resp.push({...s, likakerroin:[["Normaali","1"],["Vaativa Puhdas","1"]]});
					}
					return resp;
				},[]);

				setAsetukset({tilat:_tilat.sort(sortnimi)});
				setSelected(_sel);

			}
			else if(value === "C") {
				// Automaattinen likaisuus  Loisto normaali ja valittavana -> vaativa Loisto 1,2

				let _sel = [...selected];
				
				_sel.forEach((s:any)=>{
					s.likakerroin = 0;
					if(isDisabled(s.kuukausikerroin,"C"))
						s.kuukausikerroin = -1; //customJson.kuukausikertoimet.findIndex((x:any)=>x[0] === "1 kertaa/viikko");
				});

				const __tilat = [...loadedSettings.tilat];
				const _tilat = __tilat.reduce((resp:any, s:any) => {
					//resp.push({...s, likakerroin:s.likakerroin.filter((x:any)=>["Normaali","Vaativa Loisto"].includes(x[0]))});
					if(s.nimi == "Tekninen tila") {
						resp.push({...s, likakerroin:[["Normaali","1.44"],["Vaativa Loisto","1.64"]]});
					}
					else {
						resp.push({...s, likakerroin:[["Normaali","1.15"],["Vaativa Loisto","1.2"]]});
					}

					return resp;
				},[]);

				setAsetukset({tilat:_tilat.sort(sortnimi)});
				setSelected(_sel);

			}
			else {
				setAsetukset({...asetukset, tilat:loadedSettings.tilat});

				// const _tilat = __tilat.reduce((resp:any, s:any, index:number) => {
				// 	resp.push({...s, likakerroin:});
				// 	return resp;
				// },[]);

			}
*/
		}

	}

	const isDisabled = (index:number, paketti:any = customJson.paketti) => {
		if(index>-1) {
			if(paketti === "A") { // täsmä
				if([//"joka toinen kk",
						"1 kertaa/viikko",
						"1 kertaa/kk",
						"2 kertaa/kk",
						"la-su",
						"2 kertaa vuosi",
						"6 kertaa vuosi",
						"4 kertaa vuosi",
						"1 kerta vuosi",

						"1 x vk",
						"1 x kk",
						"2 x kk",
						"la-su",
						"2 x vuosi",
						"6 x vuosi",
						"4 x vuosi",
						"1 x vuosi",

						].includes(customJson.kuukausikertoimet[index][0])) {
					return false;
				}
				return true;
			}
			else if(paketti === "B") { // puhdas
				// if([//"joka toinen kk",
				// 	  "4 kertaa/viikko",
				// 		"joka toinen pv",
				// 		"5 kertaa/viikko",
				// 		"6 kertaa/viikko",
				// 		"7 kertaa/viikko",
				// 		"2 kertaa vuosi",
				// 		"la-su"].includes(customJson.kuukausikertoimet[index][0])) {
				// 	return true;
				// }
			}
			else if(paketti === "C") { // loisto
				// if(["2 kertaa vuosi","la-su"].includes(customJson.kuukausikertoimet[index][0])) {
				// 	return true;
				// }
			}
		}
		return false;
	}

	const onChangeSelected = (index:number, t:string, e:number) => {
		/*
		if(t==="liikevaihtoluokka") {
			let p=selected.paketti;
			if(p === "basic" && customJson.paketit[p][e] === null) { p="core";}
			setSelected({...selected, liikevaihtoluokka:e, paketti:p});
		}
		else if(t==="paketti") {
			let kf = 15;
			if(e!=="premium") { kf = 15-4; }			

			setSelected({...selected, paketti:e, koulutus:selected.koulutus&kf});	
		}
		else if(t==="palkansaajia") {
			let kf = 15;
			if(e===0) { kf = 15-8; }			
			setSelected({...selected, palkansaajia:e, koulutus:selected.koulutus&kf});	
		}
		*/
		
		let _sel = [...selected];

		if(t==="tila") {
			let ret = updatePaketti(customJson.paketti);

			_sel[index] = {..._sel[index], tila:e, kokokerroin:0, likakerroin:0, kerros:1, kpl:1, tuntihinta:ret[1][e].tuntihinta };

			//setSelected(ret[0]);
			setAsetukset({tilat:ret[1]});


			setSelected(_sel); //{...selected, tila:e});	
		}
		else if(t==="kuukausikerroin") {
			_sel[index] = {..._sel[index], kuukausikerroin:e};
			setSelected(_sel); //{...selected, kuukausikerroin:e});	
		}
		else if(t==="kokokerroin") {
			_sel[index] = {..._sel[index], kokokerroin:e};
			setSelected(_sel); //{...selected, kokokerroin:e});	
		}
		else if(t==="likakerroin") {
			_sel[index] = {..._sel[index], likakerroin:e};
			setSelected(_sel); //{...selected, likakerroin:e});	
		}

		else if(t==="kpl") {
			_sel[index] = {..._sel[index], kpl:e};
			setSelected(_sel); //{...selected, kpl:e});	
		}
		else if(t==="tuntihinta") {
			_sel[index] = {..._sel[index], tuntihinta:e};
			setSelected(_sel); //{...selected, kpl:e});	
		}

		else if(t==="kerros") {
			_sel[index] = {..._sel[index], kerros:e};
			setSelected(_sel); //{...selected, kerros:e});	
		}
	}

	const addRow = () => {
		setSelected([...selected,empty]);
	}

	const totalh = () => {
		return selected.reduce((resp,ob:any)=>{
			const tila = asetukset.tilat[ob.tila];
			if(!tila) return null;
			return resp + ob.kpl *
				  	ob.kerros *
				  	
				  	//customJson.kuukausikertoimet[ob.kuukausikerroin][1] *
				  	getKuukausikerroin(customJson.kuukausikertoimet,ob.kuukausikerroin)[1] *
				  	
				  	tila.standardiaika *
				  	tila.kokokerroin[ob.kokokerroin][1] *
				  	tila.likakerroin[ob.likakerroin][1]
				  	* pakettiKerroin(tila)
		},0);
	}

	const total = () => {
		return selected.reduce((resp,ob:any)=>{
			const tila = asetukset.tilat[ob.tila];
			if(!tila) return null;
			return resp + ob.kpl *
				  	ob.kerros *
				  	
				  	//customJson.kuukausikertoimet[ob.kuukausikerroin][1] *
				  	getKuukausikerroin(customJson.kuukausikertoimet,ob.kuukausikerroin)[1] *
				  	
				  	tila.standardiaika *
				  	tila.kokokerroin[ob.kokokerroin][1] *
				  	tila.likakerroin[ob.likakerroin][1]
				  	* pakettiKerroin(tila)
				  	* ob.tuntihinta;

		},0);
	}

	const getValue = (a:any, i:number,pos:number) => {
		if(i in a) {
			if(pos in a[i]) {
				return a[i][pos];
			}
		}
		return 0;
	}

	useEffect(()=> {
		if(asetukset && asetukset.tilat !== null && Object.keys(selected4).length > 0) {
			const _sel4 = Object.keys(selected4).reduce((resp:any, key:string)=>{
				return {...resp,[key]:{...selected4[key],lkm:0}};
			},{});

			selected.forEach((o:any)=>{
				const tila = asetukset.tilat[o.tila];
				if(tila) {
					if(Object.keys(_sel4).includes(tila.nimi)) {
						//_sel4[tila.nimi].lkm +=o.kpl*o.kerros;						
						_sel4[tila.nimi].lkm +=o.kpl;						

						let kk = "-";
						let or = -1;
						if(o.kuukausikerroin !== -1) {
							kk = customJson.kuukausikertoimet[o.kuukausikerroin][0];
							or = customJson.kuukausikertoimet[o.kuukausikerroin][1];
						}
						
						_sel4[tila.nimi].tiheys =kk;
						_sel4[tila.nimi].order =or;
					}
					else {
						//_sel4[tila.nimi]={"lkm":o.kpl*o.kerros,"st":1,"rows":[["","",""]]};
						_sel4[tila.nimi]={"lkm":o.kpl,"st":1,"rows":[["","",""]]};
					}
				}
			});
			
			//console.log(selected4);
			setSelected4(_sel4);		
		}
	},[selected,asetukset]);

	const deleteIndex = (index:number) => {
		const _selected = [...selected];
		_selected.splice(index,1);
		setSelected(_selected);
	}
	//const summaf = summa(setup,data,tilat);
	//const summa2f = summa2(data2);

	const sortnimi = (a:any, b:any) => {
		return a.nimi.toLowerCase()>b.nimi.toLowerCase()?1:-1;
	}

	const updateListyo = (key:string, key2:string, value:string) => {
		const _sel5 = {...selected5};
		_sel5[key][key2].info = value;
		setSelected5(_sel5);
	}

	useEffect(()=>{
		const _sel5 = {...selected5};
		Object.keys(_sel5).forEach((key1:any) => {
			Object.keys(_sel5[key1]).forEach((key2:string)=>{_sel5[key1][key2].visible=false;});
		});

		selected2.forEach((_sel2) => {
			if(!(_sel2.tila in _sel5)) {
				_sel5[_sel2.tila] = {[_sel2.tyo]:{info:"",visible:true}};
			}
			if(!(_sel2.tyo in _sel5[_sel2.tila])) {
				_sel5[_sel2.tila][_sel2.tyo] = {info:"",visible:true};
			}

			_sel5[_sel2.tila][_sel2.tyo].visible=true;	
		});

		setSelected5(_sel5);
	},[selected2]);

	const updatePalvelu = (key:string, index:number, col:number, value:string) => {
		const _sel4 = {...selected4};

		if(col === -1) {
			_sel4[key].st = value;
		}
		else {
			_sel4[key].rows[index][col] = value;
		}
		setSelected4(_sel4);
	}

	const deletepkIndex = (key: string, index:number) => {
		const _sel4 = {...selected4};
		_sel4[key].rows.splice(index,1);
		setSelected4(_sel4);
	}

	const addpkIndex = (key: string) => {
		const _sel4 = {...selected4};
		_sel4[key].rows.push(["","",""]);
		setSelected4(_sel4);

	}

  return (
		<React.Fragment>
			<Label>{blockContent.name}</Label>
  
  	<Container>

  	<Row>
  	<Col>
  	Valitse paketti:
  	<Input type="select" value={customJson.paketti} onChange={(e:any)=>onChangeSettings("paketti", e.target.value)}>
  		{/*<option value="">Normaali</option>*/}
  		<option value="A">Täsmä</option>
  		<option value="B">Puhdas</option>
  		<option value="C">Loisto</option>

  	</Input>
  	</Col>
  	</Row>
  	<br/>

{/* Piilotetaan
  	<Row>
  	<Col>
  	<Input type="select" value={customJson.tarjous} onChange={(e:any)=>onChangeSettings("tarjous", e.target.value)}>
  		<option value="A">Kuukausihinta koko paketille</option>
  		<option value="B">Lisäpalvelut erillisveloituksena</option>
  	</Input>
  	</Col>
  	</Row>
  	<br/>
*/}

  	<h5>Palvelut:</h5>
  	{selected.map((xselected, index) => {
			const tila = asetukset.tilat[xselected.tila] || {
				nimi: "",
				standardiaika : 0,
				kokokerroin: [["",1]],
				likakerroin: [["",1]],
				tuntihinta: 0,
				lisatyot: [["",1]],
				lisatyokerroin: [["",1]],
				lisahinta: 0,
				kerrokset: 1,
				info1: "",
				info2: "",
			};

			return (<div key={index}>
  			{tila.info1}
		  	<Row style={{"marginBottom":5, "paddingBottom":5}}>
		  		<Col>
		  			<InputGroup>
			  			<InputGroupText>Tila</InputGroupText>
						  	<Input className="text-right" type="select" value={xselected.tila} onChange={(e:any)=>onChangeSelected(index, "tila", parseInt(e.target.value))}>
						  		<option value={-1}>Valitse tila</option>
						  		{asetukset.tilat.map((lv:any, index:number) => (
						  				<option key={index} value={index}>{lv.nimi}</option>
						  			))}
						  	</Input>  			
						</InputGroup>

				  	<InputGroup>
				  		<InputGroupText>Kpl</InputGroupText>
							<Input className="text-right" type="number" value={xselected.kpl} onChange={(e:any)=>onChangeSelected(index, "kpl", Math.max(1,parseInt(e.target.value)))}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>Kerrokset</InputGroupText>
					  	<Input disabled={tila.kerrokset === 1} className="text-right" type="number" value={xselected.kerros} onChange={(e:any)=>onChangeSelected(index, "kerros", Math.min(tila.kerrokset,Math.max(1,parseInt(e.target.value))))}/>  			
						</InputGroup>

		  		</Col>

		  		<Col className="col-5">
		  			<InputGroup>
			  			<InputGroupText>Kerrat</InputGroupText>
						<Input type="select" className="text-right"
							value={xselected.kuukausikerroin} 
							onChange={(e:any)=>onChangeSelected(index, "kuukausikerroin", parseInt(e.target.value))}>
				  		<option value={-1}>Valitse tiheys</option>
			  			{customJson.kuukausikertoimet.map((lv:any, index:number) => (
			  				<>{isDisabled(index)?null
			  				:<option disabled={isDisabled(index)} key={index} value={index}>{lv[0]}</option>}
			  				</>
			  			))}

			  			{/*customJson.kuukausikertoimet.map((lv:any, index:number) => (
			  				<option key={index} value={index}>{lv[0]}</option>
			  			))*/}


			  		</Input>
						</InputGroup>

		  			<InputGroup>
			  			<InputGroupText>Koko</InputGroupText>
		  			<Input type="select" className="text-right"
		  				readOnly={tila.kokokerroin.length<2}
							value={xselected.kokokerroin} 
							onChange={(e:any)=>onChangeSelected(index, "kokokerroin", parseInt(e.target.value))}>
				  			{tila.kokokerroin.map((lv:any, index:number) => (
				  				<option key={index} value={index}>{lv[0]}</option>
				  			))}
				  	</Input>
						</InputGroup>

		  			<InputGroup>
			  			<InputGroupText>Lika</InputGroupText>
						<Input type="select" className="text-right"
							readOnly={tila.likakerroin.length<2}
							value={xselected.likakerroin} 
							onChange={(e:any)=>onChangeSelected(index, "likakerroin", parseInt(e.target.value))}>
			  			{tila.likakerroin.map((lv:any, index:number) => (
			  				<option key={index} value={index}>{lv[0]}</option>
			 				))}
			  		</Input>		
						</InputGroup>

		  		</Col>

		  		<Col className="col-3 text-right">
						<InputGroup>
							<Input className="text-right" type="number" value={xselected.tuntihinta} onChange={(e:any)=>onChangeSelected(index, "tuntihinta", e.target.value)}/>
							<InputGroupText style={{"width":50}}>€/h</InputGroupText>
						</InputGroup>

						<InputGroup>					
							<Input readOnly className="text-right" value=  		
				  			{round(xselected.kpl *
						  	xselected.kerros *
						  	getValue(customJson.kuukausikertoimet,xselected.kuukausikerroin,1) *
						  	tila.standardiaika *
						  	getValue(tila.kokokerroin,xselected.kokokerroin,1) *
						  	getValue(tila.likakerroin,xselected.likakerroin,1) * pakettiKerroin(tila))}/>
							<InputGroupText style={{"width":50}}>h</InputGroupText>
						</InputGroup>

						<InputGroup>					
							<Input readOnly className="text-right" value=  		
						  	{round(xselected.kpl *
						  	xselected.kerros *
						  	
						  	getValue(customJson.kuukausikertoimet,xselected.kuukausikerroin,1) *
						  	
						  	tila.standardiaika *
						  	getValue(tila.kokokerroin,xselected.kokokerroin,1) *
						  	getValue(tila.likakerroin,xselected.likakerroin,1) *
						  	pakettiKerroin(tila) *
						  	xselected.tuntihinta)} />
							<InputGroupText style={{"width":50}}>€</InputGroupText>
						</InputGroup>

						<Button size="sm" onClick={()=>{deleteIndex(index)}}>
		        	<FontAwesomeIcon icon={faTrash} className="fa-sm"/>
	        	</Button>
  	        
		  		</Col>

		  	</Row>
		  	</div>
  	)})}

  	<Button onClick={addRow}>Lisää rivi</Button>

  	<Row>
  		<Col>Yhteensä:</Col>
  		<Col className="text-right">
  			{round(totalh())} h<br/>
  			Alv 0% {round(total())} €<br/>
  			Alv {alv}% {round(total()*(alv/100+1))} €
  		</Col>
  	</Row>

{/* Piilotetaan
		<h5>Lisätyöt:</h5>
  	<Section2 setSelected={setSelected2} selected={selected2} settings={JSON.parse(blockContent.settings)}/>
*/}
		<h5>Lisätyöt:</h5>
  	<Lisatyot setSelected={setSelected2} selected={selected2} settings={JSON.parse(blockContent.settings)}/>


  	
  	<Row>
			<Col>
				Siirtymäaika:
				<Input step={0.01} type="number" value={customJson.siirtyma} onChange={(e:any)=>onChangeSettings("siirtyma", e.target.value)}/>
			</Col>
			<Col>
  			Kerrat:
				<Input type="select" className="text-right"
					value={customJson.siirtymakerroin} 
					onChange={(e:any)=>onChangeSettings("siirtymakerroin", parseInt(e.target.value))}>
		  		<option value={-1}>Valitse tiheys</option>

	  			{customJson.kuukausikertoimet.map((lv:any, index:number) => (
	  				<>{isDisabled(index)?null
	  				:<option disabled={isDisabled(index)} key={index} value={index}>{lv[0]}</option>}
	  				</>
	  			))}

	  			{/*customJson.kuukausikertoimet.map((lv:any, index:number) => (
	  				<option key={index} value={index}>{lv[0]}</option>		  				
	  			))*/}
	  		</Input>

			</Col>

			<Col>
				Siirtymäaika (€/h):
				<Input type="number" value={customJson.siirtymaeurh} onChange={(e:any)=>onChangeSettings("siirtymaeurh", e.target.value)}/>
			</Col>
		</Row>
		<Row>
			<Col className="text-right">
				Alv 0% {round(customJson.siirtyma * customJson.siirtymaeurh * (customJson.siirtymakerroin!==-1?customJson.kuukausikertoimet[customJson.siirtymakerroin][1]:0))} €<br/>
				Alv {alv}% {round(customJson.siirtyma * customJson.siirtymaeurh * (customJson.siirtymakerroin!==-1?customJson.kuukausikertoimet[customJson.siirtymakerroin][1]:0) * (alv/100+1))} €<br/>
			</Col>
		</Row>
		

		<br/>

  	{selected3 && <>
  		Lisätietoja:
			<Input type="textarea" value={selected3.info} onChange={(e:any)=>setSelected3({...selected3, info:e.target.value})}/>

			Alennus %:
			<InputGroup>		
				<Input type="number" value={selected3.alennus} onChange={(e:any)=>setSelected3({...selected3, alennus:e.target.value})}/>
				
  				<InputGroupText>
  					<Input addon type="checkbox" checked={selected3.showalennus} onChange={(e:any)=>setSelected3({...selected3, showalennus:e.target.checked})}/>
  					<span style={{"paddingLeft":10}}>Näytä alennus</span>
  				</InputGroupText>
  			

			</InputGroup>			
		</>}
  	

		Palvelukuvaus ylläpitosiivous:
		<table className={classes.palvelukuvaukset}>
			<thead>
			<tr>
				<td>TILA</td>
				<td>ST</td>
				<td>KPL</td>
				<td>SIIVOUS</td>
				<td>TYÖTEHTÄVÄ</td>
				<td>HUOM!</td>
			</tr>
			</thead>
			<tbody>
			{Object.keys(selected4).map((key:string)=>{
				const data = selected4[key];
				
				if(data.lkm>0) {
				return (<>
					{data.rows.map((row:any, index:number)=> {
						return (<tr>
							{index === 0 ? <td>{key}</td>: <td></td>}
							{index === 0 ? <td>
								<input style={{"width":15}} className={classes.myinput} value={data.st} onChange={(e:any)=>updatePalvelu(key, index, -1, e.target.value)}/>
							</td>: <td></td>}
							{index === 0 ? <td>{data.lkm}</td>: <td></td>}
							<td>{row[0].toLowerCase() === "siivoustiheys"?<>{data.tiheys}</>:<input className={classes.myinput} value={row[0]} onChange={(e:any)=>updatePalvelu(key, index, 0, e.target.value)}/>}</td>
							<td><textarea className={classes.myinput} value={row[1]} onChange={(e:any)=>updatePalvelu(key, index, 1, e.target.value)}>{row[1]}</textarea></td>
							<td><textarea className={classes.myinput} value={row[2]} onChange={(e:any)=>updatePalvelu(key, index, 2, e.target.value)}>{row[2]}</textarea></td>

							{index === 0 ? <td></td>:
							<td>
								<Button size="sm" color="link" onClick={()=>{deletepkIndex(key,index)}}>
		        			<FontAwesomeIcon icon={faTrash} className="fa-sm"/>
	        			</Button>
							</td>}

						</tr>);
					})}

					<tr>
						<td colSpan={6}></td>
						<td>
							<Button size="sm" color="link" onClick={()=>{addpkIndex(key)}}>
		        		<FontAwesomeIcon icon={faPlus} className="fa-sm"/>
	        		</Button>						
						</td>
					</tr>
				</>);
				}

			})}
			</tbody>
		</table>
{/*
		Lisätyöt:
		<table className={classes.palvelukuvaukset}>
			<thead>
			<tr>
				<td>TILA</td>
				<td>TYÖ</td>
				<td>HUOM!</td>
			</tr>
			</thead>
			<tbody>
			{Object.keys(selected5).map((key:string)=>{
				if(key in asetukset.tilat) {
					return Object.keys(selected5[key]).map((key2:string)=>{
						return (<>
							{!selected5[key][key2].visible?null
								:<tr>
									<td><b>{asetukset.tilat[key].nimi}</b></td>
									<td>{(asetukset.tilat[key].lisatyot[key2] || [""])[0]}</td>
									<td><textarea className={classes.myinput} value={selected5[key][key2].info} onChange={(e:any)=>updateListyo(key, key2, e.target.value)}>{selected5[key][key2].info}</textarea></td>
								</tr>}
						</>);
					});
				}
				return null;
			})}
			</tbody>
		</table>
*/}

  	</Container>
  	
    </React.Fragment>
  );
});

export default BlockContentProductsEdit;
