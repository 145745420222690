import {
  IOfferIntegrationData,
} from "../interfaces";


export const integrationParser = (data:Array<IOfferIntegrationData>) => {
  const typeData=(data || []).reduce((resp:any,ob:IOfferIntegrationData)=>{
    resp={...resp,
      [ob.type]: {
        ...(resp[ob.type] || {}),
        [ob.name]:ob.value,
      }
    }
    return resp
  },{})

  return typeData
}
