import React from "react";

import { Input } from 'reactstrap'
import { Row, Col } from 'reactstrap'

import classes from "./BlockContentProductsEdit.module.scss";
const CustomInput = (props: any) => {
	const { formElement }=props
	return (<Row style={{"marginRight":0}}>
		<Col>
			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      
	      defaultValue={formElement.config.item}
				onChange={(e:any)=>formElement.config.onChange("item",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>
		<Col className={classes.hCol} xs={1}>
			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      
	      defaultValue={formElement.config.unitPrice}
				onChange={(e:any)=>formElement.config.onChange("unitPrice",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>
		<Col className={classes.hCol} xs={1}>

			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      
	      defaultValue={formElement.config.quantity}
				onChange={(e:any)=>formElement.config.onChange("quantity",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>
		<Col className={classes.hCol} xs={1}>

			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      
	      defaultValue={formElement.config.unit}
				onChange={(e:any)=>formElement.config.onChange("unit",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>
		<Col className={classes.hCol} xs={1}>

			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      
	      defaultValue={formElement.config.vat}
				onChange={(e:any)=>formElement.config.onChange("vat",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>
		<Col className={classes.hCol} xs={1}>

			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      
	      defaultValue={formElement.config.discount}
				onChange={(e:any)=>formElement.config.onChange("discount",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>

		<Col className={classes.hCol} xs={2}>
			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      
	      defaultValue={formElement.config.total}
				onChange={(e:any)=>formElement.config.onChange("total",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>

		</Col>
	</Row>)
}
 
export default CustomInput;

