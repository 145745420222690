import React, { useEffect, useState, useContext } from "react";

import { Label, Input, Button, ButtonGroup } from 'reactstrap'
import { Row, Col } from 'reactstrap'

import { ESignerType } from "../../../../../../../enums";
import { EContentType } from "../../../../../../../enums";

import ContentTypeIcon from "../../../../../../../components/UI/ContentTypeIcon/ContentTypeIcon";

import OfferContext from "../../../../../containers/Offers/Offer/OfferContext";

import classes from "./BlockContentSignaturesEdit.module.scss";


const CustomInput = (props: any) => {
	const { formElement }=props
	const { offer, currentUser } = useContext(OfferContext);

	const [templateUpdated, setTemplateUpdated] = useState(null);

	const [localValue, setLocalValue] = useState({
		"name":formElement.config.name,
		"email":formElement.config.email,
		"phone":formElement.config.phone,
		"title":formElement.config.title,
	});

	const localUpdate = (name:string, value:string) => {
		formElement.config.onChange(formElement.config.index,name,value);
		setLocalValue({...localValue,[name]:value});
	}

	useEffect(()=>{
		if(offer) {
			if(formElement.config.name === "") {
				toggleSignerType(formElement.config.signerType);	
			}
			setTemplateUpdated(offer.userTemplate.id);
		}
	},[offer, templateUpdated])

	const toggleSignerType = (st: ESignerType) => {
		switch(st) {
			case ESignerType.USER:
				formElement.config.onChange(formElement.config.index,"signerType",st);
				formElement.config.onChange(formElement.config.index,"name",currentUser.name);
				formElement.config.onChange(formElement.config.index,"email",currentUser.email);
				formElement.config.onChange(formElement.config.index,"phone","");
				formElement.config.onChange(formElement.config.index,"title","");
				setLocalValue({
					"name":currentUser.name,
					"email":currentUser.email,
					"phone":"",
					"title":"",
				});
				break;
			case ESignerType.CONTACT:
				const customer=(offer && offer.customer) || {
					contactPerson:"",
					"email":"",
					"phone":"",
				};
				formElement.config.onChange(formElement.config.index,"signerType",st);
				formElement.config.onChange(formElement.config.index,"name",customer.contactPerson);
				formElement.config.onChange(formElement.config.index,"email",customer.email);
				formElement.config.onChange(formElement.config.index,"phone",customer.phone);
				formElement.config.onChange(formElement.config.index,"title","");
				setLocalValue({
					"name":customer.contactPerson,
					"email":customer.email,
					"phone":customer.phone,
					"title":"",
				});
				break;
			case ESignerType.OTHER:
				formElement.config.onChange(formElement.config.index,"signerType",st);
				formElement.config.onChange(formElement.config.index,"name","");
				formElement.config.onChange(formElement.config.index,"email","");
				formElement.config.onChange(formElement.config.index,"phone","");
				formElement.config.onChange(formElement.config.index,"title","");
				setLocalValue({
					"name":"",
					"email":"",
					"phone":"",
					"title":"",
				});
				break;

			default:
		}
	}

	return (<Row className={classes.frame}>
		<Col xs={3}>
			{formElement.config.signerType === ESignerType.USER && <Label>Owner</Label>}
			{formElement.config.signerType === ESignerType.CONTACT && <Label>Signer (primary contact)</Label>}
			{formElement.config.signerType === ESignerType.OTHER && <Label>Signer</Label>}
			{formElement.config.signerType === ESignerType.WATCH && <Label>Viewer</Label>}
		</Col>
		<Col xs={8} className={classes.right}></Col>
		<Col xs={1} className={classes.right}>				
			<ContentTypeIcon
				contentType={EContentType.DELETE}
				onClick={()=>{
					formElement.config.onDelete(formElement.config.index);
					if(props.onBlur) {
						props.onBlur();
					}
				}}/>
			
			{/*
			<ButtonGroup>
				<Button size="sm" onClick={()=>toggleSignerType(ESignerType.USER)} color={formElement.config.signerType === ESignerType.USER?"primary":"secondary"}>User</Button>
				<Button size="sm" onClick={()=>toggleSignerType(ESignerType.CONTACT)} color={formElement.config.signerType === ESignerType.CONTACT?"primary":"secondary"}>Contact</Button>
				<Button size="sm" onClick={()=>toggleSignerType(ESignerType.OTHER)} color={formElement.config.signerType === ESignerType.OTHER?"primary":"secondary"}>Other</Button>
			</ButtonGroup>
			*/}
		</Col>

		<Col xs={6}>
			<Label>Name</Label>
			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      			value={localValue["name"]}	      		
				onChange={(e:any)=>localUpdate("name",e.target.value)}
    	  		invalid={!formElement.config.valid && formElement.config.touched}
      			disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>

		<Col xs={6}>
			<Label>Title</Label>
			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      			value={localValue["title"]}
				onChange={(e:any)=>localUpdate("title",e.target.value)}
    	  		invalid={!formElement.config.valid && formElement.config.touched}
      			disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>

		{formElement.config.signerType!==ESignerType.USER && <>
		<Col xs={6}>
			<Label>Email</Label>
			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
      			value={localValue["email"]}
				onChange={(e:any)=>localUpdate("email",e.target.value)}
    	  		invalid={!formElement.config.valid && formElement.config.touched}
      			disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>

		<Col xs={6}>
			<Label>Phone</Label>
			<Input
				type="text"
				className={[props.className,classes.customInput2].join(" ")}
				value={localValue["phone"]}
				onChange={(e:any)=>localUpdate("phone",e.target.value)}
    	  		invalid={!formElement.config.valid && formElement.config.touched}
      			disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/>
		</Col>
		</>}
		
	</Row>)
}
 
export default CustomInput;

