import { ActionTypes } from './../actions/actionTypes';
import { ISubscriptionState, ISubscriptionAction } from "../../interfaces";
import { updateObject } from '../../shared/utility';

const initialState:ISubscriptionState = {
  loading: false,
  error: null,
  subscription: null
};

const getCurrentSubscriptionStart = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, { error: null, loading: true});
}

const getCurrentSubscriptionSuccess = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, {
      error: null,
      loading: false,
      subscription: action.subscription
  });
}

const getCurrentSubscriptionFail = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};


const saveSubscriptionStart = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, { error: null, loading: true});
}

const saveSubscriptionSuccess = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, {
      error: null,
      loading: false,
      subscription: action.subscription
  });
}

const saveSubscriptionFail = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const cancelSubscriptionStart = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, { error: null, loading: true});
}

const cancelSubscriptionSuccess = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, {
      error: null,
      loading: false,
      subscription: null
  });
}

const cancelSubscriptionFail = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const updateSubscriptionStart = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, { error: null, loading: true});
}

const updateSubscriptionSuccess = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, {
      error: null,
      loading: false,
      subscription: action.subscription
  });
}

const updateSubscriptionFail = (state:ISubscriptionState, action:ISubscriptionAction):ISubscriptionState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const reducer = (state:ISubscriptionState = initialState, action:ISubscriptionAction):ISubscriptionState => {
  switch (action.type) {
      case ActionTypes.GET_CURRENT_SUBSCRIPTION_START : return getCurrentSubscriptionStart(state, action);
      case ActionTypes.GET_CURRENT_SUBSCRIPTION_SUCCESS : return getCurrentSubscriptionSuccess(state, action);
      case ActionTypes.GET_CURRENT_SUBSCRIPTION_FAIL : return getCurrentSubscriptionFail(state, action);
      case ActionTypes.SAVE_SUBSCRIPTION_START : return saveSubscriptionStart(state, action);
      case ActionTypes.SAVE_SUBSCRIPTION_SUCCESS : return saveSubscriptionSuccess(state, action);
      case ActionTypes.SAVE_SUBSCRIPTION_FAIL : return saveSubscriptionFail(state, action);
      case ActionTypes.CANCEL_SUBSCRIPTION_START : return cancelSubscriptionStart(state, action);
      case ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS : return cancelSubscriptionSuccess(state, action);
      case ActionTypes.CANCEL_SUBSCRIPTION_FAIL : return cancelSubscriptionFail(state, action);
      case ActionTypes.UPDATE_SUBSCRIPTION_START : return updateSubscriptionStart(state, action);
      case ActionTypes.UPDATE_SUBSCRIPTION_SUCCESS : return updateSubscriptionSuccess(state, action);
      case ActionTypes.UPDATE_SUBSCRIPTION_FAIL : return updateSubscriptionFail(state, action);
      default: return state;
  }
}

export default reducer;
