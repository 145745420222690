import React, { useEffect, useState } from 'react';
import { IOrganization, IAppState, IUser } from '../../../../interfaces';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";

import { Spinner, Button } from 'reactstrap';

import { getDeals } from './Hubspot/Hubspot';
import { getDeals as severaGetDeals} from './Severa/Severa';

//import classes from "./Integrations.module.scss";

interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  onGetCurrentUser: () => Promise<void>;
  onGetUserOrganization: () => Promise<IOrganization>;
}

interface IProps extends IStateProps, IDispatchProps {
  setDeals: (deals:any) => void;
}

const LoadDealsButton: React.FC<IProps> = ({
  currentUser,
  organization,
  onGetCurrentUser,
  onGetUserOrganization,
  setDeals,
}) => {

  const [init, setInit] = useState(true);
  const [loadingDeals, setLoadingDeals] = useState(false);

  useEffect(() => {
    if (init) {
      onGetUserOrganization();

      setInit(false);
    }
    // eslint-disable-next-line
  }, [init]);

  const integrations=organization && organization.integrations;

  const loadDeals = () => {
    setLoadingDeals(true)
    getDeals(integrations,currentUser).then((resp:any)=>{
      setDeals({integration:"hubspot",data:resp})
      setLoadingDeals(false)
    });
  }

const severaLoadDeals = () => {
    setLoadingDeals(true)
    severaGetDeals(integrations,currentUser).then((resp:any)=>{
      setDeals({integration:"severa",data:resp})
      setLoadingDeals(false)
    });
  }


  return (
    <React.Fragment>
      {integrations && integrations.hubspot.active && <Button disabled={loadingDeals} size="sm" color="success" onClick={loadDeals}>Get deals from Hubspot {integrations.hubspot.stageName}
        {loadingDeals && <Spinner style={{marginLeft:"1rem",width:"1rem",height:"1rem"}}/>}
      </Button>}
      {integrations && integrations.severa.active && <Button disabled={loadingDeals} size="sm" color="success" onClick={severaLoadDeals}>Get deals from Severa
        {loadingDeals && <Spinner style={{marginLeft:"1rem",width:"1rem",height:"1rem"}}/>}
      </Button>}
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    organization: state.organizations.organization,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
    onGetUserOrganization: () => dispatch(actions.getUserOrganization()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadDealsButton);
