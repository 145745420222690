import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { IContentTypeItemType } from "../../../../../../../../interfaces";
import {
  getFormData,
  initForm
} from "../../../../../../../../shared/utility";

import { IBlockContentPrivate } from "../../../../../../../../interfaces";

import { Input, Container, Row, Col, InputGroupText, InputGroup } from 'reactstrap';

interface IProps extends IContentTypeItemType {}

const ContentTypeProducts: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ blockContent, setIsValid }, ref) => {

  const [controlsState, setControlsState] = useState({
    controls: {
    	json:{},
    	settings:{
    		asetukset: "",
        palvelukuvaukset: "",
				kuukausikertoimet: [
		        ["7 kertaa/viikko",36.9],
		        ["6 kertaa/viikko",25.6],
		        ["5 kertaa/viikko",21.3],
		        ["joka toinen pv",11.2],
		        ["4 kertaa/viikko",17.2],
	        	["3 kertaa/viikko",13],
		        ["2 kertaa/viikko",8.7],
	        	["1 kertaa/viikko",4.3],
	        	["1 kertaa/kk",1],
        		["2 kertaa/kk",2.1],
		        ["la-su",13.3],
				],	
        info: "",
        alv: 24,
        palvelukuvaukset_tasma: "",
        palvelukuvaukset_loisto: "",
        palvelukuvaukset_puhdas: "",

			},
    },
    formIsValid: true
  });

  useEffect(() => {
    if (blockContent) {
    	const _blockContent = blockContent as IBlockContentPrivate;
      //const newControlsState = initForm(controlsState.controls, {..._blockContent,json:JSON.parse(_blockContent.json),settings:JSON.parse(_blockContent.settings)});
      setControlsState({...controlsState,controls:{json:JSON.parse(_blockContent.json || "{}"),settings:JSON.parse(_blockContent.settings || JSON.stringify(controlsState.controls.settings))}});
    }
    // eslint-disable-next-line
  }, [blockContent]);

  useEffect(() => {
    setIsValid(controlsState.formIsValid);
  }, [controlsState.formIsValid, setIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      //return getFormData(controlsState.controls);
      return {
      	json:JSON.stringify(controlsState.controls.json),
      	settings:JSON.stringify(controlsState.controls.settings)
      };
    }
  }));

  const update = (field:string, value:string) => {
  	setControlsState({...controlsState,controls:{...controlsState.controls, settings:{...controlsState.controls.settings,[field]:JSON.parse(value)}}});
  }

  const update2 = (field:string, value:string) => {
  	setControlsState({...controlsState,controls:{...controlsState.controls, settings:{...controlsState.controls.settings,[field]:value}}});
  }

  return (<React.Fragment>
  	Asetukset
  	<Input type="textarea" value={controlsState.controls.settings.asetukset} onChange={(e:any)=>update2("asetukset",e.target.value)} />

    Palvelukuvaukset
    <Input type="textarea" value={controlsState.controls.settings.palvelukuvaukset} onChange={(e:any)=>update2("palvelukuvaukset",e.target.value)} />
  	
  	Kuukausikertoimet
  	<Input type="textarea" value={JSON.stringify(controlsState.controls.settings.kuukausikertoimet)} onChange={(e:any)=>update("kuukausikertoimet",e.target.value)} />

    Info
    <Input type="textarea" value={controlsState.controls.settings.info} onChange={(e:any)=>update2("info",e.target.value)} />

    Palvelukuvaukset Täsmä
    <Input type="textarea" value={controlsState.controls.settings.palvelukuvaukset_tasma} onChange={(e:any)=>update2("palvelukuvaukset_tasma",e.target.value)} />
    Palvelukuvaukset Puhdas
    <Input type="textarea" value={controlsState.controls.settings.palvelukuvaukset_puhdas} onChange={(e:any)=>update2("palvelukuvaukset_puhdas",e.target.value)} />
    Palvelukuvaukset Loisto
    <Input type="textarea" value={controlsState.controls.settings.palvelukuvaukset_loisto} onChange={(e:any)=>update2("palvelukuvaukset_loisto",e.target.value)} />

    Alv %
    <Input type="number" value={controlsState.controls.settings.alv} onChange={(e:any)=>update2("alv",e.target.value)} />


  	{/*
  	Paketit
  	<Input type="textarea" value={JSON.stringify(controlsState.controls.settings.paketit)} onChange={(e:any)=>update("paketit",e.target.value)} />
  	Rajat
  	<Input type="textarea" value={JSON.stringify(controlsState.controls.settings.palkkarajat)} onChange={(e:any)=>update("palkkarajat",e.target.value)} />
  	Koulutus
  	<Input type="textarea" value={JSON.stringify(controlsState.controls.settings.koulutus)} onChange={(e:any)=>update("koulutus",e.target.value)} />
		*/}

  	</React.Fragment>);
});

export default ContentTypeProducts;

