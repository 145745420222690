import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { IContentTypeItemType } from "../../../../../../../interfaces";
import {
  getFormData,
  initForm
} from "../../../../../../../shared/utility";

interface IProps extends IContentTypeItemType {}

const defaultValues:any={
		leftFields:[
			{value:"",fontSize:1},
			{value:"",fontSize:2},
			{value:"",fontSize:4},
		],
		rightFields:[
			{value:"",fontSize:1},
			{value:"",fontSize:2},
			{value:"",fontSize:4},
		],
	}

const ContentTypeFields: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ blockContent, setIsValid }, ref) => {
  const [controlsState, setControlsState] = useState({
    controls: {
			leftFields:{value:[
				{value:"",fontSize:1},
				{value:"",fontSize:2},
				{value:"",fontSize:4},
			]},
			rightFields:{value:[
				{value:"",fontSize:1},
				{value:"",fontSize:2},
				{value:"",fontSize:4},
			]},
      jsonData: {
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: defaultValues, //JSON.stringify(defaultValues),
      },
    },
    formIsValid: true
  });

  useEffect(() => {
    if (blockContent) {
      const newControlsState = initForm(controlsState.controls, blockContent);
      setControlsState(newControlsState);
    }
    // eslint-disable-next-line
  }, [blockContent]);

  useEffect(() => {
    setIsValid(controlsState.formIsValid);
  }, [controlsState.formIsValid, setIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return getFormData(controlsState.controls);
    }
  }));

  return null
});

export default ContentTypeFields;

