import React, { useContext } from 'react';
import WebFont from "webfontloader";

import OfferPreviewContext from '../../../../../containers/Offers/Offer/OfferPreviewContext';
import { IBlockContent, IBlockContentSignatures, ISignature } from '../../../../../../../interfaces';
import { ESignerType } from '../../../../../../../enums';
import { dateToISOString } from '../../../../../../../shared/utility';

import classes from "./BlockContentSignaturesEdit.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent: boolean;
}

const BlockContentSignaturesShow: React.FC<IProps> = ({ blockContent, showDummyContent }) => {
  const content = blockContent as IBlockContentSignatures;
  let { organization, customer, confirmDate, sentDate, customerSignature } = useContext(OfferPreviewContext);

  // if (status !== EOfferStatus.CONFIRMED) return null;

  WebFont.load({
    google: {
      families: [`Cedarville Cursive:400,700`]
    }
  });

  let signer = {
    name: "",
    title: ""
  }

  let contactPerson = customer && customer.contactPerson;
 
  let signature = null;

  const handSigned = (_customerSignature:ISignature, normalSignature:any=null) => {

    if (_customerSignature) {
      if ((_customerSignature.image || "").startsWith("data:image/png;base64,")) {
        return <img src={_customerSignature.image} alt="" />;
      } else {
        return <p className={classes.Cursive}>{_customerSignature.name}</p>;
      }
    }
  }

  if (showDummyContent) {
    if (!signer.name) {
      if (organization) {
        signer.name = organization.contactPerson;
      } else {
        signer.name = "Lorem Ipsum";
      }
    }
    if (!signer.title) {
      if (organization) {
        signer.title = organization.contactTitle
      } else {
        signer.title = "Lorem Ipsum";
      }
    }
    if (!customer) contactPerson = "Lorem Ipsum";
    if (!signature) signature = <p className={classes.Cursive}>{contactPerson}</p>;
    if (!sentDate) sentDate = dateToISOString(new Date());
    if (!confirmDate) confirmDate = dateToISOString(new Date());
  }

  // const formatConfirmDate = (ts:string) => {
  //   if(ts==null) return "";
  //   let p:any = ts.split(" ");
  //   let d:any = p[0].split("-");
  //   return d[2]+"."+d[1]+"."+d[0]+" "+p[1];
  // }

  return (
    <div className={classes.Container}>
      {content.signatures.filter((signature:ISignature) => 
          signature.signerType!==ESignerType.WATCH).map((signature:ISignature, index:number) => {

            let signaturex:any = signature.timestamp == null?{
              name: confirmDate==null || (customerSignature && customerSignature.startsWith("data:image/png;base64,"))?signature.name:customerSignature,
              email: signature.email,
              phone: signature.phone,
              title: signature.title,
              timestamp: confirmDate,
              image: customerSignature,
              signerType: null,            
            }:signature;

            return (<div key={index} className={classes.Signature}>
              {signaturex.signerType === ESignerType.USER
                ? <p className={classes.Cursive}>{signaturex.name}</p>
                : <>{signaturex.timestamp ? handSigned(signaturex) : <p></p>}</>}
              <p>{signaturex.name}</p>
              <p>{signaturex.title}</p>
              <p>{signaturex.timestamp}</p>
            </div>)
          })
      }
{/*
      <div className={classes.Signature}>
        {signer && <p className={classes.Cursive}>{signer.name}</p>}
        {signer && <p>{signer.name}</p>}
        {signer && <p>{signer.title}</p>}
        {sentDate && <p>{sentDate}</p>}
      </div>
      <div className={classes.Signature}>
        {(showDummyContent || status === EOfferStatus.CONFIRMED) && (
          <React.Fragment>
            {signature}
            {customer && <p>{contactPerson}</p>}
            {confirmDate && <p>{confirmDate}</p>}
          </React.Fragment>
        )}
      </div>
*/}      
    </div>
  );
};

export default BlockContentSignaturesShow;
