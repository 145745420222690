import { IEmbeddedAction, IEmbeddedState } from '../../interfaces';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';

const initialState:IEmbeddedState = {
    error: null,
    loading: false,
    embedded: null,
};

const getEmbeddedStart = (state:IEmbeddedState, action:IEmbeddedAction):IEmbeddedState => {
    return updateObject(state, { error: null, loading: true});
}

const getEmbeddedSuccess = (state:IEmbeddedState, action:IEmbeddedAction):IEmbeddedState => {
    return updateObject(state, {
        embedded: action.embedded,
        error: null,
        loading: false
    });
}

const getEmbeddedFail = (state:IEmbeddedState, action:IEmbeddedAction):IEmbeddedState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state:IEmbeddedState = initialState, action:IEmbeddedAction):IEmbeddedState => {
    
    switch (action.type) {
        case ActionTypes.GET_EMBEDDED_START : return getEmbeddedStart(state, action);
        case ActionTypes.GET_EMBEDDED_SUCCESS : return getEmbeddedSuccess(state, action);
        case ActionTypes.GET_EMBEDDED_FAIL : return getEmbeddedFail(state, action);
        default: return state;
    }
}

export default reducer;
