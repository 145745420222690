import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
//import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import uuid from "uuid/v1";
import ContentTypeIcon from "../../../../../../../components/UI/ContentTypeIcon/ContentTypeIcon";
import SelectableContainer from "../../../../../../../components/UI/SelectableContainer/SelectableContainer";
import { IUserTemplateBlockDraggable } from "../../../../../../../interfaces";
import { EDragArea, EDragType } from "../../../../../../../enums/drag";

import classes from "./TemplateAreaItem.module.scss";

export const DragItems = {
  templateAreaItem: "TemplateAreaItem"
};

/*
export interface ITemplateAreaItem {
  id: string;
  title: string;
  type: EContentType;
  uuid?: string;
  area?: ETemplateArea;
  isDragged?: boolean;
}
*/

interface IProps {
  item: IUserTemplateBlockDraggable;
  area: EDragArea;
  add: boolean;
  active: boolean;
  moveItem: (
    item: IUserTemplateBlockDraggable,
    toArea: EDragArea,
    toPosition: string
  ) => void;
  onStartDrag: (item: IUserTemplateBlockDraggable) => void;
  onEndDrag: (item: IUserTemplateBlockDraggable, didDrop: boolean) => void;
  clicked: (item: IUserTemplateBlockDraggable) => void;
}

const TemplateAreaItem: React.FC<IProps> = ({
  item,
  area,
  add,
  active,
  moveItem,
  onStartDrag,
  onEndDrag,
  clicked
}) => {
  const handleRef = useRef<HTMLSpanElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
/*
  const [{ isDragging }, drag, preview] = useDrag({
    item: { ...item, area, type: EDragType.BLOCK },
    begin: () => {
      if (area === EDragArea.ADDABLE) {
        return {
          ...item,
          area,
          type: EDragType.BLOCK,
          uuid: uuid()
        };
      }
      onStartDrag(item);
    },
    end: (
      dropResult: IUserTemplateBlockDraggable,
      monitor: DragSourceMonitor
    ) => {
      onEndDrag(dropResult, monitor.didDrop());
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    isDragging: monitor => {
      return item.uuid === monitor.getItem().uuid && area !== EDragArea.ADDABLE;
    }
  });

  const [, drop] = useDrop({
    accept: EDragType.BLOCK,
    hover(hoverItem: IUserTemplateBlockDraggable) {
      if (hoverItem.uuid !== item.uuid) {
        moveItem(hoverItem, area, item.uuid);
      }
    }
  });
*/
  const onItemClicked = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    clicked(item);
  };

  const opacity = 1; //isDragging || item.isDragged ? 0.5 : 1;

  //drop(previewRef);
  //drag(handleRef);

  const containerClasses = [classes.Container];
  if (add) {
    containerClasses.push(classes.ContainerAdd);
  }

  if (active) {
    containerClasses.push(classes.ContainerActive);
  }

  return (
    <div> {/*} ref={preview}>*/}
      <SelectableContainer
        onClick={onItemClicked}
        className={containerClasses.join(" ")}
        style={{ opacity }}
        active={active}
        ref={previewRef}
      >
        <div>
          <span ref={handleRef} className="drag-handle">
            <FontAwesomeIcon icon={faArrowsAlt} />
          </span>
          <p className={classes.Title}>{item.name}</p>
        </div>
        <ContentTypeIcon contentType={item.contentType} active={active} />
      </SelectableContainer>
    </div>
  );
};

export default TemplateAreaItem;
