import React from "react";
import { connect } from "react-redux";

//import { Switch, Redirect, Route, RouteComponentProps } from "react-router";
import { Routes as Switch, Route } from "react-router";
import { RouteComponentProps } from '../../../withRouter';
import { useParams, useLocation, Navigate } from 'react-router-dom';

import { IAppState } from "../../../interfaces";

import Dashboard from "./Dashboard/Dashboard";
import Organizations from "./Organizations/Organizations";
import { INavItem } from "../../../interfaces";
import Layout from "../../../hoc/Layout/Layout";
import Organization from "../../../containers/Organizations/Organization/Organization";
import User from "../../../containers/Users/User/User";
import Statistics from "./Statistics/Statistics";

interface IStateProps {}
interface IProps extends IStateProps, RouteComponentProps {}

const Admin: React.FC<IProps> = props => {
  const navItems: Array<INavItem> = [
    {
      link: "/admin/dashboard",
      text: "Dashboard"
    },
    {
      link: "/admin/organizations",
      text: "Organizations"
    },
    {
      link: "/admin/statistics",
      text: "Statistics"
    }

  ];

  const location = useLocation();

  const match = {params:useParams(),path:""};
  const pathStart = match.path;
  
  return (
    <Layout navItems={navItems} dropdownItems={null}>
      <Switch>
        <Route path={pathStart + "/organizations/:id/users/:id"} element={<User {...props} />} />
        <Route path={pathStart + "/organizations/:id"} element={<Organization {...props} />} />
        <Route path={pathStart + "/organizations"} element={<Organizations {...props} />} />
        <Route path={pathStart + "/dashboard"} element={<Dashboard />} />
        <Route path={pathStart + "/statistics"} element={<Statistics {...props} />} />

        {/*<Route path={"*"} element={<Navigate to="/admin/dashboard" replace/>} />*/}

        {/*<Redirect to={pathStart + "/dashboard"} />*/}
      </Switch>
    </Layout>
  );
};

//export default Admin;
const mapStateToProps = (state: IAppState): IStateProps => {
  return {
  };
};

export default connect(mapStateToProps, null)(Admin);

