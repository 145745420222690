import React, { useState } from 'react';
import CustomDialog from '../CustomDialog/CustomDialog';

interface IProps {
  pipedriveAuthorizeUrl: string;
}

const PipedriveDialog: React.FC<IProps> = ({ pipedriveAuthorizeUrl }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <CustomDialog
      open={isOpen}
      title="Pipedrive connection lost"
      okButtonText="Confirm Pipedrive"
      onOk={() => (window.location.href = pipedriveAuthorizeUrl)}
      onCancel={() => setIsOpen(false)}
    >
      <p>
        Confirm Pipedrive connection permissions by clicking the blue button
        on following view.
      </p>
    </CustomDialog>
  );
};

export default PipedriveDialog;
