import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { Input } from "reactstrap";
import uuid from "uuid";

import classes from "./ColorPicker.module.scss";

interface IProps {
  className?: string;
  onChange: (color: string) => void;
  value: string;
}

const colors = [
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
  "#333333",
  "#FFFFFF",
  "transparent"
];

const ColorPicker: React.FC<IProps> = props => {
  const [showPicker, setShowPicker] = useState(false);
  const [isMounted, setMounted] = useState(true);
  const [id] = useState(uuid());

  const onDocumentClick = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      const path = ev.composedPath();
      const contains = path.some(
        (item: any) => item.classList && item.classList.contains(id)
      );
      if (!contains && isMounted) {
        setShowPicker(false);
        document.removeEventListener("click", onDocumentClick);
      }
    },
    [isMounted, id]
  );

  useEffect(() => {
    return () => {
      setMounted(false);
      document.removeEventListener("click", onDocumentClick);
    };
  }, [onDocumentClick]);

  const onInputClick = () => {
    setShowPicker(true);
    document.addEventListener("click", onDocumentClick);
  };

  const onColorChange = (colorResult: ColorResult) => {
    //console.log("onColorChange", colorResult);
    props.onChange(colorResult.hex);
    //setColor(colorResult.hex);
  };

  return (
    <div className={[classes.Container, id].join(" ")}>
      <span className={classes.Color} style={{ background: props.value }} />
      <Input
        type="text"
        className={classes.Input}
        onClick={onInputClick}
        value={props.value}
        readOnly
      />
      {props.value && (
        <span className={classes.Clear} onClick={() => props.onChange('')}><FontAwesomeIcon icon={faTimes} /></span>
      )}

      {showPicker && (
        <div className={classes.SketchContainer}>
          <SketchPicker
            onChangeComplete={onColorChange}
            color={props.value}
            disableAlpha
            presetColors={colors}
          />
          {/*<TwitterPicker
            onChange={onColorChange}
            color={props.value}
            colors={colors}
          /> */}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
