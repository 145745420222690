import { Dispatch } from 'redux';

import axios from '../../axios';
import { ICustomer, ICustomerAction } from '../../interfaces';
import { ActionTypes } from './actionTypes';

export const clearCustomersState = ():ICustomerAction => {
    return {
        type: ActionTypes.CLEAR_CUSTOMERS_STATE
    };
};

export const findCustomersStart = ():ICustomerAction => {
    return {
        type: ActionTypes.FIND_CUSTOMERS_START
    };
};

export const findCustomersSuccess = (customers:Array<ICustomer>):ICustomerAction => {
    return {
        type: ActionTypes.FIND_CUSTOMERS_SUCCESS,
        customers
    };
};

export const findCustomersFail = (error:string):ICustomerAction => {
    return {
        type: ActionTypes.FIND_CUSTOMERS_FAIL,
        error
    }
};

export const findCustomers = () => {
    return async (dispatch:Dispatch) => {
        dispatch(findCustomersStart());
        try {
            const res = await axios.get(`/json/customers/list`);
            const customers = res.data;
            dispatch(findCustomersSuccess(customers));
            return customers;
            //@ts-ignore
        } catch (error: any) {
            dispatch(findCustomersFail(error.response.data));
        }
        
    };
};

export const getCustomerStart = ():ICustomerAction => {
    return {
        type: ActionTypes.GET_CUSTOMER_START
    };
};

export const getCustomerSuccess = (customer:ICustomer):ICustomerAction => {
    return {
        type: ActionTypes.GET_CUSTOMER_SUCCESS,
        customer
    };
};

export const getCustomerFail = (error:string):ICustomerAction => {
    return {
        type: ActionTypes.GET_CUSTOMER_FAIL,
        error
    }
};

export const getCustomer = (id:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(getCustomerStart());
        try {
            const res = await axios.get(`/json/customers/get?id=${id}`);
            const customer = res.data;
            dispatch(getCustomerSuccess(customer));
            return customer;
            //@ts-ignore
        } catch (error: any) {
            dispatch(getCustomerFail(error.response.data));
        }
        
    };
};

export const saveCustomerStart = ():ICustomerAction => {
    return {
        type: ActionTypes.SAVE_CUSTOMER_START
    };
};

export const saveCustomerSuccess = (customer:ICustomer):ICustomerAction => {
    return {
        type: ActionTypes.SAVE_CUSTOMER_SUCCESS,
        customer
    };
};

export const saveCustomerFail = (error:string):ICustomerAction => {
    return {
        type: ActionTypes.SAVE_CUSTOMER_FAIL,
        error
    }
};

export const saveCustomer = (customer: ICustomer) => {
    return async (dispatch:Dispatch) => {
        dispatch(saveCustomerStart());
        try {
            const res = await axios.post("/json/customers/add", customer);
            const dbCustomer = res.data;
            dispatch(saveCustomerSuccess(dbCustomer)); 
            return dbCustomer;
            //@ts-ignore
        } catch (error: any) {
            dispatch(saveCustomerFail(error.response.data));
        }
        
    };
};

export const updateCustomerStart = ():ICustomerAction => {
    return {
        type: ActionTypes.UPDATE_CUSTOMER_START
    };
};

export const updateCustomerSuccess = ():ICustomerAction => {
    return {
        type: ActionTypes.UPDATE_CUSTOMER_SUCCESS
    };
};

export const updateCustomerFail = (error:string):ICustomerAction => {
    return {
        type: ActionTypes.UPDATE_CUSTOMER_FAIL,
        error
    }
};

export const updateCustomer = (formData:{}) => {
    return async (dispatch:Dispatch) => {
        dispatch(updateCustomerStart());
        try {
            await axios.post("/json/customers/add", formData);
            dispatch(updateCustomerSuccess());   
            //@ts-ignore 
        } catch (error: any) {
            dispatch(updateCustomerFail(error.response.data));
        }
        
    };
};


export const searchCustomersStart = ():ICustomerAction => {
    return {
        type: ActionTypes.SEARCH_CUSTOMERS_START
    };
};

export const searchCustomersSuccess = (search:Array<ICustomer>):ICustomerAction => {
    return {
        type: ActionTypes.SEARCH_CUSTOMERS_SUCCESS,
        search
    };
};

export const searchCustomersFail = (error:string):ICustomerAction => {
    return {
        type: ActionTypes.SEARCH_CUSTOMERS_FAIL,
        error
    }
};

export const searchCustomers = (queryString:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(searchCustomersStart());
        try {
            const res = await axios.get(`/json/customers/search?q=${queryString}`);
            dispatch(searchCustomersSuccess(res.data));
            //@ts-ignore
        } catch (error: any) {
            dispatch(searchCustomersFail(error.response.data));
        }
        
    };
};

export const clearCustomersSearch = ():ICustomerAction => {
    return {
        type: ActionTypes.CLEAR_CUSTOMERS_SEARCH
    };
};

export const deleteCustomer = (id: string) => {
  return async (dispatch: Dispatch) => {
    //dispatch(deleteOfferStart());
    try {
      await axios.post("/json/customers/delete",{id:id});
      //dispatch(deleteOfferSuccess());
      //@ts-ignore
    } catch (error: any) {
      if (error.response) {
        //dispatch(deleteOfferFail(error.response.data));
      }
    }
  };
};


