import i18n from 'i18next';

import translations_de from './translations/de/translation.json';
import translations_en from './translations/en/translation.json';
import translations_fi from './translations/fi/translation.json';
import translations_se from './translations/se/translation.json';
import translations_es from './translations/es/translation.json';
import translations_fr from './translations/fr/translation.json';
import translations_nl from './translations/nl/translation.json';
import translations_da from './translations/da/translation.json';
import translations_pt from './translations/pt/translation.json';

i18n.init({
  interpolation: {
    escapeValue: false
  },
  lng: "en",
  fallbackLng: 'en',
  resources: {
    en: {
      translation: translations_en,
    },
    de: {
      translation: translations_de
    },
    fi: {
      translation: translations_fi
    },
    se: {
      translation: translations_se
    },
    es: {
      translation: translations_es
    },
    fr: {
      translation: translations_fr
    },
    nl: {
      translation: translations_nl
    },
    da: {
      translation: translations_da
    },
    pt: {
      translation: translations_pt
    },
  }
});

export default i18n;
