import React from "react";

import image from "../../../assets/images/pipedrive_logo.svg";

interface IProps {
  show: boolean;
}

const PipedriveLogo: React.FC<IProps> = ({ show }) => {
  if (!show) return null;
  return (
    <img
      src={image}
      width="16"
      height="16"
      alt="Pipedrive"
      style={{ marginBottom: "4px", marginRight: "0.5rem" }}
    />
  );
};

export default PipedriveLogo;
