import React from 'react';
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from '../../../../withRouter';
import { Table } from 'reactstrap';

import PipedriveLogo from '../../../../components/UI/PipedriveLogo/PipedriveLogo';
import { ELanguage } from '../../../../enums';
import { ICustomer } from '../../../../interfaces';

import classes from "./CustomersList.module.scss";

interface IProps extends RouteComponentProps {
  customers: Array<ICustomer>;
}

const CustomersList: React.FC<IProps> = ({ customers, history }) => {
  if (!customers || !customers.length) {
    return null;
  }

  const onEdit = (customer: ICustomer) => {
    history.push(`/customers/${customer.id}`);
  };

  const getLanguage = (customer: ICustomer) => {
    switch (customer.language) {
      case ELanguage.ENGLISH:
        return "English";
      case ELanguage.FINNISH:
        return "Finnish";
      case ELanguage.SWEDISH:
        return "Swedish";
      case ELanguage.GERMAN:
        return "German";
      case ELanguage.SPANISH:
        return "Spanish";
      case ELanguage.FRENCH:
        return "French";
      case ELanguage.DUTCH:
        return "Dutch";
      case ELanguage.DANISH:
        return "Danish";
      default:
        return customer.language;
    }
  };

  return (
    <Table responsive borderless striped>
      <thead>
        <tr>
          <th>Customer name</th>
          <th>Email</th>
          <th>Phone number</th>
          <th>Contact person</th>
          <th>Business ID</th>
          <th>Language</th>
        </tr>
      </thead>
      <tbody>
        {customers.map((customer, i) => {
          return (
            <tr
              key={customer.id || `${customer.name}_${i}`}
              className={[
                classes.TableRow,
                customer.isPipedrive ? classes.Disabled : ""
              ].join(" ")}
              onClick={!customer.isPipedrive ? (() => onEdit(customer)) : undefined}
            >
              <td>
                <PipedriveLogo show={customer.isPipedrive} /> {customer.name}
              </td>
              <td>{customer.email}</td>
              <td>{customer.phone}</td>
              <td>{customer.contactPerson}</td>
              <td>{customer.businessID}</td>
              <td>{getLanguage(customer)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CustomersList;
