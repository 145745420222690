import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import { EContentType } from '../../enums';
import { IAppState, IAttachment } from '../../interfaces';
import * as actions from '../../store/actions';
import ContentTypeIcon from '../UI/ContentTypeIcon/ContentTypeIcon';
import CustomDropzonePreview from './CustomDropzonePreview/CustomDropzonePreview';

import classes from "./CustomDropzone.module.scss";

interface IStateProps {
  loading: boolean;
}

interface IDispatchProps {
  onUploadAttachment: (data: FormData) => Promise<Array<IAttachment>>;
  onUploadCustomers: (data: {}) => {};
}

export interface IDropzoneConfig {
  multiple?: boolean;
}

interface IProps extends IStateProps, IDispatchProps {
  className?: string;
  accept: string;
  text: string;
  dragText: string;
  contentType?: EContentType;
  onChange?: (attachments: Array<IAttachment> | IAttachment) => void;
  onDrop?: (files: Array<File>) => void;
  value: Array<IAttachment> | IAttachment;
  config: IDropzoneConfig;
}

const CustomDropzone: React.FC<IProps> = ({
  className,
  accept,
  text,
  dragText,
  contentType,
  loading,
  onUploadAttachment,
  onUploadCustomers,
  onChange,
  onDrop,
  value,
  config
}) => {

  const onDropHandler = async (files: Array<File>) => {
    /*
      formData.append("csv", files[0]);
      onUploadCustomers(formData);
    */
    if(files && files.length > 0) {
      if (onDrop) {
        onDrop(files);
      } else {
        const formData = new FormData();
        for (let file of files) {
          formData.append("files[]", file);
        }
        const attachments = await onUploadAttachment(formData);

        if(config.multiple) {
          const newValue = (value as Array<IAttachment>).concat(attachments);
          onChange(newValue);
        } else {
          onChange(attachments[0]);
        }
      }
    }
  };

  const onDeleteHandler = (event: React.MouseEvent<HTMLDivElement>, id:string) => {
    event.stopPropagation();
    if(config.multiple) {
      const newValue = (value as Array<IAttachment>).filter(attachment => attachment.id !== id);
      onChange(newValue);
    } else {
      onChange(null);
    }

  }

  const renderPreview = (attachments: Array<IAttachment> | IAttachment) => {
    if (!attachments) return null;

    if(config.multiple) {
      return (attachments as Array<IAttachment>).filter(attachment => attachment != undefined).map(attachment => <CustomDropzonePreview key={attachment.id} attachment={attachment} onDelete={onDeleteHandler} contentType={contentType}/>);
    } 
    return <CustomDropzonePreview attachment={attachments as IAttachment} onDelete={onDeleteHandler} contentType={contentType}/>
  };

  return (
    <Dropzone onDrop={onDropHandler} multiple={config.multiple} accept={accept}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div className={className || classes.Dropzone} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={classes.Container}>
              {loading ? (
                <Spinner size="sm" color="primary" className={classes.Icon} />
              ) : contentType ? (
                <ContentTypeIcon
                  className={classes.Icon}
                  contentType={contentType}
                  active={false}
                />
              ) : null}
              <p className={classes.Text}>{isDragActive ? dragText : text}</p>
            </div>
            <div className={classes.PreviewContainer}>
              {renderPreview(value)}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.attachments.loading
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onUploadAttachment: data => dispatch(actions.uploadAttachment(data)),
    onUploadCustomers: data => dispatch(actions.uploadCustomers(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDropzone);
