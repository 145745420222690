import React, { CSSProperties, forwardRef, ReactNode, Ref } from "react";

import classes from "./SelectableContainer.module.scss";

interface IProps {
  onClick: (ev: React.MouseEvent<HTMLDivElement>) => void;
  active: boolean;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  ref?: Ref<HTMLDivElement>;
}

const SelectableContainer: React.ForwardRefExoticComponent<IProps> = forwardRef(({
  onClick,
  active,
  children,
  className,
  style
}, ref) => {
  const containerClasses = [classes.Container];

  if (active) {
    containerClasses.push(classes.Active);
  }

  if (className) {
    containerClasses.push(className);
  }

  return (
    <div
      ref={ref}
      className={containerClasses.join(" ")}
      onClick={onClick}
      style={style ? { ...style } : null}
    >
      {children}
    </div>
  );
});

export default SelectableContainer;
