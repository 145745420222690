import React, { CSSProperties, useState } from "react";
import { Spinner } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  style?: CSSProperties;
  src: string;
  alt: string;
}

const ImageSpinner: React.FC<IProps> = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = () => {
		//console.log("onError");
		setLoading(false);
		setError(true);
  };

  const styles = {
    ...props.style,
    display: loading ? "none" : "initial",
    maxWidth: '100%'
  };

  return (
		<React.Fragment>
			{!error &&
	      <img src={props.src} alt={props.alt} onLoad={onLoad} onError={onError} style={styles} />}
				{error && <div className="text-center"><FontAwesomeIcon icon={faImage} size="2x" /><br/>Missing Image</div>}
			{loading ? <Spinner color="muted" /> : null}
    </React.Fragment>
  );
};

export default ImageSpinner;
