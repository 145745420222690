import React, {
  forwardRef, 
  useImperativeHandle, 
  useState, 
  useEffect
} from "react";
import { EContentType, EDropzoneType } from "../../../../../../../enums";
import {
  IBlockContent,
  IBlockContentAttachment
} from "../../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData
} from "../../../../../../../shared/utility";
import { IBlockContentEditItem } from "../../BlockContentEdit";

interface IProps {
  ref: any;
  blockContent: IBlockContent;
  onChange?: (blockContent: IBlockContent) => void;
}

const BlockContentAttachmentEdit: React.ForwardRefExoticComponent<
  IBlockContentEditItem
> = forwardRef((props, ref) => {
  const [init, setInit] = useState(false);
  const blockContent = props.blockContent as IBlockContentAttachment;

  const [controlsState, setControlsState] = useState({
    controls: {
      attachments: {
        elementType: "dropzone",
        elementConfig: {
          label: blockContent.name,
          accept: EDropzoneType.IMG + ", " + EDropzoneType.PDF,
          text: "Drop attachment here or click to upload.",
          dragText: "Drop attachment here.",
          contentType: EContentType.ATTACHMENT,
          config: {
            multiple: true      
          }
        },
        valid: true,
        touched: false,
        value: blockContent.attachments || []
      },
    },
    controlsIsValid: false
  });

  const getData = () => {
    return Object.assign(blockContent, getFormData(controlsState.controls));
  }

  useEffect(() => {
    if(props.onChange && init) {
      props.onChange(getData())
    } 
    // eslint-disable-next-line
  }, [controlsState.controls]);

  useEffect(() => {
    setInit(true);
  }, [])

  useImperativeHandle(ref, () => ({
    getData
  }));

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  };

  let blurHandler:any = null;
    if(props.onBlur) {
      blurHandler = () => {
        //props.onBlur(getData());
      }
    }

  return controlsToFormGroups(controlsState.controls, inputChangedHandler, blurHandler);
}
);

export default BlockContentAttachmentEdit;
