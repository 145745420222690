import { Dispatch } from "redux";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IUserAction, IUser, IRegisterAction } from "../../interfaces";

export const findUsersStart = (): IUserAction => {
  return {
    type: ActionTypes.FIND_USERS_START
  };
};

export const findUsersSuccess = (users: Array<IUser>): IUserAction => {
  return {
    type: ActionTypes.FIND_USERS_SUCCESS,
    users
  };
};

export const findUsersFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.FIND_USERS_FAIL,
    error
  };
};

export const findUsers = (organizationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(findUsersStart());
    try {
      if (!organizationId) {
        organizationId = "";
      }
      const res = await axios.get(
        `/json/users/list?organizationId=${organizationId}`
      );
      const users = res.data;
      dispatch(findUsersSuccess(users));
      //@ts-ignore
    } catch (error: any) {
      dispatch(findUsersFail(error.response.data));
    }
  };
};

export const getUserStart = (): IUserAction => {
  return {
    type: ActionTypes.GET_USER_START
  };
};

export const getUserSuccess = (user: IUser): IUserAction => {
  return {
    type: ActionTypes.GET_USER_SUCCESS,
    user
  };
};

export const getUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.GET_USER_FAIL,
    error
  };
};

export const getUser = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getUserStart());
    try {
      const res = await axios.get(`/json/users/get?id=${id}`);
      const user = res.data;
      dispatch(getUserSuccess(user));
      //@ts-ignore
    } catch (error: any) {
      dispatch(getUserFail(error.response.data));
    }
  };
};

export const saveUserStart = (): IUserAction => {
  return {
    type: ActionTypes.SAVE_USER_START
  };
};

export const saveUserSuccess = (): IUserAction => {
  return {
    type: ActionTypes.SAVE_USER_SUCCESS
  };
};

export const saveUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.SAVE_USER_FAIL,
    error
  };
};

export const saveUser = (formData: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveUserStart());
    try {
      await axios.post("/json/users/add", formData);
      dispatch(saveUserSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(saveUserFail(error.response.data));
      return false;
    }
  };
};

export const updateUserStart = (): IUserAction => {
  return {
    type: ActionTypes.UPDATE_USER_START
  };
};

export const updateUserSuccess = (): IUserAction => {
  return {
    type: ActionTypes.UPDATE_USER_SUCCESS
  };
};

export const updateUserFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.UPDATE_USER_FAIL,
    error
  };
};

export const updateUser = (formData: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    try {
      await axios.post("/json/users/add", formData);
      dispatch(updateUserSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(updateUserFail(error.response.data));
      return false;
    }
  };
};

export const searchUsersStart = (): IUserAction => {
  return {
    type: ActionTypes.SEARCH_USERS_START
  };
};

export const searchUsersSuccess = (search: Array<IUser>): IUserAction => {
  return {
    type: ActionTypes.SEARCH_USERS_SUCCESS,
    search
  };
};

export const searchUsersFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.SEARCH_USERS_FAIL,
    error
  };
};

export const searchUsers = (queryString: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(searchUsersStart());
    try {
      const res = await axios.get(`/json/users/search?q=${queryString}`);
      dispatch(searchUsersSuccess(res.data));
      //@ts-ignore
    } catch (error: any) {
      dispatch(searchUsersFail(error.response.data));
    }
  };
};

export const clearUsersSearch = (): IUserAction => {
  return {
    type: ActionTypes.CLEAR_USERS_SEARCH
  };
};

export const registerUserStart = (): IRegisterAction => {
  return {
    type: ActionTypes.REGISTER_USER_START
  };
};

export const registerUserSuccess = (formData: {}): IRegisterAction => {
  return {
    type: ActionTypes.REGISTER_USER_SUCCESS,
    formData
  };
};

export const registerUserFail = (error: string): IRegisterAction => {
  return {
    type: ActionTypes.REGISTER_USER_FAIL,
    error
  }
};

export const registerUser = (formData: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(registerUserStart());
    try {
      const res = await axios.post("/json/users/register", formData);
      const organization = res.data;
      dispatch(registerUserSuccess(organization));
    } catch (err) {
      //@ts-ignore
      dispatch(registerUserFail(err));
    }

  };
};

export const resetPasswordStart = (): IUserAction => {
  return {
    type: ActionTypes.RESET_PASSWORD_START
  };
};

export const resetPasswordSuccess = (status: string): IUserAction => {
  return {
    type: ActionTypes.RESET_PASSWORD_SUCCESS,
    resetPasswordStatus: status
  };
};

export const resetPasswordFail = (error: string): IUserAction => {
  return {
    type: ActionTypes.RESET_PASSWORD_FAIL,
    error
  };
};

export const resetPassword = (resetPasswordData: {id: string, token: string, password: string}) => {
  return async (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    try {
      const res = await axios.post("/json/users/resetpassword", {...resetPasswordData});
      dispatch(resetPasswordSuccess(res.data));
    } catch (error) {
      //@ts-ignore
      dispatch(resetPasswordFail(error));
    }
  };
};

// export const uploadUserImageStart = ():IUserAction => {
//   return {
//       type: ActionTypes.UPLOAD_USER_IMAGE_START
//   };
// };

// export const uploadUserImageSuccess = ():IUserAction => {
//   return {
//       type: ActionTypes.UPLOAD_USER_IMAGE_SUCCESS
//   };
// };

// export const uploadUserImageFail = (error:string):IUserAction => {
//   return {
//       type: ActionTypes.UPLOAD_USER_IMAGE_FAIL,
//       error
//   }
// };

// export const uploadUserImage = (data:{}) => {
//   return async (dispatch:Dispatch) => {
//       dispatch(uploadUserImageStart());
//       try {
//           await axios.post("/json/attachments/upload", data);
//           dispatch(uploadUserImageSuccess());
//       } catch (error: any) {
//           dispatch(uploadUserImageFail(error.response.data));
//       }
      
//   };
// };
