import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IBlockContentFields, IFields } from "../../../../../../../interfaces";
import { IBlockContentEditItem } from "../../BlockContentEdit";
import { EFields } from "../../../../../../../enums";

import { Row, Col } from 'reactstrap'

import IconButton from "../../../../../../../components/UI/IconButton/IconButton";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  controlsToFormGroups,
} from "../../../../../../../shared/utility";

import CustomInput from './CustomInput';

const BlockContentFieldsEdit: React.ForwardRefExoticComponent<
  IBlockContentEditItem
> = forwardRef((props, ref) => {
  const blockContent = props.blockContent as IBlockContentFields;
  
  const updateControls = (jsonData:IBlockContentFields) => ({
    controls: {
      ...Object.values([EFields.LEFT,EFields.RIGHT]).reduce((sideresp:any,key:EFields)=>{
        
				sideresp[key]=Object.values(jsonData[key]).reduce((resp:any,ob:IFields,index:number)=>{
          resp[index]={
             elementType: "input",
              elementConfig: {
                placeholder: "Field text",
                type: "custom"
              },

              touched: false,
              value: ob.value || "",

              custom:CustomInput,
              side:key,
              index:index,
              fontSize:ob.fontSize,
              onChange:inputChangedHandler,
              onDelete:onDeleteHandler,
          }
          return resp
        },{})
        
        return sideresp
      },{leftFields:{},rightFields:{}}),
      
    },
  })

	const updateFields = (data:IBlockContentFields) => {
    setJsonData({...data})
    setControlsState(updateControls(data))
	}

  const inputChangedHandler = (side: EFields,index: number, name:string, value: any) => {
		switch(name) {
			case "value": jsonData[side][index].value=value; break;
			case "fontSize": jsonData[side][index].fontSize=value; break;
			default:
		}
		updateFields(jsonData)
  }

  const onDeleteHandler = (side: EFields, index: number) => {
    jsonData[side].splice(index,1)
    updateFields(jsonData);
  }

  const addField = (pos: EFields) => {
    jsonData[pos].push({value:"",fontSize:4})
		updateFields(jsonData)
  }

  const getData = () => {
		const leftFields=jsonData.leftFields;
		const rightFields=jsonData.rightFields;
    return Object.assign(blockContent, {leftFields,rightFields});

  }
  const [jsonData, setJsonData]=useState<IBlockContentFields>(blockContent)

  const [init, setInit] = useState(false);
  const [controlsState, setControlsState] = useState(updateControls(jsonData))


  useImperativeHandle(ref, () => ({
    getData
  }));
  
  useEffect(() => {
    if(props.onChange && init) {
      props.onChange(getData())
    } 
    // eslint-disable-next-line
  }, [jsonData]);

  useEffect(() => {
    setInit(true);
  }, [])

  let blurHandler:any = null;
    if(props.onBlur) {
      blurHandler = () => {
        props.onBlur(getData());
      }
    }

  return (
    <React.Fragment>

      <Row>
        <Col>
          {controlsToFormGroups(controlsState.controls.leftFields, inputChangedHandler, blurHandler)}
        </Col>
        <Col>
          {controlsToFormGroups(controlsState.controls.rightFields, inputChangedHandler, blurHandler)}
        </Col>
      </Row>

      <Row>
        <Col>
         <IconButton
          style={{ marginTop: "1rem", fontWeight: 500 }}
          color="light"
          icon={faPlus}
          onClick={()=>addField(EFields.LEFT)}
        >
          Add to left column
        </IconButton>
        </Col>
        <Col>
         <IconButton
          style={{ marginTop: "1rem", fontWeight: 500 }}
          color="light"
          icon={faPlus}
          onClick={()=>addField(EFields.RIGHT)}
        >
          Add to right column
        </IconButton>

        </Col>
      </Row>
      

    </React.Fragment>
  );
});

  export default BlockContentFieldsEdit;
