import React, { useState, useEffect, useRef } from "react";
//import { Editor } from "@tinymce/tinymce-react";
//import './tinyfix.css';

import { config } from "../../../constants";

import ReactQuill from 'react-quill-with-table';

//import 'react-quill/dist/quill.snow.css';

import EditorToolbar, { formats } from "./EditToolbar";

import { Button } from 'reactstrap';

import uuid from "uuid";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}


/*
const settings = {
  height: 300,
  branding: false,

  plugins: [
    "advlist autolink lists link charmap hr",
    "visualblocks code",
    "insertdatetime table paste code wordcount autoresize"
  ],

  menu: {
    insert: { title: "Insert", items: "link | hr | charmap" },
    format: {
      title: "Format",
      items:
        "bold italic underline strikethrough superscript subscript | removeformat"
    },
    table: {
      title: "Table",
      items: "inserttable deletetable | cell row column"
    },
    tools: { title: "Tools", items: "code" }
  },

  menubar: "insert format table tools",
	toolbar: "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist",

	contextmenu:false,

  extended_valid_elements : 'c2c'


};
*/
const CustomTinyMceEditor: React.FC<IProps> = ({ value, onChange, onBlur }) => {
	const changeHandler = (e: any): void => {
    //onChange(e.target.getContent());
  };
	
	const [sticky, setSticky]=useState(false);
  const [height, setHeight]=useState(0);
	const [tox, setTox]=useState(null);

  const [code, setCode]=useState<string>(value);
  const [useCode, setUseCode]=useState<boolean>(value.includes("<div"));

  const [quillUuid, setQuillUuid] = useState<string>(null);
  const [quillmodules, setQuillmodules] = useState<any>(null);

	const headerRef = React.createRef<HTMLDivElement>();
	const holderRef = React.createRef<HTMLDivElement>();

  useEffect(() => {

    const id = uuid();
    setQuillUuid(id);
    setQuillmodules({
      //table: true,
      toolbar: {
        container: "#toolbar-"+id,
        handlers: {
        }
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true
      },
      
      });


  },[]);

/*
	try {
  	const el=document.getElementsByClassName("modal-body")

  	if(el.length>0) {
  	  el[el.length-1].addEventListener("scroll",(e)=>{
				if(tox===null) {					
					setTox(document.getElementsByClassName("tox-editor-header")[0] as HTMLElement);
				}

  	    if(headerRef.current && holderRef.current && tox) {
  	      const xxx=headerRef.current.getBoundingClientRect()
  	      const zzz=holderRef.current.getBoundingClientRect()

  	      const yyy=tox.getBoundingClientRect()

  	      const limit=headerRef.current.offsetParent.getBoundingClientRect();

  	      tox.style.top=limit.top+"px";
  	      tox.style.width=(xxx.width-2)+"px";


  	      if( (xxx.top<limit.top) && (!sticky) && (-xxx.top<(zzz.height-yyy.height*4)) ) {
  	        setSticky(true)
  	        tox.classList.add("tiny-sticky-header");
  	        setHeight(yyy.height);
  	      }
  	      else if( xxx.top>limit.top && sticky ) {
  	        setSticky(false)
  	        tox.classList.remove("tiny-sticky-header");
  	        setHeight(0);
  	      }
  	      else if( -xxx.top>(zzz.height-yyy.height*3) ) {
  	        setSticky(false)
  	        tox.classList.remove("tiny-sticky-header");
  	        setHeight(0);
  	      }
  	    }


  	  });
  	}
  } catch(err) {
      console.log("er",err);
  }
*/


const [qvalue, setQValue] = useState<string>('');
	return (<>
    <div ref={headerRef} style={{height:height,width:"100%"}}></div>
    <div ref={holderRef}>
     
     {useCode && <div style={{"textAlign":"right"}}>
       <textarea style={{"width":"100%"}} onChange={(e)=>onChange(e.target.value as string)}>{value}</textarea>
       <Button onClick={()=>setUseCode(false)}>Back to editor</Button>
     </div>}

     {!useCode 
       ?quillUuid!==null && <>
         <EditorToolbar insertCode={()=>setUseCode(true)} uuid={quillUuid} />
         <div onBlur={onBlur}>
         
         <ReactQuill theme="snow" value={value} onChange={onChange} modules={quillmodules} formats={formats} />
         </div>
         {/*<ReactQuill theme="snow" value={value} onChange={onChange} modules={modules} formats={formats}/>*/}
       </>
       :<div dangerouslySetInnerHTML={{__html: value}}></div>
     }

     {/*<ReactQuill theme="snow" value={value} onChange={onChange}/>*/}
{/*
	    <Editor
  	    apiKey={config.TINYMCE_APIKEY}
    	  initialValue={value}
      	init={settings}
	      onChange={changeHandler}
  	    onBlur={onBlur}
        onEditorChange={(e)=>onChange(e)}
    	/>
*/}      
		</div>
  </>);
};

export default CustomTinyMceEditor;
