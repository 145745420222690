import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  //Redirect,
  Route,
  //RouteComponentProps,
  Routes as Switch,
  //withRouter
  Navigate,
} from "react-router-dom";

import { RouteComponentProps, withRouter } from '../../../withRouter';

import Organization from "../../../containers/Organizations/Organization/Organization";
import Profile from "../../../containers/Users/User/Profile/Profile";
import User from "../../../containers/Users/User/User";
import Users from "../../../containers/Users/Users";
import { EPageView } from "../../../enums";
import EUserRole from "../../../enums/user-roles";
import Layout from "../../../hoc/Layout/Layout";
import { IAppState, INavItem, IUser } from "../../../interfaces";
import Billing from "./Billing/Billing";
import Invoices from "./Billing/Invoices/Invoices";
import Block from "./Blocks/Block/Block";
import BlockItem from "./Blocks/Block/BlockItems/BlockItem/BlockItem";
import BlockItems from "./Blocks/Block/BlockItems/BlockItems";
import Blocks from "./Blocks/Blocks";
import Contracts from "./Contracts/Contracts";
import Statistics from "./Statistics/Statistics";
import Customer from "./Customers/Customer/Customer";
import Customers from "./Customers/Customers";
import Offer from "./Offers/Offer/Offer";
import OfferAccept from "./Offers/OfferAccept/OfferAccept";
import Offers from "./Offers/Offers";
import UserTemplate from "./UserTemplates/UserTemplate/UserTemplate";
import UserTemplates from "./UserTemplates/UserTemplates";
import PipedriveDialog from "../../../components/UI/PipedriveDialog/PipedriveDialog";
import AuthError from "../../../containers/Auth/AuthError";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

import Integrations from "./Integrations/Integrations";

import UserGroup from "../../../containers/Users/UserGroup/UserGroup";

interface IStateProps {
	currentUser: IUser;
  pipedriveConnectionLost: boolean;
  pipedriveAuthorizeUrl: string;
  redirect: string;
}

interface IProps extends RouteComponentProps, IStateProps {}

const Default: React.FC<IProps> = props => {
  const {
    currentUser, 
    pipedriveConnectionLost,
    pipedriveAuthorizeUrl, 
    redirect, 
    history,
    match,
    location,
  } = props;

  let navItems: Array<INavItem> = [];

  if(!currentUser.isRestricted) {
    navItems = [
      ...navItems,
      {
        link: "/customers",
        text: "Customers"
      }  
    ]
  }

  navItems = [
    ...navItems,
    {
      link: "/offers",
      text: "Proposals"
    },
    {
      link: "/contracts",
      text: "Contracts"
    },
    {
      link: "/statistics",
      text: "Statistics"
    }

  ];

  const dropdownItems: Array<INavItem> = [
    {
      link: "/profile",
      text: "Profile"
    }
  ];

  useEffect(() => {
    if (redirect) {
      // Wait that render has done so if route not found then <Redirect /> will trigger
      setTimeout(() => {
        history.replace(redirect);
      }, 1);
    }
    // eslint-disable-next-line
  }, [redirect]);

  const adminRoutes = [
    { path: "/users/:id", component: <User {...props}/> },
    { path: "/users", component: <Users organizationId={null} {...props}/> },
    { path: "/organization", component: <Organization {...props}/> },
    { path: "/billing/invoices", component: <Invoices {...props}/> },
    { path: "/billing", component: <Billing {...props}/> },
    { path: "/blocks/:blockId/items/:blockItemId", component: <BlockItem {...props}/> },
    { path: "/blocks/:id/items", component: <BlockItems {...props}/> },
    { path: "/blocks/:id", component: <Block {...props}/> },
    { path: "/blocks", component: <Blocks {...props}/> },
    { path: "/templates/:id/:type", component: <UserTemplate {...props}/> },
    { path: "/templates", component: <UserTemplates {...props}/> },
    { path: "/integrations", component: <Integrations {...props}/> },
    { path: "/usergroups/:id", component: <UserGroup {...props}/> },
  ];

	return (
    <React.Fragment>
      {pipedriveConnectionLost && pipedriveAuthorizeUrl && (
        <PipedriveDialog pipedriveAuthorizeUrl={pipedriveAuthorizeUrl} />
      )}
      <Layout navItems={navItems} dropdownItems={dropdownItems}>
        {currentUser.isRestricted && (
          <Alert color="danger">
            No active subscription. <Link to="/billing">Please add billing details and select subscription</Link>
          </Alert>
        )}
        
        <Switch>
          {currentUser.role !== EUserRole.SALES_PERSON
            ? adminRoutes.map(({ path, component }) => (
                <Route path={path} element={component} key={path} />
              ))
            : null}
          <Route
            path={"/offer"}
            element={<OfferAccept {...props} hasNavigation />}
          />
          <Route path={"/profile"} element={<Profile {...props}/>} />
          <Route path={"/customers/:id"} element={<Customer {...props}/>} />
          <Route path={"/customers"} element={<Customers {...props}/>} />
          <Route
            path={`/offers/:id/:type`}
            element={<Offer {...props}/>}
          />
          <Route
            path={`/offers/${EPageView.LIST}/*`}
            element={<Offers {...props} type={EPageView.LIST} />}
          />
          <Route
            path={`/offers/${EPageView.FUNNEL}/*`}
            element={<Offers {...props} type={EPageView.FUNNEL} />}

          />

          <Route
            path={`/offers/oldfunnel`}
            element={<Offers {...props} type={EPageView.FUNNEL} old={true}/>}
          />


          {/*<Route
            path={"/offer"}
            render={routeParams => (
              <OfferAccept {...routeParams} hasNavigation />
            )}
          
          <Route path={"/profile"} element={Profile} />
          <Route path={"/customers/:id"} element={Customer} />
          <Route path={"/customers"} element={Customers} />
          <Route
            path={`/offers/:id/:type`}
            render={routeParams => <Offer {...routeParams} />}
          />
          <Route
            path={`/offers/${EPageView.LIST}`}
            render={routeParams => (
              <Offers {...routeParams} type={EPageView.LIST} />
            )}
          />
          <Route
            path={`/offers/${EPageView.FUNNEL}`}
            render={routeParams => (
              <Offers {...routeParams} type={EPageView.FUNNEL} />
            )}
          />

          <Route
            path={`/offers/oldfunnel`}
            render={routeParams => (
              <Offers {...routeParams} type={EPageView.FUNNEL} old={true}/>
            )}
          />
          */}

          <Route path={"/contracts"} element={<Contracts {...props}/>} />
          <Route path={"/statistics"} element={<Statistics {...props}/>} />

          <Route path={"/auth-error"} element={<AuthError {...props}/>} />
          <Route path={"*"} element={<Navigate to="/offers/funnel" replace/>} />
          {/*<Redirect to={"/offers/funnel"} />*/}
        </Switch>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    redirect: state.auth.redirect,
		currentUser: state.auth.currentUser,
    pipedriveConnectionLost: state.auth.pipedriveConnectionLost,
    pipedriveAuthorizeUrl: state.auth.pipedriveAuthorizeUrl
  };
};

export default withRouter(connect(mapStateToProps, null)(Default));
