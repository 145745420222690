import React, { useEffect, useState } from "react";

import ReactGA from "react-ga";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  //Redirect,
  Route,
  //RouteComponentProps,
  Routes,
  Navigate,

  //withRouter
} from "react-router";

import { withRouter, WithRouter, RouteComponentProps } from './withRouter';

import ListSpinner from "./components/UI/ListSpinner/ListSpinner";
import Auth from "./containers/Auth/Auth";
import ForgotForm from "./containers/Auth/Password/ResetPassword/ForgotForm";
import ResetForm from "./containers/Auth/Password/ResetPassword/ResetForm";
import Register from "./containers/Register/Register";
import AuthError from "./containers/Auth/AuthError";
import EUserRole from "./enums/user-roles";

import { IAppState, IUser } from "./interfaces";
import Admin from "./sections/admin/containers/Admin";
import Default from "./sections/default/containers/Default";
import OfferAccept from "./sections/default/containers/Offers/OfferAccept/OfferAccept";

import VismaSign from "./sections/default/containers/Offers/OfferAccept/VismaSign";

import EmbeddedOffer from "./sections/default/containers/Embedded/EmbeddedOffer";

import axios from "./axios";

import * as actions from "./store/actions";

interface IStateProps {
  isAuthenticated: boolean;
  currentUser: IUser;
}

interface IDispatchProps {
  onGetCurrentUser: () => {};
  onStoreAuthRedirect: (redirectUrl: string) => {};
}

interface IProps extends IDispatchProps, IStateProps, RouteComponentProps {}

//const Test = () => (<div>test</div>);

const App: React.FC<IProps> = props => {

  const {
  onGetCurrentUser,
  onStoreAuthRedirect,
  isAuthenticated,
  currentUser,
  location,
  //history
  } = props;

  const [loading, setLoading] = useState(true);

  const { pathname, search } = location;
  const redirectUrl = pathname + search;
  const { i18n } = useTranslation();

  const [ initializeUserId, setInitializedUserId ] = useState<any>(null);

/*
  history.listen((location)=> {
    if(currentUser && initializeUserId == null) {
      setInitializedUserId(currentUser);
      ReactGA.set({userId: currentUser.email});
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
*/
  useEffect(()=>{  

      
      if(currentUser != null) {
        var heartbeat = setInterval(()=>{
          axios.get("/json/heartbeat").catch((resp)=>{
            clearInterval(heartbeat);
          });
        },5*1000*60);
      }

   },[currentUser]);


  useEffect(() => {
    // const splitpath=pathname.split("/");
    // if(splitpath.length>0 && splitpath[1]==="embedded") {
    //   setLoading(false);
    // }
    // else {
      (async () => {
        await onGetCurrentUser();
        if (redirectUrl && redirectUrl !== "/" && redirectUrl !== "/login") {
          onStoreAuthRedirect(redirectUrl);
        }
        setLoading(false);
      })();
    // }
  }, [onGetCurrentUser, redirectUrl, onStoreAuthRedirect]);

  useEffect(() => {
    if (isAuthenticated) {
      // TODO: Use user language
      // If not authenticated will use browser language or fallback to en
      i18n.changeLanguage("en");
    }
  }, [isAuthenticated, i18n]);

  if (loading) {
    return <ListSpinner />;
  }
  let routes = (
    <Routes>
      <Route path={"/embedded/:type/:id/:dealid/:userEmail"} element={<EmbeddedOffer {...props} />} />
      <Route path="/login"  element={<Auth {...props} />} />
      <Route path="/register"  element={<Register {...props} />} />
      <Route path="/forgot"  element={<ForgotForm {...props} />} />
      <Route path="/reset-password"  element={<ResetForm {...props} />} />
      <Route path={"/offer"} element={<OfferAccept {...props} />} />
      <Route path={"/vismasign"} element={<VismaSign {...props} />} />
      <Route path={"/auth-error"} element={<AuthError/>} />

      <Route path={"*"} element={<Navigate to="/login" replace/>} />

      {/*<Redirect to="/login" />*/}
    </Routes>
  );
  if (isAuthenticated) {
    switch (currentUser.role) {
      case EUserRole.SUPERADMIN:
        routes = (
          <Routes>
            <Route path="/admin/*" element={<Admin {...props} />} />
            <Route path={"*"} element={<Navigate to="/admin" replace/>} />

            {/*<Redirect to="/admin" />*/}
          </Routes>
        );
        break;
      case EUserRole.ENTREPRENEUR:
      case EUserRole.SALES_PERSON:
      case EUserRole.ADMIN:
      case EUserRole.TEAM_LEAD:
        routes = (
          <Routes>            
            <Route path={"/embedded/:type/:id/:dealid/:userEmail"} element={<EmbeddedOffer {...props} />} />

            <Route path="*" element={<Default/>} />
            {/*<Redirect to="/offers/funnel" />*/}
          </Routes>
        );
        break;
      default:
    }
  }
  return routes;
    
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    isAuthenticated: state.auth.currentUser !== null,
    currentUser: state.auth.currentUser
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
    onStoreAuthRedirect: redirectUrl =>
      dispatch(actions.storeAuthRedirect(redirectUrl))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    React.memo(App, (prevProps, nextProps) => {
      return prevProps.isAuthenticated === nextProps.isAuthenticated;
    })
  )
);
