import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { Alert, Button, Row, Col } from "reactstrap";
//import ListContainer from "../../../../../../components/ListContainer/ListContainer";
//import ListHeader from "../../../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../../../components/UI/ListSpinner/ListSpinner";
import { IAppState, IBlock, IBlockItem } from "../../../../../../interfaces";
import * as actions from "../../../../../../store/actions";
//import BlockItemsList from "../../../../components/Blocks/Block/BlockItems/BlockItemsList/BlockItemsList";

import BlockItemEdit from "../../../../components/Blocks/Block/BlockItems/BlockItemEdit/BlockItemEdit";
//import Block from "../../Block/Block";

import CustomDialog from "../../../../../../components/UI/CustomDialog/CustomDialog";

import classes from "./BlockItemsCombined.module.scss";


interface IStateProps {
  loading: boolean;
  error: string;
  block: IBlock;
  blockItems: Array<IBlockItem>;
  blockItemError: string;
  blockItemLoading: boolean;
}

interface IDispatchProps {
  onGetBlock: (id: string) => Promise<IBlock>;
  onFindBlockItems: (blockId: string) => Promise<IBlockItem>;

  onSaveBlockItem: (blockId: string, blockItem: IBlockItem) => Promise<boolean>;
  onUpdateBlockItem: (
    blockId: string,
    blockItemId: string,
    blockItem: IBlockItem
  ) => Promise<boolean>;
  onDeleteBlockItem: (blockItemId: string) => Promise<boolean>;

}

interface IProps 
 extends IStateProps, IDispatchProps {  id: string; onCancel:()=>void; }


const BlockItemsCombined: React.FC<IProps> = ({
  loading,
  error,
  block,
  blockItemLoading,
  blockItemError,
  blockItems,
  onGetBlock,
  onFindBlockItems,
  id,

  onSaveBlockItem,
  onUpdateBlockItem,
  onDeleteBlockItem,

	onCancel,

}) => {

  useEffect(() => {
    onGetBlock(id);
    onFindBlockItems(id);
  }, [onGetBlock, onFindBlockItems, id]);

  let title = "";
  if (block) {
    title = block.name;
  }

	const [editBlockItemId,setEditBlockItemId] = useState(null);
	const [editBlockId,setEditBlockId] = useState(null);

	const contentRef = useRef(null);

  const doSave = async () => {
    const newBlockItem = contentRef.current.getData();

		//    let success = false;
    if (editBlockItemId === "add") {
			//			success =
				await onSaveBlockItem(id, newBlockItem);
    } else {
			//			success =
				await onUpdateBlockItem(id, editBlockItemId, newBlockItem);
    }
		setEditBlockItemId(null);
		onFindBlockItems(id);
  };

	const deleteBlock = async () => {
    await onDeleteBlockItem(editBlockItemId);
		setEditBlockItemId(null);
		onFindBlockItems(id);
  };

  const onCreate = () => {
		setEditBlockItemId("add");
  };

  return (<>
		<div>
			{title}
		</div>
		<hr/>


      {blockItemLoading ? (
        <ListSpinner style={{ marginTop: "1rem" }} />
      ) : blockItemError ? (
        <Alert color="danger">{blockItemError}</Alert>
      ) : (<div>

		        {(blockItems || []).map(blockItem => (
							<Row key={blockItem.id} className={classes.Row}>
								<Col><b>{blockItem.name}</b></Col>
								<Col xs={2} className="text-right"><Button size="sm" color="info" onClick={()=>setEditBlockItemId(blockItem.id)}>Edit</Button></Col>
							</Row>
						))}

			</div>)}

			<br/>
			{/*
		<Button color="info" onClick={()=>setEditBlockId(id)}>Edit element</Button>
		<Button color="link" onClick={onCancel}>Discard</Button>{' '}
			*/}

		<Button color="info" onClick={onCancel}>Close</Button>{' '}

		<div className="float-right">
        <Button color="primary" onClick={onCreate} disabled={loading}>
            Add item
        </Button>
		</div>

    <CustomDialog
      loading={false}
      onCancel={()=>setEditBlockId(null)}

      open={editBlockId!==null}
      title="Multi Item Element"
      isValid={true}
			hideButtons={true}

			overflow={true}
    >
			Edit in Templates -&gt; Multi Item Elements
		{/*<Block id={id}/>*/}
    </CustomDialog>


    <CustomDialog
      loading={false}
      onCancel={()=>setEditBlockItemId(null)}

      open={editBlockItemId!==null}
      title="Update Multi Item Element"
      isValid={true}
			hideButtons={true}
			footer={<div>
					<Button color="info" onClick={doSave}>Save</Button>{' '}
					<Button color="link" onClick={()=>setEditBlockItemId(null)}>Discard</Button>{' '}
					<Button color="danger" onClick={deleteBlock}>Delete</Button>
				</div>}
	
			overflow={true}
    >
	    <BlockItemEdit showTitle={false} blockId={id} blockItemId={editBlockItemId} ref={contentRef} setIsValid={()=>{}} />
    </CustomDialog>

	</>);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.blocks.loading,
    error: state.blocks.error,
    block: state.blocks.block,
    blockItems: state.blocks.blockItems,
    blockItemLoading: state.blocks.blockItemLoading,
    blockItemError: state.blocks.blockItemError
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetBlock: id => dispatch(actions.getBlock(id)),
    onFindBlockItems: blockId => dispatch(actions.findBlockItems(blockId, null)),

    onSaveBlockItem: (blockId, blockItem) =>
      dispatch(actions.saveBlockItem(blockId, blockItem)),
    onUpdateBlockItem: (blockId, blockItemId, blockItem) =>
      dispatch(actions.updateBlockItem(blockId, blockItemId, blockItem)),
    onDeleteBlockItem: (blockItemId) => dispatch(actions.deleteBlockItem(blockItemId)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
	null,
	{ forwardRef: true }
)(BlockItemsCombined);
