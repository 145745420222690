import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
//import { useContext } from "react";
import { IBlockContentSignatures, ISignature } from "../../../../../../../interfaces";
import { IBlockContentEditItem } from "../../BlockContentEdit";

import { ESignerType } from "../../../../../../../enums";

import { Container, Row, Col, Label } from 'reactstrap'

import IconButton from "../../../../../../../components/UI/IconButton/IconButton";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//import OfferContext from "../../../../../containers/Offers/Offer/OfferContext";

import {
  controlsToFormGroups,
} from "../../../../../../../shared/utility";

import CustomInput from './CustomInput';


const BlockContentSignaturesEdit: React.ForwardRefExoticComponent<
  IBlockContentEditItem
> = forwardRef((props, ref) => {
  const blockContent = props.blockContent as IBlockContentSignatures;
  //const { offer } = useContext(OfferContext);

  const [refresh, setRefresh] = useState<string>(Math.random().toString(36));

  const updateControls = (jsonData:IBlockContentSignatures) => ({
    controls: {
      signatures:Object.values(jsonData.signatures).reduce((resp:any,ob:ISignature,index:number)=>{
        
            resp[index]={
               elementType: "input",
                elementConfig: {
                  placeholder: "Field text",
                  type: "custom"
                },

                touched: false,
                
                name: ob.name || "",
                email: ob.email || "",
                phone: ob.phone || "",
                title: ob.title || "",
                timestamp: ob.timestamp || null,
                image: ob.image || "",
                signerType: ob.signerType || ESignerType.OTHER,

                index:index,

                custom:CustomInput,

                //onItemChange:inputItemChangedHandler,
                onChange:inputChangedHandler,
                onDelete:onDeleteHandler,
            }
       
          return resp
        },{})
    },
         
  })

	const updateFields = (data:IBlockContentSignatures) => {
    setJsonData({...data}); // slow
    setControlsState(updateControls(data));
	}
/*
  const inputItemChangedHandler = (index: number, name:string, value: any) => {
    console.log("1",index,name,value);
    const p=[...jsonData.signatures]
    const s={...p[index]}
    //s["item"]=value;
    p[index]=s;
    updateFields({...jsonData,signatures:p})
  }
*/
  const inputChangedHandler = (index: number, name: keyof ISignature, value: any) => {
    jsonData.signatures[index][name]=value;
		updateFields(jsonData);
  }

  const onDeleteHandler = (index: number) => {
    jsonData.signatures.splice(index,1)
    updateFields(jsonData);
    setRefresh(Math.random().toString(36));
  }

  const addField = (st:ESignerType) => {
    if(st === ESignerType.USER) {
      jsonData.signatures.splice(0,0,{name:"",email:"",phone:"",title:"",timestamp:null,image:"", signerType:st});
    }
    else {
      jsonData.signatures.push({name:"",email:"",phone:"",title:"",timestamp:null,image:"", signerType:st});
    }
		updateFields(jsonData);
  }

  const getData = () => {
    return Object.assign(blockContent, {signatures: jsonData.signatures});
  }

  const [jsonData, setJsonData]=useState<IBlockContentSignatures>(blockContent);

  const [init, setInit] = useState(false);
  const [controlsState, setControlsState] = useState(updateControls(jsonData));


  useImperativeHandle(ref, () => ({
    getData
  }));
  
  // TODO: this is slowing down
  useEffect(() => {
    if(props.onChange && init) {
      props.onChange(getData())
    } 
    // eslint-disable-next-line
  }, [jsonData]);

  useEffect(() => {
    setInit(true);
  }, [])

  let blurHandler:any = null;

    if(props.onBlur) {
      blurHandler = () => {
        props.onBlur(getData());
      }
    }

  const isType = (st:ESignerType) => {
    return Object.values(controlsState.controls.signatures).find((ob:any)=>(ob.signerType == st));
  }

  return (
    <React.Fragment>
      <Label>{blockContent.name}</Label>

      <Container key={"id_"+refresh}>
        
          {controlsToFormGroups(controlsState.controls.signatures, null, blurHandler)}
        
      </Container>

      <Row>
        {!isType(ESignerType.USER) &&
        <Col>
          <IconButton
            style={{ marginTop: "1rem", fontWeight: 500 }}
            color="light"
            icon={faPlus}
            onClick={()=>addField(ESignerType.USER)}
          >
            Add user
          </IconButton>
        </Col>}

        {!isType(ESignerType.CONTACT) &&
        <Col>
          <IconButton
            style={{ marginTop: "1rem", fontWeight: 500 }}
            color="light"
            icon={faPlus}
            onClick={()=>addField(ESignerType.CONTACT)}
          >
            Add contact
          </IconButton>
        </Col>}

        <Col>
          <IconButton
            style={{ marginTop: "1rem", fontWeight: 500 }}
            color="light"
            icon={faPlus}
            onClick={()=>addField(ESignerType.OTHER)}
          >
            Add signer
          </IconButton>
        </Col>

        <Col>
          <IconButton
            style={{ marginTop: "1rem", fontWeight: 500 }}
            color="light"
            icon={faPlus}
            onClick={()=>addField(ESignerType.WATCH)}
          >
            Add viewer
          </IconButton>
        </Col>
      </Row>
      

    </React.Fragment>
  );
});

  export default BlockContentSignaturesEdit;
