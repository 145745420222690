export enum EOfferListType {
  CONTRACTS = "CONTRACTS",
  OFFERS = "OFFERS",
  OFFERS_AND_CONTRACTS = "OFFERS_AND_CONTRACTS",
}

export enum EOfferSort {
  CUSTOMER_NAME = "CUSTOMER_NAME",
  STATUS = "STATUS",
  UPDATED = "UPDATED",
  EXPIRATION_DATE = "EXPIRATION_DATE",
  CREATED = "CREATED",
  AUTHOR_NAME = "AUTHOR_NAME",
  CONFIRM_DATE = "CONFIRM_DATE"
}
