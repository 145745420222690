import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IOffer } from "../../../../../../interfaces";
import { useTranslation } from "react-i18next";

import { VISMA_API_PATH } from '../../../../containers/Offers/OfferAccept/VismaSign';
import classes from "./OfferActions.module.scss";

interface IProps {
  offer: IOffer;
  onForward: () => void;
}

const OfferActions: React.FC<IProps> = ({ offer, onForward }) => {
  const { t } = useTranslation();
  
  if (!offer) return null;


  const downloadAttachmentsHandler = () => {
    window.open(offer.downloadLink, "_blank");
  };

	const printOfferHandler = () => {
    if(offer.strongAuthentication) {
      const businessID=offer.organization.businessID;
	    const sid=offer.signedPDF?"sid="+offer.signedPDF:"id="+offer.strongAuthentication+"&businessid="+businessID;
			window.open(VISMA_API_PATH+"/getfile/?"+sid) //id="+offer.strongAuthentication)

		} else {
      //window.open(offer.printLink, "_blank");
      window.open(offer.printLink.split("?")[0]+"?url="+encodeURIComponent(window.location.href+"&printable=true"),"_blank");
		}
  };

  const actions = [];

  if (offer.hasAttachments) {
    actions.push({
      onClick: downloadAttachmentsHandler,
      icon: faDownload,
      text: t('offerPreview.zipButton')
    });
  }

  actions.push({
    onClick: printOfferHandler,
    icon: faPrint,
    text: t('offerPreview.printButton')
  });

  /*
  actions.push({
    onClick: onForward,
    icon: faShare,
    text: "Forward"
  });
  */

  return (
		<div className={classes.Container}>
      {actions.map(action => (
        <div
          className={classes.Action}
          onClick={action.onClick}
          key={action.text}
        >
          <FontAwesomeIcon
            className={classes.ActionIcon}
            size="2x"
            icon={action.icon}
          />
          <p className={classes.ActionText}>{action.text}</p>
        </div>
      ))}
    </div>
  );
};

export default OfferActions;
