import React from 'react';
import { Table } from 'reactstrap';

import { IOrganization, IOffer } from '../../../../interfaces';
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from '../../../../withRouter';

import classes from "./OrganizationsList.module.scss";

interface IProps extends RouteComponentProps {
	organizations: Array<IOrganization>;
	offers: any; //Array<IOffer>;
}

const OrganizationsList: React.FC<IProps> = ({ organizations, offers, history }) => {
    
    if (!organizations || !organizations.length) {
        return null;
    }

    const onEdit = (organization: IOrganization) => {
        history.push(`/admin/organizations/${organization.id}`);
    };
	
	const confirmed = (id:string) => {
		if(Object.keys(offers || {}).includes(id)) {
			const conf=offers[id].filter((ob:IOffer)=>ob.status==="CONFIRMED").length
			return conf+" / "+offers[id].length
		}
		return null
	}
	

    const isActive = (integrations:any, name:string) => {
        try { if(integrations[name].active) return name; } catch(err) {console.log(err)}
        return "";
    }

    return (
        <Table className={classes.Table} responsive borderless striped>
            <thead className={classes.TableHeader}>
                <tr>
                    <th>Name</th>
                    <th>Level</th>
                    <th>Email</th>
					<th>Created</th>
				    <th align="center">Confirmed / Proposals</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {organizations.map((organization, i) => (
                    <tr
                        className={classes.TableRow}
                        key={organization.id || `${organization.name}_${i}`}
                        onClick={() => onEdit(organization)}
                        style={{ cursor: 'pointer' }}
                    >
                        <td>{organization.name}</td>
                        <td>{organization.level}</td>
                        <td>{organization.contactEmail}</td>
						<td>{organization.created}</td>
						<td align="center">{offers && <>{confirmed(organization.id)}</>}</td>
                        <td>
                            {isActive(organization.integrations,"procountor")}
                            {isActive(organization.integrations,"hubspot")}
                            {isActive(organization.integrations,"salesforce")}
                            {isActive(organization.integrations,"google")}
                            {isActive(organization.integrations,"severa")}
                            {isActive(organization.integrations,"netvisor")}
                            {isActive(organization.integrations,"ssoSAML")}
                            {isActive(organization.integrations,"vismaSign")}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default OrganizationsList;
