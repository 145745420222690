import { ActionTypes } from './../actions/actionTypes';
import { IPaymentMethodState, IPaymentMethodAction } from "../../interfaces";
import { updateObject } from '../../shared/utility';

const initialState:IPaymentMethodState = {
    loading: false,
    error: null,
    paymentMethod: null,
};

const getPrimaryPaymentMethodStart = (state:IPaymentMethodState, action:IPaymentMethodAction):IPaymentMethodState => {
  return updateObject(state, { error: null, loading: true});
}

const getPrimaryPaymentMethodSuccess = (state:IPaymentMethodState, action:IPaymentMethodAction):IPaymentMethodState => {
  return updateObject(state, {
      error: null,
      loading: false,
      paymentMethod: action.paymentMethod
  });
}

const getPrimaryPaymentMethodFail = (state:IPaymentMethodState, action:IPaymentMethodAction):IPaymentMethodState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const savePaymentMethodStart = (state:IPaymentMethodState, action:IPaymentMethodAction):IPaymentMethodState => {
  return updateObject(state, { error: null, loading: true});
}

const savePaymentMethodSuccess = (state:IPaymentMethodState, action:IPaymentMethodAction):IPaymentMethodState => {
  return updateObject(state, {
      error: null,
      loading: false,
      paymentMethod: action.paymentMethod
  });
}

const savePaymentMethodFail = (state:IPaymentMethodState, action:IPaymentMethodAction):IPaymentMethodState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

// const findPaymentMethodsStart = (state:IBillingState, action:IBillingAction):IBillingState => {
//   return updateObject(state, { error: null, loading: true});
// }

// const findPaymentMethodsSuccess = (state:IBillingState, action:IBillingAction):IBillingState => {
//   return updateObject(state, {
//       error: null,
//       loading: false,
//       paymentMethods: action.paymentMethods
//   });
// }

// const findPaymentMethodsFail = (state:IBillingState, action:IBillingAction):IBillingState => {
//   return updateObject(state, {
//       error: action.error,
//       loading: false
//   });
// };

const reducer = (state:IPaymentMethodState = initialState, action:IPaymentMethodAction):IPaymentMethodState => {
  switch (action.type) {
      case ActionTypes.GET_PRIMARY_PAYMENT_METHOD_START : return getPrimaryPaymentMethodStart(state, action);
      case ActionTypes.GET_PRIMARY_PAYMENT_METHOD_SUCCESS : return getPrimaryPaymentMethodSuccess(state, action);
      case ActionTypes.GET_PRIMARY_PAYMENT_METHOD_FAIL : return getPrimaryPaymentMethodFail(state, action);
      case ActionTypes.SAVE_PAYMENT_METHOD_START : return savePaymentMethodStart(state, action);
      case ActionTypes.SAVE_PAYMENT_METHOD_SUCCESS : return savePaymentMethodSuccess(state, action);
      case ActionTypes.SAVE_PAYMENT_METHOD_FAIL : return savePaymentMethodFail(state, action);
      // case ActionTypes.FIND_PAYMENT_METHODS_START : return findPaymentMethodsStart(state, action);
      // case ActionTypes.FIND_PAYMENT_METHODS_SUCCESS : return findPaymentMethodsSuccess(state, action);
      // case ActionTypes.FIND_PAYMENT_METHODS_FAIL : return findPaymentMethodsFail(state, action);
      default: return state;
  }
}

export default reducer;
