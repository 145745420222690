import { faPen } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import SidebarNavigation from "../../../../../../components/UI/SidebarNavigation/SidebarNavigation";
import { EPageView } from "../../../../../../enums";
import { IUserTemplateArea, IUserTemplateBlockDraggable, IUserTemplateEdit, IOrganization, IUserGroup, IUser } from "../../../../../../interfaces";
//import TemplateSidebarAdd from "./TemplateSidebarAdd/TemplateSidebarAdd";
import TemplateSidebarEdit from "./TemplateSidebarEdit/TemplateSidebarEdit";

import classes from "./TemplateSidebar.module.scss";

export enum ETemplateSidebarTab {
  EDIT,
  ADD,
  TEMPLATE
}

interface IProps {
  addableItems: Array<IUserTemplateBlockDraggable>;
  loading: boolean;
  activeBlock: IUserTemplateBlockDraggable;
  activeArea: IUserTemplateArea;
  userTemplate: IUserTemplateEdit;
  setActiveBlock: (item: IUserTemplateBlockDraggable) => void;
  setActiveArea: (area: IUserTemplateArea) => void;
  updateBlock: (block: IUserTemplateBlockDraggable) => void;
  deleteBlock: (block: IUserTemplateBlockDraggable) => void;
  updateUserTemplate: (userTemplate: IUserTemplateEdit) => void;
  onCreateBlock: () => void;
	onViewChanged: (pageView: EPageView) => void;
  organization: IOrganization;
  userGroups: Array<IUserGroup>;
  currentUser: IUser;
}

const TemplateSidebar: React.FC<IProps> = ({
  addableItems,
  loading,
  activeBlock,
  activeArea,
  userTemplate,
  setActiveBlock,
  setActiveArea,
  updateBlock,
  deleteBlock,
  updateUserTemplate,
  onCreateBlock,
	onViewChanged,
	organization,
  userGroups,
  currentUser,
}) => {
  const [tab, setTab] = useState(ETemplateSidebarTab.TEMPLATE); //ADD);

  useEffect(() => {
    if (activeBlock || activeArea) {
      setTab(ETemplateSidebarTab.EDIT);
    }
  }, [activeBlock, activeArea, setTab]);

  const tabClickHandler = (tab: ETemplateSidebarTab) => {
//    if (tab === ETemplateSidebarTab.ADD) {
//      onViewChanged(EPageView.EDIT);
//    }
    if (tab === ETemplateSidebarTab.TEMPLATE || tab === ETemplateSidebarTab.ADD) {
      setActiveBlock(null);
      setActiveArea(null);
    }
    setTab(tab);
  };

//  const templateTab = { id: ETemplateSidebarTab.TEMPLATE, title: "Template details", icon: faPen };

	const tabs:any[]=[{ id: ETemplateSidebarTab.TEMPLATE, title: "Template details", icon: faPen }];
//	if(tab===ETemplateSidebarTab.EDIT) { tabs.push( { id: ETemplateSidebarTab.EDIT, title: "Edit element", icon: faPen} ) }
/*
  const tabs = [ 
	  { id: ETemplateSidebarTab.EDIT, title: "Edit", icon: faPen },
    { id: ETemplateSidebarTab.ADD, title: "Add new element", icon: faPlus }
  ];
*/
  return (
    <React.Fragment>
{/*      <SidebarNavigation tabs={tabs} setTab={tabClickHandler} current={tab} topTab={templateTab} />*/}
      <SidebarNavigation tabs={tabs} setTab={tabClickHandler} current={tab} />

      <div className={classes.Container}>
        {(() => {
          switch (tab) {
//            case ETemplateSidebarTab.ADD:
//              return (
//                <TemplateSidebarAdd blocks={addableItems} loading={loading} onCreateBlock={onCreateBlock} />
//              );
	          case ETemplateSidebarTab.EDIT:
            case ETemplateSidebarTab.TEMPLATE:
              return (
                <TemplateSidebarEdit
                  block={activeBlock}
                  updateBlock={updateBlock}
                  deleteBlock={deleteBlock}
                  area={activeArea}
                  setActiveArea={setActiveArea}
                  userTemplate={userTemplate}
									updateUserTemplate={updateUserTemplate}
									organization={organization}
                  userGroups={userGroups}
                  currentUser={currentUser}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </React.Fragment>
  );
};

export default TemplateSidebar;
