import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { EContentType } from "../../../../../../enums/content-types";
import { IBlockContent } from "../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  initForm
} from "../../../../../../shared/utility";
import ContentTypeItemContent from "./ContentTypeItemContent/ContentTypeItemContent";
import ContentTypeItemCustom from "./ContentTypeItemCustom/ContentTypeItemCustom";
import ContentTypeItemImage from "./ContentTypeItemImage/ContentTypeItemImage";
import ContentTypeItemVisual from "./ContentTypeItemVisual/ContentTypeItemVisual";
import ContentTypeItemAttachment from "./ContentTypeItemAttachment/ContentTypeItemAttachment";

import ContentTypeFields from "./ContentTypeFields/ContentTypeFields";
import ContentTypeProducts from "./ContentTypeProducts/ContentTypeProducts";
import ContentTypeProducts2 from "./ContentTypeProducts2/ContentTypeProducts2";
import ContentTypeProducts3 from "./Private/ContentTypeProducts3/ContentTypeProducts3";

import ContentTypeSignatures from "./ContentTypeSignatures/ContentTypeSignatures";
import ContentTypeHTML from "./ContentTypeHTML/ContentTypeHTML";

import { EUserTemplateBlockType } from "../../../../../../enums";

import { initControls } from './ContentTypeDialog';

interface IProps {
  blockContent: IBlockContent;
  blockType: EUserTemplateBlockType;
	setContentTypeControls: (x:any) => void;
	ref:any,
}
/*
const initControls = {
  controls: {
    name: {
      elementType: "input",
      elementConfig: {
        label: "Name",
        placeholder: "Name",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    }
  },
  formIsValid: false
};
*/
export interface IContentTypeItemRef {
  getData: () => Object;
}

//const ContentTypeEdit: React.FC<IProps> = forwardRef(({
const ContentTypeEdit: React.ForwardRefExoticComponent<IProps> = forwardRef(({

  blockContent,
  blockType,
	setContentTypeControls,
},ref) => {
  const contentTypeItemRef = useRef<IContentTypeItemRef>();

  const [contentType, setContentType] = useState<EContentType>(null);
  const [controlsState, setControlsState] = useState(initControls);
  //const [itemIsValid,setItemIsValid] = useState(false);
  const setItemIsValid = (x:boolean) => {}; // dummy function

	const getData = () => {
		return contentTypeItemRef.current.getData();
	};

	useEffect(() => {
		setContentTypeControls(controlsState);
	}, [controlsState]);

  useEffect(() => {
    let newControlsState = initControls;
    let newContentType = null;
    if (blockContent) {

      newControlsState = initForm(controlsState.controls, blockContent);
      newContentType = blockContent.contentType;

      switch (blockContent.contentType) {
        case EContentType.PRICING:
        case EContentType.DEAL_BETWEEN:
        case EContentType.PARTIES:
        case EContentType.TEAM_MEMBER:
        case EContentType.LEGAL_BOX:
        case EContentType.SIGNATURE:
          newContentType = EContentType.CUSTOM;
          break;
        default:
      }
    }
    setControlsState(newControlsState);
    setContentType(newContentType);
    // eslint-disable-next-line
  }, [blockContent]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) =>
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  
	useImperativeHandle(ref, () => ({
    getData
  }));

	return (<div>
			{/* Name */}
      {controlsToFormGroups(controlsState.controls, inputChangedHandler)}
      
			{(() => {
        switch (contentType) {
          case EContentType.CONTENT:
            return (
              <ContentTypeItemContent
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
								disableTypeChange={true}
              />
            );
          case EContentType.IMAGE:
            return (
              <ContentTypeItemImage
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.VISUAL:
            return (
              <ContentTypeItemVisual
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.ATTACHMENT:
            return (
              <ContentTypeItemAttachment
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.CUSTOM:
            return (
              <ContentTypeItemCustom
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
                blockType={blockType}
								disableTypeChange={true}
              />
            );

          case EContentType.FIELDS:
            return (
              <ContentTypeFields
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          case EContentType.PRODUCTS:
            return (
              <ContentTypeProducts
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.PRIVATE:
            return (
              <ContentTypeProducts2
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.PRIVATE2:
            return (
              <ContentTypeProducts3
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          case EContentType.SIGNATURES:
            return (
              <ContentTypeSignatures
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          case EContentType.HTML:
            return (
              <ContentTypeHTML
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          default:
            return null;
        }
      })()}
  </div>);
});

export default ContentTypeEdit;
