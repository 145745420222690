import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';

import { IOffer, IOrganization, IUser, IAppState, ISettings } from '../../../../../../../interfaces';
import { ESignerType } from "../../../../../../../enums";

//import { EPageView } from '../../../../../../../enums';

import * as actions from "../../../../../../../store/actions";

import { Spinner, Button, FormGroup, Label } from 'reactstrap';

import CustomSelect from '../../../../../../../components/UI/CustomSelect/CustomSelect';
import OfferContext from '../../../../../containers/Offers/Offer/OfferContext';
import { integrationParser } from '../../../../../../../shared/integrations';

import LoadDealsButton from '../../../../../containers/Integrations/LoadDealsButton';

import { getDeal } from '../../../../../containers/Integrations/Hubspot/Hubspot';
import { getDeal as severaGetDeal} from '../../../../../containers/Integrations/Severa/Severa';

import axios from "../../../../../../../axios";

import classes from "./OfferFromIntegration.module.scss";
import inputClasses from "../../../../../../../components/UI/Input/Input.module.scss";

interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  onGetCurrentUser: () => Promise<void>;
  onGetUserOrganization: () => Promise<IOrganization>;
}

interface IProps extends IStateProps, IDispatchProps {
  data?:any;
  embedded?:boolean;
}

const OfferFromIntegration: React.FC<IProps> = ({
  currentUser,
  organization,
  onGetUserOrganization,
  onGetCurrentUser,
  data,
  embedded,
}) => {
  const {
    offer,
    setOffer,
    userTemplates,
    customers,
  } = useContext(OfferContext);
  
  //const [oldOffer, setOldOffer] = useState(null);

  const [deals, setDeals] = useState({integration:null, data:[]});
  const [deal, setDeal] = useState(null);

  const [init, setInit] = useState(true);

  const [spin, setSpin] = useState(false);

  useEffect(() => {
    if (init) {
      onGetUserOrganization();
      onGetCurrentUser();

      setInit(false);
    }
    // eslint-disable-next-line
  }, [init]);


  useEffect(()=>{
    if(deal && offer) {
      if(offer.userTemplate) {
        const newOffer = updateData({...offer, userTemplate: {...offer.userTemplate, initialized: false}});
      }
    }
  },[deal]);


  useEffect(()=>{
    try {
      if(data.deal) {
        setDeal(data.deal);
      }
    } catch(err){}
  },[data]);

  const getUserTemplateOptions = () => {
    return deals.data.map((ob:any,index:any)=>({label:(ob.customer || {name:""}).name+" "+ob.name,id:index}))
  };

  const removeDeal = () => {
    const oid=[...(offer.offerIntegrationData || [])].filter((ob)=>ob && ob.type!=="HUBSPOT")
    setOffer({...offer,offerIntegrationData:oid})
    setDeal(null);
  }
  
  const selectDeal = (deal:any) => {
    if(deals.integration === "hubspot") {
      getDeal(organization.integrations,currentUser,deal.dealId).then((resp:any)=>{
        selectDeal2({...resp, integration:"hubspot"});
      });
    }
    else if(deals.integration === "severa") {
      severaGetDeal(organization.integrations,currentUser,deal.dealId).then((resp:any)=>{
        selectDeal3({...resp, integration:"severa"});
      });
    }
  }

  const selectDeal2 = (deal:any) => {
    setDeal(deal);
    
    const isCustomer=(customers || []).find((ob)=>(ob.name || "unknown").toLowerCase()===((deal.customer || {}).name || "unknown").toLowerCase())
    let newBlocks:any={main:[],header:[],footer:[]}

    if(offer.userTemplate) {
      newBlocks.header=replaceProducts(offer.userTemplate.header.blocks,deal);
      newBlocks.main=replaceProducts(offer.userTemplate.main.blocks,deal);
      newBlocks.footer=replaceProducts(offer.userTemplate.footer.blocks,deal);
    }

    const oid=[...(offer.offerIntegrationData || [])].filter((ob)=>ob && ob.type!=="HUBSPOT")
    oid.push({
      type:"HUBSPOT",
      name:"dealId",
      value:deal.dealId,
    })
    oid.push({
      type:"HUBSPOT",
      name:"portalId",
      value:deal.portalId,
    })

    setOffer({...offer,
      offerIntegrationData:oid,
      name:deal.name,
      customer:{
        ...offer.customer,
        businessID:isCustomer?isCustomer.businessID:"",
        name:deal.customer?deal.customer.name:"",
        address:isCustomer?(isCustomer.address):(deal.customer && (deal.customer.zip+" "+deal.customer.city)),
        contactPerson:deal.contact?(deal.contact.firstname+" "+deal.contact.lastname):(isCustomer?isCustomer.contactPerson:""),
        phone:deal.contact?(deal.contact.phone):(isCustomer?isCustomer.phone:""),
        email:deal.contact?(deal.contact.email):(isCustomer?isCustomer.email:""),
      },
      userTemplate:offer.userTemplate && {
        ...offer.userTemplate,
        header:{
          ...offer.userTemplate.header,
          blocks:newBlocks.header,
        },
        main:{
          ...offer.userTemplate.main,
          blocks:newBlocks.main,
        },
        footer:{
          ...offer.userTemplate.footer,
          blocks:newBlocks.footer,
        }

      }
    });
  };

  const selectDeal3 = (deal:any) => {
    setDeal(deal);
    
    const isCustomer=(customers || []).find((ob)=>(ob.name || "unknown").toLowerCase()===((deal.customer || {}).name || "unknown").toLowerCase())
    let newBlocks:any={main:[],header:[],footer:[]}

    if(offer.userTemplate) {
      newBlocks.header=replaceProducts(offer.userTemplate.header.blocks,deal);
      newBlocks.main=replaceProducts(offer.userTemplate.main.blocks,deal);
      newBlocks.footer=replaceProducts(offer.userTemplate.footer.blocks,deal);
    }

    const oid=[...(offer.offerIntegrationData || [])].filter((ob)=>ob && ob.type!=="SEVERA");
    oid.push({
      type:"SEVERA",
      name:"dealId",
      value:deal.dealId,
    })
    oid.push({
      type:"SEVERA",
      name:"portalId",
      value:deal.portalId,
    })

    setOffer({...offer,
      offerIntegrationData:oid,
      name:deal.name,
      customer:{
        ...offer.customer,
        businessID:deal.customer?(deal.customer.businessID):(isCustomer?isCustomer.businessID:""),
        name:deal.customer?deal.customer.name:"",
        address:deal.customer?deal.customer.address:"", //isCustomer?(isCustomer.address):(deal.customer && (deal.customer.zip+" "+deal.customer.city)),
        zip:deal.customer?deal.customer.zip:"",
        city:deal.customer?deal.customer.city:"",
        contactPerson:deal.contact?(deal.contact.firstname+" "+deal.contact.lastname):(isCustomer?isCustomer.contactPerson:""),
        phone:deal.contact?(deal.contact.phone):(isCustomer?isCustomer.phone:""),
        email:deal.contact?(deal.contact.email):(isCustomer?isCustomer.email:""),
      },
      userTemplate:offer.userTemplate && {
        ...offer.userTemplate,
        header:{
          ...offer.userTemplate.header,
          blocks:newBlocks.header,
        },
        main:{
          ...offer.userTemplate.main,
          blocks:newBlocks.main,
        },
        footer:{
          ...offer.userTemplate.footer,
          blocks:newBlocks.footer,
        }

      }
    });
  };


  const replaceProducts = (blocks:any,deal:any) => {
    //console.log(deal);
    //console.log("REPLACEPRODUCTS");
    if(blocks) {
      let newBlocks=[...blocks];

      newBlocks.forEach((b:any)=>{
        b.content=(b.content || []).reduce((resp:any,ob:any)=>{
          if(ob.contentType==="PRODUCTS" && deal.items && deal.items.length>0) {
            ob.currency=deal.items.reduce((resp:string, i:any)=>{
              if(i.currency) return i.currency;
              return resp;
            },"EUR");

            let unit ="";
            let vat = 0
            /*
            if(ob.currency.toLowerCase() == "eur" || ob.currency == "€") {
                vat = 24
            }
            */
            
            const _prods = ob.products.filter((fob:any)=>!fob.library);
            if(_prods.length === 1 && !_prods[0].item) {
              unit = _prods[0].unit;
              vat = _prods[0].vat;
            }

            const _old_vats = _prods.map((p:any, index:number)=>({name:p.item, vat:p.vat, unit:p.unit}));

            const _get_old_vat = (index:number, name:string) => {
              if( (index in _old_vats) && (name===_old_vats[index]["name"])) return _old_vats[index]["vat"];
              return vat;
            }

            const _get_old_unit = (index:number, name:string) => {
              if( (index in _old_vats) && (name===_old_vats[index]["name"])) return _old_vats[index]["unit"];
              return unit;
            }

            ob.products=deal.items.map((i:any, index:number)=>({
              item:i.name,
              unit:i.unit || _get_old_unit(index,i.name), // || "pcs",
              unitPrice:i.price,
              quantity:i.quantity,
              discount:i.discount, //0,
              library:false,
              optional:false,
              optionalSelected:false,
              quantityEditable:false,
              vat:_get_old_vat(index,i.name), //vat,
              description: i.description || "",

              category:i.recurringbillingfrequency || "",

              //hs_recurring_billing_period,
            }))
          }
          resp.push(ob)
          return resp
        },[]);
      })
      return newBlocks
    }
    return null
  }

  const replaceMultiSignature = (blocks:any,deal:any) => {
    if(blocks) {
      let newBlocks=[...blocks];
      newBlocks.forEach((b:any)=>{
        b.content=(b.content || []).reduce((resp:any,ob:any)=>{
          if(ob.contentType==="SIGNATURES") {
            ob.signatures=deal.contacts.reduce((resp:any, ob2:any) => {
              if(ob.signatures.some( (e:any) => e.email === ob2.email)) {
                return resp;
              }
              return [...resp, {signerType: ESignerType.WATCH, name: ob2.firstname+" "+ob2.lastname, email: ob2.email, phone: ob2.phone, title: ob2.jobtitle}];
            },[...ob.signatures]);
          }
          resp.push(ob)
          return resp
        },[]);
      })
      return newBlocks
    }
    return null
  }

  const replaceProductsPipedrive = (blocks:any,deal:any,currency:string) => {
    console.log("REPLACING",deal)
    if(blocks) {
      let newBlocks=[...blocks];

      newBlocks.forEach((b:any)=>{
        b.content=(b.content || []).reduce((resp:any,ob:any)=>{
          if(ob.contentType==="PRODUCTS" && deal.length>0) {
            ob.currency=currency;
            ob.products=deal.map((i:any)=>{
              if(i.tax) ob.tax="INCLUSIVE";
              return i;
            })
          }
          return [...resp,ob];
        },[]);
      })
      return newBlocks
    }
    return null
  }

  const replaceCustomFields = (blocks:any, customFields:Array<any>) => {
    if(blocks) {
      let newBlocks=[...blocks];

      newBlocks.forEach((b:any)=>{
        b.content=(b.content || []).reduce((resp:any,ob:any)=>{
                  
          if(ob.contentType==="CONTENT") {
            let c=ob.content;
            if(c) {
              customFields.forEach((cf:any) => {
                //console.log(cf);
                try {
                  //<span data-key="Opportunity_click2contract__Klikki__c">**this is in the klikki**</span>
                  //const re = /(<span data-key="Opportunity_click2contract__Klikki__c">)[^<>]*(<\/span>)/ig;
                  const re = new RegExp('(<c2c data-key="'+cf.apiKey+'">)[^<>]*(<\/c2c>)',"ig");
                  c = c.replaceAll(re, '<c2c data-key="'+cf.apiKey+'">'+(cf.value || "")+'</c2c>');

                  c=c.replaceAll("{{"+cf.apiKey+"}}","<c2c data-key=\""+cf.apiKey+"\">"+(cf.value || "")+"</c2c>");
                } catch(err) {}
              });
            
              ob.content=c;
            }
          }
          return [...resp,ob]
         },[]);
      })
      return newBlocks
    }
    return null
  }

  const updateCustomerDetails = (offer:IOffer) => {
    const detail = (item:keyof ISettings, def:string):string => {
      if(offer.userTemplate.settings[item]) {
        const ob=offer.customFields.find((d:any)=>d.apiKey===offer.userTemplate.settings[item]);
        if(ob) {
          return ob.value;
        }
      }
      return def;
    }


    if(offer.userTemplate && offer.customFields) {
      offer.userTemplate.initialized=true;

      offer.customer.businessID=detail("customerDetails_businessID",offer.customer.businessID);

      const _address=detail("customerDetails_address","");
      const _city=detail("customerDetails_city","");
      const _zip=detail("customerDetails_zip","");

      if(_address!=="" || _city!=="" || _zip!=="") {
        offer.customer.address=_address;
        offer.customer.city=_city;
        offer.customer.zip=_zip;
      }

      try {
        if(offer.customer.address.trim()=="null") {
          offer.customer.address="";
        }
      } catch(err) {}

      offer.customer.name=detail("customerDetails_name",offer.customer.name);
      offer.customer.contactPerson=detail("customerDetails_contactPerson",offer.customer.contactPerson);
      offer.customer.phone=detail("customerDetails_phone",offer.customer.phone);
      offer.customer.email=detail("customerDetails_email",offer.customer.email);

      replaceCustomFields(offer.userTemplate.main.blocks,offer.customFields);
    }
    return offer;
  }

  const updateData = (offer:IOffer) => {
    if(offer.userTemplate && offer.userTemplate.initialized) {
        return offer;
    }

    const detail = (item:keyof ISettings, def:string):string => {
      if(offer.userTemplate.settings[item]) {
        const ob=offer.customFields.find((d:any)=>d.apiKey===offer.userTemplate.settings[item]);
        if(ob) {
          return ob.value;
        }
      }
      return def;
    }


    if(offer.userTemplate && offer.customFields) {
      offer.userTemplate.initialized=true;

      offer.customer.businessID=detail("customerDetails_businessID",offer.customer.businessID);

      const _address=detail("customerDetails_address","");
      const _city=detail("customerDetails_city","");
      const _zip=detail("customerDetails_zip","");

      if(_address!=="" || _city!=="" || _zip!=="") {
        offer.customer.address=_address;
        offer.customer.city=_city;
        offer.customer.zip=_zip;
      }

      try {
        if(offer.customer.address.trim()=="null") {
          offer.customer.address="";
        }
      } catch(err) {}

      offer.customer.name=detail("customerDetails_name",offer.customer.name);
      offer.customer.contactPerson=detail("customerDetails_contactPerson",offer.customer.contactPerson);
      offer.customer.phone=detail("customerDetails_phone",offer.customer.phone);
      offer.customer.email=detail("customerDetails_email",offer.customer.email);

      replaceCustomFields(offer.userTemplate.main.blocks,offer.customFields);
    }

    if(offer.userTemplate && offer.pipedriveProducts) {
      const pdeal=offer.pipedriveProducts;
//      replaceProductsPipedrive(offer.userTemplate.header.blocks,pdeal,offer.pipedriveDeal.currency || "€");
      replaceProductsPipedrive(offer.userTemplate.main.blocks,pdeal,offer.pipedriveDeal.currency || "€");
//      replaceProductsPipedrive(offer.userTemplate.footer.blocks,pdeal,offer.pipedriveDeal.currency || "€");
    }

    if(offer.userTemplate && deal && deal.integration == "hubspot") {
//      replaceProducts(offer.userTemplate.header.blocks,deal);
      replaceProducts(offer.userTemplate.main.blocks,deal);
//      replaceProducts(offer.userTemplate.footer.blocks,deal);

      //replaceMultiSignature(offer.userTemplate.main.blocks, deal);
    }

    if(offer.userTemplate && deal && deal.integration == "salesforce") {
      //replaceProducts(offer.userTemplate.header.blocks,deal);
      replaceProducts(offer.userTemplate.main.blocks,deal);
      //replaceProducts(offer.userTemplate.footer.blocks,deal);

    }
    
    return offer
  }

  useEffect(() => {
//console.log(data);
      if(data && data.deal && (data.deal.integration == "salesforce" || data.deal.integration == "hubspot")) {
        let ofr = {...offer};

        if(ofr.userTemplate && !ofr.userTemplate.initialized) {
        if(offer.userTemplate) {
          try {
            //replaceProducts(ofr.userTemplate.header.blocks,data.deal);
            replaceProducts(ofr.userTemplate.main.blocks,data.deal);
            //replaceProducts(ofr.userTemplate.footer.blocks,data.deal);

            //replaceMultiSignature(ofr.userTemplate.main.blocks, data.deal);

          } catch(err) {}

          try {
            replaceCustomFields(ofr.userTemplate.main.blocks,data.deal.customFields);
            ofr.customFields = data.deal.customFields;
          } catch(err) {}
        }


        try {
          if(!ofr.customer.email) {
            ofr.customer.contactPerson=data.deal.contact.firstname+" "+data.deal.contact.lastname;
            ofr.customer.phone=data.deal.contact.phone;
            ofr.customer.email=data.deal.contact.email;

            ofr.customer.name = data.deal.customer.name;
            ofr.customer.address = data.deal.customer.address;
            ofr.customer.city = data.deal.customer.city;
            ofr.customer.zip = data.deal.customer.zip;
          }
        } catch(err) {

        }

        ofr = updateCustomerDetails(ofr);
        ofr.userTemplate.initialized=true;
        }


        setOffer(ofr);
      }

  },[data && data.deal, offer && offer.userTemplate]);


  const updateHubspot = () => {
    let ofr = {...offer};
    if(ofr.userTemplate) {
      const tmpl = {...ofr.userTemplate};
      try {
        //replaceProducts(tmpl.header.blocks,data.deal);
        replaceProducts(tmpl.main.blocks,data.deal);
        //replaceProducts(tmpl.footer.blocks,data.deal);

        //replaceMultiSignature(tmpl.main.blocks, data.deal);

      } catch(err) {}

      try {
        replaceCustomFields(tmpl.main.blocks,data.deal.customFields);
        ofr.customFields = data.deal.customFields;
      } catch(err) { console.log(err); }

      ofr.userTemplate = tmpl;
    }

    try {
      ofr.customer.contactPerson=data.deal.contact.firstname+" "+data.deal.contact.lastname;
      ofr.customer.phone=data.deal.contact.phone;
      ofr.customer.email=data.deal.contact.email;

      ofr.customer.name = data.deal.customer.name;
      ofr.customer.address = data.deal.customer.address;
      ofr.customer.city = data.deal.customer.city;
      ofr.customer.zip = data.deal.customer.zip;
    } catch(err) {}

    ofr = updateCustomerDetails(ofr);
    //console.log(ofr);

    setOffer(ofr);
  }


  useEffect(() => {
    const newOffer=updateData({...offer});
    setOffer(newOffer);
  },[offer.userTemplate]);

  const integrationData=integrationParser(offer.offerIntegrationData);

  const pipedriveGetDeal = (deal_id:string) => {
    setSpin(true);
    axios.get("json/offers2/pipedrive/getdeal?deal_id="+deal_id).then((resp)=>{

      const getCF = (apiKey:string,def:string) => {
        try {
          return resp.data.customFields.find((cf:any)=>cf.apiKey===apiKey).value;
        } catch(err) {}
        return def;
      }

      const updatecustomer={
        ...offer.customer,
        name:resp.data.organization && resp.data.organization.name || "",
        address:getCF("o-address_route",offer.customer.address)+" "+getCF("o-address_street_number",""),
        zip:getCF("o-address_postal_code",offer.customer.zip),
        city:getCF("o-address_locality",offer.customer.city),

        contactPerson:getCF("p-name",offer.customer.contactPerson),
        phone:getCF("p-phone",offer.customer.phone),
        email:getCF("p-email",offer.customer.email),
      };

      const reloadTemplate=offer.userTemplate?userTemplates.find(template=>template.id===offer.userTemplate.id):null;
      const newOffer=updateData({
        ...offer,
        userTemplate:{...reloadTemplate},
        customer:updatecustomer,
        customFields:resp.data.customFields,
        pipedriveProducts:resp.data.products,
        pipedriveDeal:{
          ...offer.pipedriveDeal,
          currency:resp.data.currency,
        },

      });

      // update template, all changes made will be deleted
      setOffer({...newOffer}); //,userTemplate:{...reloadTemplate}});
      //      setOffer({...newOffer,userTemplate:offer.userTemplate?{...offer.userTemplate}:null});

      setSpin(false);
    });
  }
        
  return (
    <div className={classes.Container}>
      
      {data && data.deal && data.deal.integration == "hubspot" && <div>
        <Button size="sm" color="primary" onClick={updateHubspot}>Update data from deal</Button>
      </div>}


      {/*(offer.offerIntegrationData || [])
        .filter((ob)=>(ob.type==="HUBSPOT"))
        .map((ob)=>(<div>{ob.value}</div>))*/}

      {offer.pipedriveDeal && offer.pipedriveDeal.id
          ?<Button disabled={spin} size="sm" color="primary" onClick={()=>pipedriveGetDeal(offer.pipedriveDeal.id)}>Update from Pipedrive deal {offer.pipedriveDeal.id} {spin && <Spinner style={{marginLeft:"1rem",width:"1rem",height:"1rem"}}/>}</Button>
        :<>
          {!embedded && <>
            <LoadDealsButton setDeals={setDeals}/>
            {integrationData.HUBSPOT && !data && <>
              {' '}<Button size="sm" target="_blank" href={"https://app.hubspot.com/contacts/"+integrationData.HUBSPOT.portalId+"/deal/"+integrationData.HUBSPOT.dealId+"/"}>Open in Hubspot</Button>
              {' '}<Button size="sm" onClick={removeDeal}>Remove connection</Button>
            </>}
          </>}
        </>}

      {deals.data.length>0 &&
      <FormGroup>
        <Label className={inputClasses.Label}>Select deal</Label>
        <CustomSelect
          className={inputClasses.Input}
          value={null}
          onChange={(value: number) => {
            selectDeal(deals.data[value])
          }}
          options={getUserTemplateOptions()}
          isMulti={false}
          placeholder="Select deal"
        />
      </FormGroup>}

    </div>
  );
};

//export default OfferFromIntegration;

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    organization: state.organizations.organization,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
    onGetUserOrganization: () => dispatch(actions.getUserOrganization()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferFromIntegration);
