import React, { ReactNode } from "react";

import classes from "./FooterFixed.module.scss";

interface IProps {
  children: ReactNode;
  mobile?: boolean;
}

const FooterFixed: React.FC<IProps> = ({ children, mobile }) => {
  let fillClasses = [classes.Fill];
  let containerClasses = [classes.Container];
  
  if (mobile) {
    fillClasses.push(classes.MobileFooter);
    containerClasses.push(classes.MobileFooter);
  }

  return (
    <div>
      <div className={fillClasses.join(" ")}></div>
      <div className={containerClasses.join(" ")}>{children}</div>
    </div>
  );
};

export default FooterFixed;
