import React, { createRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import FormContainer from '../../../../../../../components/UI/FormContainer/FormContainer';
import { IAppState, IBlock, IBlockContent, IBlockItem } from '../../../../../../../interfaces';
import { controlsToFormGroups, defaultInputChangedHandler, getFormData, initForm } from '../../../../../../../shared/utility';
import * as actions from '../../../../../../../store/actions';
import BlockContentEdit from '../../../../../components/Blocks/Block/BlockContentEdit';

interface IStateProps {
  block?: IBlock;
  blockItem?: IBlockItem;
  error?: string;
}

interface IDispatchProps {
  onGetBlock?: (id: string) => Promise<IBlock>;
  onGetBlockItem?: (id: string) => Promise<void>;
}

interface IComponentProps {
  blockId: string;
  blockItemId: string;
  showTitle?: boolean;
  blockItemProp?: IBlockItem; // Aika kauhee hax mut tällä saadaan toimii myös offer editissa
  ref?: any;
  setIsValid?: (isValid: boolean) => void;
  onBlur?: (blockItem: IBlockItem) => void;
  containerClassName?: string;
}

interface IControl {
  [key: string]: {
    elementType: string;
    elementConfig: {
      label: string;
      placeholder: string;
      type: string;
    };
    validation: {
      required: boolean;
    };
    valid: boolean;
    touched: boolean;
    value: string;
  };
}

interface IControlState {
  controls: IControl;
  formIsValid: boolean;
}

interface IProps extends IComponentProps, IStateProps, IDispatchProps {}

export interface IBlockItemContentRef {
  getData: () => IBlockContent;
}

const BlockItemEdit: React.ForwardRefExoticComponent<IProps> = React.forwardRef(
  (
    {
      onGetBlock,
      onGetBlockItem,
      block,
      blockItem,
      blockItemProp,
      blockId,
      blockItemId,
      showTitle = true,
      error,
      setIsValid,
      onBlur,
      containerClassName
    },
    ref
  ) => {
    const [loading, setLoading] = useState(false);
    blockItem = blockItemProp || blockItem;
    const isAdd = (!blockItemId && !blockItemProp) || blockItemId === "add";

    const getContent = (): Array<IBlockContent> => {
      let content: Array<IBlockContent> = null;
      if (isAdd) {
        content = (block && block.content) || [];
      } else {
        content = (blockItem && blockItem.content) || [];
      }
      return content;
    };

    const contentRefs = useRef([]);

    const [controlsState, setControlsState] = useState<IControlState>({
      controls: {
        name: {
          elementType: "input",
          elementConfig: {
            label: "Item name",
            placeholder: "",
            type: "text"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: ""
        }
      },
      formIsValid: false
    });

    const getData = (controls: IControl): IBlockItem => {
      const newBlockItem: IBlockItem = getFormData(controls);
      if (contentRefs.current) {
        newBlockItem.content = [];
        contentRefs.current.forEach((item: RefObject<IBlockItemContentRef>) => {
          if (item.current) {
            newBlockItem.content.push(item.current.getData());
          }
        });
      }
      return {...(blockItem || {}), ...newBlockItem};
    };

    useImperativeHandle(ref, () => ({
      getData: () => getData(controlsState.controls)
    }));

    useEffect(() => {
      if (setIsValid) {
        setIsValid(controlsState.formIsValid);
      }
    }, [setIsValid, controlsState.formIsValid]);

    useEffect(() => {
      (async () => {
        setLoading(true);
        if (isAdd) {
          await onGetBlock(blockId);
        } else if (blockItemId) {
          await onGetBlockItem(blockItemId);
        }
        setLoading(false);
      })();
    }, [blockId, onGetBlock, blockItemId, onGetBlockItem, isAdd]);

    useEffect(() => {
      const content = getContent();

      if (content.length > 0) {
        contentRefs.current = [];
        content.forEach(() => {
          contentRefs.current.push(createRef());
        });
      }

      // eslint-disable-next-line
    }, [block, blockItem]);

    useEffect(() => {
      if (blockItem && !isAdd) {
        const controls = initForm(controlsState.controls, blockItem);
        setControlsState(controls);
      }
      // eslint-disable-next-line
    }, [blockItem]);

    const inputChangedHandler = (
      event: React.ChangeEvent<HTMLInputElement>,
      controlName: string
    ) => {
      defaultInputChangedHandler(
        event,
        controlName,
        controlsState,
        setControlsState
      );
    };

    let title = null;
    if (isAdd) {
      title = "Add a new multi item element";
    } else {
      title = "Update multi item element";
    }

    let blurHandler: (blockContent: IBlockContent) => void | undefined;
    if (onBlur) {
      blurHandler = (blockContent: IBlockContent) => {
        onBlur(getData(controlsState.controls));
      };
    }

    return (
      <FormContainer
        title={showTitle && title}
        loading={loading}
        error={error}
        className={containerClassName}
      >
        <Form>
          {controlsToFormGroups(controlsState.controls, inputChangedHandler, blurHandler)}
          {getContent().map((blockContent, i) => {
            const contentRef = contentRefs.current[i];
            return (
              <BlockContentEdit
                key={blockContent.id}
                ref={contentRef}
                blockContent={blockContent}
                onBlur={blurHandler}
                onChange={(blockContent: IBlockContent) => {}}            
              />
            );
          })}
        </Form>
      </FormContainer>
    );
  }
);

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    error: state.blocks.error || state.blocks.blockItemError,
    block: state.blocks.block,
    blockItem: state.blocks.blockItem
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetBlock: id => dispatch(actions.getBlock(id)),
    onGetBlockItem: (id: string) => dispatch(actions.getBlockItem(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(BlockItemEdit);
