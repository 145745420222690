import React, { useEffect, useState } from 'react';

import { IUserTemplate, IUserTemplateEdit, IOrganization, IUserGroup, IUser } from '../../../../../../../../interfaces';
import { parseStyles } from '../../../../../../../../shared/styles';
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData,
  initForm,
  hasAccess,
} from '../../../../../../../../shared/utility';
import TemplateSidebarEditTheme from '../TemplateSidebarEditTheme/TemplateSidebarEditTheme';
import TemplateSidebarEditSettings from '../TemplateSidebarEditSettings/TemplateSidebarEditSettings';
import EUserRole from "../../../../../../../../enums/user-roles";


interface IProps {
  userTemplate: IUserTemplate;
	updateUserTemplate: (userTemplate: IUserTemplateEdit) => void;
	organization: IOrganization;
  userGroups: Array<IUserGroup>;
  currentUser:IUser;
}

const TemplateSidebarEditTemplate: React.FC<IProps> = ({ userTemplate, updateUserTemplate, organization, userGroups, currentUser }) => {
  //userGroups = null; // TODO enable userGroups

const initControls:any = {
  controls: {
    name: {
      elementType: "input",
      elementConfig: {
        label: "Template name",
        placeholder: "Template name",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    titleFont: {
      elementType: "font",
      elementConfig: {
        label: "Title font",
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: "Open Sans"
    },
    paragraphFont: {
      elementType: "font",
      elementConfig: {
        label: "Paragraph font",
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: "Open Sans"
    },
    customColor: {
      elementType: "color",
      elementConfig: {
        type: "color",
        label: "Custom color"
      },
      valid: true,
      touched: false,
      value: "#333333"
    },

    userGroups: {
      elementType: "select",
      elementConfig: {
        label: "Visible to Groups",
        placeholder: "Everyone",
        isMulti: true,
        isClearable: true,
        isDisabled: !hasAccess(currentUser, EUserRole.ENTREPRENEUR),
      },
      options: [],
      validation: {
        required: false
      },
      valueMapper: (x:any) => { return x.map((id:any)=>({id:id}))},
      valid: false,
      touched: false,
      value: ""
    },

  },
  formIsValid: false
}

//const TemplateSidebarEditTemplate: React.FC<IProps> = ({ userTemplate, updateUserTemplate, organization, userGroups }) => {
  const [controlsState, setControlsState] = useState(initControls);
  /**
   * Font plugin needs this. Crashes if active font is set after rendering plugin.
   */
  const [canRender, setCanRender] = useState(false);

  //const [options, setOptions] = useState([]);

  useEffect(() => {
    if (userGroups) {
      const options = userGroups.map(({ id, name }) => ({
          id: id,
          label: `${name}`
        }));
      const controls = {...initControls.controls};
      controls.userGroups.options = options;

      const ugs = (userTemplate.userGroups || []).map((id:string)=>({id:id}));
      setControlsState(initForm(controls, {...userTemplate, ...userTemplate.styles, userGroups: ugs}));

      //setControlsState(initForm(controls, {...userTemplate, ...userTemplate.styles}));
    }
  }, [userGroups]);

  useEffect(() => {
    const ugs = (userTemplate.userGroups || []).map((id:string)=>({id:id}));
    setControlsState(initForm(initControls.controls, {...userTemplate, ...userTemplate.styles, userGroups: ugs}));
    setCanRender(true);
    // eslint-disable-next-line
  }, [])

  const createTemplateObject = (templateObject: any, data: any) => {
    const newTemplateObject = {
      ...templateObject,
      styles: {
        ...templateObject.styles,
        ...parseStyles(data)
      }
    };

    const properties = ['name', 'customColor', 'userGroups'];
    for (let property of properties) {
      if(data.hasOwnProperty(property)) {
        if(property === "userGroups") {
          newTemplateObject[property] = data[property].map((x:any)=>(x.id));
        }
        else {
          newTemplateObject[property] = data[property];
        }
      }
    }

    return newTemplateObject;
	}

  const createTemplateObject2 = (templateObject: any, data: any) => {
    const newTemplateObject = {
      ...templateObject,
      settings: {
        ...templateObject.settings,
        ...data,
      }
    }
    return newTemplateObject;
  }


  const update = (data: any) => {
    const template = createTemplateObject(userTemplate, data);
    updateUserTemplate(template);
	};

  const update2 = (data: Object) => {
    const template = createTemplateObject2(userTemplate, data);
    updateUserTemplate(template)
  };


  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {  
    let _val:any = null;
    const _set = (_x:any) => { _val=_x; }
    defaultInputChangedHandler(event, controlName, controlsState, _set); //setControlsState);
    if(controlName === "customColor" || controlName === "userGroups") {
      update(getFormData(_val.controls)); 
    }
    setControlsState(_val);
  };

  const onBlurHandler = () => {
    update(getFormData(controlsState.controls));
  }

  return (
    <React.Fragment>
			{/*<h5>Template</h5>*/}
      {canRender && controlsToFormGroups(controlsState.controls, inputChangedHandler, onBlurHandler)}
      <TemplateSidebarEditTheme id={userTemplate.id} title="Template" data={userTemplate} update={update} />

			{/*organization && organization.integrations.vismaSign.active &&*/}
			{organization &&
			<TemplateSidebarEditSettings id={userTemplate.id} title="Template" data={userTemplate} update={update2} organization={organization}/>}
     
    </React.Fragment>
  );
};

export default TemplateSidebarEditTemplate;
