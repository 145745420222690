import { Dispatch } from "redux";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";
import { IAttachmentAction, IAttachment } from "../../interfaces";

export const clearAttachmentState = ():IAttachmentAction => {
  return {
      type: ActionTypes.CLEAR_ATTACHMENT_STATE
  };
};

export const uploadAttachmentStart = ():IAttachmentAction => {
  return {
      type: ActionTypes.UPLOAD_ATTACHMENT_START
  };
};

export const uploadAttachmentSuccess = (attachments: Array<IAttachment>):IAttachmentAction => {
  return {
      type: ActionTypes.UPLOAD_ATTACHMENT_SUCCESS,
      attachments
  };
};

export const uploadAttachmentFail = ():IAttachmentAction => {
  return {
      type: ActionTypes.UPLOAD_ATTACHMENT_FAIL
  }
};

export const uploadAttachment = (data:{}) => {
  return async (dispatch:Dispatch) => {
      dispatch(uploadAttachmentStart());
      try {
          const res = await axios.post("/json/attachments/upload", data);
          const attachments = res.data;
          dispatch(uploadAttachmentSuccess(attachments));
          return attachments;
      } catch {
          dispatch(uploadAttachmentFail());
      }
  };
};
