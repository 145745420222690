import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData
} from "../../../../../../../shared/utility";
import {
  IBlockContent,
  IBlockContentPricing
} from "../../../../../../../interfaces";
import { getBlockItemContentPricingTableEditControls } from "../../../../../../../shared/controls/blockItemContentPricingTableEditControls";
import { IBlockContentEditItem } from "../../BlockContentEdit";

interface IProps {
  ref: any;
  blockContent: IBlockContent;
  onChange?: (blockContent: IBlockContent) => void;
}

export enum EPricingType {
  SERVICE_PRICING = 'SERVICE_PRICING',
  FIXED_PROJECT_PRICING = 'FIXED_PROJECT_PRICING',
  DAILY_RATE = 'DAILY_RATE',
  HOURLY_RATE = 'HOURLY_RATE',
  OTHER_PRICING = 'OTHER_PRICING'
}

export enum EBillingPeriod {
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  END_OF_MONTH = 'END_OF_MONTH',
  END_OF_PROJECT = 'END_OF_PROJECT',
  UPFRONT_MONTH = 'UPFRONT_MONTH',
  UPFRONT_3_MONTHS = 'UPFRONT_3_MONTHS',
  OTHER_BILLING = 'OTHER_BILLING'
}

const pricingTypes = [
  { id: EPricingType.SERVICE_PRICING, label: "Monthly Fee" },
  { id: EPricingType.FIXED_PROJECT_PRICING, label: "Fixed project pricing" },
  { id: EPricingType.DAILY_RATE, label: "Daily rate" },
  { id: EPricingType.HOURLY_RATE, label: "Hourly rate" },
  { id: EPricingType.OTHER_PRICING, label: "Other pricing" },
];

const billingPeriods = [
  { id: EBillingPeriod.EVERY_TWO_WEEKS, label: "Every two weeks" },
  { id: EBillingPeriod.END_OF_MONTH, label: "End of month" },
  { id: EBillingPeriod.END_OF_PROJECT, label: "End of project" },
  { id: EBillingPeriod.UPFRONT_MONTH, label: "Upfront month" },
  { id: EBillingPeriod.UPFRONT_3_MONTHS, label: "Upfront 3 months" },
  { id: EBillingPeriod.OTHER_BILLING, label: "Other billing" },
];

const BlockContentPricingTableEdit: React.ForwardRefExoticComponent<
  IBlockContentEditItem
> = forwardRef((props, ref) => {
  const [init, setInit] = useState(false);
  const blockContent = props.blockContent as IBlockContentPricing;

  const [pricingType, setPricingType] = useState(null);
  const [showOtherBilling, setShowOtherBilling] = useState(false);

  useEffect(() => {
    if (blockContent.pricingType) {
      setPricingType(blockContent.pricingType);
    }
    // eslint-disable-next-line
  }, [blockContent.pricingType]);

  useEffect(() => {
    if (blockContent.billingPeriod === EBillingPeriod.OTHER_BILLING) {
      setShowOtherBilling(true);
    }
    // eslint-disable-next-line
  }, [blockContent.billingPeriod])

  useEffect(() => {
    const controls = getBlockItemContentPricingTableEditControls(pricingType, blockContent);
    setAdditionalControlsState({
      controls: controls,
      controlsIsValid: false
    });
    // eslint-disable-next-line
  }, [pricingType]);

  const [controlsState, setControlsState] = useState({
    controls: {
      pricingType: {
        elementType: "select",
        elementConfig: {
          label: "Pick pricing type"
        },
        valid: false,
        validation: {
          required: true
        },
        touched: false,
        value: blockContent.pricingType,
        options: pricingTypes
      },
      billingPeriod: {
        elementType: "select",
        elementConfig: {
          label: "Choose billing period",
          isClearable: true,
        },
        valid: false,
        validation: {
          required: true
        },
        touched: false,
        value: blockContent.billingPeriod,
        options: billingPeriods
      }
    },
    controlsIsValid: false
  });

  const [additionalControlsState, setAdditionalControlsState] = useState({
    controls: null,
    controlsIsValid: false
  });

  const [otherBillingControlState, setOtherBillingControlState] = useState({
    controls: {
      billingPeriodOther: {
        elementType: "input",
        elementConfig: {
          label: "Other billing period",
          placeholder: "Other billing period",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: blockContent.billingPeriodOther || ""
      }
    },
    controlsIsValid: false
  })


  const getData = () => {
    const controls = Object.assign({}, {
      ...controlsState.controls,
      ...additionalControlsState.controls,
      ...otherBillingControlState.controls
    });
    const data = getFormData(controls);
    return Object.assign({}, blockContent, data);
  }

  useEffect(() => {
    if(props.onChange && init) {
      props.onChange(getData())
    } 
    // eslint-disable-next-line
  }, [controlsState.controls, additionalControlsState.controls, otherBillingControlState.controls]);

  useEffect(() => {
    setInit(true);
  }, [])

  useImperativeHandle(ref, () => ({
    getData
  }));

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );

    if (controlName === 'pricingType') {
      let value = event.target ? event.target.value : event;
      setPricingType(value.toString());
    };

    if (controlName === 'billingPeriod') {
      let value = event.target ? event.target.value : event;
      const valueString = value ? value.toString() : null;

      if (valueString === EBillingPeriod.OTHER_BILLING) {
        setShowOtherBilling(true);
      } else {
        setShowOtherBilling(false);
      }
    };
  }

  const additionalInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      additionalControlsState,
      setAdditionalControlsState
    );
  }

  const billingOtherInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      otherBillingControlState,
      setOtherBillingControlState
    );
  }

  let blurHandler:any = null;
    if(props.onBlur) {
      blurHandler = () => {
        props.onBlur(getData());
      }
    }

  return (
    <React.Fragment>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler, blurHandler)}
      {controlsToFormGroups(additionalControlsState.controls, additionalInputChangedHandler, blurHandler)}
      {showOtherBilling ? controlsToFormGroups(otherBillingControlState.controls, billingOtherInputChangedHandler, blurHandler) : null}
    </React.Fragment>
  )
});

export default BlockContentPricingTableEdit;
