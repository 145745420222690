import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";

import classes from "./CustomDialog.module.scss";

export interface ExtraButton {
  onClick:()=>void;
  disabled:boolean;
  label:string;
}

interface IProps {
  open: boolean;
  onCancel?: () => void;
  onOk?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete?: () => void;
  title: string;
  children: any;
  loading?: boolean;
  okButtonText?: string;
  isValid?: boolean;
  cancelButtonText?: string;
  closable?: boolean;
  size?: string;
  hideButtons?: boolean;
  noToggle?: boolean;

	footer?:any;
  overflow?:boolean;
  overflowFullWidth?:boolean;

  extraButton?:ExtraButton;
}

const CustomDialog: React.FC<IProps> = ({
  open,
  onCancel,
  onOk,
  onDelete,
  title,
  children,
  loading,
  okButtonText,
  isValid = true,
  cancelButtonText,
  closable,
  size = "md",
  hideButtons,
  noToggle,

	footer,
  overflow,
  overflowFullWidth = false,

  extraButton,
}) => {
  let content = children;
  if (loading) {
    content = <Spinner />;
  }

  let cancelButton = null;
  if (onCancel && !hideButtons && cancelButtonText !== "") {
    cancelButton = (
      <Button color="link" onClick={onCancel} disabled={loading}>
        {cancelButtonText ? cancelButtonText : "Discard"}
      </Button>
    );
  }

  let deleteButton = null;
  if (onDelete && !hideButtons) {
    deleteButton = (
      <Button color="danger" onClick={onDelete} disabled={loading}>
        Delete
      </Button>
    );
  }

	const bodyClassNames=[classes.Content];

	const wrapClassNames=[];
	const modalClassNames=[];
//	const contentClassNames=[];

  const classNames = [];
  if (size === "xl") {
    size = null;
    classNames.push(classes.XL);
  }

  if (size === "fullscreen") {
    size = null;
    classNames.push(classes.Fullscreen);
  }

	if(overflow) {
		classNames.push(classes.OverflowHeight);
		bodyClassNames.push(classes.Overflow);

		wrapClassNames.push(classes.OverflowWrap);
		modalClassNames.push(classes.OverflowModal);

    if(overflowFullWidth) {
      modalClassNames.push(classes.OverflowFullWidth);
    }
	}

  const toggle = loading
    ? null
    : closable
    ? onCancel
      ? onCancel
      : () => onOk(null)
    : onCancel;

  return (
    <Modal
      isOpen={open}
      toggle={noToggle ? null : toggle}
      centered
      size={size}
      className={classNames.join(" ")}
			wrapClassName={wrapClassNames.join(" ")}
			modalClassName={modalClassNames.join(" ")}
		>
		{/*	contentClassName={contentClassNames.join(" ")} */}
    
      <ModalHeader className={classes.Header} toggle={noToggle ? null : toggle}>
        {title}
      </ModalHeader>
      <ModalBody className={bodyClassNames.join(" ")}>{content}</ModalBody>
      <ModalFooter className={classes.Footer}>
        {onOk && !hideButtons && (
          <Button color="info" onClick={onOk} disabled={loading || !isValid}>
            {okButtonText ? okButtonText : "Save"}
          </Button>
        )}

        {extraButton && 
          <Button color="info" onClick={extraButton.onClick} disabled={extraButton.disabled}>
            {extraButton.label}
          </Button>}

        {cancelButton}
        {deleteButton}

			 {footer && <>{footer}</>}
      </ModalFooter>
    </Modal>
  );
};

export default CustomDialog;
