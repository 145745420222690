import uuid from 'uuid/v1';

import { EDragArea, EDragType } from '../../../../../../enums/drag';
import { EContentType, EUserTemplateBlockType, ESigner } from '../../../../../../enums';

import { ELayoutType } from '../../../../../../interfaces';
import { defaultBlockStyles } from '../../../../../../shared/styles';

import { ETextType } from "../../../../components/Blocks/Block/ContentTypeDialog/ContentTypeItemContent/ContentTypeItemContent";

export const dummyLogo: any = (area: EDragArea) => {
    const id = uuid();
    return {
      id: id,
      // blockId: null,
      name: "Logo",
      showName: false,
      editableOnOffer: false,
      layoutType: ELayoutType.VERTICAL,
      styles: defaultBlockStyles,
      area,
      isDragged: false,
      contentType: EContentType.CUSTOM,
      type: EDragType.BLOCK,
      uuid: id,
      blockType: EUserTemplateBlockType.SIMPLE,
      content: [{
        id: uuid(),
        name: "Logo",
        contentType: EContentType.IMAGE,
        editOnOffer: false
      }],
    };
  };

export const dummyParties: any = (area: EDragArea) => {
    const id = uuid();
    return {
      id: id,
      // blockId: null,
      name: "Parties",
      showName: true,
      editableOnOffer: false,
      layoutType: ELayoutType.VERTICAL,
      styles: defaultBlockStyles,
      area,
      isDragged: false,
      contentType: EContentType.CUSTOM,
      type: EDragType.BLOCK,
      uuid: id,
      blockType: EUserTemplateBlockType.SIMPLE,
      content: [{
        id: uuid(),
        name: "Parties",
        contentType: EContentType.DEAL_BETWEEN,
        editOnOffer: false
      }],
    };
  };

export const dummyEditor: any = (area: EDragArea) => {
    const id = uuid();
    return {
      id: id,
      // blockId: null,
      name: "Editor",
      showName: true,
      editableOnOffer: true,
      layoutType: ELayoutType.VERTICAL,
      styles: defaultBlockStyles,
      area,
      isDragged: false,
      contentType: EContentType.CUSTOM,
      type: EDragType.BLOCK,
      uuid: id,
      blockType: EUserTemplateBlockType.SIMPLE,
      content: [{
        id: uuid(),
        name: "Our Proposal",
        contentType: EContentType.CONTENT,
        editOnOffer: true,
				textType: ETextType.WYSIWYG,
				//				title:"Our proposal",
				content:"<h4>Click to edit</h4>"+
					"<p>Click any element to open editing.</p>"+
					"<p>&nbsp;</p>"+
					"<h4>Copy and paste</h4>"+
					"<p>Copy your current proposal from a Word document and paste it here.</p>"+
					"<p>&nbsp;</p>"+
					"<h4>Make it readable</h4>"+
					"<p>We recommend you to use for instance</p>"+
					"<ul>"+
					"<li>bullets to make your message&nbsp;more easy to read,</li>"+
					"<li>videos and pictures for more visuality, and</li>"+
					"<li><strong>pricing table element (below) </strong>to easily calculate and communicate pricing.&nbsp;</li>"+
					"</ul>",
      }],
    };
  };

export const dummySignature: any = (area: EDragArea) => {
    const id = uuid();
    return {
      id: id,
      // blockId: null,
      name: "Signatures",
      showName: true,
      editableOnOffer: false,
      layoutType: ELayoutType.VERTICAL,
      styles: defaultBlockStyles,
      area,
      isDragged: false,
      contentType: EContentType.CUSTOM,
      type: EDragType.BLOCK,
      uuid: id,
      blockType: EUserTemplateBlockType.SIMPLE,
      content: [{
        id: uuid(),
        name: "Signatures",
        contentType: EContentType.SIGNATURE,
				editOnOffer: true,
				signer:ESigner.AUTHOR,
      }],
    };
  };


