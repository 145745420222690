import React from "react";
import { Button, Table } from "reactstrap";
import Content from "../../../../../components/UI/Content/Content";
import ContentTypeIcon from "../../../../../components/UI/ContentTypeIcon/ContentTypeIcon";
import { IBlock } from "../../../../../interfaces";

import classes from "./BlocksList.module.scss";

interface IProps {
  blocks: Array<IBlock>;
  onEdit: (id:string) => void;
  onSeeItems: (id:string) => void;
}

const BlocksList: React.FC<IProps> = ({ blocks, onEdit, onSeeItems }) => {
  if (!blocks || !blocks.length) {
    return (
      <Content style={{marginTop: '1rem'}}>
        <p>No blocks.</p>
      </Content>
    )
	}

	const blockSort = (a:IBlock,b:IBlock) => ((a.name || "").toLowerCase()<(b.name || "").toLowerCase()?-1:1)

  return (
    <Table responsive borderless striped>
      <tbody>
        {blocks.sort(blockSort).map(block => (
          <tr key={block.id}>
            <td style={{ width: "100%" }}>{block.name}</td>
            <td>
              <div className={classes.Icons}>
                {block.content &&
                  block.content.map((blockContent, i) => (
                    <ContentTypeIcon
                      active={false}
                      contentType={blockContent.contentType}
                      key={blockContent.id}
                    />
                  ))}
              </div>
            </td>

            <td>
              <div className={classes.Actions}>
                <Button color="light" size="sm" onClick={() => onEdit(block.id)}>
                  Edit
                </Button>
                <Button color="info" size="sm" onClick={() => onSeeItems(block.id)}>
                  See items
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BlocksList;
