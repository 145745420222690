import { ISort } from './SortIcon';
import React from "react";

const SortContext = React.createContext<{
  onSort: (newSort: Array<ISort<any>>) => void;
  currentSort: Array<ISort<any>>;
}>({
  onSort: null,
  currentSort: null
});

export default SortContext;
