import React, { useState } from 'react';
import { CustomInput, Label } from 'reactstrap';
import CustomDialog from '../../UI/CustomDialog/CustomDialog';
import { IOrganization, IUser } from '../../../interfaces';
import { EUserRole } from '../../../enums';
import { hasAccess } from '../../../shared/utility';

interface IProps {
  organization: IOrganization;
  disconnectPipedrive: () => Promise<boolean>;
  loading: boolean;
  error: string | null;
  currentUser: IUser;
  openPipedriveUninstallDialog : () => void;
}

const OrganizationPipedriveSwitch: React.FC<IProps> = ({
  organization,
  disconnectPipedrive,
  loading,
  error,
  currentUser,
  openPipedriveUninstallDialog
}) => {
  const { pipedriveConnected, pipedriveAuthorizeUrl } = organization;
  const [isOpen, setIsOpen] = useState(false);
  const [auth, setAuth] = useState(pipedriveConnected);

  const handleChange = () => {
    if (!auth && hasAccess(currentUser, EUserRole.ENTREPRENEUR)) {
      window.location.href = pipedriveAuthorizeUrl;
    } else {
      setIsOpen(true);
    }
  }

  const onOk = async () => {
    const success = await disconnectPipedrive();
    if (success) {
      setAuth(false);
      setIsOpen(false);
      openPipedriveUninstallDialog();
    }
  }

  return (
    <React.Fragment>
      <CustomDialog
        open={isOpen}
        title="Are you sure?"
        okButtonText="OK"
        onOk={onOk}
        isValid={!loading}
        onCancel={() => setIsOpen(false)}
      >
        Removing Pipedrive connection does not affect existing data.
      </CustomDialog>

      <Label>Pipedrive connection</Label>
      <CustomInput
        type="switch"
        id="PipedriveSwitch"
        label="Toggle Pipedrive connection"
        onChange={handleChange}
        checked={auth}
        disabled={!auth && !hasAccess(currentUser, EUserRole.ENTREPRENEUR)}
      />
    </React.Fragment>
  );
};

export default OrganizationPipedriveSwitch;
