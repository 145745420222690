import React, { useContext } from 'react';
import { FormGroup, Label } from 'reactstrap';

import BoxBody from '../../../../../../components/UI/BoxBody/BoxBody';
import CustomDatePicker from '../../../../../../components/UI/CustomDatePicker/CustomDatePicker';
import CustomSelect from '../../../../../../components/UI/CustomSelect/CustomSelect';
import Title from '../../../../../../components/UI/Typography/Title';
import { ELanguage } from '../../../../../../enums';
import { languages } from '../../../../../../shared/languages';
import OfferContext from '../../../../containers/Offers/Offer/OfferContext';
import OfferEmail from '../OfferEdit/OfferEmail/OfferEmail';
import OfferRecipients from '../OfferEdit/OfferRecipients/OfferRecipients';

//import AcceptanceMethods from '../../../../../../components/UI/AcceptanceMethods/AcceptanceMethods';

import classes from "./OfferMessagingSidebar.module.scss";
import inputClasses from "../../../../../../components/UI/Input/Input.module.scss";

interface IProps {
  loading: boolean;
}

const OfferMessagingSidebar: React.FC<IProps> = ({ loading }) => {
  const { offer, setOffer } = useContext(OfferContext);
  
  const expirationDateHandler = (expirationDate: string) => {
    setOffer({...offer, expirationDate});
  }


  return (
    <React.Fragment>
      <div className={classes.Container}>
        <BoxBody className={classes.Body}>
          {offer.offerCounter && <div style={{"float":"right"}}>{offer.offerCounter}</div>}
          <Title>Customer Communication</Title>
          <FormGroup>
            <Label className={inputClasses.Label}>Language</Label>
            <CustomSelect
              className={inputClasses.Input}
              value={offer.customer && offer.customer.language}
              onChange={(value: string) => {
                if (loading) return;
                const newOffer = { ...offer };
                if (newOffer.customer) {
                  newOffer.customer = { ...newOffer.customer, language: value as ELanguage }
                }
                setOffer(newOffer);
              }}
              options={languages}
              placeholder="Choose language"
              loading={false}
            />
          </FormGroup>

          <OfferEmail />
          <OfferRecipients />
          <FormGroup>
            <Label className={inputClasses.Label}>Expiration date</Label>
            <CustomDatePicker
              onChange={expirationDateHandler}
              value={offer.expirationDate}
              placeholder="Change expiration date"
            />
					</FormGroup>

				{/*<AcceptanceMethods data={offer.userTemplate} update={null} edit={false} />*/}
        </BoxBody>
      </div>
    </React.Fragment>
  );
};

export default OfferMessagingSidebar;
