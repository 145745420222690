import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label, Spinner } from "reactstrap";
import CustomSelect from "../../../../../../../../components/UI/CustomSelect/CustomSelect";
import {
  IBlockItem,
  IUserTemplateAreaBlock
} from "../../../../../../../../interfaces";
import { EBlockEditingStyle } from "../../../../../../containers/Blocks/Block/Block";
import OfferContentItemDynamicEdit from "./OfferContentItemDynamicEdit/OfferContentItemDynamicEdit";

import inputClasses from "../../../../../../../../components/UI/Input/Input.module.scss";

interface IProps {
  block: IUserTemplateAreaBlock;
  editable?: boolean;
  onBlockItemChanged: (blockId: string, blockItems: Array<IBlockItem>) => void;
  onAdd: () => void;
  onEdit: (blockItem: IBlockItem) => void;
  blockLoading: boolean;
}

const OfferContentItemDynamic: React.FC<IProps> = ({
  block,
  editable,
  onBlockItemChanged,
  onAdd,
  onEdit,
  blockLoading
}) => {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const sortByName = (a:any, b:any) => {
    if(a!==null && a!==undefined && b!==null && b!==undefined) {
      if(a.name!==null && a.name!==undefined && b.name!==null && b.name!==undefined) {
        return a.name.toLowerCase()>b.name.toLowerCase()?1:-1;
      }
    }
    return 1;
  }

  useEffect(() => {
    if (block.blockItems) {
      setOptions(
        block.blockItems.sort(sortByName).map(({ id, name, editOnOffer }) => ({
          id: id,
          label: `${name} ${editOnOffer ? "(edited)" : ""}`
        }))
      );
    }
  }, [block.blockItems]);

  useEffect(() => {
    if (block.offerBlockItems) {
      setValue(block.offerBlockItems);
    }
    // eslint-disable-next-line
  }, [block.offerBlockItems]);

  const onChange = async (blockItems: Array<IBlockItem>) => {
    //setValue(blockItems);
    setLoading(true);
    await onBlockItemChanged(block.id, blockItems);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <FormGroup>
        <Label className={inputClasses.Label}>{block.name}</Label>
        <div className={inputClasses.WithButton}>
          <CustomSelect
            value={value}
            onChange={onChange}
            options={options}
            isMulti={true}
            placeholder={`Choose ${block.name}`}
            valueMapper={(ids: Array<string>) =>
              ids.map(id =>
                block.blockItems.find(blockItem => blockItem.id === id)
              )
            }
            loading={loading}
            onEdit={
              editable && block.editingStyle === EBlockEditingStyle.LIST
                ? (id: string) =>
                    onEdit(block.offerBlockItems.find(item => item.id === id))
                : null
            }
            fullWidthMultiValues
          />
          <div>
          {/*JSON.stringify(block)*/}
            <Button onClick={onAdd} color="primary" disabled={blockLoading}>
              Add new
            </Button>
          </div>
        </div>
      </FormGroup>
      {block.editingStyle === EBlockEditingStyle.INLINE && (
        <>
          <OfferContentItemDynamicEdit block={block} />
          {blockLoading && <Spinner color="primary" />}
        </>
      )}
    </React.Fragment>
  );

  /*
  return controlsToFormGroups(
    controlsState.controls,
    (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
      defaultInputChangedHandler(
        event,
        controlName,
        controlsState,
        setControlsState
      )
  );
  */
};

export default OfferContentItemDynamic;
