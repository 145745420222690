import React, { ReactNode, useEffect, useRef } from "react";
import { useScroll } from "../../../shared/utility";

import classes from "./SidebarStatic.module.scss";

interface IProps {
  children: ReactNode;
  shadow?: boolean;
  mobileMargin?: boolean;
  scrollable?: boolean
}

const SidebarStatic: React.FC<IProps> = ({ children, shadow, mobileMargin, scrollable }) => {
  let containerClasses = [classes.Container];

  if(shadow) {
    containerClasses.push(classes.Shadow);
  }

  if (mobileMargin) {
    containerClasses.push(classes.MobileMargin);
  }

  if (scrollable) {
    containerClasses.push(classes.Scrollable);
  }

  const { y } = useScroll();
  const ref = useRef(null);

  useEffect(() => {
    if (!scrollable) return;
    if (!ref.current) return;

    if (y === 0) {
      ref.current.classList.remove(classes.NoTop);
    } else if (y > 0) {
      ref.current.classList.add(classes.NoTop);
    }
  }, [y, scrollable]);

  return (
    <React.Fragment>
      <div ref={ref} className={containerClasses.join(" ")}>
        {children}
      </div>
    </React.Fragment>
  );
};

export default SidebarStatic;
