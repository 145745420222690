import React, { useEffect, useState } from 'react';
import { IIntegrations, IOrganization, IUser } from '../../../../../interfaces';

import { CustomInput, Input, Button, Row, Col, Alert } from 'reactstrap';
import axios from '../../../../../axios';

import classes from "../Integrations.module.scss";
//import { config } from '../../../../../constants';


//const hostname = window && window.location && window.location.hostname;

let BASEURL="/integration/pipedrive";

//let BASEURL=config.PIPEDRIVE.BASEURL //"https://integrations-dot-c2ctest-203405.ey.r.appspot.com/pipedrive"
//if(hostname === 'localhost') {
//  BASEURL="/integration/pipedrive"
//}




interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  updateOrganization: (x:any)=>void;

}

interface IProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
  updateOrganization: (x:any)=>void;
  params: any;
}

export const getDeal:any = async (integrations:IIntegrations, user:IUser, deal:string) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deal?deal='+deal+'&token='+integrations.pipedrive.refreshToken+"&email="+encodeURIComponent(user.email));
  return res.data;
}


export const getDeals:any = async (integrations:IIntegrations, user:IUser) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deals?stage='+integrations.pipedrive.stageId+'&token='+integrations.pipedrive.refreshToken+"&email="+encodeURIComponent(user.email))
  return res.data;
}


const Pipedrive: React.FC<IProps> = ({
  currentUser,
  organization,
  updateOrganization,

  params,
}) => {
  
  const [stages, setStages] = useState<Array<any>>([]);
  const [error, setError] = useState<String>(null);

  const integrations=organization && organization.integrations;

  const updatePipedrive = (updated:any) => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        pipedrive:{
          ...organization.integrations.pipedrive,
          ...updated,
        }  
      }      
    }
    updateOrganization(newo);
  }


  useEffect(() => {
    if(organization && params.token && params.name==="pipedrive") {
      const newo= {...organization,
        integrations:{
          ...organization.integrations,
          pipedrive:{
            ...organization.integrations.pipedrive,
            active:true,
            refreshToken:params.token as string,
          }
        }
      }

      updateOrganization(newo);
    }
    // eslint-disable-next-line
  }, [organization]);
  /*
[{"id":1,"order_nr":1,"name":"Lead In","active_flag":true,"deal_probability":100,"pipeline_id":1,"rotten_flag":false,"rotten_days":null,"add_time":"2018-11-20 11:13:03","update_time":null,"pipeline_name":"Pipeline","pipeline_deal_probability":false},{"id":2,"order_nr":2,"name":"Contact Made","active_flag":true,"deal_probability":100,"pipeline_id":1,"rotten_flag":false,"rotten_days":null,"add_time":"2018-11-20 11:13:03","update_time":null,"pipeline_name":"Pipeline","pipeline_deal_probability":false},{"id":3,"order_nr":3,"name":"Prospect Qualified","active_flag":true,"deal_probability":100,"pipeline_id":1,"rotten_flag":false,"rotten_days":null,"add_time":"2018-11-20 11:13:03","update_time":null,"pipeline_name":"Pipeline","pipeline_deal_probability":false},{"id":4,"order_nr":4,"name":"Needs Defined","active_flag":true,"deal_probability":100,"pipeline_id":1,"rotten_flag":false,"rotten_days":null,"add_time":"2018-11-20 11:13:03","update_time":null,"pipeline_name":"Pipeline","pipeline_deal_probability":false},{"id":5,"order_nr":5,"name":"Proposal Made","active_flag":true,"deal_probability":100,"pipeline_id":1,"rotten_flag":false,"rotten_days":null,"add_time":"2018-11-20 11:13:03","update_time":null,"pipeline_name":"Pipeline","pipeline_deal_probability":false},{"id":6,"order_nr":6,"name":"Negotiations Started","active_flag":true,"deal_probability":100,"pipeline_id":1,"rotten_flag":false,"rotten_days":null,"add_time":"2018-11-20 11:13:03","update_time":null,"pipeline_name":"Pipeline","pipeline_deal_probability":false}]
   */
  const getStages = () => {
    axios.get(BASEURL+'/stages').then((resp)=>{
      if(resp.data.data) {
        setStages(resp.data.data);
        setError(null);
      }
      else {
        setError(resp.data.error);
      }
        
    })
    .catch((resp)=> {
      console.log("error",resp);
    })
  }
/*
  const setStage = (key:any) => {
    updatePipedrive({stageId:key,stageName:stages[key]})
  }
  */
  const setNewStage = (fieldId:string, fieldName:string, stage:any) => {
    if(stage===null) {
      updatePipedrive({[fieldId]:null,[fieldName]:null})
    }
    else {      
      updatePipedrive({[fieldId]:stage.id,[fieldName]:stage.name})
    }
  }
 
    /*
  const getDeals = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get('http://localhost:8082/deals?stage='+integrations.pipedrive.stageId+'&token='+integrations.pipedrive.refreshToken).then((resp)=>{
      console.log("resp",resp)
      setDeals(resp.data)
    })
    .catch((resp)=> {
      console.log("error",resp);
    })
  }
     */

  return (
    <React.Fragment>
          <div className={classes.integration}>

      <Row>
        <Col>
          <h5>Pipedrive</h5>
          {/*
            <Input
              type="switch"
              label="Is Active"
              id="pipedriveActiveId"
    			  	onChange={(e:any)=>{if(integrations.pipedrive.active) {updatePipedrive({active:false,refreshToken:""})}}}
    				  checked={integrations.pipedrive.active}
            />
            
            {integrations.pipedrive.stageId && <div>Get deals from: {(integrations.pipedrive.stageName || "").toUpperCase()}</div>}

            */}
            {/*
            {integrations.pipedrive.newStageId && <div>Move confirmed deals to: {(integrations.pipedrive.newStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage("newStageId","newStageName",null)}}>dont move</Button></div>}
              */}
            {integrations.pipedrive.sentStageId && <div>Move sent deals to: {(integrations.pipedrive.sentStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage("sentStageId","sentStageName",null)}}>dont move</Button></div>}
            {integrations.pipedrive.openedStageId && <div>Move opened deals to: {(integrations.pipedrive.openedStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage("openedStageId","openedStageName",null)}}>dont move</Button></div>}
            {integrations.pipedrive.wonStageId && <div>Move confirmed deals to: {(integrations.pipedrive.wonStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage("wonStageId","wonStageName",null)}}>dont move</Button></div>}
              {/*integrations.pipedrive.lostStageId && <div>Move declined deals to: {(integrations.pipedrive.lostStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage("lostStageId","lostStageName",null)}}>dont move</Button></div>*/}

            <CustomInput
              type="switch"
              label="Change status to Won"
              id="pipedriveChangeStatus"
    			  	onChange={(e:any)=>{updatePipedrive({setAsWon:!integrations.pipedrive.setAsWon})}}
              checked={integrations.pipedrive.setAsWon}
            />

            
            <CustomInput
              type="switch"
              label="Upload Contracts"
              id="pipedriveSendContractId"
    			  	onChange={(e:any)=>{updatePipedrive({sendContract:!integrations.pipedrive.sendContract})}}
    				  checked={integrations.pipedrive.sendContract}
            />

          <br/>

          <Button size="sm" color="info" target="_blank" href="https://marketplace.pipedrive.com/app/click-2-contract/26e70d8f6764904c">Install integration</Button>
          {' '}
          

            <Button size="sm" color="primary" onClick={getStages}>Get stages</Button>
            
        </Col>
        <Col>
          {error && <Alert color="danger">{error}</Alert>}
          <div>{[...stages || []].map((stage:any)=>(<div key={stage.id}>
              {/*
              <Button size="sm"
                color={stage.id===integrations.pipedrive.stageId?"success":"secondary"}
                value={stage.id}
                onClick={()=>setStage(stage.id)}
              >get deals from
              </Button>
                */}
          
  {/*
            <Button size="sm"
                color={stage.id==integrations.pipedrive.newStageId?"success":"secondary"}
                value={stage.id}
                onClick={()=>setNewStage("newStageId","newStageName",stage)}
            >update stage
          </Button>
    */}
            <Button size="sm"
                color={stage.id==integrations.pipedrive.sentStageId?"success":"secondary"}
                value={stage.id}
                onClick={()=>setNewStage("sentStageId","sentStageName",stage)}
            >sent
            </Button>
            <Button size="sm"
                color={stage.id==integrations.pipedrive.openedStageId?"success":"secondary"}
                value={stage.id}
                onClick={()=>setNewStage("openedStageId","openedStageName",stage)}
            >opened
            </Button>
            <Button size="sm"
                color={stage.id==integrations.pipedrive.wonStageId?"success":"secondary"}
                value={stage.id}
                onClick={()=>setNewStage("wonStageId","wonStageName",stage)}
            >confirmed
            </Button>
            {/*<Button size="sm"
                color={stage.id==integrations.pipedrive.lostStageId?"success":"secondary"}
                value={stage.id}
                onClick={()=>setNewStage("lostStageId","lostStageName",stage)}
            >declined
          </Button>*/}





              {stage.name.toUpperCase()}
          </div>))}

            </div>
        </Col>
      </Row>
      </div>


    </React.Fragment>
  );
};

export default Pipedrive;

