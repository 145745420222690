import React, { useState, useContext } from "react";
import { Button } from "reactstrap";

import CustomDialog from "../../../../../../../components/UI/CustomDialog/CustomDialog";
import {
  IBlockContent,
  IBlockContentTAG
} from "../../../../../../../interfaces";
import { ETextType } from "../../ContentTypeDialog/ContentTypeItemContent/ContentTypeItemContent";
import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";

import classes from "./BlockContentTAGShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent?: boolean;
}

const BlockContentTAGShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentTAG;

  const { customer, printable } = useContext(OfferPreviewContext);
  
  return (<span>
    {JSON.stringify(blockContent)}
    <div className="pagebreakafter"></div>
  </span>);
  /*
  return (!printable || blockContent.printable) ? (
    <React.Fragment>
      <div>
        <div style={{"position":"relative","height":blockContent.height,"overflow":"hidden"}}>
          <iframe src={dynamicLink}
            frameBorder="0" allowFullScreen
            style={{"position":"absolute","top":0,"left":0,"width":"100%","height":"100%"}}></iframe>
        </div>
      </div>

    </React.Fragment>
  ) : null;
  */
};


export default BlockContentTAGShow;
