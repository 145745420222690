import { Dispatch } from "redux";
import { ActionTypes } from "./actionTypes";
import { IPaymentMethodAction, IPaymentMethodDetail } from "../../interfaces";
import axios from "../../axios";

export const getPrimaryPaymentMethodStart = (): IPaymentMethodAction => {
  return {
    type: ActionTypes.GET_PRIMARY_PAYMENT_METHOD_START
  };
};

export const getPrimaryPaymentMethodSuccess = (paymentMethod: IPaymentMethodDetail): IPaymentMethodAction => {
  return {
    type: ActionTypes.GET_PRIMARY_PAYMENT_METHOD_SUCCESS,
    paymentMethod
  };
};

export const getPrimaryPaymentMethodFail = (error: string): IPaymentMethodAction => {
  return {
    type: ActionTypes.GET_PRIMARY_PAYMENT_METHOD_FAIL,
    error
  };
};

export const getPrimaryPaymentMethod = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getPrimaryPaymentMethodStart());
    try {
      const res = await axios.get("/json/billing/primarypaymentmethod");
      const paymentMethod = res.data;
      dispatch(getPrimaryPaymentMethodSuccess(paymentMethod));
      return paymentMethod;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getPrimaryPaymentMethodFail(error.response.data));
      return null;
    }
  };
};

export const savePaymentMethodStart = (): IPaymentMethodAction => {
  return {
    type: ActionTypes.SAVE_PAYMENT_METHOD_START
  };
};

export const savePaymentMethodSuccess = (paymentMethod: IPaymentMethodDetail): IPaymentMethodAction => {
  return {
    type: ActionTypes.SAVE_PAYMENT_METHOD_SUCCESS,
    paymentMethod
  };
};

export const savePaymentMethodFail = (error: string): IPaymentMethodAction => {
  return {
    type: ActionTypes.SAVE_PAYMENT_METHOD_FAIL,
    error
  };
};

export const savePaymentMethod = (data: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(savePaymentMethodStart());
    try {
      const res = await axios.post("/json/billing/savepaymentmethod", data);
      const paymentMethod = res.data;
      dispatch(savePaymentMethodSuccess(paymentMethod));
      return paymentMethod;
      //@ts-ignore
    } catch (error: any) {
      dispatch(savePaymentMethodFail(error.response.data));
      return null;
    }
  };
};

// export const findPaymentMethodsStart = (): IPaymentMethodAction => {
//   return {
//     type: ActionTypes.FIND_PAYMENT_METHODS_START
//   };
// };

// export const findPaymentMethodsSuccess = (paymentMethods: Array<IPaymentMethodDetail>): IPaymentMethodAction => {
//   return {
//     type: ActionTypes.FIND_PAYMENT_METHODS_SUCCESS,
//     paymentMethods
//   };
// };

// export const findPaymentMethodsFail = (error: string): IPaymentMethodAction => {
//   return {
//     type: ActionTypes.FIND_PAYMENT_METHODS_FAIL,
//     error
//   };
// };

// export const findPaymentMethods = () => {
//   return async (dispatch: Dispatch) => {
//     dispatch(findPaymentMethodsStart());
//     try {
//       const res = await axios.get("/json/billing/paymentmethods/list");
//       const paymentMethods = res.data;
//       dispatch(findPaymentMethodsSuccess(paymentMethods));
//     } catch (error: any) {
//       dispatch(findPaymentMethodsFail(error.response.data));
//     }
//   };
// };
