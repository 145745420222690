import React, { useEffect, useState } from 'react';
import { IIntegrations, IOrganization, IUser } from '../../../../../interfaces';

import { CustomInput, Input, Button, Row, Col } from 'reactstrap';
import axios from 'axios';

import { config } from '../../../../../constants';
import classes from "../Integrations.module.scss";

const hostname = window && window.location && window.location.hostname;

let BASEURL=config.HUBSPOT.BASEURL;
if(hostname === 'localhost') {
  BASEURL="http://localhost:8082/hubspot";
}




interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  updateOrganization: (x:any)=>void;

}

interface IProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
  updateOrganization: (x:any)=>void;
  params: any;
}

export const getDeal:any = async (integrations:IIntegrations, user:IUser, deal:string) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deal?deal='+deal+'&token='+integrations.hubspot.refreshToken+"&email="+encodeURIComponent(user.email));
  return res.data;
}


export const getDeals:any = async (integrations:IIntegrations, user:IUser) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deals?stage='+integrations.hubspot.stageId+'&token='+integrations.hubspot.refreshToken+"&email="+encodeURIComponent(user.email))
  return res.data;
}


const Hubspot: React.FC<IProps> = ({
  currentUser,
  organization,
  updateOrganization,

  params,
}) => {

  const [stages, setStages] = useState<Array<String>>([]);

  const integrations=organization && organization.integrations;

  const updateHubspot = (updated:any) => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        hubspot:{
          ...organization.integrations.hubspot,
          ...updated,
        }  
      }      
    }
    updateOrganization(newo);
  }


  useEffect(() => {
    if(organization && params.token && params.name==="hubspot") {
      const newo= {...organization,
        integrations:{
          ...organization.integrations,
          hubspot:{
            ...organization.integrations.hubspot,
            active:true,
            refreshToken:params.token as string,
          }
        }
      }

      updateOrganization(newo);
    }
    // eslint-disable-next-line
  }, [organization]);


  const getStages = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get(BASEURL+'/stages?token='+integrations.hubspot.refreshToken).then((resp)=>{
      //console.log("resp",resp)
      setStages(resp.data)
    })
    .catch((resp)=> {
      //console.log("error",resp);
    })
  }

  const setStage = (key:any) => {
    updateHubspot({stageId:key,stageName:stages[key]})
  }
  
  const setNewStage = (key:any) => {
    updateHubspot({newStageId:key,newStageName:stages[key] || null})
  }
 
    /*
  const getDeals = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get('http://localhost:8082/deals?stage='+integrations.hubspot.stageId+'&token='+integrations.hubspot.refreshToken).then((resp)=>{
      console.log("resp",resp)
      setDeals(resp.data)
    })
    .catch((resp)=> {
      console.log("error",resp);
    })
  }
     */

  return (
    <React.Fragment>
          <div className={classes.integration}>

      <Row>
        <Col>
      			<h5>Hubspot</h5>
            <CustomInput
              type="switch"
              label="Is Active"
              id="hubspotActiveId"
    			  	onChange={(e:any)=>{if(integrations.hubspot.active) {updateHubspot({active:false,refreshToken:""})}}}
    				  checked={integrations.hubspot.active}
            />
            {integrations.hubspot.stageId && <div>Get deals from: {(integrations.hubspot.stageName || "").toUpperCase()}</div>}
            {integrations.hubspot.newStageId && <div>Move confirmed deals to: {(integrations.hubspot.newStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage(null)}}>dont move</Button></div>}

            <CustomInput
              type="switch"
              label="Upload Contracts"
              id="hubspotSendContractId"
    			  	onChange={(e:any)=>{updateHubspot({sendContract:!integrations.hubspot.sendContract})}}
    				  checked={integrations.hubspot.sendContract}
            />

            <br/>
            <Button size="sm" color={integrations.hubspot.refreshToken?"success":"warning"} href={BASEURL+"/install"}>Hubspot authentication</Button>
            {' '}<Button size="sm" color="primary" onClick={getStages}>Get stages</Button>
            
        </Col>
        <Col>
          <div>{Object.keys(stages).map((key:any)=>(<div key={key}>
              <Button size="sm"
                color={key===integrations.hubspot.stageId?"success":"secondary"}
                value={key}
                onClick={()=>setStage(key)}
            >get deals from
            </Button>
            <Button size="sm"
                color={key===integrations.hubspot.newStageId?"success":"secondary"}
                value={key}
                onClick={()=>setNewStage(key)}
            >update stage
            </Button>

              {stages[key].toUpperCase()}
          </div>))}

            </div>
        </Col>
      </Row>
      </div>


    </React.Fragment>
  );
};

export default Hubspot;

