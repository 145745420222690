import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Button, Form } from "reactstrap";
import { IAppState } from "../../../../interfaces";
import { controlsToFormGroups, validateInput } from "../../../../shared/utility";
//import { Link, RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { RouteComponentProps } from '../../../../withRouter';
import axios from '../../../../axios';
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import * as actions from "../../../../store/actions";

import classes from "./ForgotForm.module.scss";

interface IStateProps {
  loading?: boolean;
  error?: string | null;
  status?: string;
}

interface IDispatchProps {
  onClearError: () => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const ForgotForm = (props: IProps) => {
  const [state, setState] = useState({
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          placeholder: "Email",
          type: "email"
        },
        validation: {
          required: true,
          email: true,
          error: "Email is not valid"
        },
        valid: false,
        touched: false,
        value: ""
      }
    },
    controlsIsValid: false,
    errors: [],
    userExists: true,
    emailSentStatus: ''
  });
  
  useEffect(() => {
    document.body.classList.add("login-bg");
    return () => {
      document.body.classList.remove("login-bg");
    };
  }, []);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value: any = event;
    if (event.target) {
      value = event.target.value;
    }
    const validation = validateInput(state.controls, controlName, value);
    setState({
      ...state,
      userExists: true,
      controls: validation.controls,
      controlsIsValid: validation.formIsValid,
    });
  };

  const getErrors = () => {
    const errors = Object.entries(state.controls).reduce((o, [key, obj]) => {
      if (!obj.valid) {
        return [...o, obj.validation.error]
      }
      return o
    }, []);
    setState({
      ...state,
      errors
    })
    return errors.length > 0
  }

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email } = state.controls;
    const hasErrors = getErrors();

    if (state.controlsIsValid && !hasErrors) {
      const userExists = await axios.get(`/json/users/validate?email=${email.value}`)

      if (!userExists.data) {
        setState({
          ...state,
          errors: [],
          controls: {
            email: {
              ...email,
              valid: true,
              touched: true
            }
          },
          controlsIsValid: false,
          userExists: false
        });
      } else {
        const { data } = await axios.post('/json/users/passwordresetmail', {email: email.value})
        setState({
          ...state,
          emailSentStatus: data
        })
      }
    }
  };

  const { error, loading, history } = props;
  const { userExists, errors, emailSentStatus } = state;

  return (
    <div className={classes.Container}>
      <div className={classes.FormBox}>
        <div className={classes.Title}>
          Forgot your password?
        </div>
        {loading ? <ListSpinner />
        : emailSentStatus === 'SUCCESS'
        ? <div>
            <p>We have sent you a password reset email. Click the link in the email to reset your password.</p>
            <p>If you can’t find the email, then please check other places it might be, like your junk mail, social or other folders.</p>
            <div className={classes.ButtonContainer}>
              <Button onClick={() => history.push('/login')} color="primary" className={classes.Button}>
                Log in
              </Button>
            </div>
          </div>
        : (
          <Form onSubmit={submitHandler}>
            <div className={classes.Inputs}>
              {controlsToFormGroups(state.controls, inputChangedHandler)}
            </div>
            {(error || !userExists) && (
              <Alert className={classes.ErrorMessage} color="danger">
                {error || 'We could not find account with that email address'}
              </Alert>
            )}
            {errors.length > 0 && (
              errors.map(e => (
                <Alert key={e} className={classes.ErrorMessage} color="danger">
                  {e}
                </Alert>
              ))
            )}
            <div className={classes.ButtonContainer}>
              <Button type="submit" color="primary" className={classes.Button}>
                Next
        </Button>
            </div>
            <div className={classes.LoginQuestion}>
              <h6>Remembered your password? <Link to="/login">Log in</Link></h6>
            </div>
          </Form>

        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.auth.loading,
    error: state.auth.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onClearError: () => dispatch(actions.clearError()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotForm);
