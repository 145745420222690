import React, { useEffect, useState } from 'react';
import { IIntegrations, IOrganization, IUser } from '../../../../../interfaces';

import { Button, CustomInput, Input, Row, Col } from 'reactstrap';
import { InputGroup } from 'reactstrap';

import axios from 'axios';
import uuid from "uuid";

import { config } from '../../../../../constants';

import classes from "../Integrations.module.scss";
const hostname = window && window.location && window.location.hostname;

let BASEURL=config.DYNAMICS.BASEURL;
/*
if(hostname === 'localhost') {
  BASEURL="http://localhost:8081/dynamics";
}
*/

const TENANT_ID = config.DYNAMICS.TENANT_ID; //'58fe6439-1bf3-4cf6-942e-8e988300e38a';
const CLIENT_ID = config.DYNAMICS.CLIENT_ID; //'ad7011d5-6fef-4e38-bf07-79770ff235e1';
const SCOPE = config.DYNAMICS.SCOPE; //"api://ad7011d5-6fef-4e38-bf07-79770ff235e1/API.ReadWrite.All";
const WEBRESOURCE_URI = config.DYNAMICS.WEBRESOURCE_URI;

const DYNAMICS_AUTH_URI = "https://login.microsoftonline.com/" + TENANT_ID + "/oauth2/authorize?client_id=" + CLIENT_ID + "&scope=" + SCOPE + "&response_type=code";

/*
const DYNAMICS_WEB_RESOURCE = (code:string) => { return ('<html><head></head><body style="overflow-wrap: break-word;margin:0;padding:0;" onfocusout="parent.setEmailRange();">'
  +'<script>var l = false; function openPage() { if(!l) { l=true; document.getElementById(`c2ciframe`).src = "http://localhost:3000/embedded/edit/add/deal/'
  +btoa(code)
  +'?integration=dynamics&"+location.search.replace("?","&")+"'
  +'&user="+btoa(JSON.stringify({'
  +'"id":window.parent.Xrm.Page.context.getUserId().replace(/[{}]/g, `),'
  +'"name":window.parent.Xrm.Page.context.getUserName(),'
  +'"referrer":document.referrer'
  +'}));} }</script>'
  +'<iframe id="c2ciframe" width="100%" height="100%" style="border:none;" onload="openPage()" referrerpolicy="origin"/></body></html>')};
*/

const DYNAMICS_WEB_RESOURCE = (code:string) => { return (`<html>`
+`<head></head>`
+`<body style="overflow-wrap: break-word;margin:0;padding:0;">`
+`<script>var l = false; function openPage() { if(!l) { l=true; document.getElementById('c2ciframe').src = "`
+WEBRESOURCE_URI+btoa(code)+`?integration=dynamics&"+location.search.replace("?","&")+`
+`"&user="+btoa(JSON.stringify({"id":window.parent.Xrm.Page.context.getUserId().replace(/[{}]/g, ''),"name":window.parent.Xrm.Page.context.getUserName(),"referrer":document.referrer}));} }`
+`</script><iframe id="c2ciframe" width="100%" height="100%" style="border:none;" onLoad="openPage()" referrerpolicy="origin"/></body>`
+`</html>`)}



interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  updateOrganization: (x:any)=>void;

}

interface IProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
  updateOrganization: (x:any)=>void;
  params: any;
}

export const getDeal:any = async (integrations:IIntegrations, user:IUser, deal:string) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deal?deal='+deal+'&token='+integrations.dynamics.refresh_token+"&email="+encodeURIComponent(user.email));
  return res.data;
}


export const getDeals:any = async (integrations:IIntegrations, user:IUser) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deals?stage='+integrations.dynamics.stageId+'&token='+integrations.dynamics.refresh_token+"&email="+encodeURIComponent(user.email))
  return res.data;
}


const Dynamics: React.FC<IProps> = ({
  currentUser,
  organization,
  updateOrganization,

  params,
}) => {

  const [stages, setStages] = useState<Array<String>>([]);

  const integrations=organization && organization.integrations;

  const updateDynamics = (updated:any) => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        dynamics:{
          ...organization.integrations.dynamics,
          ...updated,
        }  
      }      
    }
    updateOrganization(newo);
  }


  useEffect(() => {
    if(organization && params.token && params.name==="dynamics") {
      const newo= {...organization,
        integrations:{
          ...organization.integrations,
          dynamics:{
            ...organization.integrations.dynamics,
            active:true,
            refreshToken:params.token as string,
          }
        }
      }

      updateOrganization(newo);
    }
    // eslint-disable-next-line
  }, [organization]);


  const getStages = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get(BASEURL+'/stages?token='+integrations.dynamics.refresh_token+"&instance_url="+encodeURIComponent(integrations.dynamics.instance_url)).then((resp)=>{
      //console.log("resp",resp)
      setStages(resp.data)
    })
    .catch((resp)=> {
      //console.log("error",resp);
    })
  }

  const setSentStage = (key:any) => {
    updateDynamics({sentStageId:key,sentStageName:stages[key]})
  }

  const setOpenStage = (key:any) => {
    updateDynamics({openStageId:key,openStageName:stages[key]})
  }
  
  const setNewStage = (key:any) => {
    updateDynamics({newStageId:key,newStageName:stages[key] || null})
  }
 
    /*
  const getDeals = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get('http://localhost:8082/deals?stage='+integrations.dynamics.stageId+'&token='+integrations.dynamics.refreshToken).then((resp)=>{
      console.log("resp",resp)
      setDeals(resp.data)
    })
    .catch((resp)=> {
      console.log("error",resp);
    })
  }
     */

  const createNewOrganizationId = () => {
    if(window.confirm("Are you sure? You must update your web resource in Dynamics?")) {
      updateDynamics({organization_id:uuid()});
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(DYNAMICS_WEB_RESOURCE(integrations.dynamics.organization_id)).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      alert("Copy to clipboard failed");
    });
  }

/*
  const SALESFORCE_AUTH_URI = "https://login.dynamics.com" + 
    "/services/oauth2/authorize"+
    "?response_type=code"+ 
    "&client_id=" + config.SALESFORCE.CLIENT_ID + 
    "&redirect_uri=" + window.location.origin + "/integration/dynamics/oauth2callback";
*/
  return (
    <React.Fragment>
          <div className={classes.integration}>

      <Row>
        <Col>
      			<h5>Dynamics</h5>
            <CustomInput
              type="switch"
              label="Is Active"
              id="dynamicsActiveId"
    			  	onChange={(e:any)=>{if(integrations.dynamics.active) {updateDynamics({active:false,refreshToken:""})}}}
    				  checked={integrations.dynamics.active}
            />
            {integrations.dynamics.sentStageId && <div>Move sent deals to: {(integrations.dynamics.sentStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setSentStage(null)}}>dont move</Button></div>}
            {integrations.dynamics.openStageId && <div>Move opened deals to: {(integrations.dynamics.openStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setOpenStage(null)}}>dont move</Button></div>}
            {integrations.dynamics.newStageId && <div>Move confirmed deals to: {(integrations.dynamics.newStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage(null)}}>dont move</Button></div>}
{/*
            <Input
              type="switch"
              label="Upload Proposals to Opportunity"
              id="dynamicsSendProposalToId"
              onChange={(e:any)=>{updateDynamics({sendProposal:!integrations.dynamics.sendProposal})}}
              checked={integrations.dynamics.sendProposal}
            />

            <Input
              type="switch"
              label="Upload Contracts to Opportunity"
              id="dynamicsSendContractToId"
    			  	onChange={(e:any)=>{updateDynamics({sendContract:!integrations.dynamics.sendContract})}}
    				  checked={integrations.dynamics.sendContract}
            />

            <Input
              type="switch"
              label="Upload Contracts to Account"
              id="dynamicsSendContractToAccountId"
              onChange={(e:any)=>{updateDynamics({sendContractToAccount:!integrations.dynamics.sendContractToAccount})}}
              checked={integrations.dynamics.sendContractToAccount}
            />
*/}

            CRM Domain
            <Input
              type="text"
              label="CRM domain"
              id="dynamicsInstanceUrlId"
              onBlur={(e:any)=>{updateDynamics({instance_url:e.target.value})}}
              defaultValue={integrations.dynamics.instance_url}
            />

{/*
            Organization ID
            <Input
              type="text"
              label="Organization ID"
              id="dynamicsOrganizationId"
              //onBlur={(e:any)=>{updateDynamics({organization_id:e.target.value})}}
              value={integrations.dynamics.organization_id}
              readOnly={true}
            />
*/}            

            WEB Resource

            <InputGroup>
              <Button onClick={createNewOrganizationId}>Update</Button>
              <Input
                type="text"
                label="WEB Resource"
                id="dynamicsWebResource"
                readOnly={true}
                value={DYNAMICS_WEB_RESOURCE(integrations.dynamics.organization_id)}
              />
              <Button onClick={()=>copyToClipboard()}>Copy</Button>
            </InputGroup>
            

{/*
            <Input
              type="textarea"
              label="customfields"
              id="dynamicsCustomFieldsId"
              onBlur={(e:any)=>{updateDynamics({customFields:e.target.value})}}
              defaultValue={integrations.dynamics.customFields}
            />
*/}
            <br/>
            <Button size="sm" color={integrations.dynamics.refresh_token?"success":"warning"} href={DYNAMICS_AUTH_URI}>Dynamics authentication</Button>
            {/*
            {' '}{integrations.dynamics.instance_url && <Button size="sm" href={integrations.dynamics.instance_url+"/"+VISUALFORCEPAGE} target="_blank">Install Dynamics Visual Force Component</Button>}
            {' '}<Button size="sm" color="primary" onClick={getStages}>Get stages</Button>
            */}
            
        </Col>
        <Col>
          <div>{Object.keys(stages).map((key:any)=>(<div key={key}>
              <Button size="sm"
                color={key===integrations.dynamics.sentStageId?"success":"secondary"}
                value={key}
                onClick={()=>setSentStage(key)}
            >sent
            </Button>
            <Button size="sm"
                color={key===integrations.dynamics.openStageId?"success":"secondary"}
                value={key}
                onClick={()=>setOpenStage(key)}
            >opened
            </Button>
            <Button size="sm"
                color={key===integrations.dynamics.newStageId?"success":"secondary"}
                value={key}
                onClick={()=>setNewStage(key)}
            >confirmed
            </Button>

              {stages[key].toUpperCase()}
          </div>))}

            </div>
        </Col>
      </Row>
      </div>


    </React.Fragment>
  );
};

export default Dynamics;

