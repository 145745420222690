import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { IUserTemplateBlockDraggable } from "../../../../../../../interfaces";
import IconButton from "../../../../../../../components/UI/IconButton/IconButton";
import TemplateAreaItem from "../../../../Templates/Template/TemplateArea/TemplateAreaItem/TemplateAreaItem";

interface IProps {
	block: IUserTemplateBlockDraggable;
  blocks: Array<IUserTemplateBlockDraggable>;
	addBlock: (block1: IUserTemplateBlockDraggable,block2?: IUserTemplateBlockDraggable) => void;
	createBlock: ()=> IUserTemplateBlockDraggable;
	//openContentType: (x:IUserTemplateBlockDraggable) => void;
	activeBlock: IUserTemplateBlockDraggable;
}

const AddBlock: React.FC<IProps> = ({
	block,
	blocks,
	addBlock,
	createBlock,
	//	openContentType,
	activeBlock,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

	const [newBlock, setNewBlock]=useState(null);

	const onCreateBlock = () => {
		const n=createBlock();

		setNewBlock(n);
		//		setTimeout(()=>{setNewBlock(n)},1000);
	}

	useEffect(()=>{
		if(activeBlock && newBlock && activeBlock.id === newBlock.id) {
			setNewBlock(null);
			addBlock(block,newBlock);
			toggle();
		}
	},[activeBlock]);
	/*
	useEffect(()=>{
		if(newBlock) {
			console.log("add");
			addBlock(block,newBlock);
			toggle();
		}
	},[newBlock])
	 */
  return (<div>
		<IconButton	
	    color="dark"
    	icon={faPlus}
			onClick={toggle}
		/>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add new element</ModalHeader>
        <ModalBody>

      {blocks &&
        blocks.filter(item=>item.name!=="Untitled element").map((item, index) => (
          <TemplateAreaItem
            key={item.uuid}
            item={item}
            area={null}
            moveItem={null}
            clicked={(item)=>{addBlock(block,item);toggle()}}
            onStartDrag={null}
            onEndDrag={null}
            add={null}
            active={null}
          />
        ))}

        </ModalBody>
        <ModalFooter>
					<div className="mr-auto">
        	  <Button color="primary" onClick={onCreateBlock}>Create new element</Button>
      	    <Button color="link" onClick={toggle}>Discard</Button>
					</div>
        </ModalFooter>
      </Modal>
    </div>);
};

export default AddBlock;


