import React from "react";

interface IProps {
  id: string;
  width?: string;
  height?: string;
}

const defaultWidth = 560;
const defaultHeight = 315;

const EmbeddedVimeo: React.FC<IProps> = ({
  id,
  width = defaultWidth,
  height = defaultHeight
}) => {
  if (!id) return null;
  return (
    <iframe
      src={`https://player.vimeo.com/video/${id}`}
      //width="560"
      //height="315"
      width={width}
      height={height}
      frameBorder="0"
      allowFullScreen
      title="Vimeo"
    ></iframe>
  );
};

export default EmbeddedVimeo;
