import { languages } from "../languages";

export const getCustomerControls = (): any => {


  return {
    name: {
      elementType: "input",
      elementConfig: {
        label: "Customer name",
        placeholder: "Customer name",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    businessID: {
      elementType: "input",
      elementConfig: {
        label: "Business ID",
        placeholder: "Business ID",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    address: {
      elementType: "input",
      elementConfig: {
        label: "Address",
        placeholder: "Address",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },

    zip: {
      elementType: "input",
      elementConfig: {
        label: "Postal code",
        placeholder: "Postal code",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    city: {
      elementType: "input",
      elementConfig: {
        label: "City",
        placeholder: "City",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },

    contactPerson: {
      elementType: "input",
      elementConfig: {
        label: "Contact person",
        placeholder: "Contact person",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    phone: {
      elementType: "input",
      elementConfig: {
        label: "Phone number",
        placeholder: "Phone number in international format, e.g. +358401234567",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    email: {
      elementType: "input",
      elementConfig: {
        label: "Email *",
        placeholder: "Email",
        type: "email"
      },
      validation: {
        required: true,
        email: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    language: {
      elementType: "select",
      elementConfig: {
        label: "Language"
      },
      valid: true,
      touched: false,
      value: "",
      options: languages
    }
  };
};
