import React from "react";
import { IOffer } from "../../../../../interfaces";

interface IProps {
  offer: IOffer;
};

const OfferExpired: React.FC<IProps> = ({ offer }) => {
  return (
    <div style={{ textAlign: "center", marginTop: "1rem" }}>
      <h1>Proposal Expired</h1>
      <hr />
      <h3>Proposal expired, please contact:</h3>
      {offer ? (
        <p style={{ margin: 0 }}>
          {offer.organization && offer.organization.name ? (
            <React.Fragment>
              {offer.organization.name}
              <br />
            </React.Fragment>
          ) : null}
          {offer.organization && offer.organization.contactPerson ? (
            <React.Fragment>
              {offer.organization.contactPerson}
              <br />
            </React.Fragment>
          ) : null}
          {offer.organization && offer.organization.contactEmail ? (
            <React.Fragment>
              <a href={`mailto:${offer.organization.contactEmail}`}>{offer.organization.contactEmail}</a>
              <br />
            </React.Fragment>
          ) : null}
          {offer.organization && offer.organization.contactPhone ? (
            <React.Fragment>
              <a href={`tel:${offer.organization.contactPhone}`}>{offer.organization.contactPhone}</a>
            </React.Fragment>
          ) : null}
        </p>
      ) : null}
    </div>
  );
};

export default OfferExpired;
