import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';


import { ModalBody, ModalFooter, Button, ModalHeader, Input } from 'reactstrap';
import CustomButtonGroup from '../../../../../../components/UI/CustomButtonGroup/CustomButtonGroup';
import { useWindowDimensions } from '../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';

import classes from "./OfferSignatureModal.module.scss";

interface IProps {
  onAccept: (signature: string) => void;
}

enum ESignatureView {
  DRAW = "DRAW",
  TYPE = "TYPE",
}

const OfferSignatureModal: React.FC<IProps> = ({ onAccept }) => {
  const [view, setView] = useState(ESignatureView.DRAW);
  const [signatureString, setSignatureString] = useState("");
  const windowDimensions = useWindowDimensions();
  const { t } = useTranslation();

  const views = [
    {
      type: ESignatureView.DRAW,
      text: t("offerSignatureModal.draw")
    },
    {
      type: ESignatureView.TYPE,
      text: t("offerSignatureModal.type")
    }
  ];

  const onViewChanged = (view: ESignatureView) => {
    setView(view);
  }

  const canvasRef = useRef(null);

  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0
  });

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;

      setCanvasDimensions({
        height: container.clientHeight,
        width: container.clientWidth
      });
    }
  }, [containerRef, windowDimensions]);

  const getSignature = (): string => {
    if (view === ESignatureView.DRAW) {
      const canvas = canvasRef.current as any;
      if (canvas) {
        const signature = canvas.getTrimmedCanvas().toDataURL();
        canvas.clear();
        return signature;
      }
    } else {
      return signatureString;
    }
    return "";
  };

  const onClearSignature = () => {
    if (view === ESignatureView.DRAW) {
      const canvas = canvasRef.current as any;
      if (canvas) {
        canvas.clear();
      }
    }
  }

  return (
    <React.Fragment>
      <ModalHeader>
        <CustomButtonGroup
          items={views}
          clicked={onViewChanged}
          selected={view}
        />
        {view === ESignatureView.DRAW && <Button style={{ marginLeft: "1rem" }} onClick={onClearSignature}>{t("offerSignatureModal.clear")}</Button>}
      </ModalHeader>
      <ModalBody style={{ display: "flex" }}>
        {(() => {
          switch (view) {
            case ESignatureView.DRAW:
              return (
                <div ref={containerRef} style={{ flexGrow: 1 }} className={classes.CanvasContainer}>
                  <SignatureCanvas
                    ref={canvasRef}
                    minWidth={1.5}
                    canvasProps={{
                      width: canvasDimensions.width,
                      height: canvasDimensions.height,
                    }}
                  />
                </div>
              );
            case ESignatureView.TYPE:
              return (
                <Input
                  id="name-input"
                  className={classes.Input}
                  type="text"
                  value={signatureString}
                  onChange={event => setSignatureString(event.target.value)}
                />
              )
            default:
              return null;
          }
        })()}
      </ModalBody>
      <ModalFooter>
        <Button color="info" onClick={() => onAccept(getSignature())}>{t("offerSignatureModal.save")}</Button>
      </ModalFooter>
    </React.Fragment>
  );
};

export default OfferSignatureModal;
