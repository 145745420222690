import React, { useState, useContext } from "react";
import { Button } from "reactstrap";

import CustomDialog from "../../../../../../../components/UI/CustomDialog/CustomDialog";
import {
  IBlockContent,
  IBlockContentContent
} from "../../../../../../../interfaces";
import { ETextType } from "../../ContentTypeDialog/ContentTypeItemContent/ContentTypeItemContent";
import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";

import classes from "./BlockContentContentShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent?: boolean;
}

const WYSIWYGDymmy = `
  <h3>Lorem ipsum</h3>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
    Morbi ac fermentum sem. Vivamus viverra aliquam augue. 
    Integer ac mauris vel nisi porta porttitor eget vel lacus. 
    Nulla erat sem, viverra sit amet efficitur vitae, pellentesque aliquam augue. 
    In id consectetur nulla. Vestibulum gravida euismod orci vel fermentum. 
    Curabitur elementum elit vel nisl lobortis accumsan. Donec neque eros, molestie eu sem non, sagittis iaculis libero. 
    Praesent id augue purus. Morbi vitae libero non lacus luctus efficitur. 
    Curabitur egestas varius enim, vel efficitur sapien pellentesque at. 
    Phasellus pharetra neque eget risus gravida finibus. 
    Cras at lectus mollis, efficitur felis luctus, fringilla purus. 
    In in consectetur erat.
  </p>
`;

const textareaDummy = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
  Morbi ac fermentum sem. Vivamus viverra aliquam augue. 
  Integer ac mauris vel nisi porta porttitor eget vel lacus. 
  Nulla erat sem, viverra sit amet efficitur vitae, pellentesque aliquam augue. 
  In id consectetur nulla. Vestibulum gravida euismod orci vel fermentum. 
  Curabitur elementum elit vel nisl lobortis accumsan. Donec neque eros, molestie eu sem non, sagittis iaculis libero. 
  Praesent id augue purus. Morbi vitae libero non lacus luctus efficitur. 
  Curabitur egestas varius enim, vel efficitur sapien pellentesque at. 
  Phasellus pharetra neque eget risus gravida finibus. 
  Cras at lectus mollis, efficitur felis luctus, fringilla purus. 
  In in consectetur erat.
`;

const titleDummy = "Lorem ipsum";
const linkTextDummy = "Lorem ipsum";

const BlockContentContentShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentContent;
  const showDummyContent = props.showDummyContent;

  const { printable } = useContext(OfferPreviewContext);

  const getContent = (content: string, dummyContent: string) => {
    if (!showDummyContent || (content && content.length > 0)) return content;
    return dummyContent;
  };

  const renderHTML = (html: string) => {
    return (
      <span className={html && html.includes("<div")?"":"ql-editor"}
        dangerouslySetInnerHTML={{
          __html: getContent(html, WYSIWYGDymmy)
        }}
      ></span>
    );
  };

  const createDialog = (linkText: string, title: string, content: string, centerButton: boolean) => {
    return printable ? (
      renderHTML(content)
    ) : (
      <React.Fragment>
        <div className={centerButton ? classes.Button : null}>
          <Button color="link" onClick={() => setIsOpen(true)}>
            {getContent(linkText, linkTextDummy)}
          </Button>
        </div>
        <CustomDialog
          open={isOpen}
          onCancel={() => setIsOpen(!isOpen)}
          title={getContent(title, titleDummy)}
          overflow={true}
          overflowFullWidth={true}
          hideButtons
        >
          {renderHTML(content)}
        </CustomDialog>
      </React.Fragment>
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      {(() => {
        switch (blockContent.textType) {
          case ETextType.PLAIN:
          case ETextType.TEXTAREA:
            return (
              <p className={classes.Paragraph}>
                {getContent(blockContent.content, textareaDummy)}
              </p>
            );
          case ETextType.WYSIWYG:
            return renderHTML(blockContent.content);
          case ETextType.MODAL:
            return (
              <div>
                {blockContent.content && <>
                {createDialog(
                  blockContent.linkText,
                  blockContent.title,
                  blockContent.content,
                  true
                )}
                </>}
              </div>
            );
          case ETextType.WYSIWYG_MODAL:
            return (
              <div>
                {renderHTML(blockContent.content)}
                {blockContent.contentLong && <>
                {createDialog(
                  blockContent.linkText,
                  blockContent.title,
                  blockContent.contentLong,
                  false
                )}
                </>}
              </div>
            );
          default:
            return null;
        }
      })()}
    </React.Fragment>
  );
};

export default BlockContentContentShow;
