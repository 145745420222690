import { Dispatch } from "redux";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IOrganizationAction, IOrganization, IPipedriveConnection } from "../../interfaces";

export const findOrganizationsStart = (): IOrganizationAction => {
  return {
    type: ActionTypes.FIND_ORGANIZATIONS_START
  };
};

export const findOrganizationsSuccess = (
  organizations: Array<IOrganization>
): IOrganizationAction => {
  return {
    type: ActionTypes.FIND_ORGANIZATIONS_SUCCESS,
    organizations
  };
};

export const findOrganizationsFail = (error: string): IOrganizationAction => {
  return {
    type: ActionTypes.FIND_ORGANIZATIONS_FAIL,
    error
  };
};

export const findOrganizations = () => {
  return async (dispatch: Dispatch) => {
    dispatch(findOrganizationsStart());
    try {
      const res = await axios.get("/json/organizations/list");
      const organizations = res.data;
      dispatch(findOrganizationsSuccess(organizations));
      //@ts-ignore
    } catch (error: any) {
      dispatch(findOrganizationsFail(error.response.data));
    }
  };
};

export const getOrganizationStart = (): IOrganizationAction => {
  return {
    type: ActionTypes.GET_ORGANIZATION_START
  };
};

export const getOrganizationSuccess = (
  organization: IOrganization
): IOrganizationAction => {
  return {
    type: ActionTypes.GET_ORGANIZATION_SUCCESS,
    organization
  };
};

export const getOrganizationFail = (error: string): IOrganizationAction => {
  return {
    type: ActionTypes.GET_ORGANIZATION_FAIL,
    error
  };
};

export const getOrganization = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getOrganizationStart());
    try {
      const res = await axios.get(`/json/organizations/get?id=${id}`);
      const organization = res.data;
      dispatch(getOrganizationSuccess(organization));
      //@ts-ignore
    } catch (error: any) {
      dispatch(getOrganizationFail(error.response.data));
    }
  };
};

export const saveOrganizationStart = (): IOrganizationAction => {
  return {
    type: ActionTypes.SAVE_ORGANIZATION_START
  };
};

export const saveOrganizationSuccess = (): IOrganizationAction => {
  return {
    type: ActionTypes.SAVE_ORGANIZATION_SUCCESS
  };
};

export const saveOrganizationFail = (error: string): IOrganizationAction => {
  return {
    type: ActionTypes.SAVE_ORGANIZATION_FAIL,
    error
  };
};

export const saveOrganization = (formData: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveOrganizationStart());
    try {
      await axios.post("/json/organizations/add", formData);
      dispatch(saveOrganizationSuccess());
      //@ts-ignore
    } catch (error: any) {
      dispatch(saveOrganizationFail(error.response.data));
    }
  };
};

export const updateOrganizationStart = (): IOrganizationAction => {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_START
  };
};

export const updateOrganizationSuccess = (): IOrganizationAction => {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_SUCCESS
  };
};

export const updateOrganizationFail = (error: string): IOrganizationAction => {
  return {
    type: ActionTypes.UPDATE_ORGANIZATION_FAIL,
    error
  };
};

export const updateOrganization = (formData: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateOrganizationStart());
    try {
      await axios.post("/json/organizations/add", formData);
      dispatch(updateOrganizationSuccess());
      //@ts-ignore
    } catch (error: any) {
      dispatch(updateOrganizationFail(error.response.data));
    }
  };
};

export const searchOrganizationsStart = (): IOrganizationAction => {
  return {
    type: ActionTypes.SEARCH_ORGANIZATIONS_START
  };
};

export const searchOrganizationsSuccess = (
  search: Array<IOrganization>
): IOrganizationAction => {
  return {
    type: ActionTypes.SEARCH_ORGANIZATIONS_SUCCESS,
    search
  };
};

export const searchOrganizationsFail = (error: string): IOrganizationAction => {
  return {
    type: ActionTypes.SEARCH_ORGANIZATIONS_FAIL,
    error
  };
};

export const searchOrganizations = (queryString: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(searchOrganizationsStart());
    try {
      const res = await axios.get(
        `/json/organizations/search?q=${queryString}`
      );
      dispatch(searchOrganizationsSuccess(res.data));
      //@ts-ignore
    } catch (error: any) {
      dispatch(searchOrganizationsFail(error.response.data));
    }
  };
};

export const getUserOrganizationStart = (): IOrganizationAction => {
  return {
    type: ActionTypes.GET_USER_ORGANIZATION_START
  };
};

export const getUserOrganizationSuccess = (
  organization: IOrganization
): IOrganizationAction => {
  return {
    type: ActionTypes.GET_USER_ORGANIZATION_SUCCESS,
    organization: organization
  };
};

export const getUserOrganizationFail = (): IOrganizationAction => {
  return {
    type: ActionTypes.GET_USER_ORGANIZATION_FAIL
  };
};

export const getUserOrganization = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getUserOrganizationStart());
    try {
      const res = await axios.get("/json/organizations/user");
      const organization = res.data;
      dispatch(getUserOrganizationSuccess(organization));
      return organization;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getUserOrganizationFail());
    }
  };
};


export const testPipedriveStart = (): IOrganizationAction => {
  return {
    type: ActionTypes.TEST_PIPEDRIVE_ORGANIZATION_START
  };
};

export const testPipedriveSuccess = (pipedriveConnection: IPipedriveConnection): IOrganizationAction => {
  return {
    type: ActionTypes.TEST_PIPEDRIVE_ORGANIZATION_SUCCESS,
    pipedriveConnection
  };
};

export const testPipedriveFail = (error: string): IOrganizationAction => {
  return {
    type: ActionTypes.TEST_PIPEDRIVE_ORGANIZATION_FAIL,
    error
  };
};

export const testPipedrive = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(testPipedriveStart());
    try {
      const resp = await axios.post("/json/organizations/pipedriveconnection", {id});
      dispatch(testPipedriveSuccess(resp.data));
      //@ts-ignore
    } catch (error: any) {
      dispatch(testPipedriveFail(error.response.data));
    }
  };
};

export const disconnectPipedriveStart = (): IOrganizationAction => {
  return {
    type: ActionTypes.DISCONNECT_PIPEDRIVE_ORGANIZATION_START
  };
};

export const disconnectPipedriveSuccess = (organization: IOrganization): IOrganizationAction => {
  return {
    type: ActionTypes.DISCONNECT_PIPEDRIVE_ORGANIZATION_SUCCESS,
    organization
  };
};

export const disconnectPipedriveFail = (error: string): IOrganizationAction => {
  return {
    type: ActionTypes.DISCONNECT_PIPEDRIVE_ORGANIZATION_FAIL,
    error
  };
};

export const disconnectPipedrive = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(disconnectPipedriveStart());
    try {
      const res = await axios.post("/json/organizations/disconnectpipedrive", {id});
      dispatch(disconnectPipedriveSuccess(res.data));
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(disconnectPipedriveFail(error.response.data));
    }
  };
};
