import { ICustomer } from './../../interfaces/domain';
import { Dispatch } from 'redux';

import axios from '../../axios';
import { IImportCustomersAction } from '../../interfaces';
import { ActionTypes } from './actionTypes';

export const uploadCustomersStart = ():IImportCustomersAction => {
  return {
      type: ActionTypes.UPLOAD_CUSTOMERS_START
  };
};

export const uploadCustomersSuccess = (customersImport:Array<ICustomer>):IImportCustomersAction => {
  return {
      type: ActionTypes.UPLOAD_CUSTOMERS_SUCCESS,
      customersImport
  };
};

export const uploadCustomersFail = (error:string):IImportCustomersAction => {
  return {
      type: ActionTypes.UPLOAD_CUSTOMERS_FAIL,
      error
  }
};

export const uploadCustomers = (data:{}) => {
  return async (dispatch:Dispatch) => {
      dispatch(uploadCustomersStart());
      try {
          const res = await axios.post("/json/customers/csv/read", data);
          dispatch(uploadCustomersSuccess(res.data));
          //@ts-ignore
      } catch (error: any) {
          dispatch(uploadCustomersFail(error.response));
      }    
  };
};

export const importCustomersStart = ():IImportCustomersAction => {
  return {
      type: ActionTypes.IMPORT_CUSTOMERS_START
  };
};

export const importCustomersSuccess = ():IImportCustomersAction => {
  return {
      type: ActionTypes.IMPORT_CUSTOMERS_SUCCESS
  };
};

export const importCustomersFail = (error:string):IImportCustomersAction => {
  return {
      type: ActionTypes.IMPORT_CUSTOMERS_FAIL,
      error
  }
};

export const importCustomers = (data:{}) => {
  return async (dispatch:Dispatch) => {
      dispatch(importCustomersStart());
      try {
          await axios.post("/json/customers/csv/import", data);
          dispatch(importCustomersSuccess());
          //@ts-ignore
      } catch (error: any) {
          dispatch(importCustomersFail(error.response.data));
      }
  };
};

export const clearCustomersImport = ():IImportCustomersAction => {
  return {
      type: ActionTypes.CLEAR_CUSTOMERS_IMPORT
  };
};
