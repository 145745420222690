import { Dispatch } from "redux";
import axios from "../../axios";
import { IUserGroup, IUserGroupAction } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

/**
 * User Group
 */

export const findUserGroupsStart = (): IUserGroupAction => {
  return {
    type: ActionTypes.FIND_USER_GROUPS_START
  };
};

export const findUserGroupsSuccess = (userGroups: Array<IUserGroup>): IUserGroupAction => {
  return {
    type: ActionTypes.FIND_USER_GROUPS_SUCCESS,
    userGroups
  };
};

export const findUserGroupsFail = (error: string): IUserGroupAction => {
  return {
    type: ActionTypes.FIND_USER_GROUPS_FAIL,
    error
  };
};

export const findUserGroups = (organizationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(findUserGroupsStart());
    try {
      if (!organizationId) {
        organizationId = "";
      }
      const res = await axios.get(
        `/json/usergroups/list?organizationId=${organizationId}`
      );
      const userGroups = res.data;
      dispatch(findUserGroupsSuccess(userGroups));
      //@ts-ignore
    } catch (error: any) {
      dispatch(findUserGroupsFail(error.response.data));
    }
  };
};



export const getUserGroupStart = (): IUserGroupAction => {
  return {
    type: ActionTypes.GET_USER_GROUP_START
  };
};

export const getUserGroupSuccess = (userGroup: IUserGroup): IUserGroupAction => {
  return {
    type: ActionTypes.GET_USER_GROUP_SUCCESS,
    userGroup
  };
};

export const getUserGroupFail = (error: string): IUserGroupAction => {
  return {
    type: ActionTypes.GET_USER_GROUP_FAIL,
    error
  };
};

export const getUserGroup = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getUserGroupStart());
    try {
      const res = await axios.get(`/json/usergroups/get?id=${id}`);
      const userGroup = res.data;
      dispatch(getUserGroupSuccess(userGroup));
      //@ts-ignore
    } catch (error: any) {
      dispatch(getUserGroupFail(error.response.data));
    }
  };
};

export const saveUserGroupStart = (): IUserGroupAction => {
  return {
    type: ActionTypes.SAVE_USER_GROUP_START
  };
};

export const saveUserGroupSuccess = (): IUserGroupAction => {
  return {
    type: ActionTypes.SAVE_USER_GROUP_SUCCESS
  };
};

export const saveUserGroupFail = (error: string): IUserGroupAction => {
  return {
    type: ActionTypes.SAVE_USER_GROUP_FAIL,
    error
  };
};

export const saveUserGroup = (userGroup: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveUserGroupStart());
    try {
      await axios.post("/json/usergroups/save", userGroup);
      dispatch(saveUserGroupSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(saveUserGroupFail(error.response.data));
    }
  };
};

export const updateUserGroupStart = (): IUserGroupAction => {
  return {
    type: ActionTypes.UPDATE_USER_GROUP_START
  };
};

export const updateUserGroupSuccess = (): IUserGroupAction => {
  return {
    type: ActionTypes.UPDATE_USER_GROUP_SUCCESS
  };
};

export const updateUserGroupFail = (error: string): IUserGroupAction => {
  return {
    type: ActionTypes.UPDATE_USER_GROUP_FAIL,
    error
  };
};

export const updateUserGroup = (formData: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserGroupStart());
    try {
      await axios.put("/json/usergroups/update", formData);
      dispatch(updateUserGroupSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(updateUserGroupFail(error.response.data));
      return false;
    }
  };
};


export const deleteUserGroupStart = (): IUserGroupAction => {
  return {
    type: ActionTypes.DELETE_USER_GROUP_START
  };
};

export const deleteUserGroupSuccess = (): IUserGroupAction => {
  return {
    type: ActionTypes.DELETE_USER_GROUP_SUCCESS
  };
};

export const deleteUserGroupFail = (error: string): IUserGroupAction => {
  return {
    type: ActionTypes.DELETE_USER_GROUP_FAIL,
    error
  };
};

export const deleteUserGroup = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteUserGroupStart());
    try {
      await axios.delete(`/json/usergroups/delete?id=${id}`);
      dispatch(deleteUserGroupSuccess());
      //@ts-ignore
    } catch (error: any) {
      if (error.response) {
        dispatch(deleteUserGroupFail(error.response.data));
      }
    }
  };
};

