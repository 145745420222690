import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect
} from "react";
import { useContext } from "react";

//import { EContentType, EDropzoneType } from "../../../../../../../enums";
import {
  IBlockContent,
  IBlockContentParties,
  //ICustomer,
  IOrganization,
} from "../../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData
} from "../../../../../../../shared/utility";
import { IBlockContentEditItem } from "../../BlockContentEdit";

//import OfferContext from "../../../../../containers/Offers/Offer/OfferContext";
import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";

interface IProps {
  ref: any;
  blockContent: IBlockContent;
  onChange?: (blockContent: IBlockContent) => void;
}

const BlockContentPartiesEdit: React.ForwardRefExoticComponent<
  IBlockContentEditItem
> = forwardRef((props, ref) => {
  const [init, setInit] = useState(false);
  const blockContent = props.blockContent as IBlockContentParties;


  //const { offer } = useContext(OfferContext);
  const { organization } = useContext(OfferPreviewContext);
  
  const [controlsState, setControlsState] = useState({
    controls: {
      isModified: {
        elementType: "input",
        elementConfig: {
          type: "hidden"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: true
      },

      leftName: {
        elementType: "input",
        elementConfig: {
          label: "My Company",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.leftName:organization && organization.name || "",
      },
      leftId: {
        elementType: "input",
        elementConfig: {
          label: "Business ID",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.leftId:organization && organization.businessID || "", 

      },
      leftAddress: {
        elementType: "input",
        elementConfig: {
          label: "Address",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.leftAddress:organization && ((organization.streetAddress || "") +" "+(organization.zip || "")+" "+(organization.city || "")).trim() || "",
      },
      leftContact: {
        elementType: "input",
        elementConfig: {
          label: "Contact",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.leftContact:organization && organization.contactPerson || "",
      },
      leftPhone: {
        elementType: "input",
        elementConfig: {
          label: "Phone",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.leftPhone:organization && organization.contactPhone || "",
      },
      leftEmail: {
        elementType: "input",
        elementConfig: {
          label: "Email",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.leftEmail:organization && organization.contactEmail || "",
      },

/*
      rightName: {
        elementType: "input",
        elementConfig: {
          label: "Customer",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.rightName:customer.name
      },
      rightId: {
        elementType: "input",
        elementConfig: {
          label: "Business ID",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.rightId:customer.businessID
      },
      rightAddress: {
        elementType: "input",
        elementConfig: {
          label: "Addess",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.rightAddress:customer.address+" "+customer.zip+" "+customer.city
      },
      rightContact: {
        elementType: "input",
        elementConfig: {
          label: "Contact",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.rightContact:customer.contactPerson
      },
      rightPhone: {
        elementType: "input",
        elementConfig: {
          label: "Phone",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.rightPhone:customer.phone
      },
      rightEmail: {
        elementType: "input",
        elementConfig: {
          label: "Email",
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.isModified?blockContent.rightEmail:customer.email
      },
*/

    },
    formIsValid: true
  });

  // FIXME: sets value of other controls in the same state to empty or null
  // Separated for now
/*  
  const [descriptionState, setDescriptionState] = useState({
    controls: {
      description: {
        elementType: "wysiwyg",
        elementConfig: {
          label: "Description",
          placeholder: "Description",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: blockContent.description || ""
      }
    },
    formIsValid: false
  })
*/
  const getData = () => {
    return Object.assign(blockContent, getFormData(controlsState.controls)); //, getFormData(descriptionState.controls));
  };

  useEffect(() => {
    if (props.onChange && init) {
      props.onChange(getData());
    }
    // eslint-disable-next-line
  }, [controlsState.controls]);

  useEffect(() => {
    setInit(true);
    if(props.onChange && !blockContent.isModified) {
      props.onChange(getData());

    }
  }, []);

  useImperativeHandle(ref, () => ({
    getData
  }));

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  };
/*
  const descriptionInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      descriptionState,
      setDescriptionState
    );
  };
*/
  let blurHandler:any = null;
    if(props.onBlur) {
      blurHandler = () => {
        props.onBlur(getData());
      }
    }

  return (
    <React.Fragment>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler, blurHandler, true)}
      {/*controlsToFormGroups(descriptionState.controls, descriptionInputChangedHandler, blurHandler)*/}
    </React.Fragment>
  );
});

export default BlockContentPartiesEdit;
