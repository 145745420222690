import React, { useState } from 'react';

import classes from "./OfferCombined.module.scss";

interface IProps {
	value?: string;
	onChange: (e: string) => void;
}

const HoverInput: React.FC<IProps>  = props => {
	const { value, onChange } = props

	const [v, setV] = useState(value);

	const [hover, setHover] = useState(false);    

	const onHover = () => setHover(true);
	const blur = () => {
		setHover(false);
		onChange(v);
	}

	const tmpOnChange = (e:any) => {
		setV(e.target.value);
	}

	return (<>
		{hover && <input
			autoFocus
			className={classes.hoverInput}
			value={v}
			onChange={tmpOnChange}
			onKeyUp={(e:any)=>{if(e.keyCode===13) blur()}}
			onBlur={blur}
	  	/>}
		{!hover && <span onClick={onHover}>{value}</span>}
	</>);
};

export default HoverInput;
