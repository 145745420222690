import React, { useEffect, useState } from 'react';

import { IUserTemplate, IOrganization } from '../../../../../../../../interfaces';
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData,
  initForm,
} from '../../../../../../../../shared/utility';

import classes from "../../../../../../../../components/UI/SidebarCollapse/SidebarCollapse.module.scss";
interface IProps {
  userTemplate: IUserTemplate;
	update: (data:Object) => void;
	organization: IOrganization;
}


const initControls = {
  controls: {
    customerDetails_name: {
      elementType: "input",
      elementConfig: {
        label: "Company name apiKey",
        placeholder: "apikey",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    customerDetails_businessID: {
      elementType: "input",
      elementConfig: {
        label: "BusinessID apiKey",
        placeholder: "apikey",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },


    customerDetails_address: {
      elementType: "input",
      elementConfig: {
        label: "Address apiKey",
        placeholder: "apikey",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    customerDetails_zip: {
      elementType: "input",
      elementConfig: {
        label: "Postal code apiKey",
        placeholder: "apikey",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    customerDetails_city: {
      elementType: "input",
      elementConfig: {
        label: "City apiKey",
        placeholder: "apikey",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },

    customerDetails_contactPerson: {
      elementType: "input",
      elementConfig: {
        label: "Contact person apiKey",
        placeholder: "apikey",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    customerDetails_phone: {
      elementType: "input",
      elementConfig: {
        label: "Phone apiKey",
        placeholder: "apikey",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    customerDetails_email: {
      elementType: "input",
      elementConfig: {
        label: "Email apiKey",
        placeholder: "apikey",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },

  },
  formIsValid: false
}

const TemplateSidebarEditTemplate: React.FC<IProps> = ({ userTemplate, update, organization }) => {
  const [controlsState, setControlsState] = useState(initControls);

  useEffect(() => {
    setControlsState(initForm(initControls.controls, {...userTemplate, ...userTemplate.settings}));
    // eslint-disable-next-line
  }, [])

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(event, controlName, controlsState, setControlsState);
  };

  const onBlurHandler = () => {
    update(getFormData(controlsState.controls));
  }

  return (
    <React.Fragment>
      <hr/>
      <div className={classes.Header}>
        <p>Custom import settings</p>
      </div>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler, onBlurHandler)}
    </React.Fragment>
  );
};

export default TemplateSidebarEditTemplate;
