import React, { useState } from "react";

import BoxBody from "../../../../../../components/UI/BoxBody/BoxBody";
import CreatedBy from "../../../../../../components/UI/CreatedBy/CreatedBy";
import ExpirationDate from "../../../../../../components/UI/ExpirationDate/ExpirationDate";
import Subtitle from "../../../../../../components/UI/Typography/Subtitle";
import Title from "../../../../../../components/UI/Typography/Title";
import { IOffer } from "../../../../../../interfaces";
import OfferComments from "../OfferComment/OfferComments";
import OfferPreviewSidebarAuditLog from "./OfferPreviewSidebarNav/OfferPreviewSidebarAuditLog/OfferPreviewSidebarAuditLog";
import OfferHistory from "./OfferPreviewSidebarNav/OfferPreviewSidebarHistory/OfferHistory/OfferHistory";
import OfferPreviewSidebarNav from "./OfferPreviewSidebarNav/OfferPreviewSidebarNav";

//import axios from 'axios';

import classes from "./OfferPreviewSidebar.module.scss";

export enum EOfferSidebarTab {
  HISTORY = "history",
  COMMENTS = "comments",
  AUDIT_LOG = "audit_log",
    //  EMAIL = "email",
}

interface IProps {
  offer: IOffer;
  offerDone: boolean;
  view: string;
}

const OfferPreviewSidebar: React.FC<IProps> = ({
  offer,
  offerDone,
  view
}) => {
  const [tab, setTab] = useState(view ? view : EOfferSidebarTab.HISTORY);

  const tabClickHandler = (tab: EOfferSidebarTab) => {
    setTab(tab);
  };

  const tabs = [
    { id: EOfferSidebarTab.HISTORY, title: "History" },
    { id: EOfferSidebarTab.COMMENTS, title: "Comments" },
    //{ id: EOfferSidebarTab.EMAIL, title: "Email" },

    // { id: EOfferSidebarTab.AUDIT_LOG, title: "Audit Log" }
  ];

  /*
  const filterProducts = (blocks:any) => {
    const xx=(blocks || []).reduce((resp:any,ob:any)=>{
      return [...resp,...(ob.content || []).filter((c:any)=>c.contentType==="PRODUCTS").reduce((r:any,c:any)=>{
        return [...r,...(c.products || []).filter((p:any)=>(p.library===false && (p.optional===false || p.optionalSelected===true)))]
      },[])]
    },[])

    return xx
  }

  const send2procountor = () => {
    const name=offer.customer.name
    const lang=offer.customer.language

    const prods=[
      ...filterProducts(offer.userTemplate.header.blocks),
      ...filterProducts(offer.userTemplate.main.blocks),
      ...filterProducts(offer.userTemplate.footer.blocks),
    ]

    console.log(offer.customer)
    const data={
      buyer:{
        name:name,
        businessID:offer.customer.businessID,

      },
      rows:prods.map((ob)=>({
        item:ob.item,
        price:ob.unitPrice,
        quantity:ob.quantity,
        unit:ob.unit,
        vat:ob.vat,
        discount:ob.discount,
      }))
    }

    axios.post('http://localhost:8081/',data).then((resp)=>{
    })
    .catch((resp)=> {
    })
  }
     */

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <BoxBody className={classes.Body}>
          {offer.offerCounter && <div style={{"float":"right"}}>{offer.offerCounter}</div>}
          <Title>{offer.customer && offer.customer.name}</Title>
          <Subtitle>{offer.name}</Subtitle>

          <CreatedBy
            date={offer.created}
            seller={offer.author && offer.author.name}
          />
          <br />

          {!offerDone && (
            <ExpirationDate
              expirationDate={offer.expirationDate}
              isExpired={offer.isExpired}
              isExpiring={offer.isExpiring}
              lang={offer.customer && offer.customer.language || "fi"}
            />
          )}

          <OfferPreviewSidebarNav
            tabs={tabs}
            setTab={tabClickHandler}
            current={tab}
          />
          {/*<button onClick={send2procountor}>SEND TO PROCOUNTOR</button>*/}

          <div>
            {(() => {
              switch (tab) {
                case EOfferSidebarTab.HISTORY:
                  return <OfferHistory offer={offer} />;

                case EOfferSidebarTab.COMMENTS:
                  return (
                    <div className={classes.OfferComments}>
                      <OfferComments offer={offer}/>
                    </div>
                  );

                case EOfferSidebarTab.AUDIT_LOG:
                  return <OfferPreviewSidebarAuditLog offer={offer} />;
                  /*
                case EOfferSidebarTab.EMAIL:
                  return <div>
                    <b>{offer.email.title}</b><br/>{offer.email.body}
                  {JSON.stringify(offer.offerIntegrationData)}
                  </div>;
                  */
                default:
                  return null;
              }
            })()}
          </div>
        </BoxBody>

      </div>
    </React.Fragment>
  );
};

export default OfferPreviewSidebar;
