import React from "react";
import { Button } from "reactstrap";

import classes from "./FormFooter.module.scss";

interface IProps {
  saveText: string;
  discardText?: string;
  onDiscard: () => void;
  onSave: (event: React.FormEvent<HTMLButtonElement>) => Promise<void>;
  isValid: boolean;
  loading?: boolean;
  deleteText?: string;
  onDelete?: () => void;
}

const FormFooter: React.FC<IProps> = ({
  onDiscard,
  onSave,
  isValid,
  discardText,
  saveText,
  loading,
  deleteText,
  onDelete
}) => {
  return (
    <div className={classes.Container}>
      {onDelete ? (
        <Button color="link" onClick={onDelete} disabled={loading}>
          {deleteText ? deleteText : "Delete"}
        </Button>
      ) : null}
      <Button color="link" onClick={onDiscard} disabled={loading}>
        {discardText ? discardText : "Discard"}
      </Button>
      <Button color="info" onClick={onSave} disabled={!isValid || loading}>
        {saveText}
      </Button>
    </div>
  );
};

export default FormFooter;
