import React, { useEffect } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../withRouter';

import ListHeader from "../../../../components/ListHeader/ListHeader";
import Content from "../../../../components/UI/Content/Content";
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import { IAppState, IUserTemplate, IUserGroup } from "../../../../interfaces";
import * as actions from "../../../../store/actions";
import TemplatesList from "../../components/Templates/TemplatesList";
import { EPageView } from "../../../../enums";


interface IStateProps {
  loading: boolean;
  error: string;
  userTemplates: Array<IUserTemplate>;
  userGroups: Array<IUserGroup>;
}

interface IDispatchProps {
  onFindUserTemplates: (params: string) => void;
  onFindUserGroups: (organizationId: string) => void;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {}

const UserTemplates: React.FC<IProps> = ({
  onFindUserTemplates,
  onFindUserGroups,
  userTemplates,
  userGroups,
  loading,
  history
}) => {
  useEffect(() => {
    onFindUserTemplates(null); //"list=all");
    onFindUserGroups(null);
  }, [onFindUserTemplates]);

  const onCreateTemplate = () => {
    history.push(`/templates/add/${EPageView.COMBINED}`); //EDIT}`);
  };

  const onView = (id:string) => {
    history.push(`/templates/${id}/${EPageView.PREVIEW}`);
  }

  const onEdit = (id:string) => {
    history.push(`/templates/${id}/${EPageView.COMBINED}`); //EDIT}`);
  }

  return (
    <div>
      <ListHeader
        title="Templates"
        onCreate={{
          text: "Create new template",
          clicked: onCreateTemplate
        }}
        buttons={[
          /*
          {
            clicked: () => {},
            text: "Edit master templates",
            color: "link"
          },*/
          {
            clicked: () => {
              history.push("/blocks");
            },
            text: "Multi Item Elements",
            color: "light"
          }
        ]}
      />

      <Content>
        {loading ? (
          <ListSpinner />
        ) : (
          <TemplatesList userTemplates={userTemplates} userGroups={userGroups} onEdit={onEdit} onView={onView} />
        )}
      </Content>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.userTemplates.loading,
    error: state.userTemplates.error,
    userTemplates: state.userTemplates.userTemplates,
    userGroups: state.userGroups.userGroups,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindUserTemplates: (params:string) => dispatch(actions.findUserTemplates(params)),
    onFindUserGroups: organizationId => dispatch(actions.findUserGroups(organizationId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTemplates);
