import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../../withRouter';

import { useParams } from "react-router-dom";

import FooterFixed from "../../../../../components/UI/FooterFixed/FooterFixed";
import FormContainer from "../../../../../components/UI/FormContainer/FormContainer";
import FormFooter from "../../../../../components/UI/FormFooter/FormFooter";
import { IAppState, ICustomer } from "../../../../../interfaces";
import { controlsToFormGroups, getFormData, initForm, defaultInputChangedHandler } from "../../../../../shared/utility";
import * as actions from "../../../../../store/actions";
import { getCustomerControls } from "../../../../../shared/controls";

interface IStateProps {
  error: string | null;
  loading: boolean;
  success: boolean;
}

interface IDispatchProps {
  onGetCustomer: (id: string) => Promise<ICustomer>;
  onUpdateCustomer: (customer: ICustomer) => void;
  onSaveCustomer: (customer: ICustomer) => void;
  //onDeleteCustomer: (customer_id: string) => void;
}

interface IMatchProps {
  id: string;
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {} //<IMatchProps> {}

const Customer: React.FC<IProps> = ({
  onGetCustomer,
  match,
  loading,
  onSaveCustomer,
  onUpdateCustomer,
  //onDeleteCustomer,
  history,
  error
}) => {

  const params=useParams();
  const id:string = params.id;

  const [state, setState] = useState({
    controls: getCustomerControls(),
    formIsValid: false
  });

  useEffect(() => {
    //const { id } = match.params;
    if (id && id !== "add") {
      (async () => {
        const customer = await onGetCustomer(id);
        setState(initForm(state.controls, customer));
      })();
    }
    // eslint-disable-next-line
  }, [id]); //match.params]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      state,
      setState
    );
  };

  const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    //const params=useParams();
    //const id:string = params.id;
    //const { id } = match.params;


    const formData = getFormData(state.controls);

    if (id && id !== "add") {
      formData.id = id;
      await onUpdateCustomer(formData);
    } else {
      await onSaveCustomer(formData);
    }

    history.goBack();
  };
/*
  const deleteCustomer = async () => {
    const { id } = match.params;

    await onDeleteCustomer(id);
    history.goBack();

  }
*/
  return (
    <React.Fragment>
      <FormContainer title="Add customer" loading={loading} error={error}>
          {controlsToFormGroups(state.controls, inputChangedHandler)}
      </FormContainer>
      <FooterFixed>
        <FormFooter
          saveText="Save customer"
          onSave={submitHandler}
          onDiscard={() => history.goBack()}
          isValid={state.formIsValid}
        />
        {/*          onDelete={deleteCustomer}*/}

      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.customers.loading,
    success: state.customers.success,
    error: state.customers.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetCustomer: id => dispatch(actions.getCustomer(id)),
    onUpdateCustomer: customer => dispatch(actions.updateCustomer(customer)),
    onSaveCustomer: customer => dispatch(actions.saveCustomer(customer)),
    //onDeleteCustomer: customer_id => dispatch(actions.deleteCustomer(customer_id)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
