import React, { ReactNode } from "react";

import classes from "./Typography.module.scss";

interface IProps {
  children: ReactNode;
  className?: string;
}

const Title: React.FC<IProps> = ({ children, className }) => {
  const classNames = [classes.Title];
  if (className) {
    classNames.push(className);
  }

  return <h5 className={classNames.join(" ")}>{children}</h5>;
};

export default Title;
