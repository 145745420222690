import React, { useEffect } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../withRouter';

import { Alert } from "reactstrap";
import ListContainer from "../../../../components/ListContainer/ListContainer";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import { IAppState, IBlock } from "../../../../interfaces";
import * as actions from "../../../../store/actions";
import BlocksList from "../../components/Blocks/BlocksList/BlocksList";

interface IStateProps {
  loading: boolean;
  error: string;
  blocks: Array<IBlock>;
}

interface IDispatchProps {
  onFindBlocks: () => Promise<IBlock>;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {}

const Blocks: React.FC<IProps> = ({
  history,
  loading,
  error,
  blocks,
  onFindBlocks
}) => {
  useEffect(() => {
    onFindBlocks();
  }, [onFindBlocks]);

  const onCreate = () => {
    history.push("/blocks/add");
  };

  return (
    <ListContainer>
      <ListHeader
        title="Multi Item Elements"
        onCreate={{
          text: "New multi item element",
          clicked: onCreate
        }}
      />

      {loading ? (
        <ListSpinner style={{ marginTop: "1rem" }} />
      ) : error ? (
        <Alert color="danger">{error}</Alert>
      ) : (
        <BlocksList
          blocks={blocks}
          onEdit={id => history.push(`/blocks/${id}`)}
          onSeeItems={id => history.push(`/blocks/${id}/items`)}
        />
      )}
    </ListContainer>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.blocks.loading,
    error: state.blocks.error,
    blocks: state.blocks.blocks
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindBlocks: () => dispatch(actions.findBlocks())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Blocks);
