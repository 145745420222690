import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from "../../withRouter";
import ListSpinner from "../../components/UI/ListSpinner/ListSpinner";
import { IAppState, IRegisterForm } from "../../interfaces";
import { updateObject, getCookie } from "../../shared/utility";
import * as actions from "../../store/actions";
import Step1 from "./Forms/Step1";
import Step2 from "./Forms/Step2";
import { EOrganizationLevel } from "../../enums";
import EUserRole from "../../enums/user-roles";
import ScriptLoader from "../../shared/ScriptLoader";
import { FIRSTPROMOTER_ID, FIRSTPROMOTER_COOKIE_NAME, FIRSTPROMOTER_DOMAIN_NAME } from "../..";

import classes from "./Register.module.scss";

interface IStateProps {
  registered?: boolean;
  error?: string | null;
  isAuthenticated: boolean;
  loading?: boolean;
}

interface IDispatchProps {
  registerUser: (formData: {}) => {};
  onAuth: (account: string, password: string) => {};
  onClearError: () => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps { }

const Register = (props: IProps) => {
  const { history, registered, isAuthenticated } = props;
  const [state, setState] = useState({
    formData: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
      phone: "",
      name: "",
      companySize: "",
      industry: "",
      crm: "",
      teamSize: "",
    },
    currentStep: 1
  });

  useEffect(() => {
    document.body.classList.add("login-bg");
    return () => {
      document.body.classList.remove("login-bg");
    };
  }, []);

  const updateFormData = (inputs: IRegisterForm) => {
    const { formData, currentStep } = state;
    const updated = updateObject(formData, inputs);
    setState({ formData: updated, currentStep: currentStep === 1 && 2 })
    if (state.currentStep === 2) onSubmit(updated);
  }

  useEffect(() => {
    if (registered) {
      login();
    }
  })

  useEffect(() => {
    if (isAuthenticated) {
        history.push("/dashboard");
    }
  }, [isAuthenticated, history]);

  const login = async () => {
    const { formData: { email, password } } = state;
    const { onAuth } = props;
    await onAuth(email, password);
  }

  const onSubmit = (formData: IRegisterForm) => {
    const { registerUser } = props;
    const fpCookie = getCookie(FIRSTPROMOTER_COOKIE_NAME);

    const {
      email,
      firstName,
      lastName,
      password,
      phone,
      name,
      crm,
      teamSize
    } = formData
    registerUser({
      email,
      firstName,
      lastName,
      password,
      phoneNumber: phone,
      allowLogin: true,
			role: EUserRole.ENTREPRENEUR,
      name: name,
      contactPerson: `${firstName} ${lastName}`,
      contactEmail: email,
      contactPhone: phone,
      level: EOrganizationLevel.BASIC,
      crm,
      teamSize,
      fpCookie
    })
  }

  const { loading } = props;
  const { currentStep } = state;

  const initFirstPromoter = () => {
    const global = window as any;
    global.$FPROM.init(FIRSTPROMOTER_ID, FIRSTPROMOTER_DOMAIN_NAME);
    // console.log(global.$FPROM)
  }

  return (
    <div className={classes.Container}>
      <ScriptLoader uniqueId="firstpromoter_instance" script="https://cdn.firstpromoter.com/fprom.js" onLoad={initFirstPromoter} />
      <div className={classes.LoginBox}>
        <div className={classes.Title}>{
          currentStep === 1 ? 'Register to Click2Contract' : "You're almost done"}
        </div>
        {(loading && <ListSpinner />) || (
          currentStep === 1 ?
            <Step1 onSubmit={updateFormData} />
            :
            <Step2 onSubmit={updateFormData} />
        )}
      </div>
      {/* <div className={classes.LinksContainer}>
        <div>
          <p> By signing up I agree to the
            <a className={classes.terms} rel="noopener noreferrer" target="_blank" href="https://www.click2contract.com/en/terms/"> Terms of Use </a> 
            and acknowledge <a className={classes.terms} rel="noopener noreferrer" target="_blank" href="https://www.click2contract.com/en/privacy-policy/"> Privacy Policy</a>
          </p>
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.users.loading,
    isAuthenticated: state.auth.currentUser !== null,
    registered: state.users.registered,
    error: state.users.error,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    registerUser: (formData) => dispatch(actions.registerUser(formData)),
    onAuth: (email: string, password: string) =>
      dispatch(actions.auth(email, password)),
    onClearError: () => dispatch(actions.clearError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
