import React from 'react';
import { IOfferComment, IUser } from './../../../../../../interfaces/domain';


const OfferCommentsContext = React.createContext<{
  comments: Array<IOfferComment>;
  loading: boolean;
  error: string;
  defaultSenderName: string;
  currentUser: IUser;
  onCommentOffer: (comment:IOfferComment) => void;
  hideControls: boolean;
}>({  
  comments: null,
  loading: null,
  error: null,
  defaultSenderName: null,
  currentUser: null,
  onCommentOffer: null,
  hideControls: null
});

export default OfferCommentsContext;
