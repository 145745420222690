import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { EContentType, EDropzoneType } from '../../enums';
import { IAppState, ICustomer, IUser } from '../../interfaces';
import { controlsToFormGroups } from '../../shared/utility';
import * as actions from '../../store/actions';
import ListSpinner from '../UI/ListSpinner/ListSpinner';

import classes from "./ReadCSVModal.module.scss";

interface IStateProps {
  currentUser: IUser | null;
  customersImport: Array<ICustomer>;
  error: string;
  loading: boolean;
}

interface IDispatchProps {
  onUploadCustomers: (data: {}) => {};
  onImportCustomers: (data: {}) => {};
  onClearCustomersImport: () => void;
}

interface IProps extends IStateProps, IDispatchProps {
  showModal: boolean;
  toggleModal: () => void;
	toggleUpdateCustomers?: () => void;
	handleFile: (csv_content:string) => void;
	saveFile?: () => void;
	data: any;
	csverror: string;
}

const ImportCustomersModal: React.FC<IProps> = ({
  currentUser,
  showModal,
  toggleModal,
  onImportCustomers,
  onClearCustomersImport,
  customersImport,
  toggleUpdateCustomers,
  error,
  loading,
	onUploadCustomers,
	handleFile,
	saveFile,
	data,
	csverror,
}) => {
  const [infoText, setInfoText] = useState(null);

  useEffect(() => {
    if (customersImport && !error) {
      setInfoText(<p style={{color: "green"}}>Import file ok. Add {customersImport.length} new customers by clicking Save</p>);
    } else if (error) {
      setInfoText(<p style={{color: "red"}}>Import file reading failed. Please check the file</p>);
    }
  }, [customersImport, error]);

  const getControls = (): any => {
    return {
      image: {
        elementType: "dropzone",
        elementConfig: {
          accept: EDropzoneType.CSV,
          text: "Drop csv here or click to upload.",
          dragText: "Drop csv here.",
          contentType: EContentType.CSV,
          config: {},
          onDrop: onDropHandler
        },
        valid: true,
        touched: false,
        value: ""
      }
    }
  };

  const onDropHandler = (files: Array<File>) => {
		//const formData = new FormData();
		//formData.append("csv", files[0]);

		const reader=new FileReader()
		reader.onload = (e:Event) => {
			handleFile(reader.result as string)
		}
		reader.readAsText(files[0])
  }

	const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
	   event.preventDefault();
		saveFile();
		/*
    event.preventDefault();
    const data = { customers: [...customersImport] };
    const organizationId = currentUser.organizationId;

    if (organizationId) {
      onImportCustomers(data);
      toggleUpdateCustomers();
      onClearCustomersImport();
      setInfoText(null);
    }
		*/
    toggleModal();
  };

  const serveCustomersImportTemplate = () => {
		//    window.open(getApiPath() + "json/customers/csv/template", "_blank");
		const rows=[
			['"Name"','"Product code"','"Price"','"Unit"','"Tax"','"Add"'],
			['"Paper"','"1234"','"5"','"pcs"','"24"','"0"'],

		];
		//const csvContent="data:text/csv;charset=utf-8,"+rows.map((ob)=>ob.join(",")).join("\n")

		//const encodedUri = encodeURI(csvContent);
		//window.open(encodedUri);

		let link = document.createElement("a");
		if (link.download !== undefined) { // feature detection
			 var blob = new Blob([rows.map((ob)=>ob.join(",")).join("\n")], { type: 'text/csv;charset=utf-8;' });
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "product-template.csv");
			//link.style = "visibility:hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
		}

  }

  return (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Import products</ModalHeader>
      <ModalBody>
        {loading ? <ListSpinner />
          : (
            <div>
              {controlsToFormGroups(getControls(), null)}
							{infoText}
							{(data.length>0 || csverror) && <h5>
								{data.length} products loaded
								<div className={classes.error} >{csverror}</div>
							</h5>}
            </div>
          )}
      </ModalBody>
      <ModalFooter className={classes.Footer}>
        <div>
          <Button type="button" color="secondary" onClick={serveCustomersImportTemplate}>
            Template
                    </Button>
        </div>
        <div>
          <Button type="button" color="link" className={classes.DiscardButton} onClick={toggleModal}>
            Discard
                    </Button>
          {' '}
          <Button type="submit" color="info" className={classes.SaveButton} onClick={submitHandler}>
            Save
                    </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}


const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    customersImport: state.importCustomers.customersImport,
    error: state.importCustomers.error,
    loading: state.importCustomers.loading
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onUploadCustomers: data => dispatch(actions.uploadCustomers(data)),
    onImportCustomers: (data) => dispatch(actions.importCustomers(data)),
    onClearCustomersImport: () => dispatch(actions.clearCustomersImport())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportCustomersModal);
