import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

import { IOrganizationState, IOrganizationAction } from "../../interfaces";

const initialState: IOrganizationState = {
  organizations: null,
  loading: false,
  error: null,
  organization: null,
  success: false,
  search: null,
  pipedriveConnection: null,
  pipedriveConnectionError: null,
  pipedriveConnectionLoading: false,
  disconnectPipedriveError: null,
  disconnectPipedriveLoading: false
};

const findOrganizationsStart = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, { error: null, loading: true });
};

const findOrganizationsSuccess = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    organizations: action.organizations,
    error: null,
    loading: false,
    organization: null
  });
};

const findOrganizationsFail = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const searchOrganizationsFail = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const searchOrganizationsStart = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, { error: null, loading: true });
};

const searchOrganizationsSuccess = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    search: action.search,
    error: null,
    loading: false
  });
};

const getOrganizationStart = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, { error: null, loading: true });
};

const getOrganizationSuccess = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    organization: action.organization,
    error: null,
    loading: false
  });
};

const getOrganizationFail = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const saveOrganizationStart = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, { error: null, loading: true });
};

const saveOrganizationSuccess = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    error: null,
    success: true,
    loading: false
  });
};

const saveOrganizationFail = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const updateOrganizationStart = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, { error: null, loading: true });
};

const updateOrganizationSuccess = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    error: null,
    success: true,
    loading: false
  });
};

const updateOrganizationFail = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const testPipedriveStart = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    pipedriveConnectionError: null,
    pipedriveConnectionLoading: true
  });
};

const testPipedriveSuccess = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    pipedriveConnection: action.pipedriveConnection,
    pipedriveConnectionLoading: false
  });
};

const testPipedriveFail = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    pipedriveConnectionError: action.error,
    pipedriveConnectionLoading: false
  });
};

const disconnectPipedriveStart = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    disconnectPipedriveError: null,
    disconnectPipedriveLoading: true
  });
};

const disconnectPipedriveSuccess = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    disconnectPipedriveLoading: false,
    organization: action.organization
  });
};

const disconnectPipedriveFail = (
  state: IOrganizationState,
  action: IOrganizationAction
): IOrganizationState => {
  return updateObject(state, {
    disconnectPipedriveError: action.error,
    disconnectPipedriveLoading: false
  });
};

const reducer = (
  state: IOrganizationState = initialState,
  action: IOrganizationAction
): IOrganizationState => {
  switch (action.type) {
    case ActionTypes.FIND_ORGANIZATIONS_START:
      return findOrganizationsStart(state, action);
    case ActionTypes.FIND_ORGANIZATIONS_SUCCESS:
      return findOrganizationsSuccess(state, action);
    case ActionTypes.FIND_ORGANIZATIONS_FAIL:
      return findOrganizationsFail(state, action);
    case ActionTypes.SEARCH_ORGANIZATIONS_START:
      return searchOrganizationsStart(state, action);
    case ActionTypes.SEARCH_ORGANIZATIONS_SUCCESS:
      return searchOrganizationsSuccess(state, action);
    case ActionTypes.SEARCH_ORGANIZATIONS_FAIL:
      return searchOrganizationsFail(state, action);
    case ActionTypes.GET_ORGANIZATION_START:
      return getOrganizationStart(state, action);
    case ActionTypes.GET_ORGANIZATION_SUCCESS:
      return getOrganizationSuccess(state, action);
    case ActionTypes.GET_ORGANIZATION_FAIL:
      return getOrganizationFail(state, action);
    case ActionTypes.SAVE_ORGANIZATION_START:
      return saveOrganizationStart(state, action);
    case ActionTypes.SAVE_ORGANIZATION_SUCCESS:
      return saveOrganizationSuccess(state, action);
    case ActionTypes.SAVE_ORGANIZATION_FAIL:
      return saveOrganizationFail(state, action);
    case ActionTypes.UPDATE_ORGANIZATION_START:
      return updateOrganizationStart(state, action);
    case ActionTypes.UPDATE_ORGANIZATION_SUCCESS:
      return updateOrganizationSuccess(state, action);
    case ActionTypes.UPDATE_ORGANIZATION_FAIL:
      return updateOrganizationFail(state, action);
    case ActionTypes.GET_USER_ORGANIZATION_START:
      return getOrganizationStart(state, action);
    case ActionTypes.GET_USER_ORGANIZATION_SUCCESS:
      return getOrganizationSuccess(state, action);
    case ActionTypes.GET_USER_ORGANIZATION_FAIL:
      return getOrganizationFail(state, action);
    case ActionTypes.TEST_PIPEDRIVE_ORGANIZATION_START:
      return testPipedriveStart(state, action);
    case ActionTypes.TEST_PIPEDRIVE_ORGANIZATION_SUCCESS:
      return testPipedriveSuccess(state, action);
    case ActionTypes.TEST_PIPEDRIVE_ORGANIZATION_FAIL:
      return testPipedriveFail(state, action);
    case ActionTypes.DISCONNECT_PIPEDRIVE_ORGANIZATION_START:
      return disconnectPipedriveStart(state, action);
    case ActionTypes.DISCONNECT_PIPEDRIVE_ORGANIZATION_SUCCESS:
      return disconnectPipedriveSuccess(state, action);
    case ActionTypes.DISCONNECT_PIPEDRIVE_ORGANIZATION_FAIL:
      return disconnectPipedriveFail(state, action);
    default:
      return state;
  }
};

export default reducer;
