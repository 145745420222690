import React, { useEffect, useState, useRef } from "react";
import WebFont from "webfontloader";

import { EUserTemplateBlockType, EOfferStatus } from "../../../../../../enums";

import { IUserTemplateBlockDraggable } from "../../../../../../interfaces";

import {
  ELayoutType,
  IBlockContent,
  IOffer,
  IStyles,
  IUserTemplateArea,
  IUserTemplateAreaBlock,
  IUser,
  IOrganization,
} from "../../../../../../interfaces";
import {
  getBlockStyles,
  getBlockContentStyles
} from "../../../../../../shared/styles";
import { addElementStyles } from "../../../../../../shared/utility";
import OfferPreviewContext from "../../../../containers/Offers/Offer/OfferPreviewContext";

import CustomDialog from '../../../../../../components/UI/CustomDialog/CustomDialog';

import BlockContentDialog from "../../../Blocks/Block/BlockContentDialog/BlockContentDialog";
import IconButton from "../../../../../../components/UI/IconButton/IconButton";
import { faPen, faTrash, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

//import ContentTypeDialog from "../../../Blocks/Block/ContentTypeDialog/ContentTypeDialog";

import RenderContent from './components/RenderContent';

import AddBlock from './components/AddBlock';

import HoverInput from './HoverInput';

import classes from "./OfferCombined.module.scss";

interface IProps {
  offer: IOffer;
  isVisible: boolean;
  showDummyContent?: boolean;
  token?: string;
  printable?: boolean;
  currentUser?: IUser;
	activeBlock: IUserTemplateBlockDraggable;
	setActiveBlock: (item: IUserTemplateBlockDraggable) => void;
  deleteBlock: (block: IUserTemplateBlockDraggable) => void;
  updateBlock: (block: IUserTemplateBlockDraggable) => void;
	createBlock: () => IUserTemplateBlockDraggable;

	swapBlocks: (block1: IUserTemplateBlockDraggable,block2?: IUserTemplateBlockDraggable) => void;
  addableItems: Array<IUserTemplateBlockDraggable>;
	addBlock: (block1: IUserTemplateBlockDraggable,block2?: IUserTemplateBlockDraggable) => void;

	organization?: IOrganization;

}

const OfferCombined: React.FC<IProps> = ({
  offer,
  isVisible,
  showDummyContent,
  token,
  printable,
  currentUser,
	activeBlock,
	setActiveBlock,
	deleteBlock,
	updateBlock,
	createBlock,

	swapBlocks,
	addBlock,
	addableItems,

	organization,

}) => {
  const [loadedFonts, setLoadedFonts] = useState([]);

//	const [borderClasses, setBorderClasses] = useState<any>({});

  const [blockContentIsOpen, setBlockContentIsOpen] = useState(false);
  const [blockContent, setBlockContent] = useState<IBlockContent>(null);
  const [areaBlock, setAreaBlock] = useState<IUserTemplateAreaBlock>(null);
 
	//	const [contentTypeIsOpen, setContentTypeIsOpen] = useState(null);
	//
	//
	const defaultConfirmDialog = {
	  isOpen: false,
  	onOk: () => {},
	  title: "",
  	content: ""
	};
	
	const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
	const confirmDeleteElement = (block: IUserTemplateBlockDraggable) => 
		setConfirmDialog({
			onOk: async () => {
				deleteBlock(block)
				setConfirmDialog(defaultConfirmDialog);
      },
      isOpen: true,
      title: "Are you sure?",
      content: "Delete element?"
    });



	const elementRefs = useRef<(HTMLDivElement | null)[]>([])
	const contentRef=useRef<HTMLDivElement>(null)

  const loadFont = (font: string, newLoadedFonts: Array<string>) => {
    if (font && newLoadedFonts.indexOf(font) === -1) {
      WebFont.load({
        google: {
          families: [`${font}:400,700`]
        }
      });
      newLoadedFonts.push(font);
    }
  };

  useEffect(() => {
    if (offer.userTemplate) {
      const { styles } = offer.userTemplate;
      if (styles) {
        const newLoadedFonts = [...loadedFonts];
        loadFont(styles.titleFont, newLoadedFonts);
        loadFont(styles.paragraphFont, newLoadedFonts);
        setLoadedFonts(newLoadedFonts);

        addElementStyles(
          "offer-preview",
          styles.titleFont,
          styles.paragraphFont,
          styles.titleColor,
          styles.paragraphColor,
          styles.linkColor,
          offer.userTemplate.customColor
        );
      }
    }
    // eslint-disable-next-line
  }, [offer.userTemplate]);

  if (!offer) return null;

  const { userTemplate } = offer;

  if (!userTemplate) return null;

	const allAreaBlocks=[
			...userTemplate.header.blocks,
			...userTemplate.main.blocks,
			...userTemplate.footer.blocks,
	];

  const renderBlockContent = (block: IUserTemplateAreaBlock, index: number) => {
    switch (block.blockType) {
      case EUserTemplateBlockType.DYNAMIC:			
        if (block.offerBlockItems) {
          return block.offerBlockItems.map(blockItem => {
            return <RenderContent
							key={blockItem.id}

							index={index}
							block={block}

              content={blockItem.content}
              styles={getBlockContentStyles(block.styles)}
							setBlockContent={setBlockContent}
							setAreaBlock={setAreaBlock}
							setBlockContentIsOpen={setBlockContentIsOpen}

							activeBlock={activeBlock}					
							setActiveBlock={setActiveBlock}
						/>
          });
				}
				else {
            return <RenderContent
							index={index}
							block={block}

              content={block.content}
              styles={getBlockContentStyles(block.styles)}
							setBlockContent={setBlockContent}
							setAreaBlock={setAreaBlock}
							setBlockContentIsOpen={setBlockContentIsOpen}

							activeBlock={activeBlock}					
							setActiveBlock={setActiveBlock}
						/>

				}
      case EUserTemplateBlockType.SIMPLE:
        return (<RenderContent

					index={index}
					block={block}


          content={block.content}
          styles={getBlockContentStyles(block.styles)}
					setBlockContent={setBlockContent}
					setAreaBlock={setAreaBlock}
					setBlockContentIsOpen={setBlockContentIsOpen}

					activeBlock={activeBlock}					
					setActiveBlock={setActiveBlock}

				/>)
      default:
        return null;
    }
  };

  const addStylesGetClassName = (id: string, styles: IStyles) => {
    let className = "";
    if (styles) {
      className = styles.cssClassName;
      // FIXME: breaks font styles on user template
      addElementStyles(
        id,
        null,
        null,
        // styles.titleFont,
        // styles.paragraphFont,
        styles.titleColor,
        styles.paragraphColor,
        styles.linkColor,
        null
      );
    }
    return className;
  };

  const getSentDate = () => {
    if(!offer || !offer.history) return null;
    const sentHistory = offer.history.find(h => h.offerStatus === EOfferStatus.SENT);
    if(!sentHistory) return null;
    return sentHistory.createdISO;
  }

/*
	const clearBorders = (e:any,ndx:number) => {
		setBorderClasses({...borderClasses,[ndx]:[false,false,false,false]})
	}

	const findBorders = (e:any,ndx:number) => {
		let rect = elementRefs.current[ndx].getBoundingClientRect();
    let x = e.clientX - rect.left,      // the relative mouse postion to the element
        y = e.clientY - rect.top,       // ...
        w = rect.right - rect.left,     // width of the element
        h = rect.bottom - rect.top;     // height of the element

		if(borderClasses[ndx]!==y<17) {
			setBorderClasses({...borderClasses,[ndx]:[y<15,y>h-15,x<15,x>w-15]})
		}
	}
 */

  const renderBlocks = (blocks: Array<IUserTemplateBlockDraggable>, bigIndex: number) => {
    if (!blocks) return null;

    return blocks.map((block, index) => {
      const id = `block-${block.id}`.replace(/=/g,"");
      let className = addStylesGetClassName(id, block.styles);

//			const bclasses=[]
//			const bc=borderClasses[bigIndex+index]
//			if(bc && bc[0]) {
//					bclasses.push("hitop")
//			}

			
			const prevBlock=bigIndex+index>0 ? allAreaBlocks[bigIndex+index-1] as IUserTemplateBlockDraggable : null;
			const nextBlock=bigIndex+index<allAreaBlocks.length-1 ? allAreaBlocks[bigIndex+index+1] as IUserTemplateBlockDraggable : null;
		
			// Render single Element
      return (
        <div
					ref={(ref)=>elementRefs.current[bigIndex+index]=ref}
          key={block.id}
          style={{ ...getBlockStyles(block.styles)}}
          className={[className,classes.elementBorder,(activeBlock && activeBlock.id===block.id)?classes.activeBlock:""].join(" ")}

          id={id}
					onClick={()=>setActiveBlock(block)}
				>
					<div className={classes.editContentBtnHeader}>
					<div className={classes.editContentBtnHeaderLeft}>
					<div className={classes.editContentBtnLeft}>
						{prevBlock &&
						<IconButton	
	    			  color="dark"
          		icon={faArrowUp}
							onClick={(e:any)=>{
								e.stopPropagation();
								swapBlocks(block,prevBlock);
							}}
						/>}{' '}

						{nextBlock && <IconButton				
	    			  color="dark"
          		icon={faArrowDown}
							onClick={(e:any)=>{
								e.stopPropagation();
								swapBlocks(block,nextBlock);
							}}
						/>}{' '}
					</div>
					</div>

					<div className={classes.editContentBtnHeaderRight}>
					<div className={classes.editContentBtn}>
						<IconButton				
	    			  color="dark"
          		icon={faTrash}
							onClick={(e:any)=>{
								e.stopPropagation();
								confirmDeleteElement(block as IUserTemplateBlockDraggable);
								//deleteBlock(block as IUserTemplateBlockDraggable)
							}}
						/>{' '}

						<AddBlock
							block={block as IUserTemplateBlockDraggable}
							blocks={addableItems}
							addBlock={addBlock}
							createBlock={createBlock}
							activeBlock={activeBlock}					
						/>{' '}
				
						<IconButton				
	    			  color="dark"
          		icon={faPen}
							onClick={(e:any)=>{
								e.stopPropagation();
								setActiveBlock(block as IUserTemplateBlockDraggable)
							}}
						/>
				
					</div>
					</div>
					</div>
					
          {block.showName &&
						<div className={classes.hoverswitch} >
	            <h3 className={classes.BlockName}>
								<HoverInput
									value={block.name}
									onChange={(e:any)=>{
										block.name=e;
										updateBlock(block as IUserTemplateBlockDraggable);
									}}
								/>
							</h3>
						</div>
          }

          <div
            className={[
							//(activeBlock && activeBlock.id===block.id)?classes.activeBlock:"",
              block.layoutType === ELayoutType.HORIZONTAL
                ? classes.Horizontal
                : null].join(" ")
            }
          >
          {/*
            <OfferPreviewContext.Provider
              value={{
                customer: offer.customer,
                organization: organization, //offer.organization,
                hash: offer.hash,
                confirmDate: offer.confirmDate,
                status: offer.status,
                id: offer.id,
                token,
                hideAuditLogs: offer.hideAuditLogs,
                printable,
                sentDate: getSentDate(),
                customerSignature: offer.customerSignature,
                author: offer.author,
                currentUser
              }}
            >
              {renderBlockContent(block,index+bigIndex)}
            </OfferPreviewContext.Provider>
            */}
						{renderBlockContent(block,index+bigIndex)}


          </div>
        </div>
      );
    });
  };

  const renderArea = (area: IUserTemplateArea, id: string, bigIndex: number) => {
    if (!area) return null;

    let className = addStylesGetClassName(id, area.styles);
    return (
      <div
				key={id}
        id={id}
        className={className}
				style={{ overflow: "auto", ...getBlockStyles(area.styles) }}
			>

        {renderBlocks(area.blocks as Array<IUserTemplateBlockDraggable>,bigIndex)}
      </div>
    );
  };

	const hCount=userTemplate.header.blocks.length
	const mCount=userTemplate.main.blocks.length
	const fCount=userTemplate.footer.blocks.length

	const onSaveEdit = (content: IBlockContent) => {
		//console.log(content);
		//console.log(activeBlock);
		const newContent=(Object.values(activeBlock.content).reduce((resp,ob)=>{
			resp.push(ob.id===content.id?content:ob);
			return resp;
		},[]));

		updateBlock({...activeBlock,content:newContent} as IUserTemplateBlockDraggable);

//    const newItems = [...items];
//    const index = newItems.findIndex(item => item.id === content.id);
//    newItems[index] = { ...newItems[index], ...content };
//    setItems(newItems);
//		console.log(newItems)
  };

	const onDelete = (id: string) => {
		//console.log("delete"+id);

		const newContent=(Object.values(activeBlock.content).reduce((resp,ob)=>{
			if(ob.id!==id) { resp.push(ob) }
			return resp;
		},[]));

		updateBlock({...activeBlock,content:newContent} as IUserTemplateBlockDraggable);

	}

  return (
    <div
      className={classes.Container}
      style={{
        display: isVisible ? "block" : "none",
        ...getBlockStyles(userTemplate.styles)
      }}
      id="offer-preview"
    >
    	<OfferPreviewContext.Provider
              value={{
                customer: offer.customer,
                organization: organization, //offer.organization,
                hash: offer.hash,
                confirmDate: offer.confirmDate,
                status: offer.status,
                id: offer.id,
                token,
                hideAuditLogs: offer.hideAuditLogs,
                printable,
                sentDate: getSentDate(),
                customerSignature: offer.customerSignature,
                author: offer.author,
                currentUser
              }}
      >

		   <BlockContentDialog
	        isOpen={blockContentIsOpen}
	        setIsOpen={setBlockContentIsOpen}
	        onSave={onSaveEdit}
					onDelete={onDelete}
	        blockContent={blockContent}
					areaBlock={areaBlock}
	      />

				{/*contentTypeIsOpen && <ContentTypeDialog
	        isOpen={contentTypeIsOpen!==null}
	        setIsOpen={setContentTypeIsOpen}
	        onSave={(x)=>console.log("onAddContentType",x)}
	        onDelete={()=>console.log("onDeleteContentType")}
	        blockContent={contentTypeIsOpen}
	        blockType={EUserTemplateBlockType.SIMPLE}
	      />*/}

	      <div className={classes.Content}
					ref={contentRef}
				>
	    		{renderArea(userTemplate.header, "offer-preview-header",0)}
		    	{renderArea(userTemplate.main, "offer-preview-main",hCount)}
					{renderArea(userTemplate.footer, "offer-preview-footer",hCount+mCount)}
				</div>

	      <CustomDialog
	        loading={false}
	        onOk={confirmDialog.onOk}
	        okButtonText={"DELETE"}
	        onCancel={() => setConfirmDialog(defaultConfirmDialog)}
	        open={confirmDialog.isOpen}
	        title={confirmDialog.title}
	        isValid={true}
	      >
	        {confirmDialog.content}
				</CustomDialog>

			</OfferPreviewContext.Provider>


    </div>
  );
};

export default OfferCombined;
