import React, { useEffect, useState } from "react";
//import ContentTypeIcon from "../../../../../../../../components/UI/ContentTypeIcon/ContentTypeIcon";
import SidebarCollapse from "../../../../../../../../components/UI/SidebarCollapse/SidebarCollapse";
import { EUserTemplateBlockType } from "../../../../../../../../enums";
import { IBlockContent, IUserTemplateBlockDraggable } from "../../../../../../../../interfaces";
import { parseStyles } from "../../../../../../../../shared/styles";
import ContentArea from "../../../../../Blocks/Block/ContentArea/ContentArea";
import TemplateSidebarEditLayout from "../TemplateSidebarEditLayout/TemplateSidebarEditLayout";
import TemplateSidebarEditTheme from "../TemplateSidebarEditTheme/TemplateSidebarEditTheme";
import TemplateSidebarEditBlockDetails from "./TemplateSidebarEditBlockDetails/TemplateSidebarEditBlockDetails";

//const classes = require('./TemplateSidebarEditBlock.module.scss');

interface IProps {
  block: IUserTemplateBlockDraggable;
  updateBlock: (block: IUserTemplateBlockDraggable) => void;
  deleteBlock: (block: IUserTemplateBlockDraggable) => void;
}

const TemplateSidebarEditBlock: React.FC<IProps> = ({ block, updateBlock, deleteBlock }) => {

  const [contentItems, setContentItems] = useState<Array<IBlockContent>>([]);

  useEffect(() => {
    const newBlockObject = {
      ...block,
      content: contentItems
    }
    updateBlock(newBlockObject);
    //eslint-disable-next-line
  }, [contentItems, setContentItems]);

  useEffect(() => {
    setContentItems(block.content || []);
    // eslint-disable-next-line
  }, [block.id]);

  const createBlockObject = (block: any, data: any) => {
    const newBlockObject = {
      ...block,
      styles: {
        ...block.styles,
        ...parseStyles(data)
      }
    }

    const properties = ['name', 'showName', 'editableOnOffer', 'layoutType'];
    for (let property of properties) {
      if(data.hasOwnProperty(property)) {
        newBlockObject[property] = data[property];
      }
    }

    return newBlockObject;
  }

  
  const update = (data: Object) => {
    const newBlock = createBlockObject(block, data);
    updateBlock(newBlock)
  };

/*
  const deleteBlockHandler = () => {
    deleteBlock(block);
  }
*/

  return (
    <React.Fragment>
			{/*
      <div className={classes.Header}>
        <h5>Edit element</h5>
        <ContentTypeIcon contentType={EContentType.DELETE} onClick={deleteBlockHandler} />
      </div>
      <p className="text-muted">
        <small>{block.name}</small>
      </p>
			*/}
      <TemplateSidebarEditBlockDetails
        block={block}
        update={update}
      />
      {block.blockType === EUserTemplateBlockType.SIMPLE && (
        <SidebarCollapse title="Element content">
					{/*items={contentItems}*/}

          <ContentArea
                items={block.content}
                setItems={setContentItems}
                addText="Add new content type"
                edit
                blockType={EUserTemplateBlockType.SIMPLE}
              />
        </SidebarCollapse>
      )}
      <TemplateSidebarEditTheme id={block.uuid} data={block} title="Element" update={update} />
      <TemplateSidebarEditLayout id={block.uuid} data={block} title="Element" update={update} />
    </React.Fragment>
  );
};

export default TemplateSidebarEditBlock;
