import React, { useEffect, useState } from 'react';
import { IOrganization, IUser } from '../../../../../interfaces';

import { CustomInput, Input, Button, Row, Col } from 'reactstrap';

import classes from "../Integrations.module.scss";

interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  updateOrganization: (x:any)=>void;
}

interface IProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
  updateOrganization: (x:any)=>void;
  params: any;
}


const Netvisor: React.FC<IProps> = ({
  currentUser,
  organization,
  updateOrganization,

  params,
}) => {

  const [settings, setSettings]=useState<any>({active:false, autoSend:false, business_id:"",customer_id:"",private_key:""});

  const integrations=organization && organization.integrations;

  const updateNetvisor = (updated:any) => {
    setSettings({...settings,...updated});
  }

  const saveChanges = () => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        netvisor:{
          ...organization.integrations.netvisor,
          ...settings,
        }  
      }      
    }
    updateOrganization(newo);

  }


  useEffect(()=>{
    setSettings(integrations.netvisor);
  },[]);

  return (
    <React.Fragment>
      <div className={classes.integration}>

        <Row>
          <Col>
            <h5>Netvisor</h5>
            
            <CustomInput
                type="switch"
                label="Is Active"
                id="netvisorActiveId"
    	  		  	onChange={(e:any)=>{updateNetvisor({active:!settings.active})}}
    	  			  checked={settings.active}
              />

            <CustomInput
              type="switch"
              id="netvisorAutoSendId"
              label="Auto send to Netvisor when proposal accepted"
    			  	onChange={(e:any)=>updateNetvisor({autoSend:!settings.autoSend})}
    				  checked={settings.autoSend}
            />


            <Input value={settings.business_id} onChange={(e:any)=>updateNetvisor({business_id:e.target.value})} placeholder="Business ID: xxxxxxx-x"/>
            <Input value={settings.customer_id} onChange={(e:any)=>updateNetvisor({customer_id:e.target.value})} placeholder="Customer ID: ND_xxxxxx_xxxxx"/>
            <Input value={settings.private_key} onChange={(e:any)=>updateNetvisor({private_key:e.target.value})} placeholder="Private key: xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"/>
            {JSON.stringify(integrations.netvisor) !== JSON.stringify(settings) &&
            <Button onClick={saveChanges}>Save</Button>}

          </Col>
        </Row>
    
      </div>


    </React.Fragment>
  );
};

export default Netvisor;

