import React, { useState, useEffect } from "react";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../withRouter';
import { IOrganization, IAppState } from "../../../../interfaces";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import OrganizationsList from "../../components/Organizations/OrganizationsList";

import { EOfferListType } from '../../../../enums/offer-enums';

import axios from "../../../../axios";

interface IStateProps {
	organizations: Array<IOrganization> | null;
  error: string | null;
  loading: boolean;
  success: boolean;
}

interface IDispatchProps {
	onFindOrganizations: () => {};
	onFindOffers: (extra:string) => {};
}

interface IProps
  extends IStateProps,
  IDispatchProps,
  RouteComponentProps { }

const Organizations: React.FC<IProps> = props => {
  const { organizations, onFindOrganizations, history, loading } = props;

	const [offers, setOffers]=useState<any>({});

	const [stats, setStats] = useState<any>({});

	const [days, setDays] = useState<number>(1);
	const [loading2, setLoading2] = useState<boolean>(false);
	const [myTimer, setMyTimer] = useState<any>(null);
	const [time, setTime] = useState<number>(null);
/*
	useEffect(() => {
			onFindOrganizations();
  }, [onFindOrganizations]);
*/

	const [start, setStart] = useState<string>("start");

	const getData = async (params:string, prevdata:any) => {
		
		const OFFERS_PATH='json/offers2';
		let response:any = prevdata;

		const resp = await axios.get(OFFERS_PATH+"/history?"+params);
		if(resp.data) {
			response = resp.data.reduce((resp2:any, o:any)=> {
				const d:any = {org:o.organization,offers:[], authors:[]};

				if(o.organization.name in resp2) {
					d.offers = resp2[o.organization.name].offers;
					d.authors = resp2[o.organization.name].authors;

				}
				d.offers.push(o);
				const s:any = new Set([...d.authors,o.author.email]);
				d.authors = [...s];
				return {...resp2,[o.organization.name]:d};
			},response);						
		}
		return response;
	}


	useEffect(() => {
		(async () => {
			if(start === "start") {
				setTime(0);
				setMyTimer(setInterval(()=>{
					setTime(time => time+1);
				},1000));
			}
			setLoading2(true);
			setStart("loop");
			const d = await getData("start="+start+"&days="+days,stats);
			
			if(d!==stats) {
				setStats(d);
			}
			else {
				setLoading2(false);
				clearInterval(myTimer);
			}
		})();

	},[stats]);

	const doLoad = () =>{
		setStart("start");
		setStats({});
	}

  const addOrganization = () => {
    history.push("/admin/organizations/add");
  };

	// load offer information
	useEffect(() => {
		if(organizations) {
			/*
			const OFFERS_PATH='json/offers2';

			let tempOffers={};

			let today = new Date();
			let priorDate = new Date(new Date().setDate(today.getDate() - 30));
			let stats:{[id:string]:any} = {};

			const loopOrganizations=organizations.map((ob)=>ob.id);
	
			const xloop = (id: string) => {
				axios.get(OFFERS_PATH+`/list?listType=CONTRACTS&cuuid=${id}`)
					.then((resp)=>{
						tempOffers={...tempOffers,[id]:resp.data}
						resp.data.forEach((x:any)=>{
							if(!(x.status in stats)) {
								stats[x.status] = 0;
								stats[x.status+"-30"] = 0;
							} 

							const ts = x.created.split("-");
							const d = new Date(x.created); //ts[0],ts[1],ts[2]);
							//console.log(d,"---",priorDate);
							if(d>priorDate) {
								stats[x.status+"-30"]++;
							}

							stats[x.status]++;
							//console.log(x.status,x.created);
						});
						const next=loopOrganizations.pop();
						if(next) {
							setOffers(tempOffers)
							xloop(next)
						}
						else {
							setOffers(tempOffers);
						}
						console.log(stats);
					})
					.catch((resp)=> {})
			}
	
			xloop(loopOrganizations.pop());
			*/
		}
	},[organizations]);


  const numOffers = Object.values(stats).reduce((resp:number,s:any)=>{
      	return resp+s.offers.length;
      },0);

  const orderSort = (akey:any, bkey:any) => {
  	const a = stats[akey];
  	const b = stats[bkey];
  	return a.offers.length<b.offers.length?1:-1;
  }

  return (
    <div>
      <ListHeader
        title={"Statistics: "+numOffers+" offers in "+days+" day(s)"}
      />
      
      <div style={{"paddingLeft":116}}>
				{loading2?<div>loading...{time}s</div>
				:<div>
					<div>loaded in {time}s</div>
    		  Number of days to scan<input type="number" value={days} onChange={(e:any) => setDays(parseInt(e.target.value))}/>
      		<button onClick={doLoad}>scan</button>
      	</div>}
      	<br/>
	      <table>
  	    {Object.keys(stats).sort(orderSort).map((oid:string)=>{
    	  	//const org = stats[oid].org.name;
      		return <tr style={{"verticalAlign":"top","marginBottom":10}} key={oid}><td>{oid}</td><td>{stats[oid].offers.length}</td><td>{stats[oid].authors.map((a:string)=>(<div>{a}</div>))}</td></tr>
	      })}
  	    </table>
      </div>

    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
		organizations: state.organizations.organizations,
    loading: state.organizations.loading,
    success: state.organizations.success,
    error: state.organizations.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {

  return {
		onFindOrganizations: () => dispatch(actions.findOrganizations()),
    onFindOffers: (extra) => dispatch(actions.findOffers(null,EOfferListType.CONTRACTS,extra))

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organizations);
