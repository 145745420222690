import React from "react";
import { Badge, Button, Card, CardBody, CardTitle } from "reactstrap";
import { IUserTemplate, IUserGroup } from "../../../../interfaces";


import classes from "./TemplatesList.module.scss";

interface IProps {
  userTemplates: Array<IUserTemplate>;
  userGroups: Array<IUserGroup>;
  onEdit: (id:string) => void;
  onView: (id: string) => void;
  onCombined?: (id: string) => void;
}

const TemplatesList: React.FC<IProps> = ({
	userTemplates,
  userGroups,
	onEdit,
	onView,
	onCombined,
}) => {
  if (!userTemplates || !userTemplates.length) {
    return null;
  }

  const groupName = (key:string) => {
    const group = (userGroups || []).find((g:IUserGroup)=>g.id===key);
    return group && group.name || "?";
  }

  return (
    <div className={classes.Container}>
      {userTemplates.map(template => (
        <Card key={template.id} className={classes.Card}>
          {/*<CardImg
            src="https://via.placeholder.com/300x150"
            alt="Placeholder"
          />
          */}
          <CardBody className={classes.CardBody}>
            <CardTitle>
              <b>{template.name}</b><br/>
              {(template.userGroups || []).map((key:string)=>(<Badge key={key} style={{"marginRight":2}}>{groupName(key)}</Badge>))}
            </CardTitle>
            
            <div className={classes.Buttons}>
							{/*onCombined &&
              <Button color="info" size="sm" onClick={() => onCombined(template.id)}>
                Edit
              </Button>*/}

              <Button color="info" size="sm" onClick={() => onEdit(template.id)}>
                Edit
              </Button>
							
							{/*<Button color="light" size="sm" onClick={() => onView(template.id)}>
                View
              </Button>
							</>*/}
							


            </div>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

export default TemplatesList;
