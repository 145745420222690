export enum EContentType {
  CONTENT = 'CONTENT',
  IMAGE = 'IMAGE',
  VISUAL = 'VISUAL',
  CUSTOM = 'CUSTOM',
  PRICING = 'PRICING',
  DEAL_BETWEEN = 'DEAL_BETWEEN',
  PARTIES = 'PARTIES',
  CSV = 'CSV',
  PDF = 'PDF',
  DYNAMIC = 'DYNAMIC',
  DELETE = 'DELETE',
  TEAM_MEMBER = 'TEAM_MEMBER',
  LEGAL_BOX = 'LEGAL_BOX',
  ATTACHMENT = 'ATTACHMENT',
  SIGNATURE = 'SIGNATURE',
  FIELDS = 'FIELDS',
  PRODUCTS = 'PRODUCTS',
  PRODUCTS2 = 'PRODUCTS2',
  SIGNATURES = 'SIGNATURES',
  GOOGLESLIDES = 'GOOGLESLIDES',
  HTML = 'HTML',
  TAG = 'TAG',

  PRIVATE = 'PRIVATE',
  PRIVATE2 = 'PRIVATE2',
}
