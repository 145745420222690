import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, CSSProperties } from "react";
import { Button } from "reactstrap";

import classes from "./IconButton.module.scss";

interface IProps {
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  icon: IconDefinition;
  color: string;
  style?: CSSProperties;
  className?: string;
	disabled?: boolean;
}

const IconButton: React.FC<IProps> = ({
  onClick,
  color,
  children,
  icon,
  style,
  className,
	disabled,
}) => {
  const classNames = [classes.Button];
  if (className) {
    classNames.push(className);
  }
  return (
    <Button
			disabled={disabled}
      color={color}
      className={classNames.join(" ")}
      onClick={onClick}
      style={{ ...style }}
    >
      <div className={classes.Icon}>
        <FontAwesomeIcon icon={icon} className="fa-xs" />
      </div>
      {children}
    </Button>
  );
};

export default IconButton;
