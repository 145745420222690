import React, { useContext, useState, useEffect } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { addDays } from "date-fns";

import CustomSelect from '../../../../../../../components/UI/CustomSelect/CustomSelect';
import OfferContext from '../../../../../containers/Offers/Offer/OfferContext';
import { sortByLabel, dateToString } from '../../../../../../../shared/utility';

import { IUserTemplateAreaBlock, IBlockContent, IBlockContentProducts, IProduct } from '../../../../../../../interfaces/domain';
import { EContentType } from '../../../../../../../enums/content-types';

import classes from "./OfferBasics.module.scss";
import inputClasses from "../../../../../../../components/UI/Input/Input.module.scss";

interface IProps {}

const OfferBasics: React.FC<IProps> = () => {
  const {
    offer,
    setOffer,
    userTemplates,
    userTemplatesLoading,
    hideEditableContent,
    setHideEditableContent
  } = useContext(OfferContext);

  const [once,setOnce]=useState<boolean>(true);

  const getUserTemplateOptions = () => {
    return (
      (userTemplates &&
        sortByLabel(userTemplates.map(({ id, name }) => ({ id, label: name })))
        ) ||
      []
    );
  };

  const setValue = (name: string, value: any) => {
		if(name==="userTemplate") {
			const signType=offer.organization.level==="PREMIUM"?"SMS":"NORMAL"
			const settings=(value || {}).settings || {}
			settings.signType=settings.signType || signType

      const expirationDate = dateToString(addDays(new Date(), value.settings.expiryDays || 30));
      const email = {body: value.settings.email_body ||  "", title: value.settings.email_title || "", signature: value.settings.email_signature || ""};
      setOffer({ ...offer, [name]: {...value,settings:settings}, email:email, expirationDate} );
		}
		else {
	    setOffer({ ...offer, [name]: value });
		}
  };

  if (offer.userTemplate && offer.userTemplate.id) {
    if (!userTemplates || !userTemplates.find(template => template.id === offer.userTemplate.id)) {
      setHideEditableContent(true);
    } else {
      setHideEditableContent(false);
    }
  }

  useEffect(()=>{
    if(offer.userTemplate) {
      const _template = userTemplates.find( (template) => template.id === offer.userTemplate.id);

      if(offer.userTemplate.main.blocks) {
      offer.userTemplate.main.blocks.forEach((block:IUserTemplateAreaBlock) => {
        (block.content || []).forEach((content:IBlockContent) => {
          if((content.contentType === EContentType.PRODUCTS)) {

            const _pcontent = content as IBlockContentProducts;

            let _products:Array<IProduct> = _pcontent.products || [];
            try {
              _template.main.blocks.forEach( (_block:IUserTemplateAreaBlock) => {
                let p = (_block.content || []).find( (_content:IBlockContent) => _content.id === _pcontent.id ) as IBlockContentProducts;
                if(p) {

                  _products = p.products.reduce( (resp:Array<IProduct>, p:IProduct) => {
                    if(p.library && !resp.find((_p:IProduct)=>_p.item===p.item && _p.library)) {
                      resp.push(p);
                    }
                    return resp;
                  },_products);

                }
              });
            } catch(err) {}
          }
        });
      });
      }
    }
  },[offer.userTemplate])

  useEffect(()=>{
    try {
      if(offer && offer.userTemplate && offer.userTemplate.settings.temporary && once) {
        const templ=JSON.parse(JSON.stringify(userTemplates.find(template => template.id === offer.userTemplate.id)));

        if(offer.pipedriveDeal && offer.pipedriveDeal.customFields) {
          setTimeout(()=>{
            setOffer({ ...offer, userTemplate: templ, customFields:offer.pipedriveDeal.customFields});
          },100);
        }
        setOnce(false);
      }
    } catch(err) {
      console.log("Error",err);
    }
  },[offer.userTemplate]);


  return (
    <div className={classes.Container}>
      <h5>Proposal Basics</h5>
      <FormGroup>
        <Label for="name-input" className={inputClasses.Label}>Proposal name</Label>
        <Input
          autoComplete="new-password"
          className={inputClasses.Input}
          type="text"
          placeholder="Proposal name is visible to customer"
          value={offer.name}
          onChange={event => setValue("name", event.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label className={inputClasses.Label}>Choose template</Label>
        <CustomSelect
          className={inputClasses.Input}
          value={offer.userTemplate && offer.userTemplate.id}
          onChange={(value: string) => {
            setValue(
              "userTemplate",
              JSON.parse(JSON.stringify(userTemplates.find(template => template.id === value)))
            );
            if (hideEditableContent) {
              setHideEditableContent(false);
            }
          }}
          options={getUserTemplateOptions()}
          isMulti={false}
          placeholder="Choose template"
          loading={userTemplatesLoading}
          invalid={!offer.userTemplate || !offer.userTemplate.id}
        />
      </FormGroup>
    </div>
  );
};

export default OfferBasics;
