import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Form } from "reactstrap";
import CustomDialog from "../../../../../../components/UI/CustomDialog/CustomDialog";
import { EOrganizationLevel, ESignType } from "../../../../../../enums";
import { IOffer } from "../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData
} from "../../../../../../shared/utility";
import DialogText from "./DialogText";
import OfferSignatureModal from "../OfferSignatureModal/OfferSignatureModal";
import WebFont from "webfontloader";

import classes from "./OfferAcceptDialog.module.scss";

interface IProps {
  offer: IOffer;
  organizationLevel: string;
  open: boolean;
  error?: string | null;
  acceptOffer: (confirmCode?: string, customerSignature?: string, email?: string, hash?:string) => void;
  closeModal: () => void;
  onSendOfferConfirmCode: () => void;

  email?: string;
  hash?: string;
}

const OfferAcceptDialog: React.FC<IProps> = ({
  organizationLevel,
  open,
  acceptOffer,
  closeModal,
  offer,
  error,
  onSendOfferConfirmCode,

  email,
  hash,
}) => {
  const { t } = useTranslation();

  const [controlsState, setControlsState] = useState({
    controls: {
      code: {
        elementType: "input",
        elementConfig: {
          placeholder: "",
          label: "",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: ""
      }
    },
    formIsValid: false
  });

	const signType:ESignType=(offer && offer.userTemplate && offer.userTemplate.settings && offer.userTemplate.settings.signType) || null;


  const [isCodeSent, setIsCodeSent] = useState(false);
	const codeRequired = (signType === ESignType.SMS) || (signType===null && organizationLevel === EOrganizationLevel.PREMIUM);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  };

  // Translate ei toimi jos laittaa suoraan controlleihin
  useEffect(() => {
    if (codeRequired && open && !isCodeSent) {
      sendCode();
      setControlsState({
        ...controlsState, controls:
        {
          code: {
            ...controlsState.controls.code,
            elementConfig: {
              ...controlsState.controls.code.elementConfig,
              placeholder: t("signOfferDialog.confirmCodePlaceholder"),
              label: t("signOfferDialog.confirmCodeLabel")
            }
          }
        }
      }
      );
    }
    // eslint-disable-next-line
  }, [open]);

  const submitHandler = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    if (controlsState.formIsValid || !codeRequired) {
      const data = getFormData(controlsState.controls);
      acceptOffer(data.code, customerSignature, email, hash);
    }
  };

  const sendCode = () => {
    onSendOfferConfirmCode();
    setIsCodeSent(true);
  };

  const [signatureOpen, setSignatureOpen] = useState(false);
  const [customerSignature, setCustomerSignature] = useState(null);
  const signatureOk = (signature: string) => {
    if (signature) {
      setCustomerSignature(signature);
    }
    setSignatureOpen(false);
  }

  const isValid = () => {
    let valid = true;
    if (codeRequired) valid = valid && controlsState.formIsValid;
    if (offer.hasSignature) valid = valid && customerSignature;
    return valid;
  }

  useEffect(() => {
    if (open && offer.hasSignature) {
      WebFont.load({
        google: {
          families: [`Cedarville Cursive:400,700`]
        }
      });
      setSignatureOpen(true);
    }
  }, [open, offer.hasSignature])

  return (
    <React.Fragment>
      {signatureOpen && (
        <CustomDialog
          open={signatureOpen}
          title={t("offerSignatureModal.title")}
          size="fullscreen"
          closable={true}
          onCancel={()=>{closeModal(); setSignatureOpen(false)}}
          cancelButtonText=""
        >
          <OfferSignatureModal onAccept={signatureOk} />
        </CustomDialog>
      )
      }
      <CustomDialog
      title={t("signOfferDialog.title")}
      okButtonText={t("signOfferDialog.confirmButton")}
      cancelButtonText={t("signOfferDialog.dontConfirmButton")}
      open={open}
      onOk={submitHandler}
      onCancel={closeModal}
      isValid={isValid()}
    >
      <DialogText name={offer.customer && offer.customer.contactPerson} />
      {codeRequired && (
        <Form onSubmit={e => submitHandler(e)}>
          {controlsToFormGroups(controlsState.controls, inputChangedHandler)}
          <div className={classes.Info}>
            <span>
              {t("signOfferDialog.codeSent")} {offer.customer && offer.customer.phone}
            </span>
            <Button onClick={() => sendCode()} color="link" type="button">
              {t("signOfferDialog.resendCode")}
            </Button>
          </div>
        </Form>
      )}

      {codeRequired && controlsState.controls.code.touched && !controlsState.formIsValid && (
        <Alert className={classes.ErrorMessage} color="danger">
          <p>{t("signOfferDialog.codeRequired")}</p>
        </Alert>
      )}
      
      {/* {offer.hasSignature && (
        <IconButton icon={faSignature} onClick={() => setSignatureOpen(true)} color="">
          Add your signature
        </IconButton>
      )} */}
      {/* {offer.hasSignature && customerSignature && (
        <React.Fragment>
          <p>Your signature:</p>
          {customerSignature.startsWith("data:image/png;base64,") ? (
            <img src={customerSignature} alt="" style={{maxWidth: "100%"}} />
            ) : <p style={{ fontFamily: "Cedarville Cursive" }}>{customerSignature}</p>}
        </React.Fragment>
      )} */}

      {codeRequired && error && (
        <Alert className={classes.ErrorMessage} color="danger">
          <p>{t("signOfferDialog.failtitle")} {t("signOfferDialog.failedContentTitle")}</p>
          <p>{t("signOfferDialog.failedToSend")} {offer.customer && offer.customer.phone} {t("signOfferDialog.failedToSendPostfix")}</p>
          <p>{t("signOfferDialog.failedContactSender")} {offer.author && offer.author.name} <a href={`mailto:${offer.author && offer.author.email}`}>{offer.author && offer.author.email}</a></p>
          {/* {error} */}
        </Alert>
      )}
    </CustomDialog>
    </React.Fragment>
  );
};

export default OfferAcceptDialog;
