import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';

import { EOfferStatus } from '../../../../enums';
import { IOffer } from '../../../../interfaces';
import SortIcon, { ESortDirection } from '../../../../components/UI/SortIcon/SortIcon2';
import { EOfferSort } from '../../../../enums/offer-enums';


import classes from "./ContractsList.module.scss";

interface IProps {
  offers: Array<IOffer>;
  onOpenOffer: (id: string) => void;
  searchMore: () => void;
  searchString: string;
}

const ContractsList: React.FC<IProps> = ({ offers, onOpenOffer, searchMore, searchString }) => {
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [pages, setPages] = useState<number>(1);
  const [slicedOffers, setSlicedOffers] = useState<Array<IOffer>>([]);

  const [newSort, setNewSort] = useState<any>({property: 'CONFIRM_DATE', direction: 'DESC'});

  const PAGE_LENGTH = 25;

  useEffect(()=>{
    if(offers!==null) {
      
      //setSlicedOffers(
        const foffers = offers.filter(offer => {
        const s = (offer.customerName || "").toLowerCase()+" "+
          (offer.name || "").toLowerCase()+" "+(offer.authorName || "").toLowerCase()+offer.created+offer.confirmDate;
        return (
          (offer.status === EOfferStatus.CONFIRMED || offer.status === EOfferStatus.TERMINATED) &&
          (searchString===null || s.includes(searchString.toLowerCase()))
        )
      });

      setMaxPage(Math.ceil(foffers.length/PAGE_LENGTH));
      setSlicedOffers(foffers); //.slice(page*PAGE_LENGTH,page*PAGE_LENGTH+PAGE_LENGTH));
    }
  },[offers, searchString]) 


  const dconv = (d:string) => {
    try {
     const a=d.split(" ");
     const b=a[0].split(".");
     return b[2]+b[1]+b[0]+a;
   } catch(exception) { return ""; }
  }

  const sort = (a:IOffer,b:IOffer) => {
    if(newSort === null) return 1;
    else if(newSort.property === "CUSTOMER_NAME") {
      return (a.customerName || "").toLowerCase() > (b.customerName || "").toLowerCase() ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }
    else if(newSort.property === "CONFIRM_DATE") {
      return dconv(a.confirmDate) > dconv(b.confirmDate) ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }
    else if(newSort.property === "AUTHOR_NAME") {
      return (a.authorName || "").toLowerCase() > (b.authorName || "").toLowerCase() ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }

 
    return 1;
  }



  if (!offers || !offers.length) {
    return null;
  }

  const click = (newSortx:any) => { 
    setNewSort(newSortx);
  }

  const prevPage = () => {
    if(page>0) setPage(page-1);
  }

  const nextPage = () => {
    if(page+PAGE_LENGTH<offers.length)
      if(page<Math.ceil(offers.length/PAGE_LENGTH)-1) setPage(page+1);
  }
  return (
    <div>
    <div style={{"width":"100%", "textAlign":"center"}}>
    <button onClick={prevPage}>&lt;</button> 
    {Array.from(Array(maxPage), (e,i) => {
      return <button key={"btn"+i} style={{"background":i===Math.min(maxPage-1,page)?"#999999":"#eeeeee"}} onClick={()=>setPage(i)}>{i+1}</button>
    })} 
    <button onClick={nextPage}>&gt;</button>
    </div>

    <Table responsive borderless striped>
      <thead>
        <tr>
          <th>Customer <SortIcon property={EOfferSort.CUSTOMER_NAME} onSortChange={click} /></th>
          <th>Created by <SortIcon property={EOfferSort.AUTHOR_NAME} onSortChange={click} /></th>
          <th>Accepted <SortIcon property={EOfferSort.CONFIRM_DATE} onSortChange={click} /></th>
          <th />
        </tr>
      </thead>
      <tbody>
        {/*offers.map(offer => (offer.status === EOfferStatus.CONFIRMED || offer.status === EOfferStatus.TERMINATED) && (*/}
        {slicedOffers.sort(sort).slice(Math.min(maxPage-1,page)*PAGE_LENGTH,Math.min(maxPage-1,page)*PAGE_LENGTH+PAGE_LENGTH).map(offer => ( 
          <tr key={offer.id} onClick={() => onOpenOffer(offer.id)}>
            {/* TODO: add logic to label */}
            <td>
              {offer.customerName}{/* <CustomLabel type={ECustomLabelType.BADGE} text="New" />*/}<br/>
              {offer.name}
            </td>
            <td>{offer.authorName}<br/>{offer.created}</td>
            <td>{offer.confirmDate}</td>
            <td className={[classes.Buttons, "min-width"].join(' ')}>
              {/*offer.userTemplate && offer.userTemplate.name && <span>{offer.userTemplate.name}<br/></span>*/}
              <Button color="light" size="sm">
                View Contract
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
  );
};

export default ContractsList;
