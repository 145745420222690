import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect
} from "react";
import { EContentType, EDropzoneType } from "../../../../../../../enums";
import {
  IBlockContent,
  IBlockContentTeamMember
} from "../../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData
} from "../../../../../../../shared/utility";
import { IBlockContentEditItem } from "../../BlockContentEdit";

interface IProps {
  ref: any;
  blockContent: IBlockContent;
  onChange?: (blockContent: IBlockContent) => void;
}

const BlockContentTeamMemberEdit: React.ForwardRefExoticComponent<
  IBlockContentEditItem
> = forwardRef((props, ref) => {
  const [init, setInit] = useState(false);
  const blockContent = props.blockContent as IBlockContentTeamMember;

  const [controlsState, setControlsState] = useState({
    controls: {
      memberName: {
        elementType: "input",
        elementConfig: {
          label: "Name",
          placeholder: "Name",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: blockContent.memberName || ""
      },
      title: {
        elementType: "input",
        elementConfig: {
          label: "Title",
          placeholder: "Title",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: blockContent.title || ""
      },
      linkText: {
        elementType: "input",
        elementConfig: {
          label: "Link text",
          placeholder: "Link text",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: blockContent.linkText || ""
      },
      image: {
        elementType: "dropzone",
        elementConfig: {
          label: "Image",
          accept: EDropzoneType.IMG,
          text: "Drop image here or click to upload.",
          dragText: "Drop image here.",
          contentType: EContentType.IMAGE,
          config: {
            multiple: false
          }
        },
        valid: true,
        touched: false,
        value: blockContent.image
      }
    },
    formIsValid: false
  });

  // FIXME: sets value of other controls in the same state to empty or null
  // Separated for now
  const [descriptionState, setDescriptionState] = useState({
    controls: {
      description: {
        elementType: "wysiwyg",
        elementConfig: {
          label: "Description",
          placeholder: "Description",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: blockContent.description || ""
      }
    },
    formIsValid: false
  })

  const getData = () => {
    return Object.assign(blockContent, getFormData(controlsState.controls), getFormData(descriptionState.controls));
  };

  useEffect(() => {
    if (props.onChange && init) {
      props.onChange(getData());
    }
    // eslint-disable-next-line
  }, [controlsState.controls]);

  useEffect(() => {
    setInit(true);
  }, []);

  useImperativeHandle(ref, () => ({
    getData
  }));

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  };

  const descriptionInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      descriptionState,
      setDescriptionState
    );
  };

  let blurHandler:any = null;
    if(props.onBlur) {
      blurHandler = () => {
        props.onBlur(getData());
      }
    }

  return (
    <React.Fragment>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler, blurHandler)}
      {controlsToFormGroups(descriptionState.controls, descriptionInputChangedHandler, blurHandler)}
    </React.Fragment>
  );
});

export default BlockContentTeamMemberEdit;
