import React from "react";
import { Button, Table } from "reactstrap";
import { IBlockItem } from "../../../../../../../interfaces";
import Content from "../../../../../../../components/UI/Content/Content";

import classes from "./BlockItemsList.module.scss";

interface IProps {
  blockItems: Array<IBlockItem>;
  onEdit: (id: string) => void;
}

const BlockItemsList: React.FC<IProps> = ({ blockItems, onEdit }) => {
  if (!blockItems || !blockItems.length) {
    return (
      <Content style={{ marginTop: "1rem" }}>
        <p>No blocks items.</p>
      </Content>
    );
	}

	const blockSort = (a:IBlockItem,b:IBlockItem) => (a.name.toLowerCase()<b.name.toLowerCase()?-1:1)


  return (
    <Table responsive borderless striped>
      <tbody>
        {blockItems.sort(blockSort).map(blockItem => (
          <tr key={blockItem.id}>
            <td style={{ width: "100%" }}>{blockItem.name}</td>
            <td>
              <div className={classes.Actions}>
                <Button
                  color="info"
                  size="sm"
                  onClick={() => onEdit(blockItem.id)}
                >
                  Edit
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BlockItemsList;
