import { EContentType, EDropzoneType } from './../../enums/index';

export const getProfileControls = (): any => {
  return {
    firstName: {
      elementType: "input",
      elementConfig: {
        label: "First name",
        placeholder: "First name",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    lastName: {
      elementType: "input",
      elementConfig: {
        label: "Last name",
        placeholder: "Last name",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    image: {
      elementType: "dropzone",
      elementConfig: {
        label: "Image",
        accept: EDropzoneType.IMG,
        text: "Drop image here or click to upload.",
        dragText: "Drop image here.",
        contentType: EContentType.IMAGE,
        config: {}
      },
      valid: true,
      touched: false,
      value: ""
    },
    currentPassword: {
      elementType: "input",
      elementConfig: {
        label: "Type the current password",
        placeholder: "Current password",
        type: "password"
      },
      validation: {
        password: true,
        required: true,
        minLength: 8
      },
      valid: true,
      touched: false,
      value: ""
    },
    password: {
      elementType: "input",
      elementConfig: {
        label: "Type a new password (min. 8 chars)",
        placeholder: "Password (min. 8 chars)",
        type: "password"
      },
      validation: {
        password: true,
        required: true,
        minLength: 8
      },
      valid: true,
      touched: false,
      value: ""
    },
    passwordAgain: {
      elementType: "input",
      elementConfig: {
        label: "Type the password again to confirm",
        placeholder: "Password again",
        type: "password"
      },
      validation: {
        password: true,
        required: true,
        minLength: 8
      },
      valid: true,
      touched: false,
      value: ""
    }
  };
};
