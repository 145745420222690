import React from 'react';
import ReactDatePicker from 'react-datepicker';

import { dateToString, stringToDate } from '../../../shared/utility';

import inputClasses from "../Input/Input.module.scss";

interface IProps {
  className?: string;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
}

const CustomDatePicker: React.FC<IProps> = ({ onChange, value, placeholder }) => {
  const changeHandler = (date: Date) => {
    let newValue = "";
    if (date) {
      newValue = dateToString(date);
    }
    onChange(newValue);
  };

  let dateValue = null;
  if (value) {
    dateValue = stringToDate(value);
  }

  return (
    <ReactDatePicker
      className={["form-control", inputClasses.Input].join(" ")}
      selected={dateValue}
      onChange={date => changeHandler(date)}
      dateFormat="dd.MM.yyyy"
      isClearable
      placeholderText={placeholder}
    />
  );
};

export default CustomDatePicker;
