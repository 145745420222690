import React, { useState } from "react";
import CustomDialog from "../../../../../../components/UI/CustomDialog/CustomDialog";
import { EContentType } from "../../../../../../enums/content-types";
import { IBlockContent, IUserTemplateAreaBlock } from "../../../../../../interfaces";
import { IBlockItemContentRef } from "../../../../containers/Blocks/Block/BlockItems/BlockItem/BlockItem";
import BlockContentEdit from "../BlockContentEdit";

import ContentTypeEdit from "../ContentTypeDialog/ContentTypeEdit";
import BlockItemsCombined from "../../../../containers/Blocks/Block/BlockItems/BlockItemsCombined";

// import classes from "./BlockContentDialog.module.scss";

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (data: IContentTypeDialogData) => void;
  onDelete?: (id: string) => void;
  blockContent: IBlockContent;
  areaBlock?: IUserTemplateAreaBlock;
}

export interface IContentTypeDialogData {
  id: string;
  name: string;
  contentType: EContentType;
}

const BlockContentDialog: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  onSave,
	onDelete,
  blockContent,
	areaBlock,
}) => {
  const ref = React.createRef<IBlockItemContentRef>();
  const typeRef = React.createRef<IBlockItemContentRef>();

	const [typeParameters, setTypeParameters] = useState({});

  const closeDialog = () => {
    setIsOpen(false);
  };

	const onOk = () => {
		// getData() handles saving (?)

    const data = (ref.current && ref.current.getData()) || {};	
		const typeData= typeRef.current.getData();

		// NOTE
		if(blockContent.contentType==="PRODUCTS") {
			onSave({...blockContent,...typeData,...data,...typeParameters});
		}
		else if(blockContent.contentType==="PRODUCTS2") {
			onSave({...blockContent,...typeData,...data,...typeParameters});
		}
		else {
			onSave({...blockContent,...data,...typeData,...typeParameters});
		}

    closeDialog();
  };

	const updateBlockContent = (x:any) => {
		if(x.formIsValid) {
			const valuesList=Object.keys(x.controls).reduce((resp:{[key:string]:any},name:string)=>{
				const ob=x.controls[name];
				resp[name]=ob.value;
				return resp;
			},{  });
			setTypeParameters(valuesList);
		}

	}
 	const deleteContentType = () => {
		onDelete(blockContent.id);
    closeDialog();
	}
	
	return (
    <CustomDialog
      loading={false}
      onOk={blockContent && onOk}
      onCancel={closeDialog}
      open={isOpen}
			onDelete={blockContent && deleteContentType}

      title={areaBlock?"Multi Item Element":"Edit element content"}
			hideButtons={areaBlock?true:false}

      isValid={true}
			overflow={true}
    >
			{/* blockType = DYNAMIC / SIMPLE */}
			{blockContent && <>
				<BlockContentEdit ref={ref} blockContent={blockContent}/>
				<ContentTypeEdit
					blockContent={blockContent}
					blockType={null}
					setContentTypeControls={updateBlockContent}
					ref={typeRef}
				/>
			</>}

			{areaBlock && <>
				<BlockItemsCombined id={areaBlock.blockId} onCancel={closeDialog}/>
			</>}


    </CustomDialog>
  );
};

export default BlockContentDialog;
