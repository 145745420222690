import React from 'react';
import { connect } from 'react-redux';
import Navigation from '../../components/Navigation/Navigation';
import { IAppState, INavItem, IUser } from '../../interfaces';
import * as actions from '../../store/actions';


import classes from "./Layout.module.scss";

interface IStateProps {
  currentUser: IUser;
}

interface IDispatchProps {
  onLogout: () => void;
}

interface IProps extends IStateProps, IDispatchProps {
  children: any;
  navItems: Array<INavItem>;
  dropdownItems: Array<INavItem>;
}

const Layout: React.FC<IProps> = (props) => {

  const onLogout = () => {
    props.onLogout();
  }
  return (
    <div className={classes.root}>
      <Navigation
        currentUser={props.currentUser}
        onLogout={onLogout}
        navItems={props.navItems}
        dropdownItems={props.dropdownItems}
      />
      <div className={classes.Container}>
        {props.children}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onLogout: () => dispatch(actions.logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
