import React from "react";

import {
  IBlockContent,
  IBlockContentFields
} from "../../../../../../../interfaces";

import classes from "./BlockContentFieldsShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent?: boolean;
}

//const dum:any[]=[]
//const dummyValues={
//	jsonData:{left:dum,right:dum},
//};

const renderString = (index:any,ob:any) => (
	<p className={classes["h"+ob.fontSize]} key={index}>{ob.value}</p>
)

const BlockContentContentShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentFields;
//	console.log(blockContent)
//	const jsonData=JSON.parse(blockContent.jsonData) || dummyValues.jsonData;	
  return (
    <div className={[classes.Container, 'customColor-bg'].join(' ')}>
			<div>
				<div className={classes.Row}>
					<div className={classes.leftSide}>
						{(blockContent.leftFields || []).map((ob: any,index: number)=>(renderString(index,ob)))}
					</div>
					<div className={classes.rightSide}>
						{(blockContent.rightFields || []).map((ob: any, index: number)=>(renderString(index,ob)))}
					</div>
				</div>
			</div>
    </div>
  );
};

export default BlockContentContentShow;
