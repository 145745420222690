import axios from '../../axios';
import { ActionTypes } from './actionTypes';

import { IAuthAction, IUser } from '../../interfaces';
import { Dispatch } from 'redux';

export const storeAuthRedirect = (redirect:string):IAuthAction => {
    return {
        type: ActionTypes.STORE_AUTH_REDIRECT,
        redirect
    };
};

export const authStart = ():IAuthAction => {
    return {
        type: ActionTypes.AUTH_START
    };
};

export const authSuccess = (email:string, currentUser:IUser):IAuthAction => {
    return {
        type: ActionTypes.AUTH_SUCCESS,
        email,
        currentUser
    };
};

export const authFail = (error:string):IAuthAction => {
    return {
        type: ActionTypes.AUTH_FAIL,
        error
    }
};

export const auth = (email:string, password:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(authStart());
        const authData = {
            email,
            password
        }
        try {
            const res = await axios.post("/json/auth", authData);
            const { user, email } = res.data;
            dispatch(authSuccess(email, user));
            //@ts-ignore
        } catch (error: any) {
            dispatch(authFail(error.response.data));
        }
    };
};

export const logoutStart = ():IAuthAction => {
    return {
        type: ActionTypes.LOGOUT_START
    };
};

export const logoutSuccess = ():IAuthAction => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS
    };
};

export const logoutFail = (error:string):IAuthAction => {
    return {
        type: ActionTypes.LOGOUT_FAIL,
        error
    }
};

export const logout = () => {
    return async (dispatch:any) => {
        dispatch(logoutStart());
        try {
            await axios.get("/json/users/logout");
            dispatch(logoutSuccess());
        } catch (error) {
            //@ts-ignore
            dispatch(logoutFail(error));
        }
    };
};


export const clearError = ():IAuthAction => {
    return {
        type: ActionTypes.CLEAR_ERROR
    };
};


export const getCurrentUserStart = ():IAuthAction => {
    return {
        type: ActionTypes.GET_CURRENT_USER_START
    };
};

export const getCurrentUserSuccess = (currentUser:IUser, pipedriveConnectionLost: boolean, pipedriveAuthorizeUrl: string):IAuthAction => {
    return {
        type: ActionTypes.GET_CURRENT_USER_SUCCESS,
        currentUser,
        pipedriveConnectionLost,
        pipedriveAuthorizeUrl
    };
};

export const getCurrentUserFail = ():IAuthAction => {
    return {
        type: ActionTypes.GET_CURRENT_USER_FAIL
    }
};

export const getCurrentUser = () => {
    return async (dispatch:Dispatch) => {
        try {
            dispatch(getCurrentUserStart())
            const res = await axios.get('/json/users/currentuser');
            const { user, pipedriveConnectionLost, pipedriveAuthorizeUrl} = res.data;
            
            if (user && user.id)  {
                dispatch(getCurrentUserSuccess(user, pipedriveConnectionLost, pipedriveAuthorizeUrl));
            } else {
                dispatch(getCurrentUserFail())
            }
        } catch (error) {
            dispatch(getCurrentUserFail());
        }
        
    };
};

export const updatePasswordStart = (): IAuthAction => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_START
  };
};

export const updatePasswordSuccess = (status: string): IAuthAction => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_SUCCESS,
    status
  };
};

export const updatePasswordFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_FAIL,
    error
  };
};

export const updatePassword = (formData: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(updatePasswordStart());
    try {
      const res = await axios.post("/json/users/updatepassword", formData);
      dispatch(updatePasswordSuccess(res.data));
    } catch (error) {
        //@ts-ignore
      dispatch(updatePasswordFail(error));
    }
  };
};
