import { IUser } from './../../../../../interfaces/domain';
import React from 'react';
import { ICustomer, IOrganization } from '../../../../../interfaces/domain';
import { EOfferStatus } from './../../../../../enums/index';


const OfferPreviewContext = React.createContext<{
  customer: ICustomer;
  organization: IOrganization;
  hash: string;
  confirmDate: string;
  status: EOfferStatus;
  id: string;
  token: string;
  hideAuditLogs: boolean;
  printable: boolean;
  sentDate: string;
  customerSignature: string;
  author: IUser;
  currentUser: IUser;
	strongauth?: boolean;
}>({
  customer: null,
  organization: null,
  hash: null,
  confirmDate: null,
  status: null,
  id: null,
  token: null,
  hideAuditLogs: false,
  printable: false,
  sentDate: null,
  customerSignature: null,
  author: null,
  currentUser: null,
});

export default OfferPreviewContext;
