import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ListSpinner from "../../../../../../../components/UI/ListSpinner/ListSpinner";
import { IAppState, IAuditLog } from "../../../../../../../interfaces";
import * as actions from "../../../../../../../store/actions";
import OfferAuditLogItem from "./OfferAuditLogItem/OfferAuditLogItem";

import classes from "./OfferAuditLog.module.scss";

interface IStateProps {
  auditLogsLoading: boolean;
  auditLogs: Array<IAuditLog>;
}

interface IDispatchProps {
  onFindAuditLogs: (id: string, token: string) => Promise<IAuditLog>;
}

interface IProps extends IStateProps, IDispatchProps {
  id: string;
  token: string;
  printable?: boolean;
}

const OfferAuditLog: React.FC<IProps> = ({
  auditLogsLoading,
  auditLogs,
  onFindAuditLogs,
  id,
  token,
  printable
}) => {
  const [fetchDone, setFetchDone] = useState(false);
  useEffect(() => {
    onFindAuditLogs(id, token).then(() => {
      setFetchDone(true);
    });
    // eslint-disable-next-line
  }, []);

  const headerTextTimestamp = "Trusted timestamp";
  const headerTextAuditData = "Event with collected audit data";

  if ((!auditLogs && !fetchDone) || auditLogsLoading) {
    return <ListSpinner />;
  } else {
    return (
      <div className={printable ? null : classes.Container}>
        {auditLogs && printable ? <h3 className={classes.Title}>Audit trail</h3> : null}
        {auditLogs ? (
          <div className={printable ? [classes.AuditLogs, classes.Center].join(" ") : classes.AuditLogs}>
            <div className={classes.Header}>{headerTextTimestamp}</div>
            <div className={classes.Header}>{headerTextAuditData}</div>
            {auditLogs.map(auditLog => (
              <OfferAuditLogItem
                key={auditLog.id}
                auditLog={auditLog}
                headerTextTimestamp={headerTextTimestamp}
                headerTextAuditData={headerTextAuditData}
              />
            ))}
          </div>
        ) : (
          <p>No audit data</p>
        )}
      </div>
    );
  }
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    auditLogsLoading: state.offers.auditLogsLoading,
    auditLogs: state.offers.auditLogs
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindAuditLogs: (id, token) =>
      dispatch(actions.findOfferAuditLogs(id, token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferAuditLog);
