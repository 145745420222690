export const getUserControls = (): any => {
  return {
    firstName: {
      elementType: "input",
      elementConfig: {
        label: "First name",
        placeholder: "First name",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    lastName: {
      elementType: "input",
      elementConfig: {
        label: "Last name",
        placeholder: "Last name",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    email: {
      elementType: "input",
      elementConfig: {
        label: "Email",
        placeholder: "Email",
        type: "email"
      },
      validation: {
        required: true,
        email: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    phoneNumber: {
      elementType: "input",
      elementConfig: {
        label: "Phone",
        placeholder: "Phone",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    password: {
      elementType: "input",
      elementConfig: {
        label: "Password",
        placeholder: "Password",
        type: "text", //#password"
        style: {"-webkit-text-security":"disc"}
      },
      validation: {
        password: true,
        required: true,
        minLength: 8
      },
      valid: false,
      touched: false,
      value: ""
    },
    allowLogin: {
      elementType: "checkbox",
      elementConfig: {
        label: null,
        type: "checkbox",
        options: [{ value: true, displayValue: "Active" }]
      },
      validation: {},
      value: false,
      valid: true
    },
    role: {
      elementType: "radio",
      elementConfig: {
        label: "User role",
        type: "radio",
        options: [
          { value: "SALES_PERSON", displayValue: "Sales person" },
          { value: "ADMIN", displayValue: "Admin" },
          { value: "ENTREPRENEUR", displayValue: "Manager (have access to all proposals/contracts of the organization)" }
          //{ value: "SALES_PERSON", displayValue: "Basic User" },
          //{ value: "ADMIN", displayValue: "Team Admin" },
          //{ value: "TEAM_LEAD", displayValue: "Team Leader" },
          //{ value: "ENTREPRENEUR", displayValue: "Master User (have access to all proposals/contracts of the organization)" },
        ],
        validation: {
          required: true
        },
        value: "",
        valid: false
      }
    },

    isDeleted: {
      elementType: "checkbox",
      elementConfig: {
        label: null,
        type: "checkbox",
        options: [{ value: true, displayValue: "Delete User" }]
      },
      validation: {},
      value: false,
      valid: true

    },
  };
};
