export enum ActionTypes {
  STORE_AUTH_REDIRECT,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERROR,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,

  FIND_USERS_START,
  FIND_USERS_SUCCESS,
  FIND_USERS_FAIL,
  SEARCH_USERS_START,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAIL,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  SAVE_USER_START,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAIL,
  CLEAR_USERS_SEARCH,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,

  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,

  CLEAR_OFFERS_STATE,
  FIND_OFFERS_START,
  FIND_OFFERS_SUCCESS,
  FIND_OFFERS_FAIL,
  SEARCH_OFFERS_START,
  SEARCH_OFFERS_SUCCESS,
  SEARCH_OFFERS_FAIL,
  GET_OFFER_START,
  GET_OFFER_SUCCESS,
  GET_OFFER_FAIL,
  UPDATE_OFFER_START,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAIL,
  SAVE_OFFER_START,
  SAVE_OFFER_SUCCESS,
  SAVE_OFFER_FAIL,
  CLEAR_OFFERS_SEARCH,
  SEND_OFFER_CONFIRM_CODE_START,
  SEND_OFFER_CONFIRM_CODE_SUCCESS,
  SEND_OFFER_CONFIRM_CODE_FAIL,
  CONFIRM_OFFER_START,
  CONFIRM_OFFER_SUCCESS,
  CONFIRM_OFFER_FAIL,
  TERMINATE_OFFER_START,
  TERMINATE_OFFER_SUCCESS,
  TERMINATE_OFFER_FAIL,
  DECLINE_OFFER_START,
  DECLINE_OFFER_SUCCESS,
  DECLINE_OFFER_FAIL,
  FORWARD_OFFER_START,
  FORWARD_OFFER_SUCCESS,
  FORWARD_OFFER_FAIL,
  COMMENT_OFFER_START,
  COMMENT_OFFER_SUCCESS,
  COMMENT_OFFER_FAIL,
  MARK_OFFER_ACCEPTED_START,
  MARK_OFFER_ACCEPTED_SUCCESS,
  MARK_OFFER_ACCEPTED_FAIL,
  ARCHIVE_OFFER_START,
  ARCHIVE_OFFER_SUCCESS,
  ARCHIVE_OFFER_FAIL,
  FIND_OFFER_AUDIT_LOGS_START,
  FIND_OFFER_AUDIT_LOGS_SUCCESS,
  FIND_OFFER_AUDIT_LOGS_FAIL,
  DELETE_OFFER_START,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_FAIL,
  COPY_OFFER_START,
  COPY_OFFER_SUCCESS,
  COPY_OFFER_FAIL,

  CLEAR_ATTACHMENT_STATE,
  UPLOAD_ATTACHMENT_START,
  UPLOAD_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_FAIL,

  GET_EMAIL_DEFAULTS_START,
  GET_EMAIL_DEFAULTS_SUCCESS,
  GET_EMAIL_DEFAULTS_FAIL,
  
  CLEAR_CUSTOMERS_STATE,
  FIND_CUSTOMERS_START,
  FIND_CUSTOMERS_SUCCESS,
  FIND_CUSTOMERS_FAIL,
  SEARCH_CUSTOMERS_START,
  SEARCH_CUSTOMERS_SUCCESS,
  SEARCH_CUSTOMERS_FAIL,
  GET_CUSTOMER_START,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_START,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  SAVE_CUSTOMER_START,
  SAVE_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER_FAIL,
  CLEAR_CUSTOMERS_SEARCH,
  UPLOAD_CUSTOMERS_START,
  UPLOAD_CUSTOMERS_SUCCESS,
  UPLOAD_CUSTOMERS_FAIL,
  IMPORT_CUSTOMERS_START,
  IMPORT_CUSTOMERS_SUCCESS,
  IMPORT_CUSTOMERS_FAIL,
  CLEAR_CUSTOMERS_IMPORT,

  FIND_ORGANIZATIONS_START,
  FIND_ORGANIZATIONS_SUCCESS,
  FIND_ORGANIZATIONS_FAIL,
  GET_ORGANIZATION_START,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAIL,
  SAVE_ORGANIZATION_START,
  SAVE_ORGANIZATION_SUCCESS,
  SAVE_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION_START,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
  SEARCH_ORGANIZATIONS_START,
  SEARCH_ORGANIZATIONS_SUCCESS,
  SEARCH_ORGANIZATIONS_FAIL,
  GET_USER_ORGANIZATION_START,
  GET_USER_ORGANIZATION_SUCCESS,
  GET_USER_ORGANIZATION_FAIL,
  TEST_PIPEDRIVE_ORGANIZATION_START,
  TEST_PIPEDRIVE_ORGANIZATION_SUCCESS,
  TEST_PIPEDRIVE_ORGANIZATION_FAIL,
  DISCONNECT_PIPEDRIVE_ORGANIZATION_START,
  DISCONNECT_PIPEDRIVE_ORGANIZATION_SUCCESS,
  DISCONNECT_PIPEDRIVE_ORGANIZATION_FAIL,

  FIND_BLOCKS_START,
  FIND_BLOCKS_SUCCESS,
  FIND_BLOCKS_FAIL,
  GET_BLOCK_START,
  GET_BLOCK_SUCCESS,
  GET_BLOCK_FAIL,
  SAVE_BLOCK_START,
  SAVE_BLOCK_SUCCESS,
  SAVE_BLOCK_FAIL,
  UPDATE_BLOCK_START,
  UPDATE_BLOCK_SUCCESS,
  UPDATE_BLOCK_FAIL,
  DELETE_BLOCK_START,
  DELETE_BLOCK_SUCCESS,
  DELETE_BLOCK_FAIL,

  FIND_BLOCK_ITEMS_START,
  FIND_BLOCK_ITEMS_SUCCESS,
  FIND_BLOCK_ITEMS_FAIL,
  GET_BLOCK_ITEM_START,
  GET_BLOCK_ITEM_SUCCESS,
  GET_BLOCK_ITEM_FAIL,
  SAVE_BLOCK_ITEM_START,
  SAVE_BLOCK_ITEM_SUCCESS,
  SAVE_BLOCK_ITEM_FAIL,
  UPDATE_BLOCK_ITEM_START,
  UPDATE_BLOCK_ITEM_SUCCESS,
  UPDATE_BLOCK_ITEM_FAIL,
  DELETE_BLOCK_ITEM_START,
  DELETE_BLOCK_ITEM_SUCCESS,
  DELETE_BLOCK_ITEM_FAIL,

  FIND_USER_TEMPLATES_START,
  FIND_USER_TEMPLATES_SUCCESS,
  FIND_USER_TEMPLATES_FAIL,
  GET_USER_TEMPLATE_START,
  GET_USER_TEMPLATE_SUCCESS,
  GET_USER_TEMPLATE_FAIL,
  SAVE_USER_TEMPLATE_START,
  SAVE_USER_TEMPLATE_SUCCESS,
  SAVE_USER_TEMPLATE_FAIL,
  UPDATE_USER_TEMPLATE_START,
  UPDATE_USER_TEMPLATE_SUCCESS,
  UPDATE_USER_TEMPLATE_FAIL,
  DELETE_USER_TEMPLATE_START,
  DELETE_USER_TEMPLATE_SUCCESS,
  DELETE_USER_TEMPLATE_FAIL,
  COPY_USER_TEMPLATE_START,
  COPY_USER_TEMPLATE_SUCCESS,
  COPY_USER_TEMPLATE_FAIL,
  
  FIND_PLANS_START,
  FIND_PLANS_SUCCESS,
  FIND_PLANS_FAIL,
  GET_SEATS_START,
  GET_SEATS_SUCCESS,
  GET_SEATS_FAIL,
  
  GET_PRIMARY_PAYMENT_METHOD_START,
  GET_PRIMARY_PAYMENT_METHOD_SUCCESS,
  GET_PRIMARY_PAYMENT_METHOD_FAIL,
  SAVE_PAYMENT_METHOD_START,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_FAIL,
  // FIND_PAYMENT_METHODS_START,
  // FIND_PAYMENT_METHODS_SUCCESS,
  // FIND_PAYMENT_METHODS_FAIL,

  GET_CURRENT_SUBSCRIPTION_START,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  GET_CURRENT_SUBSCRIPTION_FAIL,
  SAVE_SUBSCRIPTION_START,
  SAVE_SUBSCRIPTION_SUCCESS,
  SAVE_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_START,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  
  FIND_INVOICES_START,
  FIND_INVOICES_SUCCESS,
  FIND_INVOICES_FAIL,
  GET_UPCOMING_INVOICE_START,
  GET_UPCOMING_INVOICE_SUCCESS,
  GET_UPCOMING_INVOICE_FAIL,
  GET_INVOICE_START,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAIL,

  GET_EMBEDDED_START,
  GET_EMBEDDED_SUCCESS,
  GET_EMBEDDED_FAIL,

  POST_EMBEDDED_START,
  POST_EMBEDDED_SUCCESS,
  POST_EMBEDDED_FAIL,

  FIND_USER_GROUPS_START,
  FIND_USER_GROUPS_SUCCESS,
  FIND_USER_GROUPS_FAIL,
  GET_USER_GROUP_START,
  GET_USER_GROUP_SUCCESS,
  GET_USER_GROUP_FAIL,
  SAVE_USER_GROUP_START,
  SAVE_USER_GROUP_SUCCESS,
  SAVE_USER_GROUP_FAIL,
  UPDATE_USER_GROUP_START,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAIL,
  DELETE_USER_GROUP_START,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAIL,

}
