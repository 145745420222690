import React from "react";

import classes from "./CreatedBy.module.scss";

interface IProps {
  date: string;
  seller:string;
}

const CreatedBy: React.FC<IProps> = ({ date, seller }) => {
  return (
    <div>
      <span className={classes.created}><b>Created by</b></span> <br />
      <span className={classes.date}>{seller} on {date}</span>
    </div>
  );
};

export default CreatedBy;