import React, { ReactNode } from 'react';

import classes from "./ListContainer.module.scss";

interface IProps {
  children: ReactNode;
}

const ListContainer:React.FC = ({ children }) => {
  return (<div className={classes.Container}>{children}</div>)
}

export default ListContainer;