import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../withRouter';


import ListHeader from "../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import { IAppState, ICustomer, IUser } from "../../../../interfaces";
import * as actions from "../../../../store/actions";
import CustomersList from "../../components/Customers/CustomersList";
import { ESearchType } from "../../../../enums";
import ListContainer from "../../../../components/ListContainer/ListContainer";
import ImportCustomersModal from "../../../../components/Customers/ImportCustomersModal/ImportCustomersModal";
import EUserRole from "../../../../enums/user-roles";

interface IStateProps {
  loading: boolean;
  error: string;
  customers: Array<ICustomer>;
  currentUser: IUser;
}

interface IDispatchProps {
  onFindCustomers: () => void;
  onSearchCustomers: (queryString: string) => void;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {}

const Customers: React.FC<IProps> = props => {
  const {
    onFindCustomers,
    customers,
    loading,
    history,
    onSearchCustomers,
    currentUser
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [updateCustomers, setUpdateCustomers] = useState(false);

console.log(props);

  useEffect(() => {
    onFindCustomers();
  }, [onFindCustomers, updateCustomers]);

  const addCustomer = () => {
    history.push("/customers/add");
  };

  const searchClickHandler = (id: string) => {
    history.push(`/customers/${id}`);
  };

  const searchChangedHandler = (queryString: string) => {
    onSearchCustomers(queryString);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleUpdateCustomers = () => {
    setUpdateCustomers(!updateCustomers);
  };

  let buttons = null;
  let importModal = null;

  if(currentUser.role !== EUserRole.SALES_PERSON) {
    buttons = [{
      clicked: toggleModal,
      text: "Import",
      color: "light"
    }];

    importModal = (
      <ImportCustomersModal
        showModal={showModal}
        toggleModal={toggleModal}
        toggleUpdateCustomers={toggleUpdateCustomers}
      />
    );
  }

  return (
    <ListContainer>
      <ListHeader
        title="Customers"
        onCreate={{
          text: "Add new customer",
          clicked: () => {
            addCustomer();
          }
        }}
        onSearch={{
          type: ESearchType.CUSTOMERS,
          searchFunc: searchChangedHandler,
          clicked: searchClickHandler
        }}
        buttons={buttons}
      />

      {importModal}

      {loading ? (
        <ListSpinner />
      ) : (
          <CustomersList {...props} customers={customers} />
        )}
    </ListContainer>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.customers.loading,
    error: state.customers.error,
    customers: state.customers.customers,
    currentUser: state.auth.currentUser
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindCustomers: () => dispatch(actions.findCustomers()),
    onSearchCustomers: queryString =>
      dispatch(actions.searchCustomers(queryString))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customers);
