import React, { useContext } from "react";
//import { DropTargetMonitor, useDrop } from "react-dnd";
import { EDragArea, EDragType } from "../../../../../../enums/drag";
import { IUserTemplateBlockDraggable, IUserTemplateArea } from "../../../../../../interfaces";
import TemplateAreaContext from "./TemplateAreaContext";
import TemplateAreaItem from "./TemplateAreaItem/TemplateAreaItem";

import classes from "./TemplateArea.module.scss";

interface IProps {
  areaType: EDragArea;
  blocks: Array<IUserTemplateBlockDraggable>;
  add?: boolean;
  area?: IUserTemplateArea;
}

const TemplateArea: React.FC<IProps> = ({ blocks, areaType, add, area }) => {
  const { moveItem, onStartDrag, onEndDrag, blockClickHandler, activeBlock, areaClickHandler, activeArea } = useContext(TemplateAreaContext);
/*
  const [, drop] = useDrop({
    accept: EDragType.BLOCK,
    hover: (item: IUserTemplateBlockDraggable, monitor: DropTargetMonitor) => {
      if (item.area !== areaType) {
        moveItem(item, areaType, null);
      }
    }
  });
*/
  const containerClasses = [classes.Container];
  if(add) {
    containerClasses.push(classes.Add);
  }
  if(activeArea && areaType === activeArea.type) {
    containerClasses.push(classes.Active);
  }

  //<div className={containerClasses.join(' ')} ref={drop} onClick={() => areaClickHandler(area, areaType)}>
  return (
    
    <div className={containerClasses.join(' ')} onClick={() => areaClickHandler(area, areaType)}>
      {area && <div className={classes.Title}>{area.name}</div>}

      {blocks &&
        blocks.map((item, index) => (
          <TemplateAreaItem
            key={item.uuid}
            item={item}
            area={areaType}
            moveItem={moveItem}
            clicked={blockClickHandler}
            onStartDrag={onStartDrag}
            onEndDrag={onEndDrag}
            add={add}
            active={activeBlock && activeBlock.uuid === item.uuid}
          />
        ))}
    </div>
  );
};

export default TemplateArea;
