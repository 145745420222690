import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { IContentTypeItemType } from "../../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData,
  initForm
} from "../../../../../../../shared/utility";

interface IProps extends IContentTypeItemType {}

const ContentTypeItemVisual: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ blockContent, setIsValid }, ref) => {
  const [controlsState, setControlsState] = useState({
    controls: {
      width: {
        elementType: "input",
        elementConfig: {
          label: "Width",
          placeholder: "Width",
          type: "number"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: ""
      },
      maxHeight: {
        elementType: "input",
        elementConfig: {
          label: "Height",
          placeholder: "Height",
          type: "number"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: ""
      }
    },
    formIsValid: true
  });

  useEffect(() => {
    if (blockContent) {
      const newControlsState = initForm(controlsState.controls, blockContent);
      setControlsState(newControlsState);
    }
    // eslint-disable-next-line
  }, [blockContent]);

  useEffect(() => {
    setIsValid(controlsState.formIsValid);
  }, [controlsState.formIsValid, setIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return getFormData(controlsState.controls);
    }
  }));

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  };

  return controlsToFormGroups(controlsState.controls, inputChangedHandler);
});

export default ContentTypeItemVisual;
