import React, { useState, useEffect } from 'react';
import { Alert, Button, Label, CustomInput, Input } from 'reactstrap';
import CustomDialog from '../UI/CustomDialog/CustomDialog';
import { IOrganization, IUser } from '../../interfaces';

import { initForm, validateInput } from '../../shared/utility';

import axios from "../../axios";

interface IProps {
  organization: IOrganization;
	currentUser: IUser;
  updateOrganization: (name: string, organization: IOrganization) => void;

}

const hostname = window && window.location && window.location.hostname;
export const VISMA_API_PATH=hostname==="localhost"?"http://localhost:8081/strongauthentication":"/strongauthentication";


const OrganizationVisma: React.FC<IProps> = ({
  organization,
	currentUser,
  updateOrganization,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [identifier, setIdentifier] = useState("");

  const [state, setState] = useState({
		controls: null,
    formIsValid: false
  });

	const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
		let value = event.target ? event.target.checked : event;
		
    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
		});

		updateOrganization("vismaSign",validation.controls)
  }

	const handleChange2 = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
		let value = event.target ? event.target.value : event;
		
    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
		});

		updateOrganization("vismaSign",validation.controls)
  }


	const onOk = async () => {
	}

    /*
  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value = event.target ? event.target.value : event;

    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
		});

		updateOrganization("vismaSign",validation.controls)
  };
     */

	const vismaSign=organization.integrations.vismaSign;


  useEffect(() => {
    if (organization) {
			let controls = {
        active: {
            elementType: "input",
            elementConfig: {
              label: "Active",
              placeholder: "active",
              type: "text"
            },
            valid: true,
            touched: false,
            value: false,
        },
        
        organizationUuid: {
            elementType: "input",
            elementConfig: {
              label: "OrganizationUuid",
              placeholder: "organizationUuid",
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },

        name: {
            elementType: "input",
            elementConfig: {
              label: "Name",
              placeholder: "Name",
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },

        businessID: {
            elementType: "input",
            elementConfig: {
              label: "BusinessID",
              placeholder: "BusinessID",
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },

        authorized: {
            elementType: "input",
            elementConfig: {
              label: "authorized",
              placeholder: "Authorized",
              type: "text"
            },
            valid: true,
            touched: false,
            value: false,
        },

        postalAddress: {
            elementType: "input",
            elementConfig: {
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },
        postalCode: {
            elementType: "input",
            elementConfig: {
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },
        municipality: {
            elementType: "input",
            elementConfig: {
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },
        firstName: {
            elementType: "input",
            elementConfig: {
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },
        lastName: {
            elementType: "input",
            elementConfig: {
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },
        email: {
            elementType: "input",
            elementConfig: {
              type: "text"
            },
            valid: true,
            touched: false,
            value: "",
        },
				/*
        name: {
            elementType: "input",
            elementConfig: {
              label: "Company Name",
              placeholder: "Company Name",
              type: "text"
            },
            valid: true,
            touched: false,
            value: ""
					},
 				*/ 
        };
				 
      const data = initForm(controls, vismaSign);
      setState({
        controls: data.controls,
        formIsValid: data.formIsValid
			});
		}
  }, [currentUser, organization]);

  const createVisma = () => {
    console.log(state.controls.businessID.value)
    console.log(state.controls.name.value)
    console.log(state.controls.postalAddress.value)
    console.log(state.controls.postalCode.value)
    console.log(state.controls.municipality.value)
    console.log(state.controls.firstName.value)
    console.log(state.controls.lastName.value)
    console.log(state.controls.email.value)

    console.log(identifier)

    const data={
      businessID:state.controls.businessID.value,
      name:state.controls.name.value,
      postalAddress:state.controls.postalAddress.value,
      postalCode:state.controls.postalCode.value,
      municipality:state.controls.municipality.value,
      firstName:state.controls.firstName.value,
      lastName:state.controls.lastName.value,
      email:state.controls.email.value,
      identifier:identifier,
    }

    axios.post(VISMA_API_PATH+"/organization",data)
      .then((resp)=>{
        console.log(resp)
      })

  }

  const invite = (e:any) => {
    e.preventDefault();

    axios.get(VISMA_API_PATH+"/checkorganization/?businessid="+state.controls.businessID.value)
      .then((resp)=>{

        let validation=state;
        validation = validateInput(state.controls, "organizationUuid", resp.data.organizationId);
        validation = validateInput(validation.controls, "name", resp.data.name);
        validation = validateInput(validation.controls, "businessID", resp.data.businessID);
        validation = validateInput(validation.controls, "authorized", resp.data.code==="200");

        setState({
          controls: validation.controls,
          formIsValid: validation.formIsValid
    		});
    
    		updateOrganization("vismaSign",validation.controls)
			});
  }

  return (
		<React.Fragment>
      <CustomDialog
        open={isOpen}
        title="Are you sure?"
        okButtonText="OK"
        onOk={onOk}
        onCancel={() => setIsOpen(false)}
      >
        Removing Visma Sign connection does not affect existing data.
      </CustomDialog>

			<Label>Visma Sign connection</Label>
      <CustomInput
        type="switch"
        id="VismaSign"
        label="Toggle Visma Sign connection"
				onChange={(e:any)=>handleChange(e,"active")}
				checked={state.controls && state.controls.active.value}
      />

    {state.controls && <div>
      {state.controls.organizationUuid.value
        ?<Button onClick={invite} block>Check {state.controls.businessID.value}</Button>
        :<Button onClick={invite}>Invite {state.controls.businessID.value}</Button>}
      {state.controls.authorized.value
        ?<Alert color="success">Integration is active</Alert>
        :<Alert color="danger">Integration is not active</Alert>}

      <Input placeholder="UUID" readOnly={true} type="text" value={state.controls.organizationUuid.value}/>

      <Input placeholder="Y-tunnus" type="text" value={state.controls.businessID.value} onChange={(e:any)=>handleChange2(e,"businessID")}/>
      <Input placeholder="Yritys" type="text" value={state.controls.name.value} onChange={(e:any)=>handleChange2(e,"name")}/>

			<Label>Company Address</Label>
      <Input placeholder="Postal Address" value={state.controls.postalAddress.value} onChange={(e:any)=>handleChange2(e,"postalAddress")} />
      <Input placeholder="Postal Code" value={state.controls.postalCode.value} onChange={(e:any)=>handleChange2(e,"postalCode")} />
      <Input placeholder="Municipality" value={state.controls.municipality.value} onChange={(e:any)=>handleChange2(e,"municipality")} />

      <Label>Contact</Label>
      <Input placeholder="Identifier" value={identifier} onChange={(e:any)=>setIdentifier(e.target.value)}/>
			<Input placeholder="First Name" value={state.controls.firstName.value} onChange={(e:any)=>handleChange2(e,"firstName")} />
			<Input placeholder="Last Name" value={state.controls.lastName.value} onChange={(e:any)=>handleChange2(e,"lastName")} />
      <Input placeholder="Email" value={state.controls.email.value} onChange={(e:any)=>handleChange2(e,"email")} />


      <Button onClick={createVisma} block>Create Visma Account</Button>
   
    </div>}

    </React.Fragment>
  );
};

export default OrganizationVisma;
