import React, { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown
} from "@fortawesome/free-solid-svg-icons";
import SortContext from "./SortContext";

import classes from "./SortIcon.module.scss";

export enum ESortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export interface ISort<T> {
  property: T;
  direction: ESortDirection;
}

interface IProps {
  property: string;
  onSortChange: (newSort:any) => void; //(ev: React.MouseEvent<HTMLElement>, newDirection: ESortDirection, isSelected: boolean) => void;
}

const SortIcon: React.FC<IProps> = ({ property, onSortChange }) => {
  const { currentSort, onSort } = useContext(SortContext);

  const onClick = (
    ev: React.MouseEvent<HTMLElement>,
    newDirection: ESortDirection,
    isSelected: boolean
  ) => {
    let newSort = [...currentSort];

    if (isSelected) {
      let index = newSort.findIndex(item => item.property === property);
      newSort[index].direction = newDirection;
    } else {
      const item = { property, direction: newDirection };

      if (ev.ctrlKey) {
        if (newSort.length === 1) {
          newSort.push(item);
        } else {
          newSort[1] = item;
        }
      } else {
        newSort = [item];
      }
    }

    onSort(newSort);
    onSortChange(newSort[0]);
    
  };

  let element = (
    <span
      onClick={ev => onClick(ev, ESortDirection.DESC, false)}
      className={classes.SortIcon}
    >
      <FontAwesomeIcon icon={faSort} />
    </span>
  );

  if (currentSort) {
    const sort = currentSort.find(item => item.property === property);
    if (sort) {
      if (sort.direction === ESortDirection.ASC) {
        element = (
          <span
            onClick={ev => onClick(ev, ESortDirection.DESC, true)}
            className={`${classes.SortIcon} ${classes.Active} ${
              classes.ActiveAsc
            }`}
          >
            <FontAwesomeIcon icon={faSortUp} />
          </span>
        );
      } else if (sort.direction === ESortDirection.DESC) {
        element = (
          <span
            onClick={ev => onClick(ev, ESortDirection.ASC, true)}
            className={`${classes.SortIcon} ${classes.Active} ${
              classes.ActiveDesc
            }`}
          >
            <FontAwesomeIcon icon={faSortDown} />
          </span>
        );
      }
    }
  }

  return element;
};

export default SortIcon;
