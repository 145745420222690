import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import { IAppState, IEmail, IOffer } from '../../../../../../../interfaces';
import { controlsToFormGroups, defaultInputChangedHandler, initForm, usePrevious } from '../../../../../../../shared/utility';
import * as actions from '../../../../../../../store/actions';
import OfferContext from '../../../../../containers/Offers/Offer/OfferContext';


import classes from "./OfferEmail.module.scss";

interface IStateProps {
  loading: boolean;
  error: string;
}

interface IDispatchProps {
  onGetEmailDefaults: (data: {}) => Promise<IEmail>;
}

interface IProps extends IStateProps, IDispatchProps {}

const OfferEmail: React.FC<IProps> = ({ loading, error, onGetEmailDefaults }) => {
  const [ init, setInit] = useState(true);
  const { offer, setOffer } = useContext(OfferContext);
  const prevOffer: IOffer = usePrevious(offer);

  const [controlsState, setControlsState] = useState({
    controls: {
      title: {
        elementType: "input",
        elementConfig: {
          label: "Email title",
          placeholder: "Email title",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: ""
      },
      body: {
        elementType: "textarea",
        elementConfig: {
          label: "Email body",
          placeholder: "Email body"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: ""
      },
      signature: {
        elementType: "textarea",
        elementConfig: {
          label: "Email signature",
          placeholder: "Email signature"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: ""
      }

    },
    formIsValid: false
  });

  const getEmailDefaults = async (language: string, contactPerson: string, name: string) => {
    const emailDefaults = await onGetEmailDefaults({
      language: language,
      customerContactName: contactPerson,
      organizationName: name
    });

    // email strings in template, don't overwrite with language
    if(offer && offer.userTemplate && offer.userTemplate.settings) {
      if(offer.userTemplate.settings.email_title) { emailDefaults.title = offer.email.title; }
      if(offer.userTemplate.settings.email_body) { emailDefaults.body = offer.email.body; }
      if(offer.userTemplate.settings.email_signature) { emailDefaults.signature = offer.email.signature; }
    }

    if (emailDefaults) {
      const state = Object.assign({}, controlsState);

      state.controls.title.value = emailDefaults.title;
      state.controls.body.value = emailDefaults.body;
      state.controls.signature.value = emailDefaults.signature;

      setControlsState(state);
      setOffer({
        ...offer,
        email: emailDefaults
      });
    }
  }

  // Should only be called when language or contactPerson changes
  useEffect(() => {
    if (init) {
      setTimeout(() => {
        if (offer.email) {
          const data = initForm(controlsState.controls, offer.email);
          setControlsState(data);
        }
        setInit(false);
      }, 100);
      //return;
    }

    if (offer.customer && offer.organization) {
      const { language, contactPerson } = offer.customer;
      const { name } = offer.organization;
      
      if(!prevOffer || (!(prevOffer.email === null && offer.email !== null))) {
      if(prevOffer && prevOffer.customer) {
        // Existing offer, new offer after first update
        
        if(prevOffer.customer.language !== language || prevOffer.customer.contactPerson !== contactPerson) {
          // Called when language or contactperson changes
          getEmailDefaults(language, contactPerson, name);
        }
      } else {
        // New offer, called when customer has valid email
        getEmailDefaults(language, contactPerson, name);
      }
      }
    }
    // eslint-disable-next-line
  }, [offer.customer]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
    /*
    const value = event.target.value;
    const newOffer = {
      ...offer,
      email: { ...offer.email, [controlName]: value }
    };
    setOffer(newOffer);
    */
  };

  const onBlurHandler = () => {
    const newOffer = {
      ...offer,
      email: {
        title: controlsState.controls.title.value,
        body: controlsState.controls.body.value,
        signature: controlsState.controls.signature.value,
      }
    };
    setOffer(newOffer);

  }

  // update email strings on template change
  useEffect(()=>{
    if(offer.userTemplate && offer.userTemplate.settings) {
      const _controls = {...controlsState.controls};
      if(offer.userTemplate.settings.email_title     !== "") _controls.title.value = offer.userTemplate.settings.email_title;
      if(offer.userTemplate.settings.email_body      !== "") _controls.body.value = offer.userTemplate.settings.email_body;
      if(offer.userTemplate.settings.email_signature !== "") _controls.signature.value = offer.userTemplate.settings.email_signature;
      setControlsState({...controlsState,controls:_controls});
    }
  },[offer.userTemplate])

  return (
    <React.Fragment>
      <div className={classes.Controls}>
        {loading
          ? <Spinner color="primary" />
          : controlsToFormGroups(controlsState.controls, inputChangedHandler, onBlurHandler)
        }
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.offers.emailLoading,
    error: state.offers.emailError
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetEmailDefaults: data => dispatch(actions.getEmailDefaults(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferEmail);
