import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../withRouter';

import ListContainer from "../../../../components/ListContainer/ListContainer";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import SortContext from "../../../../components/UI/SortIcon/SortContext";
import { ESortDirection, ISort } from "../../../../components/UI/SortIcon/SortIcon";
import { EPageView, ESearchType } from "../../../../enums";
import { EOfferListType, EOfferSort } from "../../../../enums/offer-enums";
import { IAppState, IOffer } from "../../../../interfaces";
import * as actions from "../../../../store/actions";
import ContractsList from "../../components/Contracts/ContractsList";


interface IStateProps {
  loading: boolean;
  error: string;
  offers: Array<IOffer>;
}

interface IDispatchProps {
  onFindOffers: (sort: Array<ISort<EOfferSort>>) => void;
  onClearOffersState: () => void;
  onSearchOffers: (queryString: string) => void;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {}

const defaultSort: Array<ISort<EOfferSort>> = [
  { property: EOfferSort.CONFIRM_DATE, direction: ESortDirection.DESC }
];

const Contracts: React.FC<IProps> = ({
  onFindOffers,
  onClearOffersState,
  onSearchOffers,
  offers,
  loading,
  history
}) => {
  const [currentSort, setCurrentSort] = useState(defaultSort);

  const [searchString, setSearchString] = useState<string>(null);

  useEffect(() => {
    onFindOffers(defaultSort);
    return () => {
      onClearOffersState();
    };
  }, [onFindOffers, onClearOffersState]);

  // load all contracts...
  useEffect(() => {
    onFindOffers(defaultSort);
  },[offers]);

  const searchMore = () => {
    onFindOffers(defaultSort);
  }

  const onSort = (newSort: Array<ISort<EOfferSort>>) => {
    setCurrentSort(newSort);
    // onClearOffersState();
    // onFindOffers(newSort);
  };

  const searchClickHandler = (id: string) => {
    history.push(`/offers/${id}/${EPageView.PREVIEW}`);
  };

  const searchChangedHandler = (queryString: string) => {
    //onSearchOffers(queryString);
    setSearchString(queryString);
  };

  const onOpenOffer = (id:string) => {
    history.push(`/offers/${id}/${EPageView.PREVIEW}`);
  }
/*        
        onSearch={{
          type: ESearchType.OFFERS,
          searchFunc: searchChangedHandler,
          clicked: searchClickHandler
        }}
*/        

  return (
    <ListContainer>
      <ListHeader
        title="Contracts"
        onSearch2={{
          searchFunc: searchChangedHandler,
        }}

      />
      {loading ? (
        <ListSpinner style={{"height":0,"zIndex":9}}/>
      ) : null}
      
      {/*<button onClick={searchMore}>xx</button>*/}
      <SortContext.Provider value={{ currentSort, onSort }}>
        <ContractsList offers={offers} onOpenOffer={onOpenOffer} searchMore={searchMore} searchString={searchString}/>
      </SortContext.Provider>

    </ListContainer>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.offers.loading,
    error: state.offers.error,
    offers: state.offers.offers
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindOffers: (sort) => dispatch(actions.findOffers(sort, EOfferListType.CONTRACTS)),
    onClearOffersState: () => dispatch(actions.clearOffersState()),
    onSearchOffers: queryString => dispatch(actions.searchOffers(queryString))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contracts);
