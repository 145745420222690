import { IBlockState, IBlockAction } from './../../interfaces/state';
import { ActionTypes } from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState:IBlockState = {
    blocks: null,
    loading: false,
    error: null,
    block: null,
    blockItem: null,
    blockItems: null,
    blockItemLoading: false,
    blockItemError: null
};

const findBlocksStart = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, { error: null, loading: true});
}

const findBlocksSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blocks: action.blocks,
        error: null,
        loading: false,
        block: null
    });
}

const findBlocksFail = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getBlockStart = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, { error: null, loading: true});
}

const getBlockSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        block: action.block,
        error: null,
        loading: false
    });
}

const getBlockFail = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const saveBlockStart = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, { error: null, loading: true});
}

const saveBlockSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const saveBlockFail = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateBlockStart = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, { error: null, loading: true});
}

const updateBlockSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const updateBlockFail = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const deleteBlockStart = (state:IBlockState, action:IBlockAction):IBlockState => {
  return updateObject(state, { error: null, loading: true});
}

const deleteBlockSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
  return updateObject(state, {
      error: null,
      loading: false
  });
}

const deleteBlockFail = (state:IBlockState, action:IBlockAction):IBlockState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

/**
 * BlockItem
 */

const findBlockItemsStart = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, { blockItemError: null, blockItemLoading: true, block: null});
}

const findBlockItemsSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blockItems: action.blockItems,
        blockItemError: null,
        blockItemLoading: false,
        blockItem: null
    });
}

const findBlockItemsFail = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blockItemError: action.error,
        blockItemLoading: false
    });
};

const getBlockItemStart = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, { blockItemError: null, blockItemLoading: true});
}

const getBlockItemSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blockItem: action.blockItem,
        blockItemError: null,
        blockItemLoading: false
    });
}

const getBlockItemFail = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blockItemError: action.error,
        blockItemLoading: false
    });
};

const saveBlockItemStart = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, { blockItemError: null, blockItemLoading: true});
}

const saveBlockItemSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blockItemError: null,
        blockItemLoading: false
    });
}

const saveBlockItemFail = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blockItemError: action.error,
        blockItemLoading: false
    });
};

const updateBlockItemStart = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, { blockItemError: null, blockItemLoading: true});
}

const updateBlockItemSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blockItemError: null,
        blockItemLoading: false
    });
}

const updateBlockItemFail = (state:IBlockState, action:IBlockAction):IBlockState => {
    return updateObject(state, {
        blockItemError: action.error,
        blockItemLoading: false
    });
};

const deleteBlockItemStart = (state:IBlockState, action:IBlockAction):IBlockState => {
  return updateObject(state, { blockItemError: null, blockItemLoading: true});
}

const deleteBlockItemSuccess = (state:IBlockState, action:IBlockAction):IBlockState => {
  return updateObject(state, {
      blockItemError: null,
      blockItemLoading: false
  });
}

const deleteBlockItemFail = (state:IBlockState, action:IBlockAction):IBlockState => {
  return updateObject(state, {
      blockItemError: action.error,
      blockItemLoading: false
  });
};


const reducer = (state:IBlockState = initialState, action:IBlockAction):IBlockState => {
    
    switch (action.type) {
        case ActionTypes.FIND_BLOCKS_START : return findBlocksStart(state, action);
        case ActionTypes.FIND_BLOCKS_SUCCESS : return findBlocksSuccess(state, action);
        case ActionTypes.FIND_BLOCKS_FAIL : return findBlocksFail(state, action);
        case ActionTypes.GET_BLOCK_START : return getBlockStart(state, action);
        case ActionTypes.GET_BLOCK_SUCCESS : return getBlockSuccess(state, action);
        case ActionTypes.GET_BLOCK_FAIL : return getBlockFail(state, action);
        case ActionTypes.SAVE_BLOCK_START : return saveBlockStart(state, action);
        case ActionTypes.SAVE_BLOCK_SUCCESS : return saveBlockSuccess(state, action);
        case ActionTypes.SAVE_BLOCK_FAIL : return saveBlockFail(state, action);
        case ActionTypes.UPDATE_BLOCK_START : return updateBlockStart(state, action);
        case ActionTypes.UPDATE_BLOCK_SUCCESS : return updateBlockSuccess(state, action);
        case ActionTypes.UPDATE_BLOCK_FAIL : return updateBlockFail(state, action);
        case ActionTypes.DELETE_BLOCK_START : return deleteBlockStart(state, action);
        case ActionTypes.DELETE_BLOCK_SUCCESS : return deleteBlockSuccess(state, action);
        case ActionTypes.DELETE_BLOCK_FAIL : return deleteBlockFail(state, action);
        case ActionTypes.FIND_BLOCK_ITEMS_START : return findBlockItemsStart(state, action);
        case ActionTypes.FIND_BLOCK_ITEMS_SUCCESS : return findBlockItemsSuccess(state, action);
        case ActionTypes.FIND_BLOCK_ITEMS_FAIL : return findBlockItemsFail(state, action);
        case ActionTypes.GET_BLOCK_ITEM_START : return getBlockItemStart(state, action);
        case ActionTypes.GET_BLOCK_ITEM_SUCCESS : return getBlockItemSuccess(state, action);
        case ActionTypes.GET_BLOCK_ITEM_FAIL : return getBlockItemFail(state, action);
        case ActionTypes.SAVE_BLOCK_ITEM_START : return saveBlockItemStart(state, action);
        case ActionTypes.SAVE_BLOCK_ITEM_SUCCESS : return saveBlockItemSuccess(state, action);
        case ActionTypes.SAVE_BLOCK_ITEM_FAIL : return saveBlockItemFail(state, action);
        case ActionTypes.UPDATE_BLOCK_ITEM_START : return updateBlockItemStart(state, action);
        case ActionTypes.UPDATE_BLOCK_ITEM_SUCCESS : return updateBlockItemSuccess(state, action);
        case ActionTypes.UPDATE_BLOCK_ITEM_FAIL : return updateBlockItemFail(state, action);
        case ActionTypes.DELETE_BLOCK_ITEM_START : return deleteBlockItemStart(state, action);
        case ActionTypes.DELETE_BLOCK_ITEM_SUCCESS : return deleteBlockItemSuccess(state, action);
        case ActionTypes.DELETE_BLOCK_ITEM_FAIL : return deleteBlockItemFail(state, action);
        default: return state;
    }
}

export default reducer;