import React, { ReactNode } from "react";
import Select, { components } from "react-select";
import { ActionMeta } from "react-select/lib/types";
import { Button } from "reactstrap";
import { StylesConfig } from "react-select/lib/styles";

import classes from "./CustomSelect.module.scss";

interface IOption {
  id: string;
  label?: string;
  image?: ReactNode;
}

interface IProps {
  className?: string;
  onChange: (value: any) => void;
  value: any;
  options: Array<IOption>;
  placeholder?: string;
  isMulti?: boolean;
  valueMapper?: (ids: Array<string>) => any;
  loading?: boolean;
  onEdit?: (id: string) => void;
  fullWidthMultiValues?: boolean;
  onBlur?: () => void;
  invalid?: boolean;
  styles?: StylesConfig;
  isClearable?: boolean;
  isDisabled?: boolean;
}

const CustomSelect: React.FC<IProps> = props => {
  const onChange = (value: any, action: ActionMeta) => {
    if (props.isMulti) {
      let ids = null;
      if (value) {
        ids = value.map((item: any) => item.id);

        if (props.valueMapper) {
          ids = props.valueMapper(ids);
        }
      }
      props.onChange(ids);
    } else {
      props.onChange(value && value.id);
    }
  };

  let value: any = null;
  if (props.isMulti) {
    value =
      props.value &&
      props.value.map((value: any) =>
        props.options.find((option: IOption) => value && option.id === value.id)
      );
  } else {
    value = props.options.find((item: IOption) => props.value && item.id === props.value);
  }

  if (!value) {
    value = "";
  }

  const MultiValueContainer = (containerProps: any) => {
    return (
      <components.MultiValueContainer {...containerProps}>
        {containerProps.children}
        {props.onEdit && (
          <span
            className={classes.EditContainer}
            onMouseDown={(event: React.MouseEvent<HTMLSpanElement>) => {
              event.stopPropagation();
              event.preventDefault();
              return false;
            }}
          >
            <Button
              color="primary"
              onClick={() => props.onEdit(containerProps.data.id)}
            >
              Edit
            </Button>
          </span>
        )}
      </components.MultiValueContainer>
    );
  };

  const CustomOption = (props: any) => {
    return <components.Option {...props}>{props.data.image} {props.children}</components.Option>;
  };

  const classNames = [];
  if (props.fullWidthMultiValues) {
    classNames.push(classes.FullWidthMultiValues);
  }

  if(props.invalid) {
    classNames.push(classes.Invalid);
  }

  return (
    <Select
      styles={props.styles}
      isLoading={props.loading}
      isMulti={props.isMulti}
      classNamePrefix="custom-select"
      className={classNames.join(" ")}
      value={value}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.label}
      onChange={onChange}
      options={props.options}
      placeholder={props.placeholder}
      components={{ MultiValueContainer, Option: CustomOption }}
      onBlur={props.onBlur}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
    />
  );
};

export default CustomSelect;
