import React, { useState } from "react";

import ReactGA from "react-ga";

import { connect } from "react-redux";
import { Alert, Button, Form, Row, Col } from "reactstrap";
import { IAppState } from "../../../interfaces";
import { controlsToFormGroups, validateInput } from "../../../shared/utility";
import * as actions from "../../../store/actions";

import classes from "./Step2.module.scss";

interface IStateProps {
  error?: string | null;
}

interface IDispatchProps {
  onClearError: () => {};
}

interface IProps extends IStateProps, IDispatchProps {
  onSubmit(formData: object): void;
}

const Step2 = (props: IProps) => {
  const [state, setState] = useState({
    controls: {
      firstName: {
        elementType: "input",
        elementConfig: {
          placeholder: "First name",
          type: "text"
        },
        validation: {
          required: true,
          error: "First name is required"
        },
        valid: false,
        touched: false,
        value: ""
      },
      lastName: {
        elementType: "input",
        elementConfig: {
          placeholder: "Last name",
          type: "text"
        },
        validation: {
          required: true,
          error: "Last name is required"
        },
        valid: false,
        touched: false,
        value: ""
      },
      phone: {
        elementType: "input",
        elementConfig: {
          placeholder: "Phone",
          type: "tel"
        },
        validation: {
          required: false,
          error: ""
        },
        valid: true,
        touched: false,
        value: "",
        country: null
      },
      name: {
        elementType: "input",
        elementConfig: {
          placeholder: "Company name",
          type: "text"
        },
        validation: {
          required: true,
          error: "Company name is required"
        },
        valid: false,
        touched: false,
        value: ""
      },
      crm: {
        elementType: "select",
        elementConfig: {
          placeholder: "CRM"
        },
        validation: {
          required: true,
          error: "Please select CRM"
        },
        valid: false,
        touched: false,
        value: "",
        options: [
          {id: "Pipedrive", label: "Pipedrive"},
          {id: "HubSpot CRM", label: "HubSpot CRM"},
          {id: "Salesforce", label: "Salesforce"},
          {id: "Freshsales", label: "Freshsales"},
          {id: "Other", label: "Other"},
          {id: "Still looking for CRM", label: "Still looking for CRM"},
          {id: "Don't have a CRM", label: "Don't have a CRM"}
          // {id: 5, label: "Copper (formerly ProsperWorks)"},
          // {id: 6, label: "Insightly"},
          // {id: 7, label: "Microsoft Dynamics"},
          // {id: 8, label: "Nutshell"},
          // {id: 9, label: "Zendesk Sell (formerly Base)"},
          // {id: 10, label: "Sugar CRM"},
          // {id: 11, label: "Zoho CRM"},
        ]
      },
      teamSize: {
        elementType: "select",
        elementConfig: {
          placeholder: "Estimated number of users",
          type: "text"
        },
        validation: {
          required: true,
          error: "Please select number of users"
        },
        valid: false,
        touched: false,
        value: "",
        options: [
          {id: "1-2", label: "1-2"},
          {id: "3-5", label: "3-5"},
          {id: "6-10", label: "6-10"},
          {id: "11-50", label: "11-50"},
          {id: "51+", label: "51+"}
        ]
      },
    },
    controlsIsValid: false,
    errors: []
  });

  const [termsAccepted, setTermsAccepted] = useState(false);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value: any = event;
    if (event.target) {
      value = event.target.value;
    }

    const validation = validateInput(state.controls, controlName, value);

    setState({
      ...state,
      controls: validation.controls,
      controlsIsValid: validation.formIsValid
    });
  };  
  
  const phoneChangeHandler = (
    valid: boolean,
    value: string,
    country: any
  ) => {
    const { phone } = state.controls;
    const phoneCtrl = {
      ...phone,
        // validation: {
        //   required: true,
        //   error: value ? "Please enter a valid phone number" : "Phone number is required" 
        // },
        value,
        // valid,
        touched: true,
        country: country
    }
    setState({
      ...state,
      controls: {...state.controls, phone: phoneCtrl},
      controlsIsValid: state.controlsIsValid
    });
  };

  const onPhoneBlur = () => {
    const { phone: {value} } = state.controls;
    setState({
      ...state,
      controls: {...state.controls, phone: {...phone, value: value.replace(/^0/, '')}}
    })
  }

  const getErrors = () => {
    const errors = Object.entries(state.controls).reduce((o, [key, obj]) => {
      if (!obj.valid) {
        return [...o, obj.validation.error]
      }
      return o
    }, []);
    setState({
      ...state,
      errors
    })
    return errors.length > 0
  }

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { onSubmit } = props;
    const { phone } = state.controls;
    const hasErrors = getErrors();
    // Creates simplified object from control values, eg. {firstName: "Firstname", ...}
    const values = Object.entries(state.controls).reduce((o, [key, obj]) => (
      {...o, [key]: obj.value}
    ), {});

    if (state.controlsIsValid && !hasErrors) {
      let phoneString = phone.value;
      if (phone && phone.country && phone.country.dialCode) {
        phoneString = `+${phone.country.dialCode}${phone.value}`;
      }
      
      ReactGA.event({
        category: 'User',
        action: 'Submit registration'
      });

      onSubmit({ ...values, phone: phoneString });
    }
  };

  const { error } = props;
  const { errors } = state;
  const {firstName, lastName, phone, ...controls} = state.controls;

  return (
    <Form onSubmit={submitHandler}>
      <div className={classes.Inputs}>
        <Row>
          <Col md={6}>
            {controlsToFormGroups({firstName}, inputChangedHandler)}
          </Col>
          <Col md={6}>
            {controlsToFormGroups({lastName}, inputChangedHandler)}
          </Col>
          <Col md={12}>
            {controlsToFormGroups({phone}, phoneChangeHandler, onPhoneBlur)}
          </Col>
        </Row>
        {controlsToFormGroups(controls, inputChangedHandler)}
        <div onClick={() => setTermsAccepted(!termsAccepted)}>
          <p>
            <input type="checkbox" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />{' '}
            By signing up I agree to the
            <a className={classes.terms} rel="noopener noreferrer" target="_blank" href="https://www.click2contract.com/en/terms/"> Terms of Use </a>
            and acknowledge <a className={classes.terms} rel="noopener noreferrer" target="_blank" href="https://www.click2contract.com/en/privacy-policy/"> Privacy Policy</a>
          </p>
        </div>
      </div>
      {error && (
        <Alert className={classes.ErrorMessage} color="danger">
          {error}
        </Alert>
      )}
      {errors.length > 0 && (
        errors.map(e => (
          <Alert key={e} className={classes.ErrorMessage} color="danger">
            {e}
          </Alert> 
        ))
      )}
      <div className={classes.ButtonContainer}>
        <Button type="submit" color="primary" className={classes.Button} disabled={!termsAccepted}>
          Done
        </Button>
      </div>
      {/* <div className={classes.LoginQuestion}>
        <h6>Already have an account? <Link to="/login">Log in</Link></h6>
      </div> */}
    </Form>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    error: state.users.error,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onClearError: () => dispatch(actions.clearError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2);
