import { ActionTypes } from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { IInvoiceState, IInvoiceAction } from "../../interfaces";

const initialState:IInvoiceState = {
  loading: false,
  error: null,
  invoices: null,
  invoice: null,
  upcomingInvoice: null
};

const findInvoicesStart = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, { error: null, loading: true});
}

const findInvoicesSuccess = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, {
      error: null,
      loading: false,
      invoices: action.invoices
  });
}

const findInvoicesFail = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const getUpcomingInvoiceStart = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, { error: null, loading: true});
}

const getUpcomingInvoiceSuccess = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, {
      error: null,
      loading: false,
      upcomingInvoice: action.upcomingInvoice
  });
}

const getUpcomingInvoiceFail = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const getInvoiceStart = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, { error: null, loading: true, invoice: null });
}

const getInvoiceSuccess = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, {
      error: null,
      loading: false,
      invoice: action.invoice
  });
}

const getInvoiceFail = (state:IInvoiceState, action:IInvoiceAction):IInvoiceState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const reducer = (state:IInvoiceState = initialState, action:IInvoiceAction):IInvoiceState => {
  switch (action.type) {
      case ActionTypes.FIND_INVOICES_START : return findInvoicesStart(state, action);
      case ActionTypes.FIND_INVOICES_SUCCESS : return findInvoicesSuccess(state, action);
      case ActionTypes.FIND_INVOICES_FAIL : return findInvoicesFail(state, action);
      case ActionTypes.GET_UPCOMING_INVOICE_START : return getUpcomingInvoiceStart(state, action);
      case ActionTypes.GET_UPCOMING_INVOICE_SUCCESS : return getUpcomingInvoiceSuccess(state, action);
      case ActionTypes.GET_UPCOMING_INVOICE_FAIL : return getUpcomingInvoiceFail(state, action);
      case ActionTypes.GET_INVOICE_START : return getInvoiceStart(state, action);
      case ActionTypes.GET_INVOICE_SUCCESS : return getInvoiceSuccess(state, action);
      case ActionTypes.GET_INVOICE_FAIL : return getInvoiceFail(state, action);
      default: return state;
  }
}

export default reducer;
