import React from "react";

interface IProps {
  id: string;
  width?: string;
  height?: string;
}

const defaultWidth = 500;
const defaultHeight = 315;

const EmbedYoutube: React.FC<IProps> = ({ id, width = defaultWidth, height = defaultHeight}) => {
  if (!id) return null;
  id = id.replace(/[^\w\s\-_!?]/g, "");
  return (
    <iframe
      src={`https://www.youtube.com/embed/${id}?rel=0&showinfo=0&loop=1&playlist=${id}&modestbranding=1`}
      //width="560"
      //height="315"
      width="100%"
      height="400px"
      frameBorder="0"
      allowFullScreen
      title="Youtube"
    ></iframe>
  );
};

export default EmbedYoutube;
