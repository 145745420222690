import { Dispatch } from "redux";
import { ActionTypes } from "./actionTypes";
import { ISubscriptionAction, ISubscriptionDetail } from "../../interfaces";
import axios from "../../axios";

export const getCurrentSubscriptionStart = (): ISubscriptionAction => {
  return {
    type: ActionTypes.GET_CURRENT_SUBSCRIPTION_START
  };
};

export const getCurrentSubscriptionSuccess = (subscription: ISubscriptionDetail): ISubscriptionAction => {
  return {
    type: ActionTypes.GET_CURRENT_SUBSCRIPTION_SUCCESS,
    subscription
  };
};

export const getCurrentSubscriptionFail = (error: string): ISubscriptionAction => {
  return {
    type: ActionTypes.GET_CURRENT_SUBSCRIPTION_FAIL,
    error
  };
};

export const getCurrentSubscription = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getCurrentSubscriptionStart());
    try {
      const res = await axios.get("/json/billing/currentsubscription");
      const subscription = res.data;
      dispatch(getCurrentSubscriptionSuccess(subscription));
      return subscription;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getCurrentSubscriptionFail(error.response.data));
      return null;
    }
  };
};

export const saveSubscriptionStart = (): ISubscriptionAction => {
  return {
    type: ActionTypes.SAVE_SUBSCRIPTION_START
  };
};

export const saveSubscriptionSuccess = (subscription: ISubscriptionDetail): ISubscriptionAction => {
  return {
    type: ActionTypes.SAVE_SUBSCRIPTION_SUCCESS,
    subscription
  };
};

export const saveSubscriptionFail = (error: string): ISubscriptionAction => {
  return {
    type: ActionTypes.SAVE_SUBSCRIPTION_FAIL,
    error
  };
};

export const saveSubscription = (data: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveSubscriptionStart());
    try {
      const res = await axios.post("/json/billing/savesubscription", data);
      const subscription = res.data;
      dispatch(saveSubscriptionSuccess(subscription));
      return subscription;
      //@ts-ignore
    } catch (error: any) {
      dispatch(saveSubscriptionFail(error.response.data));
      return null;
    }
  };
};

export const cancelSubscriptionStart = (): ISubscriptionAction => {
  return {
    type: ActionTypes.CANCEL_SUBSCRIPTION_START
  };
};

export const cancelSubscriptionSuccess = (): ISubscriptionAction => {
  return {
    type: ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS
  };
};

export const cancelSubscriptionFail = (error: string): ISubscriptionAction => {
  return {
    type: ActionTypes.CANCEL_SUBSCRIPTION_FAIL,
    error
  };
};

export const cancelSubscription = () => {
  return async (dispatch: Dispatch) => {
    dispatch(cancelSubscriptionStart());
    try {
      await axios.post("/json/billing/cancelsubscription");
      dispatch(cancelSubscriptionSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(cancelSubscriptionFail(error.response.data));
      return false;
    }
  };
};

export const updateSubscriptionStart = (): ISubscriptionAction => {
  return {
    type: ActionTypes.UPDATE_SUBSCRIPTION_START
  };
};

export const updateSubscriptionSuccess = (subscription: ISubscriptionDetail): ISubscriptionAction => {
  return {
    type: ActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
    subscription
  };
};

export const updateSubscriptionFail = (error: string): ISubscriptionAction => {
  return {
    type: ActionTypes.UPDATE_SUBSCRIPTION_FAIL,
    error
  };
};

export const updateSubscription = (data: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateSubscriptionStart());
    try {
      const res = await axios.post("/json/billing/updatesubscription", data);
      const subscription = res.data;
      dispatch(updateSubscriptionSuccess(subscription));
      return subscription;
      //@ts-ignore
    } catch (error: any) {
      dispatch(updateSubscriptionFail(error.response.data));
      return null;
    }
  };
};
