import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Button } from 'reactstrap';

import CustomButtonGroup from '../../../../components/UI/CustomButtonGroup/CustomButtonGroup';
import IconButton from '../../../../components/UI/IconButton/IconButton';
import { EOfferStatus, EPageView } from '../../../../enums';
import { hasOfferStatus } from '../../../../shared/offer-utils';
import { IOffer } from '../../../../interfaces';
//import OfferAcceptedElseDialog from '../../components/Offers/Offer/OfferPreviewSidebar/OfferPreviewSidebarNav/OfferPreviewSidebarHistory/OfferAcceptedElseDialog/OfferAcceptedElseDialog';
//import CustomDialog from '../../../../components/UI/CustomDialog/CustomDialog';
import { copyStringToClipboard } from '../../../../shared/utility';
//import { copyEmailToClipboard } from '../../shared/utility';

//import { integrationParser } from '../../../../shared/integrations';

//import axios from 'axios';

import { VISMA_API_PATH } from "../../containers/Offers/OfferAccept/VismaSign";

//import { config } from "../../../../constants";

//const PROCOUNTOR_URL = "https://integrations-dot-c2ctest-203405.ey.r.appspot.com";

//import classes from "./OfferFooter.module.scss";
import classes from "../../components/Offers/Offer/OfferFooter/OfferFooter.module.scss";

import eclasses from "./Embedded.module.scss";

interface IProps {
  offer: IOffer;
  pageView: EPageView;
  onViewChanged: (pageView: EPageView) => void;
  onSaveDraft: () => void;
  onSave: () => void;
  onEdit: (pageView: EPageView) => void;
  saveDraftText: string;
  status: EOfferStatus;
  isEdit: boolean;

  isOfferSent: boolean;

  offerDone: boolean;
  // onCopy: () => void;
  // onDelete: () => void;
  isUpdate: boolean;
  // onMarkOfferAsAccepted: (statusDetails: string) => void;
  // onToggleArchiveOffer: () => void;
  // onTerminateOffer: () => void;
  // integrations?:IIntegrations; 

  isSentBefore: boolean;
}

const OfferFooter: React.FC<IProps> = ({
  offer,
  pageView,
  onViewChanged,
  onSaveDraft,
  onSave,
  onEdit,
  saveDraftText,
  status,
  isEdit,

  isOfferSent,
  offerDone,
  //onDelete,
  //onCopy,
  isUpdate,
  //onMarkOfferAsAccepted,
  //onToggleArchiveOffer,
  //onTerminateOffer,
  //integrations,

  isSentBefore,
}) => {

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  //const [showOfferAcceptedDialog, setShowOfferAcceptedDialog] = useState(false);
  //const [confirmArchive, setConfirmArchive] = useState(false);
  //const [showTerminateDialog, setShowTerminateDialog] = useState(false);

  // const [integrationDialog, setIntegrationDialog] = useState(false);
  // const [integrationMessage, setIntegrationMessage] = useState("");

  //let taxType="EXCLUSIVE";

  // const filterProducts = (blocks:any) => {

  //   const xx=(blocks || []).reduce((resp:any,ob:any)=>{
  //     return [...resp,...(ob.content || []).filter((c:any)=>c.contentType==="PRODUCTS").reduce((r:any,c:any)=>{
  //       if(c.tax==="INCLUSIVE") taxType="INCLUSIVE";
  //       return [...r,...(c.products || []).filter((p:any)=>(p.library===false && (p.optional===false || p.optionalSelected===true)))]
  //     },[])]
  //   },[])

  //   return xx
  // }

  // const send2procountor = () => {
  //   setIntegrationDialog(true);
  //   setIntegrationMessage("");
    
  //   const name=offer.customer.name
  //   //const lang=offer.customer.language

  //   const prods=[
  //     ...filterProducts(offer.userTemplate.header.blocks),
  //     ...filterProducts(offer.userTemplate.main.blocks),
  //     ...filterProducts(offer.userTemplate.footer.blocks),
  //   ]

  //   const data={
  //     integration:integrations.procountor,
  //     buyer:{
  //       name:name,
  //       businessID:offer.customer.businessID || "",
  //       street:offer.customer.address || "",
  //       zip:offer.customer.zip || "",
  //       city:offer.customer.city || "",
  //     },
  //     invoice:{
  //       taxType:taxType,
  //     },
  //     rows:prods.map((ob)=>({
  //       item:ob.item,
  //       price:ob.unitPrice,
  //       quantity:ob.quantity,
  //       unit:ob.unit,
  //       vat:ob.vat,
  //       discount:ob.discount,
  //       description:ob.description,
  //     }))
  //   }

  //   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  //   axios.post(config.PROCOUNTOR.URL+'/procountor',data).then((resp)=>{
  //     if(resp.data.status===200) {
  //       setIntegrationDialog(false);
  //     }
  //     else {
  //       //console.log("RESP",resp)
  //       setIntegrationMessage(resp.data.data);
  //     }
  //   })
  //   .catch((resp)=> {
  //     //console.log("error",resp);
  //   })
  // }

  // const send2netvisor = () => {
  //   setIntegrationDialog(true);
  //   setIntegrationMessage("");
    
  //   const name=offer.customer.name
  //   //const lang=offer.customer.language

  //   const prods=[
  //     ...filterProducts(offer.userTemplate.header.blocks),
  //     ...filterProducts(offer.userTemplate.main.blocks),
  //     ...filterProducts(offer.userTemplate.footer.blocks),
  //   ]

  //   const data={
  //     integration:integrations.netvisor,
  //     buyer:{
  //       name:name,
  //       businessID:offer.customer.businessID || "",
  //       street:offer.customer.address || "",
  //       zip:offer.customer.zip || "",
  //       city:offer.customer.city || "",
  //     },
  //     invoice:{
  //       taxType:taxType,
  //     },
  //     rows:prods.map((ob)=>({
  //       item:ob.item,
  //       price:ob.unitPrice,
  //       quantity:ob.quantity,
  //       unit:ob.unit,
  //       vat:ob.vat,
  //       discount:ob.discount,
  //       description:ob.description,
  //     }))
  //   }

  //   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  //   axios.post(config.NETVISOR.URL,data).then((resp)=>{
  //     if(resp.data.status===200) {
  //       setIntegrationDialog(false);
  //     }
  //     else {
  //       //console.log("RESP",resp)
  //       setIntegrationMessage(resp.data.data);
  //     }
  //   })
  //   .catch((resp)=> {
  //     //console.log("error",resp);
  //     setIntegrationMessage(JSON.stringify(resp));

  //   })
  // }


  // let offerArchive = {
  //   title: "Remove from funnel",
  //   okButtonText: "Archive proposal",
  //   content: `Are you sure you want to archive proposal?`
  // };
  // if (offer.archived) {
  //   offerArchive = {
  //     title: "Unarchive proposal",
  //     okButtonText: "Unarchive proposal",
  //     content: `Are you sure you want to unarchive proposal?`
  //   };
  // };

  const items = [
    {
      type: EPageView.EDIT,
      text: "Edit"
    }
  ];

  if(offer.userTemplate) {
    items.push(
      {
        type: EPageView.PREVIEW,
        text: "Preview"
      }
    );
  }

  const isSent = hasOfferStatus(
    status,
    EOfferStatus.SENT,
    EOfferStatus.OPENED,
    EOfferStatus.DECLINED,
    EOfferStatus.CONFIRMED
  );
/*
  useEffect(()=>{
    setButtonsDisabled(isOfferSent);
  },[isOfferSent])
*/
  //const saveText = isSent ? "Resend proposal" : "Send proposal";

  //let offerActions = null;

  const print2PDF = () => {
  	const sid=offer.signedPDF?"sid="+offer.signedPDF:"id="+offer.strongAuthentication
  	offer.strongAuthentication
  			?window.open(VISMA_API_PATH+"/getfile/?"+sid)
  			:window.open(offer.printLink, "_blank");
  }

	// // //id="+offer.strongAuthentication)

  //const integrationData=integrationParser(offer.offerIntegrationData);


/*
	 if (isUpdate) {
    offerActions = (
      <CustomDropdown>
        
        {status === EOfferStatus.DRAFT ? (
          <DropdownItem onClick={() => onDelete()}>
            Delete draft
          </DropdownItem>
        ) : null}
        {!isEdit && offer.hasAttachments && (
          <DropdownItem onClick={() => window.open(offer.downloadLink, "_blank")}>
            Download attachments
          </DropdownItem>
        )}
        {!isEdit && offer.printLink && (
          <DropdownItem onClick={print2PDF}>
            Print to PDF
          </DropdownItem>
        )}
        <DropdownItem onClick={() => onCopy()}>
          Copy as new proposal
        </DropdownItem>
        
        {!isEdit && (
          <React.Fragment>
            
            {!offerDone && (
              <DropdownItem onClick={() => setShowOfferAcceptedDialog(true)}>
                Mark as accepted elsewhere
              </DropdownItem>
            )}
            {offer.link && (
              <DropdownItem onClick={() => copyStringToClipboard(offer.link)}>
                {(offer.status === EOfferStatus.CONFIRMED || offer.status === EOfferStatus.TERMINATED) ? "Copy contract link to clipboard" : "Copy proposal link to clipboard"}
              </DropdownItem>
            )}
            

            {offer.link && (
              <DropdownItem
                onClick={() => copyEmailToClipboard(offer.link)}
                href="mailto:seppo+asiakas@click2contract.com?subject=hei&body=paste email content from clipboard"
                target="_blank"
              >
                Copy email to clipboard
              </DropdownItem>
            )}


            {!offerDone && (
              <DropdownItem onClick={() => setConfirmArchive(true)}>
                {offerArchive.title}
              </DropdownItem>
            )}
          </React.Fragment>
        )}

        {integrations && integrations.procountor.active &&
          <DropdownItem onClick={() => send2procountor()}>
            Send to Procountor
        </DropdownItem>}

        {integrations && integrations.netvisor.active &&
          <DropdownItem onClick={() => send2netvisor()}>
            Send to Netvisor
        </DropdownItem>}

        {integrationData.HUBSPOT &&
        <DropdownItem target="_blank" href={"https://app.hubspot.com/contacts/"+integrationData.HUBSPOT.portalId+"/deal/"+integrationData.HUBSPOT.dealId+"/"}>
            Open in Hubspot
        </DropdownItem>}

      </CustomDropdown>
    )
  }
*/
  let saveDraftButton = null;

  //let classNames = [classes.Container, classes.Draft];

  // switch (status) {
  //   case EOfferStatus.DRAFT:
  //     classNames.push(classes.Draft)
  //     break;
  //   case EOfferStatus.SENT:
  //   case EOfferStatus.OPENED:
  //   case EOfferStatus.DECLINED:
  //     classNames.push(classes.Sent)
  //     break;
  //   default:
  //     classNames.push(classes.Draft)
  //     break;
  // }


  if (!isSent && !isSentBefore) {
    saveDraftButton = (
      <Button disabled={buttonsDisabled} color="link" onClick={()=>{/*setButtonsDisabled(true);*/ onSaveDraft();}}>
        {saveDraftText}
      </Button>
    );
  }
  else {
    saveDraftButton = (
      <Button disabled={buttonsDisabled} color="link" onClick={()=>copyStringToClipboard(offer.link)}>
        {(offer.status === EOfferStatus.CONFIRMED || offer.status === EOfferStatus.TERMINATED) ? "Copy contract link to clipboard" : "Copy proposal link to clipboard"}

      </Button>
    );
  }


  return (
    <React.Fragment>
    {/*
      <OfferAcceptedElseDialog
        isOpen={showOfferAcceptedDialog}
        setIsOpen={setShowOfferAcceptedDialog}
        onSave={onMarkOfferAsAccepted}
      />
    */}

{/*
      <CustomDialog
        loading={false}
        onOk={() => {
          setIntegrationDialog(false);
        }}
        okButtonText="Close"
        open={integrationDialog}
        hideButtons={integrationMessage===""}
        title="Sending"
        closable={false}
      >
        <pre>
          {integrationMessage}
        </pre>
      </CustomDialog>
*/}
{/*
      <CustomDialog
        loading={false}
        onOk={() => {
          onToggleArchiveOffer();
          setConfirmArchive(false);
        }}
        onCancel={() => setConfirmArchive(false)}
        open={confirmArchive}
        title={offerArchive.title}
        okButtonText={offerArchive.okButtonText}
        cancelButtonText="Cancel"
      >
        {offerArchive.content}
      </CustomDialog>
*/}

{/*
      <CustomDialog
        loading={false}
        onOk={() => {
          onTerminateOffer();
          setShowTerminateDialog(false);
        }}
        onCancel={() => setShowTerminateDialog(false)}
        open={showTerminateDialog}
        title={"Are you sure?"}
        okButtonText={"OK"}
        cancelButtonText="Cancel"
      >
        Do you want to mark the contract as terminated?
        <br />
        Please note that this will have no legal effect since the customer will not be informed.
        <br />
        A terminated contract will still be available in Contracts.
      </CustomDialog>
*/}
      <div className={eclasses.Container}>
        <div className={isEdit ? classes.View : classes.None}>
          {/*isEdit && status !==EOfferStatus.CONFIRMED && (*/}

          {isEdit && status === EOfferStatus.DRAFT && (
            <CustomButtonGroup
              items={items}
              clicked={onViewChanged}
              selected={pageView}
            />
          )}
        </div>

        <div className={classes.Buttons}>
          {/*offerActions*/}
          {offerDone && offer.printLink && (
            <Button color="success" onClick={print2PDF}>
              Print to PDF
            </Button>
          )}


          {!offerDone && (
            <React.Fragment>
              {(() => {
                switch (pageView) {
                  case EPageView.EDIT:
                    return (
                      <React.Fragment>
                        {saveDraftButton}
                        {/*
                        <Button
                          color="info"
                          onClick={() => onViewChanged(EPageView.PREVIEW)}
                        >
                          To sending
                        </Button>
                      */}
                      </React.Fragment>
                    );
                  case EPageView.PREVIEW:
                    return offer && offer.customer && offer.customer.email ? (
                      <React.Fragment>
                        {saveDraftButton}
                        {isEdit && !isSent && (
                          <Button disabled={isOfferSent} color="info" onClick={()=>{setButtonsDisabled(true);onSave();}}>
                            Send proposal
                          </Button>
                        )}
                      </React.Fragment> || null
                    ): <Button disabled color="info">Email missing</Button>;
                  default:
                    return null;
                }
              })()}

              {/*!isEdit && (*/}

              {status !== EOfferStatus.DRAFT && (
                <React.Fragment>
                  <Button
                    onClick={() => onEdit(EPageView.EDIT)}
                    color="info"
                    className={classes.ResendButton}
                  >
                    Edit
                </Button>

                  <IconButton
                    onClick={() => onEdit(EPageView.PREVIEW)}
                    color="info"
                    icon={faEnvelope}
                    className={classes.ResendButton}
                  >
                    Resend
                </IconButton>
                </React.Fragment>

              )}

            </React.Fragment>
          )}
		
        </div>
      </div>
    </React.Fragment>
  );
};

export default OfferFooter;
