import React, { useRef } from 'react';
import { connect } from 'react-redux';

import CustomDialog from '../../../../../../components/UI/CustomDialog/CustomDialog';
import { IAppState, IBlock, IBlockItem } from '../../../../../../interfaces';
import BlockItemEdit from '../../../Blocks/Block/BlockItems/BlockItemEdit/BlockItemEdit';

interface IStateProps {
  block: IBlock;
  loading: boolean;
}

export interface IOfferBlockItemModal {
  open: boolean;
  id: string;
  blockId: string;
  blockItem: IBlockItem;
}

interface IProps extends IStateProps {
  blockItemModal: IOfferBlockItemModal;
  closeSidebar: () => void;
  onSave: (id: string, blockItem: IBlockItem) => void;
}

export const defaultBlockItemModal: IOfferBlockItemModal = {
  open: false,
  id: null,
  blockId: null,
  blockItem: null
};

const OfferBlockItemModal: React.FC<IProps> = ({
  blockItemModal = defaultBlockItemModal,
  closeSidebar,
  onSave,
  block,
  loading
}) => {
  const contentRef = useRef(null);

  return (
    <CustomDialog
      loading={false}
      onOk={() => {
        let data = { ...contentRef.current.getData() };
        // Fixes replacing the newest block when adding new
        if (!blockItemModal.blockItem) {
          data = { ...data, id: null }
        }

        onSave(blockItemModal.id, {
          ...(blockItemModal.blockItem || {}),
          ...data
        })
      }}
      okButtonText={"Save"}
      onCancel={closeSidebar}
      open={blockItemModal.open}
      title={
        loading
          ? "Loading..."
          : block && block.name
          ? block.name
          : blockItemModal.blockItem && blockItemModal.blockItem.name
          ? blockItemModal.blockItem.name
          : null
      }
      isValid={true}
      size="xl"
    >
      {(blockItemModal.blockId || blockItemModal.blockItem) && (
        <BlockItemEdit
          blockId={blockItemModal.blockId}
          blockItemId={null}
          blockItemProp={
            blockItemModal.blockItem && { ...blockItemModal.blockItem }
          }
          ref={contentRef}
        />
      )}
    </CustomDialog>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    block: state.blocks.block,
    loading: state.blocks.loading || state.blocks.blockItemLoading
  };
};

export default connect(mapStateToProps)(OfferBlockItemModal);
