import React, { useState, useEffect } from "react";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../withRouter';

import { IOrganization, IAppState } from "../../../../interfaces";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import OrganizationsList from "../../components/Organizations/OrganizationsList";

import { EOfferListType } from '../../../../enums/offer-enums';

import axios from "../../../../axios";

interface IStateProps {
	organizations: Array<IOrganization> | null;
  error: string | null;
  loading: boolean;
  success: boolean;
}

interface IDispatchProps {
	onFindOrganizations: () => {};
	onFindOffers: (extra:string) => {};
}

interface IProps
  extends IStateProps,
  IDispatchProps,
  RouteComponentProps { }

const Organizations: React.FC<IProps> = props => {
  const { organizations, onFindOrganizations, history, loading } = props;

	const [offers, setOffers]=useState<any>({});

	useEffect(() => {
			onFindOrganizations();
  }, [onFindOrganizations]);

  const addOrganization = () => {
    history.push("/admin/organizations/add");
  };

	// load offer information
	useEffect(() => {
		if(organizations) {
			/*
			const OFFERS_PATH='json/offers2';

			let tempOffers={};

			let today = new Date();
			let priorDate = new Date(new Date().setDate(today.getDate() - 30));
			let stats:{[id:string]:any} = {};

			const loopOrganizations=organizations.map((ob)=>ob.id);
	
			const xloop = (id: string) => {
				axios.get(OFFERS_PATH+`/list?listType=CONTRACTS&cuuid=${id}`)
					.then((resp)=>{
						tempOffers={...tempOffers,[id]:resp.data}
						resp.data.forEach((x:any)=>{
							if(!(x.status in stats)) {
								stats[x.status] = 0;
								stats[x.status+"-30"] = 0;
							} 

							const ts = x.created.split("-");
							const d = new Date(x.created); //ts[0],ts[1],ts[2]);
							//console.log(d,"---",priorDate);
							if(d>priorDate) {
								stats[x.status+"-30"]++;
							}

							stats[x.status]++;
							//console.log(x.status,x.created);
						});
						const next=loopOrganizations.pop();
						if(next) {
							setOffers(tempOffers)
							xloop(next)
						}
						else {
							setOffers(tempOffers);
						}
						console.log(stats);
					})
					.catch((resp)=> {})
			}
	
			xloop(loopOrganizations.pop());
			*/
		}
	},[organizations]);


  return (
    <div>
      <ListHeader
        title="Organizations"
        onCreate={{
          text: "Create new organization",
          clicked: () => { addOrganization() }
        }}
      />
      {loading ? <ListSpinner /> : <OrganizationsList {...props} organizations={organizations} offers={offers}/>}
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
		organizations: state.organizations.organizations,
    loading: state.organizations.loading,
    success: state.organizations.success,
    error: state.organizations.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {

  return {
		onFindOrganizations: () => dispatch(actions.findOrganizations()),
    onFindOffers: (extra) => dispatch(actions.findOffers(null,EOfferListType.CONTRACTS,extra))

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organizations);
