import React from 'react';

import CustomDialog from '../../../../../../components/UI/CustomDialog/CustomDialog';
import OfferAuditLog from './OfferAuditLog/OfferAuditLog';

interface IProps {
  token: string;
  id: string;
  isOpen: boolean;
  onClose: () => void;
  printable: boolean;
}

const OfferAuditLogsDialog: React.FC<IProps> = ({
  token,
  id,
  isOpen,
  onClose,
  printable
}) => {
  return printable ? (
    <OfferAuditLog token={token} id={id} printable />
  )
  : (
    <CustomDialog
      open={isOpen}
      onOk={onClose}
      okButtonText={"Close"}
      closable
      title="Audit Log"
    >
      <OfferAuditLog token={token} id={id} />
    </CustomDialog>
  );
};

export default OfferAuditLogsDialog;
