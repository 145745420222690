import React, { useEffect, useState } from 'react';

import { IUserTemplate, IOrganization } from '../../../../../../../../interfaces';
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData,
  initForm,
} from '../../../../../../../../shared/utility';

import classes from "../../../../../../../../components/UI/SidebarCollapse/SidebarCollapse.module.scss";
interface IProps {
  userTemplate: IUserTemplate;
	update: (data:Object) => void;
	organization: IOrganization;
}


const initControls = {
  controls: {
    expiryDays: {
      elementType: "input",
      elementConfig: {
        label: "Expiry days",
        placeholder: "Expiry days",
        type: "number"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: 30
    },
  },
  formIsValid: false
}

const TemplateSidebarEditTemplate: React.FC<IProps> = ({ userTemplate, update, organization }) => {
  const [controlsState, setControlsState] = useState(initControls);

  useEffect(() => {
    setControlsState(initForm(initControls.controls, {...userTemplate, ...userTemplate.settings}));
    // eslint-disable-next-line
  }, [])

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(event, controlName, controlsState, setControlsState);
  };

  const onBlurHandler = () => {
    update(getFormData(controlsState.controls));
  }

  return (
    <React.Fragment>
      <hr/>
      <div className={classes.Header}>
        <p>Parameters</p>
      </div>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler, onBlurHandler)}
    </React.Fragment>
  );
};

export default TemplateSidebarEditTemplate;
