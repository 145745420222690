import { Dispatch } from 'redux';

import axios from '../../axios';
import { IEmbeddedAction } from '../../interfaces';
import { ActionTypes } from './actionTypes';


export const getEmbeddedStart = ():IEmbeddedAction => {
    return {
        type: ActionTypes.GET_EMBEDDED_START
    };
};

export const getEmbeddedSuccess = (embedded:string):IEmbeddedAction => {
    return {
        type: ActionTypes.GET_EMBEDDED_SUCCESS,
        embedded: embedded
    };
};

export const getEmbeddedFail = (error:string):IEmbeddedAction => {
    return {
        type: ActionTypes.GET_EMBEDDED_FAIL,
        error
    }
};

export const getEmbedded = (id:string, func:string, params: string="") => {
    return async (dispatch:Dispatch) => {
        dispatch(getEmbeddedStart());
        try {
            const res = await axios.get(`/integration/${id}/${func}${params}`);
            const embedded:any = res.data;

            dispatch(getEmbeddedSuccess(embedded));
            return embedded;
            //@ts-ignore
        } catch (error: any) {
            dispatch(getEmbeddedFail(error.response.data));
        }
        
    };
};

export const postEmbeddedStart = (): IEmbeddedAction => {
  return {
    type: ActionTypes.POST_EMBEDDED_START
  };
};

export const postEmbeddedSuccess = (): IEmbeddedAction => {
  return {
    type: ActionTypes.POST_EMBEDDED_SUCCESS
  };
};

export const postEmbeddedFail = (error: string): IEmbeddedAction => {
  return {
    type: ActionTypes.POST_EMBEDDED_FAIL,
    error
  };
};

export const postEmbedded = (id:string, func:string, data: Object, application: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(postEmbeddedStart());
    try {
      const res=await axios.post(`/integration/${application}/${func}/${id}`, data);
      dispatch(postEmbeddedSuccess());
      return res.data;
      //@ts-ignore
    } catch (error: any) {
      dispatch(postEmbeddedFail(error.response.data));
    }
    return null;
  };
};
