import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
//import { useDrag, useDrop } from "react-dnd";
import ContentTypeIcon from "../../../../../../../components/UI/ContentTypeIcon/ContentTypeIcon";
import SelectableContainer from "../../../../../../../components/UI/SelectableContainer/SelectableContainer";
import { EContentType } from "../../../../../../../enums/content-types";
import { IBlockContent } from "../../../../../../../interfaces";
import { Button } from "reactstrap";

import classes from "./ContentAreaItem.module.scss";

export const DragItems = {
  contentAreaItem: "ContentAreaItem"
};

export interface IContentAreaItem {
  id: string;
  name: string;
  type: EContentType;
}

interface IProps {
  item: IBlockContent;
  moveItem: (item: IContentAreaItem, toPosition: string) => void;
  onClick: (item: IBlockContent) => void;
  onEdit?: (item: IBlockContent) => void;
}

const ContentAreaItem: React.FC<IProps> = ({
  item,
  moveItem,
  onClick,
  onEdit
}) => {
  const handleRef = useRef<HTMLSpanElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

/*
  const [{ isDragging }, drag] = useDrag({
    item: { id: item.id, name: item.name, type: DragItems.contentAreaItem },
    end: (dropResult?: any) => {},
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    isDragging: monitor => {
      return item.id === monitor.getItem().id;
    }
  });

  const [, drop] = useDrop({
    accept: DragItems.contentAreaItem,
    hover(hoverItem: IContentAreaItem) {
      if (hoverItem.id !== item.id) {
        moveItem(hoverItem, item.id);
      }
    }
  });
*/
  const onItemClicked = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    onClick(item);
  };

  const onEditClicked = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    onEdit(item);
  };

  const opacity = 1; //isDragging ? 0.5 : 1;

  // drop(previewRef);
  // drag(handleRef);

  return (
    <SelectableContainer
      className={classes.Container}
      onClick={onItemClicked}
      active={false}
      style={{ opacity }}
      ref={previewRef}
    >
      <div>
        <span ref={handleRef} className="drag-handle">
          <FontAwesomeIcon icon={faArrowsAlt} />
        </span>
        <p className={classes.Title}>{item.name}</p>
      </div>
      <div className={classes.Right}>
        {onEdit && (
          <Button color="primary" className={classes.EditButton} onClick={onEditClicked}>
            Edit Content
          </Button>
        )}
        <ContentTypeIcon contentType={item.contentType} active={false} />
      </div>
    </SelectableContainer>
  );
};

export default ContentAreaItem;
