import { ActionTypes } from './../actions/actionTypes';
import { IBillingState, IBillingAction } from "../../interfaces";
import { updateObject } from '../../shared/utility';

const initialState:IBillingState = {
    loading: false,
    error: null,
    plans: null,
    plan: null,
    seats: null,
};

const findPlansStart = (state:IBillingState, action:IBillingAction):IBillingState => {
    return updateObject(state, { error: null, loading: true});
}

const findPlansSuccess = (state:IBillingState, action:IBillingAction):IBillingState => {
    return updateObject(state, {
        error: null,
        loading: false,
        plans: action.plans,
        plan: null
    });
}

const findPlansFail = (state:IBillingState, action:IBillingAction):IBillingState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getSeatsStart = (state:IBillingState, action:IBillingAction):IBillingState => {
    return updateObject(state, { error: null, loading: true});
}

const getSeatsSuccess = (state:IBillingState, action:IBillingAction):IBillingState => {
    return updateObject(state, {
        error: null,
        loading: false,
        seats: action.seats
    });
}

const getSeatsFail = (state:IBillingState, action:IBillingAction):IBillingState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state:IBillingState = initialState, action:IBillingAction):IBillingState => {
    switch (action.type) {
        case ActionTypes.FIND_PLANS_START : return findPlansStart(state, action);
        case ActionTypes.FIND_PLANS_SUCCESS : return findPlansSuccess(state, action);
        case ActionTypes.FIND_PLANS_FAIL : return findPlansFail(state, action);
        case ActionTypes.GET_SEATS_START : return getSeatsStart(state, action);
        case ActionTypes.GET_SEATS_SUCCESS : return getSeatsSuccess(state, action);
        case ActionTypes.GET_SEATS_FAIL : return getSeatsFail(state, action);
        default: return state;
    }
}

export default reducer;
