import React, { useEffect, useState } from 'react';
import { IIntegrations, IOrganization, IUser } from '../../../../../interfaces';

import { Button, CustomInput, Input, Row, Col } from 'reactstrap';
import axios from 'axios';

import { config } from '../../../../../constants';

import classes from "../Integrations.module.scss";
const hostname = window && window.location && window.location.hostname;

let BASEURL=config.SALESFORCE.BASEURL;
if(hostname === 'localhost') {
  //BASEURL="http://localhost:8082/salesforce";
}

//const VISUALFORCEPAGE = "https://login.salesforce.com/packaging/installPackage.apexp?p0=04t7Q000000cXiX"
//const VISUALFORCEPAGE = "https://login.salesforce.com/packaging/installPackage.apexp?p0=04t7Q000000cXic"
const VISUALFORCEPAGE = "packaging/installPackage.apexp?p0=04t7Q000000cXic"
//"https://login.salesforce.com/packaging/installPackage.apexp?p0=04t7Q000000cV2B&isdtp=p1"



interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  updateOrganization: (x:any)=>void;

}

interface IProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
  updateOrganization: (x:any)=>void;
  params: any;
}

export const getDeal:any = async (integrations:IIntegrations, user:IUser, deal:string) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deal?deal='+deal+'&token='+integrations.salesforce.refresh_token+"&email="+encodeURIComponent(user.email));
  return res.data;
}


export const getDeals:any = async (integrations:IIntegrations, user:IUser) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deals?stage='+integrations.salesforce.stageId+'&token='+integrations.salesforce.refresh_token+"&email="+encodeURIComponent(user.email))
  return res.data;
}


const Salesforce: React.FC<IProps> = ({
  currentUser,
  organization,
  updateOrganization,

  params,
}) => {

  const [stages, setStages] = useState<Array<String>>([]);

  const integrations=organization && organization.integrations;

  const updateSalesforce = (updated:any) => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        salesforce:{
          ...organization.integrations.salesforce,
          ...updated,
        }  
      }      
    }
    updateOrganization(newo);
  }


  useEffect(() => {
    if(organization && params.token && params.name==="salesforce") {
      const newo= {...organization,
        integrations:{
          ...organization.integrations,
          salesforce:{
            ...organization.integrations.salesforce,
            active:true,
            refreshToken:params.token as string,
          }
        }
      }

      updateOrganization(newo);
    }
    // eslint-disable-next-line
  }, [organization]);


  const getStages = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get(BASEURL+'/stages?token='+integrations.salesforce.refresh_token+"&instance_url="+encodeURIComponent(integrations.salesforce.instance_url)).then((resp)=>{
      //console.log("resp",resp)
      setStages(resp.data)
    })
    .catch((resp)=> {
      //console.log("error",resp);
    })
  }

  const setSentStage = (key:any) => {
    updateSalesforce({sentStageId:key,sentStageName:stages[key]})
  }

  const setOpenStage = (key:any) => {
    updateSalesforce({openStageId:key,openStageName:stages[key]})
  }
  
  const setNewStage = (key:any) => {
    updateSalesforce({newStageId:key,newStageName:stages[key] || null})
  }
 
    /*
  const getDeals = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get('http://localhost:8082/deals?stage='+integrations.salesforce.stageId+'&token='+integrations.salesforce.refreshToken).then((resp)=>{
      console.log("resp",resp)
      setDeals(resp.data)
    })
    .catch((resp)=> {
      console.log("error",resp);
    })
  }
     */

  const SALESFORCE_AUTH_URI = "https://login.salesforce.com" + 
    "/services/oauth2/authorize"+
    "?response_type=code"+ 
    "&client_id=" + config.SALESFORCE.CLIENT_ID + 
    "&redirect_uri=" + encodeURIComponent(window.location.origin + "/integration/salesforce/oauth2callback");
    //"&redirect_uri=" + encodeURIComponent("https://java17-dot-c2ctest-203405.ey.r.appspot.com" + "/integration/salesforce/oauth2callback");

  return (
    <React.Fragment>
          <div className={classes.integration}>

      <Row>
        <Col>
      			<h5>Salesforce</h5>
            <CustomInput
              type="switch"
              label="Is Active"
              id="salesforceActiveId"
    			  	onChange={(e:any)=>{if(integrations.salesforce.active) {updateSalesforce({active:false,refreshToken:""})}}}
    				  checked={integrations.salesforce.active}
            />
            {integrations.salesforce.sentStageId && <div>Move sent deals to: {(integrations.salesforce.sentStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setSentStage(null)}}>dont move</Button></div>}
            {integrations.salesforce.openStageId && <div>Move opened deals to: {(integrations.salesforce.openStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setOpenStage(null)}}>dont move</Button></div>}
            {integrations.salesforce.newStageId && <div>Move confirmed deals to: {(integrations.salesforce.newStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage(null)}}>dont move</Button></div>}

            <CustomInput
              type="switch"
              label="Upload Proposals to Opportunity"
              id="salesforceSendProposalToId"
              onChange={(e:any)=>{updateSalesforce({sendProposal:!integrations.salesforce.sendProposal})}}
              checked={integrations.salesforce.sendProposal}
            />

            <CustomInput
              type="switch"
              label="Upload Contracts to Opportunity"
              id="salesforceSendContractToId"
    			  	onChange={(e:any)=>{updateSalesforce({sendContract:!integrations.salesforce.sendContract})}}
    				  checked={integrations.salesforce.sendContract}
            />

            <CustomInput
              type="switch"
              label="Upload Contracts to Account"
              id="salesforceSendContractToAccountId"
              onChange={(e:any)=>{updateSalesforce({sendContractToAccount:!integrations.salesforce.sendContractToAccount})}}
              checked={integrations.salesforce.sendContractToAccount}
            />

            <Input
              type="textarea"
              label="customfields"
              id="salesforceCustomFieldsId"
              onBlur={(e:any)=>{updateSalesforce({customFields:e.target.value})}}
              defaultValue={integrations.salesforce.customFields}
            />

            <br/>
            <Button size="sm" color={integrations.salesforce.refresh_token?"success":"warning"} href={SALESFORCE_AUTH_URI}>Salesforce authentication</Button>
            {' '}{integrations.salesforce.instance_url && <Button size="sm" href={integrations.salesforce.instance_url+"/"+VISUALFORCEPAGE} target="_blank">Install Salesforce Visual Force Component</Button>}
            {' '}<Button size="sm" color="primary" onClick={getStages}>Get stages</Button>
            
        </Col>
        <Col>
          <div>{Object.keys(stages).map((key:any)=>(<div key={key}>
              <Button size="sm"
                color={key===integrations.salesforce.sentStageId?"success":"secondary"}
                value={key}
                onClick={()=>setSentStage(key)}
            >sent
            </Button>
            <Button size="sm"
                color={key===integrations.salesforce.openStageId?"success":"secondary"}
                value={key}
                onClick={()=>setOpenStage(key)}
            >opened
            </Button>
            <Button size="sm"
                color={key===integrations.salesforce.newStageId?"success":"secondary"}
                value={key}
                onClick={()=>setNewStage(key)}
            >confirmed
            </Button>

              {stages[key].toUpperCase()}
          </div>))}

            </div>
        </Col>
      </Row>
      </div>


    </React.Fragment>
  );
};

export default Salesforce;

