import React, { useEffect } from 'react';
import { IOrganization, IUser } from '../../../../../interfaces';

import { CustomInput, Input, Row, Col } from 'reactstrap';
import classes from "../Integrations.module.scss";
const hostname = window && window.location && window.location.hostname;



interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  updateOrganization: (x:any)=>void;

}

interface IProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
  updateOrganization: (x:any)=>void;
  params: any;
}

const SsoSAML: React.FC<IProps> = ({
  currentUser,
  organization,
  updateOrganization,

  params,
}) => {

  //const [stages, setStages] = useState<Array<String>>([]);

  const integrations=organization && organization.integrations;

  const updateSsoSaml = (updated:any) => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        ssoSAML:{
          ...organization.integrations.ssoSAML,
          ...updated,
        }  
      }      
    }
    updateOrganization(newo);
  }


  useEffect(() => {
    if(organization && params.token && params.name==="ssoSAML") {
      const newo= {...organization,
        integrations:{
          ...organization.integrations,
          ssoSAML:{
            ...organization.integrations.ssoSAML,
            active:true,
            refreshToken:params.token as string,
          }
        }
      }

      updateOrganization(newo);
    }
    // eslint-disable-next-line
  }, [organization]);


  return (
    <React.Fragment>
      <div className={classes.integration}>

      <Row>
        <Col>
      			<h5>SSO</h5>
            <CustomInput
              type="switch"
              label="Is Active"
              id="ssoSAMLActiveId"
    			  	onChange={(e:any)=>{updateSsoSaml({active:!integrations.ssoSAML.active,refreshToken:""})}}
    				  checked={integrations.ssoSAML.active}
            />
        </Col>
      </Row>

      <Row>
        <Col>
            <CustomInput
              type="switch"
              label="Allow Local Login"
              id="ssoAllowLogin"
              onChange={(e:any)=>{updateSsoSaml({allowLogin:!integrations.ssoSAML.allowLogin,refreshToken:""})}}
              checked={integrations.ssoSAML.allowLogin}
            />
        </Col>
      </Row>

      <div>Login URL</div>
      <Input value={integrations.ssoSAML.loginUrl} onChange={(e:any)=>updateSsoSaml({loginUrl:e.target.value})} placeholder="Login URL"/>


      <div>Audience (EntityID)</div>
      <Input readOnly value={"https://"+hostname+"/integration/sso/metadata/"+organization.id}/>
      <div>Recipient</div>
      <Input readOnly value={"https://"+hostname+"/integration/sso/acs/"+organization.id}/>
      <div>ACS (Consumer) URL*</div>
      <Input readOnly value={"https://"+hostname+"/integration/sso/acs/"+organization.id}/>
      <div>Single Logout URL</div>
      <Input readOnly value={"https://"+hostname+"/integration/sso/sls/"+organization.id}/>

      <div>Issuer URL</div>
      <Input value={integrations.ssoSAML.idpEntityid} onChange={(e:any)=>updateSsoSaml({idpEntityid:e.target.value})} placeholder="idp entity id"/>
      <div>SAML 2.0 Endpoint (HTTP)</div>
      <Input value={integrations.ssoSAML.idpSingleSignOnServiceUrl} onChange={(e:any)=>updateSsoSaml({idpSingleSignOnServiceUrl:e.target.value})} placeholder="idpSingleSignOnServiceUrl"/>
      <div>SLO Endpoint (HTTP)</div>
      <Input value={integrations.ssoSAML.idpSingleLogoutServiceUrl} onChange={(e:any)=>updateSsoSaml({idpSingleLogoutServiceUrl:e.target.value})} placeholder="idpSingleLogoutServiceUrl"/>
      <div>X.509 Certificate</div>
      <Input type="textarea" value={integrations.ssoSAML.x509cert} onChange={(e:any)=>updateSsoSaml({x509cert:e.target.value})} placeholder="x509cert"/>



      </div>


    </React.Fragment>
  );
};

export default SsoSAML;

