import React from 'react';
import { connect } from 'react-redux';
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from '../../withRouter';
import { Table, Badge } from 'reactstrap';

import { useLocation } from 'react-router-dom';


import EUserRole from '../../enums/user-roles';
import { IAppState, IUser, IUserGroup } from '../../interfaces';
import { hasAccess } from '../../shared/utility';

interface IStateProps {
  currentUser: IUser | null;
  users: Array<IUser>;
}

interface IProps extends IStateProps, RouteComponentProps {
  userGroups: Array<IUserGroup>;
}

const UserGroupsList: React.FC<IProps> = ({
  userGroups,
  users,
  history,
  match,
  currentUser
}) => {
  const location = useLocation();

  if (!userGroups || !userGroups.length) {
    return null;
  }

  const onEdit = (user: IUserGroup) => {
    if (hasAccess(currentUser, EUserRole.SUPERADMIN)) {
      //history.push(`${match.url}/usergroups/${user.id}`);
      history.push(`${location.pathname}/usergroups/${user.id}`);
    } else {
      history.push(`/usergroups/${user.id}`);
    }
  };

  const showUser = (id:string) => {
    if(users) {
      const user = users.find((u:IUser) => u.id === id);
      return user.name;
    }
    return "";
  }

  return (
    <Table responsive borderless striped>
      <thead>
        <tr>
          <th>Name</th>
          <th>Members</th>
          {/*<th>Team Leads</th>*/}
        </tr>
      </thead>
      <tbody>
        {userGroups.sort((a:any, b:any) => a.name < b.name ? -1:1).map((user, i) => (
          <tr key={user.id || `${user.name}_${i}`} onClick={() => onEdit(user)} style={{ cursor: "pointer" }}>
            <td>{user.name}</td>
            <td>{(user.members || []).map((id:string)=>(<Badge key={"m"+id} style={{"marginRight":5}}>{showUser(id)}</Badge>))}</td>
            {/*<td>{(user.leads || []).map((id:string)=>(<Badge key={"l"+id} style={{"marginRight":5}}>{showUser(id)}</Badge>))}</td>*/}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    users: state.users.users,
  };
};

export default connect(mapStateToProps)(UserGroupsList);
