import React, { useEffect, useState } from 'react';
//import { DndProvider } from 'react-dnd';
//import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
//import { RouteComponentProps, Prompt } from 'react-router';
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from '../../../../../withRouter';
import { useParams } from "react-router-dom";

import { Alert } from 'reactstrap';
import uuid from 'uuid/v1';

import ContentWithSidebarStatic from '../../../../../components/UI/ContentWithSidebarStatic/ContentWithSidebarStatic';
import FooterFixed from '../../../../../components/UI/FooterFixed/FooterFixed';
import ListSpinner from '../../../../../components/UI/ListSpinner/ListSpinner';
import SidebarStatic from '../../../../../components/UI/SidebarStatic/SidebarStatic';
import { EOrganizationLevel, EContentType, EPageView, EUserTemplateBlockType } from '../../../../../enums';
import { EDragArea, EDragType } from '../../../../../enums/drag';
import {
  ELayoutType,
  IAppState,
  IBlock,
  IBlockItem,
  ICustomer,
  IOffer,
  IOrganization,
  IUserTemplate,
  IUserTemplateArea,
  IUserTemplateAreaBlock,
  IUserTemplateBlockDraggable,
	IUserTemplateEdit,
	ESignType,
  IUserGroup,
  IUser,
} from '../../../../../interfaces';
import { defaultOffer } from '../../../../../shared/offer-utils';
import { defaultAreaStyles, defaultBlockStyles, defaultTemplateStyles } from '../../../../../shared/styles';
import { isUUID } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import OfferPreview from '../../../components/Offers/Offer/OfferPreview/OfferPreview';
import OfferCombined from '../../../components/Offers/Offer/OfferCombined/OfferCombined';

import TemplateArea from '../../../components/Templates/Template/TemplateArea/TemplateArea';
import TemplateAreaContext from '../../../components/Templates/Template/TemplateArea/TemplateAreaContext';
import TemplateFooter from '../../../components/Templates/Template/TemplateFooter/TemplateFooter';
import TemplateSidebar from '../../../components/Templates/Template/TemplateSidebar/TemplateSidebar';
import CustomDialog from '../../../../../components/UI/CustomDialog/CustomDialog';


import { dummyLogo, dummyParties, dummyEditor, dummySignature} from './CreateDefaults/Dummies';

import classes from "./UserTemplate.module.scss";

interface IStateProps {
  blocks: Array<IBlock>;
  blocksLoading: boolean;
  blocksError: string;
  userTemplate: IUserTemplate;
  userTemplateLoading: boolean;
  userTemplateError: string;
  organization: IOrganization;
  customers: Array<ICustomer>;
  userGroups: Array<IUserGroup>;
  currentUser: IUser;
}

interface IDispatchProps {
  onFindBlocks: () => void;
  onGetUserTemplate: (id: string) => void;
  onSaveUserTemplate: (userTemplate: IUserTemplate) => Promise<boolean>;
  onUpdateUserTemplate: (
    id: string,
    userTemplate: IUserTemplate
  ) => Promise<boolean>;
  onFindBlockItems: (
    blockId: string,
    fetchSize: number
  ) => Promise<Array<IBlockItem>>;
  onGetUserOrganization: () => Promise<IOrganization>;
  onFindCustomers: () => Promise<Array<ICustomer>>;
  onDeleteUserTemplate: (id: string) => Promise<boolean>;
  onCopyUserTemplate: (userTemplate: IUserTemplate) => Promise<IUserTemplate>;
  onFindUserGroups: (organizationId: string) => void;
}

interface IParams {
  id: string;
  type: EPageView;
}

interface IProps
  extends RouteComponentProps, //<IParams>,
    IStateProps,
    IDispatchProps {}

interface IItemsState {
  header: Array<IUserTemplateBlockDraggable>;
  main: Array<IUserTemplateBlockDraggable>;
  footer: Array<IUserTemplateBlockDraggable>;
  addable: Array<IUserTemplateBlockDraggable>;
}

const defaultUserTemplate: IUserTemplateEdit = {
  id: null,
  name: "New Template",
  customColor: "",
  userGroups: [],
  header: {
    name: "Header",
    blocks: [],
    styles: defaultAreaStyles
  },
  main: {
    name: "Main",
    blocks: [],
    styles: defaultAreaStyles
  },
  footer: {
    name: "Footer",
    blocks: [],
    styles: defaultAreaStyles
  },
	styles: defaultTemplateStyles,
	settings: {
		signType:ESignType.NORMAL,
	},
  templateBlocks: null
};

const defaultConfirmDialog = {
  isOpen: false,
  onOk: () => {},
  title: "",
  content: ""
};

const UserTemplate: React.FC<IProps> = ({
  blocks,
  blocksLoading,
  blocksError,
  userTemplate,
  userTemplateLoading,
  organization,
  onFindBlocks,
  onGetUserTemplate,
  onSaveUserTemplate,
  onUpdateUserTemplate,
  onFindBlockItems,
  onGetUserOrganization,
  onFindCustomers,
  customers,
  match,
  history,
  userTemplateError,
  onDeleteUserTemplate,
  onCopyUserTemplate,
  onFindUserGroups,
  userGroups,
  currentUser,
}) => {
  const params=useParams();

  const id:string = params.id;
  const type:EPageView = params.type as EPageView;

  //const { id, type } = match.params;
  const isAdd = id === "add";
  const [activeBlock, setActiveBlock] = useState<IUserTemplateBlockDraggable>(
    null
  );
  const [initLoading, setInitLoading] = useState(false);
  const [activeArea, setActiveArea] = useState(null);

  const [pageView, setPageView] = useState<EPageView>(type);

  const [fetchedBlockItems, setFetchedBlockItems] = useState<{
    [blockId: string]: IBlockItem;
  }>({});

  const [addableBlocks, setAddableBlocks] = useState<
    Array<IUserTemplateBlockDraggable>
  >(null);

  const [userTemplateState, setUserTemplateState] = useState<IUserTemplateEdit>(
    defaultUserTemplate
  );

  const [dummyOffer, setDummyOffer] = useState<IOffer>(defaultOffer);

  const [endDragBlock, setEndDragBlock] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);

  const [saved, setSaved] = useState(false);

  // Odotetaan et aikasempi state update on mennyt ja tehdän sitten vasta nämä.
  useEffect(() => {
    if (endDragBlock) {
      setActiveBlock(setDragged(endDragBlock, false));
      getPreviewBlock(endDragBlock);

      setEndDragBlock(null);
    }
    // eslint-disable-next-line
  }, [endDragBlock]);

  const viewClickHandler = (type: EPageView) => {
    setPageView(type);
    history.push(`/templates/${id}/${type}`);
    window.scrollTo(0, 0);
  };

  const getPreviewBlock = async (block: IUserTemplateBlockDraggable) => {
    if (block.blockType === EUserTemplateBlockType.DYNAMIC) {
      const items = getItems(userTemplateState, addableBlocks);
      const newFetchedBlockItems = { ...fetchedBlockItems };
      let blockItem = newFetchedBlockItems[block.blockId];
      if (!blockItem) {
        const blockItems = await onFindBlockItems(block.blockId, 1);
        if (blockItems) {
          blockItem = blockItems[0];
        }
      }
      if (!blockItem) return;

      Object.values(items)
        .flat()
        .forEach((item: IUserTemplateAreaBlock) => {
          if (item.blockId === block.blockId) {
            item.offerBlockItems = [blockItem];
          }
        });

      setFetchedBlockItems(newFetchedBlockItems);
      setItems(userTemplateState, items);
    }
  };

  useEffect(() => {
    setDummyOffer({ ...dummyOffer, userTemplate: { ...userTemplateState } });
    // eslint-disable-next-line
  }, [userTemplateState]);

  useEffect(() => {
    onFindBlocks();
    onGetUserOrganization();
    onFindCustomers();
  }, [onFindBlocks, onGetUserOrganization, onFindCustomers]);

  useEffect(() => {
    if (!isAdd) {
      onGetUserTemplate(id);
    }
    // eslint-disable-next-line
  }, [id, onGetUserTemplate]);

  useEffect(() => {
    setDummyOffer({ ...dummyOffer, organization });
    if(organization && organization.id) {
      onFindUserGroups(organization.id);
    }
    // eslint-disable-next-line
  }, [organization]);

  useEffect(() => {
    if (customers) {
      setDummyOffer({ ...dummyOffer, customer: customers[0] });
    }
    // eslint-disable-next-line
  }, [customers]);

  const addUserTemplateBlocks = (
    items: Array<IUserTemplateBlockDraggable>,
    area: EDragArea,
    userTemplateBlocks: Array<IUserTemplateAreaBlock>
  ) => {
    if (userTemplateBlocks && userTemplateBlocks.length > 0) {
      for (let userTemplateBlock of userTemplateBlocks) {
        let contentType = EContentType.CUSTOM;
        if (userTemplateBlock.blockType === EUserTemplateBlockType.DYNAMIC) {
          contentType = EContentType.DYNAMIC;
        }

        items.push({
          ...userTemplateBlock,
          area,
          isDragged: false,
          contentType,
          type: EDragType.BLOCK,
          uuid: userTemplateBlock.id
        });
      }
    }
  };

  /**
   * This is used for fetching blockItems content for preview when editing offer
   * @param newUserTemplate
   * @param newAddableBlocks
   */
  const fetchBlockItems = async (
    newUserTemplate: IUserTemplateEdit,
    newAddableBlocks: Array<IUserTemplateBlockDraggable>
  ) => {
    const items = getItems(newUserTemplate, newAddableBlocks);

    const newFetchedBlockItems = { ...fetchedBlockItems };

    const flatItems = Object.values(items)
      .flat()
      .filter(
        (item: IUserTemplateBlockDraggable) =>
          item.blockType === EUserTemplateBlockType.DYNAMIC &&
          item.area !== EDragArea.ADDABLE
      );

    setInitLoading(true);
    await Promise.all(
      flatItems.map(async item => {
        let blockItem = newFetchedBlockItems[item.blockId];
        if (!blockItem) {
          const blockItems = await onFindBlockItems(item.blockId, 1);
          if (blockItems) {
            blockItem = blockItems[0];
          }
          newFetchedBlockItems[item.blockId] = blockItem;
        }
      })
    );

    flatItems.forEach(item => {
      const blockItem = newFetchedBlockItems[item.blockId];
      if (blockItem) {
        item.offerBlockItems = [blockItem];
      }
    });

    setItems(newUserTemplate, items);
    setFetchedBlockItems(newFetchedBlockItems);
    setInitLoading(false);
  };

  const dummyLegalBox: any = (area: EDragArea) => {
    const id = uuid();
    return {
      id: id,
      // blockId: null,
      name: "Digital Signing Details (This element must be on every proposal. You may change the visuals but please do not remove the element.)",
      showName: false,
      editableOnOffer: false,
      layoutType: ELayoutType.VERTICAL,
      styles: defaultBlockStyles,
      area,
      isDragged: false,
      contentType: EContentType.CUSTOM,
      type: EDragType.BLOCK,
      uuid: id,
      blockType: EUserTemplateBlockType.SIMPLE,
      content: [{
        id: uuid(),
        name: "Legal Box",
        contentType: EContentType.LEGAL_BOX,
        editOnOffer: false
      }],
    };
  };

  const initBlocks = () => {
    const newAddableBlocks:Array<IUserTemplateBlockDraggable> = [];

    if (blocks) {
      for (let block of blocks) {
        newAddableBlocks.push({
          id: block.id, // Tämä tässä koska tunnistetaan et mistä kopioidaan
          blockId: block.id,
          name: block.name,
          showName: false,
          editableOnOffer: false,
          layoutType: ELayoutType.VERTICAL,
          styles: defaultBlockStyles,
          area: EDragArea.ADDABLE,
          isDragged: false,
          contentType: EContentType.DYNAMIC,
          type: EDragType.BLOCK,
          uuid: uuid(),
          blockType: EUserTemplateBlockType.DYNAMIC,
          editingStyle: block.editingStyle
        });
      }

      if (isAdd) {
      	newAddableBlocks.push(dummyLogo(EDragArea.ADDABLE));
      	newAddableBlocks.push(dummyParties(EDragArea.ADDABLE));
      	newAddableBlocks.push(dummyEditor(EDragArea.ADDABLE));
      	newAddableBlocks.push(dummySignature(EDragArea.ADDABLE));

        newAddableBlocks.push(dummyLegalBox(EDragArea.ADDABLE));
      };

    }

    let newUserTemplate: IUserTemplateEdit = {
			...defaultUserTemplate,

			settings: {
				...defaultUserTemplate.settings,
				signType: (organization && organization.level)===EOrganizationLevel.PREMIUM?ESignType.SMS:ESignType.NORMAL,
			},

      header: { ...defaultUserTemplate.header, blocks: [] },
      main: { ...defaultUserTemplate.main, blocks: [] },
      footer: { ...defaultUserTemplate.footer, blocks: [] }
    };
    if (userTemplate) {
      //const ugs = (userTemplate.userGroups || []).map((x:any)=>({id:x.id})) || [];

      newUserTemplate = {
        ...newUserTemplate,
        id: userTemplate.id,
        name: userTemplate.name || "",
        customColor: userTemplate.customColor || "",
        userGroups: userTemplate.userGroups || [],
				styles: userTemplate.styles,
				settings: userTemplate.settings,
        header: {
          styles: userTemplate.header.styles,
          blocks: [],
          name: userTemplate.header.name
        },
        main: {
          styles: userTemplate.main.styles,
          blocks: [],
          name: userTemplate.main.name
        },
        footer: {
          styles: userTemplate.footer.styles,
          blocks: [],
          name: userTemplate.footer.name
        }
      };
      addUserTemplateBlocks(
        newUserTemplate.header.blocks,
        EDragArea.HEADER,
        userTemplate.header.blocks
      );
      addUserTemplateBlocks(
        newUserTemplate.main.blocks,
        EDragArea.MAIN,
        userTemplate.main.blocks
      );

      addUserTemplateBlocks(
        newUserTemplate.footer.blocks,
        EDragArea.FOOTER,
        userTemplate.footer.blocks
      );
      addUserTemplateBlocks(
        newAddableBlocks,
        EDragArea.ADDABLE,
        userTemplate.templateBlocks
      );
    }

    if(isAdd) {
      newUserTemplate.main.blocks.push(dummyLogo(EDragArea.MAIN));

      newUserTemplate.main.blocks.push(dummyParties(EDragArea.MAIN));
      newUserTemplate.main.blocks.push(dummyEditor(EDragArea.MAIN));
      newUserTemplate.main.blocks.push(dummySignature(EDragArea.MAIN));

      newUserTemplate.main.blocks.push(dummyLegalBox(EDragArea.MAIN)); // FOOTER

    }
    
    fetchBlockItems(newUserTemplate, newAddableBlocks);
  };

  useEffect(() => {
    if ((!isAdd && userTemplate && blocks) || (isAdd && blocks)) {
      initBlocks();
    }
    // eslint-disable-next-line
  }, [userTemplate, blocks]);

  useEffect(() => {
    if (activeBlock) {
      setActiveArea(null);
    }
  }, [activeBlock]);

  useEffect(() => {
    if (activeArea) {
      setActiveBlock(null);
    }
  }, [activeArea]);

  const getArray = (items: IItemsState, area: EDragArea) => {
    switch (area) {
      case EDragArea.HEADER:
        return items.header;
      case EDragArea.MAIN:
        return items.main;
      case EDragArea.FOOTER:
        return items.footer;
      case EDragArea.ADDABLE:
        return items.addable;
      default:
        return null;
    }
  };

  const getItems = (
    newUserTemplate: IUserTemplateEdit,
    newAddableBlocks: Array<IUserTemplateBlockDraggable>
  ): IItemsState => {
    return {
      header: [...newUserTemplate.header.blocks],
      main: [...newUserTemplate.main.blocks],
      footer: [...newUserTemplate.footer.blocks],
      addable: [...newAddableBlocks]
    };
  };

  const setItems = (userTemplate: IUserTemplateEdit, items: IItemsState) => {
    setUserTemplateState({
      ...userTemplate,
      header: {
        ...userTemplate.header,
        blocks: [...items.header]
      },
      main: {
        ...userTemplate.main,
        blocks: [...items.main]
      },
      footer: {
        ...userTemplate.footer,
        blocks: [...items.footer]
      }
    });
    setAddableBlocks(items.addable);
  };

  const setDragged = (
    areaItem: IUserTemplateBlockDraggable,
    value: boolean
  ) => {
    const newItems = getItems(userTemplateState, addableBlocks);

    const item = Object.values(newItems)
      .flat()
      .find(item => item.uuid === areaItem.uuid);

    if (item) {
      item.area = areaItem.area;
      item.isDragged = value;
      setItems(userTemplateState, newItems);
    }
    return item;
  };

  const updateBlock = (block: IUserTemplateBlockDraggable) => {
    const newItems = getItems(userTemplateState, addableBlocks);
    const array = getArray(newItems, block.area);
    array[array.findIndex(item => item.uuid === block.uuid)] = block;
    setItems(userTemplateState, newItems);
    setActiveBlock(block);
  };

  const deleteBlock = (block: IUserTemplateBlockDraggable) => {
    const newItems = getItems(userTemplateState, addableBlocks);
    const array = getArray(newItems, block.area);
    array.splice(array.findIndex(item => item.uuid === block.uuid), 1);
    setItems(userTemplateState, newItems);
    setActiveBlock(null);
  };

//-------------------------------------------------------------------------------
	const getBlockItems = async (id:string) => {
		if(id) {
	 		const blockItems=await onFindBlockItems(id, 1);
			return blockItems;
		}
		return [];
	}

  const addBlock = async (block1: IUserTemplateBlockDraggable,block2?: IUserTemplateBlockDraggable) => {
		if(block1 && block2) {


   		const newItems = getItems(userTemplateState, addableBlocks);
			const array=getArray(newItems,block1.area)
  	  const item1 = array.findIndex(item => item.uuid === block1.uuid);

			const array2=getArray(newItems,block2.area)
			const item2 = array2.find(item => item.uuid === block2.uuid);

		    const item = {					
  		    ...item2,
  	      isDragged: true,
  	  	  id: uuid(),
  	    	uuid: uuid(),
					area:block1.area
		    };


		  	array.splice(item1, 0, item);

  		setItems(userTemplateState, newItems);

			item.offerBlockItems=await getBlockItems(item.blockId);

			setActiveBlock(item);

		}
  };


  const swapBlocks = (block1: IUserTemplateBlockDraggable,block2?: IUserTemplateBlockDraggable) => {
		if(block1 && block2) {
   		const newItems = getItems(userTemplateState, addableBlocks);
			const array=getArray(newItems,block1.area)
  	  const item1 = array.findIndex(item => item.uuid === block1.uuid);

			if(block1.area===block2.area) {
				const item2 = array.findIndex(item => item.uuid === block2.uuid);

				array[item1]=block2;
				array[item2]=block1;

  			setItems(userTemplateState, newItems);
			}
			else {
				const array2=getArray(newItems,block2.area)
				const item2 = array2.findIndex(item => item.uuid === block2.uuid);

				const tmp=block1.area
				block1.area=block2.area
				block2.area=tmp

				array[item1]=block2;
				array2[item2]=block1;

  			setItems(userTemplateState, newItems);
			}

		}
  };

//-------------------------------------------------------------------------------


  const updateUserTemplate = (userTemplate: IUserTemplateEdit) => {
    setUserTemplateState(userTemplate);
  };

  const onStartDrag = (areaItem: IUserTemplateBlockDraggable) => {
    setDragged(areaItem, true);
  };

  const onEndDrag = (block: IUserTemplateBlockDraggable, didDrop: boolean) => {
    setEndDragBlock(block);
  };

  const moveItem = (
    dragItem: IUserTemplateBlockDraggable,
    toArea: EDragArea,
    toItem: string
  ) => {
    if (toArea === EDragArea.ADDABLE) {
      return;
    }
    const newItems = getItems(userTemplateState, addableBlocks);

    const fromArray = getArray(newItems, dragItem.area);
    const toArray = getArray(newItems, toArea);

    let index = -1;
    if (toItem !== null) {
      index = toArray.findIndex(item => item.uuid === toItem);
    }

    let item;
    if (dragItem.area === EDragArea.ADDABLE) {
      // Copy item
      const addableItem = fromArray.find(item => item.id === dragItem.id);
      item = {
        ...addableItem,
        isDragged: true,
        id: dragItem.uuid,
        uuid: dragItem.uuid
      };
    } else {
      // Move item
      const itemIndex = fromArray.findIndex(
        item => item.uuid === dragItem.uuid
      );
      [item] = fromArray.splice(itemIndex, 1);
    }

    toArray.splice(index, 0, item);
    setItems(userTemplateState, newItems);
    dragItem.area = toArea;
  };

  const onCreateBlock = () => {
    let newAddableBlocks: Array<IUserTemplateBlockDraggable> = [];
    if (addableBlocks) newAddableBlocks = [...addableBlocks];
    const id = uuid();
    const newBlock: IUserTemplateBlockDraggable = {
      id: id,
      blockId: null,
      name: "Untitled element",
      showName: false,
      editableOnOffer: false,
      editingStyle: null,
      layoutType: ELayoutType.HORIZONTAL,
      styles: defaultBlockStyles,
      area: EDragArea.ADDABLE,
      isDragged: false,
      contentType: EContentType.CUSTOM,
      type: EDragType.BLOCK,
      uuid: id,
      blockType: EUserTemplateBlockType.SIMPLE
    };
    newAddableBlocks.push(newBlock);
    setAddableBlocks(newAddableBlocks);
    setActiveBlock(newBlock);
		return newBlock;
  };

  const blockClickHandler = (item: IUserTemplateBlockDraggable) => {
    if (
      item.area !== EDragArea.ADDABLE ||
      item.blockType === EUserTemplateBlockType.SIMPLE
    ) {
      setActiveBlock(item);
    }
  };

  const areaClickHandler = (area: IUserTemplateArea, areaType: EDragArea) => {
    if (!area) return;
    area.type = areaType;
    setActiveArea(area);
  };

  /*
  const getUserTemplate = () => {
    return {
      id,
      header: {
        ...userTemplateState.header,
        blocks: [...items.header]
      },
      main: {
        ...userTemplateState.main,
        blocks: [...items.main]
      },
      footer: {
        ...userTemplateState.footer,
        blocks: [...items.footer]
      },
      name: userTemplateState.name,
      customColor: userTemplateState.customColor,
      styles: {
        ...userTemplateState.styles
      },
      templateBlocks: items.addable.filter(
        item => item.blockType === EUserTemplateBlockType.SIMPLE
      )
    };
  }
  */

  const goToNextLocation = (redirect: string) => {
    setSaved(true);
    if (nextLocation) {
      history.push(nextLocation);
    } else {
      history.push(redirect);
    }
    setSaved(false);
  }

  const onSave = async () => {
    const mapBlocks = (block: IUserTemplateBlockDraggable) => {
      if (isUUID(block.id)) {
        block.id = null;
      }
      delete block.offerBlockItems;
      return block;
    };

    const newUserTemplate: IUserTemplate = {
      ...userTemplateState,
      header: {
        ...userTemplateState.header,
        blocks: userTemplateState.header.blocks.map(mapBlocks)
      },
      main: {
        ...userTemplateState.main,
        blocks: userTemplateState.main.blocks.map(mapBlocks)
      },
      footer: {
        ...userTemplateState.footer,
        blocks: userTemplateState.footer.blocks.map(mapBlocks)
      },
      templateBlocks: addableBlocks.filter(
        item => item.blockType === EUserTemplateBlockType.SIMPLE
      )
    };

    let success = false;
    if (isAdd) {
      success = await onSaveUserTemplate(newUserTemplate);
    } else {
      success = await onUpdateUserTemplate(id, newUserTemplate);
    }

    if (success) {
      goToNextLocation("/templates");
    }
  };

  const onDiscard = () => {
		discardUserTemplate();
    //goToNextLocation("/templates");
  };

  let saveText = null;
  if (userTemplate) {
    saveText = "Update template";
  } else {
    saveText = "Save template";
  }

  const copyUserTemplate = async () => {
    setConfirmDialog({
      onOk: async () => {
        const newUserTemplate = { ...userTemplate };
        const copyUserTemplate = await onCopyUserTemplate(newUserTemplate);
//        setPageView(EPageView.EDIT);
        setConfirmDialog(defaultConfirmDialog);
				goToNextLocation(`/templates/${copyUserTemplate.id}/${EPageView.COMBINED}`);
//        goToNextLocation(`/templates/${copyUserTemplate.id}/${EPageView.EDIT}`);

      },
      isOpen: true,
      title: "Are you sure?",
      content: "Do you want to copy the template?"
    });
  };

  const deleteUserTemplate = () => {
    setConfirmDialog({
      onOk: async () => {
        await onDeleteUserTemplate(userTemplate.id);
        setConfirmDialog(defaultConfirmDialog);
        goToNextLocation("/templates");
      },
      isOpen: true,
      title: "Are you sure?",
      content: "Deleting the template does not remove it from existing Contracts."
    });
  };

  const discardUserTemplate = () => {
    setConfirmDialog({
      onOk: async () => {
				setNextLocation("/templates");
	      setTimeout(()=>{history.push("/templates");},100);
      },
      isOpen: true,
      title: "Are you sure?",
			content: "Leave without saving changes?"
    });
  };

  // Called when reloading or closing tab
  useEffect(() => {
    window.onbeforeunload = () => true;
  }, []);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  // Called when routing
  const handleNavigation = (location: any) => {
    // console.log(location);
    if(nextLocation || saved) {
      window.onbeforeunload = undefined;
      return true;
    }
    if(!location.pathname.startsWith('/templates/')) {
      setDialogOpen(true);
      setNextLocation(location.pathname);
      return false;
    }
    return true;
  };

  return userTemplateLoading || initLoading ? (
    <ListSpinner />
  ) : userTemplateError ? (
    <Alert color="danger">{userTemplateError}</Alert>
  ) : (
    <React.Fragment>
{/*      <Prompt
        message={handleNavigation}
      />*/}
      <CustomDialog
        open={dialogOpen}
        title={"Are you sure?"}
        okButtonText="Save"
        cancelButtonText="Discard"
        onCancel={() => {
          // console.log("nextLocation", nextLocation);
          if (nextLocation) {
            history.push(nextLocation);
          }
          setDialogOpen(false);
        }}
        onOk={() => {
          onSave();
          setDialogOpen(false);
        }}
        noToggle
      >
        Do you want to save changes?
      </CustomDialog>

      <CustomDialog
        loading={false}
        onOk={confirmDialog.onOk}
        okButtonText={"OK"}
        onCancel={() => setConfirmDialog(defaultConfirmDialog)}
        open={confirmDialog.isOpen}
        title={confirmDialog.title}
        isValid={true}
      >
        {confirmDialog.content}
      </CustomDialog>
      
      <ContentWithSidebarStatic>
        <TemplateAreaContext.Provider
          value={{
            moveItem,
            onStartDrag,
            onEndDrag,
            blockClickHandler,
            activeBlock,
            activeArea,
            areaClickHandler
          }}
        >
            <React.Fragment>
							{pageView === EPageView.COMBINED && <>
								<OfferCombined
                	offer={dummyOffer}
                	isVisible={pageView === EPageView.COMBINED}
                	activeBlock={activeBlock}
                	setActiveBlock={setActiveBlock}
                	deleteBlock={deleteBlock}
                	updateBlock={updateBlock}
                	swapBlocks={swapBlocks}
                	addBlock={addBlock}
	                createBlock={onCreateBlock}
                	addableItems={addableBlocks}
                  organization={organization}
	              />
							</>}

							{pageView !== EPageView.COMBINED && <>

              <div
                className={classes.Content}
                style={{
                  display: pageView === EPageView.EDIT ? "block" : "none"
                }}
              >
                <TemplateArea
                  area={userTemplateState.header}
                  areaType={EDragArea.HEADER}
                  blocks={userTemplateState.header.blocks}
                />
                <hr className={classes.Line} />
                <TemplateArea
                  area={userTemplateState.main}
                  areaType={EDragArea.MAIN}
                  blocks={userTemplateState.main.blocks}
                />
                <hr className={classes.Line} />
                <TemplateArea
                  area={userTemplateState.footer}
                  areaType={EDragArea.FOOTER}
                  blocks={userTemplateState.footer.blocks}
                />
              </div>
              <OfferPreview
                offer={dummyOffer}
                isVisible={pageView === EPageView.PREVIEW}
                showDummyContent
              />
							</>}
            </React.Fragment>

            <SidebarStatic scrollable>
              <TemplateSidebar
                addableItems={addableBlocks}
                loading={blocksLoading || initLoading}
                activeBlock={activeBlock}
                setActiveBlock={setActiveBlock}
                activeArea={activeArea}
                setActiveArea={setActiveArea}
                updateBlock={updateBlock}
                deleteBlock={deleteBlock}
                userTemplate={userTemplateState}
                updateUserTemplate={updateUserTemplate}
                onCreateBlock={onCreateBlock}
								onViewChanged={viewClickHandler}
								organization={organization}
                userGroups={userGroups}
                currentUser={currentUser}
              />
            </SidebarStatic>
          
        </TemplateAreaContext.Provider>
      </ContentWithSidebarStatic>
      <FooterFixed>
        <TemplateFooter
          pageView={pageView}
          onViewChanged={viewClickHandler}
          onSave={onSave}
          saveText={saveText}
          onDiscard={onDiscard}
          isValid={userTemplateState && userTemplateState.name ? true : false}
          isAdd={isAdd}
          onDelete={deleteUserTemplate}
          onCopy={copyUserTemplate}
        />
      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    blocks: state.blocks.blocks,
    blocksLoading: state.blocks.loading,
    blocksError: state.blocks.error,
    userTemplate: state.userTemplates.userTemplate,
    userTemplateLoading: state.userTemplates.loading,
    userTemplateError: state.userTemplates.error,
    organization: state.organizations.organization,
    customers: state.customers.customers,
    userGroups: state.userGroups.userGroups,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindBlocks: () => dispatch(actions.findBlocks()),
    onGetUserTemplate: id => dispatch(actions.getUserTemplate(id)),
    onSaveUserTemplate: userTemplate =>
      dispatch(actions.saveUserTemplate(userTemplate)),
    onUpdateUserTemplate: (id, userTemplate) =>
      dispatch(actions.updateUserTemplate(id, userTemplate)),
    onFindBlockItems: (id, fetchSize) =>
      dispatch(actions.findBlockItems(id, fetchSize)),
    onGetUserOrganization: () => dispatch(actions.getUserOrganization()),
    onFindCustomers: () => dispatch(actions.findCustomers()),
    onDeleteUserTemplate: id => dispatch(actions.deleteUserTemplate(id)),
    onCopyUserTemplate: userTemplate => dispatch(actions.copyUserTemplate(userTemplate)),
    onFindUserGroups: organizationId => dispatch(actions.findUserGroups(organizationId)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTemplate);
