  const dev={
  PROCOUNTOR: {
    //URL:"https://integrations-dot-c2ctest-203405.ey.r.appspot.com",
    //REDIRECT_URI:"https%3A%2F%2Fintegrations-dot-c2ctest-203405.ey.r.appspot.com%2Fredirect",
    URL:"http://localhost:8082",
    REDIRECT_URI:"http%3A%2F%2Flocalhost:8082%2Fredirect",

    //API_URL:"https://api-test.procountor.com",
    API_URL:"https://pts-procountor.pubdev.azure.procountor.com",
    CLIENT_ID:"click2contractTestClient",

  },

  PIPEDRIVE: {
    //BASEURL:"http://localhost:8082/pipedrive",
    BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/pipedrive",
  },

  HUBSPOT: {
    //BASEURL:"https://integrations-dot-c2ctest-203405.ey.r.appspot.com/hubspot",
    BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/hubspot",
  },

  SEVERA: {
    //BASEURL:"https://integrations-dot-c2ctest-203405.ey.r.appspot.com/severa",
    BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/severa",
  },

  GOOGLE: {
    //BASEURL:"https://integrations-dot-c2ctest-203405.ey.r.appspot.com/google",
    BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/google",
  },

  NETVISOR: {
    //URL:"http://localhost:8082/netvisor",
    URL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/netvisor",
  },

  SALESFORCE: {
    BASEURL: "http://localhost:8082/salesforce",
    //BASEURL: "https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/salesforce",

    //CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFdBfj5DYt6VUkIhKPEzuJNXhBHIzbf39SNWIFkveBW4OztvfEuy_6JU8yUuf",
    //CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFS1EdGagI8JDa.eSSFVu8qzz4HPiYAcpnWjQEKLUDdZO_AxU0BUaxbjGZPIx", // java17 tuhottu
    //CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFYbKbmexqePxDRo83AaemuV3o.fXNv02dUuNdNMes9kpej6G7g4CoNIGnIpm", // java17 tuhottu2
    CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFQlGCnDF.sH1nKcNafe_s6eYVOq1J0jTPVIBswhLGyhukaUlU8gOkidO6_17", // java17
  },

  DYNAMICS: {
    BASEURL:"http://localhost:8082/dynamics",
    TENANT_ID : '58fe6439-1bf3-4cf6-942e-8e988300e38a',
    CLIENT_ID : 'ad7011d5-6fef-4e38-bf07-79770ff235e1',
    SCOPE : "api://ad7011d5-6fef-4e38-bf07-79770ff235e1/API.ReadWrite.All",
    WEBRESOURCE_URI : "http://localhost:3000/embedded/edit/add/deal/",
  },

  STRIPE_PUBLIC_KEY : "pk_test_FxkP9ccFi40lQz0F2GnY9dUH0094tQv2sk",

  //**TINYMCE_APIKEY : "nakhfdhqero9d1txenyrq0ckdh6oszbz18eq9c89kyf280xj",
  //TINYMCE_APIKEY:"g48e4i7sk1m51jn6apo2mn00w5e4185jkxptwqj3r9ie6hcr", //EASOFT

  CUSTOMIZED_URI : "http://localhost:8082/customized",
  //CUSTOMIZED_URI : "https://j10-dot-integrations-dot-legalri-164410.ew.r.appspot.com/customized",

}

const c2ctest={
  PROCOUNTOR: {
    URL:"https://integrations-dot-legalri-164410.ew.r.appspot.com",

    //REDIRECT_URI:"https%3A%2F%2Fintegrations-dot-legalri-164410.ew.r.appspot.com%2Fredirect%2F",
    REDIRECT_URI:"https%3A%2F%2Fintegrationsjava17-dot-legalri-164410.ew.r.appspot.com%2Fredirect%2F", // PRODUCTION JAVA17
    API_URL:"https://api.procountor.com",

    CLIENT_ID:"click2contractClient",
  },

  PIPEDRIVE: {
    //BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/pipedrive", // PRODUCTION 
    BASEURL:"https://integrationsjava17-dot-integrations-dot-legalri-164410.ew.r.appspot.com/pipedrive", // PRODUCTION JAVA17
    //BASEURL:"https://integrationsbeta-dot-integrations-dot-legalri-164410.ew.r.appspot.com/pipedrive",   // BETA
    //BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/pipedrive",  // java17
  },

  HUBSPOT: {
    //BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/hubspot", // PRODUCTION 
    BASEURL:"https://integrationsjava17-dot-integrations-dot-legalri-164410.ew.r.appspot.com/hubspot", // PRODUCTION JAVA17
    //BASEURL:"https://integrationsbeta-dot-integrations-dot-legalri-164410.ew.r.appspot.com/hubspot",   // BETA
    //BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/hubspot",  // java17
  },

  SEVERA: {
    //BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/severa", // PRODUCTION 
    BASEURL:"https://integrationsjava17-dot-integrations-dot-legalri-164410.ew.r.appspot.com/severa", // PRODUCTION JAVA17
    //BASEURL:"https://integrationsbeta-dot-integrations-dot-legalri-164410.ew.r.appspot.com/severa",   // BETA
    //BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/severa",  // java17
  },

  GOOGLE: {
    //BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/google", // PRODUCTION 
    BASEURL:"https://integrationsjava17-dot-integrations-dot-legalri-164410.ew.r.appspot.com/google", // PRODUCTION JAVA17
    //BASEURL:"https://integrationsbeta-dot-integrations-dot-legalri-164410.ew.r.appspot.com/google",   // BETA
    //BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/google",  // java17
  },

  NETVISOR: {
    //URL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/netvisor",           // PRODUCTION
    URL:"https://integrationsjava17-dot-integrations-dot-legalri-164410.ew.r.appspot.com/netvisor", // PRODUCTION JAVA17
    //URL:"https://integrationsbeta-dot-integrations-dot-legalri-164410.ew.r.appspot.com/netvisor", // BETA
    //URL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/netvisor",  // java17
  },

  SALESFORCE: {
    //BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/salesforce", // PRODUCTION 
    BASEURL:"https://integrationsjava17-dot-integrations-dot-legalri-164410.ew.r.appspot.com/salesforce", // PRODUCTION JAVA17
    //BASEURL:"https://integrationsbeta-dot-integrations-dot-legalri-164410.ew.r.appspot.com/salesforce",   // BETA
    //BASEURL:"https://integrationsjava17-dot-integrations-dot-c2ctest-203405.ey.r.appspot.com/salesforce",  // java17

    //CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFdBfj5DYt6VUkIhKPEzuJNXhBHIzbf39SNWIFkveBW4OztvfEuy_6JU8yUuf", // PRODUCTION
    //CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFS1EdGagI8JDa.eSSFVu8qzz4HPiYAcpnWjQEKLUDdZO_AxU0BUaxbjGZPIx", // java17 tuhottu
    //CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFYbKbmexqePxDRo83AaemuV3o.fXNv02dUuNdNMes9kpej6G7g4CoNIGnIpm", // java17 tuhottu2
    CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFQlGCnDF.sH1nKcNafe_s6eYVOq1J0jTPVIBswhLGyhukaUlU8gOkidO6_17", // java17    
  },
  DYNAMICS: {
    BASEURL:"https://integrationsjava17-dot-integrations-dot-legalri-164410.ew.r.appspot.com/dynamics", // PRODUCTION JAVA17
    //BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/dynamics", // PRODUCTION 
    TENANT_ID : '58fe6439-1bf3-4cf6-942e-8e988300e38a',
    CLIENT_ID : 'ad7011d5-6fef-4e38-bf07-79770ff235e1',
    SCOPE : "api://ad7011d5-6fef-4e38-bf07-79770ff235e1/API.ReadWrite.All",
    WEBRESOURCE_URI : "http://localhost:3000/embedded/edit/add/deal/",
  },

  STRIPE_PUBLIC_KEY : "pk_test_FxkP9ccFi40lQz0F2GnY9dUH0094tQv2sk", // java17
  //STRIPE_PUBLIC_KEY : "pk_live_meSJIuMpvzx2hMcXzcSnpIvj00gFj0WuHf", // PRODUCTION

  //TINYMCE_APIKEY:"wgrzpjbk59ab82y4io0gexcznri422zz7aon4wpeo5b93fb3",  // PRODUCTION
  //TINYMCE_APIKEY:"nakhfdhqero9d1txenyrq0ckdh6oszbz18eq9c89kyf280xj",  //java17
  //**TINYMCE_APIKEY:"g48e4i7sk1m51jn6apo2mn00w5e4185jkxptwqj3r9ie6hcr", //EASOFT

  CUSTOMIZED_URI : "https://integrations-dot-legalri-164410.ew.r.appspot.com/customized", 
}

const production={
  PROCOUNTOR: {
    URL:"https://integrations-dot-legalri-164410.ew.r.appspot.com",
    REDIRECT_URI:"https%3A%2F%2Fintegrations-dot-legalri-164410.ew.r.appspot.com%2Fredirect%2F",
    API_URL:"https://api.procountor.com",
    CLIENT_ID:"click2contractClient",
  },

  PIPEDRIVE: {
    BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/pipedrive", // PRODUCTION 
  },

  HUBSPOT: {
    BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/hubspot", // PRODUCTION 
  },

  SEVERA: {
    BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/severa", // PRODUCTION 
  },

  GOOGLE: {
    BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/google", // PRODUCTION 
  },

  NETVISOR: {
    URL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/netvisor",           // PRODUCTION
  },

  SALESFORCE: {
    BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/salesforce", // PRODUCTION 
//    CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFdBfj5DYt6VUkIhKPEzuJNXhBHIzbf39SNWIFkveBW4OztvfEuy_6JU8yUuf", // PRODUCTION !! DELETED !! EI TOIMI UUSILLE
    CLIENT_ID: "3MVG9t0sl2P.pByqBEmOq5l5pFQlGCnDF.sH1nKcNafe_s6eYVOq1J0jTPVIBswhLGyhukaUlU8gOkidO6_17", // java17    
  },
  DYNAMICS: {
    BASEURL:"https://integrations-dot-legalri-164410.ew.r.appspot.com/dynamics", // PRODUCTION 
    TENANT_ID : '58fe6439-1bf3-4cf6-942e-8e988300e38a',
    CLIENT_ID : 'ad7011d5-6fef-4e38-bf07-79770ff235e1',
    SCOPE : "api://ad7011d5-6fef-4e38-bf07-79770ff235e1/API.ReadWrite.All",
    WEBRESOURCE_URI : "http://localhost:3000/embedded/edit/add/deal/",
  },
  STRIPE_PUBLIC_KEY : "pk_live_meSJIuMpvzx2hMcXzcSnpIvj00gFj0WuHf", // PRODUCTION
  //TINYMCE_APIKEY:"wgrzpjbk59ab82y4io0gexcznri422zz7aon4wpeo5b93fb3",  // PRODUCTION
  ////**TINYMCE_APIKEY:"g48e4i7sk1m51jn6apo2mn00w5e4185jkxptwqj3r9ie6hcr", //EASOFT

  CUSTOMIZED_URI : "https://integrations-dot-legalri-164410.ew.r.appspot.com/customized",
  //CUSTOMIZED_URI : "https://j10-dot-integrations-dot-legalri-164410.ew.r.appspot.com/customized",
}



//export const config = process.env.NODE_ENV === "development" ? dev:c2ctest;
export const config = process.env.NODE_ENV === "development" ? dev:production;
