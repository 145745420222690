import { IOffer, ISignature } from './../interfaces/domain';
import { EOfferStatus, ESignerType } from './../enums/index';

export const defaultOffer: IOffer = {
    created: null,
    customer: null,
    id: null,
    name: "",
    author: null,
    status: null,
    updated: null,
    userTemplate: null,
    expirationDate: null,
    email: null,
    comments: null,
    organization: null,
    recipients: null,
    isExpiring: false,
    isExpired: false,
    hasAttachments: false,
    history: null,
    viewsCount: 0,
    archived: false,
    hash: null,
    link: null,
    printLink: null,
    downloadLink: null,
    lastSender: null,
    confirmDate: null,
    hideAuditLogs: true,
    customerName: null,
    authorName: null,
    customerSignature: null,
		strongAuthentication: null,
		signedPDF: null,
    hasSignature: false,

    offerIntegrationData: null,
  };



export const hasOfferStatus = (
  status: EOfferStatus,
  ...statuses: Array<EOfferStatus>
) => {
  if (!status || !statuses) return false;
  return statuses.indexOf(status) !== -1;
};

export const isOfferDone = (offer: IOffer) => {
  if (!offer) return false;
  return hasOfferStatus(
    offer.status,
    EOfferStatus.CONFIRMED,
    EOfferStatus.TERMINATED
  );
};

export const isOfferSigned = (offer: IOffer) => {
  if (!offer) return false;
  const s = getOfferSigners(offer).filter((ob:ISignature)=>ob.signerType!==ESignerType.USER && ob.timestamp);
  return s.length > 0;
}

export const getOfferSigners = (offer:IOffer) => {
    if(offer && offer.userTemplate && offer.userTemplate.main && offer.userTemplate.main.blocks) {
      const blocks = [...(offer.userTemplate.header.blocks || []), ...(offer.userTemplate.main.blocks || []), ...(offer.userTemplate.footer.blocks || [])];
      //const blocks = offer.userTemplate.main.blocks || [];
      const signatures = blocks.reduce((resp,block:any)=>{
        if(block.content) {
          const t=block.content.filter((ob:any)=>ob.contentType==="SIGNATURES");
          return t.reduce((r:any,x:any)=>{ return [...r,...x.signatures] },resp);
        }
        return resp;
      },[]);
      return signatures;
    }
    return [];
}
