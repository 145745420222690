import { IImportCustomersAction, IImportCustomersState } from '../../interfaces';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';

const initialState: IImportCustomersState = {
  customersImport: null,
  loading: false,
  error: null
};

const uploadCustomersFail = (state:IImportCustomersState, action:IImportCustomersAction):IImportCustomersState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const uploadCustomersStart = (state:IImportCustomersState, action:IImportCustomersAction):IImportCustomersState => {
  return updateObject(state, { error: null, loading: true });
}

const uploadCustomersSuccess = (state:IImportCustomersState, action:IImportCustomersAction):IImportCustomersState => {
  return updateObject(state, {
      customersImport: action.customersImport,
      error: null,
      loading: false
  });
}

const importCustomersFail = (state:IImportCustomersState, action:IImportCustomersAction):IImportCustomersState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const importCustomersStart = (state:IImportCustomersState, action:IImportCustomersAction):IImportCustomersState => {
  return updateObject(state, { error: null, loading: true });
}

const importCustomersSuccess = (state:IImportCustomersState, action:IImportCustomersAction):IImportCustomersState => {
  return updateObject(state, {
      error: null,
      loading: false
  });
}

const clearCustomersImport = (state:IImportCustomersState, action:IImportCustomersAction):IImportCustomersState => {
  return updateObject(state, {
      customersImport: null
  });
};

const reducer = (state:IImportCustomersState = initialState, action:IImportCustomersAction):IImportCustomersState => {
    
  switch (action.type) {
      case ActionTypes.UPLOAD_CUSTOMERS_START : return uploadCustomersStart(state, action);
      case ActionTypes.UPLOAD_CUSTOMERS_SUCCESS : return uploadCustomersSuccess(state, action);
      case ActionTypes.UPLOAD_CUSTOMERS_FAIL : return uploadCustomersFail(state, action);
      case ActionTypes.IMPORT_CUSTOMERS_START : return importCustomersStart(state, action);
      case ActionTypes.IMPORT_CUSTOMERS_SUCCESS : return importCustomersSuccess(state, action);
      case ActionTypes.IMPORT_CUSTOMERS_FAIL : return importCustomersFail(state, action);
      case ActionTypes.CLEAR_CUSTOMERS_IMPORT : return clearCustomersImport(state, action);
      default: return state;

  }
}

export default reducer;
