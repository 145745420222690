export * from "./user-roles";
export * from "./content-types";

export enum EOfferStatus {
  DRAFT = "DRAFT",
  SENT = "SENT",
  SELFSENT = "SELFSENT",
  CONFIRMED = "CONFIRMED",
  OPENED = "OPENED",
  DECLINED = "DECLINED",
  TERMINATED = "TERMINATED",
  EXPIRED = "EXPIRED"
}

export enum EPageView {
  FUNNEL = "funnel",
  LIST = "list",
  EDIT = "edit",
  PREVIEW = "preview",
	COMBINED = "combined",
  SENDEMAIL = "sendemail",
}

export enum EOrganizationLevel {
  BASIC = "BASIC",
  PREMIUM = "PREMIUM",
  ENTERPRISE = "ENTERPRISE"
}

export enum EUserTemplateBlockType {
  DYNAMIC = "DYNAMIC",
  SIMPLE = "SIMPLE"
}

export enum ESearchType {
  CONTRACTS = "contracts",
  CUSTOMERS = "customers",
  OFFERS = "offers",
  USERS = "users",
  CONTACTS = "contacts"
}

export enum EDropzoneType {
  CSV = ".csv",
  IMG = "image/*",
  PDF = ".pdf"
}

export enum EContentMode {
  PREVIEW,
  EDIT
}

export enum ECurrency {
  eur
}

export enum ESubscriptionInterval {
  month,
  year
}

export enum ESubscriptionStatus {
  active,
  pending,
  canceled
}

export enum EPaymentMethodLastChargeStatus {
  success,
  fail,
  expired
}

export enum EPaymentMethodType {
  card,
  billing
}

export enum ECardBrand {
  AMEX = "American Express",
  DINERSCLUB = "Diners Club",
  DISCOVER = "Discover",
  JCB = "JCB",
  MASTERCARD = "MasterCard",
  UNIONPAY = "UnionPay",
  VISA = "Visa",
  UNKNOWN = "unknown"
}

export enum EMessageStatus {
  error,
  sent,
  delivered,
  delayed,
  terminated
}

export enum EVisualType {
  IMAGE = "IMAGE",
  PDF = "PDF",
  VIDEO = "VIDEO",
  DYNAMIC_PDF = "DYNAMIC_PDF"
}

export enum EVideoSource {
  YOUTUBE = "YOUTUBE",
  VIMEO = "VIMEO"
}

export enum ELanguage {
  ENGLISH = "en",
  FINNISH = "fi",
  SWEDISH = "se",
  GERMAN = "de",
  SPANISH = "es",
  FRENCH = "fr",
  DUTCH = "nl",
  DANISH = "da",
  PORTUGUESE = "pt",
}

export enum EAuditLogAction {
  OFFER_GET_BY_TOKEN = "OFFER_GET_BY_TOKEN",
  OFFER_GET = "OFFER_GET",
  OFFER_SENT = "OFFER_SENT",
  OFFER_CONFIRM = "OFFER_CONFIRM",
  OFFER_COMMENT = "OFFER_COMMENT",
  OFFER_DECLINE = "OFFER_DECLINE",
  OFFER_CONFIRM_CODE_SENT = "OFFER_CONFIRM_CODE_SENT",
  OFFER_TERMINATE = "OFFER_TERMINATE",
	OFFER_SIGNED_VISMASIGN = "OFFER_SIGNED_VISMASIGN",
	OFFER_CUSTOMER_UPDATED = "OFFER_CUSTOMER_UPDATED",
  OFFER_SIGNED = "OFFER_SIGNED",
}

export enum ESigner {
  AUTHOR = "AUTHOR",
  CONTACT_PERSON = "CONTACT_PERSON"
}

export enum EFields {
	LEFT="leftFields",
	RIGHT="rightFields"
}

export enum ESignType {
	NORMAL="NORMAL",
	VISMA="VISMA",
	SMS="SMS",
}

export enum ETaxTypes {
	INCLUSIVE="INCLUSIVE",
	EXCLUSIVE="EXCLUSIVE",
}

export enum ESignerType {
  USER = "user",
  CONTACT = "contact",
  OTHER = "other",
  WATCH = "watch",
}
