import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  name: string;
}

const DialogText: React.FC<IProps> = ({ name }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <p>{t('signOfferDialog.confirmPrefix')} </p>
      <p>{t('signOfferDialog.confirmMid')}</p>
      <ul>
        {/*<li>{t('signOfferDialog.confirmLi1', { name })}</li>*/}
        <li>{t('signOfferDialog.confirmLi2')}</li>
        <li>{t('signOfferDialog.confirmLi3')}</li>
      </ul>
    </React.Fragment>
  );
};

export default DialogText;
