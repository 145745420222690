import React, { useEffect, useState } from "react";
import CustomButtonGroup from "../../../../../../../../components/UI/CustomButtonGroup/CustomButtonGroup";
import SidebarCollapse from "../../../../../../../../components/UI/SidebarCollapse/SidebarCollapse";
import { ELayoutType, IUserTemplateBlockDraggable } from "../../../../../../../../interfaces";
import { controlsToFormGroups, defaultInputChangedHandler, getFormData, initForm } from "../../../../../../../../shared/utility";

import classes from "./TemplateSidebarEditLayout.module.scss";

interface IProps {
  id: string;
  title: string;
  data: IUserTemplateBlockDraggable;
  update: (data: Object) => void;
}

const marginControls = {
  controls: {
    marginVertical: {
      elementType: "input",
      elementConfig: {
        type: "number"
      },
      valid: true,
      touched: false,
      value: "0"
    },
    marginHorizontal: {
      elementType: "input",
      elementConfig: {
        type: "number"
      },
      valid: true,
      touched: false,
      value: "0"
    }
  },
  formIsValid: false
};

const paddingControls = {
  controls: {
    paddingVertical: {
      elementType: "input",
      elementConfig: {
        type: "number"
      },
      valid: true,
      touched: false,
      value: "0"
    },
    paddingHorizontal: {
      elementType: "input",
      elementConfig: {
        type: "number"
      },
      valid: true,
      touched: false,
      value: "0"
    }
  },
  formIsValid: false
};

const TemplateSidebarEditLayout: React.FC<IProps> = ({
  id,
  title,
  data,
  update
}) => {
  const [type, setType] = useState<ELayoutType>(ELayoutType.HORIZONTAL);

  const [marginState, setMarginState] = useState(marginControls);
  const [paddingState, setPaddingState] = useState(paddingControls);

  useEffect(() => {
    setMarginState(initForm(marginControls.controls, data.styles));
    setPaddingState(initForm(paddingControls.controls, data.styles));
    setType(data.layoutType);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    update({layoutType: type});
    // eslint-disable-next-line
  }, [type]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string,
    state: Object,
    setState: Function
  ) => {
    const validation = defaultInputChangedHandler(
      event,
      controlName,
      state,
      setState
    );
    update(getFormData(validation.controls));
  };

  const items = [
    {
      type: ELayoutType.HORIZONTAL,
      text: "Horizontal"
    },
    {
      type: ELayoutType.VERTICAL,
      text: "Vertical"
    }
  ];

  return (
    <SidebarCollapse title={`${title} layout`}>
      <label>Layout type</label>
      <CustomButtonGroup
        items={items}
        clicked={setType}
        selected={type}
        size="sm"
        style={{ display: "flex", marginBottom: "0.5rem" }}
      />

      {(() => {
        switch (type) {
          case ELayoutType.HORIZONTAL:
            return (
              <div>
                <p className="text-muted">
                  <small>
                    Columns will be positioned horizontally
                  </small>
                </p>
              </div>
            );
          case ELayoutType.VERTICAL:
            return (
              <div>
                <p className="text-muted">
                  <small>
                    Columns will be positioned vertically
                  </small>
                </p>
              </div>
            )
          default:
            return null;
        }
      })()}
      <div className={classes.Group}>
        <label>Margin</label>
        {controlsToFormGroups(
          marginState.controls,
          (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
            inputChangedHandler(event, controlName, marginState, setMarginState)
        )}
      </div>
      <div className={classes.Group}>
        <label>Padding</label>
        {controlsToFormGroups(
          paddingState.controls,
          (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
            inputChangedHandler(
              event,
              controlName,
              paddingState,
              setPaddingState
            )
        )}
      </div>
    </SidebarCollapse>
  );
};

export default TemplateSidebarEditLayout;
