import React, { useEffect, useState } from 'react';
import { IIntegrations, IOrganization, IUser } from '../../../../../interfaces';

import { Button, Row, Col, CustomInput, Input } from 'reactstrap';
import axios from 'axios';

import { config } from '../../../../../constants';
import classes from "../Integrations.module.scss";

const hostname = window && window.location && window.location.hostname;

let BASEURL=config.SEVERA.BASEURL;
if(hostname === 'localhost') {
  BASEURL="http://localhost:8081/severa";
}




interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  updateOrganization: (x:any)=>void;

}

interface IProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
  updateOrganization: (x:any)=>void;
  params: any;
}

export const getDeal:any = async (integrations:IIntegrations, user:IUser, deal:string) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deal?deal='+deal+'&token='+integrations.severa.client_secret+"&client="+integrations.severa.client_id+"&email="+encodeURIComponent(user.email));
  return res.data;
}


export const getDeals:any = async (integrations:IIntegrations, user:IUser) => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  const res=await axios.get(BASEURL+'/deals?stage='+integrations.severa.stageId+'&token='+integrations.severa.client_secret+"&client="+integrations.severa.client_id+"&email="+encodeURIComponent(user.email))
  return res.data;
}


const Severa: React.FC<IProps> = ({
  currentUser,
  organization,
  updateOrganization,

  params,
}) => {

  const [settings, setSettings]=useState<any>({});

  const [stages, setStages] = useState<Array<String>>([]);

  const integrations=organization && organization.integrations;

  const updateSevera = (updated:any) => {
    setSettings({...settings,...updated});
  }

  const saveChanges = () => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        severa:{
          ...organization.integrations.severa,
          ...settings,
        }  
      }      
    }
    updateOrganization(newo);
  }

  useEffect(()=>{
    setSettings(integrations.severa);
  },[]);

/*
  useEffect(() => {
    if(organization && params.token && params.name==="severa") {
      const newo= {...organization,
        integrations:{
          ...organization.integrations,
          severa:{
            ...organization.integrations.severa,
            active:true,
            refreshToken:params.token as string,
          }
        }
      }

      updateOrganization(newo);
    }
    // eslint-disable-next-line
  }, [organization]);
*/

  const getStages = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get(BASEURL+'/stages?token='+integrations.severa.client_secret+"&client="+integrations.severa.client_id).then((resp)=>{
      //console.log("resp",resp)
      setStages(resp.data)
    })
    .catch((resp)=> {
      //console.log("error",resp);
    })
  }

  const setStage = (key:any) => {
    updateSevera({stageId:key,stageName:stages[key]})
  }

  const setOpenStage = (key:any) => {
    updateSevera({openStageId:key,openStageName:stages[key] || null})
  }

  const setSentStage = (key:any) => {
    updateSevera({sentStageId:key,sentStageName:stages[key] || null})
  }
  
  const setNewStage = (key:any) => {
    updateSevera({newStageId:key,newStageName:stages[key] || null})
  }
 
    /*
  const getDeals = () => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get('http://localhost:8082/deals?stage='+integrations.hubspot.stageId+'&token='+integrations.hubspot.refreshToken).then((resp)=>{
      console.log("resp",resp)
      setDeals(resp.data)
    })
    .catch((resp)=> {
      console.log("error",resp);
    })
  }
     */

  return (
    <React.Fragment>
          <div className={classes.integration}>

      <Row>
        <Col>
      			<h5>Severa</h5>
            <CustomInput
              type="switch"
              label="Is Active"
              id="severaActiveId"
    			  	onChange={(e:any)=>{updateSevera({active:!settings.active})}}
    				  checked={settings.active}
            />

            {integrations.severa.stageId && <div>Get deals from: {(integrations.severa.stageName || "").toUpperCase()}</div>}
            {integrations.severa.sentStageId && <div>Move sent deals to: {(integrations.severa.sentStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setSentStage(null)}}>dont move</Button></div>}
            {integrations.severa.openStageId && <div>Move opened deals to: {(integrations.severa.openStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setOpenStage(null)}}>dont move</Button></div>}
            {integrations.severa.newStageId && <div>Move confirmed deals to: {(integrations.severa.newStageName || "").toUpperCase()} <Button size="sm" onClick={()=>{setNewStage(null)}}>dont move</Button></div>}


{/*
            <Input
              type="switch"
              label="Upload Contracts"
              id="severaSendContractId"
    			  	onChange={(e:any)=>{updateSevera({sendContract:!integrations.severa.sendContract})}}
    				  checked={integrations.severa.sendContract}
            />
*/}
            <Input value={settings.client_id} onChange={(e:any)=>updateSevera({client_id:e.target.value})} placeholder="Client ID: ..."/>
            <Input value={settings.client_secret} onChange={(e:any)=>updateSevera({client_secret:e.target.value})} placeholder="Client SECRET: ..."/>

            {JSON.stringify(integrations.severa) !== JSON.stringify(settings) &&
            <Button onClick={saveChanges}>Save</Button>}


            <br/>
            {/*<Button size="sm" color={integrations.severa.refreshToken?"success":"warning"} href={BASEURL+"/install"}>Severa authentication</Button>
            {' '}*/}<Button size="sm" color="primary" onClick={getStages}>Get stages</Button>
         
        </Col>
        
        <Col>
          <div>{Object.keys(stages).map((key:any)=>(<div key={key}>
              <Button size="sm"
                color={key===integrations.severa.stageId?"success":"secondary"}
                value={key}
                onClick={()=>setStage(key)}
            >get deals from
            </Button>

            <Button size="sm"
                color={key===integrations.severa.sentStageId?"success":"secondary"}
                value={key}
                onClick={()=>setSentStage(key)}
            >sent
            </Button>

            <Button size="sm"
                color={key===integrations.severa.openStageId?"success":"secondary"}
                value={key}
                onClick={()=>setOpenStage(key)}
            >opened
            </Button>

            <Button size="sm"
                color={key===integrations.severa.newStageId?"success":"secondary"}
                value={key}
                onClick={()=>setNewStage(key)}
            >confirmed
            </Button>

              {stages[key].toUpperCase()}
          </div>))}

            </div>
        </Col>
        
      </Row>
      </div>


    </React.Fragment>
  );
};

export default Severa;

