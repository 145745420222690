import React, { useEffect, useState } from 'react';

import { IUserTemplate, IOrganization } from '../../../../../../../../interfaces';
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData,
  initForm,
} from '../../../../../../../../shared/utility';

import classes from "../../../../../../../../components/UI/SidebarCollapse/SidebarCollapse.module.scss";

interface IProps {
  userTemplate: IUserTemplate;
	update: (data:Object) => void;
	organization: IOrganization;
}

const initControls = {
  controls: {
    email_title: {
      elementType: "input",
      elementConfig: {
        label: "Title",
        placeholder: "Email title",
        type: "text"
      },
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      value: ""
    },
    email_body: {
      elementType: "input",
      elementConfig: {
        label: "Body",
        placeholder: "Email body",
        type: "textarea"
      },
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      value: ""
    },
    email_signature: {
      elementType: "input",
      elementConfig: {
        label: "Signature",
        placeholder: "Email signature",
        type: "textarea"
      },
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      value: ""
    },

  },
  formIsValid: false
}

const EmailTemplates: React.FC<IProps> = ({ userTemplate, update, organization }) => {
  const [controlsState, setControlsState] = useState(initControls);

  useEffect(() => {
    setControlsState(initForm(initControls.controls, {...userTemplate, ...userTemplate.settings}));
    // eslint-disable-next-line
  }, [])

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(event, controlName, controlsState, setControlsState);
  };

  const onBlurHandler = () => {
  	update(getFormData(controlsState.controls));
  }

  return (
    <React.Fragment>
      <hr/>
      <div className={classes.Header}>
        <p>Email template</p>
      </div>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler, onBlurHandler)}
    </React.Fragment>
  );
};

export default EmailTemplates;
