import React from "react";
//import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from '../../../../../withRouter';
import Title from "../../../../../components/UI/Typography/Title";
import { EOfferStatus, EPageView } from "../../../../../enums";
import { IOffer, IUserGroup, IUser } from "../../../../../interfaces";
import OfferCard from "../Offer/OfferCard/OfferCard";
import classes from "./OffersFunnel.module.scss";

interface IProps extends RouteComponentProps { //<any> {
  offers: Array<IOffer>;
  userGroups: Array<IUserGroup>;
  currentUser: IUser;
}

const OffersFunnel: React.FC<IProps> = ({ offers, history, userGroups, currentUser }) => {
  offers = offers && offers.filter(offer => !offer.archived);

  const onClick = (offer: IOffer) => {
    history.push(`/offers/${offer.id}/${EPageView.PREVIEW}`);
  };

  const mapOffers = (statuses: Array<EOfferStatus>, isExpiring: boolean) => {
    return (
      <React.Fragment>
        {offers.map(offer =>
          offer.status === EOfferStatus.CONFIRMED //|| offer.isExpired
            ? null
            : statuses.indexOf(offer.status) !== -1 &&
              offer.isExpiring === isExpiring && (
                <OfferCard
                  key={offer.id}
                  onClick={() => onClick(offer)}
                  offer={offer}
                  isExpiring={isExpiring}
                  userGroups={userGroups}
                  currentUser={currentUser}
                />
              )
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={classes.funnelContainer}>
      <div className={classes.BeforeHeader}></div>
      <div className={classes.DraftHeader}>
        <span>Draft</span>
      </div>
      <div className={classes.SentHeader}>
        <div className={classes.arrow}></div>
        <span>Sent</span>
      </div>
      <div className={classes.OpenedHeader}>
        <div className={classes.arrow}></div>
        <span>Opened</span>
      </div>
      <div className={classes.ExpiredHeader}>
        <div className={classes.arrow}></div>
        <span>Expiring</span>
      </div>
      <div className={classes.AfterHeader}></div>
      {offers && offers.length > 0 ? (
        <React.Fragment>
          <div className={classes.DraftContent}>{mapOffers([EOfferStatus.DRAFT], false)}</div>
          <div className={classes.SentContent}>{mapOffers([EOfferStatus.SENT, EOfferStatus.SELFSENT], false)}</div>
          <div className={classes.OpenedContent}>{mapOffers([EOfferStatus.OPENED, EOfferStatus.DECLINED], false)}</div>
          <div className={classes.ExpiredContent}>
            {mapOffers(
              [
                EOfferStatus.DRAFT,
                EOfferStatus.SENT,
                EOfferStatus.OPENED,
                EOfferStatus.DECLINED
              ],
              true
            )}
          </div>
        </React.Fragment>
      ) : (
          <div className={classes.noOffers}>
            <Title>
              Your topical proposals will be visible here.
{/*            <br />
              Click <Link to="?product_tour_id=85923">here</Link> to start How to use -tour*/}
            </Title>
          </div>
        )}
    </div>
  );
};

export default withRouter(OffersFunnel);
