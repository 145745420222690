import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Input, ListGroup, ListGroupItem } from "reactstrap";
import { ESearchType } from "../../../enums";
import { connect } from "react-redux";
import { IAppState, ICustomer, IUser, ISearchOffer } from "../../../interfaces";
import * as actions from '../../../store/actions';
import EUserRole from "../../../enums/user-roles";

import classes from "./Search.module.scss";

interface IStateProps {
  // contracts: Array<>,
  customers: Array<ICustomer>,
  offers: Array<ISearchOffer>,
  users: Array<IUser>,
  currentUser: IUser;
}

interface IDispatchProps {
  // onClearContractsSearch: () => void;
  onClearCustomersSearch: () => void;
  onClearOffersSearch: () => void;
  onClearUsersSearch: () => void;
}

interface IProps extends IStateProps, IDispatchProps {
  type: ESearchType;
  onChange: (queryString: string) => void;
  onClick: (id: string, obj?:any) => void;
}

const Search: React.FC<IProps> = ({
  type,
  onChange,
  onClick,
  // contracts,
  customers,
  offers,
  users,
  // onClearContractsSearch,
  onClearCustomersSearch,
  onClearOffersSearch,
  onClearUsersSearch,
  currentUser
}) => {

  useEffect(() => {
    onClearCustomersSearch();
    onClearOffersSearch();
    onClearUsersSearch();
  }, [onClearCustomersSearch, onClearOffersSearch, onClearUsersSearch]);

  if(currentUser===null) return null;
  
  if (currentUser.role === EUserRole.SUPERADMIN) {
    return null;
  }

  let searchList = null;

  const clearSearch = () => {
      switch (type) {
        case ESearchType.CONTRACTS:
          // TODO add contract search if needed
          break;
        case ESearchType.CUSTOMERS:
          onClearCustomersSearch();
          break;
        case ESearchType.OFFERS:
          onClearOffersSearch();
          break;
        case ESearchType.USERS:
          onClearUsersSearch();
          break;
        case ESearchType.CONTACTS:
          onClearCustomersSearch();
          break;
        default:
          break;
      }
  }

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let value = event.target ? event.target.value : event;
    value = value.toString();

    if (value && value.length > 0) {
      onChange(value);
    } else {
      clearSearch();
      /*
      switch (type) {
        case ESearchType.CONTRACTS:
          // TODO add contract search if needed
          break;
        case ESearchType.CUSTOMERS:
          onClearCustomersSearch();
          break;
        case ESearchType.OFFERS:
          onClearOffersSearch();
          break;
        case ESearchType.USERS:
          onClearUsersSearch();
          break;
        default:
          break;
      }
      */
    }
  };

  switch (type) {
    case ESearchType.CONTRACTS:
      // TODO add contract list if needed
      break;
    case ESearchType.CUSTOMERS:
      if (customers) {
        searchList = (
          <ListGroup className={classes.Search}>
            {customers.map((customer, i) => (
              <ListGroupItem
                tag="button"
                action
                key={customer.id || `${customer.name}_${i}`}
                onClick={() => {onClick(customer.id, customer); ; clearSearch()}}
              >
                {customer.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        )
      }
      break;
    case ESearchType.OFFERS:
      if (offers) {
        searchList = (
          <ListGroup className={classes.Search}>
            {offers.map((offer, i) => (
              <ListGroupItem
                tag="button"
                action
                key={offer.id || `${offer.customerName}_${i}`}
                onClick={() => onClick(offer.id)}
              >
                {offer.customerName} {offer.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        )
      }
      break;
    case ESearchType.USERS:
      if (users) {
        searchList = (
          <ListGroup className={classes.Search}>
            {users.map((user, i) => (
              <ListGroupItem
                tag="button"
                action
                key={user.id || `${user.name}_${i}`}
                onClick={() => {onClick(user.id);}}
              >
                {user.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        )
      }
      break;
    case ESearchType.CONTACTS:
      if (customers) {
        searchList = (
          <ListGroup className={classes.Search}>
            {customers.map((customer, i) => (
              <ListGroupItem
                tag="button"
                action
                key={customer.id || `${customer.name}_${i}`}
                onClick={() => {onClick(customer.id, customer); ; clearSearch()}}
              >
                {customer.name} {customer.email}
              </ListGroupItem>
            ))}
          </ListGroup>
        )
      }
      break;

    default:
      break;
  }

  return (
    <div className={classes.Container}>
      <span className={classes.Icon}>
        <FontAwesomeIcon icon={faSearch} />
      </span>
      <Input type="search" placeholder="Search" onChange={inputChangedHandler} className={classes.Input} />
      {searchList ? searchList : null}
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    // contracts: state.contracts.search,
    customers: state.customers.search,
    offers: state.offers.search,
    users: state.users.search,
    currentUser: state.auth.currentUser
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    // onClearContractsSearch: () => void,
    onClearCustomersSearch: () => dispatch(actions.clearCustomersSearch()),
    onClearOffersSearch: () => dispatch(actions.clearOffersSearch()),
    onClearUsersSearch: () => dispatch(actions.clearUsersSearch())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
