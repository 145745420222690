import React, { useState, useEffect } from "react";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from "../../../withRouter";
import { useParams } from 'react-router-dom';
import {
  validateInput,
  controlsToFormGroups,
  initForm,
  getFormData,
  hasAccess
} from "../../../shared/utility";
import { Form, Alert } from "reactstrap";
import { IUser, IAppState, IOrganization } from "../../../interfaces";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import EUserRole from "../../../enums/user-roles";
import { getUserControls } from "../../../shared/controls";
import FormFooter from "../../../components/UI/FormFooter/FormFooter";
import FormContainer from "../../../components/UI/FormContainer/FormContainer";
import FooterFixed from "../../../components/UI/FooterFixed/FooterFixed";

import classes from "./User.module.scss";

interface IStateProps {
  currentUser: IUser | null;
  user: IUser | null;
  organization: IOrganization | null;
  error: string | null;
  loading: boolean;
  success: boolean;
}

interface IDispatchProps {
  onGetUser: (id: string) => {};
  onUpdateUser: (formData: {}) => Promise<boolean>;
  onSaveUser: (formData: {}) => Promise<boolean>;
}

interface IMatchProps {
  id: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps {} //<IMatchProps> {}

const User: React.FC<IProps> = props => {
  const {
    onGetUser,
    user,
    organization,
    currentUser,
    match,
    history,
    loading,
    error
  } = props;

  const params=useParams();

  const [state, setState] = useState({
    controls: getUserControls(),
    formIsValid: false
  });

  const [organizationId, setOrganizationId] = useState("");
  useEffect(() => {
    if (!organization) {
      //if (hasAccess(currentUser, EUserRole.ENTREPRENEUR, EUserRole.TEAM_LEAD, EUserRole.ADMIN)) {
      if (hasAccess(currentUser, EUserRole.ENTREPRENEUR, EUserRole.ADMIN)) {
        setOrganizationId(currentUser.organizationId);
      } else {
        history.goBack();
      }
    } else {
      setOrganizationId(organization.id);
    }
    // eslint-disable-next-line
  }, [
    organization,
    currentUser.role,
    currentUser.organizationId,
    history
  ]);

  useEffect(() => {
    //const { id } = match.params;
    const id = params.id;
    if (id && id !== "add" && currentUser) {
      onGetUser(id);
    }
  //}, [currentUser, onGetUser, match.params]);
  }, [currentUser, onGetUser, params]);

  useEffect(() => {
    if (user) {
      const controls = getUserControls();
      if (user.id === currentUser.id) {
        controls.role.disabled = true;
      }

      const data = initForm(controls, user);
      setState({
        controls: data.controls,
        formIsValid: data.formIsValid
      });
    }
  }, [user, currentUser.id]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value = event.target ? event.target.value : event;

    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
  };

	const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = user ? user.id : undefined;
    const formData = getFormData(state.controls);
    let success = false;

    if (organizationId) {
      formData.organizationId = organizationId;
      if (id && id !== "add") {
        formData.id = id;
        success = await props.onUpdateUser(formData);
      } else {
        success = await props.onSaveUser(formData);
      }
    }
    
    if (success && !error) {
      history.goBack();
    }
  };

  return (
    <React.Fragment>
      <FormContainer title="Manage user" loading={loading}>
        <Form className={classes.Content} autoComplete="off">
          {controlsToFormGroups(state.controls, inputChangedHandler)}
        </Form>
        {error && (
          <Alert className={classes.ErrorMessage} color="danger">
            {error || 'Email is already in use.'}
          </Alert>
        )}
      </FormContainer>
      <FooterFixed>
        <FormFooter
					saveText="Save user"
          onSave={submitHandler}
          onDiscard={() => history.goBack()}
          isValid={state.formIsValid}
        />
      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    user: state.users.user,
    organization: state.organizations.organization,
    loading: state.users.loading,
    success: state.users.success,
    error: state.users.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetUser: id => dispatch(actions.getUser(id)),
    onUpdateUser: formData => dispatch(actions.updateUser(formData)),
    onSaveUser: formData => dispatch(actions.saveUser(formData))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
