import React from 'react';

import { IUserTemplateArea, IUserTemplateBlockDraggable, IUserTemplateEdit, IOrganization, IUserGroup, IUser } from '../../../../../../../interfaces';
//import TemplateSidebarEditArea from './TemplateSidebarEditArea/TemplateSidebarEditArea';
import TemplateSidebarEditBlock from './TemplateSidebarEditBlocks/TemplateSidebarEditBlock';
import TemplateSidebarEditTemplate from './TemplateSidebarEditTemplate/TemplateSidebarEditTemplate';


interface IProps {
  block: IUserTemplateBlockDraggable;
  area: IUserTemplateArea;
  setActiveArea: (area:IUserTemplateArea) => void;
  userTemplate: IUserTemplateEdit;
  updateBlock: (block:IUserTemplateBlockDraggable) => void;
  deleteBlock: (block:IUserTemplateBlockDraggable) => void;
	updateUserTemplate: (userTemplate: IUserTemplateEdit) => void;
	organization: IOrganization;
  userGroups: Array<IUserGroup>;
  currentUser: IUser;
}

const TemplateSidebarEdit: React.FC<IProps> = ({
    block,
    area,
    setActiveArea,
    userTemplate,
    updateBlock,
    deleteBlock,
    updateUserTemplate,
    organization,
    userGroups,
    currentUser,
}) => {

  if(block) {
    return <TemplateSidebarEditBlock block={block} updateBlock={updateBlock} deleteBlock={deleteBlock} />;
//  } else if(area) {
//    return <TemplateSidebarEditArea area={area} setActiveArea={setActiveArea} userTemplate={userTemplate} updateUserTemplate={updateUserTemplate} />;
  } else {
    return <TemplateSidebarEditTemplate userTemplate={userTemplate} updateUserTemplate={updateUserTemplate} organization={organization} userGroups={userGroups} currentUser={currentUser} />;
  }
};

export default TemplateSidebarEdit;
