import React from "react";

import classes from "./OfferPreviewSidebarNav.module.scss";

// Used User Template tab navigation as a starting point
export interface IOfferPreviewSidebarNavigationTab {
  id: any;
  title: string;
}

interface IProps {
  tabs: Array<IOfferPreviewSidebarNavigationTab>;
  setTab: (id: any) => void;
  current: any;
}

const OfferPreviewSidebarNav: React.FC<IProps> = ({ tabs, setTab, current }) => {
  return (
    <div className={classes.Container}>
      <ul className={classes.List}>
        {tabs.map(tab => (
          <li
            key={tab.id}
            onClick={() => setTab(tab.id)}
            className={tab.id === current ? classes.Active : null}
          >
            {tab.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OfferPreviewSidebarNav;
