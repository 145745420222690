import React, {useState} from "react";
import { connect } from "react-redux";

import { Button } from "reactstrap";

import { useTranslation } from 'react-i18next';

import CustomDialog from "../../../../../components/UI/CustomDialog/CustomDialog";

import * as qs from "query-string";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../../withRouter';

import axios from "../../../../../axios";

import { IAppState } from "../../../../../interfaces";

import ListSpinner from "../../../../../components/UI/ListSpinner/ListSpinner";

import classes from "./VismaSign.module.scss";

const hostname = window && window.location && window.location.hostname;
export const VISMA_API_PATH=hostname==="localhost"?"http://localhost:8081/strongauthentication":"/strongauthentication";

interface IParams {
	id:string;
	status:string;
	url:string;
	token:string;
}

interface IStateProps {}

interface IProps extends IStateProps, RouteComponentProps {} //<IParams> {}

const VismaSign: React.FC<IProps> = ({
	location,
}) => {
	const [methods,setMethods]=useState([]);
	const [init,setInit]=useState(false);
	const [loading,setLoading]=useState(false);

  //	const [hetu,setHetu]=useState({value:"080172-6694",status:false});
  const hetu={value:"080172-6694",status:false}; // dummy

  const { t, i18n } = useTranslation();

	const params = qs.parse(location.search);
  /*
	const checkHetu = (s:string) => {
		const CHECK={
			10:"A",11:"B",12:"C",13:"D",14:"E",15:"F",16:"H",
			17:"J",18:"K",19:"L",20:"M",21:"N",22:"P",23:"R",
			24:"S",25:"T",26:"U",27:"V",28:"W",29:"X",30:"Y",
		}

		if(s.length===11) {
			//			const key: (keyof CHECK)=10
			//const x=CHECK[parseInt(s.substring(0,6)+s.substring(7,10))%31]===s[key]
			//console.log(x)
			return true
		}
		return false
  }
   */

		/*
	i18n.changeLanguage("fi");
	
  useEffect(() => {
    if (offer && offer.customer) {
      i18n.changeLanguage(offer.customer.language);
    }
    // eslint-disable-next-line
  }, [offer]);
	 */

	const lang=params.lang as string || "en";

	if(params.status==="signed") {
		if(!init) {
      i18n.changeLanguage(lang);

			setInit(true);
			axios.get(VISMA_API_PATH+"/signed/?lang="+lang+"&orgid="+params.orgid+"&id="+params.id+"&otoken="+params.otoken+"&oid="+params.oid).then((resp)=>{
				window.location.href="/offer?id="+params.oid+"&token="+params.otoken;
			});
		}
		return (<div>
    	<CustomDialog
          open={true}
          title={t('signOfferDialogVisma.signedTitle')}
      >
				{t('signOfferDialogVisma.signedRedirect')}
				<ListSpinner/>
			</CustomDialog>
		</div>);
	}
  else if(params.status==="error") {
    if(!init) {
      setInit(true);
      i18n.changeLanguage(lang);
    }

    return (<div>
    	<CustomDialog
          open={true}
          title={t('confirmFailedDialog.title')}
      >
        {t('confirmFailedDialog.content')}
        <div>
          <br/>
          <Button color="secondary" href={"/offer?id="+params.id+"&token="+params.token}>{t('confirmFailedDialog.button')}</Button>
        </div>
      </CustomDialog>

    </div>)
  }
	// sign dialog
	else {
		const setMethod = (method:String) => {
      if(!loading) { // && hetu.status) {
				setLoading(true)
        axios.get(VISMA_API_PATH+"/create/?id="+params.id+"&token="+params.token).then((resp)=>{
          const docum=resp.data.document

          if(docum===null) {
				    window.location.href="/vismasign/?status=error&lang="+lang+"&id="+params.id+"&token="+params.token
          }
          else {
  					const invitation=resp.data.invitations[0]

            axios.get(VISMA_API_PATH+"/sign/?lang="+lang+"&oid="+params.id+"&otoken="+params.token+"&id="+invitation.uuid+"&did="+docum.uuid+"&service="+method+"&hetu="+hetu.value).then((resp)=>{
		  				window.location.href=resp.data
            })
		      }
				});
        
			}
		}

		if(!init) {
      i18n.changeLanguage(lang);
			setInit(true);
			axios.get(VISMA_API_PATH+"/authmethods/").then((resp)=>{
				setMethods(resp.data.methods)
			});
		}
		return (<div>

    	<CustomDialog
          open={true}
          title={loading?"":t('signOfferDialog.title')}
      >
				{loading && <>{t('signOfferDialogVisma.waitForPDF')}
					<ListSpinner/>
				</>}

				{!loading && <>
		
		      <p>{t('signOfferDialog.confirmPrefix')} </p>
		      <p>{t('signOfferDialogVisma.confirmMid')}</p>
		      <ul>
		        <li>{t('signOfferDialog.confirmLi2')}</li>
		        <li>{t('signOfferDialog.confirmLi3')}</li>
		      </ul>
          {/*
					<div>
						{t('signOfferDialogVisma.enterHetu')}{' '}
						<small>{t('signOfferDialogVisma.enterHetuInfo')}</small>
						<Input invalid={!hetu.status} type="text" value={hetu.value} onChange={(e:any)=>{setHetu({...hetu,value:e.target.value,status:checkHetu(e.target.value)})}} placeholder={t('signOfferDialogVisma.hetu')}/>
          </div>          
          <br/>
          
					<b>{t('signOfferDialogVisma.selectMethod')}</b>
					<br/>
					{methods.map((ob)=>(<div key={ob.identifier} className={[classes.method,hetu.status?"":classes.nohetu].join(" ")} onClick={()=>setMethod(ob.identifier)}>
						<img src={ob.image} alt={ob.name}/><br/>{ob.name}
          </div>))}
            */}

					<b>{t('signOfferDialogVisma.selectMethod')}</b>
					<br/>
					{methods.map((ob)=>(<div key={ob.identifier} className={classes.method} onClick={()=>setMethod(ob.identifier)}>
						<img src={ob.image} alt={ob.name}/><br/>{ob.name}
          </div>))}

				</>}

        </CustomDialog>

		</div>);
	}
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
  };
};

//export default VismaSign;
export default connect(mapStateToProps, null)(VismaSign);

