import { Dispatch } from "redux";
import { ActionTypes } from "./actionTypes";
import { IBillingAction, IPlan, ISeats } from "../../interfaces";
import axios from "../../axios";

export const findPlansStart = (): IBillingAction => {
  return {
    type: ActionTypes.FIND_PLANS_START
  };
};

export const findPlansSuccess = (plans: Array<IPlan>): IBillingAction => {
  return {
    type: ActionTypes.FIND_PLANS_SUCCESS,
    plans
  };
};

export const findPlansFail = (error: string): IBillingAction => {
  return {
    type: ActionTypes.FIND_PLANS_FAIL,
    error
  };
};

export const findPlans = () => {
  return async (dispatch: Dispatch) => {
    dispatch(findPlansStart());
    try {
      const res = await axios.get("/json/billing/plans");
      const plans = res.data;
      dispatch(findPlansSuccess(plans));
      return plans;
      //@ts-ignore
    } catch (error: any) {
      dispatch(findPlansFail(error.response.data));
      return null;
    }
  };
};

export const getSeatsStart = (): IBillingAction => {
  return {
    type: ActionTypes.GET_SEATS_START
  };
};

export const getSeatsSuccess = (seats: ISeats): IBillingAction => {
  return {
    type: ActionTypes.GET_SEATS_SUCCESS,
    seats
  };
};

export const getSeatsFail = (error: string): IBillingAction => {
  return {
    type: ActionTypes.GET_SEATS_FAIL,
    error
  };
};

export const getSeats = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getSeatsStart());
    try {
      const res = await axios.get("/json/billing/seats");
      const seats = res.data;
      dispatch(getSeatsSuccess(seats));
      return seats;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getSeatsFail(error.response.data));
      return null;
    }
  };
};
