import React, { useEffect, useState } from 'react';
import CustomButtonGroup from '../../../../../../../../components/UI/CustomButtonGroup/CustomButtonGroup';
import SidebarCollapse from '../../../../../../../../components/UI/SidebarCollapse/SidebarCollapse';
import { EContentType, EDropzoneType } from '../../../../../../../../enums';
import { IUserTemplateArea, IUserTemplateBlockDraggable, EBgType, IUserTemplate } from '../../../../../../../../interfaces';
import { controlsToFormGroups, defaultInputChangedHandler, getFormData, initForm } from '../../../../../../../../shared/utility';

interface IProps {
  id: string;
  title: string;
  data: IUserTemplateArea | IUserTemplateBlockDraggable | IUserTemplate;
  update: (data:Object) => void;
}

const colorControls = {
  controls: {
    bgColor: {
      elementType: "color",
      elementConfig: {
        type: "color",
        label: "Choose background color"
      },
      valid: true,
      touched: false,
      value: "#FFFFFF"
    }
  },
  formIsValid: false
}

const imageControls = {
  controls: {
    bgImage: {
      elementType: "dropzone",
      elementConfig: {
        label: "Choose background image",
        accept: EDropzoneType.IMG,
        text: "Drop image here or click to upload.",
        dragText: "Drop image here.",
        contentType: EContentType.IMAGE,
        config: {
          multiple: false          
        }
      },
      valid: true,
      touched: false,
      value: ''
    }
  }  
}


const otherControls = {
  controls: {
    titleColor: {
      elementType: "color",
      elementConfig: {
        type: "color",
        label: "Title color"
      },
      valid: true,
      touched: false,
      value: "", //"#FFFFFF"
    },
    paragraphColor: {
      elementType: "color",
      elementConfig: {
        type: "color",
        label: "Paragraph color"
      },
      valid: true,
      touched: false,
      value: "", //"#FFFFFF"
    },
    linkColor: {
      elementType: "color",
      elementConfig: {
        type: "color",
        label: "Link color"
      },
      valid: true,
      touched: false,
      value: "", //"#FFFFFF"
    }
  },
  formIsValid: false
}


const TemplateSidebarEditTheme:React.FC<IProps> = ({ id, title, data, update}) => {
  const [type, setType] = useState<EBgType>(data && data.styles && data.styles.bgType ? data.styles.bgType : EBgType.COLOR);

  /**
   * Rahtuc tyhmä pitäs ehkä siirtää omaansa toi image backgorund valinta kerran tänne tulee muitakin
   */
  const [colorControlsState, setColorControlsState] = useState(colorControls);
  const [imageControlsState, setImageControlsState] = useState(imageControls);
  const [otherControlsState, setOtherControlsState] = useState(otherControls);

  useEffect(() => {
    setColorControlsState(initForm(colorControls.controls, { ...data, ...data.styles }));
    setImageControlsState(initForm(imageControls.controls, { ...data, ...data.styles }));
    setOtherControlsState(initForm(otherControls.controls, { ...data, ...data.styles }));
    if (data.styles && data.styles.bgType) {
      setType(data.styles.bgType);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    update({bgType: type});
    // eslint-disable-next-line
  }, [type]);

  const colorInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    const validation = defaultInputChangedHandler(event, controlName, colorControlsState, setColorControlsState);
    update(getFormData(validation.controls));
  };

  const imageInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    const validation = defaultInputChangedHandler(event, controlName, imageControlsState, setImageControlsState);
    update(getFormData(validation.controls));
  };

  const otherInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    const validation = defaultInputChangedHandler(event, controlName, otherControlsState, setOtherControlsState);
    update(getFormData(validation.controls));
  };

  const items = [
    {
      type: EBgType.COLOR,
      text: "Color"
    },
    {
      type: EBgType.IMAGE,
      text: "Image"
    }
  ];

  return (
    <SidebarCollapse title={`${title} theme`}>
      <label>{title} background</label>
      <CustomButtonGroup
        items={items}
        clicked={setType}
        selected={type}
        size="sm"
        style={{display: 'flex', marginBottom: '0.5rem'}}
      />

      {(() => {
        switch(type) {
          case EBgType.COLOR:
            return controlsToFormGroups(colorControlsState.controls, colorInputChangedHandler);
          case EBgType.IMAGE:
              return controlsToFormGroups(imageControlsState.controls, imageInputChangedHandler);
          default:
            return null;
        }
      })()}
      {controlsToFormGroups(otherControlsState.controls, otherInputChangedHandler)}

    </SidebarCollapse>
  )
};


export default TemplateSidebarEditTheme;
