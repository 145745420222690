import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Button, DropdownItem } from 'reactstrap';

import CustomButtonGroup from '../../../../../../components/UI/CustomButtonGroup/CustomButtonGroup';
import IconButton from '../../../../../../components/UI/IconButton/IconButton';
import { EOfferStatus, EPageView, ESignerType } from '../../../../../../enums';
import { hasOfferStatus } from '../../../../../../shared/offer-utils';
import CustomDropdown from '../../../../../../components/UI/CustomPopover/CustomDropdown';
import { IOffer, IIntegrations, ISignature } from '../../../../../../interfaces';
import OfferAcceptedElseDialog from '../OfferPreviewSidebar/OfferPreviewSidebarNav/OfferPreviewSidebarHistory/OfferAcceptedElseDialog/OfferAcceptedElseDialog';
import CustomDialog from '../../../../../../components/UI/CustomDialog/CustomDialog';
import { copyStringToClipboard } from '../../../../../../shared/utility';
//import { copyEmailToClipboard } from '../../../../../../shared/utility';

import { integrationParser } from '../../../../../../shared/integrations';

import { getOfferSigners } from '../../../../../../shared/offer-utils';


import axios from 'axios';

import { VISMA_API_PATH } from "../../../../containers/Offers/OfferAccept/VismaSign";

import { config } from "../../../../../../constants";

//const PROCOUNTOR_URL = "https://integrations-dot-c2ctest-203405.ey.r.appspot.com";

import classes from "./OfferFooter.module.scss";

interface IProps {
  offer: IOffer;
  pageView: EPageView;
  onViewChanged: (pageView: EPageView) => void;
  onSaveDraft: () => void;
  onSave: () => void;
  onEdit: (pageView: EPageView) => void;
  saveDraftText: string;
  status: EOfferStatus;
  isEdit: boolean;
  offerDone: boolean;
  onCopy: () => void;
  onDelete: () => void;
  isUpdate: boolean;
  onMarkOfferAsAccepted: (statusDetails: string) => void;
  onToggleArchiveOffer: () => void;
  onTerminateOffer: () => void;
  integrations?:IIntegrations; 
}

const OfferFooter: React.FC<IProps> = ({
  offer,
  pageView,
  onViewChanged,
  onSaveDraft,
  onSave,
  onEdit,
  saveDraftText,
  status,
  isEdit,
  offerDone,
  onDelete,
  onCopy,
  isUpdate,
  onMarkOfferAsAccepted,
  onToggleArchiveOffer,
  onTerminateOffer,
  integrations,
}) => {

  const [showOfferAcceptedDialog, setShowOfferAcceptedDialog] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [showTerminateDialog, setShowTerminateDialog] = useState(false);

  const [integrationDialog, setIntegrationDialog] = useState(false);
  const [integrationMessage, setIntegrationMessage] = useState("");

  const [signatureLinks, setSignatureLinks] = useState<{[id:string]:string}>({});

  let taxType="EXCLUSIVE";

  const filterProducts = (blocks:any) => {

    const xx=(blocks || []).reduce((resp:any,ob:any)=>{
      return [...resp,...(ob.content || []).filter((c:any)=>c.contentType==="PRODUCTS").reduce((r:any,c:any)=>{
        if(c.tax==="INCLUSIVE") taxType="INCLUSIVE";
        return [...r,...(c.products || []).filter((p:any)=>(p.library===false && (p.optional===false || p.optionalSelected===true)))]
      },[])]
    },[])

    return xx
  }

  useEffect(() => {
    const _links:{[id:string]:string} = {};
    getOfferSigners(offer).filter((ob:ISignature)=>ob.signerType!==ESignerType.USER).map((sig:ISignature) => {
      _links[sig.email]=offer.link+"&email="+encodeURIComponent(sig.email)+"&hash="+encodeURIComponent(sig.hash);  
    });
    setSignatureLinks(_links);

  },[offer])
  

  const send2procountor = () => {
    setIntegrationDialog(true);
    setIntegrationMessage("");
    
    const name=offer.customer.name
    //const lang=offer.customer.language

    const prods=[
      ...filterProducts(offer.userTemplate.header.blocks),
      ...filterProducts(offer.userTemplate.main.blocks),
      ...filterProducts(offer.userTemplate.footer.blocks),
    ]

    const data={
      integration:integrations.procountor,
      buyer:{
        name:name,
        businessID:offer.customer.businessID || "",
        street:offer.customer.address || "",
        zip:offer.customer.zip || "",
        city:offer.customer.city || "",
      },
      invoice:{
        taxType:taxType,
      },
      rows:prods.map((ob)=>({
        item:ob.item,
        price:ob.unitPrice,
        quantity:ob.quantity,
        unit:ob.unit,
        vat:ob.vat,
        discount:ob.discount,
        description:ob.description,
      }))
    }

    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.post(config.PROCOUNTOR.URL+'/procountor',data).then((resp)=>{
      if(resp.data.status===200) {
        setIntegrationDialog(false);
      }
      else {
        //console.log("RESP",resp)
        setIntegrationMessage(resp.data.data);
      }
    })
    .catch((resp)=> {
      //console.log("error",resp);
    })
  }

  const send2netvisor = () => {
    setIntegrationDialog(true);
    setIntegrationMessage("");
    
    const name=offer.customer.name
    //const lang=offer.customer.language

    const prods=[
      ...filterProducts(offer.userTemplate.header.blocks),
      ...filterProducts(offer.userTemplate.main.blocks),
      ...filterProducts(offer.userTemplate.footer.blocks),
    ]

    const data={
      integration:integrations.netvisor,
      buyer:{
        name:name,
        businessID:offer.customer.businessID || "",
        street:offer.customer.address || "",
        zip:offer.customer.zip || "",
        city:offer.customer.city || "",
      },
      invoice:{
        taxType:taxType,
      },
      rows:prods.map((ob)=>({
        item:ob.item,
        price:ob.unitPrice,
        quantity:ob.quantity,
        unit:ob.unit,
        vat:ob.vat,
        discount:ob.discount,
        description:ob.description,
      }))
    }

    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.post(config.NETVISOR.URL,data).then((resp)=>{
      if(resp.data.status===200) {
        setIntegrationDialog(false);
      }
      else {
        //console.log("RESP",resp)
        setIntegrationMessage(resp.data.data);
      }
    })
    .catch((resp)=> {
      //console.log("error",resp);
      setIntegrationMessage(JSON.stringify(resp));

    })
  }


  let offerArchive = {
    title: "Remove from funnel",
    okButtonText: "Archive proposal",
    content: `Are you sure you want to archive proposal?`
  };
  if (offer.archived) {
    offerArchive = {
      title: "Unarchive proposal",
      okButtonText: "Unarchive proposal",
      content: `Are you sure you want to unarchive proposal?`
    };
  };

  const items = [
    {
      type: EPageView.EDIT,
      text: "Edit"
    },
    {
      type: EPageView.PREVIEW,
      text: "Preview"
    }
  ];

  const isSent = hasOfferStatus(
    status,
    EOfferStatus.SENT,
    EOfferStatus.OPENED,
    EOfferStatus.DECLINED
  );

  const saveText = isSent ? "Resend proposal" : "Send proposal";

  let offerActions = null;

	const print2PDF = () => {
		//const sid=offer.signedPDF?"sid="+offer.signedPDF:"id="+offer.strongAuthentication
		//offer.strongAuthentication
		//		?window.open(VISMA_API_PATH+"/getfile/?"+sid)
		//		:window.open(offer.printLink, "_blank");

    if(offer.strongAuthentication) {
      const businessID=offer.organization.businessID;
      const sid=offer.signedPDF?"sid="+offer.signedPDF:"id="+offer.strongAuthentication+"&businessid="+businessID;
      window.open(VISMA_API_PATH+"/getfile/?"+sid) //id="+offer.strongAuthentication)

    } else {
      window.open(offer.printLink, "_blank");
    }
	}
	// //id="+offer.strongAuthentication)

  const integrationData=integrationParser(offer.offerIntegrationData);

	if (isUpdate) {
    offerActions = (
      <CustomDropdown>
        {status === EOfferStatus.DRAFT ? (
          <DropdownItem onClick={() => onDelete()}>
            Delete draft
          </DropdownItem>
        ) : null}
        {!isEdit && offer.hasAttachments && (
          <DropdownItem onClick={() => window.open(offer.downloadLink, "_blank")}>
            Download attachments
          </DropdownItem>
        )}
        {!isEdit && offer.printLink && (
          <DropdownItem onClick={print2PDF}>
            Print to PDF
          </DropdownItem>
        )}
        <DropdownItem onClick={() => onCopy()}>
          Copy as new proposal
        </DropdownItem>
        {!isEdit && (
          <React.Fragment>
            {!offerDone && (
              <DropdownItem onClick={() => setShowOfferAcceptedDialog(true)}>
                Mark as accepted elsewhere
              </DropdownItem>
            )}

            {(offer.status === EOfferStatus.CONFIRMED || Object.keys(signatureLinks).length === 0) && offer.link && (
              <DropdownItem onClick={() => copyStringToClipboard(offer.link)}>
                {(offer.status === EOfferStatus.CONFIRMED || offer.status === EOfferStatus.TERMINATED) ? "Copy contract link to clipboard" : "Copy proposal link to clipboard"}
              </DropdownItem>
            )}

            {(offer.status !== EOfferStatus.CONFIRMED && Object.keys(signatureLinks).length > 0) && Object.keys(signatureLinks).map((email)=>(
              <DropdownItem key={email} onClick={() => copyStringToClipboard(signatureLinks[email])}>
                {(offer.status === EOfferStatus.CONFIRMED || offer.status === EOfferStatus.TERMINATED) ? "Copy contract link to clipboard" : "Copy "+email+" proposal link to clipboard"}
              </DropdownItem>
            ))}

            {/*offer.link && (
              <DropdownItem
                onClick={() => copyEmailToClipboard(offer.link)}
                href="mailto:seppo+asiakas@click2contract.com?subject=hei&body=paste email content from clipboard"
                target="_blank"
              >
                Copy email to clipboard
              </DropdownItem>
            )*/}


            {!offerDone && (
              <DropdownItem onClick={() => setConfirmArchive(true)}>
                {offerArchive.title}
              </DropdownItem>
            )}
          </React.Fragment>
        )}

        {integrations && integrations.procountor.active &&
          <DropdownItem onClick={() => send2procountor()}>
            Send to Procountor
        </DropdownItem>}

        {integrations && integrations.netvisor.active &&
          <DropdownItem onClick={() => send2netvisor()}>
            Send to Netvisor
        </DropdownItem>}

        {integrationData.HUBSPOT &&
        <DropdownItem target="_blank" href={"https://app.hubspot.com/contacts/"+integrationData.HUBSPOT.portalId+"/deal/"+integrationData.HUBSPOT.dealId+"/"}>
            Open in Hubspot
        </DropdownItem>}

      </CustomDropdown>
    )
  }

  let saveDraftButton = null;

  let classNames = [classes.Container];

  switch (status) {
    case EOfferStatus.DRAFT:
      classNames.push(classes.Draft)
      break;
    case EOfferStatus.SENT:
    case EOfferStatus.OPENED:
    case EOfferStatus.DECLINED:
      classNames.push(classes.Sent)
      break;
    default:
      classNames.push(classes.Draft)
      break;
  }

  if (!isSent) {
    saveDraftButton = (
      <Button color="link" onClick={onSaveDraft}>
        {saveDraftText}
      </Button>
    );
  }

  return (
    <React.Fragment>
      <OfferAcceptedElseDialog
        isOpen={showOfferAcceptedDialog}
        setIsOpen={setShowOfferAcceptedDialog}
        onSave={onMarkOfferAsAccepted}
      />


      <CustomDialog
        loading={false}
        onOk={() => {
          setIntegrationDialog(false);
        }}
        okButtonText="Close"
        open={integrationDialog}
        hideButtons={integrationMessage===""}
        title="Sending"
        closable={false}
      >
        <pre>
          {integrationMessage}
        </pre>
      </CustomDialog>


      <CustomDialog
        loading={false}
        onOk={() => {
          onToggleArchiveOffer();
          setConfirmArchive(false);
        }}
        onCancel={() => setConfirmArchive(false)}
        open={confirmArchive}
        title={offerArchive.title}
        okButtonText={offerArchive.okButtonText}
        cancelButtonText="Cancel"
      >
        {offerArchive.content}
      </CustomDialog>

      <CustomDialog
        loading={false}
        onOk={() => {
          onTerminateOffer();
          setShowTerminateDialog(false);
        }}
        onCancel={() => setShowTerminateDialog(false)}
        open={showTerminateDialog}
        title={"Are you sure?"}
        okButtonText={"OK"}
        cancelButtonText="Cancel"
      >
        Do you want to mark the contract as terminated?
        <br />
        Please note that this will have no legal effect since the customer will not be informed.
        <br />
        A terminated contract will still be available in Contracts.
      </CustomDialog>

      <div className={classNames.join(" ")}>
        <div className={isEdit ? classes.View : classes.None}>
          {isEdit && (
            <CustomButtonGroup
              items={items}
              clicked={onViewChanged}
              selected={pageView}
            />
          )}
        </div>
        <div className={classes.Buttons}>
          {offerActions}

          {!offerDone && (
            <React.Fragment>
              {(() => {
                switch (pageView) {
                  case EPageView.EDIT:
                    return (
                      <React.Fragment>
                        {saveDraftButton}
                        <Button
                          color="info"
                          onClick={() => onViewChanged(EPageView.PREVIEW)}
                        >
                          To sending
                        </Button>
                      </React.Fragment>
                    );
                  case EPageView.PREVIEW:
                    return (
                      <React.Fragment>
                        {saveDraftButton}
                        {isEdit && (
                          <Button color="info" onClick={onSave} disabled={!(offer && offer.userTemplate)}>
                            {saveText}
                          </Button>
                        )}
                      </React.Fragment>
                    );
                  default:
                    return null;
                }
              })()}

              {!isEdit && (
                <React.Fragment>
                  <Button
                    onClick={() => onEdit(EPageView.EDIT)}
                    color="info"
                    className={classes.ResendButton}
                  >
                    Edit
                </Button>

                  <IconButton
                    onClick={() => onEdit(EPageView.PREVIEW)}
                    color="info"
                    icon={faEnvelope}
                    className={classes.ResendButton}
                  >
                    Resend
                </IconButton>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
		
          {/*
          {offer.status === EOfferStatus.CONFIRMED && (
            <Button
              onClick={() => setShowTerminateDialog(true)}
              color="info"
              className={classes.ResendButton}
            >
              Terminate
            </Button>
          )}
          */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OfferFooter;
