import React from "react";

import {
  Location,
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";


export interface RouteComponentProps {
  location: Location;
  navigate: NavigateFunction;
  params: Params;
  history: any;
  match:any;
}

export interface WithRouter {
  router: RouteComponentProps;
}

export function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    let history = new NavigateHistory();
    //let match = {params:params, url:location.pathname};
    let match = {url:location.pathname};

    return (
      <Component
        {...props}
        router={{ location, navigate, params, history, match }}
        location={location}
        history={history}
        match={match}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}

class NavigateHistory {
  navigate = useNavigate();

  push(target:string) {
    this.navigate(target);
  }

  replace(redirect:string) {
    this.navigate(redirect, {replace:true});
  } 

  goBack() {
    this.navigate(-1);
  }
}

