import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';

import OfferPreviewContext from '../../../../../containers/Offers/Offer/OfferPreviewContext';
import OfferAuditLogsDialog from '../../../../Offers/Offer/OfferAuditLogsDialog/OfferAuditLogsDialog';

import classes from "./BlockContentLegalBoxShow.module.scss";

interface IProps {
  showDummyContent: boolean;
}

const BlockContentLegalBoxShow: React.FC<IProps> = ({ showDummyContent }) => {
  let { hash, confirmDate, status, id, token, hideAuditLogs, printable } = useContext(OfferPreviewContext);

  if (showDummyContent && !hash) {
    hash = "1f828019e6a02ebd0f76832d0a228c05";
  }

  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <div>
          {status} {confirmDate && `${confirmDate} GMT`}
        </div>
        <div className={classes.Hash}>hash: {hash}</div>
        {!printable && !hideAuditLogs && <div className={classes.AuditLog}><Button color="link" onClick={() => setIsOpen(true)}>Audit log</Button></div>}
      </div>
      <OfferAuditLogsDialog id={id} token={token} isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} printable={printable && !hideAuditLogs} />
    </React.Fragment>
  );
};

export default BlockContentLegalBoxShow;
