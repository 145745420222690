import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import BoxBody from "../../../../../../components/UI/BoxBody/BoxBody";
import BoxFooter from "../../../../../../components/UI/BoxFooter/BoxFooter";
import ExpirationDate from "../../../../../../components/UI/ExpirationDate/ExpirationDate";
import Subtitle from "../../../../../../components/UI/Typography/Subtitle";
import Title from "../../../../../../components/UI/Typography/Title";
import { IOffer } from "../../../../../../interfaces";
import { isOfferDone } from "../../../../../../shared/offer-utils";
import OfferActions from "../OfferActions/OfferActions";
import OfferComments from "../OfferComment/OfferComments";

import { ESignerType } from "../../../../../../enums";

import { getOfferSigners } from '../../../../../../shared/offer-utils';

import classes from "./OfferAcceptSidebar.module.scss";

interface IProps {
  offer: IOffer;
  hasNavigation: boolean;
  showMobileSidebar: boolean;
  hidden: boolean;
  onCloseMobileSidebar: () => void;
  onAccept: () => void;
  onDecline: () => void;
	onForward: () => void;
	token?:string;
	contentUpdated?:boolean;
	updateOffer?: () => void;
	email?: string;
}

const OfferAcceptSidebar: React.FC<IProps> = ({
  offer,
  hasNavigation,
  showMobileSidebar,
  hidden,
  onCloseMobileSidebar,
  onAccept,
  onDecline,
	onForward,
	token,
	contentUpdated,
	updateOffer,
	email,
}) => {
	const { t } = useTranslation();

  if(hidden) return null;
  
  const containerClasses = [classes.Container];

  if (hasNavigation) {
    containerClasses.push(classes.HasNavigation);
  }

  if(showMobileSidebar) {
    containerClasses.push(classes.MobileVisible);
  }

  const offerDone = isOfferDone(offer);

  const signers = getOfferSigners(offer) || [];

  return (
    <div className={containerClasses.join(" ")}>
      <BoxBody className={classes.Body}>
        <div className={classes.Title}>
          <Title>{offer.organization && offer.organization.name}</Title>
          {showMobileSidebar && (
            <span onClick={onCloseMobileSidebar}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          )}
        </div>
        
        <Subtitle>{offer.name}</Subtitle>
        {!offerDone && (
          <ExpirationDate
            expirationDate={offer.expirationDate}
            isExpired={offer.isExpired}
            isExpiring={offer.isExpiring}
            lang={offer.customer && offer.customer.language || "fi"}
          />
        )}
        <OfferActions offer={offer} onForward={onForward} />
        <OfferComments />
      </BoxBody>
      {!offerDone && (
				<BoxFooter className={classes.Footer}>
					
					{(contentUpdated && updateOffer)
						? <div>
							<Button onClick={updateOffer} color="success">
      	      	{t("offerAcceptSidebar.updateOffer")}
							</Button>
						</div>
						: <>
							{email && <>

								{/*}
								<div>{email}</div>
								<div>{signers.filter((s:any)=>s.timestamp).length} / {signers.length}</div>
								*/}

								{signers.find((s:any)=>s.email === email && !s.timestamp && s.signerType !== ESignerType.WATCH) && <>
									<Button
										onClick={onAccept}
										color="info"
									>
			          	 {t("offerAcceptSidebar.acceptOffer")}
									</Button>

									<Button onClick={onDecline} color="link">
		            		{t("offerAcceptSidebar.declineOffer")}
									</Button>
								</>}

								</>}

							{!email && <>
							{/* Visma Sign */}
							{offer.userTemplate && offer.userTemplate.settings.signType==="VISMA" && <Button
								color="info"
									href={"/vismasign/?id="+offer.id+"&token="+token+"&lang="+offer.customer.language}
								>
		      	      {t("offerAcceptSidebar.acceptOffer")}
								</Button>}

							{(!offer.userTemplate || offer.userTemplate.settings.signType!=="VISMA") && <Button
									onClick={onAccept}
									color="info"
								>
			            {t("offerAcceptSidebar.acceptOffer")}
								</Button>}

							{/* Original accep button */}
							{/*
								<Button
										onClick={onAccept}
										color="info"
								>
			          	 {t("offerAcceptSidebar.acceptOffer")}
								</Button>
							*/}

		          <Button onClick={onDecline} color="link">
		            {t("offerAcceptSidebar.declineOffer")}
							</Button>
							</>}
						</>
					}
					
        </BoxFooter>
		)}

    </div>
  );
};

export default OfferAcceptSidebar;
