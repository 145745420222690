import React from 'react';

import { IOfferComment } from '../../../../../../../interfaces';

import classes from "./OfferComment.module.scss";

interface IProps {
  comment: IOfferComment;
}

const Comment: React.FC<IProps> = ({ comment }) => {

  const classNames = [classes.Container];
  if(comment.user) {
    classNames.push(classes.Highlight);
  }

  return ( 
    <div className={classNames.join(' ')}>
      <p className={classes.Name}>{comment.user ? comment.user.name : comment.senderName}</p>
      <p className={classes.Created}>{comment.created}</p>
      <p className={classes.Comment}>{comment.message}</p>
    </div>
  );
};

export default Comment;
