import React, { useContext } from "react";
import { Spinner } from "reactstrap";
import { EUserTemplateBlockType } from "../../../../../../../enums";
import { IBlockItem, IUserTemplateAreaBlock } from "../../../../../../../interfaces";
import OfferContext from "../../../../../containers/Offers/Offer/OfferContext";
import OfferContentItemDynamic from "./OfferContentItem/OfferContentItemDynamic";
import OfferContentItemSimple from "./OfferContentItem/OfferContentItemSimple";

import classes from "./OfferContent.module.scss";

interface IProps {}

const OfferContent: React.FC<IProps> = () => {
  const {
    offer,
    onDynamicBlockItemChanged,
    onSimpleContentChanged,
    blockItemsLoading,
    onAddBlockItem,
    onEditBlockItem,
    hideEditableContent,
    blockLoading
  } = useContext(OfferContext);

  if (offer === null) return null;
  if (offer.userTemplate === null && !blockItemsLoading) return null;

  const renderContent = () => {
    if (blockItemsLoading) {
      return <Spinner color="primary" />;
    }

    if (offer.userTemplate === null) return null;

    const { header, main, footer } = offer.userTemplate;
    //const { main } = offer.userTemplate;

    const renderBlocks = (blocks: Array<IUserTemplateAreaBlock>) => {
      if (!blocks) return null;
      return blocks.map(block => {
        if (block.blockType === EUserTemplateBlockType.DYNAMIC) {
          //if (hideEditableContent) return null;
          return (
            <OfferContentItemDynamic
              key={block.id}
              block={block}
              onBlockItemChanged={onDynamicBlockItemChanged}
              onAdd={() => onAddBlockItem(block.id, block.blockId, block.editingStyle)}
              onEdit={(blockItem: IBlockItem) =>
                onEditBlockItem(block.id, blockItem)
              }
              editable={block.editableOnOffer}
              blockLoading={blockLoading === block.blockId}
            />
          );
        } else if (block.blockType === EUserTemplateBlockType.SIMPLE) { // && block.editableOnOffer) {
          if(!block.editableOnOffer) {
            if(block.content) {
              const isSignatures = block.content.filter((b)=> ( b.contentType === 'SIGNATURES' ));
              if(isSignatures.length > 0) {
                return <OfferContentItemSimple key={block.id} block={{...block,content:isSignatures}} onChange={onSimpleContentChanged} />;
              }
            }
          }
          else {
            return <OfferContentItemSimple key={block.id} block={block} onChange={onSimpleContentChanged} />;
          }
        }
        return null;
      });
    };

    return (
      <React.Fragment>
        {renderBlocks(header.blocks)}
        {renderBlocks(main.blocks)}
        {renderBlocks(footer.blocks)}
      </React.Fragment>
    );
  };

  return (
    <div className={classes.Container}>
      <h5>Editable Content</h5>
      {renderContent()}
    </div>
  );
};

export default OfferContent;
