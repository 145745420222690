import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Label } from 'reactstrap';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { getFormData, validateInput, controlsToFormGroups } from '../../../../../../shared/utility';
import { connect } from 'react-redux';
import { IAppState, IPaymentMethodDetail } from '../../../../../../interfaces';
import * as actions from "../../../../../../store/actions";
import ListSpinner from '../../../../../../components/UI/ListSpinner/ListSpinner';

import classes from "./PaymentMethodModal.module.scss";

interface IStateProps {
  error: string;
}

interface IDispatchProps {
  onSavePaymentMethod: (data: {}) => Promise<IPaymentMethodDetail>;
}

interface IProps extends ReactStripeElements.InjectedStripeProps, IStateProps, IDispatchProps {
  showModal: boolean;
  toggleModal: () => void;
  setPrimaryPaymentMethod: (paymentMethod: IPaymentMethodDetail) => void;
}

const PaymentMethodModal: React.FC<IProps> = ({ showModal, toggleModal, stripe, onSavePaymentMethod, setPrimaryPaymentMethod }) => {
  const getControls = (): any => {
    return {
      name: {
        elementType: "input",
        elementConfig: {
          label: "Name",
          placeholder: "Name",
          type: "text"
        },
        valid: true,
        touched: false,
        value: ""
      },
      address_line1: {
        elementType: "input",
        elementConfig: {
          label: "Address line 1",
          placeholder: "Address line 1",
          type: "text"
        },
        valid: true,
        touched: false,
        value: ""
      },
      address_line2: {
        elementType: "input",
        elementConfig: {
          label: "Address line 2",
          placeholder: "Address line 2",
          type: "text"
        },
        valid: true,
        touched: false,
        value: ""
      },
      address_city: {
        elementType: "input",
        elementConfig: {
          label: "City",
          placeholder: "City",
          type: "text"
        },
        valid: true,
        touched: false,
        value: ""
      },
      address_country: {
        elementType: "input",
        elementConfig: {
          label: "Country",
          placeholder: "Country",
          type: "text"
        },
        valid: true,
        touched: false,
        value: ""
      },
    };
  };

  const [state, setState] = useState({
    controls: getControls(),
    formIsValid: false
  });

  const [loading, setLoading] = useState(false);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value = event.target ? event.target.value : event;

    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
  };

  const savePaymentMethod = async () => {
    const formData = await getFormData(state.controls);
    let { token } = await stripe.createToken(formData);
    // console.log(token);
    
    if(!token) {
      setLoading(false);
      return;
    }

    const paymentMethod = await onSavePaymentMethod(token);
    setPrimaryPaymentMethod(paymentMethod);

    toggleModal();
    setState({
      controls: getControls(),
      formIsValid: false
    });

    setLoading(false);
  }

  const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    
    setLoading(true);
    await savePaymentMethod();

  };

  return (
    <Modal isOpen={showModal || loading} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Add card</ModalHeader>
      <ModalBody>
        {loading ? <ListSpinner /> : null}
        {controlsToFormGroups(state.controls, inputChangedHandler)}
        <Label className={classes.Label} for="card">Card details</Label>
        <CardElement id="card" className={classes.Card} />
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" onClick={toggleModal}>
          Discard
        </Button>
        {' '}
        <Button type="submit" color="info" disabled={loading} onClick={submitHandler}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    error: state.billing.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onSavePaymentMethod: (data) => dispatch(actions.savePaymentMethod(data))
  };
};

export default injectStripe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentMethodModal)
);
