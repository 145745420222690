import React, { forwardRef, useEffect, useImperativeHandle, useState, useContext } from "react";
import { IBlockContentContent } from "../../../../../../../interfaces";
import { controlsToFormGroups, defaultInputChangedHandler, getFormData } from "../../../../../../../shared/utility";
import { IBlockContentEditItem } from "../../BlockContentEdit";
import { ETextType } from "../../ContentTypeDialog/ContentTypeItemContent/ContentTypeItemContent";

//import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";
//import OfferContext from "../../../../../containers/Offers/Offer/OfferContext";

const BlockContentContentEdit: React.ForwardRefExoticComponent<
  IBlockContentEditItem
> = forwardRef((props, ref) => {
  const [init, setInit] = useState(false);
  const blockContent = props.blockContent as IBlockContentContent;

  //const { offer } = useContext(OfferContext);

  const elementType = () => {
    let _elementType = null;
    
    switch (blockContent.textType) {
      case ETextType.PLAIN:
        _elementType = "input";
        break;
      case ETextType.TEXTAREA:
        _elementType = "textarea";
        break;
      case ETextType.WYSIWYG:
      case ETextType.MODAL:
      case ETextType.WYSIWYG_MODAL:
        _elementType = 'wysiwyg';
        break;
      default:
        return null;
    }
    return _elementType;
  };

  const [controlsState, setControlsState] = useState({
    controls: {
      content: {
        elementType: elementType(),
        elementConfig: {
          label: blockContent.name,
          placeholder: blockContent.name,
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.content || ''
      }
    },
    formIsValid: false
  });

  const [additionalControlsState, setAdditionalControlsState] = useState({
    controls: {
      contentLong: {
        elementType: elementType(),
        elementConfig: {
          label: blockContent.linkText,
          placeholder: blockContent.linkText,
          type: "text"
        },
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        value: blockContent.contentLong || ''
      }
    },
    formIsValid: false
  });

  const getData = () => {
    return Object.assign(blockContent, getFormData(controlsState.controls), getFormData(additionalControlsState.controls));
  }

  useEffect(() => {
    if(props.onChange && init) {
      props.onChange(getData())
    } 
    // eslint-disable-next-line
  }, [controlsState.controls]);

  /*
  const replaceC2cFields = (ob:IBlockContentContent) => {    
    const customFields: { [key: string]: any } = {"c2c#number": offer.offerCounter || "c2c#number"};
    if(ob.contentType==="CONTENT") {
      let c:any=ob.content;
      if(c) {
        Object.keys(customFields).forEach((key:string) => {
          const value:string=customFields[key];        
          try {
            const re = new RegExp('(<c2c data-key="'+key+'">)[^<>]*(<\/c2c>)',"ig");
            c = c.replaceAll(re, '<c2c data-key="'+key+'">'+(value || "")+'</c2c>');

            c = c.replaceAll("{{"+key+"}}","<c2c data-key=\""+key+"\">"+(value || "")+"</c2c>");
          } catch(err) {}
        });
        ob.content=c;
      }
    }
  }
  */

  useEffect(() => {
    setInit(true);
    //console.log(blockContent.content);
    //replaceC2cFields(blockContent);

  }, [])

  useImperativeHandle(ref, () => ({
    getData
  }));

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    if(event && event.target && event.target.value === "<p><br></p>") {
      event.target.value = "";
    }

    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  };

  const additionalInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    if(event && event.target && event.target.value === "<p><br></p>") {
      event.target.value = "";
    }
    defaultInputChangedHandler(
      event,
      controlName,
      additionalControlsState,
      setAdditionalControlsState
    );
  };

  let blurHandler = null;
  if(props.onBlur) {
    blurHandler = () => {
      props.onBlur(getData());
    }
  }

  let additionalControls = null;
  if(blockContent.textType === ETextType.WYSIWYG_MODAL) {
    additionalControls = controlsToFormGroups(additionalControlsState.controls, additionalInputChangedHandler, blurHandler, true);
  }

  return (
    <React.Fragment>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler, blurHandler, true)}
      {additionalControls}
    </React.Fragment>
  );
});

export default BlockContentContentEdit;
