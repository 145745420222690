import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { EOfferStatus } from "../../../../../enums";
import { IOffer } from "../../../../../interfaces";

import classes from "./OfferStatus.module.scss";

interface IProps {
  offer: IOffer;
}

const OfferStatus: React.FC<IProps> = ({ offer }) => {
  const { status, isExpiring, isExpired } = offer;

  let classNames = [];
  let text = '';
  
	if (isExpiring && !isExpired) {
    classNames.push(classes.Expiring);
    text = 'Expiring';
  } else if (isExpired) {
    classNames.push(classes.Expired);
    text = 'Expired';
  } else {
    switch (status) {
      case EOfferStatus.CONFIRMED:
        classNames.push(classes.Confirmed);
        text = 'Confirmed';
        break;
      case EOfferStatus.SENT:
        classNames.push(classes.Sent);
        text = 'Sent';
        break;
      case EOfferStatus.DRAFT:
        classNames.push(classes.Draft);
        text = 'Draft';
        break;
      case EOfferStatus.OPENED:
      case EOfferStatus.DECLINED:
        classNames.push(classes.Opened);
        text = 'Opened';
        break;
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <FontAwesomeIcon className={classNames.join(' ')} icon={faCircle} /> {text}
    </React.Fragment>
  );
};

export default OfferStatus;
