import React from "react";
import {
  IBlockContent,
  IBlockContentImage,
  IAttachment
} from "../../../../../../../interfaces";
import ImageSpinner from "../../../../../../../components/UI/ImageSpinner/ImageSpinner";

//import c2clogo from "../../../../../../../assets/images/click2contract-logo.png";

import classes from "./BlockContentImageShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent?: boolean;
}

const dummyImage = {
  id: "",
	link: "", //c2clogo,
  clientName: "Image",
  contentType: "",
  serverName: "",
  size: 0,
  hash: ""
};

const BlockContentImageShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentImage;
  const showDummyContent = props.showDummyContent;

  const renderImages = (images: Array<IAttachment>) => {
    if (showDummyContent && (!images || !images.length)) {
      images = [dummyImage];
    }
    if (!images) return null;

    const width = blockContent.width ? `${blockContent.width}px` : "auto";
    const maxHeight = blockContent.height ? `${blockContent.height}px` : "auto";

    return images.map((image, index) => {
      if(!image) return null;
      return <div key={image.id} className={classes.Item}>
        <ImageSpinner
          src={image.link}
          alt={image.clientName}
          style={{
            width,
            maxHeight
          }}
        />
      </div>
      
    });
  };

  return (
    <div className={classes.Container}>{renderImages(blockContent.images)}</div>
  );
};

export default BlockContentImageShow;
