import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { IContentTypeItemType } from "../../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData,
  initForm
} from "../../../../../../../shared/utility";

interface IProps extends IContentTypeItemType {
	disableTypeChange?: boolean;
}

export enum ETextType {
  PLAIN = "PLAIN",
  TEXTAREA = "TEXTAREA",
  WYSIWYG = "WYSIWYG",
  MODAL = "MODAL",
  WYSIWYG_MODAL = "WYSIWYG_MODAL"
}

const textTypes = [
//  { id: ETextType.PLAIN, label: "Plain text" },
//  { id: ETextType.TEXTAREA, label: "Textarea" },
  { id: ETextType.WYSIWYG, label: "Text Editor" },
  { id: ETextType.MODAL, label: "Pop-up" },
  { id: ETextType.WYSIWYG_MODAL, label: "Text Editor + Pop-up"}
];

const ContentTypeItemContent: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ blockContent, setIsValid, disableTypeChange }, ref) => {
  const [controlsState, setControlsState] = useState({
    controls: {
      textType: {
        elementType: "select",
        elementConfig: {
          label: "Text type"
        },
        valid: false,
        validation: {
          required: true
        },
        touched: false,
        value: "",
        options: textTypes
      }
    },
    formIsValid: false
  });

  const [modalControlsState, setModalControlsState] = useState({
    controls: {
      title: {
        elementType: "input",
        elementConfig: {
          label: "Title",
          placeholder: "Title",
          type: "text"
        },
        valid: true,
        touched: false,
        value: ""
      },
      linkText: {
        elementType: "input",
        elementConfig: {
          label: "Link text",
          placeholder: "Link text",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: ""
      },
    },
    formIsValid: false
  });

  useEffect(() => {
    if (blockContent) {
      const newControlsState = initForm(controlsState.controls, blockContent);
      setControlsState(newControlsState);
      const newModalControlsState = initForm(modalControlsState.controls, blockContent);
      setModalControlsState(newModalControlsState);
    }
    // eslint-disable-next-line
  }, [blockContent]);

  useEffect(() => {
    if(controlsState.controls.textType.value === ETextType.MODAL || controlsState.controls.textType.value === ETextType.WYSIWYG_MODAL) {
      setIsValid(controlsState.formIsValid && modalControlsState.formIsValid);
    } else {
      setIsValid(controlsState.formIsValid);
    }
  }, [controlsState.controls.textType.value, controlsState.formIsValid, modalControlsState.formIsValid, setIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      const controls = Object.assign({}, {
        ...controlsState.controls,
        ...modalControlsState.controls
      });
      return getFormData(controls);
    }
  }));

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  };

  const modalInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      modalControlsState,
      setModalControlsState
    );
  };

  let modalControls = null;
  if(controlsState.controls.textType.value === ETextType.MODAL || controlsState.controls.textType.value === ETextType.WYSIWYG_MODAL) {
    modalControls = controlsToFormGroups(modalControlsState.controls, modalInputChangedHandler);
  }

  return (
    <React.Fragment>
      {!disableTypeChange && controlsToFormGroups(controlsState.controls, inputChangedHandler)}
      {modalControls}
    </React.Fragment>
  );
});

export default ContentTypeItemContent;
