import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { IContentTypeItemType } from "../../../../../../../interfaces";
import {
  getFormData,
  initForm
} from "../../../../../../../shared/utility";

import { IBlockContentPrivate } from "../../../../../../../interfaces";

import { Input, Container, Row, Col, InputGroupText, InputGroup } from 'reactstrap';

interface IProps extends IContentTypeItemType {}

const ContentTypeProducts: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ blockContent, setIsValid }, ref) => {

  const [controlsState, setControlsState] = useState({
    controls: {
    	json:{},
    	settings:{

				liikevaihtoluokat: [
		        ["alle 0.1",40,24],
		        ["0.1-0.2",60,24],
		        ["0.2-0.5",90,38],
		        ["0.5-1",140,53],
		        ["1-2",190,84],
		        ["2-5",250,123],
		        ["5-10",320,169],
		        ["10-25",400,199],
		        ["25-50",490,249],
		        ["50-100",590,299],
		        ["yli 100",990,399],
				],

				paketit: {
		        "basic":[18,35,55,null,null,null,null,null,null,null,null],
		        "core":[45,65,105,165,255,395,575,795,1075,1475,2200],
		        "professional":[70,95,145,220,330,500,750,1025,1375,1850,2900],
		        "premium":[105,130,180,265,385,590,875,1175,1650,2090,3190],
		        "palkat":[10,10,10,10,10,10,10,10,10,10,10],
				},

				palkkarajat: [
		        [   5,  6,0.73,0.51],
		        [  25,3.5,0.73,0.51],        
		        [ 100,3.5,0.57,0.43],
		        [ 200,2.5,0.49,0.41],
		        [ 300,  2,0.49,0.41],
		        [ 500,  2,0.41,0.33],
		        [1000,  2,0.34,0.27],
		        [null,  2,0.31,0.24],
				],

				koulutus: [1600,2880,350,{0:480,10:840,30:1400,60:2240,100:3500},560]
				
			},

    },
    formIsValid: true
  });

  useEffect(() => {
    if (blockContent) {
    	const _blockContent = blockContent as IBlockContentPrivate;
      //const newControlsState = initForm(controlsState.controls, {..._blockContent,json:JSON.parse(_blockContent.json),settings:JSON.parse(_blockContent.settings)});
      setControlsState({...controlsState,controls:{json:JSON.parse(_blockContent.json || "{}"),settings:JSON.parse(_blockContent.settings || JSON.stringify(controlsState.controls.settings))}});
    }
    // eslint-disable-next-line
  }, [blockContent]);

  useEffect(() => {
    setIsValid(controlsState.formIsValid);
  }, [controlsState.formIsValid, setIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      //return getFormData(controlsState.controls);
      return {
      	json:JSON.stringify(controlsState.controls.json),
      	settings:JSON.stringify(controlsState.controls.settings)
      };
    }
  }));

  const update = (field:string, value:string) => {
  	setControlsState({...controlsState,controls:{...controlsState.controls, settings:{...controlsState.controls.settings,[field]:JSON.parse(value)}}});
  }

  return (<React.Fragment>
  	Liikevaihtoluokat
  	<Input type="textarea" value={JSON.stringify(controlsState.controls.settings.liikevaihtoluokat)} onChange={(e:any)=>update("liikevaihtoluokat",e.target.value)} />
  	Paketit
  	<Input type="textarea" value={JSON.stringify(controlsState.controls.settings.paketit)} onChange={(e:any)=>update("paketit",e.target.value)} />
  	Rajat
  	<Input type="textarea" value={JSON.stringify(controlsState.controls.settings.palkkarajat)} onChange={(e:any)=>update("palkkarajat",e.target.value)} />
  	Koulutus
  	<Input type="textarea" value={JSON.stringify(controlsState.controls.settings.koulutus)} onChange={(e:any)=>update("koulutus",e.target.value)} />


  	</React.Fragment>);
});

export default ContentTypeProducts;

