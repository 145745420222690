import React from "react";
import { IAttachment } from "../../../interfaces";
import ImageSpinner from "../../UI/ImageSpinner/ImageSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFilePdf, faFile } from "@fortawesome/free-solid-svg-icons";
import { EContentType } from "../../../enums";

import classes from "./CustomDropzonePreview.module.scss";

interface IProps {
  attachment: IAttachment;
  onDelete: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
  contentType: EContentType;
}

const CustomDropzonePreview: React.FC<IProps> = ({
  attachment,
  onDelete,
  contentType
}) => {
  return (
    <div
      key={attachment.id}
      className={classes.PreviewItem}
      title={attachment.clientName}
    >
      {(() => {
        switch (contentType) {
          case EContentType.IMAGE:
            return (
              <ImageSpinner src={attachment.link} alt={attachment.clientName} />
            );
          case EContentType.PDF:
            return <FontAwesomeIcon icon={faFilePdf} size="3x" />;
          case EContentType.ATTACHMENT:
            return <FontAwesomeIcon icon={faFile} size="3x" />;
          default:
            return null;
        }
      })()}

      <div
        className={classes.PreviewItemDelete}
        onClick={event => onDelete(event, attachment.id)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

export default CustomDropzonePreview;
