import React from 'react';
import SidebarCollapse from '../../../../../../../../components/UI/SidebarCollapse/SidebarCollapse';

import AcceptanceMethods from '../../../../../../../../components/UI/AcceptanceMethods/AcceptanceMethods';
import EmailTemplates from './EmailTemplates';

import { IUserTemplate, IOrganization } from '../../../../../../../../interfaces';

import CustomerDetails from './CustomerDetails';
import Parameters from './Parameters';


interface IProps {
  id: string;
  title: string;
	data: IUserTemplate;
	update: (data:Object) => void;

	organization: IOrganization;
}

const TemplateSidebarEditSettings:React.FC<IProps> = ({ id, title, data, update, organization}) => {
  if(!("settings" in data)) return null;

  return (
    <SidebarCollapse title={`${title} settings`}>
      <AcceptanceMethods data={data} update={update} edit={true} organization={organization} />

      <Parameters userTemplate={data} update={update} organization={organization}/>
      
      <EmailTemplates userTemplate={data} update={update} organization={organization} />
      <CustomerDetails userTemplate={data} update={update} organization={organization} />

    </SidebarCollapse>
  )
};


export default TemplateSidebarEditSettings;
