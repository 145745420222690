import React, { useEffect } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../../../withRouter';
import { useParams } from "react-router-dom";

import { Alert } from "reactstrap";
import ListContainer from "../../../../../../components/ListContainer/ListContainer";
import ListHeader from "../../../../../../components/ListHeader/ListHeader";
import BackAlert from "../../../../../../components/UI/BackAlert/BackAlert";
import ListSpinner from "../../../../../../components/UI/ListSpinner/ListSpinner";
import { IAppState, IBlock, IBlockItem } from "../../../../../../interfaces";
import * as actions from "../../../../../../store/actions";
import BlockItemsList from "../../../../components/Blocks/Block/BlockItems/BlockItemsList/BlockItemsList";

interface IStateProps {
  loading: boolean;
  error: string;
  block: IBlock;
  blockItems: Array<IBlockItem>;
  blockItemError: string;
  blockItemLoading: boolean;
}

interface IDispatchProps {
  onGetBlock: (id: string) => Promise<IBlock>;
  onFindBlockItems: (blockId: string) => Promise<IBlockItem>;
}

interface IMatchParams {
  id: string;
}

interface IProps
  extends RouteComponentProps, //<IMatchParams>,
    IStateProps,
    IDispatchProps {}

const Blocks: React.FC<IProps> = ({
  history,
  loading,
  error,
  block,
  blockItemLoading,
  blockItemError,
  blockItems,
  onGetBlock,
  onFindBlockItems,
  match
}) => {
  const params=useParams();
  //const { id } = match.params;
  const id = params.id;

  useEffect(() => {
    onGetBlock(id);
    onFindBlockItems(id);
  }, [onGetBlock, onFindBlockItems, id]);

  const onCreate = () => {
    history.push(`/blocks/${id}/items/add`);
  };

  let title = "";
  if (block) {
    title = block.name;
  }

  return (
    <ListContainer>
      <BackAlert
        backText="Back to multi item elements view"
        onBack={() => history.push("/blocks")}
      />
      <ListHeader
        title={title}
        onCreate={{
          text: `Add new ${title}`,
          clicked: onCreate
        }}
        loading={loading}
      />

      {blockItemLoading ? (
        <ListSpinner style={{ marginTop: "1rem" }} />
      ) : blockItemError ? (
        <Alert color="danger">{blockItemError}</Alert>
      ) : (
        <BlockItemsList
          blockItems={blockItems}
          onEdit={blockItemId =>
            history.push(`/blocks/${id}/items/${blockItemId}`)
          }
        />
      )}
    </ListContainer>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.blocks.loading,
    error: state.blocks.error,
    block: state.blocks.block,
    blockItems: state.blocks.blockItems,
    blockItemLoading: state.blocks.blockItemLoading,
    blockItemError: state.blocks.blockItemError
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetBlock: id => dispatch(actions.getBlock(id)),
    onFindBlockItems: blockId => dispatch(actions.findBlockItems(blockId, null))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Blocks);
