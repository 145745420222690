import React from "react";

import { Input, Button } from 'reactstrap'
import ContentTypeIcon from "../../../../../../../components/UI/ContentTypeIcon/ContentTypeIcon";
import { EContentType } from "../../../../../../../enums";

import classes from "./BlockContentFieldsEdit.module.scss";

const CustomInput = (props: any) => {
	const { formElement }=props
	return ( <div className={classes.customHolder}>
			<Button
			color="primary"
			className={[classes.Button,classes["bsize"+formElement.config.fontSize]].join(' ')}
			onClick={(e:any)=>formElement.config.onChange(formElement.config.side,formElement.config.index,"fontSize",formElement.config.fontSize<6?formElement.config.fontSize+1:1)}>
				{[null,"1","2","3","4","5","6"][formElement.config.fontSize]}
			</Button>

      <div className={classes.trashBtn}>
				<ContentTypeIcon
					contentType={EContentType.DELETE}
					onClick={()=>formElement.config.onDelete(formElement.config.side,formElement.config.index)}/>
			</div>

			<Input
				className={[props.className,classes.customInput].join(" ")}
      
				type={formElement.config.elementConfig.type}
  	    id={formElement.id}
    	  placeholder={
      	  formElement.config.elementConfig.placeholder ||
        	formElement.config.elementConfig.label
        }
	      value={formElement.config.value}
				onChange={(e:any)=>formElement.config.onChange(formElement.config.side,formElement.config.index,"value",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
        readOnly={formElement.config.elementConfig.type === "password"}
	      onFocus={formElement.config.elementConfig.type === "password" ? (event) => event.target.removeAttribute('readonly') : null}
				onBlur={props.onBlur}
			/> 
	</div>)
}
 
export default CustomInput;

