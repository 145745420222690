import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import classes from "./SidebarNavigation.module.scss";

export interface ISidebarNavigationTab {
  id: any;
  title: string;
  icon: IconDefinition;
}

interface IProps {
  tabs: Array<ISidebarNavigationTab>;
  setTab: (id: any) => void;
  current: any;
  topTab?: ISidebarNavigationTab;
}

const SidebarNavigation: React.FC<IProps> = ({ tabs, setTab, current, topTab }) => {
  let topTabClasses = null;
  if (topTab) {
    topTabClasses = [classes.TopTab];

    if (topTab.id === current) {
      topTabClasses.push(classes.Active);
    }
  }

  return (
    <div className={classes.Container}>
      {topTab && (
        <div
          key={topTab.id}
          onClick={() => setTab(topTab.id)}
          className={topTabClasses.join(" ")}
        >
          <FontAwesomeIcon icon={topTab.icon} />
          {topTab.title}
        </div>
      )}
      <ul className={classes.List}>
        {tabs.map(tab => (
          <li
            key={tab.id}
            onClick={() => setTab(tab.id)}
            className={tab.id === current ? classes.Active : null}
          >
            <FontAwesomeIcon icon={tab.icon} />
            {tab.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarNavigation;
