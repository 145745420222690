import React, { useContext, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form, FormGroup, Input } from "reactstrap";
import ListSpinner from "../../../../../../components/UI/ListSpinner/ListSpinner";
import { IOfferComment, IOffer } from "../../../../../../interfaces";
import OfferComment from "./OfferComment/OfferComment";
import OfferCommentsContext from "./OfferCommentsContext";
import { useTranslation } from "react-i18next";


import classes from "./OfferComments.module.scss";
import inputClasses from "../../../../../../components/UI/Input/Input.module.scss";

interface IProps {
  offer?:IOffer,
}

const OfferComments: React.FC<IProps> = ({offer}) => {
  const { t } = useTranslation();

  const {
    comments,
    loading,
    currentUser,
    defaultSenderName,
    onCommentOffer,
    hideControls,
  } = useContext(OfferCommentsContext);

  const [senderName, setSenderName] = useState(
    defaultSenderName ? defaultSenderName : ""
  );
  const [message, setMessage] = useState("");

  const isInValid = () => {
    if (!currentUser && !senderName.length) return true;
    if (!message.length) return true;
    return false;
  };

  const resetForm = () => {
    setSenderName(defaultSenderName ? defaultSenderName : "");
    setMessage("");
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const comment: IOfferComment = {
      id: null,
      created: null,
      user: null,
      senderName,
      message
    };
    onCommentOffer(comment);
    resetForm();
  };

  return (
    <div>
      {offer && offer.email && <div className={classes.Title}>
        <b>{offer.email.title}</b>
        <div style={{whiteSpace:"pre-wrap"}}>{offer.email.body}</div>

      </div>}

      <div className={classes.Title}>
        <b>{t('offerComments.title')}</b>
      </div>
      {hideControls ? null : (
        <React.Fragment>
          <Form onSubmit={e => submitHandler(e)}>
            {!currentUser && (
              <FormGroup>
                <Input
                  className={inputClasses.Input}
                  type="text"
                  placeholder={t('offerAcceptSidebar.name')}
                  value={senderName}
                  onChange={event => setSenderName(event.target.value)}
                />
              </FormGroup>
            )}
            <FormGroup>
              <TextareaAutosize
                className={["form-control", inputClasses.Input].join(" ")}
                placeholder={t('offerAcceptSidebar.addComment')}
                value={message}
                onChange={event => setMessage(event.target.value)}
                minRows={2}
              />
            </FormGroup>
            <Button color="success" type="submit" disabled={isInValid()}>
              {t('offerAcceptSidebar.send')}
            </Button>
          </Form>
          <br />
        </React.Fragment>
      )}
      {loading && <ListSpinner />}
      <div className={classes.Comments}>
        {comments &&
          comments.map(comment => (
            <OfferComment key={comment.id} comment={comment} />
          ))}
      </div>
    </div>
  );
};

export default OfferComments;
