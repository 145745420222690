import { IOfferAction, IOfferState } from '../../interfaces';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';

const initialState:IOfferState = {
    offers: null,
    loading: false,
    error: null,
    offer: null,
    success: false,
    search: null,
    codeLoading: false,
    codeError: null,
    commentLoading: false,
    commentError: null,
    email: null,
    emailLoading: false,
    emailError: null,
    auditLogs: null,
    auditLogsLoading: false,
    auditLogsError: null
};

const clearOffersState = (state:IOfferState, action:IOfferAction):IOfferState => {
    return {...initialState};
}

const findOffersStart = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, { error: null, loading: true});
}


const findOffersSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
    let offers = state.offers || [];
    if(action.offers) {
        offers = [...offers, ...action.offers];
    }

    return updateObject(state, {
        offers,
        error: null,
        loading: false,
        offer: null
    });
}

const findOffersFail = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchOffersFail = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        error: action.error
    });
};

const searchOffersStart = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, { error: null });
}

const searchOffersSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        search: action.search,
        error: null
    });
}

const getOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, { error: null, loading: true});
}

const getOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        offer: action.offer,
        error: null,
        loading: false
    });
}

const getOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const saveOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, { error: null, loading: true});
}

const saveOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const saveOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, { error: null, loading: true});
}

const updateOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const updateOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const clearOffersSearch = (state:IOfferState, action:IOfferAction):IOfferState => {
    return updateObject(state, {
        search: null
    });
};

const sendOfferConfirmCodeStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { codeError: null, codeLoading: true});
}

const sendOfferConfirmCodeSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      codeError: null,
      codeLoading: false
  });
}

const sendOfferConfirmCodeFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      codeError: action.error,
      codeLoading: false
  });
};

const confirmOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { error: null, loading: true});
}

const confirmOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: null,
      loading: false,
      offer: action.offer
  });
}

const confirmOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const terminateOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { error: null, loading: true});
}

const terminateOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: null,
      loading: false,
  });
}

const terminateOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const declineOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { error: null, loading: true});
}

const declineOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      offer: action.offer,
      error: null,
      loading: false,
  });
}

const declineOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const forwardOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { error: null, loading: true});
}

const forwardOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: null,
      loading: false,
  });
}

const forwardOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const commentOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { commentError: null, commentLoading: true});
}

const commentOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      offer: action.offer,
      commentError: null,
      commentLoading: false,
  });
}

const commentOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      commentError: action.error,
      commentLoading: false
  });
};

const getEmailDefaultsStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { emailError: null, emailLoading: true });
}

const getEmailDefaultsSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
    email: action.email,
    emailError: null,
    emailLoading: false
  });
}

const getEmailDefaultsFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
    emailError: action.error,
    emailLoading: false
  });
};

const markOfferAcceptedStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { commentError: null, commentLoading: true});
}

const markOfferAcceptedSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      offer: action.offer,
      commentError: null,
      commentLoading: false,
  });
}

const markOfferAcceptedFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      commentError: action.error,
      commentLoading: false
  });
};

const archiveOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { loading: true, error: null });
}

const archiveOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      offer: action.offer,
      loading: false,
      error: null
  });
}

const archiveOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const findOfferAuditLogsStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { auditLogsError: null, auditLogsLoading: true });
}

const findOfferAuditLogsSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      auditLogsError: null,
      auditLogsLoading: false,
      auditLogs: action.auditLogs
  });
}

const findOfferAuditLogsFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      auditLogsError: action.error,
      auditLogsLoading: false
  });
};

const deleteOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { error: null, loading: true });
}

const deleteOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: null,
      loading: false,
      offer: null
  });
}

const deleteOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const copyOfferStart = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, { error: null, loading: true });
}

const copyOfferSuccess = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: null,
      loading: false,
      offer: action.offer
  });
}

const copyOfferFail = (state:IOfferState, action:IOfferAction):IOfferState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};

const reducer = (state:IOfferState = initialState, action:IOfferAction):IOfferState => {
    
    switch (action.type) {
        case ActionTypes.CLEAR_OFFERS_STATE : return clearOffersState(state, action);
        case ActionTypes.FIND_OFFERS_START : return findOffersStart(state, action);
        case ActionTypes.FIND_OFFERS_SUCCESS : return findOffersSuccess(state, action);
        case ActionTypes.FIND_OFFERS_FAIL : return findOffersFail(state, action);
        case ActionTypes.SEARCH_OFFERS_START : return searchOffersStart(state, action);
        case ActionTypes.SEARCH_OFFERS_SUCCESS : return searchOffersSuccess(state, action);
        case ActionTypes.SEARCH_OFFERS_FAIL : return searchOffersFail(state, action);
        case ActionTypes.GET_OFFER_START : return getOfferStart(state, action);
        case ActionTypes.GET_OFFER_SUCCESS : return getOfferSuccess(state, action);
        case ActionTypes.GET_OFFER_FAIL : return getOfferFail(state, action);
        case ActionTypes.SAVE_OFFER_START : return saveOfferStart(state, action);
        case ActionTypes.SAVE_OFFER_SUCCESS : return saveOfferSuccess(state, action);
        case ActionTypes.SAVE_OFFER_FAIL : return saveOfferFail(state, action);
        case ActionTypes.UPDATE_OFFER_START : return updateOfferStart(state, action);
        case ActionTypes.UPDATE_OFFER_SUCCESS : return updateOfferSuccess(state, action);
        case ActionTypes.UPDATE_OFFER_FAIL : return updateOfferFail(state, action);
        case ActionTypes.CLEAR_OFFERS_SEARCH : return clearOffersSearch(state, action);
        case ActionTypes.SEND_OFFER_CONFIRM_CODE_START : return sendOfferConfirmCodeStart(state, action);
        case ActionTypes.SEND_OFFER_CONFIRM_CODE_SUCCESS : return sendOfferConfirmCodeSuccess(state, action);
        case ActionTypes.SEND_OFFER_CONFIRM_CODE_FAIL : return sendOfferConfirmCodeFail(state, action);
        case ActionTypes.CONFIRM_OFFER_START : return confirmOfferStart(state, action);
        case ActionTypes.CONFIRM_OFFER_SUCCESS : return confirmOfferSuccess(state, action);
        case ActionTypes.CONFIRM_OFFER_FAIL : return confirmOfferFail(state, action);
        case ActionTypes.TERMINATE_OFFER_START : return terminateOfferStart(state, action);
        case ActionTypes.TERMINATE_OFFER_SUCCESS : return terminateOfferSuccess(state, action);
        case ActionTypes.TERMINATE_OFFER_FAIL : return terminateOfferFail(state, action);
        case ActionTypes.DECLINE_OFFER_START : return declineOfferStart(state, action);
        case ActionTypes.DECLINE_OFFER_SUCCESS : return declineOfferSuccess(state, action);
        case ActionTypes.DECLINE_OFFER_FAIL : return declineOfferFail(state, action);
        case ActionTypes.FORWARD_OFFER_START : return forwardOfferStart(state, action);
        case ActionTypes.FORWARD_OFFER_SUCCESS : return forwardOfferSuccess(state, action);
        case ActionTypes.FORWARD_OFFER_FAIL : return forwardOfferFail(state, action);
        case ActionTypes.COMMENT_OFFER_START : return commentOfferStart(state, action);
        case ActionTypes.COMMENT_OFFER_SUCCESS : return commentOfferSuccess(state, action);
        case ActionTypes.COMMENT_OFFER_FAIL : return commentOfferFail(state, action);
        case ActionTypes.GET_EMAIL_DEFAULTS_START : return getEmailDefaultsStart(state, action);
        case ActionTypes.GET_EMAIL_DEFAULTS_SUCCESS : return getEmailDefaultsSuccess(state, action);
        case ActionTypes.GET_EMAIL_DEFAULTS_FAIL : return getEmailDefaultsFail(state, action);
        case ActionTypes.MARK_OFFER_ACCEPTED_START : return markOfferAcceptedStart(state, action);
        case ActionTypes.MARK_OFFER_ACCEPTED_SUCCESS : return markOfferAcceptedSuccess(state, action);
        case ActionTypes.MARK_OFFER_ACCEPTED_FAIL : return markOfferAcceptedFail(state, action);
        case ActionTypes.ARCHIVE_OFFER_START : return archiveOfferStart(state, action);
        case ActionTypes.ARCHIVE_OFFER_SUCCESS : return archiveOfferSuccess(state, action);
        case ActionTypes.ARCHIVE_OFFER_FAIL : return archiveOfferFail(state, action);
        case ActionTypes.FIND_OFFER_AUDIT_LOGS_START : return findOfferAuditLogsStart(state, action);
        case ActionTypes.FIND_OFFER_AUDIT_LOGS_SUCCESS : return findOfferAuditLogsSuccess(state, action);
        case ActionTypes.FIND_OFFER_AUDIT_LOGS_FAIL : return findOfferAuditLogsFail(state, action);
        case ActionTypes.DELETE_OFFER_START : return deleteOfferStart(state, action);
        case ActionTypes.DELETE_OFFER_SUCCESS : return deleteOfferSuccess(state, action);
        case ActionTypes.DELETE_OFFER_FAIL : return deleteOfferFail(state, action);
        case ActionTypes.COPY_OFFER_START : return copyOfferStart(state, action);
        case ActionTypes.COPY_OFFER_SUCCESS : return copyOfferSuccess(state, action);
        case ActionTypes.COPY_OFFER_FAIL : return copyOfferFail(state, action);
        default: return state;

    }
}

export default reducer;
