import React, { useEffect, useState } from 'react';
import { IPlan, ISeats, IAppState, ISubscriptionDetail, IUser, IPaymentMethodDetail, IInvoice } from '../../../../interfaces';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from '../../../../withRouter';
import SubscriptionModal from '../../components/Billing/SubscriptionModal/SubscriptionModal';
import { Elements, StripeProvider } from 'react-stripe-elements';
import PaymentMethodModal from './PaymentMethods/PaymentMethodModal/PaymentMethodModal';
import BillingContainer from '../../components/Billing/BillingContainer/BillingContainer';
//import { STRIPE_PUBLIC_KEY } from '../../../..';
import { config } from '../../../../constants';
import ScriptLoader from '../../../../shared/ScriptLoader';
import CustomDialog from '../../../../components/UI/CustomDialog/CustomDialog';

interface IStateProps {
  currentUser?: IUser | null;
}

interface IDispatchProps {
  onFindPlans: () => Promise<Array<IPlan>>;
  onGetSeats: () => Promise<ISeats>;
  onGetCurrentSubscription: () => Promise<ISubscriptionDetail>;
  onCancelSubscription: () => Promise<boolean>;
  onGetPrimaryPaymentMethod: () => Promise<IPaymentMethodDetail>;
  onGetUpcomingInvoice: () => Promise<IInvoice>;
  onGetCurrentUser: () => Promise<void>
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps { }

const Billing: React.FC<IProps> = ({
  onFindPlans, onGetSeats,
  onGetCurrentSubscription,
  onCancelSubscription, history,
  onGetPrimaryPaymentMethod, onGetUpcomingInvoice, onGetCurrentUser
}) => {
  const [plans, setPlans] = useState<Array<IPlan>>(null);
  const [seats, setSeats] = useState<ISeats>(null);
  const [currentSubscription, setCurrentSubscription] = useState<ISubscriptionDetail>(null);
  const [primaryPaymentMethod, setPrimaryPaymentMethod] = useState<IPaymentMethodDetail>(null);
  const [upcomingInvoice, setUpcomingInvoice] = useState<IInvoice>(null);

  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  const [init, setInit] = useState(true);

  const findPlans = async () => {
		const plans = await onFindPlans();
    setPlans(plans);
  }

  const findSeats = async () => {
    const seats = await onGetSeats();
    setSeats(seats);
  }

  const getCurrentSubscription = async () => {
    const currentSubscription = await onGetCurrentSubscription();
    setCurrentSubscription(currentSubscription);
  }

  const getPrimaryPaymentMethod = async () => {
    const primaryPaymentMethod = await onGetPrimaryPaymentMethod();
    setPrimaryPaymentMethod(primaryPaymentMethod);
  }

  const getUpcomingInvoice = async () => {
    const upcomingInvoice = await onGetUpcomingInvoice();
    setUpcomingInvoice(upcomingInvoice);
  }

  useEffect(() => {
    if (init) {
      findPlans();
      findSeats();
      getCurrentSubscription();
      getPrimaryPaymentMethod();
      getUpcomingInvoice();
      setInit(false);
    }
    // eslint-disable-next-line
  }, [init]);

  const manageUsers = () => {
    history.push("/users");
  }

  const manageInvoices = () => {
    history.push("/billing/invoices");
  }

  const togglePlanModal = () => {
    setShowPlanModal(!showPlanModal);
  };

  const togglePaymentMethodModal = () => {
    setShowPaymentMethodModal(!showPaymentMethodModal);
  };

  const toggleCancelSubscriptionModal = () => {
    setShowCancelSubscriptionModal(!showCancelSubscriptionModal);
  };

  const cancelSubscription = async () => {
    await onCancelSubscription();
    await findSeats();
    setCurrentSubscription(null);
    setUpcomingInvoice(null);
    setCancelLoading(false);
    setShowCancelSubscriptionModal(false);
    onGetCurrentUser();
  };

  const setSubscription = (subscription: ISubscriptionDetail) => {
    setCurrentSubscription(subscription);
    findSeats();
    getUpcomingInvoice();
    onGetCurrentUser();
  }

  const [cancelLoading, setCancelLoading] = useState(false);

  return (
    <React.Fragment>
      <CustomDialog
        open={showCancelSubscriptionModal || cancelLoading}
        title="Cancel Subscription"
        onOk={() => {
          setCancelLoading(true);
          cancelSubscription();
        }}
        onCancel={() => setShowCancelSubscriptionModal(false)}
        okButtonText="Confirm"
        cancelButtonText="Cancel"
        loading={cancelLoading}
      >
        Are you sure you want to cancel your subscription?
      </CustomDialog>
      <SubscriptionModal showModal={showPlanModal} toggleModal={togglePlanModal} plans={plans} subscription={currentSubscription} setCurrentSubscription={setSubscription} />
      <ScriptLoader uniqueId="stripe_instance" script="https://js.stripe.com/v3/">
        <StripeProvider apiKey={config.STRIPE_PUBLIC_KEY}>
          <Elements>
            <PaymentMethodModal showModal={showPaymentMethodModal} toggleModal={togglePaymentMethodModal} setPrimaryPaymentMethod={setPrimaryPaymentMethod} />
          </Elements>
        </StripeProvider>
      </ScriptLoader>
      <BillingContainer
        paymentMethod={primaryPaymentMethod}
        upcomingInvoice={upcomingInvoice}
        seats={seats}
        manageUsers={manageUsers}
        subscription={currentSubscription}
        togglePlanModal={togglePlanModal}
        manageInvoices={manageInvoices}
        togglePaymentMethodModal={togglePaymentMethodModal}
        toggleCancelSubscriptionModal={toggleCancelSubscriptionModal}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindPlans: () => dispatch(actions.findPlans()),
    onGetSeats: () => dispatch(actions.getSeats()),
    onGetCurrentSubscription: () => dispatch(actions.getCurrentSubscription()),
    onCancelSubscription: () => dispatch(actions.cancelSubscription()),
    onGetPrimaryPaymentMethod: () => dispatch(actions.getPrimaryPaymentMethod()),
    onGetUpcomingInvoice: () => dispatch(actions.getUpcomingInvoice()),
    onGetCurrentUser: () => dispatch(actions.getCurrentUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing);
