import React, { ReactNode, CSSProperties } from "react";
import ListSpinner from "../ListSpinner/ListSpinner";
import { Alert, Container } from "reactstrap";

import classes from "./FormContainer.module.scss";

interface IProps {
  title: string;
  children: ReactNode;
  loading?: boolean;
  error?: string;
  style?: CSSProperties;
  className?: string;
  containerClassName?: string;
}

const FormContainer: React.FC<IProps> = ({
  title,
  children,
  loading,
  error,
  style,
  className,
  containerClassName
}) => {
  const classNames = [classes.Container];
  if(className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')} style={style}>
      <Container className={containerClassName}>
        {loading ? (
          <ListSpinner />
        ) : error ? (
          <Alert color="danger">{error}</Alert>
        ) : (
          <React.Fragment>
            {title.length && (
              <h4>{title}</h4>
            )}
            {children}
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

export default FormContainer;
