import 'react-app-polyfill/ie11';
import 'array-flat-polyfill';
import "./assets/scss/main.scss";

import 'react-quill-with-table/dist/quill.snow.css';
//import "quill-better-table/dist/quill-better-table.css";

//import mixpanel from "mixpanel-browser";
//import ReactGA from "react-ga";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import * as Sentry from '@sentry/browser';

import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { createBrowserHistory } from 'history';


import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Reducer
} from "redux";
import thunk from "redux-thunk";

import App from "./App";
import i18n from "./i18n";
import { IAppState } from "./interfaces";

import attachmentsReducer from "./store/reducers/attachmentsReducer";
import authReducer from "./store/reducers/authReducer";
import billingReducer from "./store/reducers/billingReducer";
import blocksReducer from "./store/reducers/blocksReducer";
import customersReducer from "./store/reducers/customersReducer";
import importCustomersReducer from "./store/reducers/importCustomersReducer";
import invoicesReducer from "./store/reducers/invoicesReducer";
import offersReducer from "./store/reducers/offersReducer";
import organizationsReducer from "./store/reducers/organizationsReducer";
import paymentMethodsReducer from "./store/reducers/paymentMethodsReducer";
import subscriptionsReducer from "./store/reducers/subscriptionsReducer";
import usersReducer from "./store/reducers/usersReducer";
import userTemplatesReducer from "./store/reducers/userTemplatesReducer";
import embeddedReducer from "./store/reducers/embeddedReducer";
import userGroupsReducer from "./store/reducers/userGroupsReducer";


Sentry.init({dsn: "https://2bd84dbf551044b9aab28e353e63439c@o457568.ingest.sentry.io/5453735"});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
    embeddedAuth?:string;
  }
}

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const rootReducer: Reducer<IAppState> = combineReducers({
  auth: authReducer,
  users: usersReducer,
  offers: offersReducer,
  customers: customersReducer,
  organizations: organizationsReducer,
  blocks: blocksReducer,
  userTemplates: userTemplatesReducer,
  billing: billingReducer,
  invoices: invoicesReducer,
  subscriptions: subscriptionsReducer,
  paymentMethods: paymentMethodsReducer,
  importCustomers: importCustomersReducer,
  attachments: attachmentsReducer,
  embedded: embeddedReducer,
  userGroups: userGroupsReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// export const STRIPE_PUBLIC_KEY =
//   process.env.NODE_ENV === "development"
//     ? "pk_test_FxkP9ccFi40lQz0F2GnY9dUH0094tQv2sk"
//     : "pk_live_meSJIuMpvzx2hMcXzcSnpIvj00gFj0WuHf";
    
//export const MIXPANEL_TOKEN = "926555d3c8707725bd0b9495b6230cb0";
export const INTERCOM_APP_ID = "un034907";

export const FIRSTPROMOTER_ID = "43fz8xly";
export const FIRSTPROMOTER_DOMAIN_NAME = process.env.NODE_ENV === "development" ? "localhost" : "click2contract.com";
export const FIRSTPROMOTER_COOKIE_NAME = "_fprom_track";

const hostname = window && window.location && window.location.hostname;
// if(hostname !== "localhost") {
//   //mixpanel.init(MIXPANEL_TOKEN);
//   //mixpanel.opt_in_tracking();

//   ReactGA.initialize('UA-149039081-1', {
//     //debug: true,
//   });
// }

const history = createBrowserHistory();

const root = (
  <Suspense fallback="loading">
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </Suspense>
);

ReactDOM.render(root, document.getElementById("root"));

// serviceWorker.unregister();
