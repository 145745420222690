import { faCheck, faEnvelope, faEye, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import CustomDialog from '../../../../../../../../../components/UI/CustomDialog/CustomDialog';
import { EOfferStatus } from '../../../../../../../../../enums';
import { IOffer, IOfferHistory, IOfferHistoryView } from '../../../../../../../../../interfaces';

import classes from "./OfferHistory.module.scss";

interface IProps {
  offer: IOffer;
}

const OfferHistory: React.FC<IProps> = ({ offer }) => {
  const [statusDetails, setStatusDetails] = useState(null);

  const renderView = (text: string, isView: boolean, key: number) => {
    const classNames = [classes.ViewIcon];
    if (isView) {
      classNames.push(classes.Viewed);
    }
    return (
      <div key={key} className={classes.View}>
        <span className={classNames.join(" ")}>
          <FontAwesomeIcon icon={faEye} />
        </span>
        {text}
      </div>
    );
  };

  const renderViews = (views: Array<IOfferHistoryView>) => {
    if (!views || !views.length)
      return renderView("Not viewed yet", false, 5000);
    return views.map((offerHistoryView, i) =>
      renderView(
        `${offerHistoryView.viewCount} Viewed ${offerHistoryView.viewDate}`,
        true,
        i
      )
    );
  };

  const renderHistory = (history: IOfferHistory) => {
    let title = "",
      subTitle = "",
      icon = null,
      iconClassName = "",
      titleClassName = "";
    try {
    switch (history.offerStatus) {
      case EOfferStatus.SENT:
        title = `Sent - ${history.user.name}`;
        subTitle = `Sent on ${history.created}`;
        icon = faEnvelope;
        iconClassName = classes.IconSent;
        titleClassName = classes.TitleSent;
        break;
      case EOfferStatus.DECLINED:
        title = `Declined - ${offer.customer.name}`;
        subTitle = `Declined on ${history.created}`;
        icon = faTimes;
        iconClassName = classes.IconDeclined;
        titleClassName = classes.TitleDeclined;
        break;
      case EOfferStatus.CONFIRMED:
        title = `Confirmed - ${
          history.user ? history.user.name : offer.customer.name
        }`;
        subTitle = `Confirmed on ${history.created}`;
        icon = faCheck;
        iconClassName = classes.IconConfirmed;
        titleClassName = classes.TitleConfirmed;
        break;
      case EOfferStatus.TERMINATED:
        title = `Terminated - ${
          history.user ? history.user.name : offer.customer.name
        }`;
        subTitle = `Terminated on ${history.created}`;
        icon = faTimes;
        iconClassName = classes.IconDeclined;
        titleClassName = classes.TitleDeclined;
        break;
      default:
        return;
    }
    } catch(err) {}

    let statusDetails = null;
    /**
     * If history has statusDetails we show info icon. When clicked show dialog with content
     */
    if (history.statusDetails && history.statusDetails.length) {
      statusDetails = (
        <span
          className={classes.StatusDetails}
          onClick={() =>
            setStatusDetails({
              title: title,
              message: history.statusDetails
            })
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      );
    }

    return (
      <div className={classes.HistoryContainer} key={history.id}>
        {icon && <div className={[classes.Icon, iconClassName].join(" ")}>
          <FontAwesomeIcon icon={icon} />
        </div>}
        <div className={classes.HistoryContent}>
          <div className={[classes.Title, titleClassName].join(" ")}>
            {title} {statusDetails}
          </div>
          <div className={classes.SubTitle}>{subTitle}</div>
          {history.offerStatus === EOfferStatus.SENT && (
            <div className={classes.Views}>{renderViews(history.views)}</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <CustomDialog
        loading={false}
        onOk={() => setStatusDetails(null)}
        open={statusDetails ? true : false}
        title={statusDetails && statusDetails.title}
        okButtonText="Close"
        closable
      >
        {statusDetails && statusDetails.message}
      </CustomDialog>
      <div className={classes.Container}>
        {offer.history && offer.history.map(renderHistory)}
      </div>
    </React.Fragment>
  );
};

export default OfferHistory;
