import React from "react";
//import CustomButtonGroup from "../../../../../../components/UI/CustomButtonGroup/CustomButtonGroup";
import { Button, DropdownItem } from "reactstrap";
import { EPageView } from "../../../../../../enums";
import CustomDropdown from "../../../../../../components/UI/CustomPopover/CustomDropdown";

import classes from "./TemplateFooter.module.scss";

interface IProps {
  pageView: EPageView;
  onViewChanged: (pageView: EPageView) => void;
  onDiscard: () => void;
  onSave: () => void;
  saveText: string;
  isValid: boolean;
  onDelete: () => void;
  onCopy: () => void;
  isAdd: boolean;
}

const TemplateFooter: React.FC<IProps> = ({ pageView, onViewChanged, onDiscard, onSave, saveText, isValid, onDelete, onCopy, isAdd }) => {
	/*
  const items = [
    {
      type: EPageView.EDIT,
      text: "Edit"
    },
    {
      type: EPageView.PREVIEW,
      text: "Preview"
    },
    {
      type: EPageView.COMBINED,
      text: "Combined"
    },
  ];
	 */
  return (
    <div className={classes.Container}>
			<div></div>
		{/*
      <CustomButtonGroup
        items={items}
        clicked={onViewChanged}
        selected={pageView}
      />
		*/}
      <div className={classes.Buttons}>
        {!isAdd ? (
          <CustomDropdown>
            <DropdownItem onClick={onCopy}>Copy template</DropdownItem>
            <DropdownItem onClick={onDelete}>Delete template</DropdownItem>
          </CustomDropdown>
        ) : null}
        <Button color="link" onClick={onDiscard}>Discard changes</Button>
        <Button color="info" onClick={onSave} disabled={!isValid}>{saveText}</Button>
      </div>
    </div>
  );
};

export default TemplateFooter;
