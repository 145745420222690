import { EOfferListType, EOfferSort } from '../../enums/offer-enums';
import { IAuditLog } from './../../interfaces/domain';
import { Dispatch } from "redux";
import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IOfferAction, IOffer, IEmail, ISearchOffer } from "../../interfaces";
import { ISort } from "../../components/UI/SortIcon/SortIcon";
import axiosPackage, { Canceler } from "axios";

const OFFERS_PATH='json/offers2';

let cancel: Canceler;

export const clearOffersState = (): IOfferAction => {
  if (cancel) {
    cancel();
  }
  return {
    type: ActionTypes.CLEAR_OFFERS_STATE
  };
};

export const findOffersStart = (): IOfferAction => {
  return {
    type: ActionTypes.FIND_OFFERS_START
  };
};

export const findOffersSuccess = (
  offers: Array<IOffer>,
): IOfferAction => {
  return {
    type: ActionTypes.FIND_OFFERS_SUCCESS,
    offers
  };
};

export const findOffersFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.FIND_OFFERS_FAIL,
    error
  };
};

export const findOffers = (sortParam: Array<ISort<EOfferSort>>, listType: EOfferListType, extra?:string) => {
  return async (dispatch: Dispatch) => {
    if (cancel) {
      cancel();
    }
    dispatch(findOffersStart());
    try {
      const res = await axios.get(OFFERS_PATH+"/list?listType="+listType+"&sort="+encodeURIComponent(JSON.stringify(sortParam))+(extra?extra:""), {
        cancelToken: new axiosPackage.CancelToken(c => {
          cancel = c;
        })
      });
      const offers = res.data as IOffer[];
      dispatch(findOffersSuccess(offers));
      //@ts-ignore
    } catch (error: any) {
      if (error.response) {
        dispatch(findOffersFail(error.response.data));
      }
    }
  };
};

export const getOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.GET_OFFER_START
  };
};

export const getOfferSuccess = (offer: IOffer): IOfferAction => {
  return {
    type: ActionTypes.GET_OFFER_SUCCESS,
    offer
  };
};

export const getOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.GET_OFFER_FAIL,
    error
  };
};

export const getOffer = (id: string, token: string, printable: boolean, email?: string, hash?:string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getOfferStart());
    try {
      let printableString = "";
      if (printable) {
        printableString = "&printable=true";
      }

      let hashstring="";
      if(email && hash) {
        hashstring=`&email=${encodeURIComponent(email)}&hash=${encodeURIComponent(hash)}`
      } 

      const res = await axios.get(OFFERS_PATH+`/get?id=${id}&token=${token}${printableString}${hashstring}`);
      const offer = res.data;

      dispatch(getOfferSuccess(offer));
      return offer;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getOfferFail(error.response.data));
    }
  };
};

export const saveOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.SAVE_OFFER_START
  };
};

export const saveOfferSuccess = (): IOfferAction => {
  return {
    type: ActionTypes.SAVE_OFFER_SUCCESS
  };
};

export const saveOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.SAVE_OFFER_FAIL,
    error
  };
};

export const saveOffer = (offer: IOffer, params?:string) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveOfferStart());
    try {
      const res=await axios.post(OFFERS_PATH+`/save?`+params, offer);
      dispatch(saveOfferSuccess());
      return res.data;
      //@ts-ignore
    } catch (error: any) {
      dispatch(saveOfferFail(error.response.data));
    }
    return null;
  };
};

export const updateOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.UPDATE_OFFER_START
  };
};

export const updateOfferSuccess = (): IOfferAction => {
  return {
    type: ActionTypes.UPDATE_OFFER_SUCCESS
  };
};

export const updateOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.UPDATE_OFFER_FAIL,
    error
  };
};

export const updateOffer = (id: string, offer: IOffer, params?:string) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateOfferStart());
    try {
			// console.log(JSON.stringify(offer,null,' '));
      // offer.userTemplate.header=offer.userTemplate.main;
      // offer.userTemplate.footer=offer.userTemplate.main;
      const res=await axios.put(OFFERS_PATH+`/update?id=${id}&`+params, offer);
      dispatch(updateOfferSuccess());
      return res.data;
      //@ts-ignore
    } catch (error: any) {
      dispatch(updateOfferFail(error.response.data));
    }
    return null;
  };
};

export const customerUpdateOffer = (id: string, token:string, offer: IOffer) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateOfferStart());
    try {
			//console.log(JSON.stringify(offer,null,' '));
      await axios.put(OFFERS_PATH+`/customerupdate?id=${id}&token=${token}`, offer);
      dispatch(updateOfferSuccess());
      //@ts-ignore
    } catch (error: any) {
      dispatch(updateOfferFail(error.response.data));
    }
  };
};


export const searchOffersStart = (): IOfferAction => {
  return {
    type: ActionTypes.SEARCH_OFFERS_START
  };
};

export const searchOffersSuccess = (
  search: Array<ISearchOffer>
): IOfferAction => {
  return {
    type: ActionTypes.SEARCH_OFFERS_SUCCESS,
    search
  };
};

export const searchOffersFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.SEARCH_OFFERS_FAIL,
    error
  };
};

export const searchOffers = (queryString: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(searchOffersStart());
    try {
      const res = await axios.get(OFFERS_PATH+`/search?q=${queryString}`);
      dispatch(searchOffersSuccess(res.data));
      //@ts-ignore
    } catch (error: any) {
      dispatch(searchOffersFail(error.response.data));
    }
  };
};

export const clearOffersSearch = (): IOfferAction => {
  return {
    type: ActionTypes.CLEAR_OFFERS_SEARCH
  };
};

export const sendOfferConfirmCodeStart = (): IOfferAction => {
  return {
    type: ActionTypes.SEND_OFFER_CONFIRM_CODE_START
  };
};

export const sendOfferConfirmCodeSuccess = (): IOfferAction => {
  return {
    type: ActionTypes.SEND_OFFER_CONFIRM_CODE_SUCCESS
  };
};

export const sendOfferConfirmCodeFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.SEND_OFFER_CONFIRM_CODE_FAIL,
    error
  };
};

export const sendOfferConfirmCode = (id: string, token: string, email?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(sendOfferConfirmCodeStart());
    try {
      let emailstring = "";
      if(email) {
        emailstring=`&email=${encodeURIComponent(email)}`
      } 
      await axios.post(OFFERS_PATH+`/sendconfirmcode?id=${id}&token=${token}${emailstring}`);
      dispatch(sendOfferConfirmCodeSuccess());
      //@ts-ignore
    } catch (error: any) {
      dispatch(sendOfferConfirmCodeFail(error.response.data));
    }
  };
};

export const confirmOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.CONFIRM_OFFER_START
  };
};

export const confirmOfferSuccess = (offer: IOffer): IOfferAction => {
  return {
    type: ActionTypes.CONFIRM_OFFER_SUCCESS,
    offer
  };
};

export const confirmOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.CONFIRM_OFFER_FAIL,
    error
  };
};

export const confirmOffer = (data: Object) => {
  return async (dispatch: Dispatch) => {
    dispatch(confirmOfferStart());
    try {
      const res = await axios.post(OFFERS_PATH+"/confirm", data);
      dispatch(confirmOfferSuccess(res.data));
      return res.data;
      //@ts-ignore
    } catch (error: any) {
      dispatch(confirmOfferFail(error.response.data));
      return null;
    }
  };
};

export const terminateOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.TERMINATE_OFFER_START
  };
};

export const terminateOfferSuccess = (): IOfferAction => {
  return {
    type: ActionTypes.TERMINATE_OFFER_SUCCESS
  };
};

export const terminateOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.TERMINATE_OFFER_FAIL,
    error
  };
};

export const terminateOffer = (data: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(terminateOfferStart());
    try {
      const res = await axios.post(OFFERS_PATH+"/terminate", data);
      dispatch(terminateOfferSuccess());
      return res.data;
      //@ts-ignore
    } catch (error: any) {
      dispatch(terminateOfferFail(error.response.data));
    }
  };
};

export const declineOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.DECLINE_OFFER_START
  };
};

export const declineOfferSuccess = (offer: IOffer): IOfferAction => {
  return {
    type: ActionTypes.DECLINE_OFFER_SUCCESS,
    offer
  };
};

export const declineOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.DECLINE_OFFER_FAIL,
    error
  };
};

export const declineOffer = (data: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(declineOfferStart());
    try {
      const res = await axios.post(OFFERS_PATH+"/decline", data);
      dispatch(declineOfferSuccess(res.data));
      //@ts-ignore
    } catch (error: any) {
      dispatch(declineOfferFail(error.response));
    }
  };
};

export const forwardOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.FORWARD_OFFER_START
  };
};

export const forwardOfferSuccess = (): IOfferAction => {
  return {
    type: ActionTypes.FORWARD_OFFER_SUCCESS
  };
};

export const forwardOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.FORWARD_OFFER_FAIL,
    error
  };
};

export const forwardOffer = (data: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(forwardOfferStart());
    try {
      await axios.post(OFFERS_PATH+"/forward", data);
      dispatch(forwardOfferSuccess());
      //@ts-ignore
    } catch (error: any) {
      dispatch(forwardOfferFail(error.response));
    }
  };
};

export const commentOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.COMMENT_OFFER_START
  };
};

export const commentOfferSuccess = (offer: IOffer): IOfferAction => {
  return {
    type: ActionTypes.COMMENT_OFFER_SUCCESS,
    offer
  };
};

export const commentOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.COMMENT_OFFER_FAIL,
    error
  };
};

export const commentOffer = (data: Object) => {
  return async (dispatch: Dispatch) => {
    dispatch(commentOfferStart());
    try {
      const res = await axios.post(OFFERS_PATH+"/comment", data);
      const offer = res.data;
      dispatch(commentOfferSuccess(offer));
      return offer;
      //@ts-ignore
    } catch (error: any) {
      dispatch(commentOfferFail(error.response));
    }
  };
};

export const getEmailDefaultsStart = (): IOfferAction => {
  return {
    type: ActionTypes.GET_EMAIL_DEFAULTS_START
  };
};

export const getEmailDefaultsSuccess = (email: IEmail): IOfferAction => {
  return {
    type: ActionTypes.GET_EMAIL_DEFAULTS_SUCCESS,
    email
  };
};

export const getEmailDefaultsFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.COMMENT_OFFER_FAIL,
    error
  };
};

export const getEmailDefaults = (data: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmailDefaultsStart());
    try {
      const res = await axios.post(OFFERS_PATH+"/emaildefaults", data);
      dispatch(getEmailDefaultsSuccess(res.data));
      return res.data;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getEmailDefaultsFail(error.response));
    }
  };
};

export const markOfferAcceptedStart = (): IOfferAction => {
  return {
    type: ActionTypes.MARK_OFFER_ACCEPTED_START
  };
};

export const markOfferAcceptedSuccess = (offer: IOffer): IOfferAction => {
  return {
    type: ActionTypes.MARK_OFFER_ACCEPTED_SUCCESS,
    offer
  };
};

export const markOfferAcceptedFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.MARK_OFFER_ACCEPTED_FAIL,
    error
  };
};

export const markOfferAccepted = (data: {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(markOfferAcceptedStart());
    try {
      const res = await axios.post(OFFERS_PATH+"/markaccepted", data);
      dispatch(markOfferAcceptedSuccess(res.data));
      return res.data;
      //@ts-ignore
    } catch (error: any) {
      dispatch(markOfferAcceptedFail(error.response));
    }
  };
};

export const archiveOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.ARCHIVE_OFFER_START
  };
};

export const archiveOfferSuccess = (offer: IOffer): IOfferAction => {
  return {
    type: ActionTypes.ARCHIVE_OFFER_SUCCESS,
    offer
  };
};

export const archiveOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.ARCHIVE_OFFER_FAIL,
    error
  };
};

export const archiveOffer = (id: string, archived: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(archiveOfferStart());
    try {
      const res = await axios.post(OFFERS_PATH+"/archive", {
        id,
        archived
      });
      const offer = res.data;
      dispatch(archiveOfferSuccess(offer));
      return offer;
      //@ts-ignore
    } catch (error: any) {
      dispatch(archiveOfferFail(error.response.data));
    }
  };
};


export const findOfferAuditLogsStart = (): IOfferAction => {
  return {
    type: ActionTypes.FIND_OFFER_AUDIT_LOGS_START
  };
};

export const findOfferAuditLogsSuccess = (
  auditLogs: Array<IAuditLog>,
): IOfferAction => {
  return {
    type: ActionTypes.FIND_OFFER_AUDIT_LOGS_SUCCESS,
    auditLogs
  };
};

export const findOfferAuditLogsFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.FIND_OFFER_AUDIT_LOGS_FAIL,
    error
  };
};

export const findOfferAuditLogs = (id: string, token: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(findOfferAuditLogsStart());
    try {
      const res = await axios.get(OFFERS_PATH+`/auditlogs?id=${id}&token=${token}`);
      const auditLogs = res.data as Array<IAuditLog>;
      dispatch(findOfferAuditLogsSuccess(auditLogs));
      return auditLogs;
      //@ts-ignore
    } catch (error: any) {
      if (error.response) {
        dispatch(findOfferAuditLogsFail(error.response.data));
      }
    }
  };
};

export const deleteOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.DELETE_OFFER_START
  };
};

export const deleteOfferSuccess = (): IOfferAction => {
  return {
    type: ActionTypes.DELETE_OFFER_SUCCESS
  };
};

export const deleteOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.DELETE_OFFER_FAIL,
    error
  };
};

export const deleteOffer = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteOfferStart());
    try {
      await axios.delete(OFFERS_PATH+`/delete?id=${id}`);
      dispatch(deleteOfferSuccess());
      //@ts-ignore
    } catch (error: any) {
      if (error.response) {
        dispatch(deleteOfferFail(error.response.data));
      }
    }
  };
};

export const copyOfferStart = (): IOfferAction => {
  return {
    type: ActionTypes.COPY_OFFER_START
  };
};

export const copyOfferSuccess = (): IOfferAction => {
  return {
    type: ActionTypes.COPY_OFFER_SUCCESS
  };
};

export const copyOfferFail = (error: string): IOfferAction => {
  return {
    type: ActionTypes.COPY_OFFER_FAIL,
    error
  };
};

export const copyOffer = (offer: IOffer) => {
  return async (dispatch: Dispatch) => {
    dispatch(copyOfferStart());
    try {
      const res = await axios.post(OFFERS_PATH+`/copy`, offer);
      dispatch(copyOfferSuccess());
      return res.data as IOffer;
      //@ts-ignore
    } catch (error: any) {
      if (error.response) {
        dispatch(copyOfferFail(error.response.data));
      }
    }
  };
};
