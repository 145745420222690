import React, { useEffect, useState } from "react";
//import { DndProvider } from "react-dnd";
//import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../../withRouter';
import { useParams } from "react-router-dom";

import FooterFixed from "../../../../../components/UI/FooterFixed/FooterFixed";
import FormContainer from "../../../../../components/UI/FormContainer/FormContainer";
import FormFooter from "../../../../../components/UI/FormFooter/FormFooter";
import { IAppState, IBlock, IBlockContent } from "../../../../../interfaces";
import { controlsToFormGroups, getFormData, initForm, validateInput } from "../../../../../shared/utility";
import * as actions from "../../../../../store/actions";
import ContentArea from "../../../components/Blocks/Block/ContentArea/ContentArea";
import { EUserTemplateBlockType } from "../../../../../enums";
import CustomDialog from "../../../../../components/UI/CustomDialog/CustomDialog";

import classes from "./Block.module.scss";

interface IStateProps {
  block: IBlock;
  loading: boolean;
  error: string;
}

interface IDispatchProps {
  onGetBlock: (id: string) => Promise<IBlock>;
  onSaveBlock: (block: IBlock) => Promise<boolean>;
  onUpdateBlock: (id: string, block: IBlock) => Promise<boolean>;
  onDeleteBlock: (id:string) => Promise<boolean>;
}

interface IMatchParams {
  id: string;
}

interface IProps
  extends RouteComponentProps, //<IMatchParams>,
    IStateProps,
    IDispatchProps {}

interface IDialog {
  open: boolean;
  onOk?: () => void;
}


export enum EBlockEditingStyle {
  INLINE = 'INLINE',
  LIST = 'LIST'
}

const blockEditingStyles = [
  { id: EBlockEditingStyle.INLINE, label: 'Open for edit'},
  { id: EBlockEditingStyle.LIST, label: 'Edit available in popup'}
]

const Block: React.FC<IProps> = ({
  history,
  match,
  onSaveBlock,
  onUpdateBlock,
  onGetBlock,
  block,
  loading,
  error,
  onDeleteBlock
}) => {
  const params = useParams();
  //const { id } = match.params;
  const id = params.id;
  
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [contentItems, setContentItems] = useState<Array<IBlockContent>>([]);
  const [controlsState, setControlsState] = useState({
    controls: {
      name: {
        elementType: "input",
        elementConfig: {
          label: "Multi item element name",
          placeholder: "",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: ""
      },
      editingStyle: {
        elementType: "select",
        elementConfig: {
          label: "Editing style in Create proposal view (Open for edit or Edit available in popup)"
        },
        valid: false,
        validation: {
          required: true
        },
        touched: false,
        value: "",
        options: blockEditingStyles
      }
    },
    controlsIsValid: false
  });

  useEffect(() => {
    if (id !== "add") {
      onGetBlock(id);
    }
  }, [id, onGetBlock]);

  useEffect(() => {
    if (block) {
      const controls = initForm(controlsState.controls, block);
      setControlsState({
        controls: controls.controls,
        controlsIsValid: controls.formIsValid
      });
      setContentItems(block.content || []);
    }
    // eslint-disable-next-line
  }, [block]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value = event.target ? event.target.value : event;
 
    const validation = validateInput(
      controlsState.controls,
      controlName,
      value
    );

    setControlsState({
      controls: validation.controls,
      controlsIsValid: validation.formIsValid
    });
  };

  const onSubmit = async () => {
    const formData = getFormData(controlsState.controls);

    const newBlock: IBlock = {
      id,
      name: formData.name,
      editingStyle: formData.editingStyle,
      content: [...contentItems],
      items: null
    };

    let success = false;
    if (id === "add") {
      success = await onSaveBlock(newBlock);
    } else {
      success = await onUpdateBlock(id, newBlock);
    }

    if (success) {
      history.push("/blocks");
    }
  };

  const onDiscard = () => {
    history.goBack();
  };

  const deleteBlock = async () => {
    await onDeleteBlock(block.id);
    history.push(`/blocks`);
  };

  let title = null;
  let submitText = null;
  if (block) {
    title = "Update multi item element";
    submitText = "Update element";
  } else {
    title = "Add a new multi item element";
    submitText = "Save element";
  }

  return (
    <React.Fragment>
      <CustomDialog
        loading={false}
        onOk={deleteBlock}
        okButtonText={"OK"}
        onCancel={() => setDeleteDialogOpen(false)}
        open={deleteDialogOpen}
        title={"Are you sure?"}
        isValid={true}
      >
        Deleting the element does not remove it from existing Contracts.
      </CustomDialog>
      
      <FormContainer title={title} loading={loading} error={error}>
        {controlsToFormGroups(controlsState.controls, inputChangedHandler)}

            <div className={classes.ContentArea}>
              <label>Element Content Type</label>
              <ContentArea
                items={contentItems}
                setItems={setContentItems}
                border
                addText="Add new content type"
                blockType={EUserTemplateBlockType.DYNAMIC}
              />
            </div>
          
      </FormContainer>

      <FooterFixed>
        <FormFooter
          saveText={submitText}
          isValid={controlsState.controlsIsValid}
          onSave={onSubmit}
          onDiscard={onDiscard}
          loading={loading}
          deleteText="Delete element"
          onDelete={id !== "add" ? () => setDeleteDialogOpen(true) : null}
        />
      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.blocks.loading,
    error: state.blocks.error,
    block: state.blocks.block
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetBlock: id => dispatch(actions.getBlock(id)),
    onSaveBlock: block => dispatch(actions.saveBlock(block)),
    onUpdateBlock: (id, block) => dispatch(actions.updateBlock(id, block)),
    onDeleteBlock: (id) => dispatch(actions.deleteBlock(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Block);
