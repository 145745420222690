import React, { useRef, useEffect, useState } from "react";

import CustomDialog from "../../../../../../components/UI/CustomDialog/CustomDialog";
import { IOffer } from "../../../../../../interfaces";
import { Button } from "reactstrap";
import { InputGroup, Input } from 'reactstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import classes from "./SelfSendEmail.module.scss";
/*
const copyEmailToClipboard = (value: string) => {
  let input = document.createElement('div');
  input.innerHTML = value;

  document.body.appendChild(input);

  var currentRange:Range | null;
  if(document.getSelection().rangeCount > 0)
  {
       currentRange = document.getSelection().getRangeAt(0);
    //window.getSelection().removeRange(currentRange);
  }
  else
  {
       currentRange = null;
  }

  var CopyRange = document.createRange();
  CopyRange.selectNode(input);
  window.getSelection().addRange(CopyRange);
  document.execCommand("copy");
  
  //window.getSelection().removeRange(CopyRange);

  if(currentRange)
  {
       window.getSelection().addRange(currentRange);
  }

  document.body.removeChild(input)
};
 */
interface IProps {
  isOpen:boolean;
  cancel:()=>void;
  offer:IOffer; 
  onOk:()=>void;
}

const SelfSendEmail: React.FC<IProps> = ({
  isOpen,
  cancel,
  offer,
  onOk,
}) => {
  const [forceClose, setForceClose] = useState<boolean>(false);
  const ref=useRef(null);
  const linkRef = useRef(null);
  
  const copy2clipboard = () => {
    //console.log("copy clip");
    if(ref) {
    var selection = window.getSelection();
    var range = document.createRange();
    range.selectNodeContents(ref.current);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("Copy")

        /*
      var currentRange:Range | null;
      if(document.getSelection().rangeCount > 0)
      {
        currentRange = document.getSelection().getRangeAt(0);
      }
      else
      {
        currentRange = null;
      }

      var CopyRange = document.createRange();
        
      CopyRange.selectNode(ref.current);
      window.getSelection().addRange(CopyRange);
      document.execCommand("copy");

         */      
      /*      
      if(currentRange)
      {
        window.getSelection().addRange(currentRange);
      }
       */
    }
  }
/*
  const onCancel = () => {    
    cancel();
  }
*/
  const GetBody = () => {
    /*
    const linkText:{[id:string]:string}={
      "en": "Click here to open the proposal",
      "se": "Klicka här för att öppna förslaget",
      "de": "Öffnen Sie das Angebot",
      "es": "Abre la propuesta haciendo clic aquí",
      "da": "Åbn oplægget ved at klikke her",
      "fr": "Ouvrez la proposition en cliquant ici",
      "nl": "Klik hier om het voorstel te bekijken",
      "fi": "Avaa tarjous klikkaamalla tästä",
      "pt": "Abra a proposta clicando aqui",
    };
    */
    const linkText:{[id:string]:string}={
      "en": "Review Document",
      "se": "Granska dokument",
      "de": "Dokument prüfen",
      "es": "Revisar documento",
      "da": "Gennemgå dokument",
      "fr": "Relire le document",
      "nl": "Document bekijken",
      "fi": "Avaa dokumentti",
      "pt": "Revisar o documento",
    };
    
    const lang=(offer.customer && offer.customer.language) || "en";
    const langLink=linkText[lang] || linkText["en"];

    //const cc=(offer.recipients || []).join();

    const body:string=((offer.email?offer.email.body:"") || "").replace(/\n/g,"<br/>");
    //    return (<span>
    //      <span style={{"backgroundColor":"unset"}}>{body}</span><br/><br/><a style={{"color":"rgb(52,152,219)","backgroundColor":"unset"}} href={offer.link} target="_blank">{langLink}</a>
    //    </span>)
    //    return '<span><span style="background-color:unset;">'+body+'</span><br/><br/><a style="color:rgb(52,152,219);background-color:unset;" href="'+offer.link+'" target="_blank">'+langLink+'</a></span>';
    return (<span>
      <span style={{"backgroundColor":"unset"}}>
        <div dangerouslySetInnerHTML={{__html: body}}></div>
      </span>
      <a style={{"color":"rgb(52,152,219)","backgroundColor":"unset"}} href={offer.link} target="_blank" rel="noopener noreferrer">{langLink}</a>
    </span>)


  }
    /*
  const extraClick = () => {
    //console.log("extra click");
    //copyEmailToClipboard(getBody());
    copy2clipboard();
  }
     */
  const openEmailClient = () => {
    const cc=(offer.recipients || []).join();

    window.open("mailto:"+(offer.customer.email || "")+"?subject="+(offer.email?offer.email.title:"")+(cc?"&cc="+cc:"")+"&body=paste email content from your clipboard (Windows: Ctrl+V or MacOS: Command+V)");
  }

  const [recipients, setRecipients] = useState(null);
  useEffect(() => {

    if(offer && offer.userTemplate && offer.userTemplate.main && offer.userTemplate.main.blocks) {
    
    const _recipients = offer.userTemplate.main.blocks.reduce((resp:any,block:any)=>{
      const c = [block.content || []].find((content:any)=>content.contentType == "SIGNATURES");
      if(c) {
        resp = [...resp,...c.signatures.filter((x:any)=>x.signerType != "user" && x.hash != null).map((x:any)=>offer.link+"&email="+encodeURIComponent(x.email)+"&hash="+encodeURIComponent(x.hash)+"\n")];
      }
      return resp;
    },[]);
    setRecipients(_recipients.length>0?_recipients:null);
    
    }
  },[offer]);

  useEffect(()=>{
    if(isOpen && offer.customer && ref.current) {      
      copy2clipboard();
      openEmailClient();
    }
  },[isOpen && offer.customer && ref.current]);
  
  useEffect(()=> {
    if(forceClose) { 
      document.body.classList.remove('modal-open');
      onOk(); 
    }
  },[forceClose]);

  return (
    <CustomDialog
      loading={false}
      open={isOpen && !forceClose}
      title="Send from elsewhere"
      isValid={true}
    >
      <p>
        If your default email program didn’t open, you can copy the proposal link and email from here and paste it to an email or elsewhere (like SMS or WhatsApp) to be send.
      </p>

      <div>To: <b>{offer.customer && offer.customer.email || ""}</b></div>
      <div>cc: <b>{(offer.recipients || []).join()}</b></div>
      <div>Subject: <b>{offer.email?offer.email.title:""}</b></div>
      <div ref={ref} className={classes.body}><GetBody/></div>

      Raw link to the proposal
      <InputGroup>
        <Input readOnly ref={linkRef} value={recipients?recipients:offer.link} />
        
          <Button color="secondary" onClick={()=>{
            navigator.clipboard.writeText(recipients?recipients:offer.link).then(() => {
              console.log('Async: Copying to clipboard was successful!');
            }, (err) => {
              console.error('Async: Could not copy text: ', err);
            })
          }}>
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        
      </InputGroup>

      <br/>

      <div className="text-right">
        <Button className="float-left" color="info" onClick={()=>{setForceClose(true);}}>OK</Button>
        <Button color="info" onClick={copy2clipboard}>Copy to clipboard</Button>
      </div>

      {/*
      <ol>
        <li>Copy text to clipboard </li>
        <li>Open email client</li>
        <li>Paste text</li>
        <li>Send</li>
        <li>Confirm that email has been sent by clicking "Set as send"</li>
      </ol>

      <div style={{"border":"solid 1px #ddd","padding":5,"margin":5}}>
        <div ref={ref}><GetBody/></div>
        <hr/>
        <div className="text-right">
          <Button color="primary" onClick={extraClick}>Copy to clipboard</Button>
          {' '}<Button color="primary" onClick={openEmailClient}>Open Email client</Button>

        </div>

      </div>
        */}


    </CustomDialog>
  );
};

export default SelfSendEmail;
