import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./CustomButtonGroup.module.scss";

interface IProps {
  className?: string;
  style?: any;
  size?: string;
  selected: any;
  items: Array<{ type: any; text: string; icon?: IconProp, disabled?: boolean }>;
  clicked: (type: any) => void;
}

const CustomButtonGroup: React.FC<IProps> = props => {
  return (
    <ButtonGroup size={props.size} className={props.className} style={{...props.style}}>
      {props.items.map(item => (
        <Button
          key={item.type}
          outline={item.type !== props.selected}
          color={item.type === props.selected ? "primary" : "secondary"}
          onClick={() => props.clicked(item.type)}
          disabled={item.disabled}
        >
          {item.icon && (
            <FontAwesomeIcon icon={item.icon} className={classes.Icon} />
          )}
          {item.text}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default CustomButtonGroup;
