import React from "react";
import { IOffer } from '../../../../../../../../interfaces';

interface IProps {
    offer: IOffer;
}

// "frame" for Audit log -tab

const OfferSidebarAuditLog: React.FC<IProps> = ({offer}) => {
    return (
        <div>Audit log</div>
    );
}

export default OfferSidebarAuditLog;
