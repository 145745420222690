import React from "react";

import { Input } from 'reactstrap'
import { InputGroup, InputGroupText } from 'reactstrap';

import ContentTypeIcon from "../../../../../../../components/UI/ContentTypeIcon/ContentTypeIcon";
import { EContentType } from "../../../../../../../enums";

import { Row, Col } from 'reactstrap'

import { round } from './functions';

import classes from "./BlockContentProductsEdit.module.scss";
const CustomInput = (props: any) => {
  const { formElement }=props

  if(formElement.config.deleted) return null;
  return (<><Row style={{"marginRight":0}}>
		<Col>
			<InputGroup>
        
          <InputGroupText style={{padding:5}}>
						<Input addon type="checkbox"
							checked={!formElement.config.optional}
              onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"optional",!e.target.checked)}
    					onBlur={props.onBlur}
						/>
						<Input addon type="checkbox"
							disabled={!formElement.config.optional}
							style={{ marginLeft:5 }}
							checked={formElement.config.optionalSelected}
              onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"optionalSelected",e.target.checked)}
    					onBlur={props.onBlur}
						/>

          </InputGroupText>
        

				<Input
					style={{"resize":"none"}}
					type="textarea"
					rows={formElement.config.item.split("\n").length}
					className={[props.className,classes.customInput].join(" ")}
      	
	    	  defaultValue={formElement.config.item}
					onChange={(e:any)=>formElement.config.onItemChange(formElement.config.index,"item",e.target.value)}
    		  invalid={!formElement.config.valid && formElement.config.touched}
      		disabled={formElement.config.disabled}
					onBlur={props.onBlur}
				/>
			</InputGroup>

		</Col>

		<Col xs={1} className={classes.iCol}>
			<Input
				className={[props.className,classes.customInput].join(" ")}
      
	      defaultValue={formElement.config.unitPrice}
				onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"unitPrice",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/> 
		</Col>

		<Col xs={1} className={classes.iCol}>
			<InputGroup>
        
          <InputGroupText style={{padding:5}}>
						<Input addon type="checkbox"
							checked={formElement.config.quantityEditable}
              onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"quantityEditable",e.target.checked)}
    					onBlur={props.onBlur}
						/>
          </InputGroupText>
        

				<Input
					className={[props.className,classes.customInput].join(" ")}
	      
		      defaultValue={formElement.config.quantity}
					onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"quantity",e.target.value)}
	    	  invalid={!formElement.config.valid && formElement.config.touched}
	      	disabled={formElement.config.disabled}
					onBlur={props.onBlur}
				/>
      </InputGroup>

		</Col>

		<Col xs={1} className={classes.iCol}>
			<Input
				className={[props.className,classes.customInput].join(" ")}
      
	      defaultValue={formElement.config.unit}
				onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"unit",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/> 
		</Col>


		<Col xs={1} className={classes.iCol}>
			<Input
				className={[props.className,classes.customInput].join(" ")}
      
	      defaultValue={formElement.config.vat}
				onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"vat",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/> 
		</Col>

		<Col xs={1} className={classes.iCol}>
			<Input
				className={[props.className,classes.customInput].join(" ")}
      
	      defaultValue={formElement.config.discount}
				onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"discount",e.target.value)}
    	  invalid={!formElement.config.valid && formElement.config.touched}
      	disabled={formElement.config.disabled}
				onBlur={props.onBlur}
			/> 

		</Col>

		<Col xs={1} className={classes.iCol}>
			<Input className={[props.className,classes.customInput].join(" ")}
				value={round(formElement.config.math.total)}
				readOnly={true}
			/>
		</Col>


		<Col xs={1}>
      <div className={classes.trashBtn}>
				<ContentTypeIcon
					contentType={EContentType.DELETE}
					onClick={()=>{formElement.config.onDelete(formElement.config.index);if(props.onBlur) {props.onBlur()}}}/>

			</div>
			{/*onClick={()=>{formElement.config.onDelete(formElement.config.index);if(props.onBlur) {props.onBlur()}}}/>*/}
		</Col>

  </Row>
  <Row>
    <Col>
				<Input
					style={{"resize":"none"}}
					type="textarea"
					rows={formElement.config.description.split("\n").length}
					className={[props.className,classes.customInput].join(" ")}

					maxLength={1000}
      	
	    	  defaultValue={formElement.config.description}
					onChange={(e:any)=>formElement.config.onChange(formElement.config.index,"description",e.target.value)}
    		  invalid={!formElement.config.valid && formElement.config.touched}
      		disabled={formElement.config.disabled}
					onBlur={props.onBlur}
				/>
    </Col>
  </Row>
  </>)
}
 
export default CustomInput;

