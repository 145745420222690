export const getOrganizationControls = (): any => {
  return {
    name: {
      elementType: "input",
      elementConfig: {
        label: "Name",
        placeholder: "Name",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    businessID: {
      elementType: "input",
      elementConfig: {
        label: "Business ID",
        placeholder: "Business ID",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    streetAddress: {
      elementType: "input",
      elementConfig: {
        label: "Street Address",
        placeholder: "Street Address",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    zip: {
      elementType: "input",
      elementConfig: {
        label: "Zip",
        placeholder: "Zip",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    city: {
      elementType: "input",
      elementConfig: {
        label: "City",
        placeholder: "City",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    country: {
      elementType: "input",
      elementConfig: {
        label: "Country",
        placeholder: "Country",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    contactPerson: {
      elementType: "input",
      elementConfig: {
        label: "Contact person",
        placeholder: "Contact person",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    contactTitle: {
      elementType: "input",
      elementConfig: {
        label: "Contact title",
        placeholder: "Contact title",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    contactPhone: {
      elementType: "input",
      elementConfig: {
        label: "Contact phone",
        placeholder: "Contact phone",
        type: "text"
      },
      valid: true,
      touched: false,
      value: ""
    },
    contactEmail: {
      elementType: "input",
      elementConfig: {
        label: "Contact email",
        placeholder: "Contact email",
        type: "email"
      },
      validation: {
        emailOrEmpty: true
      },
      valid: true,
      touched: false,
      value: ""
    }
/*
    offerCounter: {
      elementType: "input",
      elementConfig: {
        label: "Proposal counter",
        placeholder: "Proposal number",
        type: "number"
      },
      valid: true,
      touched: false,
      value: ""
    }
*/
  };
};
