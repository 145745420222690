import { IUserGroupAction } from '../../interfaces/state';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';
import { IUserGroupState } from './../../interfaces/state';

const initialState:IUserGroupState = {
    userGroups: null,
    userGroup: null,
    loading: false,
    error: null,
};

const findUserGroupsStart = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, { error: null, loading: true});
}

const findUserGroupsSuccess = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, {
        userGroups: action.userGroups,
        error: null,
        loading: false,
        userGroup: null
    });
}

const findUserGroupsFail = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getUserGroupStart = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, { error: null, loading: true});
}

const getUserGroupSuccess = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, {
        userGroup: action.userGroup,
        error: null,
        loading: false
    });
}

const getUserGroupFail = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};


const saveUserGroupStart = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, { error: null, loading: true});
}

const saveUserGroupSuccess = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const saveUserGroupFail = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateUserGroupStart = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, { error: null, loading: true});
}

const updateUserGroupSuccess = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const updateUserGroupFail = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const deleteUserGroupStart = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
  return updateObject(state, { error: null, loading: true });
}

const deleteUserGroupSuccess = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
  return updateObject(state, {
      error: null,
      loading: false,
      userGroup: null
  });
}

const deleteUserGroupFail = (state:IUserGroupState, action:IUserGroupAction):IUserGroupState => {
  return updateObject(state, {
      error: action.error,
      loading: false
  });
};


const reducer = (state:IUserGroupState = initialState, action:IUserGroupAction):IUserGroupState => {
    
    switch (action.type) {
        case ActionTypes.FIND_USER_GROUPS_START : return findUserGroupsStart(state, action);
        case ActionTypes.FIND_USER_GROUPS_SUCCESS : return findUserGroupsSuccess(state, action);
        case ActionTypes.FIND_USER_GROUPS_FAIL : return findUserGroupsFail(state, action);
        case ActionTypes.GET_USER_GROUP_START : return getUserGroupStart(state, action);
        case ActionTypes.GET_USER_GROUP_SUCCESS : return getUserGroupSuccess(state, action);
        case ActionTypes.GET_USER_GROUP_FAIL : return getUserGroupFail(state, action);
        case ActionTypes.SAVE_USER_GROUP_START : return saveUserGroupStart(state, action);
        case ActionTypes.SAVE_USER_GROUP_SUCCESS : return saveUserGroupSuccess(state, action);
        case ActionTypes.SAVE_USER_GROUP_FAIL : return saveUserGroupFail(state, action);
        case ActionTypes.UPDATE_USER_GROUP_START : return updateUserGroupStart(state, action);
        case ActionTypes.UPDATE_USER_GROUP_SUCCESS : return updateUserGroupSuccess(state, action);
        case ActionTypes.UPDATE_USER_GROUP_FAIL : return updateUserGroupFail(state, action);
        case ActionTypes.DELETE_USER_GROUP_START : return deleteUserGroupStart(state, action);
        case ActionTypes.DELETE_USER_GROUP_SUCCESS : return deleteUserGroupSuccess(state, action);
        case ActionTypes.DELETE_USER_GROUP_FAIL : return deleteUserGroupFail(state, action);
        default: return state;
    }
}

export default reducer;