import React, { useEffect, useRef, useState } from "react";
import CustomDialog from "../../../../../../components/UI/CustomDialog/CustomDialog";
import { EContentType } from "../../../../../../enums/content-types";
import { IBlockContent } from "../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData,
  initForm
} from "../../../../../../shared/utility";
import ContentTypeItem from "./ContentTypeItem/ContentTypeItem";
import ContentTypeItemContent from "./ContentTypeItemContent/ContentTypeItemContent";
import ContentTypeItemCustom from "./ContentTypeItemCustom/ContentTypeItemCustom";
import ContentTypeItemImage from "./ContentTypeItemImage/ContentTypeItemImage";
import ContentTypeItemVisual from "./ContentTypeItemVisual/ContentTypeItemVisual";
import ContentTypeItemAttachment from "./ContentTypeItemAttachment/ContentTypeItemAttachment";

import ContentTypeFields from "./ContentTypeFields/ContentTypeFields";
import ContentTypeProducts from "./ContentTypeProducts/ContentTypeProducts";
import ContentTypeProducts2 from "./ContentTypeProducts2/ContentTypeProducts2";
import ContentTypeProducts3 from "./Private/ContentTypeProducts3/ContentTypeProducts3";
import ContentTypeSignatures from "./ContentTypeSignatures/ContentTypeSignatures";

import ContentTypeHTML from "./ContentTypeHTML/ContentTypeHTML";
import ContentTypeTAG from "./ContentTypeTAG/ContentTypeTAG";

import { EUserTemplateBlockType } from "../../../../../../enums";

import axios from '../../../../../../axios';

import classes from "./ContentTypeDialog.module.scss";

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (data: IContentTypeDialogData) => void;
  onDelete?: (id: string) => void;
  blockContent: IBlockContent;
  blockType: EUserTemplateBlockType;
}

export interface IContentTypeDialogData {
  id: string;
  name: string;
  contentType: EContentType;
}

export const initControls = {
  controls: {
    name: {
      elementType: "input",
      elementConfig: {
        label: "Description (optional)",
        placeholder: "Description",
        type: "text"
      },
      validation: {
        required: false // true
      },
      valid: true, //false,
      touched: false,
      value: ""
    }
  },
  formIsValid: true //false
};

export interface IContentTypeItemRef {
  getData: () => Object;
}

const ContentTypeDialog: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  onSave,
  onDelete,
  blockContent,
  blockType
}) => {
  const contentTypeItemRef = useRef<IContentTypeItemRef>();

  const [contentType, setContentType] = useState<EContentType>(null);
  const [controlsState, setControlsState] = useState(initControls);
  const [itemIsValid, setItemIsValid] = useState(false);
  
	useEffect(() => {

    let newControlsState = initControls;
    let newContentType = null;
    if (blockContent) {
      newControlsState = initForm(controlsState.controls, blockContent);
      newContentType = blockContent.contentType;

      switch (blockContent.contentType) {
        case EContentType.PRICING:
        case EContentType.DEAL_BETWEEN:
        case EContentType.PARTIES:
        case EContentType.TEAM_MEMBER:
        case EContentType.LEGAL_BOX:
        case EContentType.SIGNATURE:
          newContentType = EContentType.CUSTOM;
          break;
        default:
      }
    }
    setControlsState(newControlsState);
    setContentType(newContentType);


    // eslint-disable-next-line
  }, [blockContent]);

  const closeDialog = () => {
    // setControlsState(initControls);
    // setContentType(null);
    setIsOpen(false);
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) =>
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );

  const onOk = () => {
    const contentTypeItemData = contentTypeItemRef.current
      ? contentTypeItemRef.current.getData()
      : {};

    const data = Object.assign(
      blockContent || {},
      getFormData(controlsState.controls),
      { contentType },
      contentTypeItemData
    );
    onSave(data);
    closeDialog();
  };
/*
  useEffect(()=>{
    console.log("check private elemets");

    (async () => {
      const response = await axios.get(`/json/usertemplates/private`);
      console.log("**",response);
    })();

    axios.get(`/json/usertemplates/private`);

  },[]);
*/
  const contentTypes = [
    { id: EContentType.CONTENT, text: "Text" },
    { id: EContentType.IMAGE, text: "Image" },
    { id: EContentType.VISUAL, text: "Video/PDF" },
    { id: EContentType.ATTACHMENT, text: "Attach PDFs" },
    { id: EContentType.CUSTOM, text: "Special" },

    { id: EContentType.FIELDS, text: "Fields" },
    { id: EContentType.PRODUCTS, text: "Pricing Table" },
    
    // PRIVATE
    //{ id: EContentType.PRIVATE, text: "Netvisor" },
    //{ id: EContentType.PRIVATE2, text: "PHM" },
    
    { id: EContentType.SIGNATURES, text: "Signatures" },

    { id: EContentType.HTML, text: "HTML" },

    //{ id: EContentType.TAG, text: "TAG" },

    //{ id: EContentType.GOOGLESLIDES, text: "Google Slides" },

  ];

  const onDeleteContentType = () => {
    onDelete(blockContent.id);
    setIsOpen(false);
  };

  return (
    <CustomDialog
      loading={false}
      onOk={onOk}
      onCancel={closeDialog}
      onDelete={blockContent && onDeleteContentType}
      open={isOpen}
      title="Add new content type"
      isValid={controlsState.formIsValid && itemIsValid && contentType !== null}
    >
      {controlsToFormGroups(controlsState.controls, inputChangedHandler)}
      <div className={classes.ContentTypeContainer}>
        <label>Select content type</label>
        <div>
          {contentTypes.map(item => (
            <ContentTypeItem
              key={item.id}
              item={item}
              active={contentType === item.id}
              onClick={item => setContentType(item.id)}
            />
          ))}
        </div>
      </div>
      {(() => {
        switch (contentType) {
          case EContentType.CONTENT:
            return (
              <ContentTypeItemContent
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.IMAGE:
            return (
              <ContentTypeItemImage
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.VISUAL:
            return (
              <ContentTypeItemVisual
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.ATTACHMENT:
            return (
              <ContentTypeItemAttachment
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.CUSTOM:
            return (
              <ContentTypeItemCustom
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
                blockType={blockType}
              />
            );

          case EContentType.FIELDS:
            return (
              <ContentTypeFields
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          case EContentType.PRODUCTS:
            return (
              <ContentTypeProducts
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );
          case EContentType.PRIVATE:
            return (
              <ContentTypeProducts2
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          case EContentType.PRIVATE2:
            return (
              <ContentTypeProducts3
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          case EContentType.SIGNATURES:
            return (
              <ContentTypeSignatures
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          case EContentType.HTML:
            return (
              <ContentTypeHTML
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          case EContentType.TAG:
            return (
              <ContentTypeTAG
                ref={contentTypeItemRef}
                blockContent={blockContent}
                setIsValid={setItemIsValid}
              />
            );

          default:
            return null;
        }
      })()}
    </CustomDialog>
  );
};

export default ContentTypeDialog;
