import React from "react";
import { Button } from "reactstrap";
import { IAttachment, IBlockContent, IBlockContentAttachment } from "../../../../../../../interfaces";

import classes from "./BlockContentAttachmentShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent: boolean;
}

const dummyAttachment = {
  id: '',
  link: '',
  clientName: 'Example file',
  contentType: '',
  serverName: '',
  size: 0,
  hash: ''
}

const BlockContentAttachmentShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentAttachment;
  const showDummyContent = props.showDummyContent;
  let attachments = blockContent.attachments;
  if(showDummyContent && (!blockContent.attachments || !blockContent.attachments.length)) {
    attachments = [dummyAttachment];
  }

  const onOpen = (
    attachment: IAttachment
	) => {
    window.open(attachment.link, "_blank");
  };

  const renderAttachments = (attachments: Array<IAttachment>) => {
    if (!attachments) return null;
    return attachments.map(attachment => (
      <div key={attachment.id} className={classes.Item}>
        <Button color="link"  onClick={event => onOpen(attachment)}>
          {attachment.clientName}
        </Button>
      </div>
    ));
  };

  return (
    <div className={classes.Container}>
      {renderAttachments(attachments)}
    </div>
  );
};

export default BlockContentAttachmentShow;
