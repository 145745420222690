import React from "react";
import { IOffer, IUserGroup, IUser } from "../../../../../../interfaces";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faClock,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { EOfferStatus } from "../../../../../../enums";
import { hasOfferStatus } from "../../../../../../shared/offer-utils";
import classes from "./OfferCard.module.scss";

interface IProps {
  offer: IOffer;
  userGroups: Array<IUserGroup>,
  isExpiring?: boolean;
  onClick: () => void;
  currentUser: IUser;
}

const OfferCard: React.FC<IProps> = ({ offer, isExpiring, onClick, userGroups, currentUser }) => {
  const borderColor = () => {
    const { status } = offer;
    if (isExpiring) {
      return "#FCADAD";
    } else {
      switch (status) {
        case EOfferStatus.SELFSENT:
          return "#FF0000";
        case EOfferStatus.SENT:
          return "#FFE6B1";
        case EOfferStatus.OPENED:
        case EOfferStatus.DECLINED:
          return "#6FCF97";
        case EOfferStatus.DRAFT:
          return "#E0E0E0";
        default:
          return "#E0E0E0";
      }
    }
  };

  const { customerName, name, expirationDate, status, viewsCount } = offer;
  // TODO: Replace this with error information from offer
  const error = false;
  
  return (
    <Card
      style={{ borderTop: `4px solid ${borderColor()}` }}
      className={`${classes.OfferCard} ${error && classes.Error}`}
      onClick={() => onClick()}
    >
      <CardBody className={classes.CardBody}>
        <CardTitle className={classes.Title}>
          <span className={classes.CustomerName}>
            {customerName}<br/>
            <small>
              {offer.authorName && offer.authorName !== currentUser.name && <>
                {offer.authorName}<br/></>}

            {/*offer.userTemplate && <>
              {offer.userTemplate.name}
            </>*/}
            </small>
          </span>
          
          {error && (
            <span className={classes.ErrorIcon}>
              <FontAwesomeIcon color="yellow" icon={faExclamationTriangle} />
              Error
            </span>
          )}
        </CardTitle>
        <CardSubtitle className={classes.OfferName}>{name}</CardSubtitle>
        <div className={classes.Details}>
          {expirationDate && (
            <span className={classes.ExpirationDate}>
              <FontAwesomeIcon color="gray" icon={faClock} />
              {offer.expirationDate}
            </span>
          )}

          {hasOfferStatus(
            status,
            EOfferStatus.OPENED,
            EOfferStatus.DECLINED
          ) && (
            <span className={classes.Views}>
              {viewsCount > 0 && viewsCount} <FontAwesomeIcon color="gray" icon={faEye} />
            </span>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default OfferCard;
