import React, { ReactNode, CSSProperties } from "react";

import classes from "./Content.module.scss";

interface IProps {
  children: ReactNode;
  style?: CSSProperties;
}

const Content: React.FC<IProps> = ({ children, style }) => {
  return (
    <div className={classes.Container} style={{ ...style }}>
      {children}
    </div>
  );
};

export default Content;
