import React, { useEffect, useState } from 'react';
import SidebarCollapse from '../../../../../../../../../components/UI/SidebarCollapse/SidebarCollapse';
import { IUserTemplateBlockDraggable } from '../../../../../../../../../interfaces';
import { controlsToFormGroups, defaultInputChangedHandler, getFormData, initForm } from '../../../../../../../../../shared/utility';


interface IProps {
  block: IUserTemplateBlockDraggable;
  update: (data:Object) => void;
}


const initControls = {
  controls: {
    name: {
      elementType: "input",
      elementConfig: {
        label: "Element title",
        placeholder: "Element title",
        type: "text"
      },
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      value: ""
    },
    showName: {
      elementType: "checkbox",
      elementConfig: {
        options: [
          {value: true, displayValue: 'Show element title'}
        ]
      },
      valid: true,
      touched: false,
      value: ""
    },
    editableOnOffer: {
      elementType: "checkbox",
      elementConfig: {
        options: [
          {value: true, displayValue: 'Editable on proposal'}
        ]
      },
      valid: true,
      touched: false,
      value: ""
    },
    /*
    cssClassName: {
      elementType: "input",
      elementConfig: {
        label: "CSS class name",
        placeholder: "CSS class name",
        type: "text"
      },
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      value: ""
    },
    */
  },
  formIsValid: false
}

const TemplateSidebarEditBlockDetails:React.FC<IProps> = ({ block, update}) => {
  
  const [controlsState, setControlsState] = useState(initControls);

  useEffect(() => {
    setControlsState(initForm(initControls.controls, {...block, ...block.styles}));
    // eslint-disable-next-line
  }, [block.uuid, block.name])

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {		
    const validation = defaultInputChangedHandler(event, controlName, controlsState, setControlsState);
    update(getFormData(validation.controls));
  };
	
	return (
    <SidebarCollapse title="Element details">
      {controlsToFormGroups(controlsState.controls, inputChangedHandler)}
    </SidebarCollapse>
  )
};


export default TemplateSidebarEditBlockDetails;
