import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { IContentTypeItemType } from "../../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData,
  initForm
} from "../../../../../../../shared/utility";
import { EContentType, EUserTemplateBlockType, ESigner } from "../../../../../../../enums";

interface IProps extends IContentTypeItemType {
  blockType: EUserTemplateBlockType;
	disableTypeChange?: boolean;
}

const simpleContentTypes = [
  { id: EContentType.PRICING, label: "Pricing fields (€)" },
  { id: EContentType.DEAL_BETWEEN, label: "Parties" },
  { id: EContentType.PARTIES, label: "Parties (Editable)" },
  { id: EContentType.TEAM_MEMBER, label: "Team member" },
  { id: EContentType.LEGAL_BOX, label: "E-signing details" },
  { id: EContentType.SIGNATURE, label: "Signature" },
];

const dynamicContentTypes = [
  { id: EContentType.PRICING, label: "Pricing Fields (€)" },
  { id: EContentType.TEAM_MEMBER, label: "Team member" },
];

const signers = [
  { id: ESigner.AUTHOR, label: "Author" },
  { id: ESigner.CONTACT_PERSON, label: "Contact person" },
]

const ContentTypeItemCustom: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ blockContent, setIsValid, blockType, disableTypeChange }, ref) => {

  let contentTypes = simpleContentTypes;
  if(blockType === EUserTemplateBlockType.DYNAMIC) contentTypes = dynamicContentTypes;

  const [controlsState, setControlsState] = useState({
    controls: {
      contentType: {
        elementType: "select",
        elementConfig: {
          label: "Type"
        },
        valid: false,
        validation: {
          required: true
        },
        touched: false,
        value: "",
        options: contentTypes
      }
    },
    formIsValid: false
  });

  const [signerControlsState, setSignerControlsState] = useState({
    controls: {
      signer: {
        elementType: "select",
        elementConfig: {
          label: "Signer"
        },
        valid: false,
        validation: {
          required: true
        },
        touched: false,
        value: "",
        options: signers
      }
    },
    formIsValid: false
  });

  const [signerTitleControlsState, setSignerTitleControlsState] = useState({
    controls: {
      signerTitle: {
        elementType: "input",
        elementConfig: {
          label: "Signer title",
          placeholder: "Signer title",
          type: "text"
        },
        valid: true,
        touched: false,
        value: ""
      }
    },
    formIsValid: false
  });

  useEffect(() => {
    if (blockContent) {
      const newControlsState = initForm(controlsState.controls, blockContent);
      setControlsState(newControlsState);

      if (blockContent.contentType === EContentType.SIGNATURE) {
        setSignerControlsState(initForm(signerControlsState.controls, blockContent));
        setSignerTitleControlsState(initForm(signerTitleControlsState.controls, blockContent));
      }
    }
    // eslint-disable-next-line
  }, [blockContent]);

  useEffect(() => {
    if (controlsState.controls.contentType.value === EContentType.SIGNATURE) {
      setIsValid(controlsState.formIsValid && signerControlsState.formIsValid);
    } else {
      setIsValid(controlsState.formIsValid);
    }
  }, [controlsState.formIsValid, setIsValid, controlsState.controls.contentType.value, signerControlsState.formIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      if(controlsState.controls.contentType.value === EContentType.SIGNATURE) {
        const controls = Object.assign({}, {
          ...controlsState.controls,
          ...signerControlsState.controls,
          ...signerTitleControlsState.controls
        });
        return Object.assign({}, blockContent, getFormData(controls));
      }
      return getFormData(controlsState.controls);
    }
  }));

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );
  };

  const signerInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      signerControlsState,
      setSignerControlsState
    );
  };

  const signerTitleInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      signerTitleControlsState,
      setSignerTitleControlsState
    );
  };
	
	if (controlsState.controls.contentType.value === EContentType.SIGNATURE) {
    return (
      <React.Fragment>
				{!disableTypeChange &&
	        controlsToFormGroups(controlsState.controls, inputChangedHandler)}
	
        {controlsToFormGroups(signerControlsState.controls, signerInputChangedHandler)}
        {signerControlsState.controls.signer.value === ESigner.AUTHOR && (
          controlsToFormGroups(signerTitleControlsState.controls, signerTitleInputChangedHandler)
        )}
      </React.Fragment>
    )
  }
	if(disableTypeChange) { return <></> }
	else {
	  return controlsToFormGroups(controlsState.controls, inputChangedHandler);
	}
});

export default ContentTypeItemCustom;
