import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import CustomButtonGroup from "../../../../../../../components/UI/CustomButtonGroup/CustomButtonGroup";
import { EContentType, EDropzoneType, EVideoSource, EVisualType } from "../../../../../../../enums";
import { IBlockContentVisual } from "../../../../../../../interfaces";
import { controlsToFormGroups, defaultInputChangedHandler, getFormData } from "../../../../../../../shared/utility";
import { IBlockContentEditItem } from "../../BlockContentEdit";


const BlockContentVisualEdit: React.ForwardRefExoticComponent<IBlockContentEditItem> = forwardRef(
  (props, ref) => {
    const [init, setInit] = useState(false);
    const blockContent = props.blockContent as IBlockContentVisual;
    const [visualType, setVisualType] = useState(
      blockContent.visualType || EVisualType.IMAGE
    );
    const [videoSource, setVideoSource] = useState(
      blockContent.videoSource || EVideoSource.YOUTUBE
    );

    const [imageControls, setImageControls] = useState({
      controls: {
        images: {
          elementType: "dropzone",
          elementConfig: {
            label: "Image",
            accept: EDropzoneType.IMG,
            text: "Drop image here or click to upload.",
            dragText: "Drop image here.",
            contentType: EContentType.IMAGE,
            config: {
              multiple: true
            }
          },
          valid: true,
          touched: false,
          value: blockContent.images || []
        }
      },
      controlsIsValid: false
    });

    const [pdfControls, setPdfControls] = useState({
      controls: {
        pdfs: {
          elementType: "dropzone",
          elementConfig: {
            label: "Pdf",
            accept: EDropzoneType.PDF,
            text: "Drop pdf here or click to upload.",
            dragText: "Drop pdf here.",
            contentType: EContentType.PDF,
            config: {
              multiple: true
            }
          },
          valid: true,
          touched: false,
          value: blockContent.pdfs || []
        },
        firstPage: {
          elementType:"input",
          elementConfig:{
            label: "First Page",
            placeholder: "Page number",
            type:"number",
          },
          value:blockContent.firstPage,
          config:{},
        },
        lastPage: {
          elementType:"input",
          elementConfig:{
            label: "Last Page",
            placeholder: "Page number",
            type:"number",
          },
          value:blockContent.lastPage,
          config:{},
        },
        viewType: {
          elementType:"checkbox",
          elementConfig:{
            label: "Single Page Viewer",

            options: [
              {value:"on",displayValue:"Show multiple pages"},
            ],
          

          },
          value:blockContent.viewType,
          config:{},
        },

      },
      controlsIsValid: false
    });

    const [videoControls, setVideoControls] = useState({
      controls: {
        videoEmbed: {
          elementType: "input",
          elementConfig: {
            label: "Video id",
            placeholder: 'Video id (e.g. "22qaIwrjk4M", "14213714")',
            type: "text"
          },
          validation: {
            required: true
          },
          valid: false,
          touched: false,
          value: blockContent.videoEmbed
        }
      },
      controlsIsValid: false
    });

    const getData = () => {
      let data = Object.assign(blockContent, { visualType });

      switch (visualType) {
        case EVisualType.IMAGE:
          data = { ...data, ...getFormData(imageControls.controls) };
          break;
        case EVisualType.PDF:
          data = { ...data, ...getFormData(pdfControls.controls) };
          break;
        case EVisualType.DYNAMIC_PDF:
          data = { ...data, ...getFormData(pdfControls.controls) };
          break;
        case EVisualType.VIDEO:
          data = {
            ...data,
            ...getFormData(videoControls.controls),
            videoSource
          };
          break;
        default:
      }
      return data;
    };

    useEffect(() => {
      if (props.onChange && init) {
        props.onChange(getData());
      }
      // eslint-disable-next-line
    }, [imageControls.controls, pdfControls.controls, videoControls.controls]);

    useEffect(() => {
      setInit(true);
    }, []);

    useEffect(() => {
      if(props.onBlur && init) {
        props.onBlur(getData());
      }
    }, [visualType])

    useImperativeHandle(ref, () => ({
      getData
    }));

    const items = [
      {
        type: EVisualType.IMAGE,
        text: "Image"
      },
      {
        type: EVisualType.PDF,
        text: "PDF"
      },
      {
        type: EVisualType.VIDEO,
        text: "Video"
      },
      {
        type: EVisualType.DYNAMIC_PDF,
        text: "Dynamic PDF"
      }

    ];

    const videoSources = [
      {
        type: EVideoSource.YOUTUBE,
        text: "Youtube"
      },
      {
        type: EVideoSource.VIMEO,
        text: "Vimeo"
      }
    ];

    let blurHandler:any = null;
    if(props.onBlur) {
      blurHandler = () => {
        //props.onBlur(getData());
      }
    }

    const createFormGroups = (state: any, setState: any) => {
      return controlsToFormGroups(
        state.controls,
        (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
          {
            defaultInputChangedHandler(event, controlName, state, setState);
          },
        blurHandler
      );
    };


    return (
      <React.Fragment>
        <label>Visual type</label>
        <CustomButtonGroup
          items={items}
          clicked={setVisualType}
          selected={visualType}
          size="sm"
          style={{ display: "flex", marginBottom: "0.5rem" }}
        />
        {(() => {
          switch (visualType) {
            case EVisualType.IMAGE:
              return createFormGroups(imageControls, setImageControls);
            case EVisualType.PDF:
              return createFormGroups(pdfControls, setPdfControls);
            case EVisualType.DYNAMIC_PDF:
              return (<h4>Dynamic PDF is a special component. Only use with special customized elements.</h4>);
            case EVisualType.VIDEO:
              return (
                <React.Fragment>
                  <label>Video source</label>
                  <CustomButtonGroup
                    items={videoSources}
                    clicked={setVideoSource}
                    selected={videoSource}
                    size="sm"
                    style={{ display: "flex", marginBottom: "0.5rem" }}
                  />
                  {createFormGroups(videoControls, setVideoControls)}
                </React.Fragment>
              );
            default:
              return null;
          }
        })()}
      </React.Fragment>
    );
  }
);

export default BlockContentVisualEdit;
