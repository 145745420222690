import React from 'react';
import { IUserTemplate, ESignType, IOrganization } from '../../../interfaces';
import { EOrganizationLevel } from '../../../enums';
import { Input, Label } from 'reactstrap';

interface IProps {
	data: IUserTemplate;
	update: (data:Object) => void;
	edit?:boolean;
	organization:IOrganization;
}

const AcceptanceMethods:React.FC<IProps> = ({ data, update, edit, organization}) => {
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		update({"signType":e.target.value})
	}

	if(!data || !("settings" in data)) return null;

	const integrations = organization.integrations;
	
	return (<>
		{edit && <>
		<div>
			<Input type="radio" value={ESignType.NORMAL} checked={data.settings.signType===ESignType.NORMAL} onChange={onChange} id="signTypeNORMAL" name="customRadio" label="Single Factor (IP Address)" />

			{organization.level!==EOrganizationLevel.BASIC &&
			<Input type="radio" value={ESignType.SMS}    checked={data.settings.signType===ESignType.SMS   } onChange={onChange} id="signTypeSMS"    name="customRadio" label="Two Factor (IP Address + SMS code)" />}

			{integrations && integrations.vismaSign && integrations.vismaSign.active &&
			<Input type="radio" value={ESignType.VISMA}  checked={data.settings.signType===ESignType.VISMA } onChange={onChange} id="signTypeVISMA"  name="customRadio" label="Strong authentication (available in Finland, Sweden, Norway and Denmark)" />}
		</div>
		</>}

		{!edit && <>
			<Label>Proposal acceptance authentincation method</Label>

			{data.settings.signType===ESignType.NORMAL && <div>Single Factor (IP Address)</div>}
			{data.settings.signType===ESignType.SMS    && <div> Two Factor (IP Address + SMS code)</div>}
			{data.settings.signType===ESignType.VISMA  && <div>Strong authentication (available in Finland, Sweden, Norway and Denmark)</div>}
		</>}
	</>)
};


export default AcceptanceMethods;
