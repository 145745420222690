import React, { ReactNode } from "react";

import classes from "./ContentWithSidebarStatic.module.scss";

interface IProps {
  children: ReactNode;
  isVisible?: boolean;
  className?: string; 
}

const ContentWithSidebarStatic: React.FC<IProps> = ({
  children,
  isVisible = true,
  className
}) => {
  const classNames = [classes.Container];
  if(className) {
    classNames.push(className);
  }

  return (
    <div
      className={classNames.join(' ')}
      style={{ display: isVisible ? "grid" : "none" }}
    >
      {children}
    </div>
  );
};

export default ContentWithSidebarStatic;
