import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap';

interface IProps {
  uniqueId: string;
  script: string;
  onLoad?: () => void;
}

interface Result {
  success: boolean;
  error: string;
}

// https://medium.com/@ozluy/loading-stripe-script-if-necessary-189f228e0f34
const ScriptLoader: React.FC<IProps> = ({ children, uniqueId, script, onLoad }) => {
  const [loaded, setLoaded] = useState({
    success: false,
    error: null
  });
  useEffect(() => {
    const loadScript = (src: string, uniqueId: string) =>
      new Promise<Result>((resolve, reject) => {
        const scriptElement = document.getElementById(uniqueId);

        if (!scriptElement) {
          const script = document.createElement('script');
          script.src = src;
          script.id = uniqueId;

          if(onLoad) script.onload = onLoad;

          const handleLoadScriptSuccess = () => resolve({ success: true, error: null });
          const handleLoadScriptFail = (event: any) => reject({ success: false, error: event });

          script.addEventListener('load', handleLoadScriptSuccess, {
            once: true,
          })
          script.addEventListener('error', handleLoadScriptFail, { once: true });
          document.head.appendChild(script);
        } else {
          resolve({ success: true, error: null });
        }
      });

    const fetchData = async () => {
      const result = await loadScript(script, uniqueId);
      setLoaded(result);
    };

    fetchData();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return loaded.success ? (<React.Fragment>{children}</React.Fragment>) : <Spinner color="primary" />;
}

export default ScriptLoader;
