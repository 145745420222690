import { EBlockEditingStyle } from './../../Blocks/Block/Block';
import React from 'react';
import { IOrganization, IUser, IBlockItem, ICustomer, IOffer, IUserTemplate, IUserTemplateAreaBlock, IBlockContent } from './../../../../../interfaces/domain';
//import { EPageView } from './../../../../../enums';

const OfferContext = React.createContext<{
  setOffer: (offer:IOffer) => void;
  offer: IOffer;
  onDynamicBlockItemChanged: (blockId: string, blockItems: Array<IBlockItem>) => void;
  onSimpleContentChanged: (block: IUserTemplateAreaBlock, blockContent: IBlockContent) => void;
  blockItemsLoading: boolean;
  customers: Array<ICustomer>;
  customersLoading: boolean;
  userTemplates: Array<IUserTemplate>;
  userTemplatesLoading: boolean;
  onAddBlockItem: (id: string, blockId: string, editingStyle: EBlockEditingStyle) => void; 
  onEditBlockItem: (id: string, blockItem: IBlockItem) => void;
  customerHasChanged: boolean;
  setCustomerHasChanged: (customerHasChanged: boolean) => void;
  hideEditableContent: boolean;
  setHideEditableContent: (hideEditableContent: boolean) => void;
  onBlockItemChanged: (id: string, blockItem: IBlockItem) => void;
  blockLoading: string;

  onSearchCustomers: (queryString: string) => void;
  onGetCustomer: (id: string) => Promise<ICustomer>;

  currentUser: IUser;
  organization?: IOrganization;

}>({  
  setOffer: null,
  offer: null,
  onDynamicBlockItemChanged: null,
  onSimpleContentChanged: null,
  blockItemsLoading: false,
  customers: null,
  customersLoading: false,
  userTemplates: null,
  userTemplatesLoading: false,
  onAddBlockItem: null,
  onEditBlockItem: null,
  customerHasChanged: false,
  setCustomerHasChanged: null,
  hideEditableContent: false,
  setHideEditableContent: null,
  onBlockItemChanged: null,
  blockLoading: "",
  onSearchCustomers: null,
  onGetCustomer: null,
  currentUser: null,
  organization: null,
});

export default OfferContext;
