import React from "react";

import classes from "./Typography.module.scss";

interface IProps {
}

const Subtitle: React.FC<IProps> = ({children}) => {

  return (
    <p className={classes.Subtitle}>{children}</p>
  );
};

export default Subtitle;
