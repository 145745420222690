import React from 'react';
import { Table } from 'reactstrap';

import { IInvoice } from '../../../../../interfaces';
import { dateToString } from '../../../../../shared/utility';

import classes from "./InvoicesList.module.scss";

interface IProps {
  invoices: Array<IInvoice>;
}

const InvoicesList: React.FC<IProps> = ({ invoices }) => {
  if (!invoices || !invoices.length) {
    return null;
  }

  /* TODO: Fix browser popupblocker
  Failed to execute 'postMessage' on 'DOMWindow':
  The target origin provided ('https://js.stripe.com') 
  does not match the recipient window's origin ('http://localhost:3000').
  */
  const onRedirect = (invoice: IInvoice) => {
    if(invoice.invoice_pdf) {
      window.open(invoice.invoice_pdf, "_blank");
    }
  };

  return (
    <Table responsive borderless striped>
      <thead>
        <tr>
          <th>Receipt number</th>
          <th>Status</th>
          <th>Paid at</th>
          <th>Period start</th>
          <th>Period end</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice, i) => (
          <tr key={invoice.id || `${invoice.number}_${i}`} className={classes.TableRow} onClick={() => onRedirect(invoice)}>
            <td>{invoice.number}</td>
            <td>{invoice.status}</td>
            <td>{invoice.paid_at ? dateToString(new Date(invoice.paid_at)) : '-'}</td>
            <td>{dateToString(new Date(invoice.period_start))}</td>
            <td>{dateToString(new Date(invoice.period_end))}</td>
            <td>{invoice.total}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InvoicesList;
