import { Dispatch } from "redux";
import { ActionTypes } from "./actionTypes";
import { IInvoiceAction, IInvoice } from "../../interfaces";
import axios from "../../axios";

export const findInvoicesStart = (): IInvoiceAction => {
  return {
    type: ActionTypes.FIND_INVOICES_START
  };
};

export const findInvoicesSuccess = (invoices: Array<IInvoice>): IInvoiceAction => {
  return {
    type: ActionTypes.FIND_INVOICES_SUCCESS,
    invoices
  };
};

export const findInvoicesFail = (error: string): IInvoiceAction => {
  return {
    type: ActionTypes.FIND_INVOICES_FAIL,
    error
  };
};

export const findInvoices = () => {
  return async (dispatch: Dispatch) => {
    dispatch(findInvoicesStart());
    try {
      const res = await axios.get("/json/billing/invoices");
      const invoices = res.data;
      dispatch(findInvoicesSuccess(invoices));
      return invoices;
      //@ts-ignore
    } catch (error: any) {
      dispatch(findInvoicesFail(error.response.data));
      return null;
    }
  };
};

export const getUpcomingInvoiceStart = (): IInvoiceAction => {
  return {
    type: ActionTypes.GET_UPCOMING_INVOICE_START
  };
};

export const getUpcomingInvoiceSuccess = (upcomingInvoice: IInvoice): IInvoiceAction => {
  return {
    type: ActionTypes.GET_UPCOMING_INVOICE_SUCCESS,
    upcomingInvoice
  };
};

export const getUpcomingInvoiceFail = (error: string): IInvoiceAction => {
  return {
    type: ActionTypes.GET_UPCOMING_INVOICE_FAIL,
    error
  };
};

export const getUpcomingInvoice = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getUpcomingInvoiceStart());
    try {
      const res = await axios.get("/json/billing/upcominginvoice");
      const invoice = res.data;
      dispatch(getUpcomingInvoiceSuccess(invoice));
      return invoice;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getUpcomingInvoiceFail(error.response.data));
      return null;
    }
  };
};

export const getInvoiceStart = (): IInvoiceAction => {
  return {
    type: ActionTypes.GET_INVOICE_START
  };
};

export const getInvoiceSuccess = (invoice: IInvoice): IInvoiceAction => {
  return {
    type: ActionTypes.GET_INVOICE_SUCCESS,
    invoice
  };
};

export const getInvoiceFail = (error: string): IInvoiceAction => {
  return {
    type: ActionTypes.GET_INVOICE_FAIL,
    error
  };
};

export const getInvoice = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getInvoiceStart());
    try {
      const res = await axios.get(`/json/billing/invoices/get?id=${id}`);
      const invoice = res.data;
      dispatch(getInvoiceSuccess(invoice));
      return invoice;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getInvoiceFail(error.response.data));
      return null;
    }
  };
};
