import React, { useContext } from "react";
import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";
import { IBlockContent, IBlockContentParties, IOrganization, ICustomer } from "../../../../../../../interfaces";

import classes from "./BlockContentPartiesShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent: boolean;
}

const BlockContentPartiesShow: React.FC<IProps> = ({
  blockContent, showDummyContent
}) => {
  const { customer, organization } = useContext(OfferPreviewContext);
  const content = blockContent as IBlockContentParties;
  
  const renderOrganization = (organization: IOrganization) => {
    if (!organization) return null;

    return (
      <div>
        <p className={classes.Name}>
          {content.leftName}
        </p>
        <p className={classes.ar}>{content.leftId}</p>
        <p className={classes.ar}>
          <span style={{"whiteSpace":"nowrap"}}>{content.leftAddress}</span>
        </p>
        <p className={classes.ar}>
          {content.leftContact}
        </p>
        <p className={classes.ar}>{content.leftPhone}</p>
        <p className={classes.ar}>{content.leftEmail}</p>
      </div>
    );
  };

  const renderCustomer = (customer: ICustomer) => {
    if (!customer) return null;

    const pa2=((customer.zip || "")+" "+(customer.city || "")).trim()

    return (
      <div>
        <p className={classes.Name}>
          {customer.name}
        </p>
        <p className={classes.ar}>{customer.businessID}</p>
        <p className={classes.ar}>
          {customer.address}{pa2?",":""} <span style={{"whiteSpace":"nowrap"}}>{pa2}</span>
        </p>
        <p className={classes.ar}>{customer.contactPerson}</p>
        <p className={classes.ar}>{customer.phone}</p>
        <p className={classes.ar}>{customer.email}</p>
      </div>
    );
  };

  return (
    <div className={classes.Container}>
      <div className={classes.Organization}>
        {renderOrganization(organization)}
      </div>
      <div className={[classes.Divider, "customColor-bg"].join(" ")}></div>
      <div className={classes.Customer}>{renderCustomer(customer)}</div>
    </div>
  );
};

export default BlockContentPartiesShow;
