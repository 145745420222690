import React, { useRef, useState } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../../../../withRouter';
import { useParams } from "react-router-dom";

import FooterFixed from "../../../../../../../components/UI/FooterFixed/FooterFixed";
import FormFooter from "../../../../../../../components/UI/FormFooter/FormFooter";
import { IAppState, IBlock, IBlockContent, IBlockItem } from "../../../../../../../interfaces";
import * as actions from "../../../../../../../store/actions";
import BlockItemEdit from "../../../../../components/Blocks/Block/BlockItems/BlockItemEdit/BlockItemEdit";
import CustomDialog from "../../../../../../../components/UI/CustomDialog/CustomDialog";

interface IStateProps {
  block: IBlock;
  blockItem: IBlockItem;
  loading: boolean;
  error: string;
}

interface IDispatchProps {
  onGetBlock: (id: string) => Promise<IBlock>;
  onGetBlockItem: (id: string) => void;
  onSaveBlockItem: (blockId: string, blockItem: IBlockItem) => Promise<boolean>;
  onUpdateBlockItem: (
    blockId: string,
    blockItemId: string,
    blockItem: IBlockItem
  ) => Promise<boolean>;
  onDeleteBlockItem: (blockItemId: string) => Promise<boolean>;
}

interface IMatchParams {
  blockId: string;
  blockItemId: string;
}

interface IProps
  extends RouteComponentProps, //<IMatchParams>,
    IStateProps,
    IDispatchProps {}

interface IDialog {
  open: boolean;
  onOk?: () => void;
}

export interface IBlockItemContentRef {
  getData: () => IBlockContent;
}

const BlockItem: React.FC<IProps> = ({
  history,
  match,
  onSaveBlockItem,
  onUpdateBlockItem,
  onGetBlock,
  onGetBlockItem,
  block,
  blockItem,
  loading,
  error,
  onDeleteBlockItem
}) => {
  const params = useParams();
  //const { blockId, blockItemId } = match.params;
  const blockId = params.blockId;
  const blockItemId = params.blockItemId;

  const contentRef = useRef(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const onSubmit = async () => {
    const newBlockItem = contentRef.current.getData();

    let success = false;
    if (blockItemId === "add") {
      success = await onSaveBlockItem(blockId, newBlockItem);
    } else {
      success = await onUpdateBlockItem(blockId, blockItemId, newBlockItem);
    }

    if (success) {
      history.goBack();
    }
  };

  const onDiscard = () => {
    history.goBack();
  };

  const deleteBlockItem = async () => {
    await onDeleteBlockItem(blockItemId);
    history.push(`/blocks/${blockId}/items`);
  };

  let submitText = null;
  if (blockItem) {
    submitText = "Update multi item element";
  } else {
    submitText = "Save multi item element";
  }

  const [isValid, setIsValid] = useState(false);

  return (
    <React.Fragment>
      <CustomDialog
        loading={false}
        onOk={deleteBlockItem}
        okButtonText={"OK"}
        onCancel={() => setDeleteDialogOpen(false)}
        open={deleteDialogOpen}
        title={"Are you sure?"}
        isValid={true}
      >
        Deleting the multi item element does not remove it from existing Contracts.
      </CustomDialog>
      
      <BlockItemEdit blockId={blockId} blockItemId={blockItemId} ref={contentRef} setIsValid={setIsValid} />
      <FooterFixed>
        <FormFooter
          saveText={submitText}
          isValid={isValid}
          onSave={onSubmit}
          onDiscard={onDiscard}
          loading={loading}
          deleteText="Delete element"
          onDelete={blockItemId !== "add" ? () => setDeleteDialogOpen(true) : null}
        />
      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.blocks.loading || state.blocks.blockItemLoading,
    error: state.blocks.error || state.blocks.blockItemError,
    block: state.blocks.block,
    blockItem: state.blocks.blockItem
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetBlock: id => dispatch(actions.getBlock(id)),
    onGetBlockItem: (id: string) => dispatch(actions.getBlockItem(id)),
    onSaveBlockItem: (blockId, blockItem) =>
      dispatch(actions.saveBlockItem(blockId, blockItem)),
    onUpdateBlockItem: (blockId, blockItemId, blockItem) =>
      dispatch(actions.updateBlockItem(blockId, blockItemId, blockItem)),
    onDeleteBlockItem: (blockItemId) => dispatch(actions.deleteBlockItem(blockItemId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockItem);
