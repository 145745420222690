import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { Button } from 'reactstrap';

import { EPageView, ESearchType } from '../../enums';
import CustomButtonGroup from '../UI/CustomButtonGroup/CustomButtonGroup';
import Search from '../UI/Search/Search';

import classes from "./ListHeader.module.scss";

interface IProps {
  title: string;
  onCreate?: { text: string; clicked: () => void };
  onSearch?: {
    type: ESearchType;
    searchFunc: (queryString: string) => void;
    clicked: (id: string) => void
  };
  view?: {
    currentView: EPageView;
    clicked: (type: EPageView) => void;
    views: Array<{ text: string; type: EPageView; icon: IconProp }>;
  };
  buttons?: Array<{ clicked: () => void; text: string; color: string }>;
  loading?: boolean;

  onSearch2?: {
    searchFunc: (queryString: string) => void;
  }
}

const ListHeader: React.FC<IProps> = ({
  title,
  onCreate,
  onSearch,
  view,
  buttons,
  loading,
  onSearch2
}) => {
  return (
    <div className={classes.Container}>
      <div className={classes.Left}>
        <h4 className={classes.Title}>{title}</h4>
        {onCreate && (
          <div>
            <Button className={classes.CreateButton} color="primary" onClick={onCreate.clicked} disabled={loading}>
              {onCreate.text}
            </Button>
          </div>
        )}
        {onSearch && <Search
          type={onSearch.type}
          onChange={onSearch.searchFunc}
          onClick={onSearch.clicked}
        />}
        {onSearch2 && 
           <input type="text" onChange={(e:any)=>{onSearch2.searchFunc(e.target.value)}} placeholder="Search"/>}

      </div>

      <div className={classes.Right}>
        {view && (
          <React.Fragment>
            <p className={classes.ViewsLabel}>VIEW</p>
            <CustomButtonGroup 
              items={view.views}
              selected={view.currentView}
              clicked={view.clicked}
            />
          </React.Fragment>
        )}
        {buttons && (
          <div className={classes.Buttons}>
            {buttons.map(button => (
              <Button
                key={button.text}
                color={button.color}
                onClick={button.clicked}
              >
                {button.text}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListHeader;
