import React, { ReactNode } from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import classes from "./CustomDropdown.module.scss";

interface IProps {
  children: ReactNode;
}

const CustomDropdown: React.FC<IProps> = ({ children }) => {

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle className={classes.Button} color="link" />
      <DropdownMenu className={classes.Menu}>
        {children}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default CustomDropdown;
