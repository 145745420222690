import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

import classes from "./ExpirationDate.module.scss";
import inputClasses from "./../../../components/UI/Input/Input.module.scss";

interface IProps {
  expirationDate: string;
  isExpiring: boolean;
  isExpired: boolean;
  lang?:string;
}

const ExpirationDate: React.FC<IProps> = ({ expirationDate, isExpiring, isExpired, lang="fi" }) => {
  const { t } = useTranslation();

  let expirationClassName = '';
  let expirationStatus = null;
  if (isExpired) {
    expirationClassName = classes.Expired;
    expirationStatus = "Expired";
  } else if(isExpiring) {
    expirationClassName = classes.Expiring;
    expirationStatus = "Expiring"
  }

  const localizeDate = (d: string) => {
    if(d && (lang === "en") ) {
      const s=d.split(".");
      const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];

      return s[0]+" "+monthNames[parseInt(s[1])-1]+" "+s[2];
    } else {
      return d;
    }
  }

  return (
    <div>
      <label className={inputClasses.Label}>{t('expirationDate.title')}</label>
      <div>
        <span className={[classes.Icon, expirationClassName].join(' ')}>
          <FontAwesomeIcon color="danger" icon={faClock} />
        </span>
        <span className={classes.ExpirationDate}>{localizeDate(expirationDate)}</span>
        <span className={[classes.ExpirationStatus, expirationClassName].join(' ')}>{expirationStatus}</span>
      </div>
    </div>
  );
};

export default ExpirationDate;
