import { IStyles, EBgType } from "../interfaces";
import { CSSProperties } from "react";


export const defaultBlockStyles: IStyles = {
  titleFont: '',
  paragraphFont: '',
  cssClassName: '',
	bgColor: '', //#ffffff', //'transparent',
  marginVertical: '0',
  marginHorizontal: '0',
  paddingVertical: '16',
  paddingHorizontal: '30',
  bgImage: null,
  bgType: EBgType.COLOR,
  titleColor: "",
  paragraphColor: "",
  linkColor: '#337ab7'
}

export const defaultAreaStyles: IStyles = {
  titleFont: '',
  paragraphFont: '',
  cssClassName: '',
  bgColor: 'transparent',
  marginVertical: '0',
  marginHorizontal: '0',
  paddingVertical: '0',
  paddingHorizontal: '0',
  bgImage: null,
  bgType: EBgType.COLOR,
  titleColor: "",
  paragraphColor: "",
  linkColor: ''

	/*
  titleFont: 'Open Sans',
  paragraphFont: 'Open Sans',
  cssClassName: '',
  bgColor: '#FFFFFF',
  marginVertical: '0',
  marginHorizontal: '0',
  paddingVertical: '0',
  paddingHorizontal: '0',
  bgImage: null,
  bgType: EBgType.COLOR,
  titleColor: "",
  paragraphColor: "",
  linkColor: '#337ab7'
	*/
}

export const defaultTemplateStyles: IStyles = {
  titleFont: 'Open Sans',
  paragraphFont: 'Open Sans',
  cssClassName: '',
  bgColor: '#FFFFFF',
  marginVertical: '0',
  marginHorizontal: '0',
  paddingVertical: '0',
  paddingHorizontal: '0',
  bgImage: null,
  bgType: EBgType.COLOR,
  titleColor: "#333333",
  paragraphColor: "#333333",
  linkColor: '#337ab7'
}

export const parseStyles = (data: any) => {
  const properties = [
    "titleFont",
    "paragraphFont",
    "bgColor",
    "cssClassName",
    "marginVertical",
    "marginHorizontal",
    "paddingVertical",
    "paddingHorizontal",
    "bgImage",
    "bgType",
    "titleColor",
    "paragraphColor",
    "linkColor"
  ];

  let styles: any = {};
  for (let key of properties) {
    if (data.hasOwnProperty(key)) {
      styles[key] = data[key];
    }
  }
  return styles;
};

export const getBlockStyles = (item: IStyles): CSSProperties => {
  const styles: CSSProperties = { backgroundColor: "#FFFFFF" };

  if (item) {
    if (item.bgColor && item.bgType === EBgType.COLOR) {
      styles.backgroundColor = item.bgColor;
    }
    if (item.bgImage && item.bgType === EBgType.IMAGE) {
      styles.backgroundImage = `url('${item.bgImage.link}')`;
      styles.backgroundSize = "100%";
      styles.backgroundPosition = "center";
    }
  }

  return styles;
};


export const getBlockContentStyles = (item: IStyles): CSSProperties => {
  const styles: CSSProperties = {};

  if (item) {
    if (item.marginVertical) {
      styles.marginTop = item.marginVertical + "px";
      styles.marginBottom = item.marginVertical + "px";
    }
    if (item.marginHorizontal) {
      styles.marginLeft = item.marginHorizontal + "px";
      styles.marginRight = item.marginHorizontal + "px";
    }
    if (item.paddingVertical) {
      styles.paddingTop = item.paddingVertical + "px";
      styles.paddingBottom = item.paddingVertical + "px";
    }
    if (item.paddingHorizontal) {
      styles.paddingLeft = item.paddingHorizontal + "px";
      styles.paddingRight = item.paddingHorizontal + "px";
    }
  }

  return styles;
};
