import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import { IPlan, IAppState, ISubscriptionDetail } from '../../../../../interfaces';
import { initForm, validateInput, controlsToFormGroups } from '../../../../../shared/utility';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions";
import ListSpinner from '../../../../../components/UI/ListSpinner/ListSpinner';

interface IStateProps {
  error: string | null;
}

interface IDispatchProps {
  onSaveSubscription: (data: {}) => Promise<ISubscriptionDetail>;
  onUpdateSubscription: (data: {}) => Promise<ISubscriptionDetail>;
}

interface IProps extends IStateProps, IDispatchProps {
  showModal: boolean;
  toggleModal: () => void;
  plans: Array<IPlan>;
  currentPlan?: IPlan;
  subscription?: ISubscriptionDetail;
  setCurrentSubscription: (subscription: ISubscriptionDetail) => void;
}

const SubscriptionModal: React.FC<IProps> = ({
  showModal, toggleModal, plans, currentPlan,
  onSaveSubscription, onUpdateSubscription,
  subscription, setCurrentSubscription, error
}) => {
  const getControls = (): any => {
    return {
      plan: {
        elementType: "select",
        elementConfig: {
          label: "Choose subscription"
        },
        valid: true,
        value: "",
        options: []
      }
    };
  };

  const [state, setState] = useState({
    controls: getControls(),
    formIsValid: false
  });

  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentPlan) {
      setPlan({ ...currentPlan });
    }
  }, [currentPlan]);

  useEffect(() => {
    const data = initForm(getControls(), {});
    let update = false;

    if (plans) {
			data.controls.plan.options = plans.filter((ob)=>ob && ob.active).map(
        ({
          plan_id,
          amount,
          currency,
          interval,
          interval_count,
          product_id,
          product_name
        }) => ({
          label: `${product_name}, ${interval_count} ${interval}, ${amount} ${currency}`,
          id: plan_id || product_id
        })
      );
      update = true;

      if (subscription) {
        const plan = plans.find(
          plan => plan && (plan.plan_id === subscription.stripePlanId)
        );
        if(plan) {
          data.controls.plan.value = plan.plan_id;
          setPlan(plan);
        }
      }
    }

    if(update) {
      setState({
        controls: data.controls,
        formIsValid: data.formIsValid
      });
    }
  }, [plans, subscription]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value = event.target ? event.target.value : event;

    if (controlName === "plan") {
      const plan = plans.find(plan => plan && (plan.plan_id === value) );
      setPlan(plan);
    }

    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
  };

  const saveSubscription = async () => {
    const formData = {
      plan_id: plan.plan_id,
      seats: 1,
      currency: plan.currency,
      amount: plan.amount
    };

    if (subscription) {
      const newSubscription = await onUpdateSubscription(formData);
      if (newSubscription) {
        setCurrentSubscription(newSubscription);
        toggleModal();
      }
    } else {
      const newSubscription = await onSaveSubscription(formData);
      if (newSubscription) {
        setCurrentSubscription(newSubscription);
        toggleModal();
      }
    }

    setLoading(false);
  };
  
  const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setLoading(true);
    saveSubscription();
  };

  return (
    <Modal isOpen={showModal || loading} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Manage Subscription</ModalHeader>
      <ModalBody>
        {loading ? <ListSpinner /> : null}
        {controlsToFormGroups(state.controls, inputChangedHandler)}
        {error && (
          <Alert color="danger">{error}</Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" onClick={toggleModal}>
          Discard
        </Button>
        {' '}
        <Button type="submit" color="info" disabled={!plan || loading} onClick={submitHandler}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    error: state.subscriptions.error,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onSaveSubscription: data => dispatch(actions.saveSubscription(data)),
    onUpdateSubscription: data => dispatch(actions.updateSubscription(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionModal);
