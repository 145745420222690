const f = (x:number) => (parseFloat(String(x)) || 0)

export const calcTotal = (price:number,quantity:number,discount:number) => {
	return f(price)*f(quantity)*((100-(f(discount) || 0))/100)
}

export const calcTotalVat = (price:number,quantity:number,discount:number,vat:number) => {
	return f(price)*f(quantity)*((100-(f(discount) || 0))/100)*(f(vat)/100)
}

export const round = (x:number) => {
	return f(x).toFixed(2)
}
