import React, { useState } from "react";

import { connect } from "react-redux";
import { Alert, Button, Form } from "reactstrap";
import { IAppState } from "../../../interfaces";
import { controlsToFormGroups, validateInput } from "../../../shared/utility";
import { Link } from "react-router-dom";
import axios from '../../../axios';

import classes from "./Step1.module.scss";

interface IStateProps {
  error?: string | null;
}

interface IProps extends IStateProps {
  onSubmit(formData: object): void;
}

const Step1 = (props: IProps) => {
  const [state, setState] = useState({
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          placeholder: "Email",
          type: "email"
        },
        validation: {
          required: true,
          email: true,
          error: "Email is not valid"
        },
        valid: false,
        touched: false,
        value: ""
      },
      password: {
        elementType: "input",
        elementConfig: {
          placeholder: "Password",
          type: "password"
        },
        validation: {
          password: true,
          required: true,
          minLength: 8,
          error: "Password must have at least 8 characters"
        },
        valid: false,
        touched: false,
        value: ""
      }
    },
    controlsIsValid: false,
    errors: [],
    userExists: false
  });

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value: any = event;
    if (event.target) {
      value = event.target.value;
    }
    const validation = validateInput(state.controls, controlName, value);
    setState({
      ...state,
      userExists: false,
      controls: validation.controls,
      controlsIsValid: validation.formIsValid,
    });
  };

  const getErrors = () => {
    const errors = Object.entries(state.controls).reduce((o, [key, obj]) => {
      if (!obj.valid) {
        return [...o, obj.validation.error]
      }
      return o
    }, []);
    setState({
      ...state,
      errors
    })
    return errors.length > 0
  }

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { onSubmit } = props;
    const { email, password } = state.controls;
    const hasErrors = getErrors();
    
    if (state.controlsIsValid && !hasErrors) {
      const userExists = await axios.get(`/json/users/validate?email=${email.value}`)
      if (userExists.data) {
        setState({
          errors: [],
          controls: {
            email: {
              ...email,
              valid: true,
              touched: true
            },
            password
          },
          controlsIsValid: false,
          userExists: true
        });
      } else {
        onSubmit({email: email.value, password: password.value});
      }
    }
  };

  const { error } = props;
  const { userExists, errors } = state;

  return (
    <Form onSubmit={submitHandler}>
      <div className={classes.Inputs}>
        {controlsToFormGroups(state.controls, inputChangedHandler)}
      </div>
      {(error || userExists) && (
        <Alert className={classes.ErrorMessage} color="danger">
          {error || 'Email is already in use.'}
        </Alert>
      )}
      {errors.length > 0 && (
        errors.map(e => (
          <Alert key={e} className={classes.ErrorMessage} color="danger">
            {e}
          </Alert> 
        ))
      )}
      <div className={classes.ButtonContainer}>
        <Button type="submit" color="primary" className={classes.Button}>
          Next
        </Button>
      </div>
      <div className={classes.LoginQuestion}>
        <h6>Already have an account? <Link to="/login">Log in</Link></h6>
      </div>
    </Form>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    error: state.users.error,
  };
};

export default connect(
  mapStateToProps
)(Step1);
