import React from 'react';
import ContentTypeIcon from '../../../../../../../components/UI/ContentTypeIcon/ContentTypeIcon';
import SelectableContainer from '../../../../../../../components/UI/SelectableContainer/SelectableContainer';
import { EContentType } from '../../../../../../../enums/content-types';

import classes from "./ContentTypeItem.module.scss";

interface IProps {
  item: IContentTypeItem;
  active: boolean;
  onClick: (item:IContentTypeItem) => void;
}

export interface IContentTypeItem {
  id: EContentType;
  text: string;
}

const ContentTypeItem:React.FC<IProps> = ({ item, active, onClick }) => {
  return (
    <SelectableContainer className={classes.Container} active={active} onClick={() => onClick(item)}>
      <ContentTypeIcon contentType={item.id} active={active} />
      <p className={classes.Text}>{item.text}</p>
    </SelectableContainer>
  )
}

export default ContentTypeItem;