import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import OfferContext from '../../../../../containers/Offers/Offer/OfferContext';
import { isValidEmail } from '../../../../../../../shared/utility';

import { getOfferSigners } from '../../../../../../../shared/offer-utils';

import classes from "./OfferRecipients.module.scss";
import inputClasses from "../../../../../../../components/UI/Input/Input.module.scss";

interface IProps {}

const OfferRecipients: React.FC<IProps> = () => {
  const { offer, setOffer } = useContext(OfferContext);

  const [ othersAllowed, setOthersAllowed ] = useState(true);

  useEffect(()=>{
    const signatures = getOfferSigners(offer);
    if(signatures.length>0) {
      setOthersAllowed(false);
    }
    else { setOthersAllowed(true); }
  },[offer]);

  useEffect(() => {
    if (offer) {
    }
    // eslint-disable-next-line
  }, []);

  const onAddRecipient = () => {
    const newOffer = { ...offer };
    const recipients = offer.recipients || [];
    recipients.push("");
    newOffer.recipients = recipients;
    setOffer(newOffer);
  };

  const onRemoveRecipient = (position: number) => {
    const newOffer = { ...offer };
    newOffer.recipients.splice(position, 1);
    setOffer(newOffer);
  }

  const updateRecipient = (
    event: React.ChangeEvent<HTMLInputElement>,
    position: number
  ) => {
    const newOffer = { ...offer };
    newOffer.recipients[position] = event.target.value;
    setOffer(newOffer);
  };

  const renderRecipients = () => {
    if (!offer || !offer.recipients) return null;
    return offer.recipients.map((recipient, i) => (
      <FormGroup key={i} className={classes.FormGroup}>
        <Input
          className={classes.Input}
          type="email"
          placeholder="Recipient"
          value={recipient}
          onChange={event => updateRecipient(event, i)}
          invalid={!isValidEmail(recipient)}
        />
        <span className={classes.Minus} onClick={() => onRemoveRecipient(i)}><FontAwesomeIcon icon={faMinus} /></span>
      </FormGroup>
    ));
  };

  return !othersAllowed?null:(
    <React.Fragment>
      <div className={classes.Header}>
        <Label className={inputClasses.Label}>Other Recipients</Label>
        <span className={classes.Plus} onClick={onAddRecipient}>
          <FontAwesomeIcon icon={faPlus} />
        </span>
      </div>
      <div className={classes.Recipients}>{renderRecipients()}</div>
    </React.Fragment>
  );
};

export default OfferRecipients;
