import React, { useState, useEffect } from "react";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from "../../../withRouter";
import { useParams } from 'react-router-dom';

import {
  validateInput,
  controlsToFormGroups,
  initForm,
  getFormData,
  hasAccess
} from "../../../shared/utility";
import { Form, Alert } from "reactstrap";
import { IUser, IUserGroup, IAppState, IOrganization } from "../../../interfaces";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import EUserRole from "../../../enums/user-roles";
//import { getUserControls } from "../../../shared/controls";
import FormFooter from "../../../components/UI/FormFooter/FormFooter";
import FormContainer from "../../../components/UI/FormContainer/FormContainer";
import FooterFixed from "../../../components/UI/FooterFixed/FooterFixed";

import classes from "../User/User.module.scss";

interface IStateProps {
  currentUser: IUser | null;
  userGroup: IUserGroup | null;
  organization: IOrganization | null;
  error: string | null;
  loading: boolean;
  success: boolean;
  users: Array<IUser>;
}

interface IDispatchProps {
  onFindUsers: (organizationId: string) => void;

  onGetUserGroup: (id: string) => {};
  onUpdateUserGroup: (formData: {}) => Promise<boolean>;
  onSaveUserGroup: (formData: {}) => Promise<boolean>;
  onDeleteUserGroup: (id: string) => {};
}

interface IMatchProps {
  id: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps {} //<IMatchProps> {}

const UserGroup: React.FC<IProps> = props => {
  const {
    onFindUsers,

    onGetUserGroup,
    userGroup,
    organization,
    currentUser,
    match,
    history,
    loading,
    error,
    users,
  } = props;

  const params = useParams();

  const getUserGroupControls = (): any => {
    return {
      name: {
        elementType: "input",
        elementConfig: {
          label: "Group name",
          placeholder: "Group name",
          type: "text"
        },
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        value: ""
      },
      members: {
        elementType: "select",
        elementConfig: {
          label: "Group members",
          placeholder: "Group members",
          isMulti: true,
          isClearable: true,
        },
        options: options,
        /*[
          {id:"a", label:"aaa"},
          {id:"b", label:"bee"},
        ],*/
        validation: {
          required: false
        },
        valueMapper: (x:any) => { return x.map((id:any)=>({id:id}))},
        valid: true,
        touched: false,
        value: ""
      },
/*
      leads: {
        elementType: "select",
        elementConfig: {
          label: "Team Leads",
          placeholder: "Team Leads",
          isMulti: true,
          isClearable: true,
          isDisabled: !hasAccess(currentUser, EUserRole.ENTREPRENEUR),
        },
        options: options,
        validation: {
          required: false
        },
        valueMapper: (x:any) => { return x.map((id:any)=>({id:id}))},
        valid: true,
        touched: false,
        value: ""
      },
*/      
    };
  };


  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (users) {
      setOptions(
        users.filter(({isDeleted})=>!isDeleted).map(({ id, name }) => ({
          id: id,
          label: `${name}`
        }))
      );
    }
  }, [users]);

  const [state, setState] = useState({
    controls: getUserGroupControls(),
    formIsValid: false
  });

  const [organizationId, setOrganizationId] = useState("");

  useEffect(() => {
    if (!organization) {
      //if (hasAccess(currentUser, EUserRole.ENTREPRENEUR, EUserRole.TEAM_LEAD, EUserRole.ADMIN)) {
      if (hasAccess(currentUser, EUserRole.ENTREPRENEUR, EUserRole.ADMIN)) {
        setOrganizationId(currentUser.organizationId);
      } else {
        history.goBack();
      }
    } else {
      setOrganizationId(organization.id);
    }
    // eslint-disable-next-line
  }, [
    organization,
    currentUser.role,
    currentUser.organizationId,
    history
  ]);

  useEffect(()=>{
    if(organizationId) {
      onFindUsers(organizationId);
    }
  },[organizationId, organization])

  useEffect(() => {
    //const { id } = match.params;
    const id = params.id;
    if (id && id !== "add" && currentUser) {
      onGetUserGroup(id);
    }
  //}, [currentUser, onGetUserGroup, match.params]);
  }, [currentUser, onGetUserGroup, params]);

  useEffect(() => {
    //const { id } = match.params;
    const id = params.id;
    const controls = getUserGroupControls();

    if (id && id !== "add" && currentUser && userGroup) {      
      const data = initForm(controls, {
        ...userGroup,
        members:(userGroup.members || []).map((id:string)=>({id:id})),
        //leads:(userGroup.leads || []).map((id:string)=>({id:id})),
      });
      setState({
        controls: data.controls,
        formIsValid: data.formIsValid
      });
    }
    else {
      setState({
        controls: controls,
        formIsValid: false,
      });

    }
  }, [userGroup, currentUser.id, options]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value = event.target ? event.target.value : event;

    const validation = validateInput(state.controls, controlName, value);
    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
    
  };

	const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const id = userGroup ? userGroup.id : undefined;
    const formData = getFormData(state.controls);
    let success = false;

    formData.members = (formData.members || []).map((x:any)=>(x.id));
    //formData.leads = formData.leads.map((x:any)=>(x.id));

    if (organizationId) {
      formData.organizationId = organizationId;

      if (id && id !== "add") {
        formData.id = id;
        success = await props.onUpdateUserGroup(formData);
      } else {
        success = await props.onSaveUserGroup(formData);
      }
    }
    
    if (success && !error) {
      history.goBack();
    }
  };

  const deleteHandler = async () => {
    //const { id } = match.params;
    const id = params.id;
    if (id && id !== "add" && currentUser) {
      await props.onDeleteUserGroup(id);
      history.goBack();
    }
  }

  return (
    <React.Fragment>
      <FormContainer title="Manage user group" loading={loading}>
        <Form className={classes.Content} autoComplete="off">
          {controlsToFormGroups(state.controls, inputChangedHandler)}
        </Form>
        {error && (
          <Alert className={classes.ErrorMessage} color="danger">
            {error || 'Email is already in use.'}
          </Alert>
        )}
      </FormContainer>
      <FooterFixed>
        <FormFooter
					saveText="Save group"
          onSave={submitHandler}
          onDiscard={() => history.goBack()}
          isValid={state.formIsValid}
          onDelete={deleteHandler}
          deleteText="Delete group"
        />
      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    userGroup: state.userGroups.userGroup,
    organization: state.organizations.organization,
    loading: state.users.loading,
    success: state.users.success,
    error: state.users.error,
    users: state.users.users,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindUsers: organizationId => dispatch(actions.findUsers(organizationId)),

    onGetUserGroup: id => dispatch(actions.getUserGroup(id)),
    onUpdateUserGroup: formData => dispatch(actions.updateUserGroup(formData)),
    onSaveUserGroup: formData => dispatch(actions.saveUserGroup(formData)),
    onDeleteUserGroup: id => dispatch(actions.deleteUserGroup(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGroup);
