import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import ListSpinner from '../../../../../components/UI/ListSpinner/ListSpinner';
import { ISeats, ISubscriptionDetail, IInvoice, IPaymentMethodDetail, IAppState } from '../../../../../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCardBrandImage, dateToString } from '../../../../../shared/utility';
import { connect } from 'react-redux';

import classes from "./BillingContainer.module.scss";

interface IStateProps {
  invoicesLoading: boolean;
  paymentMethodsLoading: boolean;
  subscriptionsLoading: boolean;
  billingLoading: boolean;
}

interface IProps extends IStateProps {
  paymentMethod: IPaymentMethodDetail;
  upcomingInvoice: IInvoice;
  seats: ISeats;
  manageUsers: () => void;
  subscription: ISubscriptionDetail;
  togglePlanModal: () => void;
  manageInvoices: () => void;
  togglePaymentMethodModal: () => void;
  toggleCancelSubscriptionModal: () => void;
}

const BillingContainer: React.FC<IProps> = ({
  seats, manageUsers, subscription, togglePlanModal,
  upcomingInvoice, manageInvoices, togglePaymentMethodModal,
  paymentMethod, toggleCancelSubscriptionModal, invoicesLoading,
  paymentMethodsLoading, subscriptionsLoading, billingLoading
}) => {

  return (
    // TODO: fix css
    <Container className={classes.Container}>
      <h4>Billing overview</h4>
      <Row className={classes.Box}>
        <Col xs={12} sm={6} className={classes.TopColumn}>
          <p>Users</p>
          {billingLoading
            ? <ListSpinner />
            : seats
              ? <h4>{seats.activeUsers}/{seats.availableSeats}</h4>
              : null
          }
          <Button color="secondary" onClick={manageUsers}>Manage users</Button>
        </Col>
        {/* TODO: add plan name? */}
        <Col xs={12} sm={6} className={classes.TopColumn}>
          <p>Subscription</p>
          <h4>{subscriptionsLoading
            ? <ListSpinner />
            : subscription
              ? subscription.status : 'Not subscribed'}
          </h4>
					<Button color="secondary" onClick={togglePlanModal} disabled={!paymentMethod}>Manage subscription</Button>

          {' '}
          {subscription && subscription.status.toString() !== 'canceled'
            ? (
              <Button color="danger" onClick={toggleCancelSubscriptionModal}>Cancel</Button>
            )
            : null}
        </Col>
      </Row>
      <Row className={classes.Box}>
        <Col xs={12} sm={6} className={classes.MiddleColumn}>
          <div className={classes.Row}>
            <h4>Upcoming invoice</h4>
            <Button color="secondary" onClick={manageInvoices}>Manage invoices</Button>
          </div>
          <hr />
          {invoicesLoading
            ? <ListSpinner />
            : upcomingInvoice
              ? (
                <div>
                  <div className={classes.Row}>
                    <p>Number</p>
                    <p>{upcomingInvoice.number}</p>
                  </div>
                  <div className={classes.Row}>
                    <p>Amount</p>
                    <p>{upcomingInvoice.total} {upcomingInvoice.currency}</p>
                  </div>
                  <div className={classes.Row}>
                    <p>Period</p>
                    <p>{dateToString(new Date(upcomingInvoice.period_start))} - {dateToString(new Date(upcomingInvoice.period_end))}</p>
                  </div>
                  <div className={classes.Row}>
                    <p>Status</p>
                    <p>{upcomingInvoice.status}</p>
                  </div>
                </div>
              )
              : null
          }
        </Col>
        <Col xs={12} sm={6} className={classes.MiddleColumn}>
          <div className={classes.Row}>
            <h4>Billing details</h4>
            <Button color="primary" onClick={togglePaymentMethodModal}>Add card</Button>
          </div>
          <hr />
          {paymentMethodsLoading
            ? <ListSpinner />
            : paymentMethod
              ? (
                <div>
                  <div className={classes.Row}>
                    <p>Brand</p>
                    <p>{paymentMethod.brand ? <FontAwesomeIcon icon={getCardBrandImage(paymentMethod.brand)} size="2x" color="#ccc" /> : null}</p>
                  </div>
                  <div className={classes.Row}>
                    <p>Number</p>
                    <p>**** {paymentMethod.lastDigits}</p>
                  </div>
                  <div className={classes.Row}>
                    <p>Expiring</p>
                    <p>{paymentMethod.validMonth}/{paymentMethod.validYear}</p>
                  </div>
                  <div className={classes.Row}>
                    <p>Created</p>
                    <p>{dateToString(new Date(paymentMethod.created))}</p>
                  </div>
                </div>
              )
              : <p style={{ color: "red" }}>Payment method missing.</p>}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    invoicesLoading: state.invoices.loading,
    paymentMethodsLoading: state.paymentMethods.loading,
    subscriptionsLoading: state.subscriptions.loading,
    billingLoading: state.billing.loading
  };
};

export default connect(
  mapStateToProps,
  null
)(BillingContainer);
