import React from 'react';
import { Button, FormGroup, Label, Spinner } from 'reactstrap';

import { EUserRole } from '../../enums';
import { IOrganization, IPipedriveConnection, IUser } from '../../interfaces';
import { hasAccess } from '../../shared/utility';

interface IProps {
  organization: IOrganization;
  currentUser: IUser;
  connection: IPipedriveConnection;
  error: string | null;
  loading: boolean;
  onTestConnection: () => void;
}

const OrganizationPipedrive: React.FC<IProps> = ({
  organization,
  currentUser,
  connection,
  error,
  loading,
  onTestConnection
}) => {
  if (!organization || !organization.pipedriveConnected) return null;
  if (loading) {
    return (
      <div style={{ margin: "1rem" }}>
        <Spinner color="primary" />
      </div>
    );
  }

  if (hasAccess(currentUser, EUserRole.SUPERADMIN)) {
    return (
      <React.Fragment>
        <h5>Pipedrive</h5>
        <Button color="primary" onClick={onTestConnection}>
          Test connection
        </Button>

        {connection && connection.success ? (
          <p className="text-success">Connection ok</p>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : null}

        <FormGroup>
          <Label>Access token</Label>
          <p className="form-control-static">
            {organization.pipedriveAuth.accessToken}
          </p>
        </FormGroup>
        <FormGroup>
          <Label>Refresh token</Label>
          <p className="form-control-static">
            {organization.pipedriveAuth.refreshToken}
          </p>
        </FormGroup>
        <FormGroup>
          <Label>Expires date</Label>
          <p className="form-control-static">
            {organization.pipedriveAuth.expiresDate}
          </p>
        </FormGroup>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h5>Pipedrive</h5>
      <div className="form-group">
        <label>Status</label>
        <p className="form-control-static text-success">Pipedrive connected</p>
      </div>
    </React.Fragment>
  );
};

export default OrganizationPipedrive;
