import React, { ReactNode, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "reactstrap";

import classes from "./SidebarCollapse.module.scss";

interface IProps {
  title: string;
  children: ReactNode;
}

const SidebarCollapse: React.FC<IProps> = ({ title, children }) => {
  const [isOpen, setOpen] = useState<boolean>(true);

  let icon = null;
  if (isOpen) {
    icon = faChevronDown;
  } else {
    icon = faChevronUp;
  }

  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <p>{title}</p>
        <span onClick={() => setOpen(!isOpen)}>
          <FontAwesomeIcon icon={icon} className="fa-xs" />
        </span>
      </div>
      <Collapse isOpen={isOpen}>
        <div className={classes.Content}>{children}</div>
      </Collapse>
    </div>
  );
};

export default SidebarCollapse;
