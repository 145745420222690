import React from "react";

import classes from "./BoxFooter.module.scss";

interface IProps {
  children: any;
  className?: any;
}

const BoxFooter: React.FC<IProps> = ({ children, className }) => {
  const classNames = [classes.Container];
  if(className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default BoxFooter;
