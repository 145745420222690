import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../withRouter';

import ListContainer from "../../../../components/ListContainer/ListContainer";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import { ESortDirection, ISort } from "../../../../components/UI/SortIcon/SortIcon";
import { EOfferListType, EOfferSort } from "../../../../enums/offer-enums";
import { IAppState, IOffer } from "../../../../interfaces";
import * as actions from "../../../../store/actions";

import { Table } from 'reactstrap';

import classes from "./Statistics.module.scss";

interface IStateProps {
  loading: boolean;
  error: string;
  offers: Array<IOffer>;
}

interface IDispatchProps {
  onFindOffersAndContracts: (sort: Array<ISort<EOfferSort>>) => void;
  onFindOffers: (sort: Array<ISort<EOfferSort>>) => void;
  onFindContracts: (sort: Array<ISort<EOfferSort>>) => void;
  onClearOffersState: () => void;
  onSearchOffers: (queryString: string) => void;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {}

const defaultSort: Array<ISort<EOfferSort>> = [
  { property: EOfferSort.CONFIRM_DATE, direction: ESortDirection.DESC }
];

const Statistics: React.FC<IProps> = ({
  onFindOffersAndContracts,
  onFindOffers,
  onFindContracts,
  onClearOffersState,
  onSearchOffers,
  offers,
  loading,
  history
}) => {
  const [loadStep, setLoadStep] = useState(0);

  const [userStats, setUserStats] = useState<{[id:string]:any}>({});  

  useEffect(() => {    
    if(offers) {
      const _userStats:{[id:string]:any} = userStats;

      offers.forEach((offer:IOffer) => {
        if(!Object.keys(_userStats).includes(offer.authorName)) {
          _userStats[offer.authorName] = {confirmed:[], sent:[], conv:0};
        }

        const _date = offer.created.split(" ")[0].split(".");
        const creatdate:Date = new Date(parseInt(_date[2]),parseInt(_date[1])-1,parseInt(_date[0]));
        
        if(["SENT", "SELFSENT", "OPENED"].includes(offer.status)) {
          _userStats[offer.authorName].sent[offer.id] = {...offer,created:creatdate, confirmDate: null};
        }
        else if(offer.status === "CONFIRMED") {
          const _cd = new Date(offer.confirmDate);
          const confdate:Date = new Date(_cd.getFullYear(), _cd.getMonth(), _cd.getDate());

          _userStats[offer.authorName].conv += confdate.valueOf()-creatdate.valueOf();
          _userStats[offer.authorName].confirmed[offer.id] = {...offer,created:creatdate, confirmDate: confdate};
        }
        
      });
      setUserStats({..._userStats});    
    }

    if(loadStep == 0) {     
      onFindOffers(defaultSort);
      setLoadStep(1);
    }
    else if(offers && loadStep == 1) {
      onFindContracts(defaultSort);
      setLoadStep(2);
    }


  }, [offers]);

const calculateStats = (name:string, from:Date, to:Date=null) => {
  const confirmed=Object.values(userStats[name].confirmed).filter((d:any) => (from===null || d.created>from) && (to===null || d.created<to));

  const sent=Object.values(userStats[name].sent).filter((d:any) => (from===null || d.created>from) && (to===null || d.created<to));

  const no_conf:number = confirmed.length;
  const no_sent:number = sent.length;
  const conv:number = Math.round(no_conf/(no_conf+no_sent)*100);

  const conv_time:number = Math.round(confirmed.reduce((resp:number, d:any)=> (resp+(d.confirmDate.valueOf()-d.created.valueOf())),0)/no_conf/1000/60/60/24*10)/10;

  return {no_conf, no_sent, conv, conv_time}
}

const sum = (ar:any) => ar.reduce((partialSum:number, a:number) => partialSum + a, 0);
const round = (v:number) => isNaN(v)?"-":Math.round(v*10)/10;

return (
  <ListContainer>
    <ListHeader title="Statistics"/>
      <Table className={classes.statisticsTable}>
        <thead>
          <tr>
            <th>User</th>
            <th className={classes.even} colSpan={3}>Last month</th>
            <th colSpan={3}>Current month</th>
            <th className={classes.even} colSpan={3}>Calendar year total</th>
            <th colSpan={3}>All time total</th>
          </tr>
          <tr>
            <th></th>

            <th className={classes.even}>Nro of</th>
            <th className={classes.even}>Conversion %</th>
            <th className={classes.even}>Average time</th>

            <th>Nro of</th>
            <th>Conversion %</th>
            <th>Average time</th>

            <th className={classes.even}>Nro of</th>
            <th className={classes.even}>Conversion %</th>
            <th className={classes.even}>Average time</th>

            <th>Nro of</th>
            <th>Conversion %</th>
            <th>Average time</th>

          </tr>
        </thead>

        {[1].map((n)=> {
          let t1:Array<number>=[];
          let t2:Array<number>=[];
          let t3:Array<number>=[];
          let t4:Array<number>=[];
          let t5:Array<number>=[];
          let t6:Array<number>=[];
          let t7:Array<number>=[];
          let t8:Array<number>=[];
          let t9:Array<number>=[];
          let t10:Array<number>=[];
          let t11:Array<number>=[];
          let t12:Array<number>=[];

          return (<>
            <tbody>
              {Object.keys(userStats).map((name:string) => {
                const now = new Date();
                const prev = new Date();
                prev.setDate(0);
                prev.setDate(1);
                const {no_conf:no_conf_1, no_sent:no_sent_1, conv:conv_1, conv_time:conv_time_1} = calculateStats(name, new Date(prev.getFullYear(),prev.getMonth(),1), new Date(now.getFullYear(),now.getMonth(),1));
                const {no_conf:no_conf_2, no_sent:no_sent_2, conv:conv_2, conv_time:conv_time_2} = calculateStats(name, new Date(now.getFullYear(),now.getMonth(),1));
                const {no_conf:no_conf_3, no_sent:no_sent_3, conv:conv_3, conv_time:conv_time_3} = calculateStats(name, new Date(now.getFullYear(),0,1));
                const {no_conf:no_conf_4, no_sent:no_sent_4, conv:conv_4, conv_time:conv_time_4} = calculateStats(name, null);
                
                t1.push(no_conf_1+no_sent_1);            
                if(!isNaN(conv_1)) {
                    t2.push(conv_1);
                }
                if(!isNaN(conv_time_1)) {
                    t3.push(conv_time_1);
                }

                t4.push(no_conf_2+no_sent_2);            
                if(!isNaN(conv_2)) {
                    t5.push(conv_2);
                }
                if(!isNaN(conv_time_2)) {
                    t6.push(conv_time_2);
                }
                
                t7.push(no_conf_3+no_sent_3);            
                if(!isNaN(conv_3)) {
                    t8.push(conv_3);
                }
                if(!isNaN(conv_time_3)) {
                    t9.push(conv_time_3);
                }

                t10.push(no_conf_4+no_sent_4);            
                if(!isNaN(conv_4)) {
                    t11.push(conv_4);
                }
                if(!isNaN(conv_time_4)) {
                    t12.push(conv_time_4);
                }

                return <tr key={name}>
                  <th>{name}</th>
                  
                  <td className={classes.even}>{no_conf_1+no_sent_1}</td>
                  <td className={classes.even}>{isNaN(conv_1)?"-":conv_1}</td>
                  <td className={classes.even}>{isNaN(conv_time_1)?"-":conv_time_1}</td>

                  <td>{no_conf_2+no_sent_2}</td>
                  <td>{isNaN(conv_2)?"-":conv_2}</td>
                  <td>{isNaN(conv_time_2)?"-":conv_time_2}</td>

                  <td className={classes.even}>{no_conf_3+no_sent_3}</td>
                  <td className={classes.even}>{isNaN(conv_3)?"-":conv_3}</td>
                  <td className={classes.even}>{isNaN(conv_time_3)?"-":conv_time_3}</td>
                  
                  <td>{no_conf_4+no_sent_4}</td>
                  <td>{isNaN(conv_4)?"-":conv_4}</td>
                  <td>{isNaN(conv_time_4)?"-":conv_time_4}</td>
                </tr>
              })}
            </tbody>
            <thead>
              <tr>
                <th>Total</th>
                <th className={classes.even}>{sum(t1)}</th>
                <th className={classes.even}>{round(sum(t2)/t2.length)}</th>
                <th className={classes.even}>{round(sum(t3)/t3.length)}</th>

                <th>{sum(t4)}</th>
                <th>{round(sum(t5)/t5.length)}</th>
                <th>{round(sum(t6)/t6.length)}</th>

                <th className={classes.even}>{sum(t7)}</th>
                <th className={classes.even}>{round(sum(t8)/t8.length)}</th>
                <th className={classes.even}>{round(sum(t9)/t9.length)}</th>

                <th>{sum(t10)}</th>
                <th>{round(sum(t11)/t11.length)}</th>
                <th>{round(sum(t12)/t12.length)}</th>

              </tr>
            </thead>

          </>)})}
        

      </Table>

      {loading ? (
        <ListSpinner />
      ) : null}
    </ListContainer>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.offers.loading,
    error: state.offers.error,
    offers: state.offers.offers
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindOffersAndContracts: (sort) => dispatch(actions.findOffers(sort, EOfferListType.OFFERS_AND_CONTRACTS)),
    onFindContracts: (sort) => dispatch(actions.findOffers(sort, EOfferListType.CONTRACTS)),
    onFindOffers: (sort) => dispatch(actions.findOffers(sort, EOfferListType.OFFERS)),
    onClearOffersState: () => dispatch(actions.clearOffersState()),
    onSearchOffers: queryString => dispatch(actions.searchOffers(queryString))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Statistics);




