import React, { useState, useContext } from "react";
import { Button } from "reactstrap";

import CustomDialog from "../../../../../../../components/UI/CustomDialog/CustomDialog";
import {
  IBlockContent,
  IBlockContentHTML
} from "../../../../../../../interfaces";
import { ETextType } from "../../ContentTypeDialog/ContentTypeItemContent/ContentTypeItemContent";
import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";

import classes from "./BlockContentHTMLShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent?: boolean;
}

const BlockContentContentShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentHTML;

  const { customer, printable } = useContext(OfferPreviewContext);

  let dynamicLink = blockContent.iframesrc;
  if(customer && dynamicLink) {
    dynamicLink = dynamicLink.replace("{{CUSTOMER}}",encodeURIComponent(customer.name)).replace("{{CONTACT}}",encodeURIComponent(customer.contactPerson));
  }
  
  return (!printable || blockContent.printable) ? (
    <React.Fragment>
      <div>
        <div style={{"position":"relative","height":blockContent.height,"overflow":"hidden"}}>
          <iframe src={dynamicLink}
            frameBorder="0" allowFullScreen
            style={{"position":"absolute","top":0,"left":0,"width":"100%","height":"100%"}}></iframe>
        </div>
      </div>

    </React.Fragment>
  ) : null;
};

export default BlockContentContentShow;
