import React, { useState } from "react";
import CustomDialog from "../../../../../../../../../components/UI/CustomDialog/CustomDialog";
import { controlsToFormGroups, defaultInputChangedHandler, getFormData } from "../../../../../../../../../shared/utility";

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (statusDetails: string) => void;
  onDelete?: (id: string) => void;
}

const initControls = {
  controls: {
    statusDetails: {
      elementType: "textarea",
      elementConfig: {
        label: "Details",
        placeholder: "Add details"
      },
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      value: ""
    }
  },
  formIsValid: false
};

const OfferAcceptedElseDialog: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  onSave,
}) => {
  const [controlsState, setControlsState] = useState(initControls);

  const closeDialog = () => {
    setIsOpen(false);
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) =>
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );

  const onOk = () => {
    const data = getFormData(controlsState.controls);
    onSave(data.statusDetails);
    closeDialog();
  };

  return (
    <CustomDialog
      loading={false}
      onOk={onOk}
      onCancel={closeDialog}
      open={isOpen}
      title="Mark as accepted elsewhere"
      okButtonText="Accept offer"
      cancelButtonText="Cancel"
      isValid={controlsState.formIsValid}
    >
      <p>This action will mark the proposal as accepted and move it to accepted contracts. Are you sure you wish to proceed?</p>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler)}
    </CustomDialog>
  );
};

export default OfferAcceptedElseDialog;