import { ActionTypes } from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { IAttachmentState, IAttachmentAction } from "../../interfaces";

const initialState:IAttachmentState = {
    attachments: null,
    loading: false,
    success: false
};

const clearAttachmentState = (state:IAttachmentState, action:IAttachmentAction):IAttachmentState => {
  return initialState;
}

const uploadAttachmentStart = (state:IAttachmentState, action:IAttachmentAction):IAttachmentState => {
  return updateObject(state, { loading: true});
}

const uploadAttachmentSuccess = (state:IAttachmentState, action:IAttachmentAction):IAttachmentState => {
  return updateObject(state, {
      loading: false,
      attachments: action.attachments
  });
}

const uploadAttachmentFail = (state:IAttachmentState, action:IAttachmentAction):IAttachmentState => {
  return updateObject(state, {
      success: false,
      loading: false
  });
};

const reducer = (state:IAttachmentState = initialState, action:IAttachmentAction):IAttachmentState => {
  switch (action.type) {
      case ActionTypes.CLEAR_ATTACHMENT_STATE : return clearAttachmentState(state, action);
      case ActionTypes.UPLOAD_ATTACHMENT_START : return uploadAttachmentStart(state, action);
      case ActionTypes.UPLOAD_ATTACHMENT_SUCCESS : return uploadAttachmentSuccess(state, action);
      case ActionTypes.UPLOAD_ATTACHMENT_FAIL : return uploadAttachmentFail(state, action);
      default: return state;
  }
}

export default reducer;
