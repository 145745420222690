import React from 'react';
import { connect } from 'react-redux';
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from '../../withRouter';
import { Table } from 'reactstrap';

import { useLocation } from 'react-router-dom';


import EUserRole from '../../enums/user-roles';
import { IAppState, IUser } from '../../interfaces';
import { hasAccess } from '../../shared/utility';

interface IStateProps {
  currentUser: IUser | null;
}

interface IProps extends IStateProps, RouteComponentProps {
  users: Array<IUser>;
}

const UsersList: React.FC<IProps> = ({
  users,
  history,
  match,
  currentUser
}) => {
  const location = useLocation();

  if (!users || !users.length) {
    return null;
  }

  const onEdit = (user: IUser) => {
    if (hasAccess(currentUser, EUserRole.SUPERADMIN)) {
      
      //history.push(`${match.url}/users/${user.id}`);
      history.push(`${location.pathname}/users/${user.id}`);
    } else {
      history.push(`/users/${user.id}`);
    }
  };

  return (
    <Table responsive borderless striped>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        {users.filter((user)=>(hasAccess(currentUser, EUserRole.SUPERADMIN) || !user.isDeleted)).map((user, i) => (
          <tr key={user.id || `${user.name}_${i}`} onClick={() => onEdit(user)} style={{ cursor: "pointer", textDecoration: user.isDeleted?"line-through":"" }}>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.roleString}</td>
            <td>{user.allowLogin ? 'Active' : '-'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(UsersList);
