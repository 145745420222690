import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IBlockContentPrivate, IProduct } from "../../../../../../../../interfaces";

import { ETaxTypes } from "../../../../../../../../enums";

import { IBlockContentEditItem } from "../../../BlockContentEdit";

import { Button, Label, Container, Row, Col, Input, InputGroup, InputGroupText } from 'reactstrap'

import CustomDialog from "../../../../../../../../components/UI/CustomDialog/CustomDialog";


import IconButton from "../../../../../../../../components/UI/IconButton/IconButton";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import ReadCSVModal from "../../../../../../../../components/ReadCSVModal/ReadCSVModal";

import { readString } from "react-papaparse";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  controlsToFormGroups,
} from "../../../../../../../../shared/utility";

import { isnot } from "./functions";


import classes from "./BlockContentProducts3Edit.module.scss";

//const empty:{[id:string]:any} = {tyo:"", tila:-1, kokolikakerroin:1, standardiaika:0, kpl:"", kerros:1, hinta:0, korjattuhinta: NaN};
const empty:{[id:string]:any} = {tyo:"", kpl:"€/kpl", hinta:0};

interface IProps {
  //blockContent:any;
  settings:any;
  selected:{[id:string]:any}[];
  setSelected:(x:{[id:string]:any}[])=>void;
}

const BlockContentProductsEdit: React.FC<IProps> = ({
	//blockContent,
	settings,
	selected,
	setSelected,
}) => {

  const [isOpen, setIsOpen] = useState(false);

  //const blockContent = props.blockContent as IBlockContentPrivate;
  
  //const [selected, setSelected] = useState<{[id:string]:any}[]>(blockContent.json && JSON.parse(blockContent.json) || empty);
  //const [selected, setSelected] = useState<{[id:string]:any}[]>(blockContent);

  /*
  const updateControls = (data:IBlockContentPrivate) => ({
    controls: {
  
    },

  })
	*/
//console.log("BC",blockContent);
  const round = (x:any) => {
  	if(isNaN(x)) return 1;
	  return (Math.round(parseFloat(x)*100)/100).toFixed(2);
  }

  const float = (x:any) => {
  	if(isNaN(x)) return 0;
  	return parseFloat(x);
  }
/*
  const getData = () => {
		return Object.assign(blockContent, {json: JSON.stringify({section1:selected,section2:[]}), settings: JSON.stringify(customJson)});
	}

	const [controlsState, setControlsState] = useState(null);
*/
	const [asetukset, setAsetukset] = useState<{[id:string]:any}>({tilat:[]});
	const [alv, setAlv] = useState<number>(24.0);

	useEffect(() => {
		if(settings) {
			//const settings = JSON.parse(blockContent.settings);

			setAlv(settings.alv || 24.0);

			const mymap = (x:any) => {
				 const [key,value] = x.split("=");
				 return [key.trim(),round(value)];
			}

			const x:any = {header:false, skipEmptyLines:true};
			const d:any=readString(settings.asetukset,x);
			const _tilat = d.data.reduce((resp:any, ob:any)=>{
				const _tila = {
					nimi: ob[0],
					standardiaika : float(ob[1]),
					kokokerroin: ob[2].split(";").map(mymap),
					likakerroin: ob[3].split(";").map(mymap),
					tuntihinta: float(ob[4]),
					lisatyot: ob[5].split(";").map(mymap).sort((a:any,b:any)=>a[0]>b[0]?1:-1),
					lisatyokerroin: ob[6].split(";").map(mymap),
					lisahinta: float(ob[7]),
					kerrokset: parseInt(ob[8]),
					info1: ob[9],
					info2: ob[10],
				}
				return [...resp,_tila];
			},[]);

			setAsetukset({tilat:_tilat.sort(sortnimi)});
			//setSelected(JSON.parse(blockContent.json));
		}
	},[settings]);

/*
  useImperativeHandle(ref, () => ({
    getData
  }));
  */
/*  
  useEffect(() => {
  	if(props.onChange) {
			props.onChange(getData())
		}
    // eslint-disable-next-line
  }, [selected]);
  */

  const customJson:{[id:string]:any} = settings || {
		asetukset: "",
		kuukausikertoimet: [],
		info:"",
	};


	const onChangeSelected = (index:number, t:string, e:number|string) => {		
		let _sel = [...selected];

		if(t==="hinta") {
			_sel[index] = {..._sel[index], hinta:e};
		}
		else if(t==="kpl") {
			_sel[index] = {..._sel[index], kpl:e};
		}
		else if(t==="tyo") {
			_sel[index] = {..._sel[index],tyo:e};	
		}
		setSelected(_sel);
	}

	const addRow = () => {
		//const vanhaTila = selected.length>0?selected[selected.length-1].tila:-1;
		//const vanhaHinta = selected.length>0?selected[selected.length-1].hinta:0;
		setSelected([...selected,empty]); //{...empty,tila:vanhaTila,hinta:vanhaHinta}]);
	}

	const total = () => {
		return selected.reduce((resp,ob:any)=>{
			return resp + (parseFloat(ob.hinta) || 0);
		},0);
	}

	const getValue = (a:any, _i:number,pos:number):number => {
		const i=isNaN(_i)?0:_i;
		if(i in a) {
			if(pos in a[i]) {
				return float(a[i][pos]);
			}
		}
		return 0;
	}

	//const empty = {tyo:"", tila:0, kokolikakerroin:0, standardiaika:0, kpl:1, kerros:1, hinta:0, korjattuhinta:null};

	const deleteIndex = (index:number) => {
		const _selected = [...selected];
		_selected.splice(index,1);
		setSelected(_selected);
	}

	const sortnimi = (a:any, b:any) => {
		return a.nimi.toLowerCase()>b.nimi.toLowerCase()?1:-1;
	}


  return (
		<React.Fragment>

  	{selected.map((xselected, index) => {

			return (<div key={index}>
		  	<Row style={{"marginBottom":4, "paddingBottom":5}}>
		  		<Col>
				  	<Input type="textarea" value={xselected.tyo} onChange={(e:any)=>onChangeSelected(index, "tyo", e.target.value)} />
					</Col>

		  		<Col className="col-4">
		  			<InputGroup>
		  				<Input type="number" className="text-right" value={xselected.hinta} onChange={(e:any)=>onChangeSelected(index, "hinta", e.target.value)}/>
						  	<Input className="text-right" type="select" value={xselected.kpl} onChange={(e:any)=>onChangeSelected(index, "kpl", e.target.value)}>
						  		<option value="€/kpl">€/kpl</option>
						  		<option value="€/h">€/h</option>
						  		<option value="€/krt">€/krt</option>
						  		<option value="€/kk">€/kk</option>
						  	</Input>  			
						</InputGroup>
						{round(xselected.hinta*(alv/100+1))}{xselected.kpl} Alv {alv}%
					</Col>

					<Col className="col-1">
						<Button size="sm" onClick={()=>{deleteIndex(index)}}>
		        	<FontAwesomeIcon icon={faTrash} className="fa-sm"/>
	        	</Button>

		  		</Col>

		  	</Row>
		  	</div>
  	)})}

  	<Button onClick={addRow}>Lisää rivi</Button>

  	<Row>
  		<Col>Yhteensä:</Col>
  		<Col className="text-right">
  			Alv 0% {round(total())} €<br/>
  			Alv {alv}% {round(total()*(alv/100+1))} €<br/>
  			<br/>
  		</Col>
  	</Row>
  	
  	
    </React.Fragment>
  );
};

export default BlockContentProductsEdit;
