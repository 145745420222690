import { IBlockItem } from './../../interfaces/domain';
import { Dispatch } from "redux";
import axios from "../../axios";
import { IBlock, IBlockAction } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

/**
 * Block
 */

export const findBlocksStart = (): IBlockAction => {
  return {
    type: ActionTypes.FIND_BLOCKS_START
  };
};

export const findBlocksSuccess = (blocks: Array<IBlock>): IBlockAction => {
  return {
    type: ActionTypes.FIND_BLOCKS_SUCCESS,
    blocks
  };
};

export const findBlocksFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.FIND_BLOCKS_FAIL,
    error
  };
};

export const findBlocks = () => {
  return async (dispatch: Dispatch) => {
    dispatch(findBlocksStart());
    try {
      const res = await axios.get(`/json/blocks/list`);
      const blocks = res.data;
      dispatch(findBlocksSuccess(blocks));
    //@ts-ignore      
    } catch (error: any) {
      dispatch(findBlocksFail(error.response.data));
    }
  };
};

export const getBlockStart = (): IBlockAction => {
  return {
    type: ActionTypes.GET_BLOCK_START
  };
};

export const getBlockSuccess = (block: IBlock): IBlockAction => {
  return {
    type: ActionTypes.GET_BLOCK_SUCCESS,
    block
  };
};

export const getBlockFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.GET_BLOCK_FAIL,
    error
  };
};

export const getBlock = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBlockStart());
    try {
      const res = await axios.get(`/json/blocks/get?id=${id}`);
      const block = res.data;
      dispatch(getBlockSuccess(block));
      return block;
    //@ts-ignore
    } catch (error: any) {
      dispatch(getBlockFail(error.response.data));
    }
  };
};

export const saveBlockStart = (): IBlockAction => {
  return {
    type: ActionTypes.SAVE_BLOCK_START
  };
};

export const saveBlockSuccess = (): IBlockAction => {
  return {
    type: ActionTypes.SAVE_BLOCK_SUCCESS
  };
};

export const saveBlockFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.SAVE_BLOCK_FAIL,
    error
  };
};

export const saveBlock = (block: IBlock) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveBlockStart());
    try {
      await axios.post("/json/blocks/save", block);
      dispatch(saveBlockSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(saveBlockFail(error.response.data));
    }
  };
};

export const updateBlockStart = (): IBlockAction => {
  return {
    type: ActionTypes.UPDATE_BLOCK_START
  };
};

export const updateBlockSuccess = (): IBlockAction => {
  return {
    type: ActionTypes.UPDATE_BLOCK_SUCCESS
  };
};

export const updateBlockFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.UPDATE_BLOCK_FAIL,
    error
  };
};

export const updateBlock = (id: string, block: IBlock) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateBlockStart());
    try {
      await axios.put(`/json/blocks/update?id=${id}`, block);
      dispatch(updateBlockSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(updateBlockFail(error.response.data));
    }
  };
};

export const deleteBlockStart = (): IBlockAction => {
  return {
    type: ActionTypes.DELETE_BLOCK_START
  };
};

export const deleteBlockSuccess = (): IBlockAction => {
  return {
    type: ActionTypes.DELETE_BLOCK_SUCCESS
  };
};

export const deleteBlockFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.DELETE_BLOCK_FAIL,
    error
  };
};

export const deleteBlock = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteBlockStart());
    try {
      await axios.delete(`/json/blocks/delete?id=${id}`);
      dispatch(deleteBlockSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(deleteBlockFail(error.response.data));
    }
  };
};

/**
 * BlockItem
 */

export const findBlockItemsStart = (): IBlockAction => {
  return {
    type: ActionTypes.FIND_BLOCK_ITEMS_START
  };
};

export const findBlockItemsSuccess = (blockItems: Array<IBlockItem>): IBlockAction => {
  return {
    type: ActionTypes.FIND_BLOCK_ITEMS_SUCCESS,
    blockItems
  };
};

export const findBlockItemsFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.FIND_BLOCK_ITEMS_FAIL,
    error
  };
};

export const findBlockItems = (blockId:string, fetchSize: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(findBlockItemsStart());
    try {
      const res = await axios.get(`/json/blocks/items/list?blockId=${blockId}&fetchSize=${fetchSize}`);
      const blockItems = res.data;
      dispatch(findBlockItemsSuccess(blockItems));
      return blockItems;
      //@ts-ignore
    } catch (error: any) {
      dispatch(findBlockItemsFail(error.response.data));
    }
  };
};

export const getBlockItemStart = (): IBlockAction => {
  return {
    type: ActionTypes.GET_BLOCK_ITEM_START
  };
};

export const getBlockItemSuccess = (blockItem: IBlock): IBlockAction => {
  return {
    type: ActionTypes.GET_BLOCK_ITEM_SUCCESS,
    blockItem
  };
};

export const getBlockItemFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.GET_BLOCK_ITEM_FAIL,
    error
  };
};

export const getBlockItem = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBlockItemStart());
    try {
      const res = await axios.get(`/json/blocks/items/get?id=${id}`);
      const blockItem = res.data;
      dispatch(getBlockItemSuccess(blockItem));
      return blockItem;
      //@ts-ignore
    } catch (error: any) {
      dispatch(getBlockItemFail(error.response.data));
    }
  };
};

export const saveBlockItemStart = (): IBlockAction => {
  return {
    type: ActionTypes.SAVE_BLOCK_ITEM_START
  };
};

export const saveBlockItemSuccess = (): IBlockAction => {
  return {
    type: ActionTypes.SAVE_BLOCK_ITEM_SUCCESS
  };
};

export const saveBlockItemFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.SAVE_BLOCK_ITEM_FAIL,
    error
  };
};

export const saveBlockItem = (blockId:string, blockItem: IBlockItem) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveBlockItemStart());
    try {
      const res = await axios.post(`/json/blocks/items/save?blockId=${blockId}`, blockItem);
      dispatch(saveBlockItemSuccess());
      return res.data;
      //@ts-ignore
    } catch (error: any) {
      dispatch(saveBlockItemFail(error.response.data));
    }
  };
};

export const updateBlockItemStart = (): IBlockAction => {
  return {
    type: ActionTypes.UPDATE_BLOCK_ITEM_START
  };
};

export const updateBlockItemSuccess = (): IBlockAction => {
  return {
    type: ActionTypes.UPDATE_BLOCK_ITEM_SUCCESS
  };
};

export const updateBlockItemFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.UPDATE_BLOCK_ITEM_FAIL,
    error
  };
};

export const updateBlockItem = (blockId: string, blockItemId:string, blockItem: IBlockItem) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateBlockItemStart());
    try {
      await axios.put(`/json/blocks/items/update?blockId=${blockId}&blockItemId=${blockItemId}`, blockItem);
      dispatch(updateBlockItemSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(updateBlockItemFail(error.response.data));
    }
  };
};

export const deleteBlockItemStart = (): IBlockAction => {
  return {
    type: ActionTypes.DELETE_BLOCK_ITEM_START
  };
};

export const deleteBlockItemSuccess = (): IBlockAction => {
  return {
    type: ActionTypes.DELETE_BLOCK_ITEM_SUCCESS
  };
};

export const deleteBlockItemFail = (error: string): IBlockAction => {
  return {
    type: ActionTypes.DELETE_BLOCK_ITEM_FAIL,
    error
  };
};

export const deleteBlockItem = (blockItemId:string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteBlockItemStart());
    try {
      await axios.delete(`/json/blocks/items/delete?blockItemId=${blockItemId}`);
      dispatch(deleteBlockItemSuccess());
      return true;
      //@ts-ignore
    } catch (error: any) {
      dispatch(deleteBlockItemFail(error.response.data));
    }
  };
};
