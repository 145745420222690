import React, { CSSProperties } from "react";
import { Spinner } from "reactstrap";

import classes from "./ListSpinner.module.scss";

interface IProps {
  style?: CSSProperties;
}

const ListSpinner: React.FC<IProps> = ({ style = { marginTop: "1rem" } }) => {
  return (
    <div className={classes.Container} style={style}>
      <Spinner color="primary" />
    </div>
  );
};

export default ListSpinner;
