import React from "react";
import { EDragArea } from './../../../../../../enums/drag';
import { IBlockContent, IUserTemplateAreaBlock, IUserTemplateBlockDraggable, IUserTemplateArea } from './../../../../../../interfaces/domain';

const TemplateAreaContext = React.createContext<{
  moveItem: (item: IUserTemplateBlockDraggable, toArea:EDragArea, toItem: string) => void,
	moveCombinedItem?: (item: IBlockContent, index: number, block: IUserTemplateAreaBlock) => void,
  onStartDrag: (item: IUserTemplateBlockDraggable) => void;
  onEndDrag: (item:IUserTemplateBlockDraggable, didDrop: boolean) => void;
  blockClickHandler: (item:IUserTemplateBlockDraggable) => void;
  activeBlock: IUserTemplateBlockDraggable;
  areaClickHandler: (area:IUserTemplateArea, areaType: EDragArea) => void;
  activeArea: IUserTemplateArea;
}>({
  moveItem: null,
	moveCombinedItem: null,
  onStartDrag: null,
  onEndDrag: null,
  blockClickHandler: null,
  activeBlock: null,
  activeArea: null,
  areaClickHandler: null,
});

export default TemplateAreaContext;
