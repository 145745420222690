import React, { useEffect, useState } from 'react';
import { IOrganization, IAppState, IUser } from '../../../../interfaces';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from '../../../../withRouter';

import { CustomInput, Input, Button } from 'reactstrap';

import FormContainer from '../../../../components/UI/FormContainer/FormContainer';

import * as qs from "query-string";

import { config } from '../../../../constants';

import Pipedrive from './Pipedrive/Pipedrive';
import Hubspot from './Hubspot/Hubspot';
import Netvisor from './Netvisor/Netvisor';
import Severa from './Severa/Severa';
import GoogleDrive from './Google/GoogleDrive';
import Salesforce from './Salesforce/Salesforce';
import Dynamics from './Dynamics/Dynamics';

import SsoSAML from './SsoSAML/SsoSAML';

import classes from "./Integrations.module.scss";

//const REDIRECT_URI="https%3A%2F%2Fintegrations-dot-c2ctest-203405.ey.r.appspot.com%2Fredirect"
//const REDIRECT_URI="http%3A%2F%2Flocalhost%3A8081%2Fredirect"

interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  onGetCurrentUser: () => Promise<void>;
  onGetUserOrganization: () => Promise<IOrganization>;
  onUpdateOrganization: (organization: IOrganization) => void;

}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps { }

const Integrations: React.FC<IProps> = ({
  currentUser,
  organization,
  onGetCurrentUser,
  onGetUserOrganization,
  onUpdateOrganization,

  location,
  history
}) => {

  const [init, setInit] = useState(true);
  const params = qs.parse(location.search);

  useEffect(() => {
    if (init) {
      onGetUserOrganization();

      setInit(false);
    }
    // eslint-disable-next-line
  }, [init]);

  const updateOrganization = async (o:IOrganization) => {
    await onUpdateOrganization(o);
    onGetUserOrganization();
    history.push("/integrations");
  }

  useEffect(() => {
    if(organization && params.token && params.name==="procountor") {

      const newo= {...organization,
        integrations:{
          ...organization.integrations,
          procountor:{
            ...organization.integrations.procountor,
            active:true,
            refreshToken:params.token as string,
          }
        }
      }

      updateOrganization(newo);
    }

    // eslint-disable-next-line
  }, [organization]);

  const integrations=organization && organization.integrations;

  const updateProcountor = (updated:any) => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        procountor:{
          ...organization.integrations.procountor,
          ...updated,
        }  
      }      
    }
    updateOrganization(newo);
  }
    /*
  const updateHubspot = (updated:any) => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        hubspot:{
          ...organization.integrations.hubspot,
          ...updated,
        }  
      }      
    }
    updateOrganization(newo);
  }
     */

  return (
    <React.Fragment>
      <FormContainer title="Integrations">
        {integrations && <div>
          <div className={classes.integration}>
      			<h5>Procountor</h5>
            <CustomInput
              type="switch"
              label="Is Active"
              id="procountorActiveId"
    			  	onChange={(e:any)=>{if(integrations.procountor.active) {updateProcountor({active:false,refreshToken:""})}}}
    				  checked={integrations.procountor.active}
            />
            <CustomInput
              type="switch"
              id="procountorAutoSendId"
              label="Auto send to Procountor when proposal accepted"
    			  	onChange={(e:any)=>updateProcountor({autoSend:!integrations.procountor.autoSend})}
    				  checked={integrations.procountor.autoSend}
            />

            <br/>
            <Button
              size="sm"
              color={integrations.procountor.refreshToken?"success":"warning"}
              href={config.PROCOUNTOR.API_URL+"/login?response_type=code&client_id="+config.PROCOUNTOR.CLIENT_ID+"&redirect_uri="+config.PROCOUNTOR.REDIRECT_URI+"&state=01"}
            >Procountor authentication</Button>
          </div>

          <Pipedrive
            params={params}
            currentUser={currentUser}
            organization={organization}
            updateOrganization={updateOrganization}
          />


          <Hubspot
            params={params}
            currentUser={currentUser}
            organization={organization}
            updateOrganization={updateOrganization}
          />

          <Severa
            params={params}
            currentUser={currentUser}
            organization={organization}
            updateOrganization={updateOrganization}
          />

          <Salesforce
            params={params}
            currentUser={currentUser}
            organization={organization}
            updateOrganization={updateOrganization}
          />

          <Dynamics
            params={params}
            currentUser={currentUser}
            organization={organization}
            updateOrganization={updateOrganization}
          />


          <Netvisor
            params={params}
            currentUser={currentUser}
            organization={organization}
            updateOrganization={updateOrganization}
          />

          <GoogleDrive
            params={params}
            currentUser={currentUser}
            organization={organization}
            updateOrganization={updateOrganization}
          />
          
          <SsoSAML
            params={params}
            currentUser={currentUser}
            organization={organization}
            updateOrganization={updateOrganization}
          />
 
        </div>}
      </FormContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    organization: state.organizations.organization,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
    onGetUserOrganization: () => dispatch(actions.getUserOrganization()),
    onUpdateOrganization: organization =>
      dispatch(actions.updateOrganization(organization)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Integrations);
