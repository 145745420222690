import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IBlockContentPrivate, IProduct } from "../../../../../../../interfaces";

import { ETaxTypes } from "../../../../../../../enums";

import { IBlockContentEditItem } from "../../BlockContentEdit";

import { Label, Container, Row, Col, Input, InputGroup, InputGroupText } from 'reactstrap'

import CustomDialog from "../../../../../../../components/UI/CustomDialog/CustomDialog";


import IconButton from "../../../../../../../components/UI/IconButton/IconButton";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import ReadCSVModal from "../../../../../../../components/ReadCSVModal/ReadCSVModal";

import { readString } from "react-papaparse";

import {
  controlsToFormGroups,
//	defaultInputChangedHandler,
} from "../../../../../../../shared/utility";

//import CustomInput from './CustomInput';
//import CustomInput2 from './CustomInput2';

//mport { calcTotal, calcTotalVat } from './functions';

import classes from "./BlockContentProducts2Edit.module.scss";

//const rndStr = () => (Math.random().toString(36).substring(7));

const BlockContentProductsEdit: React.ForwardRefExoticComponent<IBlockContentEditItem> = forwardRef((props, ref) => {
  const blockContent = props.blockContent as IBlockContentPrivate;
  const [selected, setSelected] = useState<{[id:string]:any}>({liikevaihtoluokka:0, paketti:"basic", palkansaajia:0, henkiloita:0, konserni:false, koulutus:0, calc:{}, ...(blockContent.json && JSON.parse(blockContent.json))} );
  //const [init, setInit] = useState(false);
  //  const [updateProductsKey, setUpdateProductsKey]=useState<string>(rndStr());

	//const [jsonData, setJsonData]=useState<IBlockContentCustom>(blockContent);
	//const [CSVProducts, setCSVProducts]=useState<IProduct[]>([]);

	//const [CSVModal, setCSVModal]=useState(false);
	//const [CSVerror, setCSVerror]=useState("");
	//const [pasteContent, setPasteContent]=useState("");

	//const [searchProduct, setSearchProduct]=useState([...blockContent.products].filter((ob)=>ob.library===true).slice(0,100));
	//const [searchString, setSearchString]=useState("");

	//const [isOpenLibrary, setIsOpenLibrary]=useState(false);

	//const [libraryProducts, setLibraryProducts]=useState<IProduct[]>([]);

  const updateControls = (data:IBlockContentPrivate) => ({
    controls: {
    	/*
			labels:{
            elementType: "input",
            elementConfig: {
              placeholder: "Field text",
              type: "custom"
            },

            touched: false,

						item:data.labels.item,
						quantity:data.labels.quantity,
						unit:data.labels.unit,
						unitPrice:data.labels.unitPrice,
						discount:data.labels.discount,
						vat:data.labels.vat,
						total:data.labels.total,

            custom:CustomInput2,
            
						onChange:inputChangedHandlerDef,
      },
			
			currency:{
					elementType:"input",
					elementConfig:{
						type:"text",
					},
					value:data.currency,
				config:{value:data.currency},
			},

			tax:{
					elementType:"radio",
					elementConfig:{
            options:[
							{value:ETaxTypes.EXCLUSIVE,displayValue:"Exclusive tax"},
							{value:ETaxTypes.INCLUSIVE,displayValue:"Inclusive tax"},
						],
					},
					value:data.tax || ETaxTypes.EXCLUSIVE,
				config:{value:data.tax},
			},

      showTotal:{
					elementType:"input",
					elementConfig:{
            type:"checkbox",
					},
					value:data.showTotal,
  				config:{value:data.showTotal},
      },

      color:{
					elementType:"input",
					elementConfig:{
						type:"color",
					},
					value:data.color,
				config:{value:data.color},

      },

			//products:Object.values(data.products).sort((a:any,b:any) => (a.order_nr>b.order_nr?1:-1)).reduce((resp:any,ob:IProduct,index:number)=>{
			products:Object.values(data.products).reduce((resp:any,ob:IProduct,index:number)=>{
        if(!ob.library) {
          	resp[index]={
          	   elementType: "input",
          	    elementConfig: {
          	      placeholder: "Field text",
          	      type: "custom"
          	    },

                touched: false,
                deleted:ob.deleted || false,

								item: ob.item || "",
								description: ob.description || "",
								quantity: ob.quantity || "",
								unit: ob.unit || "",
								unitPrice: ob.unitPrice || "",
								discount: ob.discount || "",
                vat: ob.vat || "",

                category: ob.category || "",

                order_nr: ob.order_nr || index,

								optional: ob.optional || false,
								optionalSelected: ob.optionalSelected || false,
								quantityEditable: ob.quantityEditable || false,

								math:{
										total:calcTotal(ob.unitPrice, ob.quantity || 0,ob.discount),
										totalVat:calcTotalVat(ob.unitPrice, ob.quantity || 0,ob.discount,ob.vat),
								},

          	    custom:CustomInput,
          	    index:index,

								onItemChange:inputItemChangedHandler,
								onChange:inputChangedHandler,
          	    onDelete:onDeleteHandler,
						}
				}
        return resp
      },{})
    */
    },

  })
/*
  const updateFields = (data:IBlockContentCustom) => {
    setJsonData(data)
	}
	*/

/*
  const inputChangedHandlerCurrency = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
 	 let value = event.target ? event.target.value : event;
		updateFields({...jsonData,currency:value as string})
	};

  const inputChangedHandlerTax = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
 	 let value = event.target ? event.target.value : event;
		updateFields({...jsonData,tax:value as ETaxTypes})
  };

  const myInputChangedHandler = (field:string) => (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string,
  ) => {
 	 let value = event.target ? event.target.value : event;
		updateFields({...jsonData,[field]:value})
  };

  const myInputCheckChangedHandler = (field:string) => (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string,
  ) => {
    let value = event.target && event.target.checked || false;
		updateFields({...jsonData,[field]:value})
  };



	const inputChangedHandlerDef = (name:string, value: any) => {
		const p={...jsonData.labels}
		switch(name) {
			case "total":
			case "quantity":
			case "unitPrice":
			case "discount":
			case "vat":
				p[name]=value as string;
				break;

			case "unit":
			case "description":
			case "item":
				p[name]=value as string;
				break;
			default:
		}
		updateFields({...jsonData,labels:p})
  }
  */
/*
	const librarySearch = (e:any) => {
		const el=e.target as HTMLInputElement
		setSearchString(el.value);
		if(el.value.length==0) {
			const search=jsonData.products.filter((ob)=>ob.library===true).slice(0,100)
			setSearchProduct(search);
		}
		else {/*
			const search=jsonData.products.filter((ob)=>ob.library===true && ob.item.toLowerCase().includes(el.value.toLowerCase()))
			setSearchProduct(search);
		}
	}

	useEffect(()=>{
		setLibraryProducts(jsonData.products.filter((ob)=>ob.library===true))
	},[jsonData.products]);

  const inputItemChangedHandler = (index: number, name:string, value: any) => {
    const p=[...jsonData.products]
    const s={...p[index]}
    s["item"]=value;
    p[index]=s;
		updateFields({...jsonData,products:p})
	}

  const inputChangedHandler = (index: number, name:keyof IProduct, value: any) => {
    const p=[...jsonData.products]
    const s={...p[index]}
		switch(name) {
			case "quantity":
			case "unitPrice":
			case "discount":
			case "vat":

				const v=value.replace(",",".")
				if(!isNaN(v)) {
					s[name]=v; //parseFloat(value);
				}
				break;

			case "description":
			case "unit":
			case "item":
			case "optional":
			case "optionalSelected":
			case "quantityEditable":
				s[name]=value;
				break;
			default:
    }

    p[index]=s;
    
    updateFields({...jsonData,products:p})
    updateControls({...jsonData,products:p})
  }

  const onDeleteHandler = (index: number) => {
		const p=[...jsonData.products]

		//const nr = p.findIndex((x:IProduct)=>x.order_nr == index);    
		//p[nr].deleted=true;
		
    p[index].deleted=true;

    updateFields({...jsonData,products:p})
  }

	const deleteLibrary = () => {
		const p=jsonData.products.filter((ob)=>!ob.library)
		updateFields({...jsonData,products:p})
	}

	const addFromLibrary = () => {
		setIsOpenLibrary(true)
	}
	const addProduct = (product:IProduct) => {
		const p=[...jsonData.products,{...product,library:false}];
		updateFields({...jsonData,products:p})
	}

	const addField = () => {
		const new_order_nr = jsonData.products.length>0?jsonData.products[jsonData.products.length-1].order_nr+1:0;
		const p=[...jsonData.products,
			{item:"",description:"",quantity:1,unit:"pcs",unitPrice:0,discount:0,vat:24, order_nr: new_order_nr}
		];
		updateFields({...jsonData,products:p});
  }
*/
  const getData = () => {
  	/*
    const products=jsonData.products.filter((ob)=>!ob.deleted);

		//const products = jsonData.products.filter((ob)=>!ob.deleted).sort((a:any,b:any) => ((a.order_nr || 0)>(b.order_nr || 0) ? 1:-1));

		const currency=jsonData.currency;
		const tax=jsonData.tax;
    const labels=jsonData.labels;
    const color=jsonData.color;
    const showTotal=jsonData.showTotal;

    return Object.assign(blockContent, {products,currency,tax,labels,color,showTotal});
		*/

	const _x = lisapalvelut(selected.palkansaajia);
	const palkanlaskenta = _x[0];
	const verkkopalkan_lahetys = _x[1][0];
	const toistuvaissuoritukset = _x[1][1];

	const _y = lisapalvelut(selected.henkiloita);
	const matkalaskut = _y[0];

	let paketti = 0;
	let konserni = 0;
	let avaus = 0;
	try { paketti = customJson.paketit[selected.paketti][selected.liikevaihtoluokka]; } catch(err) {}
	try { konserni = selected.konserni?customJson.liikevaihtoluokat[selected.liikevaihtoluokka][2]:0; } catch(err) {}
	try { avaus = selected.paketti==="palkat"?0:customJson.liikevaihtoluokat[selected.liikevaihtoluokka][1]; } catch(err) {}
	
	const calc = {
		palkanlaskenta,
		verkkopalkan_lahetys,
		toistuvaissuoritukset,
		matkalaskut,
		paketti:paketti, //customJson.paketit[selected.paketti][selected.liikevaihtoluokka],
		konserni:konserni, //selected.konserni?customJson.liikevaihtoluokat[selected.liikevaihtoluokka][2]:0
		avaus:avaus, //selected.paketti==="palkat"?0:customJson.liikevaihtoluokat[selected.liikevaihtoluokka][1],
		koulutus:selected.koulutus,
//			(selected.koulutus&1)===1?customJson.koulutus[0]:0+
//			(selected.koulutus&2)===2?customJson.koulutus[1]:0+
//			(selected.koulutus&4)===4?customJson.koulutus[2]:0+
//			(selected.koulutus&8)===8?customJson.koulutus[3]:0
	}

		return Object.assign(blockContent, {json: JSON.stringify({...selected,calc}), settings: JSON.stringify(customJson)});
	}

	const [controlsState, setControlsState] = useState(null) //updateControls(jsonData))


  useImperativeHandle(ref, () => ({
    getData
  }));
  
  
  useEffect(() => {
  		//setControlsState(updateControls({...blockContent,json:JSON.stringify(selected)}))
  	if(props.onChange) {
			props.onChange(getData())
		}
/*
		  if(props.onChange && init) {
        props.onChange(getData())
      } 
      */
    // eslint-disable-next-line
  }, [selected]);
  

/*
  useEffect(()=>{
    if(JSON.stringify(blockContent.products)!==JSON.stringify(jsonData.products)) {
      //clear defaultValues from input fields
      setControlsState(updateControls({...jsonData,products:[]}));

			//const sortedProducts = Object.values(blockContent.products).sort((a:any,b:any) => ((a.order_nr || 0)>(b.order_nr || 0) ? 1:-1));
			//setJsonData({...jsonData,products:sortedProducts});
      setJsonData({...jsonData,products:[...blockContent.products]});


      //setUpdateProductsKey(rndStr());

    }
  },[blockContent.products]);
*/

/*
	useEffect(() => {
		if(!init) {
			setInit(true);
			setControlsState(updateControls(jsonData));
		}
  }, [])
  */
/*
	const importCSVFile = () => {
		setCSVModal(true)
	}
	
	const saveFile = () => {
		setJsonData({...jsonData,products:[...jsonData.products,...CSVProducts]})

		const search=[...jsonData.products,...CSVProducts].filter((ob)=>ob.library===true).slice(0,100)
		setSearchProduct(search);
	}

	const parseFloat2 = (f:any) => {
		try {
			return parseFloat(f.replace(",","."))
		} catch(err) {
			return parseFloat(f)
		}
	}

	const handleFile = (csv_content:string, save_instantly:boolean=false) => {
		const d=readString(csv_content,{header:true, skipEmptyLines:true});
		//console.log(d.data);
		const tempdata:Array<IProduct>=[];

		d.data.forEach((ob,index)=>{
			//	Active: "Yes"
			//	Category: "kategoria1"
			//	Description: ""
			//	ID: "1"
			//	Name: "tuote1"
			//	Owner: "Seppo Tillanen"
			//	Price (EUR): "12"
			//	Product code: "123"
			//	Tax: "24"
			//	Unit: "kpl"
			//	Unit prices: "12 EUR"
			//	Visible to: "owner's visibility group"
				if(index<1000-jsonData.products.filter((ob)=>ob.library).length) {
          //					tempdata.push({library:true,item:(ob["Product code"]?ob["Product code"]+" ":"")+ob.Name+(ob["Description"]?"\n"+ob["Description"]:""),description:"",quantity:1,unit:ob.Unit,unitPrice:parseFloat2(ob.Price),discount:0,vat:parseFloat2(ob.Tax)})
          tempdata.push({library:true,item:(ob["Product code"]?ob["Product code"]+" ":"")+ob.Name,description:ob["Description"],quantity:1,unit:ob.Unit,unitPrice:parseFloat2(ob.Price),discount:0,vat:parseFloat2(ob.Tax)})

				}
				if(ob["Add"]>0) {
          //         tempdata.push({library:false,item:(ob["Product code"]?ob["Product code"]+" ":"")+ob.Name+(ob["Description"]?"\n"+ob["Description"]:""),description:"",quantity:parseFloat2(ob["Add"]),unit:ob.Unit,unitPrice:parseFloat2(ob.Price),discount:0,vat:parseFloat2(ob.Tax)})
					tempdata.push({library:false,item:(ob["Product code"]?ob["Product code"]+" ":"")+ob.Name,description:ob["Description"],quantity:parseFloat2(ob["Add"]),unit:ob.Unit,unitPrice:parseFloat2(ob.Price),discount:0,vat:parseFloat2(ob.Tax)})

				}

			//console.log(ob);
		})

		//		updateFields({...jsonData,products:[...jsonData.products,...tempdata]})
		setCSVProducts(tempdata);

		if(tempdata.length<d.data.length) {
			setCSVerror("total products in file "+d.data.length+" limited to 1000 items")
		}
		else {
			setCSVerror("")
		}

		if(save_instantly) {
			const p=[...jsonData.products,...tempdata];
			updateFields({...jsonData,products:p})
		}

	}

  let blurHandler:any = null;
    if(props.onBlur) {
			blurHandler = () => {
        props.onBlur(getData());
      }
    }

	if(!controlsState) return null;

	let total=0;
	let totalVat=0;
	const totals=Object.values(controlsState.controls.products).reduce((resp:any,ob:any)=>{
		if(!ob.library && !ob.deleted && (!ob.optional || ob.optionalSelected)) {
			if(controlsState.controls.tax.value===ETaxTypes.INCLUSIVE) {
				const t=ob.math.total-ob.math.total/(1+ob.vat/100);
				resp[ob.vat]=(resp[ob.vat] || 0) + t;
				total+=ob.math.total-t;
				totalVat+=t;
			}
			else {
				const t=ob.math.total*ob.vat/100;
				resp[ob.vat]=(resp[ob.vat] || 0) + t;
				total+=ob.math.total;
				totalVat+=t;
			}
		}
		return resp
	},{})


	const labels=controlsState.controls.labels;
*/

  const customJson:{[id:string]:any} = blockContent.settings && JSON.parse(blockContent.settings) || {
		liikevaihtoluokat: [
/*			
        ["alle 0.1",40,24],
        ["0.1-0.2",60,24],
        ["0.2-0.5",90,38],
        ["0.5-1",140,53],
        ["1-2",190,84],
        ["2-5",250,123],
        ["5-10",320,169],
        ["10-25",400,199],
        ["25-50",490,249],
        ["50-100",590,299],
        ["yli 100",990,399],
*/        
		],

		paketit: {
/*			
        "basic":[18,35,55,null,null,null,null,null,null,null,null],
        "core":[45,65,105,165,255,395,575,795,1075,1475,2200],
        "professional":[70,95,145,220,330,500,750,1025,1375,1850,2900],
        "premium":[105,130,180,265,385,590,875,1175,1650,2090,3190],
        "palkat":[10,10,10,10,10,10,10,10,10,10,10],
*/        
		},

		palkkarajat: [
/*			
        [   5,  6,0.73,0.51],        
        [  25,3.5,0.73,0.51],        
        [ 100,3.5,0.57,0.43],
        [ 200,2.5,0.49,0.41],
        [ 300,  2,0.49,0.41],
        [ 500,  2,0.41,0.33],
        [1000,  2,0.34,0.27],
        [null,  2,0.31,0.24],
*/        
		],

		koulutus: [
			//1600,2880,350,{0:480,10:840,30:1400,60:2240,100:3500}
			],

	};


	const lisapalvelut = (lukum:number):any => {
	  let m=lukum;
	  let t = 0;
	  let t2 = [0,0];
	  let prev = 0;
	  customJson.palkkarajat.forEach((p:any) => {
	    let u = p[0] === null?m:Math.min(m,p[0]-prev);
	    t+=u*p[1];

	    if(u>0) {
	      t2[0]=Math.round(p[2]*lukum*100)/100;
	      t2[1]=Math.round(p[3]*lukum*100)/100;
	    }
	    m-=u;
	    if(p[0]) {
        prev = p[0];
      }
	    else {
      	prev = 0;
      }
	  });

	  return [Math.round(t*100)/100,t2];
	}

/*

p1 = paketit[pak][lv]
p2,p2_2 = lisapalvelut(palkansaajia)
p3,p3_2 = lisapalvelut(matka)
p4 = liikevaihtoluokat[lv][1]

k=0
if konserni:
        k=liikevaihtoluokat[lv][2]
        print("konserni",k)
print("paketti",pak,p1)


print("palkanlaskenta",p2)
print("matka ja kulu ",p3)

print("verkkopalkan lähetys",p2_2[0]*palkansaajia)
print("toistuvaissuoritukset",p2_2[1]*palkansaajia)

print("yhteensä",p1+p2+p3+(p2_2[0]+p2_2[1])*palkansaajia+k)

print("+avaus",p4)

*/


	const onChangeSelected = (t:string, e:any) => {
		
		if(t==="liikevaihtoluokka") {
			let p=selected.paketti;
			if(p === "basic" && customJson.paketit[p][e] === null) { p="core";}
			setSelected({...selected, liikevaihtoluokka:e, paketti:p});
		}
		else if(t==="paketti") {
			let kf = 15;
			if(e!=="premium") { kf = 15-4; }			

			setSelected({...selected, paketti:e, koulutus:selected.koulutus&kf});	
		}
		else if(t==="palkansaajia") {
			let kf = 15;
			if(e===0) { kf = 15-8; }			
			setSelected({...selected, palkansaajia:e, koulutus:selected.koulutus&kf});	
		}
		else if(t==="henkiloita") {
			setSelected({...selected, henkiloita:e});	
		}
		else if(t==="konserni") {
			setSelected({...selected, konserni:e});	
		}
		else if(t==="koulutus") {
			setSelected({...selected, koulutus:e});	
		}

	}
/*
	useEffect(()=>{
		setJsonData({...blockContent, json:JSON.stringify(selected)});
	},[selected]);
*/
  return (
		<React.Fragment>
			<Label>{blockContent.name}</Label>

{/*
			<Row>
				<Col xs={3}>
					Currency
				</Col>
				<Col>
		      {controlsToFormGroups({currency:controlsState.controls.currency}, inputChangedHandlerCurrency, blurHandler)}
				</Col>
				<Col>
		      {controlsToFormGroups({tax:controlsState.controls.tax}, inputChangedHandlerTax, blurHandler)}
				</Col>

			</Row>

      {controlsToFormGroups({labels:controlsState.controls.labels}, null, blurHandler)}

      {controlsToFormGroups(controlsState.controls.products, null, blurHandler)}



      <Row>
        <Col style={{display:"flex"}}>
         <IconButton
          style={{ marginTop: "1rem", fontWeight: 500 }}
          color="light"
          icon={faPlus}
          onClick={()=>addField()}
        >
          Add a new line
        </IconButton>

				{libraryProducts.length>0 &&
					<IconButton
	          style={{ marginLeft: "1rem", marginTop: "1rem", fontWeight: 500 }}
          	color="light"
        	  icon={faPlus}
      	    onClick={()=>addFromLibrary()}
    	    >
  	        Add from library ({libraryProducts.length})
				</IconButton>}

				</Col>
      </Row>


  		<Row>
				<Col className="text-right">{labels.total} ({labels.vat} 0%)</Col>
				<Col xs={2} className="text-right">
					{total.toFixed(2)}
				</Col>
				<Col xs={1}>{controlsState.controls.currency.config.value}</Col>
			</Row>

			{Object.keys(totals).map((key:any)=>{
				const ob=totals[key];
				return(<Row key={key}>
					<Col className="text-right">{labels.vat} {key}%</Col>
					<Col xs={2} className="text-right">{ob.toFixed(2)}</Col>
					<Col xs={1}>{controlsState.controls.currency.config.value}</Col>
				</Row>)
			})}
  		
			<Row>
				<Col className="text-right">{labels.total}</Col>
				<Col xs={2} className="text-right">
					{(total+totalVat).toFixed(2)}
				</Col>
				<Col xs={1}>{controlsState.controls.currency.config.value}</Col>
      </Row>
      <hr/>
			<Row>
				<Col xs={6}>
          Header and grid Color
		      {controlsToFormGroups({color:controlsState.controls.color}, myInputChangedHandler("color"), blurHandler)}

				</Col>
        <Col xs={6} className="text-right">
          <div>
          Show Totals
          <div style={{"marginLeft":"1.25rem"}}>
            {controlsToFormGroups({showTotal:controlsState.controls.showTotal}, myInputCheckChangedHandler("showTotal"), blurHandler)}
          </div>
        </div>

				</Col>

			</Row>



      <Row>
        <Col style={{display:"flex"}}>

			{libraryProducts.length>0 &&
					<IconButton
	          style={{ marginRight: "1rem", marginTop: "1rem", fontWeight: 500 }}
          	color="light"
        	  icon={faTrash}
      	    onClick={()=>deleteLibrary()}
    	    >
  	        Delete library ({libraryProducts.length})
	        </IconButton>}

      <IconButton
          style={{ marginTop: "1rem", fontWeight: 500 }}
          color="light"
          icon={faPlus}
          onClick={()=>importCSVFile()}
      >
          Import Products from CSV -file
			</IconButton>
			</Col>
			<Col>
				<Input type="textarea" onChange={(evt)=>setPasteContent(evt.target.value)} value={pasteContent}/>

			</Col>
			<IconButton onClick={()=>{handleFile(pasteContent, true);}} icon={faPlus} color="light">import</IconButton>
		</Row>



			<ReadCSVModal
				showModal={CSVModal}
				toggleModal={()=>{setCSVModal(false);setCSVProducts([])}}
				handleFile={handleFile}
				saveFile={saveFile}
				data={CSVProducts}
				csverror={CSVerror}
			/>

			<CustomDialog
				open={isOpenLibrary}
				onCancel={()=>setIsOpenLibrary(false)}
				title={"Product library"}
			>
				<Input
					onChange={librarySearch}
					value={searchString}
					placeholder="Search products..."
				/>
					{searchProduct.map((ob)=>{
					return(<div className={classes.libraryItem} key={ob.item} onClick={()=>addProduct(ob)}>{ob.item}</div>)
				})}

			</CustomDialog>



*/}
  
  	<Container>
  	{/*JSON.stringify(selected)*/}
  	<div>
	  	<h5>Valitse yrityksen koko</h5>
	  	<Input type="select" value={selected.liikevaihtoluokka} onChange={(e:any)=>onChangeSelected("liikevaihtoluokka", parseInt(e.target.value))}>
	  		{customJson.liikevaihtoluokat.map((lv:any, index:number) => (
	  				<option key={index} value={index}>{lv[0]}</option>
	  			))}
	  	</Input>
  	</div>

  	<div>
	  	<h5>Valitse sopiva paketti</h5>
	  	<Input type="select" value={selected.paketti} onChange={(e:any)=>onChangeSelected("paketti", e.target.value)}>
	  		{Object.keys(customJson.paketit).map((key:string) => (
	  				<option key={key} value={key} disabled={customJson.paketit[key][selected.liikevaihtoluokka] === null}>{key}</option>
	  			))}
	  	</Input>
  	</div>

		<h5>Valitse tarvittavat lisäpalvelut</h5>
		<Row>
  	<Col>
	  	<b>Palkanlaskenta ja<br/> työajankirjaus</b>
	  	<Input type="number" value={selected.palkansaajia} onChange={(e:any)=>onChangeSelected("palkansaajia", parseInt(e.target.value))}/>
	  	Palkansaajaa
  	</Col>

  	<Col>
	  	<b>Matka- ja<br/> kululaskut</b>
	  	<Input type="number" value={selected.henkiloita} onChange={(e:any)=>onChangeSelected("henkiloita", parseInt(e.target.value))}/>
	  	Henkilöä
  	</Col>

  	<Col>
	  	<b>Konsernikäsittely</b><br/><br/>

  	<InputGroup>
  			<InputGroupText>
  				<Input addon type="checkbox" checked={selected.konserni} onChange={(e:any)=>onChangeSelected("konserni", e.target.checked)}/>
  			</InputGroupText>
  	</InputGroup>

  	</Col>
  	</Row>

		<h5>Valitse käyttöönottokoulutus</h5>
		<Row>
	  	<Col>
		  	
		  	<InputGroup>
		  			<InputGroupText>
		  				<Input disabled={selected.paketti==="palkat"} addon type="checkbox" checked={(selected.koulutus&1)===1} onChange={(e:any)=>onChangeSelected("koulutus", !e.target.checked?(selected.koulutus&(31-1)):((selected.koulutus|1)&31))}/>
		  			</InputGroupText>
		  			<InputGroupText>
		  				Perus käyttöönottokoulutus
		  			</InputGroupText>
		  	</InputGroup>

		  	<InputGroup>
		  			<InputGroupText>
		  				<Input disabled={selected.paketti==="palkat"} addon type="checkbox" checked={(selected.koulutus&2)===2} onChange={(e:any)=>onChangeSelected("koulutus", !e.target.checked?(selected.koulutus&(31-2)):((selected.koulutus|2)&31))}/>
		  			</InputGroupText>
		  			<InputGroupText>
		  				Laaja käyttöönottokoulutus
		  			</InputGroupText>
		  	</InputGroup>

		  	<InputGroup>
		  			<InputGroupText>
		  				<Input disabled={selected.paketti!=="premium"} addon type="checkbox" checked={(selected.koulutus&4)===4} onChange={(e:any)=>onChangeSelected("koulutus", !e.target.checked?(selected.koulutus&(31-4)):((selected.koulutus|4)&31))}/>
		  			</InputGroupText>
		  			<InputGroupText>
							Laaja + varasto käyttöönottokoulutus
		  			</InputGroupText>
		  	</InputGroup>

		  	<InputGroup>
		  			<InputGroupText>
		  				<Input disabled={selected.palkansaajia===0} addon type="checkbox" checked={(selected.koulutus&8)===8} onChange={(e:any)=>onChangeSelected("koulutus", !e.target.checked?(selected.koulutus&(31-8)):((selected.koulutus|8)&31))}/>
		  			</InputGroupText>
		  			<InputGroupText>
		  				Palkanlaskennan koulutus
		  			</InputGroupText>
		  	</InputGroup>

		  	<InputGroup>
		  			<InputGroupText>
		  				<Input disabled={!selected.konserni} addon type="checkbox" checked={(selected.koulutus&16)===16} onChange={(e:any)=>onChangeSelected("koulutus", !e.target.checked?(selected.koulutus&(31-16)):((selected.koulutus|16)&31))}/>
		  			</InputGroupText>
		  			<InputGroupText>
		  				Konsernikäsittely -koulutus
		  			</InputGroupText>
		  	</InputGroup>

	  	</Col>
  	</Row>



  	</Container>
  	

    </React.Fragment>
  );
});

  export default BlockContentProductsEdit;
