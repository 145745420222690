import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { IContentTypeItemType } from "../../../../../../../interfaces";

interface IProps extends IContentTypeItemType {}

const ContentTypeItemAttachment: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ setIsValid }, ref) => {
  
  useEffect(() => {
    setIsValid(true);
  }, [setIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return null;
    }
  }));

  return null;
});

export default ContentTypeItemAttachment;
