import React, { useContext } from 'react';
import { IBlockItem, IUserTemplateAreaBlock } from '../../../../../../../../../interfaces';
import OfferContext from '../../../../../../../containers/Offers/Offer/OfferContext';
import BlockItemEdit from '../../../../../../Blocks/Block/BlockItems/BlockItemEdit/BlockItemEdit';

import classes from "./OfferContentItemDynamicEdit.module.scss";


interface IProps {
  block: IUserTemplateAreaBlock;
}

const OfferContentItemDynamicEdit:React.FC<IProps> = ({ block }) => {
  const {
    onBlockItemChanged
  } = useContext(OfferContext);

  
	const blurHandler = (blockItem: IBlockItem) => {
    onBlockItemChanged(block.id, blockItem);
  }


  if(!block.offerBlockItems || !block.offerBlockItems.length) return null;

  return (
    <div className={classes.Container}>
      {
        block.offerBlockItems && block.offerBlockItems.map(blockItem => {
          if(blockItem) {
          return (
            <div className={classes.Item} key={blockItem.id}>
              <h5 className={classes.ItemTitle}>{blockItem.name}</h5>
              <BlockItemEdit
                blockId={block.blockId}
                blockItemId={null}
                blockItemProp={
                  { ...blockItem }
                }
                onBlur={blurHandler}
                showTitle={false}
                containerClassName={classes.FormContainer}
              />
            </div>
          )
        }
        return null
        })
      }
    </div>
  )
}

export default OfferContentItemDynamicEdit;
