import { Dispatch } from "redux";
import axios from "../../axios";
import { IUserTemplate, IUserTemplateAction } from "../../interfaces";
import { ActionTypes } from "./actionTypes";

/**
 * User Template
 */

export const findUserTemplatesStart = (): IUserTemplateAction => {
  return {
    type: ActionTypes.FIND_USER_TEMPLATES_START
  };
};

export const findUserTemplatesSuccess = (userTemplates: Array<IUserTemplate>): IUserTemplateAction => {
  return {
    type: ActionTypes.FIND_USER_TEMPLATES_SUCCESS,
    userTemplates
  };
};

export const findUserTemplatesFail = (error: string): IUserTemplateAction => {
  return {
    type: ActionTypes.FIND_USER_TEMPLATES_FAIL,
    error
  };
};

export const findUserTemplates = (params: string = "") => {
  return async (dispatch: Dispatch) => {
    dispatch(findUserTemplatesStart());
    try {
      const res = await axios.get(`/json/usertemplates/list?${params}`);
      const userTemplates = res.data;
      dispatch(findUserTemplatesSuccess(userTemplates));
      return userTemplates;
      //@ts-ignore
    } catch (error:any) {
      dispatch(findUserTemplatesFail(error.response.data));
    }
  };
};

export const getUserTemplateStart = (): IUserTemplateAction => {
  return {
    type: ActionTypes.GET_USER_TEMPLATE_START
  };
};

export const getUserTemplateSuccess = (userTemplate: IUserTemplate): IUserTemplateAction => {
  return {
    type: ActionTypes.GET_USER_TEMPLATE_SUCCESS,
    userTemplate
  };
};

export const getUserTemplateFail = (error: string): IUserTemplateAction => {
  return {
    type: ActionTypes.GET_USER_TEMPLATE_FAIL,
    error
  };
};

export const getUserTemplate = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getUserTemplateStart());
    try {
      const res = await axios.get(`/json/usertemplates/get?id=${id}`);
      const userTemplate = res.data;
      dispatch(getUserTemplateSuccess(userTemplate));
      //@ts-ignore
    } catch (error:any) {
      dispatch(getUserTemplateFail(error.response.data));
    }
  };
};

export const saveUserTemplateStart = (): IUserTemplateAction => {
  return {
    type: ActionTypes.SAVE_USER_TEMPLATE_START
  };
};

export const saveUserTemplateSuccess = (): IUserTemplateAction => {
  return {
    type: ActionTypes.SAVE_USER_TEMPLATE_SUCCESS
  };
};

export const saveUserTemplateFail = (error: string): IUserTemplateAction => {
  return {
    type: ActionTypes.SAVE_USER_TEMPLATE_FAIL,
    error
  };
};

export const saveUserTemplate = (userTemplate: IUserTemplate) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveUserTemplateStart());
    try {
      await axios.post("/json/usertemplates/save", userTemplate);
      dispatch(saveUserTemplateSuccess());
      return true;
      //@ts-ignore
    } catch (error:any) {
      dispatch(saveUserTemplateFail(error.response.data));
    }
  };
};

export const updateUserTemplateStart = (): IUserTemplateAction => {
  return {
    type: ActionTypes.UPDATE_USER_TEMPLATE_START
  };
};

export const updateUserTemplateSuccess = (): IUserTemplateAction => {
  return {
    type: ActionTypes.UPDATE_USER_TEMPLATE_SUCCESS
  };
};

export const updateUserTemplateFail = (error: string): IUserTemplateAction => {
  return {
    type: ActionTypes.UPDATE_USER_TEMPLATE_FAIL,
    error
  };
};

export const updateUserTemplate = (id: string, userTemplate: IUserTemplate) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserTemplateStart());
    try {
      await axios.put(`/json/usertemplates/update?id=${id}`, userTemplate);
      dispatch(updateUserTemplateSuccess());
      return true;
      //@ts-ignore
    } catch (error:any) {
      dispatch(updateUserTemplateFail(error.response.data));
    }
  };
};

export const deleteUserTemplateStart = (): IUserTemplateAction => {
  return {
    type: ActionTypes.DELETE_USER_TEMPLATE_START
  };
};

export const deleteUserTemplateSuccess = (): IUserTemplateAction => {
  return {
    type: ActionTypes.DELETE_USER_TEMPLATE_SUCCESS
  };
};

export const deleteUserTemplateFail = (error: string): IUserTemplateAction => {
  return {
    type: ActionTypes.DELETE_USER_TEMPLATE_FAIL,
    error
  };
};

export const deleteUserTemplate = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteUserTemplateStart());
    try {
      await axios.delete(`/json/usertemplates/delete?id=${id}`);
      dispatch(deleteUserTemplateSuccess());
      //@ts-ignore
    } catch (error:any) {
      if (error.response) {
        dispatch(deleteUserTemplateFail(error.response.data));
      }
    }
  };
};

export const copyUserTemplateStart = (): IUserTemplateAction => {
  return {
    type: ActionTypes.COPY_USER_TEMPLATE_START
  };
};

export const copyUserTemplateSuccess = (): IUserTemplateAction => {
  return {
    type: ActionTypes.COPY_USER_TEMPLATE_SUCCESS
  };
};

export const copyUserTemplateFail = (error: string): IUserTemplateAction => {
  return {
    type: ActionTypes.COPY_USER_TEMPLATE_FAIL,
    error
  };
};

export const copyUserTemplate = (userTemplate: IUserTemplate) => {
  return async (dispatch: Dispatch) => {
    dispatch(copyUserTemplateStart());
    try {
      const res = await axios.post(`/json/usertemplates/copy`, userTemplate);
      dispatch(copyUserTemplateSuccess());
      return res.data as IUserTemplate;
      //@ts-ignore
    } catch (error:any) {
      if (error.response) {
        dispatch(copyUserTemplateFail(error.response.data));
      }
    }
  };
};

