import React, { useContext } from "react";
import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";
import { IOrganization, ICustomer } from "../../../../../../../interfaces";

import classes from "./BlockContentDealBetweenShow.module.scss";

interface IProps {
  showDummyContent: boolean;
}

const BlockContentDealBetweenShow: React.FC<IProps> = ({
  showDummyContent
}) => {
  const { customer, organization } = useContext(OfferPreviewContext);

  const renderOrganization = (organization: IOrganization) => {
    if (!organization) return null;

    const pa1=((organization.zip || "")+" "+(organization.city || "")).trim()

    return (
      <div>
        <p className={classes.Name}>
          {organization.name}
        </p>
        <p className={classes.ar}>{organization.businessID}</p>
        <p className={classes.ar}>
          {organization.streetAddress}{pa1?",":""} <span style={{"whiteSpace":"nowrap"}}>{pa1}</span>
        </p>
        <p className={classes.ar}>
          {organization.contactTitle} {organization.contactPerson}
        </p>
        <p className={classes.ar}>{organization.contactPhone}</p>
        <p className={classes.ar}>{organization.contactEmail}</p>
      </div>
    );
  };

  const renderCustomer = (customer: ICustomer) => {
    if (!customer) return null;

    const pa2=((customer.zip || "")+" "+(customer.city || "")).trim()

    return (
      <div>
        <p className={classes.Name}>
          {customer.name}
        </p>
        <p className={classes.ar}>{customer.businessID}</p>
        <p className={classes.ar}>
          {customer.address}{pa2?",":""} <span style={{"whiteSpace":"nowrap"}}>{pa2}</span>
        </p>
        <p className={classes.ar}>{customer.contactPerson}</p>
        <p className={classes.ar}>{customer.phone}</p>
        <p className={classes.ar}>{customer.email}</p>
      </div>
    );
  };

  return (
    <div className={classes.Container}>
      <div className={classes.Organization}>
        {renderOrganization(organization)}
      </div>
      <div className={[classes.Divider, "customColor-bg"].join(" ")}></div>
      <div className={classes.Customer}>{renderCustomer(customer)}</div>
    </div>
  );
};

export default BlockContentDealBetweenShow;
