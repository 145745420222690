import React, { useEffect, useState } from 'react';

import ReactGA from "react-ga";

import { connect } from 'react-redux';
//import { RouteComponentProps } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { RouteComponentProps } from '../../withRouter';
import { Alert, Button, Form } from 'reactstrap';

import ListSpinner from '../../components/UI/ListSpinner/ListSpinner';
import { IAppState } from '../../interfaces';
import { controlsToFormGroups, defaultInputChangedHandler } from '../../shared/utility';
import * as actions from '../../store/actions';

import classes from "./Auth.module.scss";

interface IStateProps {
  redirect?: string | null;
  isAuthenticated: boolean;
  error?: string | null;
  loading?: boolean;
}

interface IDispatchProps {
  onAuth: (account: string, password: string) => {};
  onClearError: () => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const Auth = (props: IProps) => {

  const { isAuthenticated, history, redirect } = props;
  
  const [controls, setControls] = useState({
    controls: {
      email: {
        elementType: "login",
        elementConfig: {
          placeholder: "Username",
					type: "email",
        },
        validation: {
          required: true,
          email: true
        },
        valid: false,
        touched: false,
        value: ""
      },
      password: {
        elementType: "login",
        elementConfig: {
          placeholder: "Password",
					type: "password",
        },
        validation: {
          password: true,
          required: true,
          minLength: 8
        },
        valid: false,
        touched: false,
        value: ""
      }
    },
    formIsValid: false
  });

  useEffect(() => {
    document.body.classList.add("login-bg");
    return () => {
      document.body.classList.remove("login-bg");
    };
  }, []);


  useEffect(() => {
    if (isAuthenticated) {
      if (redirect) {
        history.push(redirect);
      } else {
        history.push("/dashboard");
      }
    }    
  }, [isAuthenticated, redirect, history]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(event, controlName, controls, setControls);
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password } = controls.controls;
    const { onAuth } = props;

    onAuth(email.value, password.value);
  };

  const { error, loading } = props;

  useEffect(() => {
    if(error) {
      const e401 = error.split("::");
      if( e401[0]==="401" ) {
        console.log("ERROR",error);
        window.location.href=e401[1];
      }
    }
  },[error]);

  const createNewAccount = () => {
    ReactGA.event({
      category: 'User',
      action: 'Start registration'
    });

    history.push('/register');
  }

  return (
    <div className={classes.Container}>
      <div className={classes.LoginBox}>
        <div className={classes.Title}>Login to C2C</div>
        {(loading && <ListSpinner />) || (
          <Form onSubmit={submitHandler}>
            <div className={classes.Inputs}>
              {controlsToFormGroups(controls.controls, inputChangedHandler)}
            </div>
            <div className={classes.ButtonContainer}>
              <Button type="submit" color="primary" className={classes.Button}>
                Login
              </Button>
            </div>
            {error && (
              <Alert className={classes.ErrorMessage} color="danger">
                {error}
              </Alert>
            )}
          </Form>
        )}
      </div>
      <div className={classes.LinksContainer}>
        <div>
          <Button color="link" onClick={createNewAccount} >Create new account</Button>
        </div>
        <div className={classes.Border} />
        <div>
          <Button color="link" onClick={() => history.push('/forgot')}>Forgot password?</Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.currentUser !== null,
    redirect: state.auth.redirect
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onAuth: (account: string, password: string) =>
      dispatch(actions.auth(account, password)),
    onClearError: () => dispatch(actions.clearError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
