import React from 'react';

import { IBlockContent, IUserTemplateAreaBlock } from '../../../../../../../../interfaces';
import { EContentType } from '../../../../../../../../enums';
import BlockContentEdit from '../../../../../Blocks/Block/BlockContentEdit';

interface IProps {
  block: IUserTemplateAreaBlock;
  onChange: (block: IUserTemplateAreaBlock, blockContent: IBlockContent) => void;
}

const OfferContentItemSimple: React.FC<IProps> = ({ block, onChange}) => {
  if(!block || !block.content) return null;
  return (
    <div>
      {block.content.map(blockContent => {
        return (
          <BlockContentEdit
            ref={null}
            blockContent={blockContent}
            key={blockContent.id}
            onBlur={(blockContent: IBlockContent)=>onChange(block, blockContent)}
            onChange={(blockContent: IBlockContent) => {
              //console.log(blockContent.contentType);
/*              
  CONTENT = 'CONTENT',
  IMAGE = 'IMAGE',
  VISUAL = 'VISUAL',
  CUSTOM = 'CUSTOM',
  PRICING = 'PRICING',
  DEAL_BETWEEN = 'DEAL_BETWEEN',
  CSV = 'CSV',
  PDF = 'PDF',
  DYNAMIC = 'DYNAMIC',
  DELETE = 'DELETE',
  TEAM_MEMBER = 'TEAM_MEMBER',
  LEGAL_BOX = 'LEGAL_BOX',
  ATTACHMENT = 'ATTACHMENT',
  SIGNATURE = 'SIGNATURE',
  FIELDS = 'FIELDS',
  PRODUCTS = 'PRODUCTS',
  SIGNATURES = 'SIGNATURES',
  */
              const changeList:Array<EContentType> = [
                EContentType.IMAGE,
                EContentType.ATTACHMENT,
                EContentType.VISUAL,
              ];

              if(changeList.includes(blockContent.contentType)) {
                onChange(block, blockContent);
              }
            }}            
          />
        );
      })}
    </div>
  );
};

export default OfferContentItemSimple;
