import React, { useState, useEffect } from "react";
import CustomDialog from "../../../../../../components/UI/CustomDialog/CustomDialog";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData
} from "../../../../../../shared/utility";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  defaultSenderName: string;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (data?: IOfferDeclineDialogData) => void;
  onDelete?: (id: string) => void;
}

export interface IOfferDeclineDialogData {
  senderName: string;
  message: string;
}

const OfferDeclineDialog: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  onSave,
  defaultSenderName
}) => {
  const { t } = useTranslation();
  const [controlsState, setControlsState] = useState({
    controls: {
      senderName: {
        elementType: "input",
        elementConfig: {
          placeholder: "",
          type: "text"
        },
        valid: true,
        touched: false,
        value: defaultSenderName ? defaultSenderName : ''
      },
      message: {
        elementType: "textarea",
        elementConfig: {
          placeholder: ""
        },
        valid: true,
        touched: false,
        value: ""
      }
    },
    formIsValid: false
  });

  const [init, setInit] = useState(true);

  const closeDialog = () => {
    setIsOpen(false);
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) =>
    defaultInputChangedHandler(
      event,
      controlName,
      controlsState,
      setControlsState
    );

  const onOk = () => {
    const data = getFormData(controlsState.controls);
    onSave(data);
    closeDialog();
  };

  // Translate ei toimi jos laittaa suoraan controlleihin
  useEffect(() => {
    if(isOpen && init) {
      setControlsState({
        ...controlsState, controls:
        {
          senderName: {
            ...controlsState.controls.senderName,
            elementConfig: {
              ...controlsState.controls.senderName.elementConfig,
              placeholder: t("offerAcceptSidebar.name")
            }
          },
          message: {
            ...controlsState.controls.message,
            elementConfig: {
              ...controlsState.controls.message.elementConfig,
              placeholder: t("offerAcceptSidebar.addComment")
            }
          },
        }
      }
      );
      setInit(false);
    }
    // eslint-disable-next-line
  }, [isOpen])

  return (
    <CustomDialog
      loading={false}
      onOk={onOk}
      onCancel={closeDialog}
      open={isOpen}
      title={t('offerDeclineDialog.title')}
      okButtonText={t('offerDeclineDialog.save')}
      cancelButtonText={t('offerDeclineDialog.discard')}
    >
      <p>{t('offerDeclineDialog.content')}</p>
      {controlsToFormGroups(controlsState.controls, inputChangedHandler)}
    </CustomDialog>
  );
};

export default OfferDeclineDialog;
