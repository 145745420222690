import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { EContentType, EDropzoneType } from '../../../enums';
import { IAppState, ICustomer, IUser } from '../../../interfaces';
import { controlsToFormGroups, getApiPath } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import ListSpinner from '../../UI/ListSpinner/ListSpinner';

import classes from "./ImportCustomersModal.module.scss";

interface IStateProps {
  currentUser: IUser | null;
  customersImport: Array<ICustomer>;
  error: string;
  loading: boolean;
}

interface IDispatchProps {
  onUploadCustomers: (data: {}) => {};
  onImportCustomers: (data: {}) => {};
  onClearCustomersImport: () => void;
}

interface IProps extends IStateProps, IDispatchProps {
  showModal: boolean;
  toggleModal: () => void;
  toggleUpdateCustomers: () => void;
}

const ImportCustomersModal: React.FC<IProps> = ({
  currentUser,
  showModal,
  toggleModal,
  onImportCustomers,
  onClearCustomersImport,
  customersImport,
  toggleUpdateCustomers,
  error,
  loading,
  onUploadCustomers
}) => {
  const [infoText, setInfoText] = useState(null);

  useEffect(() => {
    if (customersImport && !error) {
      setInfoText(<p style={{color: "green"}}>Import file ok. Add {customersImport.length} new customers by clicking Save</p>);
    } else if (error) {
      setInfoText(<p style={{color: "red"}}>Import file reading failed. Please check the file</p>);
    }
  }, [customersImport, error]);

  const getControls = (): any => {
    return {
      image: {
        elementType: "dropzone",
        elementConfig: {
          accept: EDropzoneType.CSV,
          text: "Drop csv here or click to upload.",
          dragText: "Drop csv here.",
          contentType: EContentType.CSV,
          config: {},
          onDrop: onDropHandler
        },
        valid: true,
        touched: false,
        value: ""
      }
    }
  };

  const onDropHandler = (files: Array<File>) => {
    const formData = new FormData();
    formData.append("csv", files[0]);
    onUploadCustomers(formData);
  }

  const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const data = { customers: [...customersImport] };
    const organizationId = currentUser.organizationId;

    if (organizationId) {
      onImportCustomers(data);
      toggleUpdateCustomers();
      onClearCustomersImport();
      setInfoText(null);
    }

    toggleModal();
  };

  const serveCustomersImportTemplate = () => {
    window.open(getApiPath() + "json/customers/csv/template", "_blank");
  }

  return (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Import customers</ModalHeader>
      <ModalBody>
        {loading ? <ListSpinner />
          : (
            <div>
              {controlsToFormGroups(getControls(), null)}
              {infoText}
            </div>
          )}
      </ModalBody>
      <ModalFooter className={classes.Footer}>
        <div>
          <Button type="button" color="secondary" onClick={serveCustomersImportTemplate}>
            Template
                    </Button>
        </div>
        <div>
          <Button type="button" color="link" className={classes.DiscardButton} onClick={toggleModal}>
            Discard
                    </Button>
          {' '}
          <Button type="submit" color="info" className={classes.SaveButton} disabled={!customersImport} onClick={submitHandler}>
            Save
                    </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}


const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    customersImport: state.importCustomers.customersImport,
    error: state.importCustomers.error,
    loading: state.importCustomers.loading
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onUploadCustomers: data => dispatch(actions.uploadCustomers(data)),
    onImportCustomers: (data) => dispatch(actions.importCustomers(data)),
    onClearCustomersImport: () => dispatch(actions.clearCustomersImport())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportCustomersModal);
