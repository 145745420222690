import React, {useState} from "react";

import {
  IBlockContent,
	IBlockContentProducts,
	IProduct,
	IOffer,
} from "../../../../../../../interfaces";

import { ETaxTypes, EOfferStatus } from "../../../../../../../enums";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from "@fortawesome/free-solid-svg-icons";

import { Table, Row, Col, Input } from 'reactstrap';
import { InputGroup, InputGroupText } from 'reactstrap';

import { round, calcTotal } from './functions';

import { isOfferSigned } from '../../../../../../../shared/offer-utils';


import classes from "./BlockContentProductsShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
	showDummyContent?: boolean;
	onChange?: (blockContent: IBlockContent) => void;
	onBlur?: (blockContent: IBlockContent) => void;
	offer?: IOffer;
}

const BlockContentContentShow: React.FC<IProps> = props => {
	const blockContent = props.blockContent as IBlockContentProducts;

	const [editQuantity, setEditQuantity]=useState<{[id:number]:boolean}>({});

	const updateBlock = (index:number,name:keyof IProduct,value:any) => {
		if(props.onChange) {
			const b:any={...blockContent}
			b.products[index][name]=value;
			props.onChange(b)
		}
	}


	const currency=blockContent.currency;
	const labels=blockContent.labels;

	const confirmed=props.offer && (props.offer.status===EOfferStatus.CONFIRMED || isOfferSigned(props.offer));

  const categories:{[id:string]:Array<IProduct>}=Object.values(blockContent.products).reduce((resp:{[id:string]:Array<IProduct>},ob)=>{
    const x:Array<IProduct>=resp[ob.category] || [];
    x.push(ob);
    return {...resp,[ob.category]:x}
  },{});

  const isDecimal = (v:number) => (v%1!==0)

	return (
    <div className={classes.table}>
    {Object.keys(categories).map((category)=>{
      const categoryProducts:Array<IProduct>=categories[category];

    	let total=0;
    	let totalVat=0;
      let showDiscount=false;
      let showVAT=false;
      let showUnit=false;
      let showQuantity=false;

      //    	const totals=Object.values(blockContent.products).reduce((resp:any,ob:any)=>{
    
      let decimals={price:false,quantity:false,vat:false,discount:false,total:false};

      const totals=categoryProducts.reduce((resp:any,ob:any)=>{

    		if(!ob.library && (!ob.optional || ob.optionalSelected)) {
          const quantity=ob.quantity || 0;

    			const _total=calcTotal(ob.unitPrice,quantity,ob.discount);
    
          const v=ob.vat || 0

          if(isDecimal(_total)) { decimals.total=true; }

          if(isDecimal(v)) { decimals.vat=true; }
          if(isDecimal(ob.unitPrice)) { decimals.price=true; }
          if(isDecimal(quantity)) { decimals.quantity=true; }

    			if(blockContent.tax===ETaxTypes.INCLUSIVE) {
            const t=_total-_total/(1+v/100);
   
    				resp[v]=(resp[v] || 0) + t;
    				total+=_total-t;
    				totalVat+=t;
    			}
    			else {
            const t=_total*v/100;

            resp[v]=(resp[v] || 0) + t; //(_total*v/100);
    				total+=_total;
            totalVat+=t; //_total*v/100;

    			}
          //if(isDecimal(total) || isDecimal(totalVat)) { decimals.allTotal=true; }
          //if(isDecimal(total) || isDecimal(totalVat)) { decimals.total=true; }

          if(ob.discount && ob.discount!==0) showDiscount=true;
          if(ob.vat) showVAT=true;
          if(ob.unit) showUnit=true;
          if(ob.quantity || ob.quantityEditable) showQuantity=true;
    		}
    		return resp
    	},{})

      //const separator = (num:string | number) => (String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 "));
      const separator = (num:string | number) => (String(num).replace(/(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 "));

      const borderStyle={"border":"solid 1px "+blockContent.color}; 

      const totalDecimals=isDecimal(total) || isDecimal(totalVat);

      return (<div key={category}>
        {/*category*/}
	<Table bordered className={classes.table}>
		<thead style={{"background":blockContent.color}}>
		<tr>
			<td style={borderStyle}>
        <h5>{labels.item}</h5>
			</td>
			<td style={borderStyle} className="text-center">
	      <h5>{labels.unitPrice}</h5>
			</td>
      {showQuantity && <td style={borderStyle} className="text-center">
	      <h5>{labels.quantity}</h5>
			</td>}
      {showUnit && <td style={borderStyle} className="text-center">
	      <h5>{labels.unit}</h5>
			</td>}
        {showVAT && <td style={borderStyle} className="text-center">
	      <h5>{labels.vat}</h5>
			</td>}
			{showDiscount && <td style={borderStyle} className="text-center">
	      <h5>{labels.discount}</h5>
			</td>}
      {showQuantity && <td style={borderStyle} className="text-right">
	      <h5>{labels.total}</h5>
			</td>}
		</tr>
    </thead>

      {/* {Object.values(blockContent.products).map((ob,index)=>{ */}

			{/*categoryProducts.sort((a:any,b:any) => (a.order_nr>b.order_nr?1:-1)).map((ob,index)=>{*/}
			{categoryProducts.map((ob,index)=>{
        const quantity=ob.quantity || 0;
        if(!ob.library) {
					const total=calcTotal(ob.unitPrice,quantity,ob.discount);

          return(<tbody key={index} style={{"border":"none"}}>

          	<tr className={ob.optional && !ob.optionalSelected?classes.strike:""}>
						<td style={borderStyle}>
							<InputGroup>
      				    <InputGroupText style={{border:"unset",background:"inherit",padding:5,marginRight:5}}>
										<Input addon type="checkbox" hidden={!ob.optional} checked={ob.optionalSelected || !ob.optional} disabled={!ob.optional || confirmed} onChange={(e:any)=>updateBlock(index,"optionalSelected",e.target.checked)}/>
      				    </InputGroupText>

                <div className={classes.wordbreak}>
                  {(ob.item || "").split("\n").map((ob,index)=>(<span key={index}>{ob}<br/></span>))}
                </div>
							</InputGroup>
						</td>
						<td style={borderStyle} className="text-center">
				  	    {separator(decimals.price?round(ob.unitPrice):ob.unitPrice)}
						</td>
            {showQuantity && <td style={borderStyle} className="text-center" onClick={()=>setEditQuantity({...editQuantity,[index]:true})}>
							
							{(ob.quantityEditable && editQuantity[index] && !confirmed)
								? <Input type="number" autoFocus className={classes.editQuantity} value={quantity} onBlur={()=>setEditQuantity({...editQuantity,[index]:false})} onChange={(e:any)=>updateBlock(index,"quantity",e.target.value)}/>
								: <span>
									{ob.quantityEditable && !confirmed && <div className={classes.editable}><FontAwesomeIcon icon={faPen}/></div>}
									{separator(decimals.quantity?round(quantity):quantity)}
								</span>
							}
						</td>}
            {showUnit && <td style={borderStyle} className="text-center">
				  	    {ob.unit}
						</td>}
            {showVAT && <td style={borderStyle} className="text-center">
				  	    {decimals.vat?round(ob.vat):ob.vat} %
						</td>}
						{showDiscount && <td style={borderStyle} className="text-center">
				  	    {separator(decimals.discount?round(ob.discount):ob.discount)} %
						</td>}
		
            {showQuantity && <td style={borderStyle} className="text-right">
              {/*separator(blockContent.tax===ETaxTypes.EXCLUSIVE?(decimals.total?round(total):total):(decimals.total?round(total*(1+ob.vat/100)):total*(1+ob.vat/100)))} {currency*/}
              {separator(decimals.total?round(total):total)} {currency}
						</td>}
		
          	</tr>

          	{ob.description !== null && ob.description !== undefined && ob.description !== "" && <tr>
            	<td style={borderStyle} colSpan={showDiscount?7:6}>
              	<div className={classes.desc}>{ob.description}</div>
            	</td>
          	</tr>}

          </tbody>)
        }
        else { return null };
			})}
	</Table>





  {blockContent.showTotal && 
	<div className={classes.totalBox}>

    {showVAT && <>
  		<Row className={blockContent.tax!==ETaxTypes.INCLUSIVE?classes.header:""}>
				<Col className="text-right">{labels.total} {labels.vat} 0%</Col>
				<Col xs={3} className="text-right">
					{separator(totalDecimals?round(total):total)} {currency}
				</Col>
			</Row>

			{Object.keys(totals).map((key:any)=>{
				const ob=totals[key];
        return(ob===0?null:<Row key={key}>
					<Col className="text-right">{labels.vat} {key}%</Col>
					<Col xs={3} className="text-right">{separator(totalDecimals?round(ob):ob)} {currency}</Col>
				</Row>)
			})}
    </>}

			<Row className={blockContent.tax===ETaxTypes.INCLUSIVE?classes.header:""}>
				<Col className="text-right">{labels.total}</Col>
				<Col xs={3} className="text-right">
					{separator(totalDecimals?round(total+totalVat):(total+totalVat))} {currency}
				</Col>
			</Row>

    </div>
  }
</div>)})}

    </div>
  );
};

export default BlockContentContentShow;
