import { ELanguage } from "./../enums/index";

export const languages = [
  { id: ELanguage.ENGLISH, label: "English" },
  { id: ELanguage.FINNISH, label: "Finnish" },
  { id: ELanguage.SWEDISH, label: "Swedish" },
  { id: ELanguage.GERMAN, label: "German" },
  { id: ELanguage.SPANISH, label: "Spanish" },
  { id: ELanguage.FRENCH, label: "French" },
  { id: ELanguage.DUTCH, label: "Dutch" },
  { id: ELanguage.DANISH, label: "Danish" },
  { id: ELanguage.PORTUGUESE, label: "Portuguese" }
];

