import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { IContentTypeItemType } from "../../../../../../../interfaces";
import { ESignerType } from "../../../../../../../enums";
import {
  getFormData,
  initForm,
  //controlsToFormGroups,
  //defaultInputChangedHandler,
} from "../../../../../../../shared/utility";

interface IProps extends IContentTypeItemType {}

const ContentTypeSignatures: React.ForwardRefExoticComponent<
  IProps
> = forwardRef(({ blockContent, setIsValid }, ref) => {
  const [controlsState, setControlsState] = useState({
    controls: {
			signatures:{
        value:[
				  {
					 name:"",
					 email:"",
					 phone:"",
					 title:"",
					 image:null,
					 timestamp:null,
           signerType: ESignerType.USER,
				  },
          {
            name:"",
            email:"",
            phone:"",
            title:"",
            image:null,
            timestamp:null,
            signerType: ESignerType.CONTACT,
          },
			  ] 
      },
    },
    formIsValid: true
  });
/*
  const [modControlsState, setModControlsState] = useState({
    controls: {
        user: {
        elementType: "checkbox",
        elementConfig: {
          options: [
            {value:"USER",displayValue:"User"},
          ],
          label: "Signed by sender",
        },
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        value: "USER"
      },
      contact: {
        elementType: "checkbox",
        elementConfig: {
          options: [
            {value:"CONTACT",displayValue:"Contact"},
          ],
          label: "Prefill contact",
        },
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        value: "CONTACT"
      },
    },
    formIsValid: true
  });
*/

  useEffect(() => {
    if (blockContent) {
      const newControlsState = initForm(controlsState.controls, blockContent);
      setControlsState(newControlsState);
    }
    // eslint-disable-next-line
  }, [blockContent]);

  useEffect(() => {
    setIsValid(controlsState.formIsValid);
  }, [controlsState.formIsValid, setIsValid]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return getFormData(controlsState.controls);
    }
  }));
/*
  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    defaultInputChangedHandler(
      event,
      controlName,
      modControlsState,
      setModControlsState
    );
  };

  
  return controlsToFormGroups(modControlsState.controls, inputChangedHandler);
*/
  return null
});

export default ContentTypeSignatures;

