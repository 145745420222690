import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import horseImage from '../../../../../../../assets/images/dummy/horse-image.jpg';
import CustomDialog from '../../../../../../../components/UI/CustomDialog/CustomDialog';
import ImageSpinner from '../../../../../../../components/UI/ImageSpinner/ImageSpinner';
import { IAttachment, IBlockContent, IBlockContentTeamMember } from '../../../../../../../interfaces';
import OfferPreviewContext from '../../../../../containers/Offers/Offer/OfferPreviewContext';

import classes from "./BlockContentTeamMemberShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent: boolean;
}

const dummyTeamMember = {
  memberName: "John D.",
  title: "The Horse",
  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
  Morbi ac fermentum sem. Vivamus viverra aliquam augue. 
  Integer ac mauris vel nisi porta porttitor eget vel lacus. 
  Nulla erat sem, viverra sit amet efficitur vitae, pellentesque aliquam augue. 
  In id consectetur nulla. Vestibulum gravida euismod orci vel fermentum. 
  Curabitur elementum elit vel nisl lobortis accumsan. Donec neque eros, molestie eu sem non, sagittis iaculis libero. 
  Praesent id augue purus. Morbi vitae libero non lacus luctus efficitur. 
  Curabitur egestas varius enim, vel efficitur sapien pellentesque at. 
  Phasellus pharetra neque eget risus gravida finibus. 
  Cras at lectus mollis, efficitur felis luctus, fringilla purus. 
  In in consectetur erat.`,
  image: {
    id: "",
    link: horseImage,
    clientName: "Horse",
    contentType: "",
    serverName: "",
    size: 0,
    hash: ""
  }
};

const BlockContentTeamMemberShow: React.FC<IProps> = props => {
  let blockContent = props.blockContent as IBlockContentTeamMember;
  const showDummyContent = props.showDummyContent;

  const { printable } = useContext(OfferPreviewContext);

  if (
    showDummyContent &&
    !blockContent.memberName &&
    !blockContent.title &&
    !blockContent.image &&
    !blockContent.description
  ) {
    blockContent = { ...blockContent, ...dummyTeamMember };
  }

  const { t } = useTranslation();

  const renderImage = (image: IAttachment) => {
    if (!image) return null;
    return (
      <div className={classes.Item}>
        <ImageSpinner
          src={image.link}
          alt={image.clientName}
          // style={{
          //   maxWidth: blockContent.maxWidth + "px",
          //   maxHeight: blockContent.maxHeight + "px"
          // }}
        />
      </div>
    );
  };

  const renderContent = (
    blockContent: IBlockContentTeamMember,
    dialog?: boolean
  ) => {
    if (!blockContent) return null;
    return (
      <div className={classes.Container}>
        {renderImage(blockContent.image)}
        <p className={classes.MemberName}>{blockContent.memberName}</p>
        <p className={classes.Title}>{blockContent.title}</p>
        {!blockContent.description ? null : dialog || printable ? (
          <div className={classes.Description}>
            <span
              dangerouslySetInnerHTML={{ __html: blockContent.description }}
            ></span>
          </div>
        ) : (
            <Button
              color="link"
              className={classes.Dialog}
              onClick={() => setIsOpen(true)}
            >
              {blockContent.linkText
                ? blockContent.linkText
                : t("offerPreview.moreInformation")}
            </Button>
          )}
      </div>
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      {renderContent(blockContent)}
      <CustomDialog title="" open={isOpen} closable onCancel={() => setIsOpen(false)} hideButtons>
        {renderContent(blockContent, true)}
      </CustomDialog>
    </React.Fragment>
  );
};

export default BlockContentTeamMemberShow;
