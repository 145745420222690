import React from "react";
import { IAuditLog } from "../../../../../../../../interfaces";
import { EAuditLogAction } from "../../../../../../../../enums";

import classes from "./OfferAuditLogItem.module.scss";

interface IProps {
  auditLog: IAuditLog;
  headerTextTimestamp: string;
  headerTextAuditData: string;
}

const OfferAuditLogItem: React.FC<IProps> = ({ auditLog, headerTextTimestamp, headerTextAuditData }) => {
  let customDetails = null;
  if (auditLog.customDetails) {
    customDetails = <p>{auditLog.customDetails}</p>;
  }


  const getActionContent = () => {
    const user = auditLog.user;

    switch (auditLog.action) {
      case EAuditLogAction.OFFER_COMMENT:
        return user ? `Document was commented by ${user!==undefined?user.name:"unknown"}` : `Document was commented by Customer`;
      case EAuditLogAction.OFFER_CONFIRM:
        return user ? `Document was confirmed by ${user!==undefined?user.name:"unknown"}` : `Document was confirmed by Customer`;
      case EAuditLogAction.OFFER_DECLINE:
        return user ? `Document was declined by ${user!==undefined?user.name:"unknown"}` : `Document was declined by Customer`;
      case EAuditLogAction.OFFER_GET_BY_TOKEN:
        return `Document was opened by Customer`;
      case EAuditLogAction.OFFER_SENT:
        return `Document was sent by ${user!==undefined?user.name:"unknown"} to`;
      case EAuditLogAction.OFFER_CONFIRM_CODE_SENT:
        return `Confirm code was sent to`
      case EAuditLogAction.OFFER_TERMINATE:
        return `Document was terminated by ${user!==undefined?user.name:"unknown"}`;
			
      case EAuditLogAction.OFFER_SIGNED_VISMASIGN:
        return `Document was signed by Visma Sign`;
      case EAuditLogAction.OFFER_CUSTOMER_UPDATED:
        return `Document was updated by Customer`;

      case EAuditLogAction.OFFER_SIGNED:
        return `Document was signed by Customer`;



			default:
        return null;
    }

  }

  return (
    <React.Fragment>
      <div className={classes.Header}>{headerTextTimestamp}</div>
      <div className={classes.Timestamp}>{auditLog.created} UTC</div>
      <div className={classes.Header}>{headerTextAuditData}</div>
      <div className={classes.Content}>
        <p>{getActionContent()}</p>
        {customDetails}
        <p>
          {auditLog.browserName || auditLog.operatingSystem ? (
            <React.Fragment>
              Device: {auditLog.browserName} on {auditLog.operatingSystem}
              <br />
            </React.Fragment>
          ) : null}

          {auditLog.ipAddress ? (
            <React.Fragment>
              IP number: {auditLog.ipAddress}
              <br />
            </React.Fragment>
          ) : null}

          {auditLog.city || auditLog.region || auditLog.country ? (
            <React.Fragment>
              IP Location: {auditLog.city}, {auditLog.region}, {auditLog.country}
            </React.Fragment>
          ) : null}

        </p>
      </div>
      <div className={classes.Line}></div>
    </React.Fragment>
  );
};

export default OfferAuditLogItem;
