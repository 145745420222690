import React, { useState, useEffect } from 'react';
import { IUser, IAppState, IAttachment } from '../../../../interfaces';
//import { RouteComponentProps } from 'react-router';
import { RouteComponentProps } from "../../../../withRouter";

import { connect } from 'react-redux';
import { validateInput, getFormData, controlsToFormGroups, initForm } from '../../../../shared/utility';
import * as actions from "../../../../store/actions";
import FormFooter from '../../../../components/UI/FormFooter/FormFooter';
import FormContainer from '../../../../components/UI/FormContainer/FormContainer';
import FooterFixed from '../../../../components/UI/FooterFixed/FooterFixed';
import { getProfileControls } from '../../../../shared/controls/profileControls';
import ListSpinner from '../../../../components/UI/ListSpinner/ListSpinner';

interface IStateProps {
  currentUser: IUser | null;
  error: string | null;
  status: string | null;
  attachments: Array<IAttachment>;
  loading: boolean;
};

interface IDispatchProps {
  onUpdatePassword: (formData: {}) => {};
  onGetCurrentUser: () => {};
  onClearAttachmentState: () => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps { };

const Profile: React.FC<IProps> = props => {
  const {
    currentUser, onUpdatePassword, status,
    onGetCurrentUser, attachments, loading,
    onClearAttachmentState, history,
  } = props;

  const [state, setState] = useState({
    controls: getProfileControls(),
    formIsValid: false
  });

  const [statusText, setStatusText] = useState('');

  useEffect(() => {
    setStatusText(null);
  }, [onGetCurrentUser]);

  useEffect(() => {
    let controls = getProfileControls();

    const data = initForm(controls, currentUser);
    setState({
      controls: data.controls,
      formIsValid: data.formIsValid
    });
  }, [currentUser]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value = event.target ? event.target.value : event;

    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
  };

  // TODO: texts could be returned from server?
  const getStatus = (status: string) => {
    switch (status) {
      case "SUCCESS":
        return <h4>User updated successfully.</h4>;
      case "ERROR_CURRENT_PASSWORD":
        return <h4 style={{ color: "red" }}>Wrong current password. Please try again.</h4>;
      case "ERROR_PASSWORD_LENGTH":
        return <h4 style={{ color: "red" }}>Password too short (min. 8 chars). Please try again.</h4>;
      case "ERROR_NO_UPDATE":
        return null;
      default:
        return <h4 style={{ color: "red" }}>Password change failed. Please try again.</h4>;
    }
  };

  const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (currentUser) {
      const formData = getFormData(state.controls);

      if (attachments) {
        formData.imageURL = attachments[0].serverName;
      }

      await onUpdatePassword(formData);
      
      if(status) {
        setStatusText(status);
      }

      onClearAttachmentState();
      onGetCurrentUser();
    }
  };

  return (
    <React.Fragment>
      <FormContainer title="Profile">
        {controlsToFormGroups(state.controls, inputChangedHandler)}
        {loading ? <ListSpinner />
          : attachments ? <p>Image loaded.</p> : null}
        {statusText ? getStatus(status) : null}
      </FormContainer>
      <FooterFixed>
        <FormFooter
          saveText="Save profile"
          onSave={submitHandler}
          onDiscard={() => history.goBack()}
          isValid={state.formIsValid && !loading}
        />
      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    error: state.auth.error,
    status: state.auth.status,
    attachments: state.attachments.attachments,
    loading: state.attachments.loading,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onUpdatePassword: formData => dispatch(actions.updatePassword(formData)),
    onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
    onClearAttachmentState: () => dispatch(actions.clearAttachmentState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);