import React, { useEffect, useState } from 'react';
import { IOrganization, IUser } from '../../../../../interfaces';

import { CustomInput, Input, Button, Row, Col } from 'reactstrap';

//import { config } from '../../../../../constants';


//const hostname = window && window.location && window.location.hostname;
/*
let BASEURL=config.GOOGLE.BASEURL;
if(hostname === 'localhost') {
  BASEURL="http://localhost:8081/google";
}
*/

import classes from "../Integrations.module.scss";

interface IStateProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
}

interface IDispatchProps {
  updateOrganization: (x:any)=>void;

}

interface IProps {
  currentUser?: IUser | null;
  organization: IOrganization | null;
  updateOrganization: (x:any)=>void;
  params: any;
}

const GoogleDrive: React.FC<IProps> = ({
  currentUser,
  organization,
  updateOrganization,

  params,
}) => {

  const [settings, setSettings]=useState<any>({});

  const integrations=organization && organization.integrations;

  const updateGoogle = (updated:any) => {
    if(settings.active) {
      setSettings({...settings,active:false});
    }
    else {
      window.location.href="/integration/google/authenticate";
    }
  }

  const saveChanges = () => {
    const newo={...organization,
      integrations:{
        ...organization.integrations,
        google:{
          ...organization.integrations.google,
          ...settings,
        }  
      }      
    }
    updateOrganization(newo);
  }

  useEffect(()=>{
    setSettings(integrations.google);
  },[]);

  return (
    <React.Fragment>
          <div className={classes.integration}>

      <Row>
        <Col>
      			<h5>Upload confirmed PDF's to Google Drive</h5>
            <CustomInput
              type="switch"
              label="Is Active"
              id="googleDriveActiveId"
    			  	onChange={(e:any)=>{updateGoogle({active:!settings.active})}}
    				  checked={settings.active}
            />

            {JSON.stringify(integrations.google) !== JSON.stringify(settings) &&
            <Button onClick={saveChanges}>Save</Button>}
         
        </Col>
                
      </Row>
      </div>


    </React.Fragment>
  );
};

export default GoogleDrive;

