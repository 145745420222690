import React, { useRef, useEffect, useState } from "react";

import Input from "../components/UI/Input/Input";
import { ECardBrand, EUserRole, EContentType } from "../enums";
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa
} from "@fortawesome/free-brands-svg-icons";
import {
  IUser,
  IUserTemplateAreaBlock,
  IBlockContent,
  IBlockContentContent,
  IBlockContentImage,
  IBlockContentVisual,
  IBlockContentAttachment,
  IBlockContentPricing,
  IBlockContentTeamMember,
  IBlockContentSignature,
  IBlockContentProducts,
  IProduct,
} from "../interfaces";

export function updateObject<T>(oldObject: T, updatedProperties: T): T {
  return Object.assign({}, oldObject, updatedProperties);
}

export const checkValidity = (
  value: any,
  rules: any,
  controls: any
): boolean => {
  let isValid = true;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = String(value).trim() !== "" && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }
  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.email) {
    isValid = isValidEmail(value) && isValid;
  }

  if(rules.emailOrEmpty) {
    isValid = value ? isValidEmail(value) && isValid : isValid
  }

  if (rules.match) {
    isValid = controls[rules.match].value === value && isValid;
    controls[rules.match].valid = isValid;
  }

  return isValid;
};

export const isValidEmail = (email: string): boolean => {
  return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email);
};

export const validateInput = (
  controls: any,
  controlName: string,
  value: any
): any => {
  const elementType = controls[controlName].elementType;
  if (elementType === "checkbox") {
    let values = controls[controlName].value;
    if (typeof values === "object") {
      const index = values.indexOf(value);
      if (index !== -1) {
        values.splice(index, 1);
      } else {
        values.push(value);
      }
      value = values;
    } else {
      value = value === "true" || value === "false" ? Boolean(value) : value;
      value = values === value ? false : value;
    }
  } else if (elementType === "datepicker") {
    // value = new Date(value);
  }

  const updatedControls = updateObject(controls, {
    [controlName]: updateObject(controls[controlName], {
      value,
      valid: checkValidity(value, controls[controlName].validation, controls),
      touched: true
    })
  });

  return {
    controls: updatedControls,
    formIsValid: validateControls(updatedControls)
  };
};

export const validateControls = (controls: any): boolean => {
  let formIsValid = true;
  for (let controlIdentifier in controls) {
    formIsValid = controls[controlIdentifier].valid && formIsValid;
  }
  return formIsValid;
};

export const initForm = (controls: any, data: any): any => {
  let validation = null;
  for (let key in data) {
    if (controls[key]) {
      validation = validateInput(controls, key, data[key]);
      controls = validation.controls;
    }
  }

  return {
    controls: validation ? validation.controls : controls,
    formIsValid: validation ? validation.formIsValid : false
  };
};

export const getFormData = (controls: any): any => {
  const formData: any = {};

  for (let formElementIdentifier in controls) {
    let value = controls[formElementIdentifier].value;
    if (value instanceof Date) {
      value = dateToString(value);
    }

    formData[formElementIdentifier] = value;
  }
  return formData;
};

export const controlsToFormGroups = (
  controls: any,
  inputChangedHandler: any,
  onBlurHandler?: any,
	hideLabel?:any,
): any => {
  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({
      id: key,
      config: controls[key]
    });
  }
  
	const formGroups = formElementsArray.map(formElement => {
    return (
      <Input
        key={formElement.id}
        formElement={formElement}
        changed={inputChangedHandler}
        onBlur={onBlurHandler}
				hideLabel={hideLabel}
      />
  );
  });

  return formGroups;
};

export const defaultInputChangedHandler = (
  event: React.ChangeEvent<HTMLInputElement>,
  controlName: string,
  controlsState: any,
  setControlsState: any
) => {
  let value = event.target ? event.target.value : event;
  const validation = validateInput(controlsState.controls, controlName, value);
  setControlsState(validation);
  return validation;
};

export const getApiPath = () => {
  const hostname = window && window.location && window.location.hostname;
  let apiPath = "/";
  if (hostname === "localhost") {
    apiPath = "http://localhost:8081/";
  }
  return apiPath;
};

export const dateToString = (date: Date) => {
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

export const stringToDate = (dateString: string) => {
  const parts = dateString.match(/(\d+)/g);
  return new Date(+parts[2], +parts[1]-1, +parts[0]);
}

export const getCardBrandImage = (brand: string) => {
  switch (brand) {
    case ECardBrand.AMEX:
      return faCcAmex;
    case ECardBrand.DINERSCLUB:
      return faCcDinersClub;
    case ECardBrand.DISCOVER:
      return faCcDiscover;
    case ECardBrand.JCB:
      return faCcJcb;
    case ECardBrand.MASTERCARD:
      return faCcMastercard;
    case ECardBrand.UNIONPAY:
      return null;
    case ECardBrand.VISA:
      return faCcVisa;
    case ECardBrand.UNKNOWN:
      return null;
    default:
      return null;
  }
};

export const isUUID = (uuid:string) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);
}


/**
 * Aikas kauhea ratkasu, mutta styles-components aiheuttaa ylimääräistä renderöintiä.
 * Tällä saadaan turhat lataukset edit <-> preview välillä pois.
 */
export const addElementStyles = (target: string, titleFont: string, paragraphFont: string, titleColor:string, paragraphColor: string, linkColor: string, customColor: string | null) => {

  const exists = document.getElementById(`${target}-styles`);
  if(exists) {
    exists.remove();
  }

  const paragraphElements = ['p', 'li', 'div'];
  const titleElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const linkElements = ['a', 'button.btn-link'];
  const customBgElements = ['.customColor-bg', '.customColor-bg:after']

  const styleSheet = document.createElement(`style`);
  styleSheet.id = `${target}-styles`;
  document.head.appendChild(styleSheet);

  const mapElements = (elements:Array<string>, styleValue:string, styleName:string) => elements.map(elem => `#${target} ${elem} { ${styleName}: ${styleValue};}`).join(' ')
  if ( paragraphFont ) styleSheet.appendChild(document.createTextNode(mapElements(paragraphElements, paragraphFont, 'font-family')));
  if ( titleFont ) styleSheet.appendChild(document.createTextNode(mapElements(titleElements, titleFont, 'font-family')));
  if ( titleColor ) styleSheet.appendChild(document.createTextNode(mapElements(titleElements, titleColor, 'color')));
  if ( paragraphColor ) styleSheet.appendChild(document.createTextNode(mapElements(paragraphElements, paragraphColor, 'color')));
  if ( linkColor ) styleSheet.appendChild(document.createTextNode(mapElements(linkElements, linkColor, 'color')));
  if ( customColor ) styleSheet.appendChild(document.createTextNode(mapElements(customBgElements, customColor, 'background-color')));
}

export const copyToClipboard = (input: HTMLInputElement) => {
  input.select();
  input.setSelectionRange(0, 99999);
  document.execCommand("copy");
  if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) { 
      window.getSelection().removeAllRanges();
    }
  }
}

export const copyEmailToClipboard = (value: string) => {
  let input = document.createElement('div');
  input.innerHTML = value; //'<a href="'+value+'" target="_blank" style="background: rgb(52,152,219); color: rgb(255, 255, 255); display: inline-block; border: 1px solid rgb(52, 152, 219); border-radius: 5px; box-sizing: border-box; text-decoration-line: none; font-weight: bold; margin: 0px; padding: 12px 25px;">Avaa tarjous klikkaamalla tästä</a>'

  document.body.appendChild(input);

     var currentRange:Range | null;
     if(document.getSelection().rangeCount > 0)
     {
          currentRange = document.getSelection().getRangeAt(0);
          window.getSelection().removeRange(currentRange);
     }
     else
     {
          currentRange = null;
     }

     var CopyRange = document.createRange();
     CopyRange.selectNode(input);
     window.getSelection().addRange(CopyRange);
     document.execCommand("copy");

     window.getSelection().removeRange(CopyRange);

     if(currentRange)
     {
          window.getSelection().addRange(currentRange);
     }



  document.body.removeChild(input)
};

/*
function CopyClassText(){
     var textToCopy = document.getElementById("CopyMeID");

     var currentRange;
     if(document.getSelection().rangeCount > 0)
     {
          currentRange = document.getSelection().getRangeAt(0);
          window.getSelection().removeRange(currentRange);
     }
     else
     {
          currentRange = false;
     }

     var CopyRange = document.createRange();
     CopyRange.selectNode(textToCopy);
     window.getSelection().addRange(CopyRange);
     document.execCommand("copy");

     window.getSelection().removeRange(CopyRange);

     if(currentRange)
     {
          window.getSelection().addRange(currentRange);
     }
}
 */


export const copyStringToClipboard = (value: string) => {
  let input = document.createElement('input');
  input.setAttribute('type', 'text');
  input.value = value;
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input)
};

/**
 * Hook for comparing with old props
 * @param value 
 */ 
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const hasAccess = (
  user: IUser,
  ...roles: Array<EUserRole>
) => {
  if (!user || !roles) return false;
  return roles.indexOf(user.role) !== -1;
};

/**
 * Check if block is empty
 */
export const showBlock = (block: IUserTemplateAreaBlock): boolean => {
  if (block.offerBlockItems && block.offerBlockItems.length > 0) {
    for(let i in block.offerBlockItems) {
      if(block.offerBlockItems[i] && block.offerBlockItems[i].content && block.offerBlockItems[i].content.length > 0) {
        for(let j in block.offerBlockItems[i].content) {
          if (showBlockContent(block.offerBlockItems[i].content[j])) return true;
        }
      }
    }
	}
  if (block.content && block.content.length > 0) {
    for(let i in block.content) {
      if (showBlockContent(block.content[i])) return true;
    }
  }

  return false;
}

const showBlockContent = (blockContent: IBlockContent): boolean => {
  if (blockContent.contentType === EContentType.CONTENT) {
		const content = blockContent as IBlockContentContent;
		if (content.content) return true;
		if(content.textType==="MODAL" && (
			content.title
      || content.linkText
      || content.contentLong)) return true;
  } else if (blockContent.contentType === EContentType.IMAGE) {
    const content = blockContent as IBlockContentImage;
    if (content.images && content.images.length > 0) return true;

  } else if (blockContent.contentType === EContentType.VISUAL) {
    const content = blockContent as IBlockContentVisual;
    if (content.images && content.images.length > 0) return true;
    if (content.pdfs && content.pdfs.length > 0) return true;
    if (content.videoEmbed && content.videoEmbed.length > 0) return true;

  } else if (blockContent.contentType === EContentType.ATTACHMENT) {
    const content = blockContent as IBlockContentAttachment;
    if (content.attachments && content.attachments.length > 0) return true;

  } else if (blockContent.contentType === EContentType.PRICING) {
    const content = blockContent as IBlockContentPricing;
    // if (content.pricingType) return true;
    // if (content.billingPeriod) return true;
    if (content.billingPeriodOther
      || content.price
      || content.priceOther
      || content.initialPayment
      || content.terminationPeriod
      || content.estimatedAmount
      || content.estimatedAmountMax
      || content.estimatedAmountText
      || content.estimatedDeliveryDate) return true;

  } else if (blockContent.contentType === EContentType.DEAL_BETWEEN) {
    return true;
  } else if (blockContent.contentType === EContentType.PARTIES) {
    return true;

  } else if (blockContent.contentType === EContentType.TEAM_MEMBER) {
    const content = blockContent as IBlockContentTeamMember;
    if (content.memberName
      || content.title
      || content.description
      || content.image
      || content.linkText) return true;

  } else if (blockContent.contentType === EContentType.LEGAL_BOX) {
    return true;

  } else if (blockContent.contentType === EContentType.SIGNATURE) {
    const content = blockContent as IBlockContentSignature;
    if (content.signer) return true;

  } else if (blockContent.contentType === EContentType.FIELDS) {
    return true;
  } else if (blockContent.contentType === EContentType.PRODUCTS) {
    const content = blockContent as IBlockContentProducts;
    if(content.products.filter((ob:IProduct)=>!ob.library).length>0) {
      return true;
    }    
  } else if (blockContent.contentType.startsWith(EContentType.PRIVATE)) {
    return true;
  } else if (blockContent.contentType === EContentType.SIGNATURES) {
    return true;
  } else if (blockContent.contentType === EContentType.HTML) {
    return true;
  } else if (blockContent.contentType === EContentType.TAG) {
    return true;
  }


  return false;
}

/**
 * if value is null, return an empty string
 * 
 * if value has no decimals, return initial value
 * 
 * if value has decimals, round to 2
 */
export const toFixed = (number: Number) => {
  if(!number) return "";
  if(number.valueOf() % 1 === 0) return number;
  return Number(number.toString()).toFixed(2);
}

export const sortByLabel = (arr: Array<any>) => {
  if(!arr) return null;
  return arr.sort((a, b) => {
    if (a.label < b.label) { return -1; }
    if (a.label > b.label) { return 1; }
    return 0;
  });
}

export const getCookie = (cname: string) => {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const dateToISOString = (date: Date) => {
  if (!date) return null;
  return date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + (date.getDate())).slice(-2) + " " + date.getHours() + ":" + date.getMinutes();
}

/**
 * Returns scroll position
 * https://gist.github.com/joshuacerbito/ea318a6a7ca4336e9fadb9ae5bbb87f4
 */
export const useScroll = () => {
  // Set a single object `{ x: ..., y: ..., direction: ... }` once on init
  const [scroll, setScroll] = useState({
    x: document.body.getBoundingClientRect().left,
    y: document.body.getBoundingClientRect().top,
    direction: ''
  })

  const listener = () => {
    // `prev` provides us the previous state: https://reactjs.org/docs/hooks-reference.html#functional-updates
    setScroll(prev => ({
      x: document.body.getBoundingClientRect().left,
      y: -document.body.getBoundingClientRect().top,
      // Here we’re comparing the previous state to the current state to get the scroll direction
      direction: prev.y > -document.body.getBoundingClientRect().top ? 'up' : 'down'
    }))
  }

  useEffect(() => {
    window.addEventListener('scroll', listener)
    // cleanup function occurs on unmount
    return () => window.removeEventListener('scroll', listener)
    // Run `useEffect` only once on mount, so add `, []` after the closing curly brace }
  }, [])

  return scroll
}
