import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import OfferStatus from "../OfferStatus/OfferStatus";
import { IOffer } from "../../../../../interfaces";
import { EOfferStatus } from "../../../../../enums";
import SortIcon, { ESortDirection } from "../../../../../components/UI/SortIcon/SortIcon2";
import { EOfferSort } from "../../../../../enums/offer-enums";

interface IProps {
  offers: Array<IOffer>;
  onOpenOffer: (id: string) => void;
  searchString: string;
}

const OffersList: React.FC<IProps> = ({ offers, onOpenOffer, searchString}) => {
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [pages, setPages] = useState<number>(1);
  const [slicedOffers, setSlicedOffers] = useState<Array<IOffer>>([]);

  const [newSort, setNewSort] = useState<any>({property: 'UPDATED', direction: 'DESC'});

  const PAGE_LENGTH = 25;

  useEffect(()=>{
    if(offers!==null) {

      const foffers = offers.filter(offer => {
        const s = (offer.customerName || "").toLowerCase()+" "+
          (offer.name || "").toLowerCase()+" "+(offer.authorName || "").toLowerCase()+offer.created+offer.confirmDate;
        return (
          (searchString===null || s.includes(searchString.toLowerCase()))
        )
      });

      setMaxPage(Math.ceil(foffers.length/PAGE_LENGTH));
      setSlicedOffers(foffers);
    }
  },[offers, searchString]) 

  const prevPage = () => {
    if(page>0) setPage(page-1);
  }

  const nextPage = () => {
    if(page+PAGE_LENGTH<offers.length)
      if(page<Math.ceil(offers.length/PAGE_LENGTH)-1) setPage(page+1);
  }

  const dconv = (d:string) => {
     const a=d.split(" ");
     const b=a[0].split(".");
     return b[2]+b[1]+b[0]+a;
  }

  const sort = (a:IOffer,b:IOffer) => {
    if(newSort === null) return 1;
    else if(newSort.property === "STATUS") {
      const astatus = a.isExpired?"EXPIRED":a.status;
      const bstatus = b.isExpired?"EXPIRED":b.status;
      return astatus > bstatus ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }
    else if(newSort.property === "CUSTOMER_NAME") {
      return (a.customerName || "").toLowerCase() > (b.customerName || "").toLowerCase() ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }
    else if(newSort.property === "UPDATED") {
      return dconv(a.updated) > dconv(b.updated) ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }
    else if(newSort.property === "EXPIRATION_DATE") {
      return dconv(a.expirationDate) > dconv(b.expirationDate) ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }
    else if(newSort.property === "CREATED") {
      return dconv(a.created) > dconv(b.created) ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }
    else if(newSort.property === "AUTHOR_NAME") {
      return (a.authorName || "").toLowerCase() > (b.authorName || "").toLowerCase() ? (newSort.direction === ESortDirection.ASC ? 1 : -1) : (newSort.direction === ESortDirection.ASC ? -1 : 1);
    }

 
    return 1;
  }

  const click = (newSortx:any) => { 
    setNewSort(newSortx);
  }

  if (!offers || !offers.length) {
    return null;
  }

  return (
    <div>
    <div style={{"width":"100%", "textAlign":"center"}}>
    <button onClick={prevPage}>&lt;</button> 
    {Array.from(Array(maxPage), (e,i) => {
      return <button key={"btn"+i} style={{"background":i===Math.min(maxPage-1,page)?"#999999":"#eeeeee"}} onClick={()=>setPage(i)}>{i+1}</button>
    })} 
    <button onClick={nextPage}>&gt;</button>
    </div>

    <Table responsive borderless striped>
      <thead>
        <tr>
          <th>Customer <SortIcon property={EOfferSort.CUSTOMER_NAME} onSortChange={click} /></th>
          <th>Status <SortIcon property={EOfferSort.STATUS} onSortChange={click} /></th>
          <th>Activity <SortIcon property={EOfferSort.UPDATED} onSortChange={click} /></th>
          <th>Expiring on <SortIcon property={EOfferSort.EXPIRATION_DATE} onSortChange={click} /></th>
          <th>Created <SortIcon property={EOfferSort.CREATED} onSortChange={click} /></th>
          <th>Created by <SortIcon property={EOfferSort.AUTHOR_NAME} onSortChange={click} /></th>
					{/*<th style={{ "textAlign":"center" }}>Archived</th>*/}
        </tr>
      </thead>
      <tbody>
        {/*offers.map(offer => offer.status === EOfferStatus.CONFIRMED ? null : (*/}
        {slicedOffers.sort(sort).slice(Math.min(maxPage-1,page)*PAGE_LENGTH,Math.min(maxPage-1,page)*PAGE_LENGTH+PAGE_LENGTH).map(offer => ( 

          <tr key={offer.id} onClick={() => onOpenOffer(offer.id)}>
            <td>
              {offer.customerName}<br/>
              {offer.name}
            </td>
            <td>
              <OfferStatus offer={offer} />
            </td>
            <td>{offer.updated}</td>
            <td>{offer.expirationDate}</td>
            <td>{offer.created}</td>
            <td>{offer.authorName}</td>
						{/*<td style={{ "textAlign":"center" }}>{offer.archived?"X":""}</td>*/}
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
  );
};

export default OffersList;

