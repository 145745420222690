import React, {useState} from "react";

import {
  IBlockContent,
	//IBlockContentProducts,
	IBlockContentPrivate,
	IProduct,
	IOffer,
} from "../../../../../../../interfaces";

import { ETaxTypes, EOfferStatus } from "../../../../../../../enums";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from "@fortawesome/free-solid-svg-icons";

import { Table, Row, Col, Input } from 'reactstrap';
import { InputGroup, InputGroupText } from 'reactstrap';

import { round, calcTotal } from '../BlockContentProducts/functions';

import { isOfferSigned } from '../../../../../../../shared/offer-utils';


import classes from "../BlockContentProducts/BlockContentProductsShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
	showDummyContent?: boolean;
	onChange?: (blockContent: IBlockContent) => void;
	onBlur?: (blockContent: IBlockContent) => void;
	offer?: IOffer;
}

const BlockContentContentShow: React.FC<IProps> = props => {
	const blockContent = props.blockContent as IBlockContentPrivate;

	const [editQuantity, setEditQuantity]=useState<{[id:number]:boolean}>({});
/*
	const updateBlock = (index:number,name:keyof IProduct,value:any) => {
		if(props.onChange) {
			const b={...blockContent}
			b.products[index][name]=value;
			props.onChange(b)
		}
	}

	const updateBlock2 = (index:number) => {
		if(props.onChange) {
			const b={...blockContent}
			const p = b.products[index].item.split(":")[0];;
			
			b.products = b.products.reduce((resp:any,ob:any)=>{
				
				const _p = ob.item.split(":")[0];
				if(_p === p) {
					return [...resp, {...ob,optionalSelected:false}];
				}
				return [...resp, ob];
			},[]);
			b.products[index].optionalSelected=true;
			props.onChange(b)
		}
	}


	const currency=blockContent.currency;
	const labels=blockContent.labels;

	const confirmed=props.offer && (props.offer.status===EOfferStatus.CONFIRMED || isOfferSigned(props.offer));

  const categories:{[id:string]:Array<IProduct>}=Object.values(blockContent.products).reduce((resp:{[id:string]:Array<IProduct>},ob)=>{
    const x:Array<IProduct>=resp[ob.category] || [];
    x.push(ob);
    return {...resp,[ob.category]:x}
  },{});

  const isDecimal = (v:number) => (v%1!==0)
*/

const data:any = JSON.parse(blockContent.json || "{}");
const setup:any = JSON.parse(blockContent.settings || "{}");


const eur = (v:number) => {
	return (Math.round(v*100)/100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}).replace(/,/g, " ").replace(/\./g, ",");

}

const palkka_koulutus = (paketti:string) => {
	
		return Object.keys(setup.koulutus[3]).reduce((resp:number,key:string) => {
			if(data.palkansaajia>key) {
				resp = Math.max(setup.koulutus[3][key],resp);
			}
			return resp;
		},0);
	
}

/*
	return (
    <div className={classes.table}>
    {Object.keys(categories).map((category)=>{
      const categoryProducts:Array<IProduct>=categories[category];

    	let total=0;
    	let totalVat=0;
      let showDiscount=false;
      let showVAT=false;
      let showUnit=false;
      let showQuantity=false;

      let test="";
      const groups:{[id:string]:any} = {};

      const selections:any = categoryProducts.reduce((resp:any,ob:any, index:number)=>{

    		if(!ob.library) {
    			try {
    			const g = ob.item.split(":");
    			const s = resp[g[0]] || {options:[],label:g[3]};
    			if(s.label==="" && g[3]!=="") s.label=g[3];

    			s.options.push({label:g[4], multiplier:ob.unitPrice, row:g[1], group:g[2], ob:ob, index:index});

    			resp[g[0]]=s;
    			} catch(err) {}
    		}

    		return resp;
    	},{});

    	//console.log(selections);

      //    	const totals=Object.values(blockContent.products).reduce((resp:any,ob:any)=>{
    
      let decimals={price:false,quantity:false,vat:false,discount:false,total:false};

      const totals=categoryProducts.reduce((resp:any,ob:any)=>{

    		if(!ob.library && (!ob.optional || ob.optionalSelected)) {
    			try {
    			const group = ob.item.split(":");
    			const g = groups[group[2]] || 1;
    			groups[group[2]]=g*ob.unitPrice;
    			} catch(err) {}

          // const quantity=ob.quantity || 0;

    			// const _total=calcTotal(ob.unitPrice,quantity,ob.discount);
    
          // const v=ob.vat || 0

          // if(isDecimal(_total)) { decimals.total=true; }

          // if(isDecimal(v)) { decimals.vat=true; }
          // if(isDecimal(ob.unitPrice)) { decimals.price=true; }
          // if(isDecimal(quantity)) { decimals.quantity=true; }

    			// if(blockContent.tax===ETaxTypes.INCLUSIVE) {
          //   const t=_total-_total/(1+v/100);
   
    			// 	resp[v]=(resp[v] || 0) + t;
    			// 	total+=_total-t;
    			// 	totalVat+=t;
    			// }
    			// else {
          //   const t=_total*v/100;

          //   resp[v]=(resp[v] || 0) + t; //(_total*v/100);
    			// 	total+=_total;
          //   totalVat+=t; //_total*v/100;

    			// }

          // if(isDecimal(total) || isDecimal(totalVat)) { decimals.allTotal=true; }
          // if(isDecimal(total) || isDecimal(totalVat)) { decimals.total=true; }

          // if(ob.discount && ob.discount!==0) showDiscount=true;
          // if(ob.vat) showVAT=true;
          // if(ob.unit) showUnit=true;
          // if(ob.quantity || ob.quantityEditable) showQuantity=true;

    		}

    		return resp
    	},0);
      

      //const separator = (num:string | number) => (String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 "));
      const separator = (num:string | number) => (String(num).replace(/(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 "));

      const borderStyle={"border":"solid 1px "+blockContent.color}; 

      const totalDecimals=isDecimal(total) || isDecimal(totalVat);

      return (<div key={category}>

				{Object.values(selections).map((s:any)=>{
					const selected = s.options.find((x:any)=>x.ob.optionalSelected);
					return (selected?<div key={"s"+selected.index}>
						<h5>{s.label}</h5>
						<Input
						 type="select"
						 value={selected.index}
						 onChange={(e:any)=>updateBlock2(parseInt(e.target.value))} >
						{s.options.map((o:any)=>{
							return (<option key={"o"+o.index} value={o.index}>
								{o.label}
							</option>)
						})}
						</Input>
					</div>:null)
				})}
*/

return (<div>
<Row>
	<Col>Paketti</Col>
	<Col className="col-6" style={{textTransform: "capitalize"}}>{data.paketti}</Col>
	<Col className="text-right">{data.calc && eur(data.calc.paketti + data.calc.konserni)} €/kk</Col>
</Row>

<hr/>

<Row>
	<Col>Lisäpalvelut</Col>
	<Col className="col-6">Palkanlaskenta ja työajankirjaus ({data.palkansaajia} hlö)</Col>
	<Col className="text-right">{data.calc && eur(data.calc.palkanlaskenta)} €/kk</Col>
</Row>

<Row>
	<Col></Col>
	<Col className="col-6">Matka- ja kululaskut ({data.henkiloita} hlö), laskutus toteutuman mukaan</Col>
	<Col className="text-right">{data.calc && eur(data.calc.matkalaskut)} €/kk</Col>
</Row>
{/*
<Row>
	<Col></Col>
	<Col className="col-6">Konsernikäsittely (0 kpl)</Col>
	<Col className="text-right">- €/kk</Col>
</Row>
*/}
<hr/>

<Row>
	<Col>Tapahtumamaksut</Col>
	<Col className="col-6"><i>Hinnat ovat arvioita, laskutus toteutuman mukaan</i></Col>
	<Col className="text-right"></Col>
</Row>

<Row>
	<Col></Col> 
	<Col className="col-6">Sähköiset tapahtumat</Col>
	<Col className="text-right"></Col>
</Row>
{/*
<Row>
	<Col></Col>
	<Col className="col-6"> - Verkkolaskun vastaanotto</Col> 
	<Col className="text-right">0,00 €/kk</Col>
</Row>

<Row>
	<Col></Col>
	<Col className="col-6">Verkkolaskun lähetys</Col>
	<Col className="text-right">0,00 €/kk</Col>
</Row>
*/}
<Row>
	<Col></Col>
	<Col className="col-6">&nbsp;&nbsp;Verkkopalkan lähetys</Col>
	<Col className="text-right">{data.calc && eur(data.calc.verkkopalkan_lahetys)} €/kk</Col>
</Row>

<Row>
	<Col></Col>
	<Col className="col-6">Pankkitapahtumat</Col>
	<Col className="text-right"></Col>
</Row>

{/*
<Row>
	<Col></Col>
	<Col className="col-6">Maksut</Col>
	<Col className="text-right">0,00 €/kk</Col>
</Row>

<Row>
	<Col></Col>
	<Col className="col-6">Viitesuoritukset</Col>
	<Col className="text-right">0,00 €/kk</Col>
	</Row>
*/}

<Row>
	<Col></Col>
	<Col className="col-6">&nbsp;&nbsp;Toistuvaissuoritukset</Col>
	<Col className="text-right">{data.calc && eur(data.calc.toistuvaissuoritukset)} €/kk</Col>
</Row>

<hr/>

<Row>
	<Col>YHTEENSÄ</Col>
	<Col className="col-6"></Col>
	<Col className="text-right">{data.calc && eur(data.calc.paketti + data.calc.konserni + data.calc.palkanlaskenta + data.calc.matkalaskut + data.calc.verkkopalkan_lahetys + data.calc.toistuvaissuoritukset)} €/kk</Col>
</Row>

<hr/>

<Row>
	<Col>Netvisorin avausmaksu</Col>
	<Col className="col-6"></Col>
	<Col className="text-right">+ {data.calc && eur(data.calc.avaus)} €</Col>
</Row>

{data.calc && data.calc.koulutus > 0 && <>
<hr/>
<Row>
	<Col>Koulutuspaketit</Col>
	<Col className="col-6"></Col>
	<Col className="text-right"></Col>
</Row>

{data.calc && ((data.calc.koulutus&1)===1) && <Row>
	<Col></Col>
	<Col className="col-6">Perus käyttöönottokoulutus</Col>
	<Col className="text-right">+ {eur(setup.koulutus[0])} €</Col>
</Row>}
{data.calc && ((data.calc.koulutus&2)===2) && <Row>
	<Col></Col>
	<Col className="col-6">Laaja käyttöönottokoulutus</Col>
	<Col className="text-right">+ {eur(setup.koulutus[1])} €</Col>
</Row>}
{data.calc && ((data.calc.koulutus&4)===4) && <Row>
	<Col></Col>
	<Col className="col-6">Laaja + varasto käyttöönottokoulutus</Col>
	<Col className="text-right">+ {eur(setup.koulutus[2])} €</Col>
</Row>}
{data.calc && ((data.calc.koulutus&8)===8) && <Row>
	<Col></Col>
	<Col className="col-6">Palkanlaskennan koulutus</Col>
	<Col className="text-right">+ {eur(palkka_koulutus(data.paketti))} €</Col>
</Row>}
{data.calc && ((data.calc.koulutus&16)===16) && <Row>
	<Col></Col>
	<Col className="col-6">Konsernikäsittely -koulutus</Col>
	<Col className="text-right">+ {eur(setup.koulutus[4])} €</Col>
</Row>}
</>}


<hr/>
<Row>
	<Col></Col>
	<Col className="col-6"></Col>
	<Col className="text-right"><small>Hinnat ALV 0 %</small></Col>
</Row>
{/*
	<h5>Hinta: {Object.values(groups).reduce((partialSum, a) => partialSum + a, 0)} € / käyttäjä</h5>
*/}

</div>)};
/*
      )}

    </div>
  );
};
*/
export default BlockContentContentShow;
