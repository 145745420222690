import { ICustomerAction, ICustomerState } from '../../interfaces';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';

const initialState:ICustomerState = {
    customers: null,
    loading: false,
    error: null,
    customer: null,
    success: false,
    search: null
};

const clearCustomersState = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return initialState;
}

const findCustomersStart = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, { error: null, loading: true});
}


const findCustomersSuccess = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        customers: action.customers,
        error: null,
        loading: false,
        customer: null
    });
}

const findCustomersFail = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchCustomersFail = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchCustomersStart = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, { error: null, loading: false});
}

const searchCustomersSuccess = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        search: action.search,
        error: null,
        loading: false
    });
}

const getCustomerStart = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, { error: null, loading: true});
}

const getCustomerSuccess = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        customer: action.customer,
        error: null,
        loading: false
    });
}

const getCustomerFail = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const saveCustomerStart = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, { error: null, loading: true});
}

const saveCustomerSuccess = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    const customers = state.customers ? [...state.customers] : [];
    const { customer }= action;
    customers.push(customer);

    return updateObject(state, {
        customer: customer,
        error: null,
        success: true,
        loading: false,
        customers: customers
    });
}

const saveCustomerFail = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateCustomerStart = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, { error: null, loading: true});
}

const updateCustomerSuccess = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const updateCustomerFail = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const clearCustomersSearch = (state:ICustomerState, action:ICustomerAction):ICustomerState => {
    return updateObject(state, {
        search: null
    });
};

const reducer = (state:ICustomerState = initialState, action:ICustomerAction):ICustomerState => {
    
    switch (action.type) {
        case ActionTypes.CLEAR_CUSTOMERS_STATE : return clearCustomersState(state, action);
        case ActionTypes.FIND_CUSTOMERS_START : return findCustomersStart(state, action);
        case ActionTypes.FIND_CUSTOMERS_SUCCESS : return findCustomersSuccess(state, action);
        case ActionTypes.FIND_CUSTOMERS_FAIL : return findCustomersFail(state, action);
        case ActionTypes.SEARCH_CUSTOMERS_START : return searchCustomersStart(state, action);
        case ActionTypes.SEARCH_CUSTOMERS_SUCCESS : return searchCustomersSuccess(state, action);
        case ActionTypes.SEARCH_CUSTOMERS_FAIL : return searchCustomersFail(state, action);
        case ActionTypes.GET_CUSTOMER_START : return getCustomerStart(state, action);
        case ActionTypes.GET_CUSTOMER_SUCCESS : return getCustomerSuccess(state, action);
        case ActionTypes.GET_CUSTOMER_FAIL : return getCustomerFail(state, action);
        case ActionTypes.SAVE_CUSTOMER_START : return saveCustomerStart(state, action);
        case ActionTypes.SAVE_CUSTOMER_SUCCESS : return saveCustomerSuccess(state, action);
        case ActionTypes.SAVE_CUSTOMER_FAIL : return saveCustomerFail(state, action);
        case ActionTypes.UPDATE_CUSTOMER_START : return updateCustomerStart(state, action);
        case ActionTypes.UPDATE_CUSTOMER_SUCCESS : return updateCustomerSuccess(state, action);
        case ActionTypes.UPDATE_CUSTOMER_FAIL : return updateCustomerFail(state, action);
        case ActionTypes.CLEAR_CUSTOMERS_SEARCH : return clearCustomersSearch(state, action);
        default: return state;

    }
}

export default reducer;
