import { ActionTypes } from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

import {IUserState, IUserAction, IRegisterState, IRegisterAction} from '../../interfaces';

const initialState:IUserState = {
    users: null,
    loading: false,
    error: null,
    user: null,
    success: false,
    search: null,
    resetPasswordStatus: ''
};

const findUsersStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const findUsersSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        users: action.users,
        error: null,
        loading: false,
        user: null
    });
}

const findUsersFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchUsersFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchUsersStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: false});
}

const searchUsersSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        search: action.search,
        error: null,
        loading: false
    });
}

const getUserStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const getUserSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        user: action.user,
        error: null,
        loading: false
    });
}

const getUserFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const saveUserStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const saveUserSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const saveUserFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateUserStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const updateUserSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const updateUserFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const registerUserStart = (state:IRegisterState, action:IRegisterAction):IRegisterState => {
    return updateObject(state, { error: null, loading: true});
}

const registerUserSuccess = (state:IRegisterState, action:IRegisterAction):IRegisterState => {
    return updateObject(state, {
        error: null,
        registered: true,
        loading: false
    });
}

const registerUserFail = (state:IRegisterState, action:IRegisterAction):IRegisterState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const clearUsersSearch = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        search: null
    });
};

const resetPasswordStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const resetPasswordSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: null,
        resetPasswordStatus: action.resetPasswordStatus,
        loading: false,
    });
}

const resetPasswordFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};

// const uploadUserImageFail = (state:IUserState, action:IUserAction):IUserState => {
//     return updateObject(state, {
//         error: action.error,
//         loading: false
//     });
// };

// const uploadUserImageStart = (state:IUserState, action:IUserAction):IUserState => {
//     return updateObject(state, { error: null, loading: true});
// }

// const uploadUserImageSuccess = (state:IUserState, action:IUserAction):IUserState => {
//     return updateObject(state, {
//         error: null,
//         loading: false
//     });
// }

const reducer = (state:IUserState = initialState, action:IUserAction):IUserState => {
    
    switch (action.type) {
        case ActionTypes.FIND_USERS_START : return findUsersStart(state, action);
        case ActionTypes.FIND_USERS_SUCCESS : return findUsersSuccess(state, action);
        case ActionTypes.FIND_USERS_FAIL : return findUsersFail(state, action);
        case ActionTypes.SEARCH_USERS_START : return searchUsersStart(state, action);
        case ActionTypes.SEARCH_USERS_SUCCESS : return searchUsersSuccess(state, action);
        case ActionTypes.SEARCH_USERS_FAIL : return searchUsersFail(state, action);
        case ActionTypes.GET_USER_START : return getUserStart(state, action);
        case ActionTypes.GET_USER_SUCCESS : return getUserSuccess(state, action);
        case ActionTypes.GET_USER_FAIL : return getUserFail(state, action);
        case ActionTypes.SAVE_USER_START : return saveUserStart(state, action);
        case ActionTypes.SAVE_USER_SUCCESS : return saveUserSuccess(state, action);
        case ActionTypes.SAVE_USER_FAIL : return saveUserFail(state, action);
        case ActionTypes.UPDATE_USER_START : return updateUserStart(state, action);
        case ActionTypes.UPDATE_USER_SUCCESS : return updateUserSuccess(state, action);
        case ActionTypes.UPDATE_USER_FAIL : return updateUserFail(state, action);
        case ActionTypes.REGISTER_USER_START : return registerUserStart(state, action);
        case ActionTypes.REGISTER_USER_SUCCESS : return registerUserSuccess(state, action);
        case ActionTypes.REGISTER_USER_FAIL : return registerUserFail(state, action);
        case ActionTypes.CLEAR_USERS_SEARCH : return clearUsersSearch(state, action);
        case ActionTypes.RESET_PASSWORD_START : return resetPasswordStart(state, action);
        case ActionTypes.RESET_PASSWORD_SUCCESS : return resetPasswordSuccess(state, action);
        case ActionTypes.RESET_PASSWORD_FAIL : return resetPasswordFail(state, action);
        // case ActionTypes.UPLOAD_USER_IMAGE_START : return uploadUserImageStart(state, action);
        // case ActionTypes.UPLOAD_USER_IMAGE_SUCCESS : return uploadUserImageSuccess(state, action);
        // case ActionTypes.UPLOAD_USER_IMAGE_FAIL : return uploadUserImageFail(state, action);
        default: return state;

    }
}

export default reducer;