import React, { useContext } from 'react';
import WebFont from "webfontloader";

import OfferPreviewContext from '../../../../../containers/Offers/Offer/OfferPreviewContext';
import { IBlockContent, IBlockContentSignature } from '../../../../../../../interfaces';
import { ESigner, EOfferStatus } from '../../../../../../../enums';
import { dateToISOString } from '../../../../../../../shared/utility';

import classes from "./BlockContentSignatureShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent: boolean;
}

const BlockContentSignatureShow: React.FC<IProps> = ({ blockContent, showDummyContent }) => {
  const content = blockContent as IBlockContentSignature;
  let { organization, customer, confirmDate, sentDate, customerSignature, author, status, currentUser } = useContext(OfferPreviewContext);

  // if (status !== EOfferStatus.CONFIRMED) return null;

  WebFont.load({
    google: {
      families: [`Cedarville Cursive:400,700`]
    }
  });

  let signer = {
    name: "",
    title: ""
  }

  let contactPerson = customer && customer.contactPerson;

  switch (content.signer) {
    case ESigner.AUTHOR:
      signer = {
        name: author && author.name,
        title: content.signerTitle
      }

      if (!signer.name && !status) {
        signer.name = currentUser && currentUser.name;
      }
      break;
    case ESigner.CONTACT_PERSON:
      signer = {
        name: organization && organization.contactPerson,
        title: organization && organization.contactTitle
      }
      break;
    default:
      break;
  }

  let signature = null;

  if (customerSignature) {
    if (customerSignature.startsWith("data:image/png;base64,")) {
      signature = <img src={customerSignature} alt="" />;
    } else {
      signature = <p className={classes.Cursive}>{customerSignature}</p>;
    }
  }

  if (showDummyContent) {
    if (!signer.name) {
      if (organization) {
        signer.name = organization.contactPerson;
      } else {
        signer.name = "Lorem Ipsum";
      }
    }
    if (!signer.title) {
      if (organization) {
        signer.title = organization.contactTitle
      } else {
        signer.title = "Lorem Ipsum";
      }
    }
    if (!customer) contactPerson = "Lorem Ipsum";
    if (!signature) signature = <p className={classes.Cursive}>{contactPerson}</p>;
    if (!sentDate) sentDate = dateToISOString(new Date());
    if (!confirmDate) confirmDate = dateToISOString(new Date());
  }

  return (
    <div className={classes.Container}>
      <div className={classes.Signature}>
        {signer && <p className={classes.Cursive}>{signer.name}</p>}
        {signer && <p>{signer.name}</p>}
        {signer && <p>{signer.title}</p>}
        {sentDate && <p>{sentDate}</p>}
      </div>
      <div className={classes.Signature}>
        {(showDummyContent || status === EOfferStatus.CONFIRMED) && (
          <React.Fragment>
            {signature}
            {customer && <p>{contactPerson}</p>}
            {confirmDate && <p>{confirmDate}</p>}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default BlockContentSignatureShow;
