import React, { useEffect } from "react";
import { connect } from "react-redux";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../../withRouter';

import ListHeader from "../../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../../components/UI/ListSpinner/ListSpinner";
import { IAppState, IInvoice } from "../../../../../interfaces";
import * as actions from "../../../../../store/actions";
import ListContainer from "../../../../../components/ListContainer/ListContainer";
import InvoicesList from "../../../components/Billing/Invoices/InvoicesList";

interface IStateProps {
  loading: boolean;
  error: string;
  invoices: Array<IInvoice>;
}

interface IDispatchProps {
  onFindInvoices: () => void;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {}

const Customers: React.FC<IProps> = props => {
  const {
    onFindInvoices,
    invoices,
    loading,
  } = props;

  useEffect(() => {
    onFindInvoices();
  }, [onFindInvoices]);

  return (
    <ListContainer>
      <ListHeader
        title="Invoices"
      />

      {loading ? (
        <ListSpinner />
      ) : (
        <InvoicesList invoices={invoices} />
      )}
    </ListContainer>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.invoices.loading,
    error: state.invoices.error,
    invoices: state.invoices.invoices
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindInvoices: () => dispatch(actions.findInvoices()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customers);
