import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Button, Form } from "reactstrap";
import { IAppState } from "../../../../interfaces";
import { controlsToFormGroups, validateInput } from "../../../../shared/utility";
//import { Link, RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { RouteComponentProps } from '../../../../withRouter';

import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import * as actions from "../../../../store/actions";
import * as qs from 'query-string'

import classes from "./ResetForm.module.scss";

interface IStateProps {
  loading?: boolean;
  error?: string | null;
  resetPasswordStatus?: string;
}

interface IDispatchProps {
  onPasswordReset(id: string, token: string, password: string): void;
  onClearError: () => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}


const ResetForm = (props: IProps) => {
  const [state, setState] = useState({
    controls: {
      password: {
        elementType: "input",
        elementConfig: {
          placeholder: "Password",
          type: "password"
        },
        validation: {
          password: true,
          required: true,
          minLength: 8,
          error: "Password must have at least 8 characters"
        },
        valid: false,
        touched: false,
        value: ""
      },
      passwordConfirm: {
        elementType: "input",
        elementConfig: {
          placeholder: "Confirm password",
          type: "password"
        },
        validation: {
          password: true,
          required: true,
          minLength: 8,
          error: "Passwords do not match"
        },
        valid: false,
        touched: false,
        value: ""
      }
    },
    controlsIsValid: false,
    errors: []
  });
  
  useEffect(() => {
    document.body.classList.add("login-bg");
    return () => {
      document.body.classList.remove("login-bg");
    };
  }, []);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value: any = event;
    if (event.target) {
      value = event.target.value;
    }
    const {controls: {password}} = state;
    const validation = validateInput(state.controls, controlName, value);
    const doesMatch = controlName === 'passwordConfirm' && value === password.value
    const {controls, formIsValid} = validation;
    setState({
      ...state,
      controls: {
        ...controls,
        passwordConfirm: {
          ...controls.passwordConfirm,
          valid: doesMatch
        }
      },
      controlsIsValid: formIsValid,
    });
    
  };

  const getErrors = () => {
    const errors = Object.entries(state.controls).reduce((o, [key, obj]) => {
      if (!obj.valid) {
        return [...o, obj.validation.error]
      }
      return o
    }, []);
    setState({
      ...state,
      errors
    })
    return errors.length > 0
  }

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { onPasswordReset, location: {search} } = props;
    const { password: {value} } = state.controls;
    const {id, token} = qs.parse(search) as {id: string, token: string};
    const hasErrors = getErrors();

    if (state.controlsIsValid && !hasErrors) {
      onPasswordReset(id, token, value)
    }
  };

  const { error, loading, resetPasswordStatus, history } = props;
  const { errors } = state;

  return (
    <div className={classes.Container}>
      <div className={classes.FormBox}>
        <div className={classes.Title}>
          {resetPasswordStatus === 'SUCCESS' ? 'Success!' : 'Reset password'}
        </div>
        {loading ? <ListSpinner />
        : resetPasswordStatus === 'SUCCESS'
        ? <div className={classes.ButtonContainer}>
            <p>Your password has been reset.</p>
              <Button onClick={() => history.push('/login')} color="primary" className={classes.Button}>
                Log in
              </Button>
          </div>
        : (
          <Form onSubmit={submitHandler}>
            <div className={classes.Inputs}>
              {controlsToFormGroups(state.controls, inputChangedHandler)}
            </div>
            {(error || resetPasswordStatus === 'ERROR') && (
              <Alert className={classes.ErrorMessage} color="danger">
                {error || 'Something went wrong, please try again'}
              </Alert>
            )}
            {errors.length > 0 && (
              errors.map(e => (
                <Alert key={e} className={classes.ErrorMessage} color="danger">
                  {e}
                </Alert>
              ))
            )}
            <div className={classes.ButtonContainer}>
              <Button type="submit" color="primary" className={classes.Button}>
                Next
              </Button>
            </div>
            <div className={classes.LoginQuestion}>
              <h6>Remembered your password? <Link to="/login">Log in</Link></h6>
            </div>
          </Form>

        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.users.loading,
    error: state.users.error,
    resetPasswordStatus: state.users.resetPasswordStatus
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onPasswordReset: (id: string, token: string, password: string) =>
      dispatch(actions.resetPassword({id, token, password})),
    onClearError: () => dispatch(actions.clearError()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetForm);
