import { faFilter, faList } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//import { Navigate, Route, RouteComponentProps, Routes as Switch } from "react-router";
import { Navigate, Route, Routes as Switch } from "react-router";
import { RouteComponentProps } from '../../../../withRouter';

import { Alert } from "reactstrap";
import ListContainer from "../../../../components/ListContainer/ListContainer";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ListSpinner from "../../../../components/UI/ListSpinner/ListSpinner";
import SortContext from "../../../../components/UI/SortIcon/SortContext";
import { ESortDirection, ISort } from "../../../../components/UI/SortIcon/SortIcon";
import { EPageView, ESearchType } from "../../../../enums";
import { EOfferListType, EOfferSort } from '../../../../enums/offer-enums';
import { IAppState, IOffer, IUser, IUserGroup } from "../../../../interfaces";
import * as actions from "../../../../store/actions";
import OffersFunnel from "../../components/Offers/OffersFunnel/OffersFunnel";
import OffersList from "../../components/Offers/OffersList/OffersList";

import ReactGA from "react-ga";

interface IStateProps {
  loading: boolean;
  error: string;
  offers: Array<IOffer>;
  currentUser: IUser;
  userGroups: Array<IUserGroup>;
}

interface IDispatchProps {
  onFindOffers: (sort: Array<ISort<EOfferSort>>, old: boolean) => void;
  onFindUserGroups: (organizationId: string) => void;

  onClearOffersState: () => void;
  onSearchOffers: (queryString: string) => void;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {
  type: EPageView;
  old?: boolean;
}

const defaultSort: Array<ISort<EOfferSort>> = [
  { property: EOfferSort.UPDATED, direction: ESortDirection.DESC }
];

const Offers: React.FC<IProps> = ({
  onFindOffers,
  onFindUserGroups,
  onClearOffersState,
  offers,
  loading,
  history,
  type,
  onSearchOffers,
  error,
  currentUser,
  userGroups,
  old,
}) => {
  const [view, setView] = useState(type);
  const [currentSort, setCurrentSort] = useState(defaultSort);
  const [searchString, setSearchString] = useState<string>(null);
  const [filteredOffers, setFilteredOffers] = useState<Array<IOffer>>([]);

  useEffect(() => {
    onFindOffers(defaultSort, old);
    onFindUserGroups(null);
    return () => {
      onClearOffersState();
    };
  }, [onFindOffers, onClearOffersState]);

  const onSort = (newSort: Array<ISort<EOfferSort>>) => {
    setCurrentSort(newSort);
    //onClearOffersState();
    //onFindOffers(newSort, old);
  };

  // load all contracts...
  useEffect(() => {
    onFindOffers(defaultSort,old);
  },[offers]);

  useEffect(()=>{
    if(offers!==null) {
      
      //setSlicedOffers(
        const foffers = offers.filter(offer => {
        const s = (offer.customerName || "").toLowerCase()+" "+
          (offer.name || "").toLowerCase()+" "+(offer.authorName || "").toLowerCase()+offer.created+offer.confirmDate;
        return (
          (searchString!==null && searchString !== "" && s.includes(searchString.toLowerCase()))
        )
      });

      if(searchString!=null && searchString !== "")
        setFilteredOffers(foffers);
      else {
        setFilteredOffers(offers.filter(o=>!o.isExpired));
       
      }
    }
  },[offers, searchString]) 


  const viewClickHandler = (type: EPageView) => {
    setView(type);
    history.push(`/offers/${type}`);
  };

  const onCreate = () => {
    ReactGA.event({
      category: 'User',
      action: 'Created proposal'
    });

    history.push(`/offers/add/${EPageView.EDIT}`);
  };

  const searchClickHandler = (id: string) => {
    history.push(`/offers/${id}/${EPageView.PREVIEW}`);
  };

/*
  const [searchTimeout, setSearchTimeout] = useState(null);
  const searchChangedHandler = (queryString: string) => {
    clearTimeout(searchTimeout);
    setSearchTimeout(setTimeout(()=>{
      onSearchOffers(queryString);
    },500));
  };
*/
  const searchChangedHandler = (queryString: string) => {
    //onSearchOffers(queryString);
    setSearchString(queryString);
  };



  const onOpenOffer = (id: string) => {
    history.push(`/offers/${id}/${EPageView.PREVIEW}`);
  };

  if (error) {
    return (
      <Alert color="danger" style={{ margin: "1rem" }}>
        {error}
      </Alert>
    );
  }

  let onCreateAction = {
    text: "Create new proposal",
    clicked: onCreate
  };
  if(currentUser.isRestricted) {
    onCreateAction = null;
  }

  return (
    <React.Fragment>
      <ListContainer>
        <ListHeader
          title="Proposals"
          onCreate={onCreateAction}
          view={{
            currentView: view,
            clicked: viewClickHandler,
            views: [
              { text: "Funnel", type: EPageView.FUNNEL, icon: faFilter },
              { text: "List", type: EPageView.LIST, icon: faList }
            ]
          }}
          onSearch2={{
            searchFunc: searchChangedHandler,
          }}
        />
        {/*
          onSearch={{
            type: ESearchType.OFFERS,
            searchFunc: searchChangedHandler,
            clicked: searchClickHandler
          }}
        />*/}


        {loading ? (
          <ListSpinner style={{"height":0,"zIndex":9}}/>
        ) : null}

        {type === EPageView.FUNNEL
          ?<OffersFunnel offers={filteredOffers} userGroups={userGroups} currentUser={currentUser} />
          : <SortContext.Provider value={{ currentSort, onSort }}>
            <OffersList offers={offers} onOpenOffer={onOpenOffer} searchString={searchString}/>
          </SortContext.Provider>
        }

      </ListContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.offers.loading,
    error: state.offers.error,
    offers: state.offers.offers,
    currentUser: state.auth.currentUser,
    userGroups: state.userGroups.userGroups,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindOffers: (sort, old) =>
      dispatch(actions.findOffers(sort, EOfferListType.OFFERS, old?"&old=old":"")),
    onFindUserGroups: organizationId => dispatch(actions.findUserGroups(organizationId)),
    onClearOffersState: () => dispatch(actions.clearOffersState()),
    onSearchOffers: queryString => dispatch(actions.searchOffers(queryString))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
